<template>
  <div class="informationNav">
    <navTop />
    <div class="inforBody">
      <div class="leftBar">
        <el-form ref="ruleForm" label-width="100px">
          <el-form-item prop="username" class="demo-ruleForm" label="账户名称">
            <el-input
              v-model="ruleForm.username"
              class="alliInput"
              placeholder="请输入"
              suffix-icon="iconfont iconsousuo-copy"
            />
          </el-form-item>
          <el-form-item prop="profile" class="demo-ruleForm" label="个人简介">
            <el-input
              v-model="ruleForm.profile"
              class="alliInput"
              placeholder="个人简介"
              type="textarea"
              :rows="8"
            />
          </el-form-item>
        </el-form>
        <el-button class="local" type="primary" @click="saveBtn"
          >保存</el-button
        >
      </div>
      <div class="rightBar">
        <el-form ref="ruleForm" label-width="100px">
          <el-form-item prop="username" class="demo-ruleForm" label="用户头像">
            <el-upload
              class="avatar-uploader"
              action="aaa"
              accept=".jpg, .jpeg, .png"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-change="avatarUpload"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon" />
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { provinceAndCityData } from "element-china-area-data";
import { mapState, mapMutations } from "vuex";
const formData = new FormData();
export default {
  data() {
    return {
      ruleForm: {
        username: "",
        profile: "",
        province: "",
        city: "",
      },
      options: provinceAndCityData,
      selectedOptions: [],
      imageUrl: "",
    };
  },
  methods: {
    ...mapMutations(["currentUrlStorage", "userStorage"]),
    /**
     * 更改用户信息
     */
    async saveBtn() {
      const obj = {};
      if (this.ruleForm.username !== "") {
        obj.name = this.ruleForm.username;
      }
      if (this.ruleForm.profile !== "") {
        obj.desc = this.ruleForm.profile;
      }
      if (this.ruleForm.province !== "") {
        obj.province = this.ruleForm.province;
        obj.city = this.ruleForm.city;
      }

      this.$apis.userUpdateInfo(obj, this.token).then((res) => {
        if (res.data.status == 0) {
          this.$message.success("修改成功");
          this.$apis.info({}, this.token).then((data) => {
            this.userStorage(data.data.data);
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    handleChange(value) {
      this.ruleForm.province = value[0];
      this.ruleForm.city = value[1];
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg";
      const isLt2M = file.size < 2 * 1024 * 1024;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG、png、jpeg 格式!");
        return;
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
        return;
      }

      this.imageUrl = window.URL.createObjectURL(file);
      // return isJPG && isLt2M;
      formData.append("file", file, file.name);
    },
    avatarUpload(file) {
      let formData = new FormData();
      formData.append("file", file.raw);
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$apis.userPhoto(formData, this.token).then((res) => {
        loading.close();
        if (res.data.status == 0) {
          this.$message.success("修改成功");
          this.$apis.info({}, this.token).then((data) => {
            this.userStorage(data.data.data);
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
  mounted() {
    if (this.$route.query.nav == "pers") {
      this.currentUrlStorage(["首页", "个人中心", "个人信息"]);
    }
  },
  computed: {
    ...mapState(["user", "token"]),
  },
};
</script>

<style lang="less" scoped>
.avatar-uploader {
  margin-top: 50px;
}
/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 89px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 164px;
  height: 164px;
  line-height: 164px;
  text-align: center;
}
/deep/.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/.el-input {
  margin-left: 30px;
}
/deep/.el-form-item {
  margin-top: 53px;
}
/deep/.el-textarea {
  margin-left: 30px;
}
.informationNav {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  padding: 0px 30px;
  .inforBody {
    width: 100%;
    height: calc(100vh - 160px);
    background-color: #ffffff;
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    .leftBar {
      width: 40%;
      height: 85%;
      margin-left: 3%;
      float: left;
    }
    .rightBar {
      width: 40%;
      height: 100%;
      margin-left: 15%;
      float: left;
    }
    .local {
      float: left;
      width: 100px;
      height: 40px;
      margin-left: 5%;
      margin-top: 10%;
    }
  }
}
</style>
