<template>
  <div class="subject">
    <navTop />
    <div class="bodyNav">
      <div class="top">
        <el-form
          class="formbox"
          ref="ruleForm"
          :model="ruleForm"
          label-width="80px"
        >
          <el-form-item
            class="userForm"
            prop="authorize_unit_id"
            label="巡护单位"
          >
            <el-select
              v-model="ruleForm.authorize_unit_id"
              filterable
              placeholder="请选择(可搜索)"
              clearable
            >
              <el-option
                v-for="(item, index) in unitData"
                :key="index"
                :label="item.unit_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="userForm" prop="air_space_id" label="空域ID">
            <el-input
              placeholder="请输入"
              v-model="ruleForm.air_space_id"
            ></el-input>
          </el-form-item>
          <el-form-item class="userForm" prop="mobile" label="账户">
            <el-input placeholder="请输入" v-model="ruleForm.mobile"></el-input>
          </el-form-item>
          <el-form-item class="userForm" prop="air_space_name" label="空域名称">
            <el-input
              placeholder="请输入"
              v-model="ruleForm.air_space_name"
            ></el-input>
          </el-form-item>
          <div class="footBtn">
            <div class="fmBtn">
              <el-button class="searchBtn" type="primary" @click="searchBtn"
                >查&nbsp;&nbsp;&nbsp;&nbsp;询</el-button
              >
              <el-button
                class="reset"
                @click="resetForm('ruleForm')"
                type="primary"
                plain
                >重&nbsp;&nbsp;&nbsp;&nbsp;置</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <div class="center-body">
        <el-table
          height="80%"
          :stripe="true"
          :header-cell-style="{ background: '#DDE6EF' }"
          :data="tableData"
          style="width: 98%; margin-left: 1%; margin-top: 1%"
        >
          <el-table-column
            align="center"
            prop="air_space_id"
            label="空域申请ID"
            width="300"
            class="number"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="authorize_unit_name"
            label="巡护单位"
            width="140"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="mobile"
            label="账户"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="air_space_name"
            label="空域名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="created_at"
            label="创建时间"
            width="160"
          ></el-table-column>
          <el-table-column align="center" label="申请详情">
            <template slot-scope="scope">
              <el-button @click="editBtn(scope.row)" type="text"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="application_letter"
            label="申请函附件"
            width="180"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                @click="download(scope.row)"
                plain
                >空域申请函.pdf</el-button
              >
            </template>
          </el-table-column>
          <el-table-column align="center" prop="status" label="是否受理">
            <template slot-scope="scope">
              <el-button @click="acceptance(scope.row)" type="text"
                >受理</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button @click="reject(scope.row)" type="text" class="blok"
                >驳回</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="block">
          <el-pagination
            @current-change="currentchange"
            @next-click="handleNext"
            @prev-click="handlePrev"
            :current-page="current_page"
            :page-size="this.per_page"
            layout="total,  prev, pager, next, jumper"
            :total="this.total"
          ></el-pagination>
        </div>
      </div>
    </div>
    <ApplyAir ref="airtails" :airDetails="airDetails" :title="title" />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ApplyAir from "@/components/messageBox/applyAir";
export default {
  components: {
    ApplyAir,
  },
  data() {
    return {
      current_page: 1, //当前页
      total: 0, //总条目数
      per_page: 10, //每页多少条
      page: 1,
      ruleForm: {
        authorize_unit_id: "",
        air_space_id: "",
        air_space_name: "",
        mobile: "",
      },
      tableData: [],
      unitData: [],
      airDetails: {},
      title: "待审批空域",
    };
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    resetForm(ruleForm) {
      this.$refs["ruleForm"].resetFields();
    },
    // 详情接口
    detail(id) {
      this.$apis
        .detail({ id: id }, this.token)
        .then((data) => {
          this.airDetails = data.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 申请详情
    editBtn(param) {
      this.detail(param.id);
      setTimeout(() => {
        this.$refs.airtails.open();
      }, 500);
    },
    // 是否受理
    datasubmit(data, id) {
      this.$confirm("是否继续此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$apis
            .audit(
              {
                status: data,
                id: id,
              },
              this.token
            )
            .then((data) => {
              if (data.data.status == 0) {
                this.$message({
                  showClose: true,
                  message: data.data.message,
                  type: "success",
                });
                this.pending();
              }
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch(() => {});
    },
    // 受理
    acceptance(param) {
      this.datasubmit(1, param.id);
    },
    // 驳回
    reject(param) {
      this.datasubmit(2, param.id);
    },
    // 下载附件
    download(param) {
      window.open(param.application_letter);
    },
    // 查询
    searchBtn() {
      this.page = 1;
      this.pending();
    },
    // 待审批数据
    pending() {
      this.$apis
        .pending(
          {
            authorize_unit_id: this.ruleForm.authorize_unit_id,
            air_space_id: this.ruleForm.air_space_id,
            air_space_name: this.ruleForm.air_space_name,
            mobile: this.ruleForm.mobile,
            per_page: 8,
            page: this.page,
          },
          this.token
        )
        .then((data) => {
          this.tableData = data.data.data.data;
          this.total = data.data.data.total;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 分页
    handleNext() {
      this.page++;
      this.pending(this.page, this.per_page);
    },
    handlePrev() {
      this.page--;
      this.pending(this.page, this.per_page);
    },
    currentchange(page) {
      this.page = page;
      this.pending(this.page, this.per_page);
    },
    // 巡护列表
    unitsList() {
      this.$apis
        .unitList({ per_page: 1000000000000 }, this.token, "?page=" + this.page)
        .then((data) => {
          this.unitData = this.unitData.concat(data.data.data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  created() {
    this.unitsList();
    this.pending();
  },
  computed: {
    ...mapState(["user", "token"]),
  },
  mounted() {
    if (this.$route.query.nav == "approva") {
      this.currentUrlStorage(["首页", "空域查询", "待审批空域"]);
    }
  },
};
</script>

<style lang="less" scoped>
.blok {
  color: #fd5c5e;
}
.bodyNav {
  height: calc(100vh - 130px);
  width: 100%;
}
.el-tag.el-tag--success {
  background-color: #e7faf0;
  border-color: #1cd0bd;
  color: #1cd0bd;
  width: 80px;
}
/deep/.el-pagination__editor.el-input .el-input__inner {
  width: 40px;
}
/deep/.el-input {
  width: 200px;
}
/deep/.el-form-item {
  float: left;
  margin-left: 70px;
  width: 25%;
}
.online {
  color: #409eff;
  font-size: 14px;
}
.formbox {
  float: left;
  margin-top: 2%;
  width: 100%;
}
.fmBtn {
  float: right;
  width: 35%;
}
.block {
  padding: 20px 0 0 70px;
}
.subject {
  width: 100%;
  height: 100%;
  padding: 0 15px 2% 15px;
  background-color: #f0f0f0;

  .top {
    width: 100%;
    height: 20%;
    display: flow-root;
    background: rgba(255, 255, 255, 1);
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    .nape {
      margin: 2.664% 2% 0 0;
      display: inline-block;
      width: 22%;
      height: 40px;
      span {
        padding-right: 8.3%;
      }
      .import {
        display: inline-block;
        width: 58.363%;
      }
    }
  }
  .center-body {
    width: 100%;
    height: 77%;
    margin-top: 1%;
    margin-top: 15px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    position: relative;
    overflow-y: auto;
    /deep/ .el-table .cell {
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
    }
    /deep/ .el-table .cell:hover {
      text-overflow: inherit;
      overflow: visible;
      white-space: pre-line;
    }
    .pagination {
      position: absolute;
      bottom: 4.973%;
      right: 19.216%;
    }
  }
}
@media only screen and (max-width: 1344px) {
  .subject {
    .center-body {
      height: 67%;
    }
  }
}
</style>