// 任务页面回传数据
<template>
  <el-dialog
    :title="'回传' + name + '数据'"
    :visible.sync="dialogVisible"
    width="687px"
    v-dialogDrag
    :close-on-click-modal="false"
  >
    <div class="bodyBar">
      <div class="row">
        <span class="name">任务名称</span>
        <span>{{ item.task_name }}</span>
      </div>

      <div class="row">
        <span class="name">任务ID</span>
        <span>{{ item.id }}</span>
      </div>

      <div class="row textarea">
        <span class="name">任务名称描述</span>
        <el-input
          v-model="textarea"
          class="area"
          type="textarea"
          maxlength="500"
          :show-word-limit="true"
          placeholder="请输入"
        />
      </div>

      <div class="row">
        <span class="name">回传数据类型</span>
        <span>{{ name }}数据</span>
      </div>

      <div class="row">
        <span class="name">回传平台</span>
        <span>调度中心端</span>
      </div>

      <div class="row">
        <span class="name">回传账户</span>
        <span>{{ user.mobile }}</span>
      </div>

      <div class="row">
        <span class="name">隶属巡护单位</span>
        <span>{{ user.user_unit.unit_name }}</span>
      </div>

      <div style="margin: 30px 0" class="row">
        <span class="name">回传数据包络</span>
        <span class="segmentation"></span>
      </div>

      <div class="row checkList">
        <span class="name">{{ name }}</span>
        <el-checkbox-group v-model="checkList">
          <el-checkbox
            class="list-check"
            v-for="(item, index) in checkListArr"
            :key="index"
            :label="item.name"
          ></el-checkbox>
        </el-checkbox-group>
      </div>
    </div>

    <div class="row uploading">
      <span class="name">文件</span>
      <!-- <div>
        <input
          type="file"
          multiple="multiple"
          webkitRelativePath
          ref="file"
          @change="aetherupload"
          class="uploadInput"
        />
        <span>{{ uploadFile }}</span>
      </div> -->
      <el-button @click="toPostBack" style="color: #1782fb">上传文件</el-button>
    </div>

    <div class="btn">
      <el-button type="primary" style="margin-right: 24px" @click="postback"
        >回传</el-button
      >
      <el-button @click="cancel" style="margin-right: 36px">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
let files = null;
import { mapState } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false,
      name: "原始",
      item: "",
      checkList: [],
      checkListArr: [],
      textarea: "",
      uploadFile: "上传文件",
    };
  },
  mounted() {
    this.monitoring();
  },
  computed: {
    ...mapState(["user", "token", "role", "currentUrl"]),
  },
  methods: {
    cancel() {
      this.dialogVisible = false;
      Object.assign(this.$data, this.$options.data());
    },
    /**
     * 回传数据
     */
    postback() {
      // this.formDatas(files[0]);
      let type_name = JSON.stringify(this.checkList);
      let obj = {
        action: "data",
        task_id: this.item.id,
        description: this.textarea,
        type: this.name,
        type_name: type_name,
      };
      this.taskPostDataBack(obj, "data");
    },
    /**
     * 监听数据
     */
    monitoring() {
      this.$on("open", (res) => {
        this.name = res.name;
        this.item = res.item;
        this.dialogVisible = true;
        this.getClassify();

        let obj = {
          action: "detail",
          task_id: this.item.id,
          type: this.name,
        };
        this.taskPostDataBack(obj, "detail");
      });
    },
    /**
     * 分类数据获取判断
     */
    getClassify() {
      let apiUrl = "";
      if (this.name === "原始") {
        apiUrl = "getOriginalCategory";
      } else {
        apiUrl = "getResultCategory";
      }
      this.taskGetClassify(apiUrl);
    },
    /**
     * 获取数据分类列表
     */
    taskGetClassify(url) {
      this.$apis
        .taskGetClassify(url, this.token)
        .then((data) => {
          this.checkListArr = data.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    aetherupload(e) {
      const inputs = e.target;
      files = e.target.files;
      this.sum = this.sum + files.length;
      this.uploadFile = e.target.files[0].name;
      this.utils.$notifySuccess("成功", "上传成功");
    },
    formDatas(data) {
      let file = data;
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(file);
      fileReader.onload = (e) => {
        let formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("upload_file", true);
        formData.append("task_id", this.item.id);
        formData.append("description", this.textarea);
        formData.append("type", this.name);
        let type_name = JSON.stringify(this.checkList);
        formData.append("type_name", type_name);
        this.taskPostDataBack(formData);
      };
    },
    /**
     * 数据回传
     */
    taskPostDataBack(obj, action) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$apis
        .taskPostDataBack(obj, this.token)
        .then((data) => {
          loading.close();
          if (data.data.status === 0) {
            if (action === "data") {
              this.utils.$message("数据回传成功");
              this.dialogVisible = false;
              this.$emit("updateFlying");
            } else if (action === "detail") {
              if (data.data.data !== null) {
                this.textarea = data.data.data.description;
                let arr = JSON.parse(data.data.data.type_name);
                arr.forEach((e, index) => {
                  this.checkList.push(e);
                });
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
          loading.close();
        });
    },
    toPostBack() {
      let obj = this.item;
      obj.name = this.name;

      this.$emit("toPostBack", obj);
    },
  },
};
</script>

<style lang="less" scoped>
.row {
  margin-bottom: 20px;
}

.name {
  display: inline-block;
  width: 85px;
  text-align: right;
  font-size: 13px;
  margin-right: 49px;
}

.textarea {
  display: flex;
  .area {
    width: 440px;
  }
}

.segmentation {
  display: inline-block;
  width: 440px;
  border-top: 1px dashed #a6aab8;
  height: 1px;
}

.checkList {
  display: flex;

  .list-check {
    margin: 0 24px 25px 0;
  }
}

.uploading {
  display: flex;
  div {
    width: 440px;
    height: 130px;
    background: url(../assets/img/file.png) no-repeat center center;
    background-size: 40px 40px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    position: relative;
    span {
      position: absolute;
      left: 50%;
      transform: translateX(-58%);
      bottom: 31px;
      font-size: 14px;
      color: #838a9d;
    }
  }
  input {
    width: 440px;
    height: 130px;
    opacity: 0;
  }
}
.btn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
</style>
