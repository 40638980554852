var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bottomBar" }, [
    _c(
      "div",
      {
        staticClass: "item",
        on: {
          click: function ($event) {
            return _vm.selectDifferentImg(3)
          },
        },
      },
      [
        _c("div", {
          staticClass: "imgs",
          class: [
            _vm.selectImg === 3
              ? "selectItemOnlineDevicesImg"
              : "itemOnlineDevicesImg",
          ],
        }),
        _vm._v(" "),
        _c("div", [
          _c("div", [_vm._v("在线设备")]),
          _vm._v(" "),
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.datas.onlineTotal)),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "item",
        on: {
          click: function ($event) {
            return _vm.selectDifferentImg(2)
          },
        },
      },
      [
        _c("div", {
          staticClass: "imgs",
          class: [
            _vm.selectImg === 2
              ? "selectItemNetworkDeviceImg"
              : "itemNetworkDeviceImg",
          ],
        }),
        _vm._v(" "),
        _c("div", [
          _c("div", [_vm._v("联网设备")]),
          _vm._v(" "),
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.datas.networkingTotal)),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "item",
        on: {
          click: function ($event) {
            return _vm.selectDifferentImg(4)
          },
        },
      },
      [
        _c("div", {
          staticClass: "imgs",
          class: [_vm.selectImg === 4 ? "selectItemImg" : "itemImg"],
        }),
        _vm._v(" "),
        _c("div", [
          _c("div", [_vm._v("任务数量")]),
          _vm._v(" "),
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.datas.taskTotal)),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "item",
        on: {
          click: function ($event) {
            return _vm.selectDifferentImg(1)
          },
        },
      },
      [
        _c("div", {
          staticClass: "imgs",
          class: [_vm.selectImg === 1 ? "selectItemAreaImg" : "itemAreaImg"],
        }),
        _vm._v(" "),
        _c("div", [
          _c("div", [_vm._v("飞行面积")]),
          _vm._v(" "),
          _c("div", { staticClass: "number" }, [
            _vm._v(_vm._s(_vm.datas.flyAreaTotal)),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }