<template>
  <div style="width: 100%; height: 100%">
    <iframe
      ref="iframe"
      style="width: 100%; height: 100%"
      :src="'/result/index.html?config=' + param"
      frameborder="0"
    />
    <Drawer class="drawer" />
  </div>
</template>

<script>
import AddTag from "@/components/messageBox/addTag";
import Drawer from "@/components/messageBox/drawer";
import EarthHelper from "@/components/earthOperator/earthHelper";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    AddTag,
    Drawer,
  },
  data() {
    return {
      param: JSON.stringify({ publish: false, poiVisiable: false }),
    };
  },
  methods: {
    ...mapMutations(["serviceStorage", "chawidStorage", "chaintrStorage"]),
    /**
     * 获取宝略window对象
     */
    getWindow() {
      window.iWindow = this.$refs.iframe.contentWindow;
    },
    feimaBtn() {
      this.sendMessag();
    },
    turnOn() {
      this.chawidStorage(true);
      this.chaintrStorage(true);
    },
    /**
     * @description: 下载Datamanager
     * @param {type:object} :
     * @return:
     */
    downDatamanager() {
      this.$apis
        .upgrade({ category: "data", version_int: "1.5.0" }, this.token)
        .then((data) => {
          if (data.data.status == 0) {
            const Message = EarthHelper.getMessageService();
            let poi = Message.setUrl(data.data.data.file_url);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  created() {
    this.turnOn();
  },
  mounted() {
    var _this = this;
    this.getWindow();
    if (_this.$refs.iframe.attachEvent) {
      _this.$refs.iframe.attachEvent("onload", function () {
        // IE
        _this.downDatamanager();
      });
    } else {
      _this.$refs.iframe.onload = function () {
        // 非IE
        _this.downDatamanager();
      };
    }
  },

  computed: {
    ...mapState(["user", "token", "drawer", "intrdrawer"]),
  },
};
</script>

<style lang="less" scoped>
/deep/.v-modal {
  z-index: 0;
}
.feimabtn {
  margin-left: 100px;
}
.drawer {
  width: 24%;
  position: absolute;
  left: 10px;
  top: 20px;
  z-index: 999;
  background-color: #fff;
  overflow-y: auto;
  height: 93%;
}
</style>