<template>
  <div>
    <el-dialog
      :show-close="false"
      :visible.sync="this.registerVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="registerNav">
        <div class="loginLogo">
          <img src="@/assets/img/new-logo.png" alt width="60px"/>
        </div>
        <div class="loginTit">
          <span class="loginword">无人机巡护指挥调度管理平台</span>
        </div>
        <div class="getawaytit">
          <span class="getaword">【公众门户网站】</span>
        </div>
        <el-form
          ref="ruleForm"
          class="loginInput"
          :model="ruleForm"
          :rules="rules"
          label-width="100px"
        >
          <el-form-item prop="mobile" class="regInputBar">
            <el-input v-model="ruleForm.mobile" placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item prop="sms_code" class="regInputBar">
            <el-input placeholder="请输入验证码" v-model="ruleForm.sms_code">
              <el-button
                @click="getRegister"
                class="getVerification"
                slot="suffix"
                type="text"
                :disabled="disabled"
                :sms_code="ruleForm.sms_code"
              >
                <!-- 获取验证码 -->
                {{ btnTitle }}
              </el-button>
            </el-input>
          </el-form-item>
          <el-form-item prop="password" class="regInputBar">
            <el-input
              v-model="ruleForm.password"
              placeholder="请输入密码"
              type="password"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item prop="surePassword" class="regInputBar">
            <el-input
              type="password"
              placeholder="请确认密码"
              v-model="ruleForm.surePassword"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item class="regBar">
            <el-checkbox v-model="agreeStatement"
                  ><span style="color:rgb(96, 98, 102)">我已同意并认真阅读</span><a href="#" @click="lookStatement" style="color:#5185ff">《免责声明》</a></el-checkbox
                >
            <el-button
              :loading="loading"
              class="loginBar"
              type="primary"
              @click="userRegister('ruleForm')"
              :disabled="!agreeStatement"
            >注&nbsp;&nbsp;册</el-button>
          </el-form-item>
        </el-form>
        <div class="footerBar">
          <el-link class="forgetPsw" :underline="false" @click="loginBtn">登录账号</el-link>
        </div>
      </div>
    </el-dialog>
    <div class="disclaimer">
      <el-dialog title="免责声明" :visible.sync="disStatemenetData" width="300px" top="25vh" center>
        <disclaimer :show="disStatemenetData" />
        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="disStatemenetData = false;agreeStatement = false">取 消</el-button> -->
          <el-button type="primary" @click="disStatemenetData = false;">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { validPhone } from "../utils/validate.js";
import disclaimer from "@/components/statemenet/disclaimer"
let rule = /^1[0-9][0-9]{9}$/;
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.surePassword !== "") {
          this.$refs.ruleForm.validateField("surePassword");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      disabled: false,
      btnTitle: "获取验证码",
      ruleForm: {
        mobile: "",
        sms_code: "",
        password: "",
        surePassword: ""
      },
      rules: {
        mobile: [
          { required: true, message: "手机号码不能为空", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确手机号", trigger: "blur" }
        ],
        sms_code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 4, max: 4, message: "请输入正确验证码", trigger: "blur" }
        ],
        password: [{ validator: validatePass, trigger: "blur" }],
        surePassword: [{ validator: validatePass2, trigger: "blur" }]
      },
      agreeStatement: false, //同意免责声明
      disStatemenetData: false, //免责声明弹窗
    };
  },
  methods: {
    ...mapMutations(["loginStorage", "registerStorage"]),
    loginBtn() {
      this.registerStorage(false);
      this.loginStorage(true);
    },
    /**
     * 查看免责声明
     */
    lookStatement() {
      console.log(1234);
      this.disStatemenetData = true
    },
    // 注册按钮
    userRegister(formName) {
      // validate：验证当前表单元素中所有的规则
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.submitData();
        } else {
        }
      });
    },
    // 数据提交
    submitData() {
      this.$apis
        .register({
          mobile: this.ruleForm.mobile,
          password: this.ruleForm.password,
          sms_code: this.ruleForm.sms_code,
          name: this.ruleForm.mobile,
          unit_id: 1,
          register_channel:"门户网站用户"
        })
        .then(data => {

          if (data.data.status == 0) {
            this.$message({
              message: data.data.message,
              type: "success"
            });
          } else {
            this.reload();
          }
        })
        .catch(err => {});
    },
    // 发送验证码
    getRegister() {
      // 开启倒计时
      if (!validPhone(this.ruleForm.mobile)) {
        this.$message.error("手机号不能为空或不合法");
        return;
      } else {
        // 发送验证码请求
        this.$apis
          .note({
            action: "register",
            mobile: this.ruleForm.mobile
          })
          .then(data => {
            if (data.data.status == 0) {
              // 倒计时
              this.validateBtn();
              this.$message({
                message: "验证码发送成功",
                type: "success"
              });
            }

          })
          .catch(err => {});
      }
    },
    validateBtn() {
      //倒计时
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.disabled = false;
          this.btnTitle = "获取验证码";
        } else {
          this.btnTitle = time + "秒后重试";
          this.disabled = true;
          time--;
        }
      }, 1000);
    }
  },
  components: { disclaimer },
  computed: {
    ...mapState(["user", "token", "registerVisible"])
  }
};
</script>

<style lang="less" scoped>
.regBar {
  margin-top: 50px;
  .loginBar {
    font-size: 16px;
    width: 316px;
    height: 40px;
    border-radius: 4px;
    // background: linear-gradient(
    //   225deg,
    //   rgba(0, 110, 254, 1) 0%,
    //   rgba(73, 74, 255, 1) 70%
    // );
  }
}
/deep/.el-form-item.is-error.isNotError .el-input__inner {
  border: 1px solid #dcdfe6;
}
/deep/.el-form-item.is-error.isNotError {
  .el-form-item__error {
    display: none !important;
  }
}
.bodyBar {
  width: 358px;
  height: 410px;
  margin: 0 auto;
  text-align: center;
}
.loginLogo {
  float: left;
  width: 100%;
}
.loginTit {
  float: left;
  width: 100%;
  margin-top: 10px;
  .loginword {
    font-size: 16px;
    color: #3c4353;
  }
}
.getawaytit {
  float: left;
  width: 100%;
  margin-top: 12px;
  .getaword {
    font-size: 14px;
    color: #006efe;
  }
}
.loginInput {
  float: left;
  width: 100%;
  margin-top: 46px;
}
.demo-ruleForm {
  width: 316px;
  margin: 0px auto 37px auto;
}
.regInputBar {
  width: 316px;
  margin: 0px auto 25px auto;
}
.SignInBar {
  .loginBar {
    font-size: 16px;
    width: 316px;
    height: 40px;
    border-radius: 4px;
    background: linear-gradient(
      225deg,
      rgba(0, 110, 254, 1) 0%,
      rgba(73, 74, 255, 1) 70%
    );
  }
}
.footerBar {
  width: 370px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 50px;
}
.register {
  cursor: pointer;
  color: #006efe;
}
.dividing {
  height: 16px;
  width: 2px;
  background-color: #8099bb;
  margin: 0 20px 0 20px;
}
.forgetPsw {
  cursor: pointer;
  color: #006efe;
}
/deep/.el-dialog {
  width: 400px;
  border-radius: 8px;
  border: 1px solid #a4aab9;
  height: 610px !important;
}
.registerNav {
  // height: 590px;
  margin: 0 auto;
  text-align: center;
  width: 358px;
}
/deep/.el-dialog__body {
  padding: 10px 10px;
}
.disclaimer {
  /deep/.el-dialog{
    height: 285px !important;
  }
  /deep/.el-dialog__header{
    padding-bottom: 0px;
  }
  /deep/.el-dialog__footer {
    padding-top: 0;
  }
}
</style>
