var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "userBody" },
    [
      _c("navTop"),
      _vm._v(" "),
      _c("div", { staticClass: "bodyNav" }, [
        _c(
          "div",
          { staticClass: "ImgBar" },
          [
            _c(
              "div",
              { staticClass: "feimaBar" },
              [
                _c("span", { staticClass: "topTit" }, [_vm._v("当前在线")]),
                _vm._v(" "),
                _c("div", { staticClass: "topNum" }, [
                  _c("div", { staticClass: "NumOne" }, [
                    _c("span", { staticClass: "superWord" }, [
                      _vm._v(_vm._s(_vm.userStatisticsObj.online_admin_count)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "superBar" }, [
                      _vm._v("超级管理员"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "NumTwo" }, [
                    _c("span", { staticClass: "unitWord" }, [
                      _vm._v(_vm._s(_vm.userStatisticsObj.online_unit_count)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "unitBar" }, [
                      _vm._v("巡护管理员"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "NumThr" }, [
                    _c("span", { staticClass: "zdWord" }, [
                      _vm._v(_vm._s(_vm.userStatisticsObj.online_user_count)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "zhongduanBar" }, [
                      _vm._v("巡护终端用户"),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-divider"),
                _vm._v(" "),
                _c("div", { staticClass: "feimaBot" }, [
                  _c("div", { staticClass: "botTit" }, [_vm._v("用户总数")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "unit" }, [
                    _c("span", { staticClass: "unitname" }, [
                      _vm._v("巡护单位"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "unitCount" }, [
                      _vm._v(_vm._s(_vm.userStatisticsObj.unit_count)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "userNum" }, [
                    _c("span", { staticClass: "username" }, [
                      _vm._v("用户总数"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "userCount" }, [
                      _vm._v(_vm._s(_vm.userStatisticsObj.user_count)),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-divider", {
              staticClass: "line",
              attrs: { direction: "vertical" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "RtopBar" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("el-divider"),
            _vm._v(" "),
            _c("div", { attrs: { id: "userOption" } }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "midleBar" },
          [
            _vm._m(1),
            _vm._v(" "),
            _c("el-divider"),
            _vm._v(" "),
            _c("div", { attrs: { id: "ecahrtTwo" } }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "botBar" },
          [
            _vm._m(2),
            _vm._v(" "),
            _c("el-divider"),
            _vm._v(" "),
            _c("div", { attrs: { id: "echartThr" } }),
            _vm._v(" "),
            _c("div", { staticClass: "footerBox" }, [
              _c("div", { staticClass: "tableBox" }, [
                _c(
                  "table",
                  { staticClass: "tableData", staticStyle: { width: "100%" } },
                  [
                    _vm._m(3),
                    _vm._v(" "),
                    _vm._l(
                      this.userStatisticsObj.top_unit_data,
                      function (item, index) {
                        return _c(
                          "tr",
                          { key: index, staticClass: "tabletr" },
                          [
                            _c(
                              "td",
                              {
                                staticClass: "tableFile",
                                attrs: { align: "center", valign: "middle" },
                              },
                              [
                                _c("img", {
                                  staticClass: "feimatit",
                                  attrs: {
                                    src: require("../../assets/img/title.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(item.unit_name))]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.unit_code)),
                            ]),
                            _vm._v(" "),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.admin_count)),
                            ]),
                            _vm._v(" "),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.unit_count)),
                            ]),
                            _vm._v(" "),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.user_count)),
                            ]),
                            _vm._v(" "),
                            _c("td", { attrs: { align: "center" } }, [
                              _vm._v(_vm._s(item.group_count)),
                            ]),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                ),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Rtitle" }, [
      _c("span", { staticClass: "titleWord" }, [_vm._v("用户类型占比")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "midTitBar" }, [
      _c("span", { staticClass: "midTit" }, [
        _vm._v("月新增巡护单位/巡护终端用户"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "unitLine" }),
      _vm._v(" "),
      _c("span", { staticClass: "unitname" }, [_vm._v("巡护终端用户")]),
      _vm._v(" "),
      _c("div", { staticClass: "userLine" }),
      _vm._v(" "),
      _c("span", { staticClass: "username" }, [_vm._v("巡护单位")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "botTitBar" }, [
      _c("span", { staticClass: "botTit" }, [
        _vm._v("终端用户数量前十巡护单位分布表"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "tabTitle" }, [
      _c(
        "th",
        {
          staticClass: "tableTitle",
          staticStyle: { width: "30%" },
          attrs: { align: "center", valign: "middle" },
        },
        [_vm._v("\n                巡护单位\n              ")]
      ),
      _vm._v(" "),
      _c(
        "th",
        {
          staticClass: "tableTitle",
          staticStyle: { width: "20%" },
          attrs: { align: "center", valign: "middle" },
        },
        [_vm._v("\n                巡护代码\n              ")]
      ),
      _vm._v(" "),
      _c(
        "th",
        {
          staticClass: "tableTitle",
          staticStyle: { width: "10%" },
          attrs: { align: "center", valign: "middle" },
        },
        [_vm._v("\n                超级管理员\n              ")]
      ),
      _vm._v(" "),
      _c(
        "th",
        {
          staticClass: "tableTitle",
          staticStyle: { width: "10%" },
          attrs: { align: "center", valign: "middle" },
        },
        [_vm._v("\n                巡护管理员\n              ")]
      ),
      _vm._v(" "),
      _c(
        "th",
        {
          staticClass: "tableTitle",
          staticStyle: { width: "10%" },
          attrs: { align: "center", valign: "middle" },
        },
        [_vm._v("\n                巡护终端用户\n              ")]
      ),
      _vm._v(" "),
      _c(
        "th",
        {
          staticClass: "tableTitle",
          staticStyle: { width: "10%" },
          attrs: { align: "center", valign: "middle" },
        },
        [_vm._v("\n                任务群组\n              ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }