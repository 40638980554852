var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "100%", height: "70px" } }, [
    _c(
      "div",
      { staticClass: "Navbar" },
      [
        _c(
          "div",
          { staticClass: "grid-content bg-purple" },
          [
            _c("div", { staticClass: "TitleBar", on: { click: _vm.GoHome } }, [
              _c("div", { staticClass: "imgBar" }),
              _vm._v(" "),
              _vm._m(0),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "navmenu" },
              [
                _c(
                  "el-menu",
                  {
                    staticClass: "el-menu-vertical-demo",
                    staticStyle: { width: "730px" },
                    attrs: {
                      "default-active": _vm.$route.path,
                      "text-color": "#fff",
                      "active-text-color": "#fff",
                      mode: "horizontal",
                    },
                    on: { select: _vm.handleSelectMenu },
                  },
                  [
                    _c(
                      "el-menu-item",
                      {
                        staticClass: "fmHover",
                        class: { fmHoverBgc: _vm.currentUrl[1] === "首页" },
                        attrs: { index: "/home" },
                        on: { click: _vm.GoHome },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "menuTitle",
                            attrs: { slot: "title" },
                            slot: "title",
                          },
                          [_vm._v("火情监测")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        staticClass: "fmHover",
                        class: { fmHoverBgc: _vm.currentUrl[1] === "任务管理" },
                        attrs: { index: "/taskCoordination" },
                        on: {
                          click: function ($event) {
                            return _vm.leftNav(9)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "menuTitle",
                            attrs: { slot: "title" },
                            slot: "title",
                          },
                          [_vm._v("任务管理")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        staticClass: "fmHover",
                        class: { fmHoverBgc: _vm.currentUrl[1] === "应急装备" },
                        attrs: { index: "/deviceManagement?type=uav" },
                        on: {
                          click: function ($event) {
                            return _vm.leftNav(1)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "menuTitle",
                            attrs: { slot: "title" },
                            slot: "title",
                          },
                          [_vm._v("装备管理")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        staticClass: "fmHover",
                        class: { fmHoverBgc: _vm.currentUrl[1] === "用户管理" },
                        attrs: { index: "/alliance" },
                        on: {
                          click: function ($event) {
                            return _vm.leftNav(2)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "menuTitle",
                            attrs: { slot: "title" },
                            slot: "title",
                          },
                          [_vm._v("用户管理")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        staticClass: "fmHover",
                        staticStyle: { position: "relative" },
                      },
                      [
                        _c("div", { staticClass: "open-menu-box" }, [
                          _c(
                            "span",
                            {
                              staticClass: "menuTitle",
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [_vm._v("火情数据")]
                          ),
                          _vm._v(" "),
                          _c("i", {
                            class: [
                              _vm.isOpenMenuCard
                                ? "el-icon-caret-top"
                                : "el-icon-caret-bottom",
                            ],
                            staticStyle: {
                              position: "absolute",
                              top: "25%",
                              right: "0",
                            },
                          }),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isOpenMenuCard,
                            expression: "isOpenMenuCard",
                          },
                        ],
                        staticClass: "open-menu-card",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            on: {
                              click: function ($event) {
                                return _vm.handleSelectChildMenu(
                                  "achievement",
                                  3
                                )
                              },
                            },
                          },
                          [_vm._v("\n              数据查看\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            on: {
                              click: function ($event) {
                                return _vm.handleSelectChildMenu(
                                  "airspaceQuery",
                                  4
                                )
                              },
                            },
                          },
                          [_vm._v("\n              空域管理\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "item",
                            on: {
                              click: function ($event) {
                                return _vm.handleSelectChildMenu(
                                  "/flystat?nav=flysta",
                                  5
                                )
                              },
                            },
                          },
                          [_vm._v("\n              每日报告\n            ")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isOpenMenuCard,
                          expression: "isOpenMenuCard",
                        },
                      ],
                      staticClass: "open-menu-mask",
                      on: {
                        click: function ($event) {
                          _vm.isOpenMenuCard = false
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "userInform" }, [
              _vm.user.permissions &&
              _vm.user.permissions.indexOf("cloud.server.config") === -1
                ? _c(
                    "div",
                    {
                      staticClass: "nav-link",
                      staticStyle: { opacity: "0.4" },
                      attrs: {
                        title:
                          "您不具有数据云处理的权限，请联系超级管理员开通此权限。",
                      },
                      on: { click: _vm.toDataCloud },
                    },
                    [_c("div", { staticClass: "colony" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { on: { click: _vm.newBtn } }, [
                _c(
                  "div",
                  { staticClass: "news" },
                  [
                    _c("el-badge", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.unreadNoticeNum != 0,
                          expression: "unreadNoticeNum != 0",
                        },
                      ],
                      staticClass: "mark",
                      attrs: { value: _vm.unreadNoticeNum },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "userNav", on: { click: _vm.showUser } },
                [
                  _c("avatar", {
                    staticClass: "avatar",
                    attrs: { avatar: _vm.user.photo_url },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "userName",
                      attrs: { title: _vm.user.name },
                    },
                    [_vm._v(_vm._s(_vm.user.name))]
                  ),
                  _vm._v(" "),
                  _vm.user.is_admin == 1
                    ? _c("i", { staticClass: "iconfont icon-VIP" }, [
                        _vm._v(""),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user.is_admin == 0 && _vm.user.is_unit == 1
                    ? _c("i", { staticClass: "iconfont icon-pre" }, [
                        _vm._v(""),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                staticClass: "userBox",
                attrs: {
                  modal: false,
                  "show-close": false,
                  visible: _vm.dialogVisible,
                  width: "230px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogVisible = $event
                  },
                },
              },
              [
                _c("div", { staticClass: "biguserInfor" }, [
                  _c("div", { staticClass: "userInfor" }, [
                    _c(
                      "div",
                      { staticClass: "userAva" },
                      [
                        _c("avatar", {
                          attrs: {
                            avatar: _vm.user.photo_url,
                            width: 60,
                            height: 60,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "userIdentity" }, [
                      _c("span", { staticClass: "usernames" }, [
                        _vm._v(_vm._s(_vm.user.name)),
                      ]),
                      _vm._v(" "),
                      _vm.user.is_admin == 1
                        ? _c("i", { staticClass: "iconfont userIcons" }, [
                            _vm._v(""),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.user.is_admin == 0 && _vm.user.is_unit == 1
                        ? _c("i", { staticClass: "iconfont userIconsone" }, [
                            _vm._v(""),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm.user.is_admin == 1
                      ? _c("div", { staticClass: "unitBtn" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(this.overpipe) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.is_admin == 0 && _vm.user.is_unit == 1
                      ? _c("div", { staticClass: "userBtn" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(this.unionpipe) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.is_admin == 0 && _vm.user.is_unit == 0
                      ? _c("div", { staticClass: "enduser" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(this.terminaluser) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "userBar" }, [
                  _c("div", { staticClass: "personal users" }, [
                    _c(
                      "div",
                      {
                        staticClass: "feimaBar",
                        on: {
                          click: function ($event) {
                            return _vm.personal(6)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "iconfont usercenter" }, [
                          _vm._v(""),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "saysever" }, [
                          _vm._v("个人中心"),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.role == 0
                    ? _c(
                        "div",
                        {
                          staticClass: "permission users",
                          on: { click: _vm.Jurisdiction },
                        },
                        [
                          _c("div", { staticClass: "feimaBar" }, [
                            _c(
                              "i",
                              { staticClass: "iconfont permissionIcon" },
                              [_vm._v("")]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "saysever" }, [
                              _vm._v("权限配置"),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "loginout users",
                      on: { click: _vm.loginOut },
                    },
                    [
                      _c("div", { staticClass: "feimaBar" }, [
                        _c("i", { staticClass: "iconfont loginoutIcon" }, [
                          _vm._v(""),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "saysever" }, [
                          _vm._v("退出登录"),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
            staticClass: "chat-add-dialog",
            attrs: {
              modal: false,
              "lock-scroll": false,
              visible: _vm.chatAddDialog.show,
            },
            on: {
              "update:visible": function ($event) {
                return _vm.$set(_vm.chatAddDialog, "show", $event)
              },
            },
          },
          [_c("chatAdd", { key: _vm.chatAddDialog.key, ref: "chatAdd" })],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            directives: [
              { name: "dialogDragCustom", rawName: "v-dialogDragCustom" },
            ],
            staticClass: "chat",
            attrs: {
              modal: false,
              "append-to-body": false,
              "modal-append-to-body": false,
              "close-on-click-modal": false,
              "close-on-press-escape": false,
              "lock-scroll": false,
              visible: _vm.chatDialog.show,
            },
            on: {
              "update:visible": function ($event) {
                return _vm.$set(_vm.chatDialog, "show", $event)
              },
              close: _vm.closeChatDialog,
            },
          },
          [_c("chat", { key: _vm.chatDialog.key, ref: "chat" })],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            staticClass: "media-request-dialog",
            attrs: {
              modal: false,
              "lock-scroll": false,
              "modal-append-to-body": true,
              "show-close": false,
              "close-on-click-modal": false,
              "close-on-press-escape": false,
              visible: _vm.mediaRequestDialog.show,
            },
            on: {
              "update:visible": function ($event) {
                return _vm.$set(_vm.mediaRequestDialog, "show", $event)
              },
            },
          },
          [
            _c("div", { staticClass: "media-request" }, [
              _c(
                "div",
                { staticClass: "head" },
                [
                  _c("avatar", {
                    staticClass: "avatar",
                    attrs: { avatar: _vm.mediaRequestDialog.photo },
                  }),
                  _vm._v(" "),
                  _c("div", { staticStyle: { "margin-left": "30px" } }, [
                    _vm._v(_vm._s(_vm.mediaRequestDialog.name)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "foot" }, [
                _c("img", {
                  attrs: { src: require("@/assets/img/chat/hangup.png") },
                  on: { click: _vm.hangUpMedia },
                }),
                _vm._v(" "),
                _c("img", {
                  attrs: { src: require("@/assets/img/chat/answer.png") },
                  on: { click: _vm.answerMedia },
                }),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            directives: [
              { name: "dialogDragCustom", rawName: "v-dialogDragCustom" },
            ],
            ref: "mediasoupDialog",
            staticClass: "mediasoup-dialog",
            attrs: {
              modal: false,
              "append-to-body": false,
              "close-on-click-modal": false,
              "close-on-press-escape": false,
              visible: _vm.mediaDialog.show,
            },
            on: {
              "update:visible": function ($event) {
                return _vm.$set(_vm.mediaDialog, "show", $event)
              },
            },
          },
          [
            _c("template", { slot: "title" }, [
              _c("div", { staticClass: "el-dialog-custom-header" }, [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.mediasoupDialogEnlarge,
                      expression: "!mediasoupDialogEnlarge",
                    },
                  ],
                  attrs: { src: require("@/assets/img/chat/enlarge.png") },
                  on: { click: _vm.enlargeMedia },
                }),
                _vm._v(" "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.mediasoupDialogEnlarge,
                      expression: "mediasoupDialogEnlarge",
                    },
                  ],
                  attrs: {
                    src: require("@/assets/img/chat/enlarge_reset.png"),
                  },
                  on: { click: _vm.enlargeMedia },
                }),
              ]),
            ]),
            _vm._v(" "),
            _vm.mediaDialog.show
              ? _c(
                  "iframe",
                  {
                    staticClass: "media-iframe",
                    attrs: {
                      src: _vm.mediaDialog.url,
                      allow:
                        "geolocation; microphone; camera; midi; encrypted-media;",
                    },
                  },
                  [_vm._v("您的浏览器版本过低 请切换至火狐或谷歌浏览器")]
                )
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c("NewViews", { ref: "newview" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "selectIntranetOrExtranet" },
          [
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "提示",
                  visible: _vm.toDataCloudDialogVisible,
                  width: "25%",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.toDataCloudDialogVisible = $event
                  },
                },
              },
              [
                _c("div", { staticClass: "text" }, [
                  _c("span", { staticClass: "el-icon-warning icon-warning" }),
                  _vm._v("请选择内网用户或外网用户?\n        "),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "selectBut" },
                  [
                    _c("el-button", { on: { click: _vm.openExtranet } }, [
                      _vm._v("外网"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.openIntranet },
                      },
                      [_vm._v("内网")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titleBar" }, [
      _c("span", { staticClass: "top-title" }, [
        _vm._v("无人机巡护指挥调度管理平台"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }