<template>
  <div class="bottomBar">
    <div class="item" @click="selectDifferentImg(3)">
      <div
        class="imgs"
        :class="[
          selectImg === 3
            ? 'selectItemOnlineDevicesImg'
            : 'itemOnlineDevicesImg'
        ]"
      ></div>
      <div>
        <div>在线设备</div>
        <div class="number">{{ datas.onlineTotal }}</div>
      </div>
    </div>
    <div class="item" @click="selectDifferentImg(2)">
      <div
        class="imgs"
        :class="[
          selectImg === 2
            ? 'selectItemNetworkDeviceImg'
            : 'itemNetworkDeviceImg'
        ]"
      ></div>
      <div>
        <div>联网设备</div>
        <div class="number">{{ datas.networkingTotal }}</div>
      </div>
    </div>

    <div class="item" @click="selectDifferentImg(4)">
      <div
        class="imgs"
        :class="[selectImg === 4 ? 'selectItemImg' : 'itemImg']"
      ></div>
      <div>
        <div>任务数量</div>
        <div class="number">{{ datas.taskTotal }}</div>
      </div>
    </div>
    <div class="item" @click="selectDifferentImg(1)">
      <div
        class="imgs"
        :class="[selectImg === 1 ? 'selectItemAreaImg' : 'itemAreaImg']"
      ></div>
      <div>
        <div>飞行面积</div>
        <div class="number">{{ datas.flyAreaTotal }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  props: {
    datas: {
      type: Object,
      default: () => {
        return {
          flyAreaTotal: 2000,
          networkingTotal: 2000,
          onlineTotal: 2340,
          taskTotal: 2000
        };
      }
    },
    selected: {
      type: Number,
      default: -1
    }
  },
  watch: {
    selected: {
      immediate: true,
      handler: "selectedChange"
    }
  },
  data() {
    return {
      selectImg: -1,
      flyAreaTotal: 2000,
      networkingTotal: 2000,
      onlineTotal: 2340,
      taskTotal: 2000
    };
  },
  mounted() {
    // this.getTaskTotal();
  },
  computed: {
    ...mapState(["token", "user"])
  },
  methods: {
    selectDifferentImg(index) {
      if (this.selectImg !== index) {
        this.selectImg = index;
      } else {
        this.selectImg = -1;
      }

      this.$emit("showDio", index);
    },
    selectedChange() {
      this.selectImg = this.selected;
    },
    getTaskTotal() {
      let nowDate = this.utils.getCurrentTime();
      console.log(nowDate);
      this.$apis
        .getTaskData(
          {
            type: "project",
            start_datetime: "2021-01-01 00:00:00",
            end_datetime: nowDate,
            month_data_start_date: "2021-01-01 00:00:00",
            month_data_end_date: nowDate
          },
          this.token
        )
        .then(data => {
          console.log(data);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }
};
</script>
<style lang="scss">
.bottomBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .imgs {
    width: 114px;
    height: 84px;
    background-size: 100% 100%;
  }
  .itemImg {
    background: url("~@/assets/img/centerHome/task.png") center no-repeat;
    background-size: 100% 100%;
    &:hover {
      background: url("~@/assets/img/centerHome/selectTask.png") center
        no-repeat;
      background-size: 100% 100%;
    }
  }
  .selectItemImg {
    background: url("~@/assets/img/centerHome/selectTask.png") center no-repeat;
    background-size: 100% 100%;
  }
  .itemNetworkDeviceImg {
    background: url("~@/assets/img/centerHome/networkingDevices.png") center
      no-repeat;
    background-size: 100% 100%;
    &:hover {
      background: url("~@/assets/img/centerHome/selectNetworkingDevices.png")
        center no-repeat;
      background-size: 100% 100%;
    }
  }
  .selectItemNetworkDeviceImg {
    background: url("~@/assets/img/centerHome/selectNetworkingDevices.png")
      center no-repeat;
    background-size: 100% 100%;
  }
  .itemOnlineDevicesImg {
    background: url("~@/assets/img/centerHome/onlineDevices.png") center
      no-repeat;
    background-size: 100% 100%;
    &:hover {
      background: url("~@/assets/img/centerHome/selectOnlineDevices.png") center
        no-repeat;
      background-size: 100% 100%;
    }
  }
  .selectItemOnlineDevicesImg {
    background: url("~@/assets/img/centerHome/selectOnlineDevices.png") center
      no-repeat;
    background-size: 100% 100%;
  }
  .itemAreaImg {
    background: url("~@/assets/img/centerHome/flyArea.png") center no-repeat;
    background-size: 100% 100%;
    &:hover {
      background: url("~@/assets/img/centerHome/selectflyArea.png") center
        no-repeat;
      background-size: 100% 100%;
    }
  }
  .selectItemAreaImg {
    background: url("~@/assets/img/centerHome/selectflyArea.png") center
      no-repeat;
    background-size: 100% 100%;
  }
  .item {
    // display: flex;
    // align-items: center;
    text-align: center;
    color: #0087ff;
    font-size: 20px;
    // margin-right: 72px;
    cursor: pointer;
    .number {
      font-size: 40px;
      font-family: "DIN";
    }
  }
}
</style>
