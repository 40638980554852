// 巡护相关接口
import {
  fly,
  baseURL,
  start,
  finishRes,
  finishErr,
  deploy
} from '../fly.js'

// 添加请求拦截器
fly.interceptors.request.use(
  (request) => {
    start(request)
  }
)

// 添加响应拦截器，响应拦截器会在then/catch处理之前执行
fly.interceptors.response.use(
  (response) => {
    finishRes(response)
  },
  (err) => {
    finishErr(err)
  }
)

//  获取巡护列表接口
export const unitLists = data => fly.post(
  baseURL + '/unit/list',
  data, deploy(30000, '')
)

//  获取巡护列表接口
export const unitList = (data, token, page) => fly.post(
  baseURL + '/unit/list' + page,
  data, deploy(30000, token)
)

//  排行榜
export const ranking = (data, token) => fly.post(
  baseURL + '/report/getUserRanking',
  data, deploy(30000, token)
)

//  巡护代码登录
export const loginByCode = data => fly.post(
  baseURL + '/unit/loginByCode',
  data, deploy(30000, "")
)

//  获取巡护用户列表接口
export const unituserList = (data, token) => fly.get(
  baseURL + '/unituser/list',
  data, deploy(30000, token)
)

//  获取门户用户列表
export const getPortal = (data, token) => fly.post(
  baseURL + '/user/getPortal',
  data, deploy(30000, token)
)

//  新建巡护用户
export const addunituser = (data, token) => fly.post(
  baseURL + '/unituser/add',
  data, deploy(30000, token)
)

//  删除巡护用户
export const delunituser = (data, token) => fly.post(
  baseURL + '/unituser/del',
  data, deploy(30000, token)
)

//  删除门户用户
export const userDelPortal = (data, token) => fly.post(
  baseURL + '/user/delPortal',
  data, deploy(30000, token)
)


//  编辑巡护单位
export const edit = (data, token) => fly.post(
  baseURL + '/unit/edit',
  data, deploy(30000, token)
)

//  删除巡护单位
export const delunit = (data, token) => fly.post(
  baseURL + '/unit/del',
  data, deploy(30000, token)
)

//  设置是否为超管
export const setsuper = (data, token) => fly.post(
  baseURL + '/unituser/set',
  data, deploy(30000, token)
)

//  账户权限设置_差异化
export const setChild = (data, token) => fly.post(
  baseURL + '/unituser/setChild',
  data, deploy(30000, token)
)


//  重设密码
export const resetPsw = (data, unittoken) => fly.post(
  baseURL + '/unit/reset',
  data, {
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    unittoken: unittoken
  },
  parseJson: true,
}
)



//  添加巡护绑定
export const addManager = (data, unittoken) => fly.post(
  baseURL + '/unit/addManager',
  data, {
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    unittoken: unittoken
  },
  parseJson: true,
}
)

//  添加巡护绑定
export const listManager = (data, unittoken) => fly.get(
  baseURL + '/unit/listManager',
  data, {
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    unittoken: unittoken
  },
  parseJson: true,
}
)

//  删除巡护管理员
export const delManager = (data, unittoken) => fly.post(
  baseURL + '/unit/delManager',
  data, {
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    unittoken: unittoken
  },
  parseJson: true,
}
)

// 巡护列表
export const listForUnitBind = (data, unittoken) => fly.post(
  baseURL + '/unituser/listForUnitBind',
  data, {
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    unittoken: unittoken
  },
  parseJson: true,
}
)

//  新建巡护
export const add = (data, token) => fly.post(
  baseURL + '/unit/add',
  data, {
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    token: token
  },
  parseJson: true,
}
)


export const exportPortal = (data, token) => fly.post(
  baseURL + '/user/exportPortal',
  data, {
  timeout: 30000,
  headers: {
    "content-type": "application/json",
    token: token,
  },
  responseType: 'blob',
  parseJson: true,
}
)

export const exportUnit = (data, token) => fly.post(
  baseURL + '/unit/export',
  data, {
  timeout: 30000,
  headers: {
    "content-type": "application/json",
    token: token,
  },
  responseType: 'blob',
  parseJson: true,
}
)
export const exportUnitUser = (data, token) => fly.post(
  baseURL + '/unituser/export',
  data, {
  timeout: 30000,
  headers: {
    "content-type": "application/json",
    token: token,
  },
  responseType: 'blob',
  parseJson: true,
}
)
export const exportDevice = (data, token) => fly.post(
  baseURL + '/device/exportDevice',
  data, {
  timeout: 30000,
  headers: {
    "content-type": "application/json",
    token: token,
  },
  responseType: 'blob',
  parseJson: true,
}
)
