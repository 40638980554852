<template>
  <div :class="_module">
    <div class="status setLineHeight">
      <div class="setDisplay">
        <span class="deviceTitle">设备状态</span>
        <span class="deviceData" v-if="terminal.status === 0">正常</span>
        <span class="deviceData" v-else-if="terminal.status === 1">故障</span>
        <span class="deviceData" v-else-if="terminal.status === 2">设备维修</span>
        <span class="deviceData" v-else-if="terminal.status === 3">设备遗失</span>
        <span class="deviceData" v-else>设备报废</span>
      </div>
      <div class="setDisplay">
        <span class="deviceTitle">归属部门</span
        ><span
          v-if="terminal.holder_dept !== ''"
          class="deviceData"
          style="width: 50px"
          :title="terminal.holder_dept"
          >{{ terminal.holder_dept }}</span
        >
        <span v-else class="deviceData">无</span>
      </div>
    </div>

    <div class="setLineHeight">
      <span class="deviceTitle">巡护单位</span
      ><span class="deviceData" :title="terminal.unit_name" style="width: 190px">{{
        terminal.unit_name | filterData
      }}</span>
    </div>
    <div class="setLineHeight">
      <span class="deviceTitle" style="margin-right: 25px">持有人</span
      ><span class="deviceData" style="width: 85px" :title="terminal.name">{{
        terminal.holder_name | filterData
      }}</span
      ><span class="phone">{{ terminal.mobile | filterMobile }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "deviceDataBlock",
  props: {
    terminal: {
      type: Object,
    },
    setStyle: {
      type: Boolean,
      default: false,
    },
    blockSetStyle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  filters: {
    filterData(val) {
      return val !== "" ? val : "无";
    },
    filterMobile(val) {
      return val !== "" ? val : "";
    },
  },
  mounted() {
    if (this.blockSetStyle) {
      let title = document.getElementsByClassName("deviceTitle");
      for (let i = 0; i < title.length; i++) {
        title[i].style.color = "#666666";
        title[i].style.fontSize = "13px";
      }
      let data = document.getElementsByClassName("deviceData");
      for (let i = 0; i < data.length; i++) {
        data[i].style.color = "#333333";
        data[i].style.fontSize = "13px";
      }
    }

    // console.log(title.length);
  },
  computed: {
    _module: {
      get: function () {
        if (this.setStyle) {
          // console.log(1234);
          return "";
        } else {
          console.log(this.blockSetStyle);
          if (this.blockSetStyle) {
            return "blockTaskStyle setSnIDPaddingBlock";
          } else {
            return "information setSnIDPadding";
          }
        }
      },
    },
  },
};
</script>
<style lang="scss">
.setSnIDPadding {
  padding-left: 30px;
  padding-right: 19px;
}
.setSnIDPaddingBlock {
  padding: 10px;
  padding-left: 30px;
  padding-right: 19px;
}
.information {
  background: #000d1c;
  height: 117px;
  padding-top: 13px;
}
.blockTaskStyle {
  background: #fff !important;
}
.setLineHeight {
  line-height: 27px;
  display: flex;
}
.status {
  display: flex;
  justify-content: space-between;
}
.deviceTitle {
  color: #69789e;
  margin-right: 12px;
}
.deviceData {
  color: #007bf7;
  overflow: hidden; /*超出部分隐藏*/
  text-overflow: ellipsis; /* 超出部分显示省略号 */
  white-space: nowrap; /*规定段落中的文本不进行换行 */
  display: inline-block;
  // width: 22px;
}
.phone {
  color: #ffba00;
  text-decoration: underline;
  margin-left: 11px;
}
.setDisplay {
  display: flex;
  align-items: center;
}
</style>
