var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.setVisible,
          expression: "setVisible",
        },
      ],
      staticClass: "setBar",
    },
    [
      _c("div", { staticStyle: { position: "relative" } }, [
        _c("label", { staticClass: "allselect" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.feimaVisible,
                expression: "feimaVisible",
              },
            ],
            staticClass: "selectInp",
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.feimaVisible)
                ? _vm._i(_vm.feimaVisible, null) > -1
                : _vm.feimaVisible,
            },
            on: {
              click: function ($event) {
                return _vm.hideOrshowMassMaker("feima", _vm.feimaVisible)
              },
              change: function ($event) {
                var $$a = _vm.feimaVisible,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.feimaVisible = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.feimaVisible = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.feimaVisible = $$c
                }
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "circle" }),
          _vm._v(" "),
          _c("span", { staticClass: "flytit" }, [_vm._v("飞马无人机")]),
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "select" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.onlineFeimaMarkerVisible,
                expression: "onlineFeimaMarkerVisible",
              },
            ],
            staticClass: "selectInp",
            attrs: { type: "checkbox", id: "Runoob", value: "正在飞行" },
            domProps: {
              checked: Array.isArray(_vm.onlineFeimaMarkerVisible)
                ? _vm._i(_vm.onlineFeimaMarkerVisible, "正在飞行") > -1
                : _vm.onlineFeimaMarkerVisible,
            },
            on: {
              click: function ($event) {
                return _vm.hideOrshowMassMaker(
                  "onlineFeima",
                  _vm.onlineFeimaMarkerVisible
                )
              },
              change: function ($event) {
                var $$a = _vm.onlineFeimaMarkerVisible,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = "正在飞行",
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      (_vm.onlineFeimaMarkerVisible = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.onlineFeimaMarkerVisible = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.onlineFeimaMarkerVisible = $$c
                }
              },
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "flystatus",
              style: {
                color: _vm.onlineFeimaMarkerVisible
                  ? "rgb(50, 247, 147)"
                  : "rgb(164, 174, 226)",
              },
            },
            [_vm._v("正在飞行(" + _vm._s(_vm.feimaOnlineCount) + ")")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: { position: "absolute", bottom: "0px", left: "20px" },
            on: {
              click: function ($event) {
                return _vm.showFilterForm("feima")
              },
            },
          },
          [_c("i", { staticClass: "iconfont zdhistory" }, [_vm._v("")])]
        ),
        _vm._v(" "),
        _c("label", { staticClass: "select" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.historyFeimaMarkerVisible,
                expression: "historyFeimaMarkerVisible",
              },
            ],
            staticClass: "historyInp",
            attrs: { type: "checkbox", id: "Google", value: "历史飞行" },
            domProps: {
              checked: Array.isArray(_vm.historyFeimaMarkerVisible)
                ? _vm._i(_vm.historyFeimaMarkerVisible, "历史飞行") > -1
                : _vm.historyFeimaMarkerVisible,
            },
            on: {
              click: function ($event) {
                return _vm.hideOrshowMassMaker(
                  "historyFeima",
                  _vm.historyFeimaMarkerVisible
                )
              },
              change: function ($event) {
                var $$a = _vm.historyFeimaMarkerVisible,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = "历史飞行",
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      (_vm.historyFeimaMarkerVisible = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.historyFeimaMarkerVisible = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.historyFeimaMarkerVisible = $$c
                }
              },
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "flystatus",
              style: {
                color: _vm.historyFeimaMarkerVisible
                  ? "rgb(50, 247, 147)"
                  : "rgb(164, 174, 226)",
              },
            },
            [_vm._v("历史飞行(" + _vm._s(_vm.feimaHistoryFliterCount) + ")")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { clear: "both" } }),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { position: "relative" } }, [
        _c("label", { staticClass: "universal" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.commonVisible,
                expression: "commonVisible",
              },
            ],
            staticClass: "univerInp",
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.commonVisible)
                ? _vm._i(_vm.commonVisible, null) > -1
                : _vm.commonVisible,
            },
            on: {
              click: function ($event) {
                return _vm.hideOrshowMassMaker("common", _vm.commonVisible)
              },
              change: function ($event) {
                var $$a = _vm.commonVisible,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.commonVisible = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.commonVisible = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.commonVisible = $$c
                }
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "circleOne" }),
          _vm._v(" "),
          _c("span", { staticClass: "univerfly" }, [_vm._v("通用无人机")]),
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "select" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.onlineCommonMarkerVisible,
                expression: "onlineCommonMarkerVisible",
              },
            ],
            staticClass: "selectInp",
            attrs: { type: "checkbox", id: "Runoob", value: "正在飞行" },
            domProps: {
              checked: Array.isArray(_vm.onlineCommonMarkerVisible)
                ? _vm._i(_vm.onlineCommonMarkerVisible, "正在飞行") > -1
                : _vm.onlineCommonMarkerVisible,
            },
            on: {
              click: function ($event) {
                return _vm.hideOrshowMassMaker(
                  "onlineCommon",
                  _vm.onlineCommonMarkerVisible
                )
              },
              change: function ($event) {
                var $$a = _vm.onlineCommonMarkerVisible,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = "正在飞行",
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      (_vm.onlineCommonMarkerVisible = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.onlineCommonMarkerVisible = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.onlineCommonMarkerVisible = $$c
                }
              },
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "flystatus",
              style: {
                color: _vm.onlineCommonMarkerVisible
                  ? "rgb(73, 178, 255)"
                  : "rgb(164, 174, 226)",
              },
            },
            [_vm._v("正在飞行(" + _vm._s(_vm.commonOnlineCount) + ")")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: { position: "absolute", bottom: "0px", left: "20px" },
            on: {
              click: function ($event) {
                return _vm.showFilterForm("common")
              },
            },
          },
          [_c("i", { staticClass: "iconfont zdhistory" }, [_vm._v("")])]
        ),
        _vm._v(" "),
        _c("label", { staticClass: "select" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.historyCommonMarkerVisible,
                expression: "historyCommonMarkerVisible",
              },
            ],
            staticClass: "historyInp",
            attrs: { type: "checkbox", id: "Google", value: "历史飞行" },
            domProps: {
              checked: Array.isArray(_vm.historyCommonMarkerVisible)
                ? _vm._i(_vm.historyCommonMarkerVisible, "历史飞行") > -1
                : _vm.historyCommonMarkerVisible,
            },
            on: {
              click: function ($event) {
                return _vm.hideOrshowMassMaker(
                  "historyCommon",
                  _vm.historyCommonMarkerVisible
                )
              },
              change: function ($event) {
                var $$a = _vm.historyCommonMarkerVisible,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = "历史飞行",
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      (_vm.historyCommonMarkerVisible = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.historyCommonMarkerVisible = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.historyCommonMarkerVisible = $$c
                }
              },
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "flystatus",
              style: {
                color: _vm.historyCommonMarkerVisible
                  ? "rgb(73, 178, 255)"
                  : "rgb(164, 174, 226)",
              },
            },
            [_vm._v("历史飞行(" + _vm._s(_vm.commonHistoryFliterCount) + ")")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { clear: "both" } }),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { position: "relative" } }, [
        _c("label", { staticClass: "teamTerminal" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.terminalVisible,
                expression: "terminalVisible",
              },
            ],
            staticClass: "teamInp",
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.terminalVisible)
                ? _vm._i(_vm.terminalVisible, null) > -1
                : _vm.terminalVisible,
            },
            on: {
              click: function ($event) {
                return _vm.hideOrshowMassMaker("terminal", _vm.terminalVisible)
              },
              change: function ($event) {
                var $$a = _vm.terminalVisible,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.terminalVisible = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.terminalVisible = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.terminalVisible = $$c
                }
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "circleTwo" }),
          _vm._v(" "),
          _c("span", { staticClass: "teamfly" }, [_vm._v("林业巡护")]),
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "select" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.onlineTerminalMarkerVisible,
                expression: "onlineTerminalMarkerVisible",
              },
            ],
            staticClass: "selectInp",
            attrs: {
              checked: "",
              type: "checkbox",
              id: "Runoob",
              value: "在线终端",
            },
            domProps: {
              checked: Array.isArray(_vm.onlineTerminalMarkerVisible)
                ? _vm._i(_vm.onlineTerminalMarkerVisible, "在线终端") > -1
                : _vm.onlineTerminalMarkerVisible,
            },
            on: {
              click: function ($event) {
                return _vm.hideOrshowMassMaker(
                  "onlineTerminal",
                  _vm.onlineTerminalMarkerVisible
                )
              },
              change: function ($event) {
                var $$a = _vm.onlineTerminalMarkerVisible,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = "在线终端",
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      (_vm.onlineTerminalMarkerVisible = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.onlineTerminalMarkerVisible = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.onlineTerminalMarkerVisible = $$c
                }
              },
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "flystatus",
              style: {
                color: _vm.onlineTerminalMarkerVisible
                  ? "rgb(251, 68, 57)"
                  : "rgb(164, 174, 226)",
              },
            },
            [_vm._v("在线终端(" + _vm._s(_vm.terminalOnlineCount) + ")")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "trajectoryBar" },
          [
            _c("span", { staticClass: "trajectory" }, [_vm._v("移动轨迹")]),
            _vm._v(" "),
            _c("el-switch", {
              staticClass: "switchs",
              on: { change: _vm.changeOnlineTermianalTrackVisible },
              model: {
                value: _vm.onlineTerminalTrackVisible,
                callback: function ($$v) {
                  _vm.onlineTerminalTrackVisible = $$v
                },
                expression: "onlineTerminalTrackVisible",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: { position: "absolute", bottom: "0px", left: "20px" },
            on: {
              click: function ($event) {
                return _vm.showFilterForm("terminal")
              },
            },
          },
          [_c("i", { staticClass: "iconfont zdhistory" }, [_vm._v("")])]
        ),
        _vm._v(" "),
        _c("label", { staticClass: "select" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.historyTerminalMarkerVisible,
                expression: "historyTerminalMarkerVisible",
              },
            ],
            staticClass: "historyInp",
            attrs: { type: "checkbox", id: "Google", value: "历史终端" },
            domProps: {
              checked: Array.isArray(_vm.historyTerminalMarkerVisible)
                ? _vm._i(_vm.historyTerminalMarkerVisible, "历史终端") > -1
                : _vm.historyTerminalMarkerVisible,
            },
            on: {
              click: function ($event) {
                return _vm.hideOrshowMassMaker(
                  "historyTerminal",
                  _vm.historyTerminalMarkerVisible
                )
              },
              change: function ($event) {
                var $$a = _vm.historyTerminalMarkerVisible,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = "历史终端",
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      (_vm.historyTerminalMarkerVisible = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.historyTerminalMarkerVisible = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.historyTerminalMarkerVisible = $$c
                }
              },
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "flystatus",
              style: {
                color: _vm.historyTerminalMarkerVisible
                  ? "rgb(251, 68, 57)"
                  : "rgb(164, 174, 226)",
              },
            },
            [_vm._v("历史终端(" + _vm._s(_vm.terminalHistoryFliterCount) + ")")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { clear: "both" } }),
      ]),
      _vm._v(" "),
      _c("el-divider", { staticClass: "hhh" }),
      _vm._v(" "),
      _c("div", { staticClass: "switchGroup" }, [
        _c(
          "div",
          { staticClass: "topSwitch" },
          [
            _c("span", { staticClass: "gaudMap" }, [_vm._v("默认地图")]),
            _vm._v(" "),
            _c("el-switch", {
              staticClass: "switchTurn",
              attrs: { disabled: _vm.isGuideMap },
              on: { change: _vm.guideMap },
              model: {
                value: _vm.isGuideMap,
                callback: function ($$v) {
                  _vm.isGuideMap = $$v
                },
                expression: "isGuideMap",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "midSwitch" },
          [
            _c("span", { staticClass: "skyMap" }, [_vm._v("天地图")]),
            _vm._v(" "),
            _c("el-switch", {
              staticClass: "switchTurn",
              attrs: { disabled: _vm.isSkyMap },
              on: { change: _vm.skyMap },
              model: {
                value: _vm.isSkyMap,
                callback: function ($$v) {
                  _vm.isSkyMap = $$v
                },
                expression: "isSkyMap",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "footSwitch" },
          [
            _c("span", { staticClass: "imageTile" }, [_vm._v("影像瓦片")]),
            _vm._v(" "),
            _c("el-switch", {
              staticClass: "switchTurn",
              attrs: { disabled: _vm.isImageTile },
              on: { change: _vm.imageTile },
              model: {
                value: _vm.isImageTile,
                callback: function ($$v) {
                  _vm.isImageTile = $$v
                },
                expression: "isImageTile",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "uavBar" },
        [
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              staticClass: "taskBox",
              attrs: {
                title: "开放日期",
                "close-on-click-modal": false,
                visible: _vm.dialogVisible,
                modal: false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("el-divider"),
              _vm._v(" "),
              _c("div", { staticClass: "detailBar" }, [
                _c("div", { staticClass: "formBox" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.filterType == "terminal",
                          expression: "filterType == 'terminal'",
                        },
                      ],
                      staticClass: "chooseHistr",
                    },
                    [
                      _c("span", { staticClass: "histrWord" }, [
                        _vm._v("移动轨迹"),
                      ]),
                      _vm._v(" "),
                      _c("el-switch", {
                        staticClass: "switchs",
                        on: { change: _vm.changeTrajectory },
                        model: {
                          value: _vm.historyTerminalTrackVisible,
                          callback: function ($$v) {
                            _vm.historyTerminalTrackVisible = $$v
                          },
                          expression: "historyTerminalTrackVisible",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "totalBar" }, [
                    _c(
                      "div",
                      {
                        staticClass: "roundBtn",
                        class: {
                          "is-click": _vm.selectTimeType == "day",
                          "click-disable":
                            _vm.filterType == "terminal" && _vm.track == 0,
                        },
                        attrs: { round: "", plain: "" },
                        on: { click: _vm.getday },
                      },
                      [_vm._v("\n              今日\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "roundBtn",
                        class: {
                          "is-click": _vm.selectTimeType == "week",
                          "click-disable":
                            _vm.filterType == "terminal" && _vm.track == 0,
                        },
                        attrs: { round: "", plain: "" },
                        on: { click: _vm.getweek },
                      },
                      [_vm._v("\n              本周\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "roundBtn",
                        class: {
                          "is-click": _vm.selectTimeType == "month",
                          "click-disable":
                            _vm.filterType == "terminal" && _vm.track == 0,
                        },
                        attrs: { round: "", plain: "" },
                        on: { click: _vm.getmonth },
                      },
                      [_vm._v("\n              本月\n            ")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "block" },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled:
                            (_vm.filterType == "terminal" && _vm.track == 0) ||
                            _vm.selectTimeType != "",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        on: { change: _vm.getDateRange },
                        model: {
                          value: _vm.dateRange,
                          callback: function ($$v) {
                            _vm.dateRange = $$v
                          },
                          expression: "dateRange",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("el-divider"),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: { click: _vm.confirm },
                    },
                    [_vm._v("确 定")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("关 闭")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }