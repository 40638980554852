<template>
  <!-- 通用飞马任务 -->
  <div class="flying">
    <navTop />
    <div class="nav iconfont">
      <div
        class="choice1"
        :class="{ choice: choice == 1 }"
        @click="taskSelect(1)"
      >
        <img class="posi image" src="../../assets/img/navIcon1.png" mode />
        <span class="posi">全部任务</span>
      </div>
      <div
        v-if="role != 0"
        class="choice2"
        :class="{ choice: choice == 2 }"
        @click="taskSelect(2)"
      >
        <img class="posi image" src="../../assets/img/navIcon2.png" mode />
        <span class="posi">待接单任务</span>
      </div>
      <div
        v-if="role != 2"
        class="choice3"
        :class="{ choice: choice == 4 }"
        @click="taskSelect(4)"
      >
        <img class="posi image" src="../../assets/img/navIcon4.png" mode />
        <span class="posi">待推送任务</span>
      </div>
      <div
        v-if="role == 2"
        class="choice4"
        :class="{ choice: choice == 3 }"
        @click="taskSelect(3)"
      >
        <img class="posi image" src="../../assets/img/navIcon3.png" mode />
        <span class="posi">已接单任务</span>
      </div>
      <div
        v-if="role != 2"
        class="choice4"
        :class="{ choice: choice == 5 }"
        @click="taskSelect(5)"
      >
        <img class="posi image" src="../../assets/img/navIcon3.png" mode />
        <span class="posi">已推送任务</span>
      </div>
    </div>
    <div class="cen">
      <div class="nape nape-one nape-top-one">
        <span>任务名称</span>
        <el-input
          v-model="taskName"
          class="import scale"
          placeholder="请输入"
          clearable
        />
      </div>
      <div class="nape nape-two nape-top-one">
        <span>创建人</span>
        <el-input
          v-model="creator"
          class="import scale"
          placeholder="请输入"
          clearable
        />
      </div>
      <div class="nape nape-three nape-top-one">
        <span>创建时间</span>
        <el-date-picker
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          v-model="creationTime"
          class="import scale"
          type="daterange"
          align="right"
          unlink-panels
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </div>

      <div class="nape nape-one nape-top-two">
        <span>一级推送类型</span>
        <el-select
          v-model="stairType"
          class="import scale"
          clearable
          placeholder="请选择"
          @visible-change="dropDown"
        >
          <el-option
            v-for="item in TypeArr"
            :key="item.id"
            :value="item.name"
          />
        </el-select>
      </div>
      <div class="nape nape-two nape-top-two">
        <span>一级推送人</span>
        <el-input
          v-model="stairPeople"
          class="import scale"
          placeholder="请输入"
          clearable
        />
      </div>
      <div class="nape nape-three nape-top-two">
        <span>一级推送时间</span>
        <el-date-picker
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          v-model="stairTime"
          class="import scale"
          type="daterange"
          align="right"
          unlink-panels
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </div>

      <div class="nape nape-one nape-top-three">
        <span>二级推送类型</span>
        <el-select
          v-model="secondType"
          class="import scale"
          clearable
          placeholder="请选择"
          @visible-change="dropDown"
        >
          <el-option
            v-for="item in TypeArr"
            :key="item.id"
            :value="item.name"
          />
        </el-select>
      </div>
      <div class="nape nape-two nape-top-three">
        <span>二级推送人</span>
        <el-input
          v-model="secondPeople"
          class="import scale"
          placeholder="请输入"
          clearable
        />
      </div>
      <div class="nape nape-three nape-top-three">
        <span>二级推送时间</span>
        <el-date-picker
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          v-model="secondTime"
          class="import scale"
          type="daterange"
          align="right"
          unlink-panels
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </div>

      <div style="top: 203.2px" class="nape nape-one">
        <span>任务ID</span>
        <el-input
          v-model="taskID"
          class="import scale"
          placeholder="请输入"
          clearable
        />
      </div>
      <div class="nape nape-two nape-top-four">
        <span>发布门户</span>
        <el-select
          v-model="releasePortal"
          class="import scale"
          clearable
          placeholder="请选择"
          @visible-change="dropDown"
        >
          <el-option
            v-for="item in ['全部任务', '已发布至门户网站', '未发布至门户网站']"
            :key="item"
            :value="item"
          />
        </el-select>
      </div>
      <div class="nape nape-three nape-top-four">
        <span>舆情发布</span>
        <el-select
          v-model="releaseOpinion"
          class="import scale"
          clearable
          placeholder="请选择"
          @visible-change="dropDown"
        >
          <el-option
            v-for="item in [
              '全部任务',
              '已发布舆情',
              '未发布舆情',
              '已发布至门户网站',
              '未发布至门户网站',
            ]"
            :key="item"
            :value="item"
          />
        </el-select>
      </div>

      <button class="inquire btn" type="default" @click="inquire">查询</button>
      <button class="reset btn" type="default" @click="reset">重置</button>
    </div>
    <div class="bottom">
      <div class="bottom-el-select">
        <div class="refresh-btn" @click="init">
          <i class="iconfont">&#xe66f;</i>
        </div>
        <el-select
          v-model="taskQueryValue"
          class="el-select-power scale"
          placeholder="请选择"
          @change="taskQueryAlter()"
        >
          <el-option v-for="item in taskQueryArr" :key="item" :value="item" />
        </el-select>
        <!-- <el-popover v-model="elPopoverVisible" placement="top">
          <p>请选择前往创建飞马任务还是通用任务</p>
          <div style="text-align: right; margin: 0">
            <el-button
              size="mini"
              type="text"
              @click="(elPopoverVisible = false), $refs.newFmTask.reveal()"
              >飞马任务</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="(elPopoverVisible = false), newTask()"
              >通用任务</el-button
            >
          </div>
        </el-popover> -->
        <el-button
          class="el-select-button scale"
          type="primary"
          @click="newTaskNumStorage(true)"
          >新建任务</el-button
        >
      </div>
      <!-- <div class="all" @click="stateAmend">
        <span>{{ this.allState ? '-' : '+' }}</span>
        全部展开
      </div> -->
      <!-- <div class="all"><span style="opacity: 0;" /></div> -->
      <div v-for="(item, index) in list" :key="index" class="bottom-content">
        <div v-if="item.created_at" class="bottom-content-top flex-view">
          <div class="unfold-btn iconfont" @click="ubfoldAlter(index, item.id)">
            <!-- {{ unfoldArr[index] ? "-" : "+" }} -->
            <span v-if="unfoldArr[index]">&#xe672;</span>
            <span v-if="!unfoldArr[index]">&#xe671;</span>
          </div>
          <div
            class="content-top-name"
            :title="`姓名：${item.name || '无'}  单位：${
              item.unit_name || '无'
            }`"
          >
            创建人：{{ item.name }} &nbsp;&nbsp;{{ item.unit_name }}
          </div>
          <div class="content-top-time">
            创建时间：{{ item.created_at.slice(0, 19) }}
          </div>
          <div />
          <div
            v-if="role !== 2"
            class="cursor"
            :class="{ isPortalRed: item.is_portal }"
            style="width: 8%; margin-left: 12%"
            @click="taskIsPortal(item.id, item.is_portal, index)"
          >
            {{ !item.is_portal ? "发布门户" : "取消发布" }}
          </div>
          <div
            v-if="role !== 2"
            class="cursor publicOpinion"
            style="width: 8%"
            @click="publicSentiment(item)"
          >
            {{ item.disaster !== null ? "编辑舆情" : "舆情发布" }}
            <span
              v-if="item.disaster !== null && item.disaster.is_portal !== 0"
              class="round"
            />
          </div>
        </div>
        <div
          v-if="item.created_at"
          :class="{
            contentCenVacancy:
              item.user_push_data === '' && item.unit_push_data === '',
          }"
          class="bottom-content-cen flex-view"
        >
          <div
            :class="{
              contentCenVacancy:
                item.user_push_data === '' && item.unit_push_data === '',
            }"
            class="content-cen-name"
            @click="ubfoldAlter(index, item.id)"
          >
            <div class="content-cen-name-box">{{ item.project_name }}</div>
          </div>
          <div
            :class="{ 'content-cen-conceal': item.unit_push_data == '' }"
            class="content-cen-identity"
          >
            <div :title="`${item.unit_push_data.push_user_name || '无'}`">
              推送人：{{ item.unit_push_data.push_user_name || "无" }}
            </div>
            <div :title="`${item.unit_push_data.push_unit_name || '无'}`">
              推送人单位：{{ item.unit_push_data.push_unit_name || "无" }}
            </div>
            <div v-if="item.unit_push_data">
              推送时间：{{ item.unit_push_data.push_datetime.slice(0, 19) }}
            </div>
            <div>推送类型：{{ item.unit_push_data.push_type }}</div>
            <div>推送进度：{{ item.unit_push_data.push_progress }}</div>
            <div>推送巡护：{{ item.unit_push_data.push_unit_qty }}</div>
            <div>推送人员：{{ item.unit_push_data.push_user_qty }}</div>
          </div>
          <div
            :class="{ 'content-cen-conceal': item.user_push_data == '' }"
            class="content-cen-identity content-cen-company"
          >
            <div>推送巡护：{{ item.user_push_data.push_unit_name }}</div>
            <div v-if="item.user_push_data">
              推送时间：{{ item.user_push_data.push_datetime.slice(0, 19) }}
            </div>
            <div>推送类型：{{ item.user_push_data.push_type }}</div>
            <div>推送进度：{{ item.user_push_data.push_progress }}</div>
            <div>推送巡护：{{ item.user_push_data.push_user_qty }}</div>
          </div>
          <div style="text-align: right; margin-right: 6.25%">
            <el-popconfirm
              title="请问该任务是自行推送还是分享给超管推送？"
              cancel-button-type="Primarys"
              confirm-button-text="自行推送"
              cancel-button-text="超管推送"
              @onCancel="pushTag(item.id, index)"
            >
              <el-button
                v-if="role === 1 && item.mobile == user.mobile"
                slot="reference"
                :disabled="item.push_flag != 'self'"
                type="primary"
                plain
                >选择</el-button
              >
            </el-popconfirm>
          </div>
          <div
            class="cursor content-cen-btn"
            @click="toPush(item, index, item.id)"
          >
            概览
          </div>
          <div
            v-if="role !== 2"
            class="cursor content-cen-btn"
            @click="reformBtn(item, index, item.id)"
            :style="{
              color: item.redo === 0 ? 'rgb(187 183 183)' : '#1890ff',
            }"
          >
            重做
          </div>

          <div
            v-if="
              (item.push_flag == 'admin' && role !== 2) ||
              (item.push_flag == 'self' && role === 1)
            "
            class="cursor content-cen-btn content-cen-delete"
            @click="taskUnitDelete(item.id)"
          >
            删除
          </div>
          <!-- <div -->
          <div
            v-show="item.project_files !== ''"
            class="cursor content-cen-btn content-cen-download"
            @click="downloadFile(item.project_files)"
          >
            <a :href="item.project_files" :download="item.project_files"
              >详细说明</a
            >
          </div>
          <!-- </div> -->
        </div>
        <div v-if="unfoldArr[index]" class="bottom-content-bot">
          <div v-for="(item2, index2) in item.arr" :key="index2 + '0'">
            <div class="content-list-title">{{ item2.block_name }}</div>
            <el-table ref="filterTable" :data="item2.task" style="width: 100%">
              <el-table-column
                align="center"
                prop="task_name"
                label="任务名称"
                sortable
              />
              <el-table-column
                align="center"
                prop="id"
                label="任务ID"
                sortable
              />
              <el-table-column align="center" label="终端进度">
                <template slot-scope="scope">
                  <div class="state">
                    <span v-if="scope.row.status_name == '已推送联盟'">{{
                      role === 0 ? scope.row.status_name : "巡护待接单"
                    }}</span>
                    <span v-if="scope.row.status_name == '请接单任务'">{{
                      role === 0 ? scope.row.status_name : "终端待接单"
                    }}</span>
                    <span
                      v-if="
                        scope.row.status_name != '已推送联盟' &&
                        scope.row.status_name != '请接单任务'
                      "
                      :class="{ redness: scope.row.status_name == '已拒绝' }"
                      >{{ scope.row.status_name }}</span
                    >
                  </div>
                </template>
              </el-table-column>

              <el-table-column align="center" label="终端回传">
                <template slot-scope="scope">
                  <div class="instruct-btn iconfont">
                    <span
                      v-if="scope.row.task_data_shp_url !== ''"
                      title="SHP文件"
                      class="shp"
                      @click="getShp(scope.row.task_data_shp_url)"
                    />
                    <span
                      v-if="scope.row.task_brief_count !== 0"
                      title="任务简报"
                      class="task"
                      @click="bulletin(scope.row)"
                    />
                    <span
                      v-if="scope.row.task_data_result_count !== 0"
                      title="成果数据回传"
                      class="original"
                      @click="bacKikc('成果', scope.row.id)"
                    />
                    <span
                      v-if="scope.row.task_data_original_count !== 0"
                      title="原始数据回传"
                      class="achievement"
                      @click="bacKikc('原始', scope.row.id)"
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" label="处理操作">
                <template slot-scope="scope">
                  <div class="order-btn">
                    <el-button
                      v-if="
                        role !== 2 &&
                        (item.push_flag == 'admin' ||
                          (item.push_flag == 'self' && role === 1))
                      "
                      type="primary"
                      plain
                      @click="
                        orderOrpush(item, index, item.id, index2, scope.row)
                      "
                    >
                      {{
                        role != 0 && item.unit_push_data != ""
                          ? scope.row.status_name === "已创建，待推送" ||
                            scope.row.status_name === "已推送联盟"
                            ? "接单"
                            : "推送"
                          : "推送"
                      }}
                    </el-button>
                    <!-- <template> -->
                    <!-- <el-popconfirm title="请到【分布式队伍终端】app进行接单" confirm-button-text="下载" @onConfirm="onConfirmOrpush"> -->
                    <el-button
                      v-if="
                        role === 2 && scope.row.status_name === '请接单任务'
                      "
                      type="primary"
                      plain
                      >接单</el-button
                    >
                    <!-- </el-popconfirm> -->
                    <!-- </template> -->
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="tag" label="任务操作">
                <template slot-scope="scope">
                  <div class="postback-btn">
                    <div
                      v-if="
                        (item.push_flag == 'admin' && role !== 2) ||
                        (item.push_flag == 'self' && role === 1)
                      "
                      @click="toPush(item, index, item.id, index2, scope.row)"
                    >
                      详情
                    </div>
                    <el-popover cplacement="top" width="150">
                      <div v-if="taskPopNum !== -1">
                        <el-button
                          v-if="role !== 2"
                          style="margin-left: 10px"
                          class="elButton"
                          type="text"
                          @click.stop="
                            evaluate(
                              item.project_name,
                              item2.block_name,
                              scope.row
                            )
                          "
                          >评分评价</el-button
                        >
                        <el-button
                          style="margin-left: 10px"
                          class="elButton"
                          type="text"
                          @click.stop="postBack('原始', scope.row)"
                          >回传原始数据</el-button
                        >
                        <el-button
                          class="elButton"
                          type="text"
                          @click.stop="postBack('成果', scope.row)"
                          >回传成果数据</el-button
                        >
                      </div>
                      <el-button
                        v-if="
                          item.push_flag == 'admin' ||
                          (item.push_flag == 'self' && role === 1)
                        "
                        slot="reference"
                        type="text"
                        class="more"
                        @click.stop="taskPopNum = scope.row.id"
                        >更多</el-button
                      >
                    </el-popover>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!-- 简报列表 -->
      <el-dialog
        title="简报列表"
        :visible.sync="bulletinVisible"
        width="40.1%"
        center
      >
        <el-table :data="bulletinListArr" @row-click="listRow">
          <el-table-column property="brief_title" label="简报标题" />
          <el-table-column property="project_type" label="任务类型" />
          <el-table-column property="execute_progress" label="进度" />
          <el-table-column property="disaster_level" label="灾情态势" />
          <el-table-column label="操作">
            <template>
              <el-button size="mini">前往</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
      <el-dialog
        title="简报详情"
        :visible.sync="bulletinDetailsVisible"
        width="35.5vw"
        center
      >
        <div class="bulletin-details">
          <div>
            <span>简报标题:</span>
            {{ bulletinDetailsData.brief_title }}
          </div>
          <div>
            <span>任务ID:</span>
            {{ bulletinDetailsData.id }}
          </div>
          <div>
            <span>任务下达类型:</span>
            {{ bulletinDetailsData.project_type }}
          </div>
          <div>
            <span>任务执行进度:</span>
            {{ bulletinDetailsData.execute_progress }}%
          </div>
          <div>
            <span>灾情态势:</span>
            {{ bulletinDetailsData.disaster_level }}
          </div>
          <div></div>
          <div style="width: 100%">
            <span>灾情描述:</span>
            {{ bulletinDetailsData.disaster_description }}
          </div>
          <div style="width: 100%" class="img">
            <span>灾情测区快照</span>
            <img :src="bulletinDetailsData.file_1" alt />
          </div>
          <div style="width: 100%" class="img">
            <span>灾情现场快照</span>
            <img :src="bulletinDetailsData.file_2" alt />
          </div>
        </div>
      </el-dialog>

      <dataBackhaul
        @updateFlying="updateFlying"
        @toPostBack="toPostBack"
        ref="dataBackhaul"
      />

      <evaluate ref="evaluate" />
      <publicSen
        v-if="publicObj.id"
        ref="public"
        :obj="publicObj"
        @updateFlying="updateFlying"
      />
      <editPublicOpinion
        v-if="editPublicObj.id"
        ref="editPublic"
        :obj="editPublicObj"
        @updateFlying="updateFlying"
      />
      <el-pagination
        class="bottom-el-pagination"
        background
        layout="prev, pager, next"
        :current-page="pageIndex"
        :total="sumPage"
        @current-change="currentChange"
      />
    </div>

    <newFmTask ref="newFmTask" />

    <newTaskPop v-if="newTaskNum" @newFmPop="newFmPop" />

    <!-- 回传文件 -->
    <postback
      v-if="postbackShow"
      @postbackCancel="postbackCancel"
      :obj="postbackObj"
    />
    <!-- 回传文件列表 -->
    <postbackList
      v-if="postbackListShow"
      @postbackListCancel="postbackListCancel"
      :obj="postbackListObj"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import publicSen from "@/components/messageBox/taskPublic";
export default {
  components: {
    publicSen,
  },
  data() {
    return {
      elPopoverVisible: false,
      taskPopNum: -1, //  任务更多弹出框
      taskQueryArr: [
        "全部的",
        "我创建的",
        "超级管理员创建的",
        "巡护管理员创建的",
      ], //  任务select查询
      taskQueryValue: "全部的", //  下拉框选择的
      sumPage: 100, // 总页数
      pageIndex: 1,
      nav: 1, //  页面飞马还是通用
      choice: 1, //  任务查询选择
      taskName: "", //  任务名称
      creator: "", // 创建人
      creationTime: "", //  创建时间
      stairType: "", // 一级推送类型
      TypeArr: [], //  推送类型数组
      stairPeople: "", // 一级推送人
      stairTime: "", // 一级推送时间
      secondType: "", // 二级推送类型
      secondPeople: "", // 二级推送人
      secondTime: "", // 二级推送时间
      taskID: "", //  任务id
      allState: false, //  全部展开
      unfoldArr: [], //  判断是否展开
      list: [], //  任务列表数据
      bulletinVisible: false, //  简报列表弹框
      bulletinListArr: [], // 简报列表数租组
      bulletinDetailsVisible: false, // 简报详情弹框
      bulletinDetailsData: {}, //  简报详情
      releasePortal: "", //  已发布门户
      releaseOpinion: "", //  发布舆情
      publicObj: {}, //  舆情发布组件绑定对象
      editPublicObj: {}, //  舆情编辑携带对象
      postbackObj: {}, //  回传文件所携带的对象
      postbackShow: false, //  回传文件弹框显示隐藏
      postbackListObj: {}, //  查看回传文件列表所携带的对象
      postbackListShow: false, //  回传文件列表弹框显示隐藏
    };
  },
  created() {
    // if (this.role === 2) {
    //   this.$confirm('暂无操作权限', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   })
    //     .then(() => {
    //       this.$router.go(-1);
    //     })
    //     .catch(() => {
    //       this.$router.go(-1);
    //     });
    // }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState([
      "user",
      "token",
      "role",
      "currentUrl",
      "ifPortal",
      "newTaskNum",
    ]),
  },
  watch: {
    $route: "fetchData",
  },
  methods: {
    ...mapMutations([
      "currentUrlStorage",
      "navMessageStorage",
      "newTaskNumStorage",
    ]),
    /**
     * 打开回传文件弹框
     */
    toPostBack(data) {
      // this.postbackObj = data;
      // this.postbackShow = true;
      data.url = process.env.VUE_APP_API_HOST + "/api";
      data.token = this.token;
      this.utils.$funcSave(data, "postback");
      // window.open("http://localhost:9527/postback");
      window.open(`${process.env.VUE_APP_URL_HOST}/postback`);
    },
    /**
     * 取消按钮关闭
     */
    postbackCancel() {
      this.postbackShow = false;
      this.postbackObj = {};
    },
    /**
     * 回传文件列表关闭回调
     */
    postbackListCancel() {
      this.postbackListShow = false;
      this.postbackListObj = {};
    },
    /**
     * 弹出新建飞马任务
     */
    newFmPop() {
      this.$refs.newFmTask.reveal();
    },
    /**
     * 初始化
     */
    init() {
      if (JSON.stringify(this.$route.query) !== "{}") {
        this.fetchData(this.$route.query);
      } else {
        const obj = {
          nav: "feima",
        };
        this.fetchData(obj);
      }
    },
    /**
     * 舆情发布
     */
    publicSentiment(obj) {
      if (obj.disaster === null) {
        this.publicObj = {};
        setTimeout(() => {
          this.publicObj = obj;
          this.publicObj.radio = this.nav;
          setTimeout(() => {
            this.$refs.public.open();
          }, 100);
        }, 50);
        return;
      }
      this.editPublicObj = {};
      setTimeout(() => {
        this.editPublicObj = obj;
        setTimeout(() => {
          this.$refs.editPublic.open();
        }, 100);
      }, 50);
    },
    /**
     * 发布舆情后更新页面
     */
    updateFlying() {
      this.init();
    },
    /**
     * 是否发布到门户
     */
    taskIsPortal(project_id, is_portal, index) {
      this.$apis
        .taskIsPortal(
          {
            project_id: project_id,
            is_portal: is_portal === 0 ? 1 : 0,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status === 0) {
            this.list[index].is_portal = is_portal === 0 ? 1 : 0;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 查看回传数据
     */
    bacKikc(str, id) {
      this.postbackListShow = true;
      this.postbackListObj = {
        type: str,
        id: id,
      };
    },
    /**
     * 评分评价
     */
    evaluate(project_name, block_name, item) {
      this.$refs.evaluate.$emit("open", {
        project_name: project_name,
        block_name: block_name,
        item: item,
      });
    },
    /**
     * 回传数据
     */
    postBack(e, item) {
      if (this.role === 2 && item.confirm_user_id !== this.user.id) {
        this.utils.$notifyError("回传失败", "该任务未分配于当前账户");
        return;
      }
      this.$refs.dataBackhaul.$emit("open", { name: e, item: item });
    },
    /**
     * 获取shp文件
     */
    getShp(url) {
      window.open(url);
    },
    /**
     * 查看任务简报列表
     */
    bulletin(item) {
      this.$apis
        .taskGetTaskBriefList(
          {
            task_id: item.id,
          },
          this.token
        )
        .then((data) => {
          this.bulletinListArr = data.data.data;
          this.bulletinVisible = true;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 简报列表某一行被点击时会触发事件
     */
    listRow(e) {
      this.$apis
        .taskGetTaskBriefDetail(
          {
            task_brief_id: e.id,
          },
          this.token
        )
        .then((data) => {
          this.bulletinVisible = false;
          this.bulletinDetailsVisible = true;
          this.bulletinDetailsData = data.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 删除任务
     */
    taskUnitDelete(id) {
      this.$confirm("此操作将永久删除该任务, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$apis
            .taskUnitDelete({ project_id: id }, this.token)
            .then((data) => {
              if (data.data.status === 0) {
                this.utils.$message("删除成功");
                this.fetchData(this.$route.query);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /**
     * 下载文件说明
     */
    downloadFile(item, index, id) {
      console.log(item, index, id);
    },
    /**
     * 设置推送标记巡护推送还是管理员推送
     * @param {Object} project_id
     * @param {Object} push_flag
     */
    pushTag(project_id, index) {
      this.$apis
        .taskAddCommon(
          {
            action: "update",
            project_id: project_id,
            push_flag: "admin",
          },
          this.token
        )
        .then((data) => {
          if (data.data.message === "设置推送成功") {
            this.utils.$notifySuccess("成功", data.data.message);
            this.list[index].push_flag = "admin";
            this.list = [...this.list];
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 终端用户接单
     */
    onConfirmOrpush() {},
    /**
     * 接单/推送
     * @param {Object} item
     * @param {number} index
     * @param {string} id
     * @param {number} index2
     * @param {number} index3
     */
    orderOrpush(item, index, id, index2, obj) {
      if (
        (obj.status_name === "已创建，待推送" ||
          obj.status_name === "已推送联盟") &&
        this.role !== 0 &&
        item.mobile != this.user.mobile
      ) {
        this.$confirm(
          `确认接单${item.arr[index2].block_name}下${obj.task_name}?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.$apis
              .taskUnitConfirm(
                {
                  task_id: obj.id,
                },
                this.token
              )
              .then((data) => {
                if (data.data.status === 0) {
                  if (data.data.message !== "联盟接单失败") {
                    this.utils.$notifySuccess("提示", obj.id + "任务接单成功");
                    for (
                      let i = 0;
                      i < this.list[index].arr[index2].task.length;
                      i++
                    ) {
                      if (this.list[index].arr[index2].task[i].id === obj.id) {
                        this.list[index].arr[index2].task[i].status_name =
                          "待推送任务";
                      }
                    }
                    this.list = [...this.list];
                  } else {
                    this.utils.$notifyError(
                      "错误",
                      obj.id + "任务接单失败，请联系超管推送"
                    );
                  }
                }
              })
              .catch((err) => {
                console.error(err);
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消接单",
            });
          });
      } else {
        this.toPush(item, index, item.id, index2, obj);
      }
    },
    /**
     * 新建任务
     */
    newTask() {
      this.$router.push({
        path: "/newgeneric",
      });
    },
    /**
     * 任务类型搜索用户级别下拉框改变
     * @param {number} index
     */
    taskQueryAlter() {
      this.antiShake(
        this.taskList,
        this.taskQueryArr.indexOf(this.taskQueryValue)
      );
    },
    /**
     * 下拉框出现的时候
     */
    dropDown(data) {
      if (data && this.TypeArr.length === 0) {
        this.$apis
          .taskPushType({}, this.token)
          .then((data) => {
            this.TypeArr = data.data.data;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    /**
     * 点击重置
     */
    reset() {
      this.taskName = "";
      this.creator = "";
      this.creationTime = ["", ""];
      this.stairType = "";
      this.stairPeople = "";
      this.stairTime = ["", ""];
      this.secondType = "";
      this.secondPeople = "";
      this.secondTime = ["", ""];
      this.taskID = "";
      this.releasePortal = "";
      this.releaseOpinion = "";
      this.taskQueryValue = "全部的";
    },
    /**
     * 点击查询
     */
    inquire() {
      this.pageIndex = 1;
      this.antiShake(this.taskList, 0);
    },
    /**
     * 概述，前往推送页面
     * @param {Object} item
     */
    toPush(item, index, id, index2, obj) {
      // console.log(item[0],item);
      // if (item.arr) {
        console.log("进来");
          this.$router.push({
            path: "/blockTaskPush",
            query: {
              id: id,
              nav: this.nav,
              name: item.project_name,
              mobile: item.mobile,
              push_flag: item.push_flag,
              redo: item.redo,
            },
          });

        // 这里为什么要这样子写???
      // } else {
      //   console.log("这里又请求？？？");
      //   this.taskListTask(index, id, "/taskPush");
      // }
    },
    /**
     * 项目返回重做
     */
    reformBtn(item, index, id) {
      if (item.redo === 0) {
        return;
      }
      this.$confirm("是否确认重做:" + item.project_name, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.taskRedoProject(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消重做",
          });
        });
    },
    /**
     * 重做项目
     */
    taskRedoProject(id) {
      this.$apis
        .taskRedoProject(
          {
            project_id: id,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status === 0) {
            this.init();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 获取项目列表
     */
    taskList(num = 0) {
      let way = "listFeiMa";
      way = this.nav === 1 ? "listFeiMa" : "listCommon";
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const parameter = {
        page: this.pageIndex,
        type: this.nav,
        status: "",
        project_name: this.taskName,
        create_user_mobile: this.creator,
        created_at_from: this.creationTime === null ? "" : this.creationTime[0],
        created_at_to: this.creationTime === null ? "" : this.creationTime[1],
        first_push_user: this.stairPeople,
        push_unit_created_at_from:
          this.stairTime === null ? "" : this.stairTime[0],
        push_unit_created_at_to:
          this.stairTime === null ? "" : this.stairTime[1],
        second_push_user: this.secondPeople,
        push_user_created_at_form:
          this.secondTime === null ? "" : this.secondTime[0],
        push_user_created_at_to:
          this.secondTime === null ? "" : this.secondTime[1],
        task_id: this.taskID,
        user_level: this.taskQueryArr.indexOf(this.taskQueryValue),
      };
      switch (this.releasePortal) {
        case "已发布至门户网站":
          parameter.is_portal = 1;
          break;
        case "未发布至门户网站":
          parameter.is_portal = 2;
          break;
        default:
          parameter.is_portal = 0;
          break;
      }
      switch (this.releaseOpinion) {
        case "已发布舆情":
          parameter.is_disaster = 1;
          break;
        case "未发布舆情":
          parameter.is_disaster = 2;
          break;
        case "已发布至门户网站":
          parameter.is_disaster = 3;
          break;
        case "未发布至门户网站":
          parameter.is_disaster = 4;
          break;
        default:
          parameter.is_disaster = 0;
          break;
      }
      if (this.stairType !== "") {
        parameter.first_push_type = this.stairType === "群发" ? 2 : 1;
      }
      if (this.secondType !== "") {
        parameter.second_push_type = this.secondType === "群发" ? 2 : 1;
      }
      switch (this.choice) {
        case 1:
          parameter.status = "";
          break;
        case 2:
          parameter.status = 4;
          if (this.role === 1) {
            parameter.status = 2;
          }
          break;
        case 3:
          parameter.status = 5;
          break;
        case 4:
          parameter.status = 3;
          if (this.role === 0) {
            parameter.status = 1;
          }
          break;
        case 5:
          parameter.status = 4;
          if (this.role === 0) {
            parameter.status = 2;
          }
          break;
        default:
          return;
      }
      this.$apis
        .taskList(parameter, this.token, way)
        .then((data) => {
          this.sumPage = data.data.data.last_page * 10;
          this.pageIndex = data.data.data.current_page;
          this.list = [];
          this.unfoldArr = [];
          this.list = data.data.data.data;
          const lengthNum = this.list.length;
          for (let i = 0; i < lengthNum; i++) {
            this.unfoldArr.push(false);
          }
          loading.close();
        })
        .catch((err) => {
          console.error(err);
          loading.close();
        });
    },
    /**
     * 当前页码发生变化时
     */
    currentChange(e) {
      this.pageIndex = e;
      this.antiShake(this.taskList, 0);
    },
    /**
     * @param {Object} data
     * 判断路由的路径
     */
    fetchData(data) {
      let dataNav = "";
      this.reset();
      if (data.nav) {
        dataNav = data.nav;
        this.choice = data.choice ? data.choice : 1;
      } else {
        dataNav = data.query.nav;
        this.choice = data.query.choice ? data.query.choice : 1;
      }
      this.navMessageStorage(0);
      if (dataNav === "gm") {
        this.currentUrlStorage(["首页", "任务下达", "通用任务"]);
        this.nav = 2;
      } else if (dataNav === "feima") {
        this.currentUrlStorage(["首页", "任务下达", "航线任务"]);
        this.nav = 1;
      } else {
        this.currentUrlStorage(["首页", "任务下达", "区块任务"]);
        this.nav = 3;
      }
      this.antiShake(this.taskList, 0);
    },
    /**
     * 全选状态判断
     */
    allUnfold() {
      for (let i = 0; i < this.unfoldArr.length; i++) {
        if (!this.unfoldArr[i]) {
          this.allState = false;
          return false;
        }
        this.allState = true;
      }
    },
    /**
     * 查看全部任务，待接单任务选择
     * @param {number} index\
     */
    taskSelect(index) {
      this.choice = index;
      this.antiShake(this.taskList, 0);
    },
    /**
     * 总全部展开
     */
    stateAmend() {
      this.allState = !this.allState;
      this.unfoldArr = [];
      setTimeout(() => {
        for (let i = 0; i < this.list.length; i++) {
          this.unfoldArr.push(this.allState);
        }
      }, 100);
    },
    /**
     * 列表每一项的全部展开
     * @param {number} index
     */
    ubfoldAlter(index, id) {
      if (!this.list[index].arr) {
        this.taskListTask(index, id);
      } else {
        this.unfoldArr.splice(index, 1, !this.unfoldArr[index]);
        this.allUnfold();
      }
    },
    /**
     * 获取子任务
     * @param {number} index
     * @param {number} id
     */
    taskListTask(index, id, pushUrl) {
      let way = "listFeiMaTask";
      way = this.nav === 1 ? "listFeiMaTask" : "listCommonTask";
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$apis
        .taskListTask(
          {
            project_id: id,
            sort: "asc",
          },
          this.token,
          way
        )
        .then((data) => {
          this.list[index].arr = data.data.data;
          loading.close();
          if (pushUrl) {
            this.$router.push({
              path: "/blockTaskPush",
              query: {
                id: id,
                nav: this.nav,
                name: this.list[index].project_name,
                mobile: this.list[index].mobile,
                push_flag: this.list[index].push_flag,
                redo: this.list[index].redo,
              },
            });
          } else {
            this.unfoldArr.splice(index, 1, !this.unfoldArr[index]);
            setTimeout(() => {
              this.allUnfold();
            }, 100);
          }
        })
        .catch((err) => {
          console.error(err);
          loading.close();
        });
    },
    /**
     * 页面防抖函数
     * @param {fundtion} fn
     * @param {object} parameter
     */
    antiShake(fn, parameter) {
      fn(parameter);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/iconfont/iconfont.css";
.flying {
  padding: 0px 40px 40px 40px;
  background-color: #f0f0f0;
}
.top {
  margin-left: 30px;
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  span {
    color: rgba(0, 0, 0, 0.65);
  }
}

.nav {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 2vh;
  div {
    width: 18.4vw;
    max-width: 304px;
    max-height: 138px;
    height: 8.3vw;
    line-height: 8.3vw;
    background: rgba(255, 255, 255, 1);
    border-radius: 6.4px;
    margin-right: 1.5vw;
    box-sizing: border-box;
    text-align: center;
    font-size: 19.2px;
    position: relative;
  }

  div:nth-child(1) {
    border-bottom: 0px solid #7c6dea;
    box-shadow: 0px 4px 12px 0px rgba(194, 156, 81, 0.23);
  }
  div:nth-child(2) {
    border-bottom: 0px solid #ff4444;
    box-shadow: 0px 4px 12px 0px rgba(216, 115, 115, 0.16);
  }
  div:nth-child(3) {
    border-bottom: 0px solid #9bceb9;
    box-shadow: 0px 4px 12px 0px rgba(155, 206, 185, 0.28);
  }
  div:nth-child(4) {
    border-bottom: 0px solid #36c98d;
    box-shadow: 0px 4px 12px 0px rgba(147, 147, 190, 0.29);
  }

  .image {
    width: 3.96vw;
    height: 3.96vw;
    max-width: 65.6px;
    max-height: 65.6px;
    left: 2.216vw;
  }
  .choice1 {
    background: url("../../assets/img/nav1.png") no-repeat center center;
    background-size: 100% 100%;
  }
  .choice2 {
    background: url("../../assets/img/nav2.png") no-repeat center center;
    background-size: 100% 100%;
  }
  .choice3 {
    background: url("../../assets/img/nav3.png") no-repeat center center;
    background-size: 100% 100%;
  }
  .choice4 {
    background: url("../../assets/img/nav4.png") no-repeat center center;
    background-size: 100% 100%;
  }
  .choice {
    border-bottom-width: 0.43vw !important;
    background-size: 100% 100%;
  }
}

@mixin scale($scale, $navSize) {
  .scale {
    transform: scale($scale, $scale);
  }

  .posi {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: $navSize;
  }
}

@include scale($scale: 1, $navSize: 26px);

@media screen and(max-width: 1680px) {
  @include scale($scale: 0.95, $navSize: 26px);
}
@media screen and(max-width: 1550px) {
  @include scale($scale: 0.9, $navSize: 22px);
}
@media screen and(max-width: 1450px) {
  @include scale($scale: 0.85, $navSize: 20px);
  .content-cen-name {
    font-size: 18px !important;
  }
}
@media screen and(max-width: 1300px) {
  @include scale($scale: 0.8, $navSize: 18px);
  .table-top > div {
    font-size: 14px !important;
  }
  .content-cen-name {
    font-size: 17px !important;
  }
}

.cen {
  width: 100%;
  height: 324.8px;
  position: relative;
  margin: 0 auto;
  box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
  background-color: #fff;
  margin-bottom: 2vh;
  .nape {
    position: absolute;
    display: inline-block;
    width: 26%; // 332
    text-align: right;
    span {
      padding-right: 2%;
      color: #838a9d;
      font-size: 14px;
    }
    .import {
      display: inline-block;
      width: 55%;
      height: 40px;
    }
  }
  .nape-one {
    right: 72.3125%;
  }
  .nape-two {
    right: 46.9375%;
  }
  .nape-three {
    width: 34%; // 389
    right: 12%;
    .import {
      display: inline-block;
      width: 60%;
      height: 40px;
    }
  }
  .nape-top-one {
    top: 35.2px;
  }
  .nape-top-two {
    top: 91.2px;
  }
  .nape-top-three {
    top: 147.2px;
  }
  .nape-top-four {
    top: 203.2px;
  }

  .btn {
    position: absolute;
    bottom: 1.2vw;
    width: 112px;
    height: 40px;
    border-radius: 2px;
    border: 0;
    font-size: 16px;
  }

  .inquire {
    color: #fff;
    background-color: #1182fb;
    right: 11vw;
  }
  .reset {
    right: 2.95vw;
    color: #1182fb;
    background-color: #fff;
    border: 1px solid #1182fb;
  }
}

@media screen and (max-width: 1280px) {
  .bottom-el-select {
    left: 68.5% !important;
  }
}

@media screen and (max-width: 1520px) {
  .bottom-el-select {
    left: 74.8% !important;
  }
}
@media screen and (max-width: 1420px) {
  .bottom-el-select {
    left: 71.8% !important;
  }
}
.bottom {
  padding: 0 3.8vw 168px;
  margin-bottom: 30px;
  position: relative;
  background-color: #fff;
  .bottom-el-select {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 4vw;
    border-radius: 2px;
    min-width: 295px;
    margin-bottom: 1vw;

    .el-select-power {
      width: 10.8vw;
      height: 2.1vw;
      margin: 0 1.3vw;
      min-width: 180px;
    }

    .el-select-button {
      width: 6vw;
      height: 1.8vw;
      min-width: 90px;
      min-height: 36px;
    }
  }

  .all {
    cursor: pointer;
    user-select: none;
    margin: 24px 22px;
    font-size: 0.843vw;
    width: 120px;

    span {
      display: inline-block;
      width: 17px;
      height: 17px;
      line-height: 17px;
      border-radius: 2px;
      border: 1px solid rgba(131, 138, 157, 1);
      text-align: center;
    }
  }

  .table-top {
    display: flex;
    padding: 1.6vw 0;
    border-top: 1px solid #dde2ee;
    div {
      flex-direction: column;
      width: 16.66667%;
      text-align: center;
      cursor: pointer;
      user-select: none;
      font-size: 14px;
      color: #3c4353;
      font-weight: 700;
    }
  }

  .bottom-content {
    line-height: 44.8px;
    border-radius: 2px;
    border: 1px solid rgba(221, 226, 238, 1);
    margin-bottom: 1.5vw;

    .flex-view {
      display: flex;
      div {
        flex-direction: column;
        width: 16.66667%;
        text-align: center;
      }
    }

    .unfold-btn {
      width: 13.6px !important;
      // height: 13.6px !important;
      line-height: 13.6px;
      border-radius: 2px;
      // border: 1px solid rgba(131, 138, 157, 1);
      text-align: center;
      margin: 15.2px 0 15.2px 17.6px;
      cursor: pointer;
      user-select: none;
      box-sizing: border-box;
      display: flex;
      span {
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
      }
    }

    .bottom-content-top {
      background: rgba(221, 230, 239, 1);
      color: #3c4353;
      font-size: 0.84vw;
      height: 44.8px;
      .content-top-name {
        width: 34%;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 20px;
        text-align: left;
      }
      .content-top-time {
        width: 28%;
        font-size: 14px;
      }

      .isPortalRed {
        color: red;
      }

      .publicOpinion {
        display: flex;
        position: relative;
        .round {
          position: absolute;
          right: -5px;
          top: 50%;
          transform: translateY(-50%);
          width: 9px;
          height: 9px;
          background: rgba(27, 208, 190, 1);
          border: 2px solid rgba(27, 208, 190, 0.3);
          border-radius: 50%;
        }
      }
    }

    .bottom-content-cen {
      height: 11.68vw;
      line-height: 11.68vw;
      max-height: 210px;
      min-height: 160px;
      text-align: center;
      display: flex;
      justify-content: space-around;
    }

    .content-cen-name {
      height: 11.68vw;
      max-height: 194px;
      min-height: 160px;
      color: #1182fb;
      font-size: 18px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      display: flex;
      justify-content: center;
      user-select: none;
      .content-cen-name-box {
        width: 100%;
        word-break: break-all;
        white-space: normal;
        line-height: 24px;
      }
    }

    .contentCenVacancy {
      height: 4.68vw;
      line-height: 4.68vw;
      max-height: 77.7px;
      min-height: 64px;
    }

    .content-cen-identity {
      padding-left: 39px;
      height: 11.68vw;
      max-height: 194px;
      min-height: 160px;
      box-sizing: content-box;
      div {
        margin-top: 0.56vw;
        width: 100%;
        font-size: 12px;
        height: 19px;
        line-height: 19px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
      }
    }

    .content-cen-btn {
      width: 50px !important;
      text-align: center;
      font-size: 14px;
      color: #1182fb;
    }
    .content-cen-delete {
      margin-left: 0;
      color: #f00;
    }
    .content-cen-download {
      width: 70px !important;
    }
    .content-cen-company {
      margin-top: 13px;
    }

    .content-cen-conceal {
      visibility: hidden;
    }

    .bottom-content-bot {
      .content-list {
        padding-left: 2.6vw;
        height: 48px;
        line-height: 48px;
        div {
          font-size: 14px;
        }
      }
      .content-list-title {
        height: 48px;
        line-height: 48px;
        color: rgba(0, 0, 0, 0.65);
        padding-left: 52px;
        border-top: 1px solid rgba(221, 226, 238, 1);
        border-bottom: 1px solid rgba(221, 226, 238, 1);
        font-size: 16px;
        font-weight: 700;
      }
      .state {
        span {
          display: inline-block;
          height: 28px;
          line-height: 28px;
          background: rgba(246, 255, 237, 1);
          border-radius: 2px;
          border: 1px solid #b7eb8f;
          padding: 0 12px;
          color: #52c41a;
          font-size: 12px;
        }
      }

      .instruct-btn {
        width: 100%;
        span {
          width: 21.66%;
          display: inline-block;
          height: 24px;
          line-height: 60%;
          margin-top: 10px;
        }
        .shp {
          border-radius: 2px;
          background: url("../../assets/img/SHP.png") no-repeat center center;
          background-size: 100% 100%;
        }
        .task {
          width: 33.3%;
          border-radius: 2px;
          background: url("../../assets/img/mission_brief.png") no-repeat center
            center;
          background-size: 100% 100%;
        }
        .original {
          border-radius: 2px;
          background: url("../../assets/img/achievements.png") no-repeat center
            center;
          background-size: 100% 100%;
        }

        .achievement {
          border-radius: 2px;
          background: url("../../assets/img/raw_data.png") no-repeat center
            center;
          background-size: 100% 100%;
        }
      }

      .redness {
        border-color: #ffa39e !important;
        color: #ffa39e;
      }

      .order-btn {
        span {
          display: block;
          width: 96px;
          height: 36px;
          line-height: 36px;
          border-radius: 2px;
          text-align: center;
          margin: 6px auto 0;
          color: #1182fb;
          border: 1px solid rgba(17, 130, 251, 1);
        }
      }

      .postback-btn {
        color: #1182fb;
        cursor: pointer;
        user-select: none;
        div {
          display: inline-block;
          width: 40px;
        }

        .more {
          position: relative;
        }
      }
    }
  }
  .bottom-el-pagination {
    position: absolute;
    right: 18%;
    bottom: 56px;
  }
}

.bulletin-details {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  span {
    opacity: 0.8;
    margin-right: 15px;
    // display: block;
    color: #3c4353;
    position: absolute;
    left: 0;
    text-align: right;
    width: 4.9vw;
  }
  div {
    color: #838a9d;
    margin: 20px 0;
    width: 49%;
    position: relative;
    padding-left: 6vw;
    font-size: 13px;
  }
  .img {
    width: 280px;
    height: 280px;
    img {
      display: inline-block;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.refresh-btn {
  transition: All 0.4s ease-in-out;
  -webkit-transition: All 0.4s ease-in-out;
  -moz-transition: All 0.4s ease-in-out;
  -o-transition: All 0.4s ease-in-out;
  .iconfont {
    cursor: pointer;
    font-size: 30px;
  }
}
.refresh-btn:hover {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}
</style>
