var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "disclaimer" }, [
      _c("div", [
        _c("div", { staticClass: "statement" }, [
          _vm._v(
            "\n        通过无人机巡护指挥调度管理平台接受任务的测绘资质单位，请自行做好飞行计划安排并确保安全飞行，平台不提供空域保障和飞行计划报批等相关服务。本网站对无人机用户单位（公司）出现的任何损害情况不承担任何法律责任。\n      "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }