<template>
  <div>
    <div class="bodyBar">
      <div class="mainBar">
        <el-dropdown trigger="click" @command="uploadFile">
          <el-button
            icon="iconfont icon-datareturn"
            type="primary"
          >&nbsp;&nbsp;数据回传</el-button>
          <el-dropdown-menu>
            <el-dropdown-item
              class="echoFile"
              command="returnFile"
            >回传文件</el-dropdown-item>
            <el-dropdown-item
              class="echoFile"
              command="returnFolder"
            >回传文件夹</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button
          icon="iconfont icon-datareturn"
          title="1GB以上可选择大文件回传"
          @click="largeFileClick"
        >&nbsp;&nbsp;大文件回传</el-button>
        <el-button
          type="primary"
          class="downLoad"
          :disabled="this.disables === 0"
          @click="batchDown"
        >下载</el-button>
        <el-button
          type="danger"
          class="danger"
          :disabled="this.disables === 0"
          @click="batchDel"
        >删除</el-button>
        <!-- 搜索框 -->
        <el-form ref="ruleForm" label-width="100px">
          <el-form-item prop="mobile" class="demo-ruleForm">
            <el-input
              v-model="type"
              class="searchInput"
              placeholder="请输入文件名称进行搜索"
              suffix-icon="el-icon-search"
              @change="searchResult"
            />
          </el-form-item>
        </el-form>
        <div class="dataMain">
          <div class="tableBar">
            <el-table
              ref="multipleTable"
              v-loading="!hasGotDownloadLinks"
              height="500px"
              :data="fileDataList"
              tooltip-effect="dark"
              style="width: 100%"
              :default-sort="{ prop: 'date', order: 'descending' }"
              class="tableNav"
              element-loading-text="正在加载,请稍候..."
              @select="onTableSelect"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="55" />
              <el-table-column
                sortable
                prop="source_name"
                label="文件名"
                width="350"
                header-align="center"
                align="left"
              >
                <template v-if="hasGotDownloadLinks" slot-scope="scope">
                  <a :href="scope.row.downloadLink? scope.row.downloadLink: ''" :download="scope.row.source_name"><img :src="fileIcon" alt>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                    {{ scope.row.source_name }}</a>
                </template>
              </el-table-column>
              <el-table-column
                prop="size"
                sortable
                label="大小"
                width="200"
                align="center"
              >
                <template slot-scope="scope">{{ scope.row.size }}</template>
              </el-table-column>
              <el-table-column
                align="center"
                sortable
                prop="created_at"
                label="回传时间"
                show-overflow-tooltip
                :formatter="formatter"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.created_at.substring(0, 10) +
                      `&nbsp;` +
                      scope.row.created_at.substring(11, 19)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                label="操作"
                width="180"
                align="center"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click="handleClick(scope.row)"
                  >下载</el-button>
                  <el-button
                    type="text"
                    size="small"
                    class="deletBtn"
                    @click="delBtn(scope.row)"
                  >删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="note">另存为：右键单击文件名，选择“链接另存为”</div>
          </div>
          <div class="block">
            <el-pagination
              :current-page="current_page"
              :page-size="page_size"
              layout="total,  prev, pager, next, jumper"
              :total="total"
              @next-click="handleNext"
              @prev-click="handlePrev"
              @current-change="currentchange"
            />
          </div>
        </div>
      </div>
    </div>
    <DelFile ref="delfile" :file-infor="fileInfor" @upFileList="refresh" />
    <UploadFile ref="file" @toLargeFile="largeFileClick" @refresh="refresh" />
    <downloadLoad :download-length="downloadLength" />

    <largeFile
      v-if="largeFileShow"
      :upload-type="'index_data'"
      :uppy-iframe="uppyIframe"
      @cancel="cancel"
      @refresh="refresh"
      @fullscreenLoading="fullscreenLoading"
    />
  </div>
</template>

<script>
import DelFile from "@/components/messageBox/delFile"
import UploadFile from "@/components/messageBox/uploadFile"
import { mapState, mapMutations } from "vuex"
import download from "downloadjs"
import LargeFile from "@/components/largeFile.vue"
export default {
  components: {
    DelFile,
    UploadFile
  },
  data() {
    return {
      fileIcon: require("../../assets/img/datareturn／other.png"),
      disables: 0,
      selected: "",
      status: true,
      type: "",
      fileDataList: [],
      current_page: 1, // 当前页
      total: 0, // 总条目数
      page_size: 8, // 每页多少条
      page: 1,
      per_page: 10,
      multipleSelection: [],
      sum: 0, //  总数量
      schedule: 0, //  已完成数量
      underway: 0, //  进行中数量
      percentageNum: 0,
      underwayNum: 0,
      fileInfor: {},
      downloadLength: 0, //  正在下载中文件的数量
      largeFileShow: false, //  大文件回传弹框
      uppyIframe: process.env.VUE_APP_API_HOST + "/map/uppy.html",
      hasGotDownloadLinks: false
    }
  },
  created() {
    this.fileList()
    const obj = {
      token: this.token,
      uploadType: "index_data"
    }
    this.uppyIframe =
      process.env.VUE_APP_API_HOST +
      "/map/uppy.html?" +
      encodeURIComponent(JSON.stringify(obj))

    // this.uppyIframe =
    //   "http://127.0.0.1:8848/hybrid/uppy.html?" +
    //   encodeURIComponent(JSON.stringify(obj));
  },
  methods: {
    /**
     * 回传加载全屏loading
     */
    fullscreenLoading(data) {
      console.log(data)
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      })
      if (data === false) {
        loading.close()
      }
    },
    /**
     * 大文件回传关闭回调
     */
    cancel() {
      this.largeFileShow = false
      this.$router.go(0)
    },
    /**
     * 大文件回传
     */
    largeFileClick() {
      this.largeFileShow = true
      // window.open(process.env.VUE_APP_API_HOST + "/map/uppy.html");
    },
    refresh() {
      this.fileList()
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.selected = this.multipleSelection.length > 0
      if (this.selected == true) {
        this.disables = 1
      } else {
        this.disables = 0
      }
    },
    formatter(row, column) {
      return row.returnTime
    },
    handleNext() {
      this.page++
      this.fileList()
    },
    handlePrev() {
      this.page--
      this.fileList()
    },
    currentchange(page) {
      this.page = page
      this.fileList()
    },
    // 检索成果列表
    searchResult() {
      this.page = 1
      this.fileList()
    },
    // 批量下载
    batchDown() {
      this.downloadLength = this.multipleSelection.length
      for (let i = 0; i < this.multipleSelection.length; i++) {
        const fileType = this.multipleSelection[i].source_name.slice(
          this.multipleSelection[i].source_name.lastIndexOf(".") + 1
        )
        const obj = this.multipleSelection[i]
        this.$apis
          .download({ id: this.multipleSelection[i].id }, this.token)
          .then((res) => {
            if (obj.size < 100 * (1024 * 1024)) {
              this.utils
                .$funcDownloadImgZP(res.data.data, obj.source_name)
                .then((data) => {
                  this.downloadLength = this.downloadLength - 1
                })
                .catch((err) => {
                  this.downloadLength = this.downloadLength - 1
                })
            } else {
              window.open(res.data.data)
              this.downloadLength = this.downloadLength - 1
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    // 批量删
    batchDel() {
      this.$confirm("此操作将永久删除文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          })
          for (let i = 0; i < this.multipleSelection.length; i++) {
            this.$apis
              .delfile(
                {
                  id: this.multipleSelection[i].id
                },
                this.token
              )
              .then((data) => {
                if (data.data.status == 0) {
                  this.fileList()
                  this.$message({
                    type: "success",
                    message: "删除成功!"
                  })
                }
                loading.close()
              })
              .catch((err) => {
                this.loading = false
                console.error(err)
                loading.close()
              })
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          })
        })
    },
    // 删除
    delBtn(row) {
      this.fileInfor = row
      setTimeout(() => {
        this.$refs.delfile.openDelEdit()
      }, 0)
    },
    // 下载
    handleClick(row) {
      if (row.downloadLink) {
        window.open(row.downloadLink)
        return
      }
      this.$apis
        .download({ id: row.id }, this.token)
        .then((res) => {
          // download(res.data.data);
          window.open(res.data.data)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    // 获取文件下载地址
    getDownloadHref(file, index) {
      this.$apis
      .download({ id: file.id }, this.token)
      .then((res) => {
        this.fileDataList[index].downloadLink = res.data.data
        if (index === this.fileDataList.length - 1) {
          setTimeout(() => {
            this.hasGotDownloadLinks = true
          }, 100)
        }
      })
      .catch((err) => {
        console.error(err)
      })
    },
    // 勾选状态
    onTableSelect(rows, row) {
      this.selected = rows.length && rows.indexOf(row) !== -1
      if (this.selected == true) {
        this.disables = 1
      } else {
        this.disables = 0
      }
    },
    // 回传文件
    uploadFile(command) {
      if (command == "returnFile") {
        this.$refs.file.open(1)
      } else {
        this.$refs.file.open(2)
      }
    },
    // 文件列表
    fileList() {
      this.$apis
        .uploadList(
          {
            per_page: 8,
            page: this.page,
            source_name: this.type
          },
          this.token
        )
        .then((data) => {
          this.fileDataList = data.data.data.data
          this.fileDataList.forEach((e, index) => {
            // console.log(e)
            this.getDownloadHref(e, index)
            if (e.size > 1024 * 1024 * 1024) {
              this.fileDataList[index].size =
                (e.size / (1024 * 1024 * 1024)).toFixed(2) + "GB"
            } else if (e.size > 1024 * 1024) {
              this.fileDataList[index].size =
                (e.size / (1024 * 1024)).toFixed(2) + "MB"
            } else if (e.size > 1024) {
              this.fileDataList[index].size = (e.size / 1024).toFixed(2) + "KB"
            } else {
              this.fileDataList[index].size = e.size + "B"
            }
          })
          this.total = data.data.data.total
          this.current_page = data.data.data.current_page
        })
        .catch((err) => {})
        .finally(() => {
          setTimeout(() => {
            this.hasGotDownloadLinks = true
          }, 100)
        })
    }
  },
  computed: {
    ...mapState(["user", "token"])
  }
}
</script>

<style lang="less" scoped>
a:hover{
  color: #1890ff;
}
.deletBtn {
  color: #ff5c5e;
}
.block {
  margin-left: 10%;
}
/deep/.el-divider--horizontal {
  margin: 12px 0;
}
/deep/.el-table {
  width: 93% !important;
  margin-left: 45px;
  border: 1px solid #dde2ee;
  margin-top: 5%;
  float: left;
}
/deep/.el-table tr > th {
  background-color: #dde6ef;
}
.bodyBar {
  height: calc(100vh - 80px);
  width: 100%;
  float: left;
  padding: 30px 15%;
  background-color: #f6f7f9;
  .mainBar {
    width: 100%;
    height: 100%;
    .echoFile {
      font-size: 14px;
    }
    .el-form {
      width: 244px;
      float: right;
      .searchInput {
        width: 244px;
      }
    }
    .dataMain {
      width: 100%;
      height: 93%;
      margin-top: 20px;
      box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      background-color: #fff;
      .tableBar {
        width: 100%;
        height: 90%;
        .tableNav {
          width: 100%;
          // height: 88%;
          overflow-y: auto;
        }
      }
      .side {
        float: left;
      }
      .fileCound {
        float: left;
        margin-left: 10%;
        color: #000000;
        font-size: 16px;
      }
    }
    .downLoad {
      width: 112px;
      height: 40px;
      background-color: #fff;
      color: #1782fb;
      margin-left: 32px;
    }
    .danger {
      width: 112px;
      height: 40px;
      background-color: #fff;
      color: #fd5c5e;
      margin-left: 32px;
    }
  }
  .note {
    color: #7c7d80;
    font-size: 14px;
    width: 90%;
    margin-left: 5%;
    display: inline-block;
    margin-top: 20px;
  }
}
</style>
