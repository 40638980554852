<template>
  <div class="apmain">
    <!-- 顶部通栏 -->
    <div class="loginNav">
      <LoginNavBar></LoginNavBar>
    </div>
    <div class="loginapp">
      <div class="loginMain">
        <div class="loginLogo">
          <img class="loginImg" src="../../assets/img/reg-login.png" alt />
          <p class="loginTitle">登录巡护代码成功 !</p>
          <p class="logintext">若想继续浏览平台，请使用绑定手机号登录。</p>
          <el-button class="loginBtn" type="primary" @click="phoneLogin"
            >退出巡护登录</el-button
          >
          <div class="loginside"></div>
          <p class="bdtelphone">绑定手机号</p>
          <p class="logintext">
            巡护绑定，即巡护单位通过巡护代码登录后进行<br />手机号绑定，被绑定的手机号具有“巡护管理员”的<br />权限，每个巡护单位最多绑定三个手机号。
          </p>
          <div class="footerBar">
            <el-link class="register" @click="unBind" :underline="false"
              >解除巡护绑定</el-link
            >
            <div class="dividing"></div>
            <el-link class="forgetPsw" @click="changeUnitPsw" :underline="false"
              >更改巡护代码密码</el-link
            >
          </div>
          <div class="addreg">
            <div class="loginBox">
              <div v-if="statusList.length !== 0">
                <div
                  class="addSucce"
                  slot="reference"
                  v-for="item in statusList"
                  :key="item.id"
                >
                  <div class="turnOff">
                    <p class="user">账号:{{ item.mobile }}</p>
                    <p :title="item.name" class="username">
                      账户名称:{{ item.name }}
                    </p>
                    <i
                      class="iconfont offBtn"
                      v-if="showDel"
                      @click="delBtn(item)"
                      >&#xe639;</i
                    >
                    <img
                      class="bangding"
                      src="../../assets/img/binding.png"
                      alt
                    />
                  </div>
                  <el-dialog :visible.sync="outerVisible" width="300px">
                    <i class="el-icon-warning" style="color: #faad14"></i>
                    <span class="fmAsk"
                      >确定将
                      {{ userName }}
                      解除巡护绑定吗？解除后，该用户权限将变为巡护终端用户。</span
                    >
                    <div style="text-align: center; margin-top: 8px">
                      <el-button size="mini" type="primary" @click="sureBtn"
                        >确定</el-button
                      >
                    </div>
                  </el-dialog>
                </div>
              </div>
              <!-- 添加前 -->
              <el-popover
                v-for="(item, index) in this.num"
                :key="index"
                placement="right"
                width="200"
                trigger="click"
              >
                <i class="el-icon-warning" style="color: #faad14"></i>
                <span class="fmAsk"
                  >请问平台内人员绑定还是平台外人员绑定？</span
                >
                <div style="text-align: center; margin-top: 8px">
                  <el-button size="mini" type="primary" @click="inplatform"
                    >平台内</el-button
                  >
                  <el-button type="primary" @click="sendMsg" size="mini" plain
                    >平台外</el-button
                  >
                </div>
                <div class="addLmReg" slot="reference">
                  <div class="addButton">
                    <i class="el-icon-plus loginIcon"></i>
                    <span class="addregister">添加巡护绑定</span>
                  </div>
                </div>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Elastic ref="child" />
    <InflatForm ref="inflat" />
    <changeUnitPsw ref="change" />
  </div>
</template>

<script>
import LoginNavBar from "@/components/loginNavBar"; // 顶部通栏
import AddReg from "@/components/messageBox/addReg"; // 添加巡护绑定按钮and弹出框
import Elastic from "@/components/messageBox/elastic";
import InflatForm from "@/components/messageBox/inplatform";
import changeUnitPsw from "@/components/messageBox/changeUnitPsw";
import { mapState, mapMutations } from "vuex";
export default {
  inject: ["reload"],
  components: {
    LoginNavBar,
    AddReg,
    Elastic,
    InflatForm,
    changeUnitPsw,
  },
  data() {
    return {
      statusList: [],
      showsReg: true,
      num: "",
      showDel: false,
      outerVisible: false,
      userName: "",
      userID: 0,
    };
  },
  methods: {
    unBind() {
      this.showDel = !this.showDel;
    },
    delBtn(prama) {
      this.userName = prama.name;
      this.userID = prama.id;
      this.outerVisible = true;
    },
    // 确定删除
    sureBtn() {
      this.$apis
        .delManager(
          {
            unit_id: this.unit.id,
            user_id: this.userID,
          },
          this.unit_token
        )
        .then((data) => {
          this.reload();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 手机登录
    phoneLogin() {
      this.$store.commit("logOutUnit");
      this.$router.push("/");
    },
    // 平台外
    sendMsg() {
      this.$refs.child.open();
    },
    // 平台内
    inplatform() {
      this.$refs.inflat.open();
    },
    // 管理员列表
    uniterList() {
      this.$apis
        .listManager({}, this.unit_token)
        .then((data) => {
          this.statusList = data.data.data;
          this.num = 3 - this.statusList.length;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    changeUnitPsw() {
      this.$refs.change.open();
    },
  },
  created() {
    this.uniterList();
  },
  computed: {
    ...mapState(["unit", "unit_token"]),
  },
};
</script>

<style lang="less" scoped>
.offBtn {
  margin-left: 60px;
  color: #fd5c5e;
  cursor: pointer;
}
.user {
  float: left;
  line-height: 0px;
  margin-left: 15px;
  color: #606266;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  margin-top: 18px;
  // width: 130px;
}
.username {
  width: 130px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: left;
  margin-left: 15px;
  color: #606266;
  font-size: 14px;
  font-family: MicrosoftYaHei;
}
.bangding {
  float: right;
  margin-right: -2px;
  margin-bottom: 2px;
}
.turnOff {
  float: right;
}
.fmAsk {
  width: 234px;
  height: 56px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
}
.loginBox {
  display: flex;
  justify-content: center;
  margin-right: 50px;
  .addLmReg {
    width: 218px;
    height: 88px;
    background: rgba(246, 247, 249, 1);
    border-radius: 5px;
    border: 2px dashed rgba(245, 187, 75, 1);
    float: left;
    margin-left: 56px;
    .addButton {
      margin-left: 20px;
      .loginIcon {
        font-size: 32px;
        width: 30px;
        height: 30px;
        float: left;
        line-height: 84px;
        color: rgba(245, 187, 75, 1);
        // margin-left: 35px;
      }
      .addregister {
        font-size: 20px;
        color: rgba(245, 187, 75, 1);
        line-height: 84px;
      }
    }
  }
}
.addSucce {
  width: 218px;
  height: 88px;
  background: rgba(246, 247, 249, 1);
  border-radius: 5px;
  border: 1px solid rgba(221, 226, 238, 1);
  float: left;
  margin-left: 56px;
}
.footerBar {
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 50px;
}
.dividing {
  width: 1px;
  height: 20px;
  background: rgba(216, 216, 216, 1);
  margin: 0 20px 0 20px;
}
.register {
  cursor: pointer;
  color: #1182fb;
}
.forgetPsw {
  cursor: pointer;
  color: #1182fb;
}
.apmain {
  width: 100%;
  height: 100%;
  background-color: #dde5ed;
}
.loginapp {
  width: 96.88%;
  height: 84.63%;
  margin-top: 30px;
  float: left;
  margin-left: 30px;
  background-color: #fff;

  .loginMain {
    display: flex;
    justify-content: center;
    text-align: center;
    .loginLogo {
      margin-top: 90px;
      .loginImg {
        width: 70px;
        height: 70px;
      }
      .loginTitle {
        font-size: 24px;
        line-height: 32px;
        height: 32px;
      }
      .logintext {
        height: 32px;
        font-size: 14px;
        color: rgba(131, 138, 157, 1);
        line-height: 25px;
      }
      .otherlogin {
        width: 300px;
        .fmside {
          width: 1px;
          height: 20px;
          background: red;
        }
      }
      .loginBtn {
        width: 128px;
        height: 40px;
        font-size: 14px;
      }
      .loginside {
        width: 1440px;
        height: 1px;
        background: rgba(216, 216, 216, 1);
        margin-top: 52px;
      }
      .bdtelphone {
        height: 32px;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 32px;
        margin-top: 47px;
      }
      .addreg {
        margin-top: 42px;
        width: 1440px;
        height: 90px;
        .loginBox {
          display: flex;
          justify-content: center;
          margin-right: 50px;
          .addLmReg {
            width: 218px;
            height: 88px;
            background: rgba(246, 247, 249, 1);
            border-radius: 5px;
            border: 2px dashed rgba(245, 187, 75, 1);
            float: left;
            margin-left: 56px;
            .addButton {
              margin-left: 20px;
              .loginIcon {
                font-size: 32px;
                width: 30px;
                height: 30px;
                float: left;
                line-height: 84px;
                color: rgba(245, 187, 75, 1);
                // margin-left: 35px;
              }
              .addregister {
                font-size: 20px;
                color: rgba(245, 187, 75, 1);
                line-height: 84px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1650px) {
  .loginapp {
    width: 95.88%;
    height: 83.63%;
    margin-top: 30px;
    float: left;
    margin-left: 30px;
    background-color: #fff;
    .loginLogo {
      margin-top: 20px !important;
      .loginImg {
        width: 70px;
        height: 70px;
      }
      .loginTitle {
        font-size: 24px;
        line-height: 32px;
        height: 32px;
      }
      .logintext {
        height: 32px;
        font-size: 14px;
        color: rgba(131, 138, 157, 1);
        line-height: 32px;
      }
      .otherlogin {
        width: 300px;
        .fmside {
          width: 1px;
          height: 20px;
          background: red;
        }
      }
      .loginBtn {
        width: 128px;
        height: 40px;
        font-size: 16px;
        border-radius: 2px;
      }
      .loginside {
        width: 1000px !important;
        height: 1px;
        background: rgba(216, 216, 216, 1);
        margin-top: 42px;
        margin: 0 auto;
      }
      .bdtelphone {
        height: 32px;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 32px;
        margin-top: 47px;
      }
      .addreg {
        margin-top: 42px;
        width: 1440px;
        height: 90px;
        .loginBox {
          display: flex;
          justify-content: center;
          margin-right: 50px;
          .addLmReg {
            width: 218px;
            height: 88px;
            background: rgba(246, 247, 249, 1);
            border-radius: 5px;
            border: 2px dashed rgba(245, 187, 75, 1);
            float: left;
            margin-left: 56px;
            .addButton {
              margin-left: 20px;
              .loginIcon {
                font-size: 32px;
                width: 30px;
                height: 30px;
                float: left;
                line-height: 84px;
                color: rgba(245, 187, 75, 1);
                // margin-left: 35px;
              }
              .addregister {
                font-size: 20px;
                color: rgba(245, 187, 75, 1);
                line-height: 84px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
