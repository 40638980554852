<template>
  <div class="container">
    <div class="chat-user">
      <div class="header">
        <span v-if="chatAddDialog.type == 1">发起群聊</span>
        <span v-else-if="chatAddDialog.type == 2">发起音视频通话</span>
        <span v-else-if="chatAddDialog.type == 3">添加成员</span>
      </div>
      <div class="select-body">
        <div v-if="this.chatDialog.project.id">
          <div class="task-name task-name-no">
            {{ chatDialog.project.name }}
          </div>
        </div>
        <div v-else>
          <div class="task-name" @click="selectTask">
            {{ chatDialog.project.name ? chatDialog.project.name : "默认" }}
          </div>
        </div>
        <div class="option-box">
          <div class="add-member" @click="showSelectUserDialog">
            <img src="@/assets/img/chat/add_member.png" />
            <div class="btn-font">添加成员</div>
          </div>
          <div style="padding-top: 10px">
            <div class="hint">
              <i class="iconfont">&#xe616;</i>
              最多不能超过30人
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="user" v-show="confirmUsers.length == 0">暂无人员</div>
        <div class="user" v-show="confirmUsers.length > 0">
          <div class="user-box" v-for="user in confirmUsers.slice(0, 4)">
            <el-image class="avatar" :src="user.photo_url">
              <div slot="error" class="image-slot">
                <el-image
                  class="avatar"
                  :src="require('@/assets/img/user-default-head-big.png')"
                ></el-image>
              </div>
            </el-image>
            <div>{{ user.name }}</div>
          </div>
          <div class="user-num">共{{ confirmUsers.length }}人</div>
        </div>
      </div>
      <div class="bottom">
        <div style="width: 112px">
          <el-button
            type="primary"
            v-if="chatAddDialog.type == 1"
            v-loading="addLoading"
            @click="createRoom"
            :disabled="addLoading"
            >群聊</el-button
          >
          <el-button
            type="primary"
            v-else-if="chatAddDialog.type == 2"
            v-loading="addLoading"
            :disabled="addLoading"
            @click="createRoom"
            >音视频通话</el-button
          >
          <el-button
            type="primary"
            v-else-if="chatAddDialog.type == 3"
            v-loading="addLoading"
            :disabled="addLoading"
            @click="addUser"
            >添加成员</el-button
          >
        </div>
      </div>
    </div>
    <!-- 选择项目 -->
    <el-dialog
      v-dialogDrag
      :modal="false"
      :width="'80%'"
      :lock-scroll="false"
      :visible.sync="selectTaskDialogVisible"
    >
      <task-select
        @selectTask="isSelectTask"
        @cancelSelect="selectTaskDialogVisible = false"
      />
    </el-dialog>
    <!-- 选择成员 -->
    <el-dialog
      v-dialogDrag
      title="添加成员"
      class="el-dialog-wi"
      :visible.sync="selectUserDialogVisible"
      :modal="false"
      append-to-body
    >
      <user-select
        :key="chatAddDialog.key"
        @confirmSelect="confirmSelect"
        @cancelSelect="selectUserDialogVisible = false"
      />
    </el-dialog>
  </div>
</template>


<script>
import { mapState, mapMutations } from "vuex";
import taskSelect from "@/components/task/taskSelect";
import userSelect from "./userSelect";

export default {
  components: {
    taskSelect,
    userSelect,
  },
  data() {
    return {
      selectTaskDialogVisible: false,

      selectUserDialogVisible: false,

      confirmUsers: [], //确定的用户
      addLoading: false,
    };
  },
  methods: {
    /**
     * 子组件选择任务完成
     */
    isSelectTask(data) {
      this.chatDialog.project.id = data.id;
      this.chatDialog.project.name = data.project_name;
      this.selectTaskDialogVisible = false;
    },
    showSelectUserDialog() {
      this.selectUserDialogVisible = true;
    },

    /**
     * 选择任务
     */
    selectTask() {
      this.selectTaskDialogVisible = true;
    },

    /**
     * 确认选择的用户
     */
    confirmSelect(user) {
      if (user.length == 0) {
        this.$message({ type: "error", message: "请选择用户" });
        return false;
      }
      if (user.length > 30) {
        this.$message({ type: "error", message: "最多不能超过30人" });
        return false;
      }
      this.selectUserDialogVisible = false;
      this.confirmUsers = user;
    },
    /**
     * 创建群聊
     */
    createRoom() {
      this.addLoading = true
      let userArr = [];
      this.confirmUsers.forEach((item) => {
        userArr.push(item.id);
      });
      userArr.unshift(this.user.id);
      this.$apis
        .chatCreateRoom(
          {
            type: this.chatAddDialog.type,
            project_id: this.chatDialog.project.id
              ? this.chatDialog.project.id
              : 0,
            task_id: this.chatDialog.task.id ? this.chatDialog.task.id : 0,
            user_ids: userArr.join(","),
          },
          this.token
        )
        .then((res) => {
          this.addLoading = false
          if (res.data.status == 0) {
            this.chatDialog.show = true;
            this.chatAddDialog.show = false;
            this.chatAddDialog.result = res.data;
          }
        }).catch(() => {
          this.addLoading = false
        });
    },

    /**
     * 添加成员
     */
    addUser() {
      let userArr = [];
      this.confirmUsers.forEach((item) => {
        let find = this.chatAddDialog.add_user_data.users.find((v) => {
          return v.id == item.id;
        });
        if (!find) {
          userArr.push(item.id);
        }
      });
      if (userArr.length == 0) {
        this.$message({ type: "error", message: "请添加新的成员" });
        return;
      }

      this.$apis
        .chatAddRoomUser(
          {
            room_id: this.chatAddDialog.add_user_data.room_id,
            user_ids: userArr.join(","),
          },
          this.token
        )
        .then((res) => {
          this.addLoading = false
          if (res.data.status == 0) {
            this.chatAddDialog.show = false;
            this.chatAddDialog.result = res.data;
          }
        }).catch(() => {
          this.addLoading = false
        });
    },
  },
  created() {},
  mounted() {
    if (this.chatAddDialog.type == 3) {
      //已存在音视频房间再次添加成员
      this.chatAddDialog.add_user_data.users.forEach((item) => {
        item.id = item.user_id;
        item.photo_url = item.photo;
        delete item.user_id;
        delete item.photo;
        if (item.id != this.user.id) {
          this.confirmUsers.push(item);
        }
      });
    }
  },
  computed: {
    ...mapState(["user", "token", "chatAddDialog", "chatDialog"]),
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/iconfont/iconfont.css");
.container {
  height: 687px;
  overflow-y: auto;
}

.chat-user {
  width: 100%;
  height: 100%;
  background: #000d43;
  color: #838a9d;
  display: flex;
  flex-direction: column;
  .header {
    width: 100%;
    height: 69px;
    background: #000620;
    border-bottom: 9px solid rgb(36, 47, 93);
    padding: 0 44px;
    color: #ffffff;
    line-height: 60px;
    font-size: 14px;
  }
  .select-body {
    flex: 1;
    padding: 131px 138px 0 138px;
    .task-name {
      margin-top: 7px;
      width: 292px;
      height: 40px;
      background: rgba(255, 255, 255, 0.15);
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 40px;
      padding: 0 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .task-name-no {
      cursor: not-allowed;
    }
    .option-box {
      margin-top: 30px;
      display: flex;
      .add-member {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .btn-font {
          margin-top: 4px;
          text-align: center;
        }
        img {
          width: 80px;
          height: 40px;
          cursor: pointer;
        }
      }
      .hint {
        margin-left: 24px;
        display: flex;
        align-items: center;
        i {
          display: block;
          margin-right: 5px;
        }
      }
    }
    .line {
      margin-top: 52px;
      margin-bottom: 30px;
      background: #3c4353;
      width: 524px;
      height: 1px;
    }
    .user {
      display: flex;
      font-size: 14px;
      align-items: center;
      .user-box {
        color: #ffffff;
        margin-right: 12px;
        display: flex;
        flex-direction: column;
        .avatar {
          width: 88px;
          height: 88px;
          border-radius: 50%;
          margin-bottom: 15px;
        }
      }
      .user-num {
        margin-left: 12px;
        color: #1182fb;
      }
    }
  }
  .bottom {
    width: 100%;
    height: 111px;
    background: #000620;
    border-top: 9px solid rgb(36, 47, 93);
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
