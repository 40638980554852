import { render, staticRenderFns } from "./news.vue?vue&type=template&id=25603163&scoped=true"
import script from "./news.vue?vue&type=script&lang=js"
export * from "./news.vue?vue&type=script&lang=js"
import style0 from "./news.vue?vue&type=style&index=0&id=25603163&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25603163",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("G:\\work\\projects\\vue3\\gx_web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25603163')) {
      api.createRecord('25603163', component.options)
    } else {
      api.reload('25603163', component.options)
    }
    module.hot.accept("./news.vue?vue&type=template&id=25603163&scoped=true", function () {
      api.rerender('25603163', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/news.vue"
export default component.exports