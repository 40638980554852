<template>
  <div>
    <div class="appBar">
      <div class="bodyBar">
        <div class="head">
          <div class="signBar"></div>
          <span class="title">消息中心</span>
        </div>
        <div class="center">
          <div class="content" v-for="(item, index) in noticeList" :key="index">
            <div class="content-box" v-if="item.type == 4">
              <div v-if="item.data_apply.status == 1">
                <span class="contword">{{ item.created_at }}</span>
                <span class="cont">有编号为：</span>
                <span class="contnum" @click="turnData(item)">{{
                  item.data_apply.data_apply_no
                }}</span>
                <span class="cont">的数据申请待审核</span>
              </div>
              <div
                v-else-if="
                  item.data_apply.status == 2 || item.data_apply.status == 3
                "
              >
                <span class="contword">{{ item.created_at }}</span>
                <span class="cont">您的数据申请：</span>
                <span class="contnum" @click="turnData(item)">{{
                  item.data_apply.data_apply_no
                }}</span>
                <span class="cont">审核结果为：</span>
                <span class="contword">{{
                  item.data_apply.status | dataApplyStatus
                }}</span>
              </div>
              <div v-else>
                <span class="contword">{{ item.created_at }}</span>
                <span class="cont">编号为：</span>
                <span class="contnum" @click="turnData(item)">{{
                  item.data_apply.data_apply_no
                }}</span>
                <span class="cont">的数据申请，</span>
                <span class="contword">{{
                  item.data_apply.status | dataApplyStatus
                }}</span>
              </div>
            </div>
            <div class="content-box" v-if="item.type == 5">
              <div v-if="item.warning.status == 0">
                <span class="contword">{{ item.created_at }}</span>
                <span class="cont">有新的预警信息待审核：</span>
                <span class="contnum" @click="turnData(item)">{{
                  item.warning.area_name
                }}</span>
              </div>
              <div v-else>
                <span class="contword">{{ item.created_at }}</span>
                <span class="cont">您的预警信息：</span>
                <span class="contnum" @click="turnData(item)">{{
                  item.warning.area_name
                }}</span>
                <span class="cont">审核结果为：</span>
                <span class="cont">{{
                  item.warning.status | warningStatus
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="bottom">
          <el-pagination
            :current-page="current_page"
            :page-size="this.per_page"
            layout="total,  prev, pager, next, jumper"
            :total="this.total"
            @current-change="currentchange"
            @next-click="handleNext"
            @prev-click="handlePrev"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      noticeList: [],
      current_page: 1, // 当前页
      total: 0, // 总条目数
      per_page: 10, // 每页多少条
      page: 1,
    };
  },
  methods: {
    // 获取消息列表
    getNoticeList() {
      this.noticeList = [];
      this.$apis
        .getNoticeList(
          {
            type: "4,5,6",
            per_page: this.per_page,
            page: this.page,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status == 0) {
            this.noticeList = data.data.data.data;
            this.total = data.data.data.total;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 数据跳转
    turnData(param) {
      switch (param.type) {
        case 4:
          if (param.data_apply.status == 1) {
            this.$router.push({
              path: "/auditpage",
              query: { id: param.data_apply.id },
            });
          } else {
            this.$router.push({
              path: "/datadetails",
              query: { id: param.data_apply.id },
            });
          }
          break;
        case 5:
          if (param.warning.status == 0) {
            this.$router.push({
              path: "/warningmanage",
              query: { nav: "wman" },
            });
          } else {
            this.$router.push({
              path: "/details",
              query: { id: param.warning.id },
            });
          }
          break;
        case 6:
          break;
      }
    },
    // 分页
    handleNext() {
      this.page++;
      this.getNoticeList();
    },
    handlePrev() {
      this.page--;
      this.getNoticeList();
    },
    currentchange(page) {
      this.page = page;
      this.getNoticeList();
    },
  },
  created() {
    this.getNoticeList();
  },
  computed: {
    ...mapState(["token", "user"]),
  },
  filters: {
    dataApplyStatus(val) {
      let text = "";
      switch (val + "") {
        case "1":
          text = "审核中";
          break;
        case "2":
          text = "待确认";
          break;
        case "3":
          text = "已退回";
          break;
        case "4":
          text = "用户已成交";
          break;
        case "5":
          text = "用户已终止";
          break;
      }
      return text;
    },
    warningStatus(val) {
      let text = "";
      switch (val + "") {
        case "0":
          text = "待审核";
          break;
        case "1":
          text = "已通过";
          break;
        case "2":
          text = "已驳回";
          break;
      }
      return text;
    },
  },
};
</script>

<style lang="less" scoped>
.bottom {
  height: 75px;
  display: flex;
  align-items: center;
}
.appBar {
  width: 100%;
  height: calc(100vh - 50px);
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
}
.bodyBar {
  width: 70%;
  background-color: #ffffff;
  margin-top: 20px;
  padding: 30px 20px 0px 20px;
  display: flex;
  flex-direction: column;
}
.signBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    color: #828a9e;
    font-size: 16px;
  }
}
.dividing {
  height: 1px;
  width: 100%;
  border: 1px dashed #828a9e;
  float: left;
  margin-top: 20px;
}
.center {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.content {
  height: 10%;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #828a9e;
  .contword {
    font-size: 13px;
    color: #3c3d41;
    line-height: 25px;
    margin-right: 30px;
  }
  .cont {
    font-size: 13px;
    color: #3c3d41;
    line-height: 25px;
  }
  .contnum {
    font-size: 13px;
    color: #1782fb;
    text-decoration: underline;
    cursor: pointer;
    line-height: 25px;
    margin-right: 5px;
  }
}
</style>