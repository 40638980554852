import store from "@/store";
import { apis } from "@/http/api";
import coordtransform from "@/utils/transform.js";
class RequestData {
  constructor() {
    this.sortiesListData = {
      彩虹: [],
      其他: [],
      测绘装备: [],
      终端设备: [],
      未知: []
    };
    this.categoryBrandData = { 彩虹无人机: { 其他: {}, 广西: [] } };
    this.temCategoryBrandData = { 彩虹无人机: { 其他: {}, 广西: [] } };
    this.historySortiesData = []; //一开始所有的历史架次数据
    this.dateHistorySortiesData = []; //时间筛选的历史数据
    this.temTestOnline = [];
    this.getDeviceCategoryBrand().then(data => {
      // this.categoryBrandData = data;
      for (let key in data) {
        this.temCategoryBrandData[key] = {};
        data[key].forEach(item => {
          this.temCategoryBrandData[key][item] = [];
        });
      }
    });
  }
  // 获取架次数量RequestData
  getSortiesCount(date) {
    let params = {};
    if (date !== "") {
      params = date;
    }
    return new Promise((resolve, reject) => {
      apis.sortiesCount(params, store.state.token).then(res => {
        let data = res.data.data;
        resolve(data);
      });
    });
  }
  // 通过日期获取数据
  getDateSortiesList(data, page) {
    let temCategoryBrandData = JSON.parse(
      JSON.stringify(this.categoryBrandData)
    );
    let wgsCategoryBrandData = JSON.parse(
      JSON.stringify(this.categoryBrandData)
    );
    let allData = { wgs84: {}, gcj02: {} };
    if (page === 1) {
      this.dateHistorySortiesData = [];
    }
    data["per_page"] = 2000;
    return new Promise((resolve, reject) => {
      apis.sortiesList(data, store.state.token).then(res => {
        let data = res.data.data.data;
        this.dateHistorySortiesData = this.dateHistorySortiesData.concat(data);
        temCategoryBrandData = this.analysisData(
          this.dateHistorySortiesData,
          temCategoryBrandData,
          "gcj02"
        );
        wgsCategoryBrandData = this.analysisData(
          this.dateHistorySortiesData,
          wgsCategoryBrandData,
          "wgs84"
        );
        allData["totalPage"] = res.data.data.total;
        allData["per_page"] = res.data.data.per_page;
        allData["gcj02"] = temCategoryBrandData;
        allData["wgs84"] = wgsCategoryBrandData;
        resolve(allData);
      });
    });
  }
  getMassData(data, lnglatText) {
    let that = this;
    let temCategoryBrandData = JSON.parse(
      JSON.stringify(that.temCategoryBrandData)
    );
    let wgsCategoryBrandData = JSON.parse(
      JSON.stringify(that.temCategoryBrandData)
    );
    let allData = { wgs84: {}, gcj02: {} };
    temCategoryBrandData = this.analysisData(
      data,
      temCategoryBrandData,
      "gcj02",
      lnglatText
    );
    wgsCategoryBrandData = this.analysisData(
      data,
      wgsCategoryBrandData,
      "wgs84",
      lnglatText
    );
    allData["totalPage"] = data.length;
    allData["gcj02"] = temCategoryBrandData;
    allData["wgs84"] = wgsCategoryBrandData;
    return allData;
  }
  // 获取架次列表
  getSortiesList(data, page = -1) {
    let that = this;

    return new Promise((resolve, reject) => {
      let params = data;
      let temCategoryBrandData = JSON.parse(
        JSON.stringify(that.temCategoryBrandData)
      );
      let wgsCategoryBrandData = JSON.parse(
        JSON.stringify(that.temCategoryBrandData)
      );
      let allData = { wgs84: {}, gcj02: {} };
      if (page !== -1) {
        params["page"] = page;
      }
      data["per_page"] = 2000;
      apis.sortiesList(params, store.state.token).then(res => {
        let data = res.data.data.data;
        this.historySortiesData = this.historySortiesData.concat(data);
        temCategoryBrandData = this.analysisData(
          this.historySortiesData,
          temCategoryBrandData,
          "gcj02"
        );
        wgsCategoryBrandData = this.analysisData(
          this.historySortiesData,
          wgsCategoryBrandData,
          "wgs84"
        );
        allData["totalPage"] = res.data.data.total;
        allData["gcj02"] = temCategoryBrandData;
        allData["wgs84"] = wgsCategoryBrandData;
        // temCategoryBrandData["totalPage"] = res.data.data.total;
        resolve(allData);
      });
    });
  }

  // 获取设备品牌
  getDeviceCategoryBrand() {
    return new Promise((resolve, reject) => {
      apis.deviceCategoryBrand({}, store.state.token).then(res => {
        let data = res.data.data;
        // this.categoryBrandData = data;
        for (let key in data) {
          this.categoryBrandData[key] = {};
          data[key].forEach(item => {
            this.categoryBrandData[key][item] = [];
          });
        }
        resolve(data);
      });
    });
  }
  getOnlineData() {
    return new Promise((resolve, reject) => {
      apis.deviceViewIndex({ is_online: 1 }, store.state.token).then(res => {
        let data = res.data.data;
        resolve(data);
      });
    });
  }
  // 获取设备视图
  getDeviceViewIndex(is_online = "") {
    let temCategoryBrandData = JSON.parse(
      JSON.stringify(this.categoryBrandData)
    );
    let temData1 = JSON.parse(JSON.stringify(this.categoryBrandData));
    let temData2 = JSON.parse(JSON.stringify(this.categoryBrandData));
    let temData3 = JSON.parse(JSON.stringify(this.categoryBrandData));
    let temData4 = JSON.parse(JSON.stringify(this.categoryBrandData));
    let temData5 = "";
    let temData6 = "";
    let temData7 = "";
    let temData8 = "";
    let total = 0;
    // temCategoryBrandData["无人机"]["彩虹无人机"] = [];
    let params = is_online === "" ? {} : { is_online: is_online };
    return new Promise((resolve, reject) => {
      apis
        .deviceViewIndex(params, store.state.token)
        .then(res => {
          let data = res.data.data;
          // for (let i=0;i<3;i++){
          //   data = data.concat(data)
          // }
          if (is_online === "") {
            temData5 = this.getMassData(data, "last_point");
            temData6 = this.getMassData(data, "last_two_point");
            temData7 = this.getMassData(data, "register_point");
            temData8 = this.getMassData(data, "register_point");
          }
          data.forEach((item, index) => {
            // if (
            //   item._source.last_point.lon
            // ) {
            let val = item._source;

            if (is_online === "") {
              if (val.last_point.lon) {
                let temItem = JSON.parse(JSON.stringify(item));
                temItem["lnglat"] = [val.last_point.lon, val.last_point.lat];

                temData1 = this.analysisHistoryData(val, temData1, temItem);
                // let testTemData1 = this.getMassData(val)
              }
              if (val.last_two_point.lon) {
                let temItem = JSON.parse(JSON.stringify(item));
                temItem["lnglat"] = [
                  val.last_two_point.lon,
                  val.last_two_point.lat
                ];
                temData2 = this.analysisHistoryData(val, temData2, temItem);
              }
              if (val.register_point.lon) {
                let temItem = JSON.parse(JSON.stringify(item));
                temItem["lnglat"] = [
                  val.register_point.lon,
                  val.register_point.lat
                ];
                temData3 = this.analysisHistoryData(val, temData3, temItem);
              }
              total = total + 1;
              temData4 = this.analysisHistoryData(val, temData4, item);
            } else {
              if (!val.last_point.lon || !val.last_point.lat) return
              if (
                val.category_name === "终端设备" &&
                val.brand_name === "林业巡护"
              ) {
                item["gcj02"] = [val.last_point.lon, val.last_point.lat];
                item["wgs84"] = coordtransform.gcj02towgs84(
                  val.last_point.lon,
                  val.last_point.lat
                );
              } else {
                item["wgs84"] = [val.last_point.lon, val.last_point.lat];
                item["gcj02"] = coordtransform.wgs84togcj02(
                  val.last_point.lon,
                  val.last_point.lat
                );
              }
              switch (val.category_name) {
                case "无人机":
                  if (val.brand_name === "彩虹") {
                    if (val.register_province.indexOf("广西") !== -1) {
                      temCategoryBrandData["彩虹无人机"]["广西"].push(item);
                    } else {
                      if (
                        temCategoryBrandData["彩虹无人机"]["其他"][
                          item._source.register_province
                        ]
                      ) {
                        temCategoryBrandData["彩虹无人机"]["其他"][
                          item._source.register_province
                        ].push(item);
                      } else {
                        temCategoryBrandData["彩虹无人机"]["其他"][
                          item._source.register_province
                        ] = [];
                        temCategoryBrandData["彩虹无人机"]["其他"][
                          item._source.register_province
                        ].push(item);
                      }
                    }
                  } else {
                    temCategoryBrandData["无人机"][val.brand_name].push(item);
                  }
                  break;
                case "测绘装备":
                  temCategoryBrandData["测绘装备"][val.brand_name].push(item);
                  break;
                case "终端设备":
                  temCategoryBrandData["终端设备"][val.brand_name].push(item);
                  break;
                default:
                  break;
              }
            }
            // } else {
            //   // console.log("错误经纬度",item._source);
            // }
          });
          if (is_online === "") {
            temCategoryBrandData = [
              temData1,
              temData2,
              temData3,
              temData4,
              temData5,
              temData6,
              temData7,
              temData8
            ];
          }
          // console.log(temCategoryBrandData);
          resolve({ data: temCategoryBrandData, allOnlineData: res.data.data });
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  analysisHistoryData(val, temCategoryBrandData, item) {
    item["name"] = item._source;
    if (item.lnglat) {
      if (val.category_name === "终端设备" && val.brand_name === "林业巡护") {
        item["gcj02"] = item.lnglat;
        item["wgs84"] = coordtransform.gcj02towgs84(
          item.lnglat[0],
          item.lnglat[1]
        );
      } else {
        item["wgs84"] = item.lnglat;

        item["gcj02"] = coordtransform.wgs84togcj02(
          item.lnglat[0],
          item.lnglat[1]
        );
      }
    }

    switch (val.category_name) {
      case "无人机":
        if (val.brand_name === "彩虹") {
          // temCategoryBrandData["无人机"]["彩虹无人机"].push(item);
          if (val.register_province.indexOf("广西") !== -1) {
            temCategoryBrandData["彩虹无人机"]["广西"].push(item);
          } else {
            if (
              temCategoryBrandData["彩虹无人机"]["其他"][
                item._source.register_province
              ]
            ) {
              temCategoryBrandData["彩虹无人机"]["其他"][
                item._source.register_province
              ].push(item);
            } else {
              temCategoryBrandData["彩虹无人机"]["其他"][
                item._source.register_province
              ] = [];
              temCategoryBrandData["彩虹无人机"]["其他"][
                item._source.register_province
              ].push(item);
            }
            // temCategoryBrandData["彩虹无人机"]["其他"].push(item)
          }
        } else {
          temCategoryBrandData["无人机"][val.brand_name].push(item);
        }
        break;
      case "测绘装备":
        temCategoryBrandData["测绘装备"][val.brand_name].push(item);
        break;
      case "终端设备":
        temCategoryBrandData["终端设备"][val.brand_name].push(item);
        break;
      default:
        break;
    }
    if (item._source.online === 1) {
      this.temTestOnline.push(item);
    }
    return temCategoryBrandData;
  }
  analysisData(
    data,
    temCategoryBrandData,
    type = "gcj02",
    lnglatText = "point"
  ) {
    data.forEach(item => {
      if (item._source[lnglatText].lon) {
        let val = item._source;
        let temData = {};
        let gcjData = {}; //存储gcj02坐标系
        let wgsData = {}; //存储wgs84坐标系

        if (val.category_name === "终端设备" && val.brand_name === "林业巡护") {
          gcjData["lnglat"] = [
            item._source[lnglatText].lon,
            item._source[lnglatText].lat
          ];
          wgsData["lnglat"] = coordtransform.gcj02towgs84(
            item._source[lnglatText].lon,
            item._source[lnglatText].lat
          );
        } else {
          gcjData["lnglat"] = coordtransform.wgs84togcj02(
            item._source[lnglatText].lon,
            item._source[lnglatText].lat
          );
          wgsData["lnglat"] = [
            item._source[lnglatText].lon,
            item._source[lnglatText].lat
          ];
        }

        gcjData["style"] = 0;
        gcjData["name"] = item._source;
        wgsData["style"] = 0;
        wgsData["name"] = item._source;

        wgsData["_source"] = item._source; //要删除
        wgsData["gcj02"] = [
          item._source[lnglatText].lon,
          item._source[lnglatText].lat
        ];
        wgsData["wgs84"] = [
          item._source[lnglatText].lon,

          item._source[lnglatText].lat
        ];
        if (type === "gcj02") {
          temData = gcjData;
        } else {
          temData = wgsData;
        }
        switch (val.category_name) {
          case "无人机":
            if (val.brand_name === "彩虹") {
              // temCategoryBrandData["无人机"]["彩虹无人机"].push(item);
              if (val.register_province.indexOf("广西") !== -1) {
                temCategoryBrandData["彩虹无人机"]["广西"].push(temData);
              } else {
                if (
                  temCategoryBrandData["彩虹无人机"]["其他"][
                    item._source.register_province
                  ]
                ) {
                  temCategoryBrandData["彩虹无人机"]["其他"][
                    item._source.register_province
                  ].push(temData);
                } else {
                  temCategoryBrandData["彩虹无人机"]["其他"][
                    item._source.register_province
                  ] = [];
                  temCategoryBrandData["彩虹无人机"]["其他"][
                    item._source.register_province
                  ].push(temData);
                }
              }
            } else {
              if (!temCategoryBrandData["无人机"]) {
                temCategoryBrandData["无人机"] = {};
              }
              if (temCategoryBrandData["无人机"][val.brand_name]) {
                temCategoryBrandData["无人机"][val.brand_name].push(temData);
              } else {
                temCategoryBrandData["无人机"][val.brand_name] = [];
                temCategoryBrandData["无人机"][val.brand_name].push(temData);
              }
            }
            break;
          case "测绘装备":
            if (!temCategoryBrandData["测绘装备"]) {
              temCategoryBrandData["测绘装备"] = {};
            }
            if (temCategoryBrandData["测绘装备"][val.brand_name]) {
              temCategoryBrandData["测绘装备"][val.brand_name].push(temData);
            } else {
              temCategoryBrandData["测绘装备"][val.brand_name] = [];
              temCategoryBrandData["测绘装备"][val.brand_name].push(temData);
            }
            break;
          case "终端设备":
            if (!temCategoryBrandData["终端设备"]) {
              temCategoryBrandData["终端设备"] = {};
            }
            if (temCategoryBrandData["终端设备"][val.brand_name]) {
              temCategoryBrandData["终端设备"][val.brand_name].push(temData);
            } else {
              temCategoryBrandData["终端设备"][val.brand_name] = [];
              temCategoryBrandData["终端设备"][val.brand_name].push(temData);
            }
            break;
          default:
            break;
        }
      }
    });
    return temCategoryBrandData;
  }
}
export default RequestData;
