var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dioContent" }, [
    _c("div", { staticClass: "information1" }, [
      _c("div", { staticClass: "number" }, [
        _vm.statsTexts && _vm.statsTexts.sup
          ? _c("div", { staticClass: "setSup" }, [
              _vm._v("\n        " + _vm._s(_vm.statsTexts.sup) + "\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "setTitle" }, [
          _vm._v("\n        " + _vm._s(_vm.statsTexts.title) + "\n      "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "setData" }, [
          _vm._v("\n        " + _vm._s(_vm.statsTexts.data) + "\n      "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "setUnit" }, [
          _vm._v("\n        " + _vm._s(_vm.statsTexts.unit) + "\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "setChart", attrs: { id: "chart" } }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "moreDatas" },
      _vm._l(_vm.data, function (val, key, index) {
        return _c("div", { key: key, staticClass: "datas" }, [
          _c("div", { staticClass: "title" }, [
            _c("span", {
              staticClass: "sign",
              style: { backgroundColor: _vm.color[index] },
            }),
            _vm._v(_vm._s(key)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "data", attrs: { title: val } }, [
            _vm._v(_vm._s(val)),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }