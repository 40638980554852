<template>
  <el-dialog
    title="评分评价"
    width="35%"
    :visible.sync="dialogTableVisible"
    :before-close="shut"
  >
    <div class="partition"></div>
    <div class="pop">
      <div class="pop-cen">
        <div class="task-name posi">{{ project_name }}</div>
        <div class="task-block posi">{{ block_name }}</div>
        <div class="task-list-name posi">{{ item.task_name }}</div>
        <div class="pop-body">
          <div class="rate-div">
            <span>响应速度</span>
            <el-rate
              :colors="colors"
              v-model="speedValue"
              :texts="texts"
              show-text
            ></el-rate>
          </div>
          <div class="rate-div">
            <span>过程调度</span>
            <el-rate
              :colors="colors"
              v-model="processValue"
              :texts="texts"
              show-text
            ></el-rate>
          </div>
          <div class="rate-div">
            <span>服务态度</span>
            <el-rate
              :colors="colors"
              v-model="attitudeValue"
              :texts="texts"
              show-text
            ></el-rate>
          </div>
          <div class="rate-div">
            <span>成果质量</span>
            <el-rate
              :colors="colors"
              v-model="qualityValue"
              :texts="texts"
              show-text
            ></el-rate>
          </div>
          <div class="rate-div">
            <span>总体评分</span>
            <el-rate
              :colors="colors"
              v-model="overallValue"
              :texts="texts"
              show-text
            ></el-rate>
          </div>
          <div>
            <span>评价建议</span>
            <el-input
              v-model="suggest"
              class="area"
              type="textarea"
              maxlength="200"
              :show-word-limit="true"
              placeholder="请写下您的评价建议吧"
            />
          </div>
        </div>
      </div>
      <el-button class="btn posi" @click="submit" type="primary"
        >提交</el-button
      >
      <el-button class="btn posi cancel" @click="cancelsBtn">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      dialogTableVisible: false,
      speedValue: null, //  响应速度
      processValue: null, //  过程调度
      attitudeValue: null, //  服务态度
      qualityValue: null, //  成果质量
      overallValue: null, //  总体评分
      suggest: "", //  评价建议
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      texts: ["较差", "一般", "良好", "优秀", "优秀"],
      item: {},
      project_name: "",
      block_name: "",
    };
  },
  mounted() {
    this.monitoring();
  },
  computed: {
    ...mapState(["user", "token", "role", "currentUrl"]),
  },
  methods: {
    /**
     * 关闭
     */
    shut() {
      Object.assign(this.$data, this.$options.data());
    },
    cancelsBtn() {
      this.shut();
      this.dialogTableVisible = false;
    },
    /**
     * 监听数据
     */
    monitoring() {
      this.$on("open", (res) => {
        this.project_name = res.project_name;
        this.block_name = res.block_name;
        this.item = res.item;
        this.dialogTableVisible = true;

        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });

        this.$apis
          .taskSetTaskScore(
            {
              task_id: this.item.id,
              action: "list",
            },
            this.token
          )
          .then((data) => {
            console.log(data);
            loading.close();
            if (data.data.status === 0 && data.data.data != null) {
              this.speedValue = data.data.data.response_speed;
              this.processValue = data.data.data.process_scheduling;
              this.attitudeValue = data.data.data.service_attitude;
              this.qualityValue = data.data.data.quality_of_results;
              this.overallValue = data.data.data.overall_score;
              this.suggest = data.data.data.advise;
            }
          })
          .catch((err) => {
            loading.close();
            console.error(err);
          });
      });
    },
    /**
     * 提交评价
     */
    submit() {
      if (
        this.speedValue !== null &&
        this.processValue !== null &&
        this.attitudeValue !== null &&
        this.qualityValue !== null &&
        this.overallValue !== null &&
        this.suggest !== ""
      ) {
        this.taskSetTaskScore();
      } else {
        this.utils.$message("请填写所有评价信息", "error");
      }
    },
    /**
     * 评分评价
     */
    taskSetTaskScore() {
      this.$apis
        .taskSetTaskScore(
          {
            task_id: this.item.id,
            response_speed: this.speedValue,
            process_scheduling: this.processValue,
            service_attitude: this.attitudeValue,
            quality_of_results: this.qualityValue,
            overall_score: this.overallValue,
            advise: this.suggest,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status === 0) {
            this.utils.$message("评价成功");
            setTimeout(() => {
              this.dialogTableVisible = false;
              Object.assign(this.$data, this.$options.data());
            }, 1500);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.posi {
  position: absolute;
}

.partition {
  height: 1px;
  width: 100%;
  background: rgba(221, 226, 238, 1);
  transform: translateY(-20px);
}
.pop {
  position: relative;
  width: 100%;
  min-height: 380px;
  padding-bottom: 16px;
}

.pop-cen {
  width: 100%;

  .task-name {
    left: 7.5%;
    top: 1.85vh;
    max-width: 140px;
  }

  .task-block {
    left: 7.5%;
    top: 8vh;
    color: #3c4353;
    font-weight: 700;
  }

  .task-list-name {
    left: 10.88%;
    top: 11.25vh;
  }

  .pop-body {
    padding-top: 1.85vh;
    margin-left: 33.3%;
    .rate-div {
      height: 20px;
    }
    div {
      margin-bottom: 24px;
      display: flex;
      span {
        color: #3c4353;
        margin-right: 27px;
      }
    }
  }

  .area {
    width: 76%;
  }
}

.btn {
  bottom: 0;
  right: 27.6%;
}
.cancel {
  right: 5.83%;
}
</style>
