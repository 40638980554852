var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dialogSingleMonitor" },
    [
      _vm.singleDialogVisible
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                modal: false,
                visible: _vm.singleDialogVisible,
                "show-close": true,
                "append-to-body": true,
                "custom-class": "singleM",
                width: "88%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.singleDialogVisible = $event
                },
                close: _vm.closeMonitor,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "dialog_title",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src: _vm.terminal.device_data.device_brands_icon,
                      alt: "",
                      width: "56",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "area" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/homeDialog/area.png"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "area_data" }, [
                      _vm._v(_vm._s(_vm.flyArea)),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticStyle: { "font-size": "13px" } }, [
                      _vm._v("已飞面积（k㎡）"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "location" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/homeDialog/location.png"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.terminal.device_data.last_point.province !==
                              undefined,
                            expression:
                              "terminal.device_data.last_point.province !== undefined",
                          },
                        ],
                        staticStyle: { "font-size": "13px" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.terminal.device_data.last_point.province +
                              "-" +
                              _vm.terminal.device_data.last_point.city +
                              "-" +
                              _vm.terminal.device_data.last_point.area
                          )
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.terminal.monitor_type !== "common",
                          expression: "terminal.monitor_type !== 'common'",
                        },
                      ],
                      staticClass: "feimaPlaneDatas",
                    },
                    [
                      _c("div", { staticClass: "feimaPlaneData" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/homeDialog/mobile_asset/gps_normal.png"),
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          attrs: {
                            src: _vm.feimaDroneInfo.gps_signal_icon,
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "feimaPlaneSingal" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.feimaDroneInfo.gps_signal_sup) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "feimaPlaneData" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/homeDialog/mobile_asset/hd_normal.png"),
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          attrs: {
                            src: _vm.feimaDroneInfo.hd_signal_icon,
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "feimaPlaneHd" }, [
                          _vm._v(_vm._s(_vm.feimaDroneInfo.hd_signal_sup)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "feimaPlaneData" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/homeDialog/mobile_asset/drone_battery_disconnected.png"),
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _vm.feimaDroneInfo.drone_battery_percent_0 &&
                        _vm.feimaDroneInfo.drone_battery_percent_0 !==
                          "undefined%"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.feimaDroneInfo.drone_battery_percent_0
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.terminal.monitor_type === "common",
                        expression: "terminal.monitor_type === 'common'",
                      },
                    ],
                    staticClass: "plane_information",
                    attrs: {
                      src: require("@/assets/img/homeDialog/plane_information.png"),
                      alt: "",
                    },
                    on: { click: _vm.lookPlaneData },
                  }),
                  _vm._v(" "),
                  _c("div"),
                ]
              ),
              _vm._v(" "),
              _c("singleMonitor", {
                ref: "singleMonitor",
                attrs: {
                  "plane-type": _vm.terminal.monitor_type,
                  software_code: _vm.terminal.device_data.software_code,
                  order_id: _vm.terminal.droneInfo.mission_id,
                  "is-image-map": _vm.isImageMap,
                  "list-device-data": _vm.listDeviceData,
                  "sn-id": _vm.terminal.droneInfo.software_code,
                  "brand-name": _vm.terminal.droneInfo.brand_name,
                  source: _vm.source,
                },
                on: {
                  setFlyArea: _vm.setFlyArea,
                  setFeimaDroneInfo: _vm.setFeimaDroneInfo,
                },
              }),
              _vm._v(" "),
              _vm.isShowData
                ? _c(
                    "div",
                    { staticClass: "plane_data" },
                    [
                      _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                        _c("ul", [
                          _c("li", { staticClass: "snIdTitle" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/homeDialog/snId.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "text" }, [
                              _vm._v("SN码"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "snIdData" }, [
                            _vm._v(
                              _vm._s(_vm.terminal.droneInfo.software_code)
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("deviceData", {
                        attrs: {
                          terminal: _vm.listDeviceData,
                          "set-style": _vm.setStyle,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }