<template>
  <!-- 顶部通栏 -->
  <div style="width: 100%; height: 70px">
    <div class="Navbar">
      <div class="grid-content bg-purple">
        <div class="TitleBar" @click="GoHome">
          <div class="imgBar">
            <!-- <img style="width: 100%" src="@/assets/img/new-logo.png" /> -->
          </div>
          <div class="titleBar">
            <span class="top-title">无人机巡护指挥调度管理平台</span>
            <!-- <span class="Platformname">【调度中心端】</span> -->
          </div>
        </div>
        <div class="navmenu">
          <el-menu
            :default-active="$route.path"
            class="el-menu-vertical-demo"
            text-color="#fff"
            active-text-color="#fff"
            mode="horizontal"
            @select="handleSelectMenu"
            style="width:730px"
          >
            <el-menu-item
              :class="{ fmHoverBgc: currentUrl[1] === '首页' }"
              class="fmHover"
              index="/home"
              @click="GoHome"
            >
              <span slot="title" class="menuTitle">火情监测</span>
            </el-menu-item>
            <el-menu-item
              :class="{ fmHoverBgc: currentUrl[1] === '任务管理' }"
              class="fmHover"
              index="/taskCoordination"
              @click="leftNav(9)"
            >
              <span slot="title" class="menuTitle">任务管理</span>
            </el-menu-item>
            <el-menu-item
              :class="{ fmHoverBgc: currentUrl[1] === '应急装备' }"
              class="fmHover"
              index="/deviceManagement?type=uav"
              @click="leftNav(1)"
            >
              <span slot="title" class="menuTitle">装备管理</span>
            </el-menu-item>
            <el-menu-item
              :class="{ fmHoverBgc: currentUrl[1] === '用户管理' }"
              class="fmHover"
              index="/alliance"
              @click="leftNav(2)"
            >
              <span slot="title" class="menuTitle">用户管理</span>
            </el-menu-item>
              <el-menu-item
              class="fmHover"
              style="position:relative"
            >
              <div class="open-menu-box">
                <span slot="title" class="menuTitle">火情数据</span>
                <i
                  style="position: absolute; top: 25%; right: 0"
                  :class="[
                    isOpenMenuCard
                      ? 'el-icon-caret-top'
                      : 'el-icon-caret-bottom',
                  ]"
                ></i>
              </div>

            </el-menu-item>
            <div class="open-menu-card" v-show="isOpenMenuCard">
              <div
                class="item"
                @click="handleSelectChildMenu('achievement', 3)"
              >
                数据查看
              </div>
              <div
                class="item"
                @click="handleSelectChildMenu('airspaceQuery', 4)"
              >
                空域管理
              </div>
              <div class="item" @click="handleSelectChildMenu('/flystat?nav=flysta', 5)">
                每日报告
              </div>
              </div>



            <div
              class="open-menu-mask"
              v-show="isOpenMenuCard"
              @click="isOpenMenuCard = false"
            ></div>
          </el-menu>

        </div>
        <div class="userInform">
          <div
            v-if="
              user.permissions &&
              user.permissions.indexOf('cloud.server.config') === -1
            "
            @click="toDataCloud"
            class="nav-link"
            style="opacity: 0.4"
            title="您不具有数据云处理的权限，请联系超级管理员开通此权限。"
          >
            <div class="colony"></div>
          </div>
          <!-- <div
            v-if="
              user.permissions &&
              user.permissions.indexOf('cloud.server.config') !== -1
            "
            @click="toDataCloud"
            class="nav-link"
          >
            <div class="colony"></div>
          </div> -->
          <div @click="newBtn">
            <div class="news">
              <el-badge
                class="mark"
                :value="unreadNoticeNum"
                v-show="unreadNoticeNum != 0"
              />
            </div>
          </div>
          <div class="userNav" @click="showUser">
            <avatar class="avatar" :avatar="user.photo_url" />
            <span class="userName" :title="user.name">{{ user.name }}</span>
            <i v-if="user.is_admin == 1" class="iconfont icon-VIP">&#xe632;</i>
            <i
              v-if="user.is_admin == 0 && user.is_unit == 1"
              class="iconfont icon-pre"
              >&#xe605;</i
            >
          </div>
        </div>
        <el-dialog
          class="userBox"
          :modal="false"
          :show-close="false"
          :visible.sync="dialogVisible"
          width="230px"
        >
          <div class="biguserInfor">
            <div class="userInfor">
              <div class="userAva">
                <avatar :avatar="user.photo_url" :width="60" :height="60" />
              </div>
              <div class="userIdentity">
                <span class="usernames">{{ user.name }}</span>
                <i v-if="user.is_admin == 1" class="iconfont userIcons"
                  >&#xe632;</i
                >
                <i
                  v-if="user.is_admin == 0 && user.is_unit == 1"
                  class="iconfont userIconsone"
                  >&#xe605;</i
                >
              </div>
              <!-- 超管 -->
              <div v-if="user.is_admin == 1" class="unitBtn">
                {{ this.overpipe }}
              </div>
              <!-- 巡护管理 -->
              <div
                v-if="user.is_admin == 0 && user.is_unit == 1"
                class="userBtn"
              >
                {{ this.unionpipe }}
              </div>
              <!-- 终端用户 -->
              <div
                v-if="user.is_admin == 0 && user.is_unit == 0"
                class="enduser"
              >
                {{ this.terminaluser }}
              </div>
            </div>
          </div>
          <div class="userBar">
            <div class="personal users">
              <div class="feimaBar" @click="personal(6)">
                <i class="iconfont usercenter">&#xe62c;</i>
                <span class="saysever">个人中心</span>
              </div>
            </div>
            <div
              v-if="role == 0"
              class="permission users"
              @click="Jurisdiction"
            >
              <div class="feimaBar">
                <i class="iconfont permissionIcon">&#xe62d;</i>
                <span class="saysever">权限配置</span>
              </div>
            </div>
            <div class="loginout users" @click="loginOut">
              <div class="feimaBar">
                <i class="iconfont loginoutIcon">&#xe62b;</i>
                <span class="saysever">退出登录</span>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
      <!-- 聊天发起组件 -->
      <el-dialog
        class="chat-add-dialog"
        v-dialogDrag
        :modal="false"
        :lock-scroll="false"
        :visible.sync="chatAddDialog.show"
      >
        <chatAdd ref="chatAdd" :key="chatAddDialog.key" />
      </el-dialog>
      <!-- 聊天组件 -->
      <el-dialog
        class="chat"
        v-dialogDragCustom
        :modal="false"
        :append-to-body="false"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :lock-scroll="false"
        :visible.sync="chatDialog.show"
        @close="closeChatDialog"
      >
        <chat ref="chat" :key="chatDialog.key" />
      </el-dialog>
      <!-- 音视频邀请组件 -->
      <el-dialog
        class="media-request-dialog"
        :modal="false"
        :lock-scroll="false"
        :modal-append-to-body="true"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="mediaRequestDialog.show"
      >
        <div class="media-request">
          <div class="head">
            <avatar class="avatar" :avatar="mediaRequestDialog.photo" />
            <div style="margin-left: 30px">{{ mediaRequestDialog.name }}</div>
          </div>
          <div class="foot">
            <img src="@/assets/img/chat/hangup.png" @click="hangUpMedia" />
            <img src="@/assets/img/chat/answer.png" @click="answerMedia" />
          </div>
        </div>
      </el-dialog>
      <!-- 音视频iframe组件 -->
      <el-dialog
        class="mediasoup-dialog"
        ref="mediasoupDialog"
        v-dialogDragCustom
        :modal="false"
        :append-to-body="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="mediaDialog.show"
      >
        <template slot="title">
          <div class="el-dialog-custom-header">
            <img
              v-show="!mediasoupDialogEnlarge"
              src="@/assets/img/chat/enlarge.png"
              @click="enlargeMedia"
            />
            <img
              v-show="mediasoupDialogEnlarge"
              src="@/assets/img/chat/enlarge_reset.png"
              @click="enlargeMedia"
            />
          </div>
        </template>
        <iframe
          v-if="mediaDialog.show"
          :src="mediaDialog.url"
          class="media-iframe"
          allow="geolocation; microphone; camera; midi; encrypted-media;"
          >您的浏览器版本过低 请切换至火狐或谷歌浏览器</iframe
        >
      </el-dialog>
      <NewViews ref="newview" />
      <div class="selectIntranetOrExtranet">
        <el-dialog
          title="提示"
          :visible.sync="toDataCloudDialogVisible"
          width="25%"
        >
          <div class="text">
            <span class="el-icon-warning icon-warning"></span
            >请选择内网用户或外网用户?
          </div>
          <div class="selectBut">
            <el-button @click="openExtranet">外网</el-button>
            <el-button type="primary" @click="openIntranet">内网</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import chatAdd from "@/components/taskChat/add";
import chat from "@/components/taskChat/index";
import NewViews from "@/components/messageBox/newViews";
import avatar from "@/components/avatar/avatar";

export default {
  components: {
    chat,
    chatAdd,
    NewViews,
    avatar,
  },
  data() {
    return {
      toDataCloudDialogVisible: false,
      activeIndex: "/",
      wheatherList: {},
      weatherImg: "/static/img/weathercn/999.png",
      dialogVisible: false,
      overpipe: "超级管理员",
      unionpipe: "巡护管理员",
      terminaluser: "巡护终端用户",
      taskgroup: "任务群组管理员",
      avatarUrl: require("@/assets/img/user-default-head-small.png"),
      mediasoupDialogEnlarge: false,
      isOpenMenuCard: false,
    };
  },
  directives: {
    drag(el, bindings) {
      el.onmousedown = function (e) {
        var disx = e.pageX - el.offsetLeft;
        var disy = e.pageY - el.offsetTop;
        document.onmousemove = function (e) {
          el.style.left = e.pageX - disx + "px";
          el.style.top = e.pageY - disx + "px";
        };
        document.onmouseup = function () {
          document.onmousemove = document.onmouseup = null;
        };
      };
    },
  },
  // 检测路由变化
  watch: {
    $route() {
      this.setCurrentRoute();
      this.dialogVisible = false;
    },
  },
  computed: {
    ...mapState([
      "navMessage",
      "role",
      "token",
      "user",
      "currentUrl",
      "chatAddDialog",
      "chatDialog",
      "mediaRequestDialog",
      "mediaDialog",
      "msgCenter",
    ]),
    unreadNoticeNum() {
      let data = this.msgCenter.unread_num;
      let num = data.task + data.task_command + data.task_file;
      return num > 99 ? "..." : num;
    },
  },
  mounted() {
    this.getMsgCenterUneadNum()
  },
  methods: {
    ...mapMutations([
      "userStorage",
      "tokenStorage",
      "safetyTopStorage",
      "navMessageStorage",
      "currentUrlStorage",
      "chatDialogStorage",
    ]),
    //获取未读消息
    getMsgCenterUneadNum() {
      this.$apis.noticeUnreadNum({}, this.token).then((res) => {
        let data = res.data.data;
        for(var key in this.msgCenter.unread_num) {
          this.msgCenter.unread_num[key] = data[key]
        }
      });
    },
    // 跳转数据云处理平台
    toDataCloud() {
      if (this.user.permissions.indexOf("cloud.server.config") !== -1) {
        this.toDataCloudDialogVisible = true;
      }
    },
    // 打开内网
    openIntranet() {
      window.open(process.env.VUE_APP_URL_INTRANET);
    },
    // 打开外网
    openExtranet() {
      window.open(process.env.VUE_APP_DATA_CLOUD);
    },
    // 拒绝视频通话
    hangUpMedia() {
      this.mediaRequestDialog.show = false;
    },
    // 接受视频通话
    answerMedia() {
      this.mediaRequestDialog.show = false;
      this.$refs.chat.startMedia(this.mediaRequestDialog.url);
    },
    // 拒绝音视频
    endMedia() {
      this.mediaDialog.url = "";
      this.mediaDialog.show = false;
    },
    // 放大、还原音视频
    enlargeMedia() {
      this.mediasoupDialogEnlarge = !this.mediasoupDialogEnlarge;
      let el = this.$refs.mediasoupDialog.$el;
      if (this.mediasoupDialogEnlarge) {
        //放大后就不能再拖动了
        el.ableDrag = false;
        el.style.top = 0;
        el.style.left = 0;
        el.style.transform = "scale(1)";
        el.style.width = "100%";
        el.style.height = "100%";
        el.querySelector(".el-dialog__header").style.cursor = "auto";
      } else {
        el.ableDrag = true;
        el.style.transform = "scale(0.58)";
        el.style.left = "auto";
        el.style.top = "auto";
        el.style.right = "-210px";
        el.style.bottom = "-210px";
        el.style.width = "1000px";
        el.style.height = "1000px";
        el.querySelector(".el-dialog__header").style.cursor = "move";
      }
    },
    // 关闭聊天窗口
    closeChatDialog() {
      this.chatDialog.show = false;
    },
    handleSelectMenu(key, keyPath) {
      if (key) {
        this.$router.push({
          path: key,
          params: { data: keyPath },
        });
      } else {
        this.isOpenMenuCard = !this.isOpenMenuCard;
      }
    },
    handleSelectChildMenu(key, leftNavIndex) {
      this.isOpenMenuCard = false
      this.$router.push({
        path: key,
      });
      this.leftNav(leftNavIndex);
    },
    // 修改左侧导航栏
    leftNav(index) {
      this.navMessageStorage(index);
      switch (index) {
        case 0:
          this.currentUrlStorage(["首页", "任务下达", "区块任务"]);
          break;
        case 1:
          this.currentUrlStorage(["首页", "应急装备", "无人机"]);
          break;
        case 2:
          this.currentUrlStorage(["首页", "用户管理", "巡护单位"]);
          break;
        case 3:
          this.currentUrlStorage(["首页", "目录查询", "空域查询"]);
          break;
        case 4:
          this.currentUrlStorage(["首页", "空域查询", "空域查询"]);
          break;
        case 5:
          this.currentUrlStorage(["首页", "气象查询", "气象查询"]);
          break;
        case 6:
          this.currentUrlStorage(["首页", "统计分析", "任务统计"]);
          break;
        case 9:
          this.currentUrlStorage([
            "首页",
            "任务管理"
          ]);
          break;
        default:
          break;
      }
    },
    setCurrentRoute() {
      this.activeIndex = this.$route.path; // 通过他就可以监听到当前路由状态并激活当前菜单
    },
    personal(index) {
      this.navMessageStorage(index);
      this.$router.push("/information");
      this.currentUrlStorage(["首页", "个人中心", "个人信息"]);
    },
    showUser() {
      this.dialogVisible = true;
    },
    Jurisdiction() {
      if (this.role == 0) {
        this.$router.push("/permissionManagement");
      }
    },
    // 点击标题回首页
    GoHome() {
      this.$router.push("/home");
    },
    // 退出登录
    loginOut() {
      this.$confirm("您确定退出登录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.commit("logOut");
          this.$router.push("/login");
          this.$message({
            type: "success",
            message: "退出成功!",
          });
        })
        .catch(() => {});
    },
    // 消息提醒
    newBtn() {
      this.$refs.newview.open();
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("~@/assets/iconfont/iconfont.css");

.drag {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: red;
}

.userNav {
  display: flex;
  align-items: center;
  .avatar {
    margin-left: 10px;
    /deep/.avatar-box {
      border-radius: 40px;
    }
  }
}
.TitleBar {
  cursor: pointer;
  display: flex;
  width: 620px;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  padding-right: 105px;
  background: url("~@/assets/img/centerHome/navbarLeft.png") 600px 70px;
}
.imgBar {
  //background: url("~@/assets/img/home／logo.png") center no-repeat;
  width: 38px;
  height: 44px;
  display: flex;
  align-items: center;
  margin-top: -10px;
  // margin-left: 40px;
}
.titleBar {
  display: flex;
  align-items: center;
  width: 365px;
  margin-left: 5px;
  margin-top: -10px;
  span {
    display: inline-block;
  }
}
.unitBtn {
  color: #fff;
  position: absolute;
  left: 88px;
  top: 55px;
  width: 80px;
  height: 20px;
  border-radius: 3px;
  background-color: #1862ff;
  display: flex;
  justify-content: center;
  line-height: 20px;
  font-size: 12px;
}
.userBtn {
  color: #3c4353;
  position: absolute;
  left: 88px;
  top: 55px;
  width: 80px;
  height: 20px;
  border-radius: 3px;
  background-color: #ffc000;
  display: flex;
  justify-content: center;
  line-height: 20px;
  font-size: 12px;
}
.enduser {
  color: #3c4353;
  position: absolute;
  left: 88px;
  top: 55px;
  width: 80px;
  height: 20px;
  border-radius: 3px;
  background-color: #59fa9c;
  display: flex;
  justify-content: center;
  line-height: 20px;
  font-size: 12px;
}
.usernames {
  margin-left: 10px;
  color: #fff;
}
.userIcons {
  color: #1862ff;
  margin-left: 5px;
}
.userIconsone {
  color: #faad14;
  margin-left: 5px;
}
.userIdentity {
  float: right;
  margin-bottom: 35px;
}
.userAva {
  width: 60px;
  height: 60px;
  margin-left: 15px;
  float: left;
}
.AvaIdenti {
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
.feimaBar {
  margin-left: 15px;
  line-height: 30px;
}
.users {
  margin-bottom: 10px;
  cursor: pointer;
}
.users:hover {
  background-color: #dde2ef;
}
.personal {
  width: 100%;
  height: 30px;
  .usercenter {
    font-size: 18px;
  }
}
.loginout {
  width: 100%;
  height: 30px;
  .loginoutIcon {
    font-size: 18px;
  }
}
.saysever {
  margin-left: 10px;
  font-size: 18px;
}
.permission {
  width: 100%;
  height: 30px;
  .permissionIcon {
    font-size: 18px;
  }
}
.userInfor {
  width: 100%;
  height: 100px;
  background-color: #1182fb;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.biguserInfor {
  width: 100%;
  height: 100px;
  background-color: #fff;
  padding: 4px;
}
.userBar {
  width: 100%;
  padding: 0px 4px;
  height: 100px;
  margin-top: 25px;
}
/deep/.userBox .el-dialog {
  margin-top: 10vh !important;
  margin-right: 5vh;
  height: 250px;
}
/deep/.userBox .el-dialog__header {
  padding: 0;
  padding-top: 0;
}
/deep/.userBox .el-dialog__body {
  padding: 3px 20px;
  padding: 0;
  padding-top: 0;
}
/deep/.el-menu-item {
  &:hover {
    background-color: #2e4d8c8f !important;
  }
}
.userInform {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  width: 620px;
  .lingdang {
    width: 36px;
    height: 36px;
    font-size: 40px;
    color: #fff;
    display: flex;
    justify-content: center;
    line-height: 80px;
    float: left;
  }
  .Avatar {
    margin-left: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    float: left;
    cursor: pointer;
  }
  .userName {
    color: #fff;
    font-size: 16px;
    float: left;
    line-height: 70px;
    margin-left: 30px;
    cursor: pointer;
    max-width: 150px;
    height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .icon-VIP {
    font-size: 14px;
    color: #1182fb;
    display: flex;
    justify-content: center;
    line-height: 70px;
    float: left;
    margin-left: 5px;
    cursor: pointer;
  }
  .icon-pre {
    font-size: 14px;
    color: #faad14;
    display: flex;
    justify-content: center;
    line-height: 70px;
    float: left;
    margin-left: 5px;
    cursor: pointer;
  }
  .news {
    position: relative;
    height: 30px;
    width: 30px;
    margin: 15px 35px 0px 0px;
    background-size: 30px 30px;
    background-repeat: repeat;
    background-image: url("~@/assets/img/centerHome/news.png");
    &:hover {
      background-image: url("~@/assets/img/centerHome/newsHover.png");
    }
  }
  .mark {
    position: absolute;
    right: -5px;
    top: 0px;
  }
  /deep/.el-badge__content {
    background-color: #ff5e5e;
    border-radius: 12px;
    border: none;
    height: 14px;
    line-height: 14px;
  }
}

.el-menu-vertical-demo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  background-color: transparent;
  // border: 1px solid rgba(230, 233, 239, 1);
  border-radius: 40px;
  height: 48px;
}

.fmHoverBgc {
  background-color: #2e4d8c8f !important;
}
/deep/.el-menu {
  margin: 8px 0 !important;
  user-select: none;
  // position: relative;
}

.fmHover {
  border-radius: 10px;
  width: 124px;
  height: 46px;
  display: flex;
  justify-content: center;
}
.navmenu {
  width: 746px;
  z-index: 9;
  height: 70px;
  max-width: 982px;
  position: relative;
  // margin-left: 40px;
}

.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
  background-color: transparent;
}
.el-menu-item :hover {
  background-color: #2e4d8c8f !important;
  border-radius: 10px;
  height: 46px;
  width: 125px;
}
/deep/.el-menu-item.is-active {
  // background-color: #0052f7;
  border-bottom: none;
  border-radius: 10px;
  border-bottom-color: #2e4d8c8f !important;
}
/deep/.el-menu.el-menu--horizontal {
  border: none;
}
.menuTitle {
  font-size: 15px;
  display: flex;
  justify-content: center;
  line-height: 46px;
  padding: 0 35px 0 35px;
  border: none !important;
}
.Navbar {
  height: 70px;
  background: linear-gradient(270deg, #030d32 0%, #041f5e 100%);
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  // min-width: 1300px;
  .grid-content {
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
    height: 70px;
    width: 100%;
    min-width: 1280px;
    background-image: url("~@/assets/img/centerHome/navbarCenter.png");
    background-size: 100% 70px;
    background-repeat: no-repeat;
    .top-title {
      font-style: normal;
      color: #fff;
      font-size: 17px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      float: left;
      font-weight: 900;
      text-align: center;
      line-height: 25px;
      // width: 185px;
    }
    .Platformname {
      color: #ffffff;
      font-size: 13px;
      margin-left: 4px;
      width: 95px;
      height: 25px;
      line-height: 25px;
    }
  }
  .division {
    width: 1px;
    height: 30px;
    background: rgba(166, 170, 184, 1);
    float: left;
    margin-top: 25px;
    margin-left: 10px;
  }
  .weather {
    float: left;
    width: 190px;
    height: 30px;
    margin-left: 30px;
    line-height: 70px;
    cursor: pointer;
    .sztemp {
      color: #dee3e5;
      width: 35px;
      height: 21px;
      font-size: 16px;
    }
    .weathImg {
      width: 33px;
      height: 33px;
      font-size: 16px;
      position: relative;
      top: 11px;
      border: hidden;
    }
  }
}
.navbar {
  height: 70px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }
  }
}

.nav-link {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 15px 20px 0 0;
  .colony {
    height: 30px;
    width: 30px;
    background-size: 30px 30px;
    background-repeat: repeat;
    background-image: url("~@/assets/img/centerHome/colony.png");
    &:hover {
      background-image: url("~@/assets/img/centerHome/colonyHover.png");
    }
  }
}
/deep/.chat-add-dialog {
  .el-dialog {
    .el-dialog__header {
      position: absolute;
      z-index: 998;
      width: 100%;
      height: 60px;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
}
/deep/.chat {
  width: 900px;
  height: 687px;
  left: calc(50% - 450px);
  top: calc(50% - 343px);
  > .el-dialog:first-child {
    width: 100%;
    margin-top: 0 !important;
    .el-dialog__header {
      position: absolute;
      right: 0;
      z-index: 998;
      width: 100%;
      height: 70px;
      .chat .el-dialog__headerbtn {
        top: 10px;
      }
    }
    .el-dialog__body {
      padding: 0;
    }
  }
}

/deep/.media-request-dialog {
  .el-dialog {
    width: 400px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
}

/deep/.mediasoup-dialog {
  transform: scale(0.58);
  width: 63%;
  left: auto;
  top: auto;
  right: -210px;
  bottom: -210px;
  width: 1000px;
  height: 1000px;
  .el-dialog {
    width: 100%;
    height: 100%;
    margin-top: 0 !important;
    display: flex;
    flex-direction: column;
    .el-dialog__header {
      height: 50px;
      padding: 0;
      background: #000000;
      color: #6d778b;
      .el-dialog__headerbtn {
        transform: scale(2);
        top: 17px;
      }
    }
    .el-dialog__body {
      padding: 0;
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
  .el-dialog-custom-header {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    padding-right: 50px;
    img {
      height: 100%;
      cursor: pointer;
    }
  }
}

/deep/.mediasoup-dialog-enlarge {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(1);
}

.media-request {
  width: 100%;
  height: 600px;
  background-color: #000000;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 15px;
  .head {
    display: flex;
    align-items: center;
    img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
  }
  .foot {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    img {
      width: 65px;
      height: 65px;
    }
  }
}

.media-iframe {
  width: 100%;
  height: 100%;
}

.media-foot {
  background: #000620;
  display: flex;
  padding: 20px;
  .btn-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 88px;
    cursor: pointer;
    img {
      width: 70px;
      height: 40px;
      margin-bottom: 4px;
    }
  }
}

.selectIntranetOrExtranet {
  .text {
    font-size: 16px;
    padding-bottom: 20px !important;
    .icon-warning {
      color: #e6a23c;
      font-size: 25px;
      padding-right: 5px;
      position: relative;
      top: 4px;
    }
  }
  /deep/.el-dialog__body {
    padding: 15px 30px;
    height: 110px;
  }
  .selectBut {
    position: absolute;
    right: 20px;
    bottom: 13px;
  }
}

@media screen and (max-width: 1480px) {
  .grid-content .Platformname {
    display: none;
  }
  .TitleBar {
    width: 600px;
    padding-left: 10px;
    padding-right: 65px;
    transition: padding 0.3 ease-in-out;
    background: url("~@/assets/img/centerHome/navbarLeft.png") 560px 70px;
  }
  @media screen and (max-width: 1280px) {
    .TitleBar {
      width: 510px;
      padding-left: 10px;
      padding-right: 25px;
      background: url("~@/assets/img/centerHome/navbarLeft.png") 480px 70px;
    }
    .imgBar {
      display: none;
    }
  }
}

.open-menu-card {
  width: 136px;
  background: #032248;
  border-radius: 4px;
  border: 2px solid rgba(105, 120, 158, 0.3);
  color: #ffffff;
  position: absolute;
  top: 67px;
  right: 47px;
  padding: 17px 0;
  .item {
    width: 100%;
    text-align: center;
    height: 39px;
    line-height: 39px;
    cursor: pointer;
  }
  .item:hover {
    background: #2e4d8c8f;
  }
  z-index: 2000;
}
.open-menu-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  z-index: 1999;
}
</style>
