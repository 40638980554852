<template>
  <div class="singleMonitor" v-loading="loading">
    <!-- <el-button @click="text">click{{holder_user_data}}</el-button> -->
    <div class="main" :id="`mainWrapper` + source" :class="{ 'history-map-small': curContentType === 'video' }">
      <div :id="software_code + source" :class="{ setBorder: !moreScreen }" class="map" @click="clickMonitor"></div>
    </div>
    <span v-if="moreScreen">
      <div class="plane_data" v-show="isShowData">
        <div style="margin-bottom: 10px">
          <ul>
            <li class="snIdTitle">
              <img src="@/assets/img/homeDialog/snId.png" alt="" />
              <div class="text">SN码</div>
            </li>
            <li class="snIdData hideLength" :title="snId">{{ snId }}</li>
          </ul>
        </div>
        <deviceData :terminal="listDeviceData" :setStyle="setStyle" />
      </div>
      <div v-if="planeType === 'common'" class="showDatas">
        <!-- <div>{{monitorPlaneData}}</div> -->
        <span v-if="brandName !== '彩虹'">
          <div class="realTime" v-show="showData['仪表盘']" ref="realTime">
            <img src="@/assets/img/homeDialog/leftTop.png" alt="" class="realTime_border" style="top: -3px; left: -2px" />
            <img src="@/assets/img/homeDialog/leftBottom.png" alt="" class="realTime_border"
              style="bottom: -161px; left: -33px" />
            <img src="@/assets/img/homeDialog/rightTop.png" alt="" class="realTime_border"
              style="right: -206px; top: -2px" />
            <img src="@/assets/img/homeDialog/rightBottom.png" alt="" class="realTime_border"
              style="bottom: -161px; right: -175px" />
            <div class="realTime_body">
              <div class="lnglat">
                <div class="setImg">
                  <img src="@/assets/img/homeDialog/lat.png" alt="" width="20" />
                  <span class="lnglat_title">纬度</span><span class="lnglat_data">{{
                    (monitorPlaneData.Latitude || monitorPlaneData.lat)
                    | saveLnglat1
                  }}</span>
                </div>
                <div class="setImg">
                  <img src="@/assets/img/homeDialog/lng.png" alt="" width="20" />
                  <span class="lnglat_title">经度</span><span class="lnglat_data">{{
                    (monitorPlaneData.Longitude || monitorPlaneData.lon)
                    | saveLnglat1
                  }}</span>
                </div>
              </div>
              <div class="line"></div>
              <div class="realTime_data">
                <div class="time">
                  <table>
                    <tr>
                      <td class="title">更新时间</td>
                      <td class="data">
                        {{ monitorPlaneData.TimeStamp | filtTimeStamp }}
                      </td>
                    </tr>
                    <tr>
                      <td class="title">飞行时间</td>
                      <td class="data">{{ flyTime }}</td>
                    </tr>
                    <tr>
                      <td class="title">海拔高度</td>
                      <td class="data">
                        {{ monitorPlaneData.Altitude | flitTowData }}
                      </td>
                    </tr>
                    <tr>
                      <td class="title">航迹角</td>
                      <td class="data">
                        {{ Number(monitorPlaneData.Course).toFixed(1) }}°
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="speed">
                  <div class="title"><span>飞行速度</span></div>
                  <div class="data">
                    {{ monitorPlaneData.Gs | flitOneData }}m/s
                  </div>
                  <div class="title" style="color: #35d9a7">
                    <span>飞行高度</span>
                  </div>
                  <div class="data" style="color: #35d9a7">
                    {{ monitorPlaneData.Height | flitTowData }}m
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
        <span v-if="brandName === '彩虹'">
          <div class="realTime" v-show="showData['仪表盘']" style="height: 138px">
            <img src="@/assets/img/homeDialog/leftTop.png" alt="" class="realTime_border" style="top: -3px; left: -2px" />
            <img src="@/assets/img/homeDialog/leftBottom.png" alt="" class="realTime_border"
              style="bottom: -114px; left: -33px" />
            <img src="@/assets/img/homeDialog/rightTop.png" alt="" class="realTime_border"
              style="right: -206px; top: -2px" />
            <img src="@/assets/img/homeDialog/rightBottom.png" alt="" class="realTime_border"
              style="bottom: -114px; right: -175px" />
            <div class="realTime_body">
              <div class="lnglat">
                <div class="setImg">
                  <img src="@/assets/img/homeDialog/lat.png" alt="" width="20" />
                  <span class="lnglat_title">纬度</span><span class="lnglat_data">{{
                    (monitorPlaneData.Latitude || monitorPlaneData.lat)
                    | saveLnglat1
                  }}</span>
                </div>
                <div class="setImg">
                  <img src="@/assets/img/homeDialog/lng.png" alt="" width="20" />
                  <span class="lnglat_title">经度</span><span class="lnglat_data">{{
                    (monitorPlaneData.Longitude || monitorPlaneData.lon)
                    | saveLnglat1
                  }}</span>
                </div>
              </div>
              <div class="line"></div>
              <div class="realTime_data">
                <div class="CaiHonTime" style="border: none">
                  <table>
                    <tr>
                      <td class="title">更新时间</td>
                      <td class="data">
                        {{ monitorPlaneData.TimeStamp | filtTimeStamp }}
                      </td>
                      <td class="title">飞行时间</td>
                      <td class="data">{{ flyTime }}</td>
                    </tr>
                    <tr>
                      <td class="title">海拔高度</td>
                      <td class="data">
                        {{ Number(monitorPlaneData.Altitude).toFixed(1) }}
                      </td>
                      <td class="title">航迹角</td>
                      <td class="data">
                        {{ Number(monitorPlaneData.Course).toFixed(1) }}°
                      </td>
                    </tr>
                  </table>
                </div>
                <!-- <div class="speed">
                <div v-if="brandName!=='彩虹'" class="title"><span>飞行速度</span></div>
                <div v-if="brandName!=='彩虹'" class="data">
                  {{ monitorPlaneData.Gs | flitOneData }}m/s
                </div>
                <div v-if="brandName!=='彩虹'" class="title" style="color: #35d9a7">
                  <span>飞行高度</span>
                </div>
                <div v-if="brandName!=='彩虹'" class="data" style="color: #35d9a7">
                  {{ monitorPlaneData.Height | flitTowData }}m
                </div>
              </div> -->
              </div>
            </div>
          </div>
        </span>
      </div>
      <div v-else>
        <span v-if="feimaManager.fmMonitor !== undefined" class="showDatas">
          <div class="realTime" v-show="showData['仪表盘']">
            <img src="@/assets/img/homeDialog/leftTop.png" alt="" class="realTime_border" style="top: -3px; left: -2px" />
            <img src="@/assets/img/homeDialog/leftBottom.png" alt="" class="realTime_border"
              style="bottom: -161px; left: -33px" />
            <img src="@/assets/img/homeDialog/rightTop.png" alt="" class="realTime_border"
              style="right: -206px; top: -2px" />
            <img src="@/assets/img/homeDialog/rightBottom.png" alt="" class="realTime_border"
              style="bottom: -161px; right: -175px" />
            <div class="realTime_body">
              <div class="lnglat">
                <div class="setImg">
                  <img src="@/assets/img/homeDialog/lat.png" alt="" width="20" />
                  <span class="lnglat_title">纬度</span><span class="lnglat_data"
                    v-if="feimaManager.fmMonitor !== undefined">{{
                      feimaManager.fmMonitor.flightPanelDatas.latitude
                    }}</span>
                </div>
                <div class="setImg">
                  <img src="@/assets/img/homeDialog/lng.png" alt="" width="20" />
                  <span class="lnglat_title">经度</span><span v-if="feimaManager.fmMonitor !== undefined"
                    class="lnglat_data">{{
                      feimaManager.fmMonitor.flightPanelDatas.longitude
                    }}</span>
                </div>
              </div>
              <div class="line"></div>
              <div class="realTime_data">
                <div class="time">
                  <table>
                    <tr v-if="!isHistory">
                      <td class="title">更新时间</td>
                      <td class="data">
                        {{ fmUpdateAt }}
                      </td>
                    </tr>
                    <!-- <tr>
                      <td class="title">飞行时间</td>
                      <td class="data">
                        {{
                            feimaManager.fmMonitor.flightPanelDatas
                              .flight_duration
                        }}
                      </td>
                    </tr> -->
                    <tr v-if="brandName !== '大疆'">
                      <td class="title">海拔高度</td>
                      <td class="data" v-if="feimaManager.fmMonitor.flightPanelDatas &&
                        feimaManager.fmMonitor.flightPanelDatas.altitude
                        ">
                        {{
                          isPackage
                          ? Math.round(
                            Number(
                              feimaManager.fmMonitor.flightPanelDatas.altitude.split(
                                "m"
                              )[0]
                            ) / 1000
                          )
                          : feimaManager.fmMonitor.flightPanelDatas.altitude
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="title">航迹角</td>
                      <td class="data">
                        {{ feimaManager.fmMonitor.flightPanelDatas.angle }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="speed">
                  <div class="title">
                    <span class="flyName">飞行速度</span><span class="goalSpeed">目标
                      {{
                        feimaManager.fmMonitor.flightPanelDatas.target_speed.split(
                          "m"
                        )[0]
                      }}</span>
                  </div>
                  <div class="data">
                    {{ feimaManager.fmMonitor.flightPanelDatas.speed }}
                  </div>
                  <div class="title" style="color: #35d9a7">
                    <span class="flyName">飞行高度</span>
                    <span class="goalSpeed goalHeight">目标
                      {{
                        feimaManager.fmMonitor.flightPanelDatas.target_height.split(
                          "m"
                        )[0]
                      }}</span>
                  </div>
                  <div class="data" style="color: #35d9a7" v-if="feimaManager.fmMonitor.flightPanelDatas &&
                      feimaManager.fmMonitor.flightPanelDatas.drone_height
                      ">
                    {{
                      isPackage
                      ? Math.round(
                        Number(
                          feimaManager.fmMonitor.flightPanelDatas.drone_height.split(
                            "m"
                          )[0]
                        ) / 1000
                      )
                      : feimaManager.fmMonitor.flightPanelDatas.drone_height
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="planeData">
            <div class="bcg">
              <img src="@/assets/img/homeDialog/leftTop.png" alt="" class="realTime_border1"
                style="top: -3px; left: -2px" />
              <img src="@/assets/img/homeDialog/leftBottom.png" alt="" class="realTime_border1"
                style="bottom: -1px; left: -1px" />
              <img src="@/assets/img/homeDialog/rightTop.png" alt="" class="realTime_border1"
                style="right: -1px; top: -1px" />
              <img src="@/assets/img/homeDialog/rightBottom.png" alt="" class="realTime_border1"
                style="bottom: -1px; right: -1px" />
            </div>

            <div class="planeDataBody">
              <div class="peopleDistance">
                <div @click="showSpellIt">
                  <img src="@/assets/img/homeDialog/peopleDistance.png" alt="" />
                </div>

                <div class="data">
                  {{
                    feimaManager.fmMonitor.flightPanelDatas.drone_pilot_distance
                  }}
                </div>
              </div>
              <div class="line"></div>
              <div class="datas">
                <div class="setData">
                  <span class="title">航点</span>
                  <span class="textData">{{
                    feimaManager.fmMonitor.flightPanelDatas.flight_progress
                  }}</span>
                </div>
                <div class="setData">
                  <span class="title">里程</span>
                  <span class="textData">{{
                    feimaManager.fmMonitor.flightPanelDatas.flight_miles
                  }}</span>
                </div>
                <div class="setData">
                  <span class="title">照片</span>
                  <span class="textData">{{
                    feimaManager.fmMonitor.flightPanelDatas.photo_count
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>
      <div class="control">
        <div v-if="planeFlightData" :style="{ top: playBackTop }">
          <img src="@/assets/img/homeDialog/playback.png" alt="" @click="showBack = !showBack" />
          <div v-if="showBack && feimaManager !== ''" class="playback">
            <playBack :endTime="endTime" :FlightTime="monitorPlaneData.FlightTime" :allTimeS="allTimeS" @pause="pause"
              @openBackPlay="openBackPlay" @moveProgress="moveProgress" ref="playBack" />
          </div>
        </div>
        <div v-show="software_code !== '-1'" style="top: 31%" class="show">
          <img src="@/assets/img/homeDialog/setShow.png" alt="" @click="clickControl(2)" v-show="isControl !== 2" />
          <img src="@/assets/img/homeDialog/selectShowOrHidden.png" alt="" @click="clickControl(2)"
            v-show="isControl === 2" />

          <div class="showView" v-show="isControl === 2">
            <showView @selectShow="selectShow" :showData="showData" />
          </div>
        </div>
        <div style="top: 32%">
          <img src="@/assets/img/homeDialog/switchMap.png" alt="" @click="clickControl(3)" />
          <div class="switchMap" v-show="isControl === 3">
            <div class="clickMap" @click="switchMap(1)">
              <img v-if="mapIndex === 1" src="@/assets/img/homeDialog/selectGaode.png" alt="" />
              <img v-else src="@/assets/img/homeDialog/gaode.png" alt="" />
              <div class="mapType" :class="mapIndex === 1 ? 'isMap' : ''">
                默认地图
              </div>
            </div>
            <div class="clickMap" @click="switchMap(2)">
              <img v-if="mapIndex === 2" src="@/assets/img/homeDialog/selectTianditu.png" alt="" />
              <img v-else src="@/assets/img/homeDialog/tianditu.png" alt="" />

              <div class="mapType" :class="mapIndex === 2 ? 'isMap' : ''">
                天地图
              </div>
            </div>
            <div class="clickMap" @click="switchMap(3)">
              <img v-if="mapIndex === 3" src="@/assets/img/homeDialog/selectYingxiang.png" alt="" />
              <img v-else src="@/assets/img/homeDialog/yingxiang.png" alt="" />

              <div class="mapType" :class="mapIndex === 3 ? 'isMap' : ''">
                影像瓦片
              </div>
            </div>
          </div>
        </div>
        <div v-show="software_code !== '-1'" style="top: 33.5%">
          <img src="@/assets/img/homeDialog/mobileBrowsing.png" alt="" @click="clickControl(4)" />
          <!-- <button @click="clearn">click</button>
        <button @click="test">click2{{monitorPlaneData}}</button> -->
          <div class="qrCode" v-show="isControl === 4">
            <div class="vueQr">
              <vue-qr class="qr" :size="172" :margin="0" :auto-color="true" :dot-scale="1" :text="toDetailUrl" />
            </div>
            <div>扫码手机观看</div>
          </div>
        </div>
        <div style="top: 43%">
          <img src="@/assets/img/homeDialog/redThread.png" alt="" @click="clickControl(5)" />
        </div>
        <div v-show="software_code !== '-1' && !isHistory" style="top: 46%">
          <img src="@/assets/img/homeDialog/video.png" alt="" @click="clickControl(6)" />
        </div>
      </div>
      <div class="video" v-show="isControl === 6 && planeType !== 'common'">
        <div class="popuper" :id="`popuper` + source" @click="switchVideoMap('popuper')"
          v-show="!(mainContentType === 'video' && isMapHidden)">
          <video autoplay muted class="videoBody" :id="`videoBody` + source"></video>
        </div>
        <div class="footer">
          <div class="left">
            <div v-show="mainContentType === 'map'">直播中</div>
            <img v-show="mainContentType === 'map'" src="@/assets/img/homeDialog/live.png" alt="" class="playIcon" />
          </div>
          <div class="right">
            <div class="videoListIcon" v-if="mainContentType === 'map'"
              :class="[isShowVideoList ? 'videoListHl' : 'videoList']" @click="showVideoList"></div>
            <div class="videoListIcon" v-else :class="[isMapHidden ? 'showMap' : 'hideMap']" @click="hidePopupMap">
            </div>
          </div>
        </div>
      </div>
    </span>

    <div v-if="canFly && planeType !== 'common'" class="spell">
      <spell-it :mission_id="mission_id" :flight_task_name="flight_task_name" :isHistory="planeFlightData"
        @spellitImg="spellitImg" @clearSingleTileImagers="clearSingleTileImagers" @clearTileLayer="clearTileLayer"
        ref="spellIt" />
    </div>
    <ComVideoList v-if="isHistory && feimaManager" class="com-video-list" :missionId="mission_id"
      @setVideoUrl="setVideoUrl"></ComVideoList>
    <div v-show="videoUrl" class="video-body" :class="{ 'video-player-full': curContentType === 'video' }">
      <div v-if="curContentType !== 'video'" class="close" @click.stop="closeHistoryVideo">
        <img src="@/assets/img/homeDialog/littleBtn/off-pre.png" alt="" />
      </div>
      <div style="height:100%">
        <video id="historyVideo" @click.prevent="onVideoClick" controlslist="noplaybackrate" :src="videoUrl" controls
          autoplay muted loop="loop"></video>
      </div>
    </div>
  </div>
</template>
<script>
import coordtransform from "@/utils/transform.js";
// import websocketView from "@/utils/websocket.js";
import LinkWebsocketView from "@/utils/newWebsocket.js";

import { mapState, mapMutations } from "vuex";
import deviceData from "@/components/monitorPlane/deviceData.vue";
import playBack from "@/components/monitorPlane/playBack.vue";
import showView from "@/components/monitorPlane/showView.vue";
import spellIt from "@/components/monitorPlane/spellIt.vue";
import ComVideoList from "@/components/comVideoList/comVideoList.vue"
import { FmMapManager, PathConfig } from "@/utils/fmmapmanager/index.js";
import axios from "axios";
import { SetUavLive } from '@/utils/liveManager/setLive'
const imgPathConfig = {
  planeImgPathMapBase: "/static/img/feimaToDetail/stat/", // 监控页面飞机图片路径
  planeImgPathCloudBase: "/static/img/feimaToDetail/cloudplay/", // 云播页面飞机图片路径
  signalImageBaseUrl: "/static/img/feimaToDetail/signal_", // 信号图片base地址
  landingPointImgUrl: "/static/img/feimaToDetail/landing_point.png", // 起始点标记图片
  endPointImgUrl: "/static/img/feimaToDetail/end_Point.png" // 结束点标记图片
};
const turf = require("@turf/turf");
export default {
  name: "singleMonitor",
  props: {
    moreScreen: {
      type: Boolean,
      default: true
    },
    planeType: {
      type: String,
      default: "common"
    },

    software_code: {
      type: String
    },

    order_id: {
      type: String
    },

    listDeviceData: {
      type: Object,
      default: function () {
        return {};
      }
    },

    snId: {
      type: String,
      default: "-1"
    },
    brandName: {
      type: String,
      default: ""
    },
    isBlock: {
      type: Boolean,
      default: false
    },
    isImageMap: {
      type: Boolean,
      default: false
    },
    canFly: {
      type: Boolean,
      default: true
    },
    source: {
      type: String,
      default: "one"
    }
  },
  emits: ["setFlyArea"],
  components: {
    deviceData,
    playBack,
    showView,
    spellIt,
    ComVideoList
  },
  data: function () {
    return {
      map: "", //地球实例
      isShowData: false, //是否显示更多信息弹窗
      mapIndex: 1, //选择不同地图
      tiandituLayer: {}, //天地图
      tianditutext: {}, //天地图文字
      googleLayer: {}, //谷歌影像地图
      tiandituYinxiangLayer: {}, //天地图影像
      isControl: -1, //选中右侧控件的第几个
      marker: {}, //飞机图标点
      trajectory: [], //轨迹wgs84
      gcjTrajectory: [], //gcj轨迹
      wgsTrajectory: [],
      planeIcon: {}, //飞机图标
      planeLocation: [114.033266, 22.522495],
      isWgs: false,
      polyline: [], //轨迹线
      timer: null,
      setStyle: true, //设备信息弹窗是否取消之前的样式，因为组件在多个地方引用，拥有不一样的样式
      wsCounter: 0, //计算获取多少次websocket的值，10次重新计算面积
      points: [], //计算面积
      isHistory: false,
      distancePoints: [], //折线点集合
      showData: { 仪表盘: true, 航线: true, 轨迹: true },
      comWidth: 345,
      toDetailUrl: "",
      feimaManager: "", // 飞马无人机控制
      flyingAirline: "", //飞马无人机轨迹
      flightPanelDatas: {},
      feimaPlaneMarker: "", //飞马无人机图标标记点
      routeLine: "", //飞马航线
      nowTime: "00:00:00 ",
      homePoint: "", //飞马无人机航线开始点
      endPoint: "", //飞马无人机航线结束点
      totalMapImages: [], //快拼存储图片数据
      wgsSpellMapImages: [], //wgs84快拼图片数据
      gcjSpellMapImages: [], //gcj快拼图片数据
      mission_id: "", //快拼mission_id
      flight_task_name: "",
      features: ["bg", "road", "building", "point"],
      // 直播相关
      isShowVideoList: false,
      videoLoaded: false,
      liveUrls: {}, // 直播链接
      mainContentType: "map", // video || map
      isMapHidden: false,
      planeFlightData: undefined, //回放的飞行数据
      loading: false, //请求数据时的加载状态
      allTimeS: 0, //回放轨迹运行了多少秒
      startTime: 0,
      showBack: true, //显示进度条
      playBackTop: 0, //回放按钮距离上边的距离

      commonBackPlayStartTime: 0, //通用回放时初始时间
      commonBackPlayTimer: null, //定时器
      commonAllTotal: 0, //剩余的数量
      commonAllLength: 0,
      commonAllTime: 0, //剩余时间
      isCommonBack: true,

      monitorPlaneData: {
        Altitude: 0,
        Coordinate: "WGS-84",
        Course: 0,
        FlightTime: 0,
        Gs: 0,
        Height: 0,
        Latitude: "00.000000",
        Longitude: "00.0000000",
        ManufactureID: "",
        OrderID: "",
        TimeStamp: "",
        UASID: "",
        software_code: ""
      },
      holder_user_data: 1,
      canShowSpell: 7, //用于开启隐藏快拼
      websocketView: "", //连接websocker
      isPackage: false, //回放数据是否是package带过来的数据
      uavSocket: null, // 连接工具管理包的服务
      fmUpdateAt: '', // 更新时间
      videoUrl: '', //播放的视频地址
      curContentType: 'map',
      tileLayer: ''
    };
  },

  computed: {
    // ...mapState(["monitorPlaneData", "token"]),
    ...mapState(["token"]),

    flyTime: function () {
      //计算分钟
      //算法：将秒数除以60，然后下舍入，既得到分钟数
      let s = 0;
      if (this.isCommonBack) {
        s = this.allTimeS - this.commonAllTime;
      } else {
        s = this.monitorPlaneData.FlightTime;
      }
      let time = this.sChangeTime(s);
      return time;
    },
    endTime() {
      //计算分钟
      //算法：将秒数除以60，然后下舍入，既得到分钟数
      if (this.planeFlightData) {
        let data = this.planeFlightData.packages;
        let commonData = this.planeFlightData.commonPackages;
        if (data && data.length !== 0) {
          let sData =
            (Number(new Date(data[data.length - 1].created_at)) -
              Number(new Date(data[0].created_at))) /
            1000;
          this.allTimeS = Math.ceil(sData);
          let time = this.sChangeTime(this.allTimeS);
          return time;
        }
        if (commonData && commonData.length > 0) {
          let sData =
            Number(commonData[0].create_time) -
            Number(commonData[commonData.length - 1].create_time);
          this.allTimeS = Math.ceil(sData);
          let time = this.sChangeTime(this.allTimeS);
          return time;
        }
      }
      return "00:00";
    }
  },
  watch: {
    monitorPlaneData: {
      //深度监听，可监听到对象、数组的变化
      handler: "handleMonitorPlaneUpdate",
      deep: true //true 深度监听
    },
    "feimaManager.fmMonitor.flightPanelDatas": {
      handler(newVal, oldVal) {
        this.nowTime = this.sChangeTime(newVal.flyTime);
      },
      deep: true
    }
  },
  filters: {
    flitTowData(value) {
      value = value / 100;
      value = Math.round(parseFloat(value) * 10) / 10;
      let s = value.toString().split(".");
      if (s.length == 1) {
        value = value.toString() + ".0";
        return value;
      }
      if (s.length > 1) {
        if (s[1].length < 2) {
          value = value.toString();
        }
        return value;
      }
    },
    flitOneData(value) {
      value = value / 10;
      value = Math.round(parseFloat(value) * 10) / 10;
      let s = value.toString().split(".");
      if (s.length == 1) {
        value = value.toString() + ".0";
        return value;
      }
      if (s.length > 1) {
        if (s[1].length < 2) {
          value = value.toString();
        }
        return value;
      }
    },
    saveLnglat1(value) {
      value = Math.round(parseFloat(value) * 1000000) / 1000000;
      let s = value.toString().split(".");
      if (s.length == 1) {
        value = value.toString() + ".000000";
        return value;
      }
      if (s.length > 1) {
        if (s[1].length < 2) {
          value = value.toString() + "0";
        }
        return value;
      }
    },
    filtTimeStamp(val) {
      if (typeof val === "number") {
        val = String(val);
      }
      return (
        val.substring(8, 10) +
        ":" +
        val.substring(10, 12) +
        ":" +
        val.substring(12, 14)
      );
    }
  },
  mounted() {
    this.init();
    setTimeout(() => {
      this.$refs.spellIt && this.$refs.spellIt.initGetStatus();

    },1500)
  },
  methods: {
    ...mapMutations(["setMonitorPlaneData"]),
    showSpellIt() {
      this.canShowSpell++;
    },
    text() {
      this.holder_user_data = "cxs";
    },
    init() {
      this.initMap();
    },
    initMap() {
      let map = new AMap.Map(this.software_code + this.source, {
        resizeEnable: true,
        mapStyle: "amap://styles/dark",
        zoom: 4,
        features: ["bg", "road", "building", "point"]
      });
      this.map = map;

      // map.add(layer);
      this.tiandituLayer = new AMap.TileLayer({
        getTileUrl:
          "//t{0,1,2,3,4,5,6,7}.tianditu.gov.cn/DataServer?T=vec_w&tk=" +
          process.env.VUE_APP_TIANDITU_KEY +
          "&x=[x]&y=[y]&l=[z]",
        zIndex: 3
      });
      this.tianditutext = new AMap.TileLayer({
        getTileUrl:
          "//t{0,1,2,3,4,5,6,7}.tianditu.gov.cn/DataServer?T=cva_w&tk=" +
          process.env.VUE_APP_TIANDITU_KEY +
          "&x=[x]&y=[y]&l=[z]",
        zIndex: 101
      });
      this.googleLayer = new AMap.TileLayer({
        getTileUrl:
          "//wprd0{1,2,3,4}.is.autonavi.com/appmaptile?&x=[x]&y=[y]&z=[z]&lang=zh_cn&size=1&scl=1&style=6",
        zIndex: 3
      });

      // 天地图影像图层
      this.tiandituYinxiangLayer = new AMap.TileLayer({
        getTileUrl:
          "//t{0,1,2,3,4,5,6,7}.tianditu.gov.cn/DataServer?T=img_w&tk=" +
          process.env.VUE_APP_TIANDITU_KEY +
          "&x=[x]&y=[y]&l=[z]",
        zIndex: 3
      });

      this.setPlaneConfigure();
      // 区块任务内监控
      if (this.isBlock) {
        this.$emit("getMap", this.map);
        this.map.setFeatures([]);
        this.switchMap(3);
      }
      if (this.isImageMap) {
        this.isWgs = true;
        this.mapIndex = 3;
        this.map.setFeatures([]);
        this.tianditutext.setMap(this.map);
        this.tiandituYinxiangLayer.setMap(this.map);
      }
    },
    clickMonitor() {
      // console.log(this.isControl);
      if (this.isControl !== -1) {
        this.isControl = -1;
      }
      if (this.curContentType === 'video') {
        this.curContentType = 'map'
      }
    },
    // 设置飞机配置
    setPlaneConfigure() {
      let imageItem = null;
      if (this.brandName === "彩虹") {
        imageItem = require("@/assets/img/centerHome/onlineIcon/caihong-online.svg");
      } else {
        imageItem = require("@/assets/img/homeDialog/other-online.svg");
      }

      this.planeIcon = new AMap.Icon({
        size: new AMap.Size(32, 32),
        image: imageItem,
        imageSize: new AMap.Size(32, 32),
        imageOffset: new AMap.Pixel(0, 0)
      });
    },
    // 打开更多信息弹窗
    openMoreData() {
      this.isShowData = !this.isShowData;
    },
    // 设置飞机所在位置图标
    setPlaneIcon(lnglat) {
      // console.log(lnglat, "设置飞机所在位置图标");
      this.marker = new AMap.Marker({
        position: lnglat,
        icon: this.planeIcon,
        zIndex: 111,
        offset: new AMap.Pixel(-16, -16)
      });
      this.map.add(this.marker);
    },
    // 选择地图
    switchMap(index) {
      if (index !== this.mapIndex) {
        this.mapIndex = index;
        if (index === 1) {
          this.map.setFeatures(this.features);
          this.tiandituLayer.setMap(null);
          this.tiandituYinxiangLayer.setMap(null);
          this.tianditutext.setMap(null);
          if (this.canFly) {
            if (this.isWgs) {
              if (this.planeType === "common") {
                this.selectGaoDe();
              } else {
                this.selectFeimaGaode();
              }
            }
          } else {
            this.$emit("changeMonitorMap", !this.isWgs);
          }

          this.isWgs = false;
        } else if (index === 2) {
          this.map.setFeatures([]);

          this.tiandituYinxiangLayer.setMap(null);
          this.tianditutext.setMap(this.map);
          this.tiandituLayer.setMap(this.map);
          if (this.canFly) this.selectWgs84Map();
          else {
            if (!this.isWgs) {
              this.$emit("changeMonitorMap", !this.isWgs);
              this.isWgs = true;
            }
          }
        } else {
          this.tianditutext.setMap(this.map);
          this.tiandituYinxiangLayer.setMap(this.map);
          if (this.canFly) this.selectWgs84Map();
          else {
            if (!this.isWgs) {
              this.$emit("changeMonitorMap", !this.isWgs);
              this.isWgs = true;
            }
          }
        }
        // this.map.setCenter(this.trajectory[0]);
      }
    },
    // 选中高德地图时，通用无人机的轨迹图标的切换
    selectGaoDe() {
      this.map.setFeatures(this.features);
      this.trajectory = JSON.parse(JSON.stringify(this.gcjTrajectory));
      this.polyline.setPath(this.trajectory);
      this.marker.setPosition(this.trajectory[0]);
      const center = this.map.getCenter();
      this.map.setCenter(coordtransform.wgs84togcj02(center.lng, center.lat));
    },
    // 飞马无人机选择高德地图时的切换
    selectFeimaGaode() {
      this.map.setFeatures(this.features);
      this.routeLine.setPath(this.feimaManager.airline.path);
      this.homePoint.setPosition(this.feimaManager.markers[0].lnglat);
      this.endPoint.setPosition(this.feimaManager.markers[1].lnglat);
      const center = this.map.getCenter();
      this.map.setCenter(coordtransform.wgs84togcj02(center.lng, center.lat));
      const gcj02Airline = this.feimaManager.flyingAirline.path;
      this.flyingAirline.setPath(this.feimaManager.flyingAirline.path);
      console.log("切换高德");
      this.feimaPlaneMarker &&
        this.feimaPlaneMarker.setPosition([
          this.feimaManager.fmMonitor.currentPosition.longitude,
          this.feimaManager.fmMonitor.currentPosition.latitude
        ]);
      for (let key in this.totalMapImages) {
        this.totalMapImages[key].setMap(this.map);
      }
      for (let key in this.wgsSpellMapImages) {
        this.wgsSpellMapImages[key].setMap(null);
      }
    },
    // 切换成wgs84底图时使用
    selectWgs84Map() {
      if (!this.isWgs) {
        this.map.setFeatures([]);
        if (this.planeType === "common") {
          this.trajectory = JSON.parse(JSON.stringify(this.wgsTrajectory));
          this.polyline.setPath(this.trajectory);
          this.marker.setPosition(this.trajectory[0]);
        } else {
          // 有快拼影像时进行切换
          // 将gcj02的隐藏
          for (let key in this.totalMapImages) {
            this.totalMapImages[key].setMap(null);
          }
          for (let key in this.wgsSpellMapImages) {
            this.wgsSpellMapImages[key].setMap(this.map);
          }
          // 显示wgs84的
          // this.wgsSpellMapImages.forEach(item=>{
          //   item.setMap(this.map)
          // })
          this.routeLine.setPath(this.feimaManager.wgs84Airline.path);
          this.homePoint.setPosition(this.feimaManager.wgs84Markers[0].lnglat);

          this.endPoint.setPosition(this.feimaManager.wgs84Markers[1].lnglat);
          const wgs84Airline = this.feimaManager.wgs84FlyingAirline.path;
          this.flyingAirline.setPath(wgs84Airline);
          this.feimaPlaneMarker &&
            this.feimaPlaneMarker.setPosition([
              this.feimaManager.fmMonitor.wgs84CurrentPosition.longitude,
              this.feimaManager.fmMonitor.wgs84CurrentPosition.latitude
            ]);
        }
        const center = this.map.getCenter();
        this.map.setCenter(coordtransform.gcj02towgs84(center.lng, center.lat));
        this.isWgs = true;
      }
    },
    // 选择右侧的控件，从上到下，从1开始
    clickControl(index) {
      if (index === 6 || index === 3 || index === 2 || index === 1) {
        if (index === this.isControl) {
          this.isControl = -1;
        } else {
          this.isControl = index;
        }
        if (index === 6 && !this.videoLoaded) {
          // this.playVideo();
        }
        this.isShowData = false;
      } else {
        this.$message("功能暂未开放");
      }
    },
    // 画轨迹
    drawTrajectory(lnglat, drawLine = true) {
      if (!this.isWgs) {
        this.trajectory.unshift(
          coordtransform.wgs84togcj02(lnglat[0], lnglat[1])
        );
      } else {
        this.trajectory.unshift([lnglat[0], lnglat[1]]);
      }
      if (drawLine) this.polyline.setPath(this.trajectory);
      this.gcjTrajectory.unshift(
        coordtransform.wgs84togcj02(lnglat[0], lnglat[1])
      );
      this.wgsTrajectory.unshift([lnglat[0], lnglat[1]]);
    },
    // 画线
    drawPolyline() {
      if (this.polyline.length === 0) {
        this.polyline = new AMap.Polyline({
          path: this.trajectory,
          strokeColor: "#00AAFF",
          strokeOpacity: 1,
          strokeWeight: 1,
          // 折线样式还支持 'dashed'
          strokeStyle: "solid",
          // strokeStyle是dashed时有效
          strokeDasharray: [10, 5],
          lineJoin: "round",
          lineCap: "round",
          zIndex: 50
        });
        this.polyline.setMap(this.map);
      }

      // 缩放地图到合适的视野级别
      // this.map.setFitView([this.polyline]);
    },
    // 链接websocket
    linkWebsocket(isHistory, lnglat) {
      this.isHistory = isHistory;
      this.loading = true;
      this.showData = { 仪表盘: true, 轨迹: true };
      this.$apis
        .getDeviceMonitorData(
          {
            software_code: this.software_code,
            mission_id: this.order_id
          },
          this.token
        )
        .then(data => {
          let planeData = data.data.data;
          this.isCommonBack = isHistory;

          if (planeData !== null) {
            if (!isHistory) {
              if (planeData.length > 0) {
                this.trajectory = [];
                this.points = [];
                data.data.data.forEach(item => {
                  this.trajectory.push([item.lon, item.lat]);
                  this.gcjTrajectory.push(
                    coordtransform.wgs84togcj02(item.lon, item.lat)
                  );
                  this.wgsTrajectory.push([item.lon, item.lat]);
                  this.points.push(turf.point([item.lon, item.lat]));
                  this.distancePoints.push([item.lon, item.lat]);
                });
                this.getFlyArea();

                this.drawPolyline();
                this.map.setFitView([this.polyline]);
                if (this.trajectory.length > 0)
                  this.setPlaneIcon(this.trajectory[0]);
                planeData[0]["Latitude"] = planeData[0].lat;
                planeData[0]["Longitude"] = planeData[0].lon;
                planeData[0]["TimeStamp"] = String(planeData[0].TimeStamp);
                this.monitorPlaneData = planeData[0];
              } else {
                this.drawPolyline();
                this.setPlaneIcon(
                  coordtransform.wgs84togcj02(lnglat[0], lnglat[1])
                );
                this.map.setZoomAndCenter(
                  15,
                  coordtransform.wgs84togcj02(lnglat[0], lnglat[1])
                );
                this.setDefaultData();

                // this.$message("该飞机暂未有监控");
              }
              //
              this.websocketView = new LinkWebsocketView();
              this.websocketView.wsSendMes({
                option: "monitor_device",
                data: {
                  software_code: this.software_code
                }
              });
              this.websocketView.wsSendDataToCom = e => {
                this.monitorPlaneData = JSON.parse(e.data).data;
              };
            } else {
              if (planeData.length > 0) {
                this.drawPolyline();
                this.planeFlightData = {};
                this.planeFlightData.commonPackages = planeData;
                let sData =
                  Number(planeData[0].create_time) -
                  Number(planeData[planeData.length - 1].create_time);
                this.commonAllTime = sData; //总的时间
                this.commonBackPlayStartTime =
                  planeData[planeData.length - 1].create_time;
                this.commonAllTotal = planeData.length - 1; //通用回放时总数据长度
                this.commonAllLength = planeData.length - 1;
                if (this.isWgs) {
                  this.setPlaneIcon([
                    planeData[planeData.length - 1].lon,
                    planeData[planeData.length - 1].lat
                  ]);
                } else {
                  this.setPlaneIcon(
                    coordtransform.wgs84togcj02(
                      planeData[planeData.length - 1].lon,
                      planeData[planeData.length - 1].lat
                    )
                  );
                }

                this.map.setFitView();
                // this.setMonitorPlaneData(planeData[this.commonAllTotal]);
                this.monitorPlaneData = this.planeFlightData.commonPackages[
                  this.commonAllTotal
                ];

                this.commonAllTotal--;
                this.commonBackPlay();
              }
            }
          } else {
            if (planeData.length !== 0) {
              this.drawPolyline();
              this.setDefaultData();
            } else {
              this.$message({
                type: "warning",
                message: "暂无数据"
              });
            }
          }

          this.loading = false;
        });
    },
    // 通用飞机回放
    commonBackPlay() {
      this.commonBackPlayTimer = setInterval(() => {
        if (this.commonAllTime >= 0) {
          this.commonBackPlayStartTime++;
          if (
            this.commonBackPlayStartTime ===
            this.planeFlightData.commonPackages[this.commonAllTotal].create_time
          ) {
            this.monitorPlaneData = this.planeFlightData.commonPackages[
              this.commonAllTotal
            ];
            // this.setMonitorPlaneData(
            //   this.planeFlightData.commonPackages[this.commonAllTotal]
            // );
            this.commonAllTotal--;
          }

          this.commonAllTime--;
        } else {
          clearInterval(this.commonBackPlayTimer);
        }
      }, 1000);
    },
    // 设置通用无人机默认数据，在没有获取到无人机信息时使用
    setDefaultData() {
      this.$emit("setFlyArea", 0);
      let planeDatas = {
        Altitude: 0,
        Coordinate: "WGS-84",
        Course: 0,
        FlightTime: 0,
        Gs: 0,
        Height: 0,
        Latitude: 0,
        Longitude: 0,
        ManufactureID: "",
        OrderID: "",
        TimeStamp: "00000000000000",
        UASID: "",
        software_code: ""
      };
      this.setMonitorPlaneData(planeDatas);
    },
    // 关闭websocket
    closeWebsocket() {
      this.websocketView.wsCloseWhenCloseView();
    },
    // 计算轨迹面积
    getFlyArea() {
      if (this.points.length > 2) {
        try {
          let featPoints = turf.featureCollection(
            JSON.parse(JSON.stringify(this.points))
          );
          const hull = turf.convex(featPoints);
          const area = turf.area(hull) * 0.000001;
          this.$emit("setFlyArea", area);
        } catch (e) {
          // console.log(e);
          this.$emit("setFlyArea", 0);
        }
      } else {
        this.$emit("setFlyArea", 0);
      }
    },
    // 第二个控件选择显示
    selectShow(key) {
      if (this.planeType !== "common") {
        if (key === "航线") {
          if (this.showData[key]) this.routeLine.hide();
          else this.routeLine.show();
        }
        if (key === "轨迹") {
          if (this.showData[key]) this.flyingAirline.hide();
          else this.flyingAirline.show();
        }
      } else {
        if (key === "轨迹") {
          if (this.showData[key]) this.polyline.hide();
          else this.polyline.show();
        }
      }

      this.showData[key] = !this.showData[key];
    },
    // 打开区块播放
    openBlockFeimaPlay(res, local_id) {
      this.fmMonitorData(res, local_id);
    },
    // 飞马无人机的监控
    async openFeimaDetail(local_id) {
      // local_id = 'a13155c4c90849b1823a094a7224c4f5'
      this.loading = true;

      this.mission_id = local_id;
      const isTest = false;
      UavManagerLive.playLive({
        domId: "videoBody" + this.source,
        localId: local_id,
        isTest,
      });

      this.getFeimaMonitorData("/flightMission/monitor", { local_id }).then(
        res => {
          // if (res.data.data.flight_task_name) {
          this.flight_task_name = res.data.data.flight_task_name;
          if (!res.data.is_mission_flying) {
            this.getFeimaMonitorData("/flightLog/getDecode", {
              flight_mission_id: res.data.data.id
            })
              .then((urlRes) => {
                this.loading = false;
                this.planeFlightData = {}
                this.planeFlightData.packages = urlRes.data;
                res.data.data.flight_log = {};
                res.data.data.flight_log.packages = urlRes.data;
                this.fmMonitorData(res, local_id);
              })
              .catch(err => {
                console.log(err);
                this.loading = false;
                this.planeFlightData = undefined;
                this.$message({
                  type: "warning",
                  message: "暂无数据"
                });
              });
          } else {
            this.fmMonitorData(res, local_id);
          }
          // } else {
          //   this.loading = false;
          //   this.$message({
          //     type: "warning",
          //     message: "暂无数据3"
          //   });
          // }
        }
      );
    },
    getPlaneWebsocketUrl() {
      let websocketUrl = process.env.VUE_GET_MONITOR_WEBSOCKETURL
        ? process.env.VUE_GET_MONITOR_WEBSOCKETURL
        : "//airport.afuav.com/api/device/monitor/info";
      return new Promise((resolve, reject) => {
        let url = websocketUrl + "?serial_no=" + this.software_code;
        let _this = this;
        axios
          .get(url)
          .then(function (response) {
            resolve(response.data.data);
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },
    async fmMonitorData(res, local_id) {
      // this.$refs.spellIt && this.$refs.spellIt.getStatus();
      let ws_url = "";
      let tb_id = "";
      // await this.getPlaneWebsocketUrl().then(data => {
      //   ws_url = data.ws_url;
      //   tb_id = data.data.tb_id;
      // });
      this.isHistory = !res.data.is_mission_flying
      const fmMonitorOptions = {
        isWgsNeeded: false,
        flight_data: res.data.data, //必填。 请求到的任务数据
        local_id: local_id, // 必填。任务id
        url: ws_url, // 必填。websocket监听地址
        platform: "", // 选填。不填默认web端
        is_mission_flying: res.data.is_mission_flying, // 必填。是否正在飞行中
        airlineOptions: {
          // 选填。
          base_airline_options: {
            // 选填。底层航线
            strokeColor: "#FFCD33",
            strokeWidth: 2,
            id: "1"
          },
          upper_airline_options: {
            // 选填。上层航线
            strokeColor: "#0090FF",
            strokeWidth: 4,
            id: "2"
          }
        },
        markersOptions: {
          // 网页端参考amap marker文档, uniapp端参考文档。配置除图片和位置外的所需参数
          landingMarker: {}, // 起飞点
          endPointMarker: {}, // 终止点
          planeMarker: {} // 飞机marker
        }
      };
      fmMonitorOptions.wsMessageFunc = () => {
        return `{"attrSubCmds":[],"tsSubCmds":[{"entityType":"DEVICE","entityId":"${tb_id}","scope":"LATEST_TELEMETRY","cmdId":1}],"historyCmds":[]}`;
      };
      let temPoint = [];
      this.points = JSON.parse(JSON.stringify(temPoint));
      PathConfig.setConfigs(imgPathConfig); // 图片路径
      this.feimaManager = new FmMapManager(fmMonitorOptions);
      const that = this
      // 使用飞马连接工具包进行连接
      this.uavSocket = await UavManagerSocket.useUavSocket({
        serialNo: '',
        missionId: local_id,  // 设备id
        // 是否是测试服,若只为了测试，这里可以直接修改为true 或者 false
        isTestServer: false,
        onReceiveData: function (data) {
          // 接收数据
          that.feimaManager.onReceiveData(data);
          // 更新时间
          that.fmUpdateAt = that.feimaManager.fmMonitor.getUpdate(new Date().getTime());
        },
        onError: function (err) {
          that.feimaManager.errorMonitor(err);
        },
      });
      this.feimaManager.onPlaneMove = data => {
        this.getFeimaWebsocketData(data);
      };
      // 异步，导致没有获取到信息
      setTimeout(() => {
        this.$emit("setFeimaDroneInfo", this.feimaManager.fmMonitor.droneInfo);
      }, 1000);
      if (this.isWgs) {
        this.routeLine = new AMap.Polyline({
          ...this.feimaManager.wgs84Airline,
          zIndex: 50
        });
        this.routeLine.setMap(this.map);
        this.map.setFitView(this.routeLine);
        this.newMarker(this.feimaManager.wgs84Markers[0], "homePoint");
        this.newMarker(this.feimaManager.wgs84Markers[1], "endPoint");
      } else {
        this.routeLine = new AMap.Polyline({
          ...this.feimaManager.airline,
          zIndex: 50
        });
        this.routeLine.setMap(this.map);
        this.map.setFitView(this.routeLine);
        this.newMarker(this.feimaManager.markers[0], "homePoint");
        this.newMarker(this.feimaManager.markers[1], "endPoint");
      }
      // 设置飞机
      // websocket端口
      this.feimaManager.errorMonitor = error => {
        this.$message("连接已断开，请重新进入");
        // console.log("链接断开");
      };
      this.loading = false;
    },
    /**
     * 获取到飞行数据
     */
    getUrlFlyLog(data) {
      return new Promise((resolve, reject) => {
        let url = data.flight_log.file_url;
        var _this = this;

        axios.defaults.headers.post["Content-Type"] =
          "application/json;charset=UTF-8";
        try {
          axios
            .get(url)
            .then(function (response) {
              resolve(_this.getResult);
            })
            .catch(function (error) {
              reject(error);
            });
        } catch (err) {
          reject(error);
        }
      });
    },
    /**
     * 使用云监控接口获取到数据
     */
    getFeimaMonitorData(api, params) {
      return new Promise((resolve, reject) => {
        let url = process.env.VUE_APP_FEIMA_MONITOR_API + api;
        var _this = this;

        axios.defaults.headers.post["Content-Type"] =
          "application/json;charset=UTF-8";
        try {
          axios
            .get(url, { params })
            .then(function (response) {
              _this.getResult = response.data;
              resolve(_this.getResult);
            })
            .catch(function (error) {
              reject(error);
            });
        } catch (err) {
          reject(error);
        }
      });
    },
    /**
     * 暂停回放
     */
    pause() {
      if (this.planeType !== "common") {
        this.feimaManager.fmMonitor.pausePlayBack();
      } else {
        clearInterval(this.commonBackPlayTimer);
      }
    },
    /**
     * 开始播放
     */
    openBackPlay() {
      if (this.planeType !== "common") {
        console.log("播放");

        this.feimaManager.fmMonitor.openPlayBack();
      } else {
        this.commonBackPlay();
      }
    },
    /**
     * 移动进度条
     */
    moveProgress(precent = 0) {
      if (this.planeType !== "common") {
        this.feimaManager.fmMonitor.setPlaybackProgress(precent).then(res => {
          if (this.flyingAirline === "") {
            this.flyingAirline = new AMap.Polyline({
              path: this.isWgs
                ? this.feimaManager.wgs84FlyingAirline.path
                : this.feimaManager.flyingAirline.path,
              strokeColor: that.feimaManager.wgs84FlyingAirline.strokeColor,
              strokeWeight: that.feimaManager.wgs84FlyingAirline.strokeWidth,
              zIndex: 50
            });
            this.flyingAirline.setMap(this.map);
          } else {
            if (this.isWgs) {
              this.flyingAirline.setPath(
                this.feimaManager.wgs84FlyingAirline.path
              );
            } else {
              this.flyingAirline.setPath(this.feimaManager.flyingAirline.path);
            }
          }
        });
        this.points = [];
        this.feimaManager.wgs84FlyingAirline.path.forEach(item => {
          this.points.push(turf.point([item[0], item[1]]));
        });
        this.getFlyArea();
      } else {
        clearInterval(this.commonBackPlayTimer);
        let count = Math.ceil(this.commonAllLength * precent);
        this.commonAllTime = this.allTimeS - Math.ceil(this.allTimeS * precent);
        this.commonAllTotal = this.commonAllLength - count;
        let data = this.planeFlightData.commonPackages[this.commonAllTotal];
        this.commonBackPlayStartTime = this.planeFlightData.commonPackages[
          this.commonAllTotal + 1
        ].create_time;
        if (this.isWgs) {
          this.marker.setPosition([data.lon, data.lat]);
        } else {
          this.marker.setPosition(
            coordtransform.wgs84togcj02(data.lon, data.lat)
          );
        }

        this.trajectory = [];
        this.gcjTrajectory = [];
        this.wgsTrajectory = [];
        this.points = [];

        for (let i = this.commonAllLength; i > this.commonAllTotal; i--) {
          // console.log(i);
          this.drawTrajectory(
            [
              this.planeFlightData.commonPackages[i].lon,
              this.planeFlightData.commonPackages[i].lat
            ],
            false
          );
          this.points.push(
            turf.point([
              this.planeFlightData.commonPackages[i].lon,
              this.planeFlightData.commonPackages[i].lat
            ])
          );
        }
        this.drawTrajectory([data.lon, data.lat], false);
        this.polyline.setPath(this.trajectory);
        this.getFlyArea();
        this.commonBackPlay();
        //
      }
    },
    // 区块飞马无人机的监控
    blockOpenFeimaDetail(local_id, res) {
      this.mission_id = local_id;
      if (this.feimaManager !== "") {
        this.feimaManager.fmMonitor.pausePlayBack();
        this.flyingAirline.setMap(null);
        this.homePoint.setMap(null);
        this.endPoint.setMap(null);
        this.routeLine.setMap(null);
        this.feimaPlaneMarker.setMap(null);
        if (this.$refs.playBack) {
          this.$refs.playBack.initData();
        }
      }
      this.points = [];
      this.feimaManager = ""; // 飞马无人机控制
      this.flyingAirline = ""; //飞马无人机轨迹
      this.flightPanelDatas = {};
      this.feimaPlaneMarker = ""; //飞马无人机图标标记点
      this.routeLine = ""; //飞马航线
      this.homePoint = ""; //飞马无人机航线开始点
      this.endPoint = ""; //飞马无人机航线结束点
      this.isWgs = true;
      this.playBackTop = "9%";
      this.flight_task_name = res.data.data.flight_task_name;
      this.planeFlightData = res.data.data.flight_log;
      if (this.planeFlightData && this.planeFlightData.packages.length === 0) {
        this.getUrlFlyLog(res.data.data).then(res1 => {
          this.planeFlightData.packages = res1;
          res.data.data.flight_log.packages = res1;
          this.openBlockFeimaPlay(res, local_id);
        });
      } else {
        this.openBlockFeimaPlay(res, local_id);
      }
    },
    // 飞马标记点移动时的数据
    getFeimaWebsocketData(data) {
      let that = this;
      if (this.flyingAirline === "") {
        this.flyingAirline = new AMap.Polyline({
          path: [],
          strokeColor: that.feimaManager.wgs84FlyingAirline.strokeColor,
          strokeWeight: that.feimaManager.wgs84FlyingAirline.strokeWidth,
          zIndex: 50
        });
        this.flyingAirline.setMap(this.map);
      } else {
        let path = that.flyingAirline.getPath();
        if (this.isWgs) {
          path.push([
            that.feimaManager.fmMonitor.wgs84CurrentPosition.longitude,
            that.feimaManager.fmMonitor.wgs84CurrentPosition.latitude
          ]);
          // console.log(path);
          that.flyingAirline.setPath(path);
        } else {
          path.push([
            that.feimaManager.fmMonitor.currentPosition.longitude,
            that.feimaManager.fmMonitor.currentPosition.latitude
          ]);
          this.flyingAirline.setPath(path);
        }
      }
      if (this.feimaPlaneMarker === "") {
        if (this.feimaManager.markers[2] !== undefined) {
          let planeMarker = this.feimaManager.markers[2];
          this.setFeimaPlaneMarker(planeMarker);
        }
      } else {
        if (this.isWgs) {
          that.feimaPlaneMarker.setPosition([
            this.feimaManager.fmMonitor.wgs84CurrentPosition.longitude,
            this.feimaManager.fmMonitor.wgs84CurrentPosition.latitude
          ]);
        } else {
          that.feimaPlaneMarker.setPosition([
            this.feimaManager.fmMonitor.currentPosition.longitude,
            this.feimaManager.fmMonitor.currentPosition.latitude
          ]);
        }
        this.feimaPlaneMarker &&
          this.feimaPlaneMarker.setAngle(
            this.feimaManager.fmMonitor.planeRotate
          );
        this.points.push(turf.point([data.longitude, data.latitude]));
        this.wsCounter++;
        if (this.wsCounter === 10) {
          this.getFlyArea();
          this.wsCounter = 0;
        }
      }
    },
    // 设置起飞点和结束点标记点
    newMarker(makerData, name) {
      this[name] = new AMap.Marker({
        position: makerData.lnglat,
        icon: makerData.icon,
        zIndex: 111,
        offset: new AMap.Pixel(0, 0),
        anchor: "center"
      });
      this.map.add(this[name]);
    },
    // 设置飞马无人机图标标记点，因为要多次赋值经纬度，所以要绑定数据
    setFeimaPlaneMarker(data) {
      this.feimaPlaneMarker = new AMap.Marker({
        position: data.lnglat,
        icon: data.icon,
        zIndex: 111,
        offset: new AMap.Pixel(0, 0),
        anchor: "center"
      });
      this.map.add(this.feimaPlaneMarker);
    },
    // 关闭飞马无人机
    closeFeimaWebsocket() {
      this.feimaManager && this.feimaManager.stopMonitor();
    },
    // 进行快拼
    spellitImg(data) {
      const item = data;
      const imageUrl = item["path"];
      if (this.totalMapImages[imageUrl]) {
        // do nothing
      } else {
        try {
          const neLng = item["north_east_lng"];
          const neLat = item["north_east_lat"];
          // const neCoord = coordtransform.wgs84togcj02(neLng, neLat);
          const neCoord = [neLng, neLat];
          const swLng = item["south_west_lng"];
          const swLat = item["south_west_lat"];
          // const swCoord = coordtransform.wgs84togcj02(swLng, swLat);
          const swCoord = [swLng, swLat];
          let that = this;
          if (this.isWgs) {
            this.totalMapImages[imageUrl] = new AMap.GroundImage(
              imageUrl,
              new AMap.Bounds(
                coordtransform.wgs84togcj02(swLng, swLat),
                coordtransform.wgs84togcj02(neLng, neLat)
              )
            );
            this.wgsSpellMapImages[imageUrl] = new AMap.GroundImage(
              imageUrl,
              new AMap.Bounds(swCoord, neCoord),
              {
                map: that.map
              }
            );
          } else {
            this.totalMapImages[imageUrl] = new AMap.GroundImage(
              imageUrl,
              new AMap.Bounds(
                coordtransform.wgs84togcj02(swLng, swLat),
                coordtransform.wgs84togcj02(neLng, neLat)
              ),
              {
                map: that.map
              }
            );
            this.wgsSpellMapImages[imageUrl] = new AMap.GroundImage(
              imageUrl,
              new AMap.Bounds(swCoord, neCoord)
            );
          }
        } catch (err) {
          console.log("错误了", err);
        }

        // this.map.setZoomAndCenter(15, neCoord);
      }
    },
    clearSingleTileImagers(titlesUrl) {
      for (const key in this.totalMapImages) {
        this.totalMapImages[key].setMap(null);
        this.wgsSpellMapImages[key].setMap(null);
      }
      this.totalMapImages = []
      this.wgsSpellMapImages = []
      this.clearTileLayer()

      this.tileLayer = new AMap.TileLayer({
        'getTileUrl': (x, y, z) => {
          const reY = Math.pow(2, z) - y - 1;
          var a = titlesUrl + z + '/' + (x) + '/' + reY + '.png'
          return a;
        },
        zIndex: 49,
        zooms: [10, 20]
      });
      console.log(this.map);
      this.tileLayer.setMap(this.map);
      this.map.setFitView(this.tileLayer)
    },
    clearTileLayer() {
      if (this.tileLayer){
        this.tileLayer.setMap(null)
        this.tileLayer = null
      }
      
      // CMap.removeLayer(tileLayer)
    },
    playVideo() {
      if (this.planeType === "common") {
        this.$message("功能暂未开放");
        return;
      }
    },
    // 打开视频列表
    showVideoList() {
      this.$message("功能暂未开放");
      // this.isShowVideoList=!this.isShowVideoList
    },
    // 隐藏地图弹窗
    hidePopupMap() {
      if (this.mainContentType === "video") {
        this.isMapHidden = !this.isMapHidden;
      }
    },
    handleMonitorPlaneUpdate(val) {
      if (val.Latitude !== 0 || val.Longitude !== 0) {
        let position;
        if (this.isWgs) {
          position = [val.Longitude || val.lon, val.Latitude || val.lat];
        } else {
          position = coordtransform.wgs84togcj02(
            val.Longitude || val.lon,
            val.Latitude || val.lat
          );
        }
        if (this.marker && typeof this.marker.setPosition === "function") {
          this.marker.setPosition(position);
        }
      }
      if ((val.Longitude !== 0, val.Longitude !== 0))
        this.drawTrajectory([
          val.Longitude || val.lon,
          val.Latitude || val.lat
        ]);
      this.wsCounter++;
      this.points.push(
        turf.point([val.Longitude || val.lon, val.Latitude || val.lat])
      );
      if (this.wsCounter === 10) {
        this.getFlyArea();
        this.wsCounter = 0;
      }
    },
    // 切换视频与地图
    /**
     * @param {String} 切换操作来源  popuper: 点击弹窗主体; control: 点击弹窗的切换按钮
     */
    switchVideoMap(from) {
      if (from === "popuper") {
        this.isControl = 6;
      }
      const popuper = document.getElementById("popuper" + this.source);
      const mainWrapper = document.getElementById("mainWrapper" + this.source);

      const map = document.getElementById(this.software_code + this.source);
      const video = document.getElementById("videoBody" + this.source);
      console.log(video, popuper, mainWrapper, map, 4567);
      if (this.mainContentType === "map") {
        popuper.removeChild(video);
        mainWrapper.append(video);

        mainWrapper.removeChild(map);
        popuper.append(map);

        this.mainContentType = "video";
      } else {
        popuper.removeChild(map);
        mainWrapper.append(map);

        mainWrapper.removeChild(video);
        popuper.append(video);

        this.mainContentType = "map";
      }
    },
    /**
     * 秒转为时间
     */
    sChangeTime(s) {
      let h;
      h = Math.floor(s / 60);
      //计算秒
      //算法：取得秒%60的余数，既得到秒数
      s = s % 60;
      //将变量转换为字符串
      h += "";
      s += "";
      //如果只有一位数，前面增加一个0
      h = h.length == 1 ? "0" + h : h;
      s = s.length == 1 ? "0" + s : s;
      return h + ":" + s;
    },
    setVideoUrl(url) {
      this.videoUrl = url;
    },
    closeHistoryVideo() {
      this.videoUrl = '';
    },
    // 视频视图点击事件处理函数
    onVideoClick() {
      if (this.curContentType === 'map') {
        this.curContentType = 'video';
      }
    }
  },
  beforeDestroy() {
    if (this.canFly) {
      clearInterval(this.commonBackPlayTimer);
      // console.log(this.brandName);
      if (this.brandName === "飞马" || this.brandName === "大疆") {
        if (this.showBack) {
          //是历史回放数据时的操作
          this.feimaManager.fmMonitor &&
            this.feimaManager.fmMonitor.pausePlayBack();
        } else {
          this.closeFeimaWebsocket();
        }
        // 关闭mqtt连接
        this.uavSocket && this.uavSocket.close();
      } else {
        if (!this.isHistory) {
          this.closeWebsocket();
        }
      }
    }
    UavManagerLive.closeLive()
  },

};
</script>
<style lang="scss" scoped>
@import "./singleMonitor.scss"
</style>
