<template>
  <div
    :style="{
      height: projectObj.status_name != '已创建，待推送' ? '230px' : '64px',
    }"
    class="taskItem"
  >
    <div class="task-item-top">
      <el-checkbox v-model="checked">{{ projectObj.task_name }}</el-checkbox>
      <div
        v-if="
          this.checked &&
          (projectObj.status_name === '已创建，待推送' ||
            projectObj.status_name === '已推送联盟') &&
          role === 1 &&
          projectObj.status == 2
        "
        class="task-operation cursor iconfont"
      >
        <div
          v-if="
            projectObj.status_name === '已推送联盟' &&
            allianceState !== '已拒绝'
          "
          style="margin-right: 17px"
          @click="operation('接单')"
        >
          <span>&#xe62b;</span>
          接单
        </div>
        <div
          v-if="
            projectObj.status_name === '已推送联盟' &&
            allianceState !== '已拒绝'
          "
          @click="operation('拒绝')"
        >
          <span>&#xe6e7;</span>
          拒绝
        </div>
      </div>
      <div
        v-if="authority"
        class="task-item-redoProject cursor"
        @click="reformBtn(projectObj)"
        :style="{
          color:
            projectObj.task_data_original_count === 0 &&
            projectObj.task_data_result_count === 0
              ? 'rgb(187 183 183)'
              : '#1890ff',
        }"
      >
        重做
      </div>
      <div
        class="task-media-icon iconfont cursor"
        @click.stop="conversation(projectObj)"
      >
        &#xe60f;
      </div>
      <div
        v-if="authority"
        class="task-item-icon iconfont cursor"
        @click.stop="instructions(projectObj)"
      >
        &#xe633;
      </div>
    </div>
    <div
      v-if="projectObj.status_name != '已创建，待推送'"
      class="task-item-cen"
    >
      <div class="item-body iconfont">
        <div
          :style="{
            opacity:
              projectObj.task_push_data.push_to_unit_datetime !== null
                ? '1'
                : '0',
          }"
          class="first-line"
          :class="{
            reject: projectObj.task_push_data.task_last_log_name == '已拒绝',
          }"
          @click="directiveLog"
        >
          <div v-if="allianceState == ''">
            {{
              projectObj.status_name === "已推送联盟"
                ? role === 0
                  ? "已推送联盟"
                  : "巡护待接单"
                : "已接单"
            }}
          </div>
          <div
            v-if="allianceState != ''"
            :class="{
              reject: allianceState == '已超时' || allianceState == '已拒绝',
            }"
          >
            {{ allianceState }}
          </div>
        </div>
        <div class="item-div">
          <div
            v-if="projectObj.task_push_data.push_to_unit_datetime !== null"
            class="icon company"
          >
            &#xe6d6;
          </div>
          <div
            v-if="projectObj.task_push_data.push_to_unit_datetime === null"
            class="icon company vacancy"
          >
            &#xe63a;
          </div>
        </div>
        <div style="position: relative">
          <div
            :style="{
              opacity:
                projectObj.task_push_data.unit_data.length > 0 ? '1' : '0',
            }"
            class="name over-dot"
            @click="massDetails('巡护')"
          >
            <span v-if="projectObj.task_push_data.unit_data.length > 1">{{
              projectObj.task_push_data.unit_data[1].unit_name
            }}</span>
            <div
              :style="{
                paddingLeft:
                  projectObj.task_push_data.unit_data.length > 1 ? '20px' : '0',
              }"
              :title="
                projectObj.task_push_data.unit_data.length > 0
                  ? projectObj.task_push_data.unit_data[0].unit_name
                  : ''
              "
            >
              {{
                projectObj.task_push_data.unit_data.length > 0
                  ? projectObj.task_push_data.unit_data[0].unit_name
                  : ""
              }}
            </div>
          </div>
          <div
            v-if="projectObj.task_push_data.unit_data.length > 1"
            class="pcs"
            style="right: -32px"
          >
            等{{ projectObj.task_push_data.unit_data.length }}个
          </div>
        </div>
        <div class="item-div time">
          <div>
            {{ projectObj.task_push_data.push_to_unit_datetime }}
            <span
              v-if="projectObj.task_push_data.push_to_unit_datetime !== null"
              >&#xe647;</span
            >
          </div>
        </div>
        <div class="item-div time">
          {{ projectObj.task_push_data.push_to_unit_confirm_datetime }}
        </div>
      </div>
      <div class="wire" />
      <div class="item-body two" />
      <div class="item-body iconfont">
        <div
          class="first-line"
          :style="{ opacity: userState ? '1' : '0' }"
          @click="directiveLog"
        >
          <div
            :class="{ reject: userState == '已拒绝' || userState == '已超时' }"
          >
            {{ userState }}
          </div>
        </div>
        <div class="item-div">
          <div
            v-if="projectObj.task_push_data.user_data.length > 1"
            class="icon company"
          >
            &#xe6d6;
          </div>
          <div
            v-if="projectObj.task_push_data.user_data.length <= 1"
            :class="{
              vacancy: projectObj.task_push_data.user_data.length === 0,
            }"
            class="icon company"
          >
            &#xe63a;
          </div>
        </div>
        <div style="position: relative">
          <div
            class="name over-dot"
            :style="{
              opacity:
                projectObj.task_push_data.user_data.length > 0 ? '1' : '0',
            }"
            @click="massDetails('用户')"
          >
            <span v-if="projectObj.task_push_data.user_data.length > 1">{{
              projectObj.task_push_data.user_data[1].name
            }}</span>
            <div
              :style="{
                paddingLeft:
                  projectObj.task_push_data.user_data.length > 1 ? '20px' : '0',
              }"
              style="max-width: 100px"
            >
              {{
                projectObj.task_push_data.user_data.length > 0
                  ? projectObj.task_push_data.user_data[0].name
                  : ""
              }}
            </div>
          </div>
          <div
            v-if="projectObj.task_push_data.user_data.length > 1"
            class="pcs"
          >
            等{{ projectObj.task_push_data.user_data.length }}个
          </div>
        </div>
        <div class="item-div time">
          <div v-if="projectObj.task_push_data.push_to_user_datetime !== null">
            {{ projectObj.task_push_data.push_to_user_datetime }}
            <span>&#xe647;</span>
          </div>
        </div>
        <div
          v-if="
            projectObj.task_push_data.push_to_user_confirm_datetime !== null
          "
          class="item-div time"
        >
          {{ projectObj.task_push_data.push_to_user_confirm_datetime }}
        </div>
      </div>
    </div>

    <el-dialog
      :title="projectObj.task_name + '推送详情'"
      :modal="false"
      :visible.sync="dialogTableVisible"
    >
      <el-table :data="gridData">
        <el-table-column align="center" property="name" label="名称" />
        <el-table-column align="center" property="user_canceled" label="状态" />
      </el-table>
    </el-dialog>

    <el-dialog
      title="任务日志"
      top="8vh"
      :visible.sync="directiveVisible"
      width="30%"
      :modal="false"
    >
      <div
        v-for="(item, index) in directiveList"
        :key="index"
        :style="{ color: item.log_from == 'app' ? '#1182FB' : '#3C4353' }"
        class="pop-up"
      >
        <div class="pop-up-time">{{ item.created_time }}</div>
        <div class="pop-up-status">{{ item.status }}</div>
        <div v-if="item.status === '上传任务进度'" class="pop-up-progress">
          <div class="progress-bar">
            <div :style="{ width: progress + '%' }"></div>
          </div>
          <div class="progress-num">{{ progress }}%</div>
        </div>
        <div
          :style="{ opacity: index === directiveList.length - 1 ? 0 : 1 }"
          class="interval"
        >
          <div />
          <div />
          <div />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "TaskItem",
  data() {
    return {
      checked: this.checkeds,
      currentTime: "",
      gridData: [],
      dialogTableVisible: false, // 控制群发推送
      allianceState: "", //  巡护接单状态
      userState: "", //  终端接单状态
      taskCoordinatePoints: [],
      taskCoordinateWaypoints: [],
      directiveVisible: false, //  指令的弹框列表显示与隐藏
      directiveList: [], // 指令日志列表
      progress: 0, //  当前显示的任务日志的进度
    };
  },
  computed: {
    ...mapState(["currentUrl", "user", "role", "token"]),
  },
  mounted() {
    // 处理坐标 飞马任务和通用任务坐标处理方式不一样
    console.log();
    if (Number(this.$route.query.nav) === 1) {
      this.taskCoordinatePoints = this.utils.$funcResolution(
        this.projectObj.task_coordinate_points,
        true,
        1,
        this.projectObj.software_version
      );
      const waypointsArr = this.projectObj.task_coordinate_waypoints.split(
        "FF"
      )[0];
      // software_version
      this.taskCoordinateWaypoints = this.utils.$funcResolution(
        waypointsArr,
        false,
        1,
        this.projectObj.software_version
      );
    } else if (Number(this.$route.query.nav) === 2) {
      this.taskCoordinatePoints = this.utils.$funcResolution(
        this.projectObj.task_coordinate_points,
        true,
        2
      );
    }else {
      // console.log(this.projectObj.task_coordinate_points,);
      let task_coordinate_points = this.projectObj.task_coordinate_points.split(",")
      if (task_coordinate_points.length === 3){
        this.projectObj.survey_block_type = "circle"
      }else {
        this.projectObj.survey_block_type = "polygon"
      }
      this.projectObj["survey_area_coordinate_points"] = this.projectObj.task_coordinate_points
    }
    const time = this.utils.getCurrentTime();
    // 判断是否超时
    if (
      this.projectObj.task_push_data.push_data !== null &&
      Object.keys(this.projectObj.task_push_data.push_data).length !== 0
    ) {
      if (
        this.projectObj.status === 2 &&
        time > this.projectObj.task_push_data.push_data.end_date
      ) {
        this.allianceState = "已超时";
        return;
      } else if (
        this.projectObj.status === 4 &&
        time > this.projectObj.task_push_data.push_data.end_date
      ) {
        this.userState = "已超时";
        return;
      }
    }

    // 判断巡护接单状态
    if (this.projectObj.status === 2) {
      // 已推送联盟
      this.allianceState = "已推送联盟";
      if (this.projectObj.task_push_data.unit_data.length > 0) {
        this.allianceState = "巡护待接单";
      }
      // 如果所有巡护都已拒绝，则显示已拒绝
      let refuseNum = 0;
      for (
        let i = 0;
        i < this.projectObj.task_push_data.unit_data.length;
        i++
      ) {
        if (this.projectObj.task_push_data.unit_data[i].user_canceled === 1) {
          refuseNum++;
        }
      }
      if (refuseNum == this.projectObj.task_push_data.unit_data.length) {
        this.allianceState = "已拒绝";
      }
    }
    // 判断终端接单状态
    if (this.projectObj.status >= 4) {
      if (this.projectObj.status === 4) {
        // 已推送终端
        this.userState = "已推送终端";
        if (this.projectObj.task_push_data.user_data.length > 0) {
          this.userState = "终端待接单";
        }
        // 如果所有终端都已拒绝，则显示已拒绝
        let refuseNum = 0;
        for (
          let i = 0;
          i < this.projectObj.task_push_data.user_data.length;
          i++
        ) {
          if (this.projectObj.task_push_data.user_data[i].user_canceled === 1) {
            refuseNum++;
          }
        }
        if (refuseNum == this.projectObj.task_push_data.user_data.length) {
          this.userState = "已拒绝";
        }
      } else {
        this.userState = this.projectObj.status_name;
      }
    }
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    /**
     * 子任务重做
     * @param {Object} reformBtn
     */
    reformBtn(reformBtn) {
      if (
        reformBtn.task_data_original_count === 0 &&
        reformBtn.task_data_result_count === 0
      ) {
        return;
      }
      this.$confirm("是否确认重做:" + reformBtn.task_name, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.taskRedoTask(Number(reformBtn.id));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消重做",
          });
        });
    },
    taskRedoTask(id) {
      this.$apis
        .taskRedoTask(
          {
            task_id: id,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status === 0) {
            this.$emit("taskRedoTaskUpdate");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 指令日志
     */
    async directiveLog() {
      let taskData = await this.$apis.taskGetTaskDetail(
        {
          task_id: this.projectObj.id,
        },
        this.token
      );
      taskData = taskData.data.data;
      this.progress = taskData.progress ? taskData.progress : 0;
      this.$apis
        .taskGetTaskLog(
          {
            task_id: this.projectObj.id,
          },
          this.token
        )
        .then((data) => {
          this.directiveVisible = true;
          this.directiveList = data.data.data.log_data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 坐标拆分
     * @param {string} str
     */
    resolution(str, decide) {
      const arrStr = str.split(";");
      if (decide) {
        arrStr[arrStr.length - 1] = arrStr[0];
      } else {
        arrStr.pop();
      }
      const arr = [];
      for (let i = 0; i < arrStr.length; i++) {
        const lonArr = arrStr[i].split(",");
        arr[i] = [Number(lonArr[1]), Number(lonArr[0])];
      }
      return arr;
    },
    /**
     * 巡护管理员任务操作
     * @param {Object} str
     */
    operation(str) {
      if (this.allianceState === "已超时") {
        this.utils.$message("任务已超时，请联系超管重新推送", "error");
        return;
      }
      if (str === "接单") {
        this.orderOrpush();
      } else {
        this.refuse();
      }
    },
    /**
     * 群发详情
     */
    massDetails(str) {
      // this.nav !== 2 ||
      if (this.allianceState === "已超时") {
        return;
      }
      let obj = [];
      if (this.projectObj.status === 2 && str === "巡护") {
        if (this.projectObj.task_push_data.unit_data.length > 1) {
          obj = this.projectObj.task_push_data.unit_data;
        }
      } else if (
        this.projectObj.status === 4 &&
        str === "用户" &&
        this.userState !== "已超时"
      ) {
        if (this.projectObj.task_push_data.user_data.length > 1) {
          obj = this.projectObj.task_push_data.user_data;
        }
      }
      this.gridData = obj;
      if (obj.length > 0) {
        const lengthNum = this.gridData.length;
        for (let i = 0; i < lengthNum; i++) {
          this.gridData[i].user_canceled =
            this.gridData[i].user_canceled === 1 ? "已拒绝" : "用户接单中";
          if (str === "巡护") {
            this.gridData[i].name = this.gridData[i].unit_name;
          }
        }
        this.dialogTableVisible = true;
      }
    },
    /**
     * 发起音视频/聊天
     *
     */
    conversation(data) {
      this.$emit("clickConversation", {
        task: data,
      });
    },
    /**
     * Checked绑定值变化时触发的事件
     */
    alterChecked() {
      if (
        this.role === 1 &&
        this.$route.query.mobile === this.user.mobile &&
        this.$route.query.push_flag !== "self"
      ) {
        let decide = true;
        if (this.projectObj.task_push_data.unit_data.length === 1) {
          if (
            this.projectObj.task_push_data.unit_data[0].unit_name ===
            this.user.user_unit.unit_name
          ) {
            decide = false;
          }
        } else if (this.projectObj.task_push_data.unit_data.length > 1) {
          for (
            let i = 0;
            i < this.projectObj.task_push_data.unit_data.length;
            i++
          ) {
            if (
              this.projectObj.task_push_data.unit_data[i].unit_name ===
              this.user.user_unit.unit_name
            ) {
              decide = false;
              break;
            }
          }
        }
        if (decide) {
          setTimeout(() => {
            this.utils.$notifyError(
              "错误",
              this.projectObj.task_name + "暂无推送权限，请联系超管分配"
            );
          }, 10);
          this.checked = false;
          return;
        }
      }
      this.sonChecked();
    },
    /**
     * 推送当前Checked状态
     */
    sonChecked() {
      this.$emit("sonChecked", {
        checked: this.checked,
        index: this.index,
        block_id: this.blockId,
        task_id: this.projectObj.id,
        indexI: this.indexI,
        status: this.projectObj.status,
        taskCoordinatePoints: this.taskCoordinatePoints,
        taskCoordinateWaypoints: this.taskCoordinateWaypoints,
        confirm_unit_id: this.projectObj.confirm_unit_id,
        itemData: this.projectObj
      });
    },
    /**
     * 点击显示任务弹框指令的时候
     */
    instructions(item) {
      if (
        this.allianceState == "已超时" ||
        this.userState == "已超时" ||
        this.allianceState == "已拒绝" ||
        this.userState == "已拒绝"
      ) {
        this.utils.$notifyError("任务已超时或者已拒绝，无法推送");
        return false;
      }

      this.$emit("instructions", {
        index: this.index,
        task_id: this.projectObj.id,
        status: this.projectObj.status,
        task: item,
      });
    },
    /**
     * 接单任务
     */
    orderOrpush() {
      if (
        (this.projectObj.status_name === "已创建，待推送" ||
          this.projectObj.status_name === "已推送联盟") &&
        this.role !== 0
      ) {
        this.$confirm(`确认接单${this.projectObj.task_name}?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$apis
              .taskUnitConfirm(
                {
                  task_id: this.projectObj.id,
                },
                this.token
              )
              .then((data) => {
                if (data.data.status === 0) {
                  if (data.data.message !== "联盟接单失败") {
                    this.$emit("taskListTask");
                  } else {
                    this.utils.$notifyError(
                      "错误",
                      this.projectObj.id + "任务接单失败，请联系超管推送"
                    );
                  }
                }
              })
              .catch((err) => {
                console.error(err);
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消接单",
            });
          });
      }
    },
    /**
     * 拒绝任务
     */
    refuse() {
      if (
        (this.projectObj.status_name === "已创建，待推送" ||
          this.projectObj.status_name === "已推送联盟") &&
        this.role !== 0
      ) {
        this.$confirm(`确认拒绝${this.projectObj.task_name}?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$apis
              .taskUnitRefuse(
                {
                  task_id: this.projectObj.id,
                },
                this.token
              )
              .then((data) => {
                if (data.data.status === 0) {
                  if (data.data.message !== "巡护拒单失败") {
                    this.$emit("taskListTask");
                  } else {
                    this.utils.$notifyError(
                      "错误",
                      this.projectObj.id + "任务巡护拒单失败"
                    );
                  }
                }
              })
              .catch((err) => {
                console.error(err);
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
  },
  watch: {
    checkeds(val) {
      this.checked = val;
    },
    checked() {
      if (this.checked) {
        this.alterChecked();
      } else {
        this.sonChecked();
      }
    },
  },
  props: {
    projectObj: {
      type: Object,
    },
    blockId: {
      type: Number,
    },
    index: {
      type: Number,
    },
    indexI: {
      type: Number,
    },
    checkeds: {
      type: Boolean,
    },
    block: {
      type: String,
    },
    nav: {
      type: Number,
      data: 1,
    },
    authority: {
      type: Boolean,
      data: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/iconfont/iconfont.css";
.taskItem {
  width: 100%;
  height: 230px;
  background: rgba(255, 255, 255, 1);
  position: relative;
  box-sizing: border-box;
  border-radius: 12px;
}
.task-item-top {
  height: 64px;
  line-height: 64px;
  padding-left: 37px;
  border-bottom: 1px solid #dde2ee;

  .task-operation {
    position: absolute;
    left: 64%;
    transform: translateX(-70%);
    top: 0;
    height: 64px;
    line-height: 64px;
    width: 150px;
    display: flex;
    text-align: right;
    div {
      font-size: 16px;
      color: #f00;
    }
    div:nth-child(1) {
      margin-right: 17px;
      color: #1182fb;
    }
  }
}

.task-item-redoProject {
  position: absolute;
  top: 0px;
  right: 90px;
  line-height: 64px;
  height: 64px;
  color: #1890ff;
}

.task-item-icon {
  position: absolute;
  top: 0px;
  right: 34px;
}
.task-media-icon {
  position: absolute;
  top: 0px;
  right: 60px;
}
.task-chat-icon {
  position: absolute;
  top: 0px;
  right: 90px;
}
.task-item-cen {
  display: flex;
  .first-line {
    margin: 6px 0;
    div {
      display: inline-block;
      padding: 0px 12px;
      height: 28px;
      line-height: 28px;
      line-height: 28px;
      background: rgba(246, 255, 237, 1);
      border-radius: 2px;
      border: 1px solid rgba(183, 235, 143, 1);
      font-size: 12px;
      color: #52c41a;
    }
  }

  .reject {
    border-color: #ffa39e !important;
    color: #ff5e5e !important;
  }

  .item-body {
    width: 50%;
    flex-direction: column;
    height: 100%;
    text-align: center;
    font-size: 12px;
    box-sizing: border-box;
    margin-right: 15px;
    .red {
      color: #ff0000 !important;
      border-color: #ff0000 !important;
      background: rgba(255, 241, 240, 1);
    }
    .item-div {
      width: 100%;
      margin: 6px 0;
    }

    .vacancy {
      color: #a6aab8;
      background-color: #fff;
      border: 1px solid rgba(166, 170, 184, 1);
    }

    .name {
      display: inline-block;
      background: rgba(230, 247, 255, 1);
      border-radius: 2px;
      border: 1px solid rgba(145, 213, 255, 1);
      color: #00abff;
      margin: 0 auto;
      height: 28px;
      line-height: 28px;
      text-align: center;
      padding: 0 4%;
    }
    .pcs {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      color: #00abff;
    }

    .over-dot {
      position: relative;
      max-width: 180px;
      overflow: hidden;
      div {
        position: relative;
        background-color: #e6f7ff;
        max-width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
      span {
        position: absolute;
        width: 20px;
        text-align: left;
        height: 100%;
        left: 0px;
        background-color: #e6f7ff;
        overflow: hidden;
        border-right: 1px solid #91d5ff;
        z-index: 1;
      }
    }

    .time {
      height: 16px;
      line-height: 16px;
      font-size: 12px;
      width: 125px;
    // margin-left: -17px;
      div {
        display: inline-block;
        position: relative;
        span {
          position: absolute;
          font-size: 16px;
          right: -16px;
          top: 0;
        }
      }
    }
  }

  .two {
    width: 15%;
    height: 2px;
  }

  .icon {
    width: 32px !important;
    height: 32px;
    line-height: 32px;
    background: rgba(17, 130, 251, 1);
    border-radius: 50%;
    text-align: center;
    color: #fff;
    font-size: 18px;
    margin: 0 auto;
  }

  .wire {
    width: 40%;
    height: 2px;
    background: rgba(221, 226, 238, 1);
    position: absolute;
    top: 120px;
    left: 47%;
    transform: translateX(-50%);
  }
}

.pop-up {
  width: 100%;
  text-align: center;
  height: 80rpx;
  margin-top: 10px;

  .pop-up-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px auto 0;
    .progress-bar {
      width: 38%;
      height: 6px;
      border-radius: 3px;
      border: 1px solid #dde2ee;
      margin-right: 11px;
      div {
        background-color: rgba(28, 208, 189, 1);
        height: 100%;
      }
    }
    .progress-num {
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .interval {
    margin-top: 10px;
    div {
      width: 5px;
      height: 5px;
      background: rgba(17, 130, 251, 1);
      margin: 0 auto 3px;
    }
    div:nth-child(1) {
      border-radius: 50%;
    }
    div:nth-child(2) {
      width: 2px;
      height: 19px;
    }
    div:nth-child(3) {
      border-radius: 50%;
    }
  }
}
</style>
