var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { overflow: "hidden", width: "100%", height: "50px" } },
    [
      _c(
        "div",
        { staticClass: "topBar" },
        [
          _c("div", { staticClass: "bg-purple" }, [
            _c("div", { staticClass: "topLeftBar" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "titleBar", on: { click: _vm.returnHome } },
                [
                  _c("span", { staticClass: "top-title" }, [
                    _vm._v("无人机巡护指挥调度管理平台"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "Platformname" }, [
                    _vm._v("【公众门户网站】"),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "navmenu", staticStyle: { width: "43%" } },
              [
                _c(
                  "el-menu",
                  {
                    staticClass: "el-menu-demo",
                    attrs: {
                      "default-active": _vm.$route.path,
                      "text-color": "#fff",
                      "active-text-color": "#fff",
                      mode: "horizontal",
                    },
                    on: { select: _vm.handleSelect },
                  },
                  [
                    _c(
                      "el-menu-item",
                      {
                        class: { fmHoverBgc: _vm.currentUrl[1] === "应急任务" },
                        attrs: { index: "/getawayTask" },
                        on: {
                          click: function ($event) {
                            return _vm.leftNav(0)
                          },
                        },
                      },
                      [_vm._v("应急任务")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        class: { fmHoverBgc: _vm.currentUrl[1] === "目录查询" },
                        attrs: { index: "/result" },
                        on: {
                          click: function ($event) {
                            return _vm.leftNav(1)
                          },
                        },
                      },
                      [_vm._v("目录查询")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        class: { fmHoverBgc: _vm.currentUrl[1] === "数据申请" },
                        attrs: { index: "/application" },
                        on: {
                          click: function ($event) {
                            return _vm.leftNav(2)
                          },
                        },
                      },
                      [_vm._v("数据申请")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        class: { fmHoverBgc: _vm.currentUrl[1] === "空域查询" },
                        attrs: { index: "/airsearch" },
                        on: {
                          click: function ($event) {
                            return _vm.leftNav(3)
                          },
                        },
                      },
                      [_vm._v("空域查询")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      {
                        class: { fmHoverBgc: _vm.currentUrl[1] === "气象服务" },
                        attrs: { index: "/publicPortalWeather" },
                        on: {
                          click: function ($event) {
                            return _vm.leftNav(4)
                          },
                        },
                      },
                      [_vm._v("气象服务")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "userInform" }, [
              _c(
                "div",
                { on: { click: _vm.newBtn } },
                [
                  _c("i", { staticClass: "iconfont lingdang" }, [_vm._v("")]),
                  _vm._v(" "),
                  _c("el-badge", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.unreadNoticeNum != 0,
                        expression: "unreadNoticeNum != 0",
                      },
                    ],
                    staticClass: "mark",
                    attrs: { value: _vm.unreadNoticeNum },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "userBar", on: { click: _vm.userBtn } },
                [
                  !_vm.token
                    ? _c("span", { staticClass: "userName" }, [
                        _vm._v("请登录"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.token
                    ? _c("avatar", {
                        staticClass: "avatar",
                        attrs: { avatar: _vm.user.photo_url },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.token
                    ? _c(
                        "span",
                        {
                          staticClass: "userName",
                          attrs: { title: _vm.user.name },
                        },
                        [_vm._v(_vm._s(_vm.user.name))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user.is_admin == 1
                    ? _c("i", { staticClass: "iconfont icon-VIP" }, [
                        _vm._v(""),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user.is_admin == 0 && _vm.user.is_unit == 1
                    ? _c("i", { staticClass: "iconfont icon-pre" }, [
                        _vm._v(""),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("News", { ref: "newcenter" }),
          _vm._v(" "),
          _c("personalInformation", { ref: "Infor" }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgBar" }, [
      _c("img", {
        staticStyle: { width: "100%" },
        attrs: { src: require("@/assets/img/new-logo.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }