var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "uavBody" },
    [
      _c("navTop"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bodyNav" },
        [
          _c("div", { staticClass: "rulForm" }, [
            _c(
              "div",
              { staticClass: "formList" },
              [
                _c(
                  "el-form",
                  {
                    ref: "searchForm",
                    staticClass: "formbox",
                    attrs: { model: _vm.searchForm, "label-width": "80px" },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.type !== "terminal",
                            expression: "type!=='terminal'",
                          },
                        ],
                        staticClass: "userForm",
                        attrs: { prop: "fade_code", label: "设备ID" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.searchForm.fade_code,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "fade_code", $$v)
                            },
                            expression: "searchForm.fade_code",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.type == "terminal"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "userForm",
                            attrs: { prop: "uuid", label: "UUID" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.searchForm.uuid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "uuid", $$v)
                                },
                                expression: "searchForm.uuid",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type != "terminal" &&
                    _vm.type != "monitorModule" &&
                    _vm.type !== "countryApp"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "userForm",
                            attrs: { prop: "brand", label: "设备品牌" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择", clearable: "" },
                                on: { change: _vm.brandChange },
                                model: {
                                  value: _vm.searchForm.brand_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "brand_id", $$v)
                                  },
                                  expression: "searchForm.brand_id",
                                },
                              },
                              _vm._l(_vm.brandList, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type != "terminal" && _vm.type != "monitorModule"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "userForm",
                            attrs: { prop: "model_id", label: "设备型号" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择", clearable: "" },
                                model: {
                                  value: _vm.searchForm.model_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "model_id", $$v)
                                  },
                                  expression: "searchForm.model_id",
                                },
                              },
                              _vm._l(_vm.modelList, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "userForm",
                        attrs: { prop: "unit_id", label: "巡护单位" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择", clearable: "" },
                            model: {
                              value: _vm.searchForm.unit_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "unit_id", $$v)
                              },
                              expression: "searchForm.unit_id",
                            },
                          },
                          _vm._l(_vm.unitList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.unit_name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "userForm",
                        attrs: { prop: "status", label: "设备状态" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择", clearable: "" },
                            model: {
                              value: _vm.searchForm.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "status", $$v)
                              },
                              expression: "searchForm.status",
                            },
                          },
                          _vm._l(_vm.statusList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.key, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "userForm",
                        attrs: { prop: "holder_name", label: "持有人" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.searchForm.holder_name,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "holder_name", $$v)
                            },
                            expression: "searchForm.holder_name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "userForm",
                        attrs: { prop: "holder_phone", label: "手机号" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.searchForm.holder_phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "holder_phone", $$v)
                            },
                            expression: "searchForm.holder_phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.type == "uav"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "userForm",
                            attrs: { prop: "name", label: "创建人账户" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.searchForm.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "name", $$v)
                                },
                                expression: "searchForm.name",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type == "monitorModule"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "userForm",
                            attrs: { label: "关联设备类型" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择", clearable: "" },
                                model: {
                                  value:
                                    _vm.searchForm.associated_device_type_id,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "associated_device_type_id",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "searchForm.associated_device_type_id",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { value: 0, label: "请选择" },
                                }),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.associatedDeviceTypeList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.value,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type == "monitorModule"
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "userForm",
                            attrs: { prop: "name", label: "关联设备ID" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value:
                                  _vm.searchForm
                                    .associated_device_software_code,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.searchForm,
                                    "associated_device_software_code",
                                    $$v
                                  )
                                },
                                expression:
                                  "searchForm.associated_device_software_code",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "footBtn" }, [
              _c(
                "div",
                { staticClass: "fmBtn" },
                [
                  _vm.type == "uav" || _vm.type == "monitorModule"
                    ? _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.addBtn },
                        },
                        [_vm._v("添    加")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.clickSearchBtn },
                    },
                    [_vm._v("查    询")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.resetForm },
                    },
                    [_vm._v("重    置")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("deviceList", {
            staticClass: "operation",
            attrs: {
              editAble: true,
              delAble: true,
              exportAble: _vm.role !== 2,
              data: _vm.data,
              tableConfig: _vm.tableConfig,
              page: _vm.searchForm.page,
              perPage: _vm.searchForm.per_page,
              total: _vm.dataTotal,
              deviveType: _vm.type,
            },
            on: {
              searchData: _vm.searchData,
              pageChange: _vm.pageChange,
              edit: _vm.edit,
              del: _vm.del,
              exportFile: _vm.exportFile,
              "update:page": function ($event) {
                return _vm.$set(_vm.searchForm, "page", $event)
              },
              "update:perPage": function ($event) {
                return _vm.$set(_vm.searchForm, "per_page", $event)
              },
              "update:per-page": function ($event) {
                return _vm.$set(_vm.searchForm, "per_page", $event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("editDialog", {
        ref: "editDialog",
        attrs: { categoryId: _vm.categoryId, type: _vm.type },
        on: {
          reload: function ($event) {
            _vm.resetForm()
            _vm.searchData()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }