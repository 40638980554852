<template>
  <div class="task-push">
    <navTop class="navTop" />
    <el-amap
      ref="map"
      :plugin="plugin"
      :events="events"
      :center="center"
      :zoom="zoom"
      class="amap-demo"
      :amap-manager="amapManager"
    >
      <el-amap-polygon
        v-for="(polygon, index) in polygons"
        :key="index"
        :stroke-opacity="0"
        :fill-opacity="0.3"
        fill-color="#367cf3"
        :vid="index"
        :path="polygon"
      />
      <el-amap-polyline
        stroke-color="#ffff00"
        :editable="false"
        :stroke-weight="2"
        :path="polyline"
      />
      <el-amap-polyline
        stroke-color="#ffff00"
        :editable="false"
        :stroke-weight="1"
        stroke-style="dashed"
        :path="polylines"
      />
      <div class="left-alert">
        <div class="left-alert-ov iconfont">
          <div class="project-name">{{ projectName }}</div>
          <div v-for="item in projectData.arr" :key="item.id">
            <div style="margin: 19px 0 18px 49px">{{ item.block_name }}</div>
            <div
              v-for="item2 in item.task"
              :key="item2.id"
              class="list-div"
              @click="getDetails(item2)"
            >
              {{ item2.task_name }}
            </div>
          </div>
        </div>
      </div>
    </el-amap>

    <div
      v-if="Object.keys(parameter).length > 0 && parameterIndex > 0"
      class="task-particulars"
    >
      <div class="particulars-top">
        <div :class="{ bor: parameterIndex === 1 }" @click="meaagseCut(1)">
          任务信息
        </div>
        <div
          :class="{ bor: parameterIndex === 2 }"
          class="cen"
          @click="meaagseCut(2)"
        >
          无人机信息
        </div>
        <div :class="{ bor: parameterIndex === 3 }" @click="meaagseCut(3)">
          终端信息
        </div>
      </div>
      <div class="particulars-body">
        <div v-if="parameterIndex === 1 && nav === 1" class="body-top">
          <div class="div name">
            <div class="task-name" />
            <div class="model">
              <div>
                设备型号:
                <span>{{ parameter.content.plane_type }}</span>
              </div>
              <div style="margin-left: 52px">
                载荷型号:
                <span>{{ parameter.content.camera_type }}</span>
              </div>
            </div>
          </div>
          <div class="div parameter">
            <div style="color: #fff; font-size: 14px">参数</div>
            <div style="color: #fff; font-size: 14px">最高点</div>
            <div style="color: #fff; font-size: 14px">最低点</div>
            <div>
              GSD
              <span>{{ parameter.content.average_ground_resolution }} cm</span>
            </div>
            <div>
              GSD
              <span>{{ parameter.content.high_gsd }}cm</span>
            </div>
            <div>
              GSD
              <span>{{ parameter.content.low_gsd }}cm</span>
            </div>
            <div>
              航向重叠
              <span>{{ parameter.content.longitudinal_overlap }}%</span>
            </div>
            <div>
              航向重叠
              <span>{{ parameter.content.high_longitudinal_overlap }}%</span>
            </div>
            <div>
              航向重叠
              <span>{{ parameter.content.low_longitudinal_overlap }}%</span>
            </div>
            <div>
              旁向重叠
              <span>{{ parameter.content.lateral_overlap }}%</span>
            </div>
            <div>
              旁向重叠
              <span>{{ parameter.content.high_lateral_overlap }}%</span>
            </div>
            <div>
              旁向重叠
              <span>{{ parameter.content.low_lateral_overlap }}%</span>
            </div>
            <div>
              海拔高度
              <span>{{ parameter.content.survey_average_altitude }}m</span>
            </div>
            <div>
              海拔高度
              <span>{{ parameter.content.survey_highest_altitude }}m</span>
            </div>
            <div>
              海拔高度
              <span>{{ parameter.content.survey_lowest_altitude }}m</span>
            </div>
            <div>
              飞行高度
              <span>{{ parameter.content.minimum_route_height }}m</span>
            </div>
            <div />
            <div />
            <div>
              比例尺
              <span>{{ parameter.content.work_scale }}</span>
            </div>
          </div>
          <div class="div message">
            <div style="font-size: 14px">信息</div>
            <div class="message-div">
              <div>
                航线间距:
                <span>{{ parameter.content.flight_interval }}m</span>
              </div>
              <div>
                预计时间:
                <span>{{ parameter.content.work_time }}min</span>
              </div>
            </div>
            <div class="message-div">
              <div>
                拍照间距:
                <span>{{ parameter.content.take_pic_interval }}m</span>
              </div>
              <div v-if="parameter.content.work_area">
                预计面积:
                <span>{{ parameter.content.work_area.slice(0, 3) }}km²</span>
              </div>
            </div>
            <div class="message-div">
              <div>
                默认空速:
                <span>{{ parameter.content.default_air_speed }}m/s</span>
              </div>
              <div>
                预计里程:
                <span v-if="parameter.content.total_mileage"
                  >{{ parameter.content.total_mileage.slice(0, 5) }}km</span
                >
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="parameterIndex === 1 && nav === 2" class="div uav">
          <div class="uav-div">
            <div>传感器</div>
            <div>
              <span>{{ parameter.project_content.project.sensor_type }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>授权空域</div>
            <div>
              <span>{{ parameter.project_content.project.auth_area }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>任务性质</div>
            <div>
              <span>{{
                parameter.project_content.project.charge_property
              }}</span>
            </div>
          </div>
          <div
            v-if="parameter.project_content.project.point_cloud_density === ''"
            class="uav-div"
          >
            <div>影像分辨率</div>
            <div>
              <span
                >{{
                  parameter.project_content.project.average_ground_resolution
                }}cm</span
              >
            </div>
          </div>
          <div
            v-if="parameter.project_content.project.point_cloud_density === ''"
            class="uav-div"
          >
            <div>航向重叠度</div>
            <div>
              <span
                >{{
                  parameter.project_content.project.longitudinal_overlap
                }}%</span
              >
            </div>
          </div>
          <div
            v-if="parameter.project_content.project.point_cloud_density === ''"
            class="uav-div"
          >
            <div>旁向重叠度</div>
            <div>
              <span
                >{{ parameter.project_content.project.lateral_overlap }}%</span
              >
            </div>
          </div>
          <div
            v-if="parameter.project_content.project.point_cloud_density !== ''"
            class="uav-div"
          >
            <div>激光点密度</div>
            <div>
              <span
                >{{
                  parameter.project_content.project.point_cloud_density
                }}p/m²</span
              >
            </div>
          </div>
          <div
            v-if="parameter.project_content.project.point_cloud_density !== ''"
            class="uav-div"
          >
            <div>航带重叠度</div>
            <div>
              <span
                >{{ parameter.project_content.project.lateral_overlap }}%</span
              >
            </div>
          </div>
        </div>

        <div
          v-else-if="parameterIndex === 2 && parameter.uav != null"
          class="uav div"
        >
          <div class="uav-div">
            <div>设备ID</div>
            <div>
              <span>{{ parameter.uav.software_code }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>设备型号</div>
            <div>
              <span>{{ parameter.uav.name }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>巡护单位</div>
            <div>
              <span>{{ parameter.uav.unit_name }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>设备状态</div>
            <div>
              <span>{{ statusArr[parameter.uav.status] }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>归属部门</div>
            <div>
              <span>{{ parameter.uav.holder_dept }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>持有人</div>
            <div>
              <span>{{ parameter.uav.holder_name }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>持有人手机号</div>
            <div>
              <span>{{ parameter.uav.holder_phone }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>创建人手机号</div>
            <div>
              <span>{{ parameter.uav.creator_phone }}</span>
            </div>
          </div>
        </div>
        <div
          v-else-if="parameterIndex === 3 && parameter.terminal != null"
          class="div uav"
        >
          <div class="uav-div">
            <div>设备ID</div>
            <div>
              <span>{{ parameter.terminal.id }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>设备型号</div>
            <div>
              <span>{{ parameter.terminal.name }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>巡护单位</div>
            <div>
              <span>{{ parameter.terminal.unit_name }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>设备状态</div>
            <div>
              <span>{{ statusArr[parameter.terminal.status] }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>归属部门</div>
            <div>
              <span>{{ parameter.terminal.holder_dept }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>持有人</div>
            <div>
              <span>{{ parameter.terminal.holder_name }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>持有人手机号</div>
            <div>
              <span>{{ parameter.terminal.holder_phone }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>创建人手机号</div>
            <div>
              <span>{{ parameter.terminal.creator_phone }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="parameter.iframe_url !== ''"
        class="iconfont play"
        @click="play(parameter.iframe_url)"
      >
        &#xe66c;
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { AMapManager } from "vue-amap";
const amapManager = new AMapManager();
export default {
  data() {
    const self = this;
    return {
      projectId: "", // 任务id
      projectName: "", //  任务标题
      polygons: [], //  多边形
      polyline: [], //  折线
      polylines: [], //  折线
      amapManager: amapManager,
      zoom: 16,
      center: [121.59996, 31.197646],
      googleLayer: {},
      events: {
        init(e, mapStyle) {
          self.googleLayer = new AMap.TileLayer({
            getTileUrl:
              "http://wprd0{1,2,3,4}.is.autonavi.com/appmaptile?&x=[x]&y=[y]&z=[z]&lang=zh_cn&size=1&scl=1&style=6",
            zIndex: 2,
          });
          self.googleLayer.setMap(e);
          e.setMapStyle("amap://styles/dark");
        },
        click() {
          self.parameterIndex = self.parameterIndex === 0 ? 1 : 0;
        },
      },
      /**
       * 高德插件
       */
      plugin: [
        {
          pName: "Geolocation",
          events: {
            init(o) {
              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  self.lng = result.position.lng;
                  self.lat = result.position.lat;
                  self.center = [self.lng, self.lat];
                }
              });
            },
          },
        },
      ],
      projectData: {}, // 路由传过来的项目数据
      nav: 1, //  飞马还是通用任务
      parameter: {}, //  飞马子任务的详细信息
      parameterIndex: 1, //  飞马子任务详情选选项卡
      statusArr: ["正常", "故障", "设备维修", "设备遗失", "设备报废"], //  设备状态数组
    };
  },
  computed: {
    ...mapState(["user", "token", "role", "currentUrl", "chatDialog"]),
  },
  watch: {},
  created(data) {
    this.nav = parseInt(this.$route.query.nav);
    this.projectId = this.$route.query.id;
    this.projectName = this.$route.query.name;
    this.taskListTask();
  },
  mounted() {},
  methods: {
    ...mapMutations(["currentUrlStorage", "chatDialogStorage"]),
    /**
     * 展示任务详情
     */
    getDetails(data) {
      if (this.nav === 1) {
        const centerLon = this.utils.$funcResolution(
          data.task_coordinate_points,
          true,
          1,
          data.software_version
        );
        this.polygons[0] = centerLon;
        const waypointsArr = data.task_coordinate_waypoints.split("FF")[0];
        this.polyline = this.utils.$funcResolution(
          waypointsArr,
          false,
          1,
          data.software_version
        );
        this.polylines = [
          this.polyline[0],
          this.polyline[this.polyline.length - 1],
        ];
        centerLon[0].O === undefined
          ? (this.center = centerLon[0])
          : (this.center = [centerLon[0].O, centerLon[0].P]);
      } else if (this.nav === 2) {
        const centerLon = this.utils.$funcResolution(
          data.task_coordinate_points,
          true,
          2
        );
        this.polygons[0] = centerLon;
        centerLon[0].O === undefined
          ? (this.center = centerLon[0])
          : (this.center = [centerLon[0].O, centerLon[0].P]);
      }
      this.taskGetTaskDetail(data.id);
      let polygonArea = AMap.GeometryUtil.ringArea(this.polygons[0]);
      switch (true) {
        case polygonArea <= 100000:
          this.zoom = 16;
          break;
        case polygonArea <= 400000:
          this.zoom = 15;
          break;
        case polygonArea <= 800000:
          this.zoom = 14;
          break;
        case polygonArea <= 1200000:
          this.zoom = 13;
          break;
        case polygonArea <= 1800000:
          this.zoom = 12;
          break;
        case polygonArea <= 2400000:
          this.zoom = 11;
          break;
        case polygonArea <= 3000000:
          this.zoom = 10;
          break;
        case polygonArea <= 3600000:
          this.zoom = 9;
          break;
        case polygonArea <= 4200000:
          this.zoom = 8;
          break;
        default:
          this.zoom = 7;
          break;
      }
    },

    /**
     * 任务详情
     */
    taskGetTaskDetail(task_id) {
      this.$apis
        .taskGetTaskDetail(
          {
            task_id: task_id,
          },
          this.token
        )
        .then((data) => {
          this.parameterIndex = 1;
          this.parameter = data.data.data;
          if (this.nav === 2) {
            this.parameter.project_content = JSON.parse(
              this.parameter.project_content
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    /**
     * 播放
     */
    play(url) {
      window.open(url);
    },
    /**
     * 信息列表切换
     * @param {NUmber} index
     */
    meaagseCut(index) {
      this.parameterIndex = index;
    },
    /**
     * 获取任务下的子任务
     */
    taskListTask() {
      let way = "listFeiMaTask";
      way = this.nav === 1 ? "listFeiMaTask" : "listCommonTask";
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$apis
        .portalListTask(
          {
            project_id: this.$route.query.id,
            sort: "asc",
          },
          this.token
        )
        .then((data) => {
          const arrObj = this.projectData;
          this.projectData = [];
          loading.close();

          setTimeout(() => {
            arrObj.arr = data.data.data;
            this.projectData = arrObj;
            this.taskSort();
          }, 100);
        })
        .catch((err) => {
          console.error(err);
          loading.close();
        });
    },
    /**
     * 子任务排序
     */
    taskSort() {
      if (this.projectData.arr) {
        for (let i = 0; i < this.projectData.arr.length; i++) {
          this.projectData.arr[i].task.sort((a, b) => {
            return a.id - b.id;
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__body {
  // display: none;
  padding: 0;
}
.task-push {
  background-color: #f0f2f5;
  height: calc(100% - 35px);
}

.left-alert-ov::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}

.left-alert {
  width: 18%;
  height: calc(100% - 14px);
  top: 14px;
  position: absolute;
  left: 29px;
  bottom: 25px;
  background-color: #ebecef;
  box-sizing: border-box;
  overflow: hidden;
  padding-bottom: 40px;
  z-index: 999;

  .left-alert-ov {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .project-name {
      height: 72px;
      line-height: 72px;
      width: 100%;
      box-sizing: border-box;
      margin-left: 49px;
      border-bottom: 1px solid rgba(221, 226, 238, 1);
    }

    .list-div {
      width: calc(100% - 16px);
      height: 44px;
      line-height: 44px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 4px 6px 0px rgba(2, 118, 187, 0.18);
      box-sizing: border-box;
      padding-left: 61px;
      margin: 0 auto 6px;
      color: #838a9d;
      font-size: 13px;
    }

    .opt {
      border: 1px solid rgba(23, 130, 251, 1);
    }
  }
}
.amap-demo {
  position: absolute;
  left: 30px;
  right: 30px;
  top: 3vw;
  height: calc(100% - 95px);
}

.task-particulars {
  width: 412px;
  height: 68.7vh;
  background: rgba(0, 6, 32, 1);
  position: fixed;
  top: 15vh;
  right: 40px;
  color: #fff;
  font-size: 14px;

  .play {
    font-size: 30px;
    margin-left: 15px;
    margin-top: 6px;
  }

  .particulars-top {
    display: flex;
    height: 56px;
    line-height: 56px;
    background: rgba(0, 6, 32, 1);
    div {
      width: 24.6%;
      text-align: center;
    }
    .cen {
      margin: 0 auto;
    }
    .bor {
      border-bottom: 2px solid #1782fb;
    }
  }

  .particulars-body {
    height: 57vh;
    background-color: #242f5d;

    .div {
      width: 100%;
      background: rgba(0, 6, 32, 0.63);
      padding-left: 41px;
      box-sizing: border-box;
    }
    .body-top {
      height: 100%;
      .name {
        height: 18.34%;

        .task-name {
          padding-top: 33px;
        }

        .model {
          margin-top: 16px;
          display: flex;
          color: #a6acb9;
          span {
            color: #fff;
          }
        }
      }

      .parameter {
        height: 45.61%;
        margin-top: 10px;
        padding-top: 26px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        div {
          width: 33%;
          color: #a6acb9;
          font-size: 13px;
          span {
            color: #ffffff;
          }
        }
      }

      .message {
        height: 28.57%;
        margin-top: 10px;
        padding-top: 10px;
        box-sizing: border-box;
        font-size: 13px;

        .message-div {
          display: flex;
          margin-top: 21px;
          div {
            width: 45%;
            color: #a6acb9;
            span {
              color: #fff;
            }
          }
        }
      }
    }

    .uav {
      height: 94.3%;
      background: rgba(0, 6, 32, 0.63);
      padding-top: 27px;
      font-size: 13px;
      .uav-div {
        display: flex;
        margin-top: 16px;
        div:nth-child(1) {
          color: #a6acb9;
          width: 96px;
        }
        div:nth-child(2) {
          color: #fff;
        }
      }
    }
  }
}
</style>
