<template>
  <div class="comBjDio" v-if="requestDataManager !== ''">
    <div class="header" ref="bj_header"></div>
    <div class="body" ref="bj_body"></div>
    <div class="footer" ref="bj_footer"></div>
    <div class="content">
      <div class="taskBtn" v-show="isTasks">
        <div
          class="onlineTaskBtn btn"
          :class="{ selectTaskBtn: isTaskBtn === 1 }"
          @click="clickTaskBtn(1)"
        >
          在线任务
        </div>
        <div
          class="onlineTaskBtn btn"
          :class="{ selectTaskBtn: isTaskBtn === 2 }"
          @click="clickTaskBtn(2)"
        >
          历史任务
        </div>
      </div>
      <div class="content-header">
        <img
          src="@/assets/img/centerHome/incoming.png"
          v-show="!isTasks"
          alt=""
        />
        <img
          src="@/assets/img/centerHome/onlinetask.png"
          v-show="isTasks && isTaskBtn === 1"
          alt=""
        />
        <img
          src="@/assets/img/centerHome/offtask.png"
          v-show="isTasks && isTaskBtn === 2"
          alt=""
        />

        <div class="device-total">{{ corsTotal + total }}</div>
        <div
          v-show="isTasks && isTaskBtn === 1"
          class="examine"
          @click="toMore"
        >
          多屏查看
        </div>
      </div>
      <div v-show="!isTasks" class="content-btns">
        <div
          class="content-btn"
          :class="{ selectContentBtn: isSelectLocation === 1 }"
          @click="selectLocation(1)"
        >
          当前位置
        </div>
        <div
          class="content-btn"
          :class="{ selectContentBtn: isSelectLocation === 2 }"
          @click="selectLocation(2)"
        >
          上次位置
        </div>
        <div
          class="content-btn"
          :class="{ selectContentBtn: isSelectLocation === 3 }"
          @click="selectLocation(3)"
        >
          登记位置
        </div>
      </div>
      <div class="content-data">
        <div class="content-data-item" v-if="sortiesData['其他']">
          <show-more-check-box
            :sortiesData="sortiesData['其他']['无人机']"
            :isHistoricalTask="isHistoricalTask"
            deviceName="其他无人机"
            @checkedDevice="checkedDevice"
            @getDateData="getDateData"
            :isDate="isDate"
            :datedDeviceName="datedDeviceName"
            :initSelect="initSelect"
          />
        </div>
        <div class="content-data-item" v-if="sortiesData['其他']">
          <show-more-check-box
            :sortiesData="sortiesData['其他']['终端设备']"
            :isHistoricalTask="isHistoricalTask"
            deviceName="终端设备"
            @checkedDevice="checkedDevice"
            @getDateData="getDateData"
            :isDate="isDate"
            :datedDeviceName="datedDeviceName"
            :initSelect="initSelect"
          />
        </div>
        <div style="margin: 11px 0"></div>
        <div class="trajectoryBar" v-show="isTaskBtn === 1 && isTasks">
          <span class="trajectory">聚合开关</span>
          <el-switch
            class="switchTurn"
            style="margin-right:20px;"
            @change="showMaxZoom"
            v-model="isMaxZoom"
          ></el-switch>
          <span class="trajectory">移动轨迹</span>
          <el-switch
            class="switchTurn"
            @change="showAllTrack"
            v-model="isShowAllTrack"
          ></el-switch>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import selectDate from "./selectDate";
import RequestData from "@/components/backEndHome/requestDevicesData.js";
import ShowMoreCheckBox from "./showMoreCheckBox";
import { iconData } from "@/components/backEndHome/cors.js";
const cityOptions = { 广西: 0, 其他: 7 };
export default {
  props: {
    isTasks: {
      type: Boolean
    },
    requestDataManager: {
      type: Object
    },
    onlineSortiesData: {
      type: Object,
      default: {}
    }, //架次在线数据
    deviceData: {
      type: Array,
      default: []
    }, //设备数据
    isShowtTasksAndSorties: {
      type: Boolean,
      default: false
    }
  },
  components: {
    selectDate,
    ShowMoreCheckBox
  },
  data() {
    return {
      checkAll: true,
      checkAllRainbowDrone: true,
      allCheckedRainbow: ["广西", "其他"],
      checkedRainbow: ["广西", "其他"],
      checkedOtherRainbow: [], //多选框其他无人机列表
      allCheckedOtherRainbow: [],
      cities: cityOptions,
      otherDrone: {}, //其他无人机
      isIndeterminate: true,
      isMoreOtherDrone: false, //测试显示其他无人机
      isMoreMapping: false,
      isMoreOtherRainbow: false,
      isShowThisTrack: false, //是否显示轨迹
      isSelectLocation: 1, //架次按钮 ,1当前位置，2上次位置，3登记位置
      isTaskBtn: 1, //任务按钮 ，1在线，2历史,3设备
      isHistoricalTask: false,
      value1: "",
      checkAllTerminalTotal: false, //选中所有的终端设备
      // requestDataManager: "",
      sortiesData: {}, //架次数据
      historySortiesData: {},
      sortiesAnalysisData: {},

      otherCount: 0,
      rainbowTotal: 0,
      deviceCategoryBrand: {},
      isDate: true,
      loading: true,
      datedDeviceName: "", //选择时间时，判断是否要选中复选框
      isShowAllTrack: false,
      isMaxZoom: true,
      initSelect: 1,
      timerSorties: null,
      corsTotal: iconData.length, //cors基站数量
      checkCorsDrone: true
    };
  },
  created() {},
  mounted() {},
  watch: {
    isTasks(newVal, oldVal) {
      if (!newVal) {
        this.isHistoricalTask = false;
      } else {
        this.isHistoricalTask = this.isTaskBtn === 2;
      }
      this.recoverData();
    },
    onlineSortiesData(newVal, oldVal) {
      if (this.isShowtTasksAndSorties && this.initSelect === 1) {
        this.sortiesData = JSON.parse(JSON.stringify(this.onlineSortiesData));
      }
    }
  },
  filters: {
    filtTotal(val) {
      let total = 0;
      if (val) {
        for (let key in val) {
          total = total + val[key];
        }
      }
      return total;
    }
  },
  methods: {
    // 获取解析架次历史任务数据
    getSortiesHistoryData(date = "") {
      return new Promise((resolve, reject) => {
        this.requestDataManager.getDeviceCategoryBrand().then(data => {
          this.deviceCategoryBrand = data;
          this.requestDataManager.getSortiesCount(date).then(data => {
            if (date === "") {
              this.historySortiesData = data;
              for (let key in this.deviceCategoryBrand) {
                if (this.historySortiesData["其他"][key]) {
                  this.deviceCategoryBrand[key].forEach(item => {
                    if (!this.historySortiesData["其他"][key]["brand"][item]) {
                      this.historySortiesData["其他"][key]["brand"][item] = 0;
                    }
                  });
                } else {
                  this.historySortiesData["其他"][key] = {};
                  this.historySortiesData["其他"][key]["brand"] = {};
                  this.deviceCategoryBrand[key].forEach(item => {
                    this.historySortiesData["其他"][key]["brand"][item] = 0;
                    this.historySortiesData["其他"][key]["count"] = 0;
                  });
                }
              }
            } else {
              if (date.category === "彩虹无人机") {
                this.historySortiesData["彩虹无人机"] = data["彩虹无人机"];
              } else {
                let defaultVal = { brand: {}, count: 0 };
                if (data["其他"][date.category]) {
                  this.historySortiesData["其他"][date.category] =
                    data["其他"][date.category];
                } else {
                  this.historySortiesData["其他"][date.category] = defaultVal;
                }
              }
              this.selectOnlineTask();
            }
          });
        });

        resolve(true);
      });
    },
    // 切换在线任务或历史任务
    selectOnlineTask() {
      if (this.isTasks) {
        if (this.isTaskBtn === 2) {
          this.initSelect = 2;
          this.sortiesData = JSON.parse(
            JSON.stringify(this.historySortiesData)
          );

          this.$EventBus.$emit("switchShowOtherIcon", {
            name: "historySortieLayer",
            index: -1
          });
        } else {
          this.initSelect = 1;
          this.sortiesData = JSON.parse(JSON.stringify(this.onlineSortiesData));
          this.$EventBus.$emit("switchShowOtherIcon", {
            name: "online",
            index: -1
          });
        }
      } else {
        this.initSelect = this.initSelect < 3 ? 3 : this.initSelect;

        this.isHistoricalTask = false;
        try {
          if (this.selectContentBtn === 1) {
            this.sortiesData = JSON.parse(JSON.stringify(this.deviceData[0]));
          } else if (this.selectContentBtn === 2) {
            this.sortiesData = JSON.parse(JSON.stringify(this.deviceData[1]));
          } else {
            this.sortiesData = JSON.parse(JSON.stringify(this.deviceData[2]));
          }
        } catch {
          setTimeout(() => {
            this.selectOnlineTask();
          }, 1500);
          return;
        }

        this.filtrateData();
        this.isHistoricalTask = false;
      }

      // 获取多选框固定的彩虹无人机中其他无人机
      this.checkedOtherRainbow = [];
      this.allCheckedOtherRainbow = [];
      for (let key in this.sortiesData["彩虹无人机"]) {
        this.allCheckedOtherRainbow.push(key);
        this.checkedOtherRainbow.push(key);
      }
    },
    // 筛选数据
    filtrateData() {
      if (this.isSelectLocation === 1) {
        this.sortiesData = JSON.parse(JSON.stringify(this.deviceData[0]));
      } else if (this.isSelectLocation === 2) {
        this.sortiesData = JSON.parse(JSON.stringify(this.deviceData[1]));
      } else {
        this.sortiesData = JSON.parse(JSON.stringify(this.deviceData[2]));
      }
      this.$EventBus.$emit("switchShowOtherIcon", {
        name: "allDeviceLayer",
        index: this.isSelectLocation - 1
      });
    },
    // 彩虹无人机选中全部
    handleCheckAllChange() {
      this.checkedRainbow = this.checkAllRainbowDrone
        ? this.allCheckedRainbow
        : [];
      if (this.checkAllRainbowDrone) {
        this.checkedOtherRainbow = JSON.parse(
          JSON.stringify(this.allCheckedOtherRainbow)
        );
      } else {
        this.checkedOtherRainbow = [];
      }
      this.checkedDevice({
        parent: "彩虹无人机",
        show: this.checkAllRainbowDrone
      });
    },
    // 选中彩虹无人机中其他无人机下拉框选项
    handleCheckedOtherRainbow(value) {
      if (value.length === this.allCheckedOtherRainbow.length) {
        this.checkedRainbow.push("其他");
      } else {
        this.checkedRainbow.splice(this.checkedRainbow.indexOf("其他"), 1);
      }
      // this.checkedDevice({ parent: "彩虹无人机", show: this.checkAllRainbowDrone })
    },
    // 选中 “广西” “其他”的彩虹无人机无人机
    handleCheckedRainbow(value) {
      this.checkAllRainbowDrone =
        value.length === this.allCheckedRainbow.length;
      if (value.indexOf("其他") !== -1) {
        this.checkedOtherRainbow = JSON.parse(
          JSON.stringify(this.allCheckedOtherRainbow)
        );
      } else {
        this.checkedOtherRainbow = [];
      }
      // this.checkedDevice({ parent: "彩虹无人机", show: this.checkAllRainbowDrone })
    },
    // 单选值一个彩虹无人机
    checkedRainbowOne(data) {
      let show = true;
      if (data === "广西") {
        show = this.checkedRainbow.indexOf(data) !== -1;
      } else {
        if (data === "其他") {
          show = this.checkedRainbow.indexOf(data) !== -1;
        } else {
          show = this.checkedOtherRainbow.indexOf(data) !== -1;
        }
      }
      this.checkedDevice({ parent: "彩虹无人机", show: show, sonLayer: data });
    },
    showMoreOtherDrone() {
      this.isMoreOtherDrone = !this.isMoreOtherDrone;
    },
    // 显示测绘装备
    showMoreMapping() {
      this.isMoreMapping = !this.isMoreMapping;
    },
    // 架次位置按钮点击
    selectLocation(index) {
      // this.isTaskBtn = 3
      this.isSelectLocation = index;
      this.initSelect = 2 + index;
      this.filtrateData();
      this.checkAllRainbowDrone = true;
      // this.handleCheckAllChange()
      this.checkedRainbow = this.checkAllRainbowDrone
        ? this.allCheckedRainbow
        : [];
      if (this.checkAllRainbowDrone) {
        this.checkedOtherRainbow = JSON.parse(
          JSON.stringify(this.allCheckedOtherRainbow)
        );
      } else {
        this.checkedOtherRainbow = [];
      }
    },
    // 复原选项
    recoverData() {
      this.checkAllRainbowDrone = true;
      this.checkCorsDrone = true;
      this.checkedRainbow = ["广西", "其他"];
    },
    // 任务按钮点击
    clickTaskBtn(index) {
      this.recoverData();
      this.isMoreMapping = false;
      this.isMoreOtherDrone = false;
      this.isTaskBtn = index;
      let type = "";
      // this.
      if (index === 2) {
        this.isHistoricalTask = true;
        type = "history";
        this.$EventBus.$emit("switchShowOtherIcon", {
          name: "historySortieLayer",
          index: -1
        });
      } else {
        this.isDate = true;
        this.isHistoricalTask = false;
        type = "online";
        this.$EventBus.$emit("switchShowOtherIcon", {
          name: "online",
          index: -1
        });
      }
      this.selectOnlineTask();
    },
    showMoreOtherRainbow() {
      this.isMoreOtherRainbow = !this.isMoreOtherRainbow;
    },
    // 获取架次数量
    getsSortiesCount() {},
    // 显示轨迹
    showAllTrack() {
      this.$EventBus.$emit("showAllTrack", this.isShowAllTrack);
    },
    //聚合开关
    showMaxZoom() {
      this.$EventBus.$emit("showMaxZoom", this.isMaxZoom);
    },
    // 根据新的日期获取新的数据
    getDateData(data) {
      this.datedDeviceName = data.category;
      this.isDate = false;
      this.getSortiesHistoryData(data);
      this.$EventBus.$emit("selectDateShowHistoryData", data);
      if (data.category === "彩虹无人机") {
        this.checkAllRainbowDrone = true;
        this.checkedRainbow = this.allCheckedRainbow;
        if (this.checkAllRainbowDrone) {
          this.checkedOtherRainbow = JSON.parse(
            JSON.stringify(this.allCheckedOtherRainbow)
          );
        } else {
          this.checkedOtherRainbow = [];
        }
      }
    },
    // 点击多选框
    checkedDevice(data) {
      console.log("data-checkedDevice", data);
      data["type"] = this.isTasks;
      if (data.parent === "其他无人机") {
        data.parent = "无人机";
      }
      if (this.isTasks) {
        if (this.isTaskBtn === 1) {
          data["isOnline"] = true;
        } else {
          data["isOnline"] = false;
        }
      }
      this.$EventBus.$emit("showOrHideMarker", data);
    },
    // 多屏查看
    toMore() {
      this.$emit("toMore");
    },
    // 选中cors站
    handleCheckCors(data) {
      this.$EventBus.$emit("showCorsMarker", data);
    }
  },
  computed: {
    gxCount() {
      let count = 0;
      if (this.sortiesData["彩虹无人机"]) {
        for (let key in this.sortiesData["彩虹无人机"]) {
          if (key.indexOf("广西") !== -1) {
            count = this.sortiesData["彩虹无人机"][key];
          }
        }
        return count;
      }
    },
    total() {
      let count = 0;
      for (let key in this.sortiesData["彩虹无人机"]) {
        count = count + this.sortiesData["彩虹无人机"][key];
      }
      this.rainbowTotal = count;
      this.otherCount = count - this.gxCount;
      for (let key in this.sortiesData["其他"]) {
        count = count + this.sortiesData["其他"][key].count;
      }
      return count;
    }
  }
};
</script>
<style lang="scss" scoped>
.comBjDio {
  position: absolute;
  .trajectoryBar {
    //     position: absolute;
    // bottom: 43px;
    // right: 27px;
    color: #fff;
  }
  %BtnPubilcStyle {
    cursor: pointer;
    user-select: none;
    color: #fff;
  }
  right: 85px;
  .leftWindowData {
    position: relative;
    top: 33px;
    left: 47px;
  }
  .header {
    width: 390px;
    height: 177px;
    background: url("~@/assets/img/centerHome/frametop.png") no-repeat center;
    background-size: 100% 100%;

    // border-bottom: 1px solid #69789E;
  }
  .body {
    width: 390px;
    height: 315px;
    background: url("~@/assets/img/centerHome/framem.png") no-repeat center;
    background-size: 100% 100%;
    padding-left: 45px;
    // max-height: 100px;
  }
  .footer {
    width: 390px;
    height: 162px;
    background: url("~@/assets/img/centerHome/framedown.png") no-repeat center;
    background-size: 100% 100%;
  }
  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 2px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #3f597e;
  }

  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: transparent;
  }
  * {
    scrollbar-color: #000 #6d6d83; /* 滑块颜色  滚动条背景颜色 */
    scrollbar-width: thin; /* 滚动条宽度有三种：thin、auto、none */
  }
  .content {
    position: absolute;
    top: 50px;
    left: 36px;

    max-height: 576px;
    .taskBtn {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      .btn {
        width: 133px;
        height: 44px;
        background: rgba(105, 120, 158, 0.3);
        border: 1px solid #69789e;
        text-align: center;
        line-height: 44px;

        @extend %BtnPubilcStyle;
      }
      .selectTaskBtn {
        background: rgba(7, 155, 255, 0.3);
        border-top: 1px solid #079bff;
        border-bottom: 1px solid #079bff;
        border-left: 4px solid #079bff;
        border-right: 4px solid #079bff;
      }
    }
    .content-header {
      display: flex;
      width: 313px;
      height: 48px;
      background: url("~@/assets/img/centerHome/content-bj.png") no-repeat
        bottom;
      background-size: 100% 50%;
      margin-bottom: 39px;
      img {
        width: 32px;
        height: 32px;
        margin-right: 16px;
      }
      .device-total {
        font-size: 32px;
        font-family: DIN;
        font-weight: bold;
        color: #ffd65c;
      }
      .examine {
        font-size: 15px;
        @extend %BtnPubilcStyle;

        position: absolute;
        right: 43px;
        top: 105px;
        font-weight: bold;
      }
    }
    .content-btns {
      display: flex;
      margin-bottom: 39px;
      .content-btn {
        width: 96px;
        height: 32px;
        line-height: 31px;
        text-align: center;
        background: rgba(105, 120, 158, 0.3);
        border-radius: 16px;
        border: 1px solid #69789e;
        margin-right: 12px;
        @extend %BtnPubilcStyle;
      }
      .selectContentBtn {
        background: rgba(7, 155, 255, 0.3);
        border: 1px solid #079bff;
      }
    }
    .content-data {
      max-height: 370px;
      overflow: auto;
      padding-right: 13px;
      .content-data-item {
        width: 100%;
        position: relative;
        margin-top: 24px;
        .checkBoxGroup {
          display: flex;
          justify-content: space-between;
          margin-left: 20px;
          width: 240px;
        }

        .deviceName {
          font-weight: bold;
          color: #fff;
        }
        .deviceTotal {
          font-weight: bold;
          color: #ffd65c;

          margin-left: 10px;
          margin-right: 4px;
        }
        .selectDate {
          position: absolute;
          right: 0;
          top: 2px;
        }
      }

      .showMore {
        position: relative;
        img {
          position: absolute;
          top: 2px;
          left: 5px;
          transform: rotate(180deg);
          cursor: pointer;
        }
        .selectRotate {
          transform: rotate(0deg);
        }
      }
      .caihongDrone {
        margin-top: 13px;
        width: 290px;
        background: rgba(105, 120, 158, 0.3);
        border-radius: 4px;
        .styleKey {
          display: inline-block;
          width: 55px;
          // margin-right: 10px;
        }
        .styleVal {
          display: inline-block;
          width: 35px;
        }
      }
    }
    .trajectoryBar {
      text-align: right;
      color: #7893b9;
      margin-top: 13px;
    }
  }
}
@media screen and (max-height: 1000px) {
  .comBjDio {
    transform: scale(0.8);
    top: -47px;
  }
}
</style>
