<template>
  <el-dialog
    title="大文件回传"
    :visible.sync="dialogVisible"
    v-dialogDrag
    custom-class="uppy-dialog"
    :before-close="shut"
  >
    <div>提示：上传过程中删除文件将无法保存文件上传记录</div>
    <iframe
      :src="uppyIframe"
      frameborder="0"
      scrolling="no"
      style="border: 0px"
      ref="iframe"
      height="600"
      width="780"
      id="uppyIframe"
      v-if="dialogVisible"
    ></iframe>
  </el-dialog>
</template>

<script>
import { string } from "clipboard";
import { mapState, mapMutations } from "vuex";
import { Loading } from "element-ui";
const that = this;
let uppyIndex = 0;

export default {
  name: "largeFile",
  data() {
    return {
      dialogVisible: true,
      showProgress: false, //  显示进度条
      percentageNum: 0, //  进度条刻度
      spanClick: "handleSpanClick", //html中需要响应的事件
      uppyMessage: "",
    };
  },
  props: {
    uploadType: {
      type: String,
    },
    taskId: {
      type: Number,
      data: "",
    },
    returnType: {
      type: String,
      data: "",
    },
    uppyIframe: {
      type: String,
      data: "",
    },
  },
  computed: {
    ...mapState(["currentUrl", "role", "user", "token"]),
  },
  created() {
    window.addEventListener(
      "message",
      (event) => {
        console.log(event);
        this.uppyMessage = event.data;
        console.log(this.uppyMessage);
        // alert(e.data);
        uppyIndex++;
        if (this.uppyMessage === "loading") {
          this.$emit("fullscreenLoading", true);
        } else if (this.uppyMessage === "refresh") {
          uppyIndex = 0;
          this.$emit("fullscreenLoading", false);
          this.$emit("refresh");
        }
      },
      false
    );
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    shut() {
      this.$confirm("上传记录已保存，确认关闭?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$emit("cancel");
          this.dialogVisible = false;
          uppyIndex = 0;
        })
        .catch(() => {
          uppyIndex = 0;
        });
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.uppy-dialog {
  min-width: 800px;
  width: 800px;
}
</style>
