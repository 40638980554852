<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      class="taskBox"
      :close-on-click-modal="false"
      :modal="false"
      v-dialogDrag
    >
      <el-divider class="fenge"></el-divider>
      <div class="detailBar">
        <div class="leftTit">
          <div class="details">
            <span class="title">空域名称</span>
          </div>
          <div class="details">
            <span class="title">空域中心点</span>
          </div>
          <div class="details">
            <span class="title">作业高度（真高）</span>
          </div>
          <div class="details">
            <span class="title">航飞作业单位名称</span>
          </div>
          <div class="details">
            <span class="title">委托巡护单位名称</span>
          </div>
          <div class="details">
            <span class="title">规划飞行窗口</span>
          </div>
          <div class="details">
            <span class="title">申请函</span>
          </div>
        </div>
        <div class="rightTit">
          <div class="details">
            <span class="value">{{ this.airDetails.air_space_name }}</span>
          </div>
          <div class="details">
            <span class="value">({{ this.airDetails.coordinate_point }})</span>
          </div>
          <div class="details">
            <span class="value">{{ this.airDetails.flight_altitude }}m</span>
          </div>
          <div class="details">
            <span class="value">{{ this.airDetails.flight_company_name }}</span>
          </div>
          <div class="details">
            <span class="value">{{ this.airDetails.authorize_unit_name }}</span>
          </div>
          <div class="details">
            <span class="value">{{ start }}～{{ end }}</span>
          </div>
          <div class="details">
            <el-link
              style="margin-left: 20px;"
              type="primary"
              @click="download"
              plain
              >下载申请函</el-link
            >
          </div>
        </div>
      </div>
      <el-divider class="fenge"></el-divider>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeBtn" type="primary">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState,mapMutations } from "vuex";
export default {
  props: {
    airDetails: {
      type: Object,
      default: {},
    },
    title: {
      type: String,
      default: "",
    },
  },
  data () {
    return {
      dialogVisible: false,
      start: "",
      end: "",
      lng: "",
      lat: "",
    };
  },
  methods: {
    open () {
      this.dialogVisible=true;
      if(this.airDetails!=undefined) {
        this.start=this.airDetails.flight_start_time.slice(0,10);
        this.end=this.airDetails.flight_end_time.slice(0,10);
        this.lng=this.airDetails.coordinate_point.slice(2,18);
        this.lat=this.airDetails.coordinate_point.slice(21,37);
      }
    },
    closeBtn () {
      this.dialogVisible=false;
    },
    download () {
      window.open(this.airDetails.application_letter);
    },
  },
  computed: {
    ...mapState(["user","token"]),
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--primary.is-plain {
  margin-left: 20px;
}
/deep/.el-dialog {
  height: 500px;
  width: 480px;
}
/deep/.el-dialog__title {
  color: #000000;
  font-size: 20px;
  margin-left: 20px;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-dialog__header {
  padding-bottom: 0px;
}
.title {
  float: left;
  font-size: 13px;
  color: #3c4353;
}
.details {
  width: 100%;
  float: left;
  margin-bottom: 25px;
}
.value {
  float: left;
  margin-left: 20px;
  font-size: 13px;
  color: #828a9e;
}
.detailBar {
  padding: 20px 30px 0 30px;
  float: left;
}
.fenge {
  float: left;
}
.leftTit {
  float: left;
  width: 30%;
}
.rightTit {
  float: right;
  width: 70%;
}
</style>