<template>
  <div class="showViews" :style="{width:comWidth+'px'}">
    <div class="showView">
      <div v-for="(val,key,index) in showData" class="name" @click="selectShow(key)">
      <img v-show="!val" src="@/assets/img/homeDialog/noSelect.png" alt="">
      <img v-show="val" src="@/assets/img/homeDialog/select.png" alt="">
      <div>{{key}}</div>
    </div>
    </div>

  </div>
</template>

<script>
export default {

  props:{
    showData: {
      type: Object,
      default: {"仪表盘": true,"航线":true,"轨迹":true}
    },
    comWidth: {
      type: Number,
      default: 345,
    }
  },
  emits: ["selectShow"],
  data(){
    return {

    }
  },
  methods: {
    selectShow(key){
      this.$emit("selectShow",key)
    }
  }
}
</script>

<style lang="scss" scoped>
  .showViews {
    // width: 345px;
    height: 58px;
    background: rgba(3, 13, 28, 0.8);
    border-radius: 32px;

    .showView {
      width: 300px;
      height: 100%;
      display: flex;
      justify-content: space-around;
    }
    .name {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #fff;
      img {
        margin-right: 10px;
      }
    }
  }
</style>
