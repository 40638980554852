<template>
    <div v-show="isShowVideoList" class="video-list">
        <div class="title">
            <p>视频列表</p>
        </div>
        <div class="video-item-list">
            <div v-for="(item, index) in videoList" :key="index" class="video-item" @click="playVideo(item, index)"
                :class="{ activeVideo: nowPlayVideoIndex === index }">
                <!-- <div class="video-icon">
                  <img src="@/assets/img/spellBtn/video-icon.jpg" alt="">
              </div> -->
                <div class="video-info">
                    <div>
                        <p>{{ item.name }}</p>
                    </div>
                    <div class="create-at">
                        <p>{{ item.createAt }}</p>
                    </div>
                </div>
                <div v-if="nowPlayVideoIndex === index">
                    <p style="color: #DBEBEB;">正在播放</p>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        missionId: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            videoList: [], // 视频列表
            isShowVideoList: false, // 是否打开视频列表
            nowPlayVideoIndex: -1, // 现在播放的下标

        }
    },
    mounted() {
        this.getRecordVideoAll()
    },
    methods: {
        async getRecordVideoAll() {
            await this.$apis.getLiveList({ type: 'live_record', mission_id: this.missionId }, this.token).then(res => {
                this.videoList = res.data.data.map((item, index) => {
                    const fileUrlNameList = item.file_url.split('/')
                    const name = fileUrlNameList[fileUrlNameList.length - 1]
                    return { name: name, createAt: item.created_at, url: item.file_url }
                })
                this.isShowVideoList = this.videoList.length !== 0
                if (this.isShowVideoList) {
                    this.nowPlayVideoIndex = 0;
                    const videoData = this.videoList[0];
                    this.playVideo(videoData, this.nowPlayVideoIndex)
                    const video = document.getElementById("historyVideo");
                    if (!video) return
                    // 添加事件监听器，监听视频播放完成事件
                    video.addEventListener("ended", function () {
                        // 在这里可以执行你想要的操作
                        if (this.nowPlayVideoIndex === this.videoList.length - 1) {
                            if (this.nowPlayVideoIndex === 0) {
                                // 将视频的 loop 属性设置为 true，使其自动重复播放
                                video.loop = true;

                                // 播放视频
                                video.play();
                            } else {
                                this.nowPlayVideoIndex = 0;
                                const nowVideoData = this.videoList[this.nowPlayVideoIndex]
                                this.playVideo(nowVideoData, this.nowPlayVideoIndex)
                            }


                        } else {
                            this.nowPlayVideoIndex++;
                            const nowVideoData = this.videoList[this.nowPlayVideoIndex]
                            this.playVideo(nowVideoData, this.nowPlayVideoIndex)
                        }
                    });
                }
            })

        },
        /**
         * 播放视频
         * @param item 
         * @param index 
         */
        playVideo(item, index) {
            this.nowPlayVideoIndex = index;

            this.$emit('setVideoUrl', item.url)

        }
    },
    computed: {
        ...mapState(["token"]),
    },
}

</script>
 
  
<style lang="scss" scoped>
.video-list {
    height: 100%;
    border-top: 4px solid #2B6ED1;
    background: rgba(13, 33, 54, 0.9);
    color: #fff;
    font-size: 21px;
    width: 400px;

    .title {
        height: 50px;
        display: flex;
        align-items: center;
        margin-left: 19px;
        font-size: 22px;
    }

    .video-item-list {
        height: 79%;
        overflow: auto;

        .activeVideo {
            background: #0A1C30;
        }
    }

    .video-item {
        display: flex;
        padding: 17px 19px;
        height: 70px;
        cursor: pointer;
        align-items: center;
        justify-content: space-between;

        &:hover {
            background: rgba(10, 28, 48, 0.5);
        }
        p {
            margin: 0 !important;
        }
        .video-icon {
            margin-right: 10px;

            img {
                height: 59px;
            }
        }

        .video-info {
            // width: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .create-at {
                p {
                    color: #DBEBEB;
                    opacity: 0.3;
                }

            }
        }
    }
}
</style>
  