<template>
  <!-- 更改巡护代码密码 -->
  <el-dialog
    title="更改巡护代码密码"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    class="taskBox"
    v-dialogDrag
  >
    <el-divider></el-divider>
    <div class="detailBar">
      <div class="formBox">
        <el-form
          class="formbox"
          ref="ruleForm"
          :rules="rules"
          :model="ruleForm"
          label-width="80px"
        >
          <el-form-item
            :class="[formMessage.password_old ? 'isNotError' : '']"
            prop="password_old"
            label
          >
            <el-input
              placeholder="请输入当前密码"
              v-model="ruleForm.password_old"
              @blur="passwordOld"
            ></el-input>
          </el-form-item>
          <el-form-item
            :class="[formMessage.password_new ? 'isNotError' : '']"
            prop="password_new"
            label
          >
            <el-input
              type="password"
              placeholder="请输入新密码"
              v-model="ruleForm.password_new"
              autocomplete="off"
              @blur="passwordNew"
            ></el-input>
          </el-form-item>
          <el-form-item
            :class="[formMessage.password_repeat ? 'isNotError' : '']"
            prop="password_repeat"
            label
          >
            <el-input
              type="password"
              placeholder="请确认新密码"
              v-model="ruleForm.password_repeat"
              autocomplete="off"
              @blur="passwordRepeat"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-divider></el-divider>
    <span slot="footer" class="dialog-footer">
      <el-button class="footBtn" type="primary" @click="editUnit"
        >确 认</el-button
      >
      <el-button class="footBtn" @click="dialogVisible = false"
        >取 消</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { mapState,mapMutations } from "vuex";
export default {
  inject: ["reload"],
  props: {
    // currentParam: {
    //   type: Object,
    //   default: {}
    // }
  },
  data () {
    var validatePass=(rule,value,callback) => {
      if(value==="") {
        callback(new Error("请输入密码"));
      } else {
        if(this.ruleForm.password_repeat!=="") {
          this.$refs.ruleForm.validateField("password_repeat");
        }
        callback();
      }
    };
    var validatePass2=(rule,value,callback) => {
      if(value==="") {
        callback(new Error("请再次输入密码"));
      } else if(value!==this.ruleForm.password_new) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        password_old: "",
        password_new: "",
        password_repeat: ""
      },
      rules: {
        password_old: [
          { required: true,message: "当前密码不能为空",trigger: "blur" }
        ],
        password_new: [{ validator: validatePass,trigger: "blur" }],
        password_repeat: [{ validator: validatePass2,trigger: "blur" }]
      },
      dialogVisible: false,
      formMessage: {
        password_old: false,
        password_new: false,
        password_repeat: false
      }
    };
  },
  methods: {
    open () {
      this.dialogVisible=true;
    },
    editUnit () {
      this.$refs["ruleForm"].validate(valid => {
        if(valid) {
          this.submitData();
        } else {
        }
      });
    },
    submitData () {
      this.$apis
        .resetPsw(
          {
            password_old: this.ruleForm.password_old,
            password_new: this.ruleForm.password_new,
            password_repeat: this.ruleForm.password_repeat
          },
          this.unit_token
        )
        .then(data => {
         
          if(data.data.status==0) {
            this.$message({
              message: data.data.message,
              type: "success"
            });
            this.dialogVisible=false;
          } else {
            this.$message({
              message: data.data.message,
              type: "error"
            });
            this.dialogVisible=false;
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    passwordOld (val) {
      val.target.value
        ? (this.formMessage.password_old=false)
        :(this.formMessage.password_old=true);
    },
    passwordNew (val) {
      val.target.value
        ? (this.formMessage.password_new=false)
        :(this.formMessage.password_new=true);
    },
    passwordRepeat (val) {
      val.target.value
        ? (this.formMessage.password_repeat=false)
        :(this.formMessage.password_repeat=true);
    }
  },
  created () { },
  computed: {
    ...mapState(["unit","unit_token"])
  }
};
</script>

<style lang="less" scoped>
/deep/.el-form-item.is-error.isNotError .el-input__inner {
  border: 1px solid #dcdfe6;
}
/deep/.el-form-item.is-error.isNotError {
  .el-form-item__error {
    display: none !important;
  }
}
.formBox {
  margin-top: 50px !important;
  margin-left: 60px;
  margin-bottom: 50px;
}
/deep/.el-dialog {
  width: 400px !important;
  height: 400px !important;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-divider--horizontal {
  margin: 12px 0;
}
/deep/.el-dialog__footer {
  text-align: center;
}
.dialog-footer {
  margin: 0 auto;
}
.footBtn {
  width: 140px;
  height: 48px;
  margin-left: 20px;
}
.el-input {
  width: 277px;
}
</style>