// 预警相关接口
import {
	fly,
	baseURL,
	start,
	finishRes,
	finishErr,
	deploy
} from '../fly.js'

// 添加请求拦截器
fly.interceptors.request.use(
	(request) => {
		start(request)
	}
)

// 添加响应拦截器，响应拦截器会在then/catch处理之前执行
fly.interceptors.response.use(
	(response) => {
		finishRes(response)
	},
	(err) => {
		finishErr(err)
	}
)

// 发布预警
export const saveWarning = (data, token) => fly.post(
	baseURL + '/portal/saveWarning',
	data, deploy(30000, token)
)

// 预警列表
export const getWarningList = (data, token) => fly.post(
	baseURL + '/portal/getWarningList',
	data, deploy(30000, token)
)

// 我的预警发布
export const getMyWarningList = (data, token) => fly.post(
	baseURL + '/portal/getMyWarningList',
	data, deploy(30000, token)
)

// 预警发布管理
export const manageWarning = (data, token) => fly.post(
	baseURL + '/portal/manageWarning',
	data, deploy(30000, token)
)

// 预警详情
export const getWarningDetail = (data, token) => fly.post(
	baseURL + '/portal/getWarningDetail',
	data, deploy(30000, token)
)

// 审核预警
export const auditWarning = (data, token) => fly.post(
	baseURL + '/portal/auditWarning',
	data, deploy(30000, token)
)

// 删除预警
export const deleteWarning = (data, token) => fly.post(
	baseURL + '/portal/deleteWarning',
	data, deploy(30000, token)
)