import Vue from 'vue'
import Cookies from 'js-cookie'
import 'normalize.css/normalize.css'
import Element from 'element-ui'
import VueAMap from 'vue-amap'
import { lazyAMapApiLoaderInstance } from 'vue-amap';
import './styles/element-variables.scss'
import '@/styles/index.scss' // global css
import '../src/assets/icon/iconfont.css'
import App from './App'
import store from './store'
import router from './router'
import http from './http'
import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log
import global from './components/globalComponent'
import utils from './utils/public' // 任务公用方法
import * as filters from './filters' // global filters
import animated from 'animate.css'
import echarts from 'echarts'
import Echo from 'laravel-echo'
import './utils/dialog' //弹框拖拽
import VueQr from 'vue-qr'; //二维码显示
import {selectNavSide} from './utils/changeNav'
import '@/styles/DIN.scss';
Vue.prototype.$EventBus = new Vue()
import Aegis from 'aegis-web-sdk';
const aegis = new Aegis({
  id: 'OekK08BZEG63MVr5Xq', // 应用ID，即上报key
  uin: 'xxx', // 用户唯一 ID（可选）
  reportApiSpeed: true, // 接口测速
  reportAssetSpeed: true, // 静态资源测速
  spa: true // spa 应用页面跳转的时候开启 pv 计算
});
// 飞马在线设备多屏
window.apihost = process.env.VUE_APP_API_HOST
window.runEnv = process.env.VUE_APP_RUNENV ? process.env.VUE_APP_RUNENV : 'backend'
window.io = require('socket.io-client')
window.Echo = new Echo({
  broadcaster: 'socket.io',
  host: process.env.VUE_APP_API_SOCKET,
  authEndpoint: ':8000/broadcasting/auth'
})

console.log(window.runEnv)

// 添加监听事件
window.Echo.channel('chat').listen('.message', e => {
  const message = JSON.parse(e.message)

  const findIndex = message.data.to_user_ids.findIndex(v => {
    return v == store.state.user.id
  })
  // 只针对to_user里的用户做处理
  if (findIndex >= 0) {
    store.state.chatDialog.new_msg = {
      wait_handle: 1,
      cmd: message.cmd,
      data: message.data
    }

    if (message.cmd == 'new_msg') {
      const user = JSON.parse(message.data.user)
      // 增加未读消息数
      if (user.id != store.state.user.id) { // 对于创建者来说不需要处理
        store.state.chatDialog.unread_num++
        store.state.chatDialog.unread_num_arr['project_id_' + message.data.project_id] = store.state.chatDialog.unread_num_arr['project_id_' + message.data.project_id] ? store.state.chatDialog.unread_num_arr['project_id_' + message.data.project_id] + 1 : 1
      }
    }
    if (message.cmd == 'media_room_leave') {
      const findIndex = store.state.mediaDialog.join_user.findIndex(v => {
        return v == message.data.leave_user
      })
      store.state.mediaDialog.join_user.splice(findIndex, 1)
    }
    if (message.cmd == 'media_room_join') {
      store.state.mediaDialog.join_user.push(message.data.join_user)
    }
    if (message.cmd == 'media_open') {
      const open_user = JSON.parse(message.data.open_user)
      if (open_user.id != store.state.user.id) {
        store.state.mediaRequestDialog.url = message.data.media_url
        store.state.mediaRequestDialog.show = true
        store.state.mediaRequestDialog.name = open_user.name + '邀请你参加视频通话'
        store.state.mediaRequestDialog.photo = open_user.photo
        store.state.mediaRequestDialog.room_id = message.data.id
        setTimeout(() => {
          store.state.mediaRequestDialog.show = false
        }, 30000)
      }
    }
  }
})
window.Echo.channel('msg_center').listen('.message', e => {
  const message = JSON.parse(e.message)
  let data = message.data
  if (data.user_id == store.state.user.id) {
    store.state.msgCenter.new_msg = {
      wait_handle: 1,
      cmd: message.cmd,
      data: message.data
    }

    if (data.type == 1) {
      store.state.msgCenter.unread_num.task++
    }
    if (data.type == 2) {
      store.state.msgCenter.unread_num.task_command++
    }
    if (data.type == 3) {
      store.state.msgCenter.unread_num.task_file++
    }
    if (data.type == 4) {
      store.state.msgCenter.unread_num.data_apply++
    }
    if (data.type == 5) {
      store.state.msgCenter.unread_num.warning++
    }
    if (data.type == 6) {
      store.state.msgCenter.unread_num.disaster++
    }
  }
})
window.Echo.channel('task').listen('.message', e => {
  const message = JSON.parse(e.message)
  console.log(e.message)
  const findIndex = message.data.to_user_ids.findIndex(v => {
    return v == store.state.user.id
  })
  if (findIndex >= 0 || store.state.user.is_admin == 1) {
    store.state.taskWslisten.new_msg = {
      wait_handle: 1,
      cmd: message.cmd,
      data: message.data
    }
  }
})

Vue.use(animated)
Vue.use(VueAMap)
Vue.use(VueQr)
Vue.prototype.utils = utils
// 初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德key
  key: process.env.VUE_APP_AMAP,
  // 插件集合 （插件按需引入）
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType',
    'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geocoder', 'AMap.Geolocation', 'AMap.Heatmap', 'AMap.MouseTool',
    'AMap.InfoWindow', 'AMap.Object3DLayer', 'AMap.Object3D'
  ],
  uiVersion: '1.0.11', // 版本号
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
})


Vue.use(http)
Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})

Vue.prototype.$echarts = echarts

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  selectNavSide(to.path,to.query);
  const sysName = window.sessionStorage.getItem('sysname')
  if (sysName) {
    document.title = sysName
  } else if (window.runEnv !== 'portal') {
    document.title = '无人机巡护指挥调度管理平台'
    if (localStorage.getItem('TokenKeyToUser') !== null && to.path === '/login'){
      router.push('/home')
    }
  } else {
    document.title = '应急测绘保障服务体系基础平台公众门户网站'
  }

  next()
})

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
Vue.directive('loadmore', {
  bind(el, binding) {
    var p = 0
    var t = 0
    var down = true
    var selectWrap = el.querySelector('.el-table__body-wrapper')
    selectWrap.addEventListener('scroll', function () {
      // 判断是否向下滚动
      p = this.scrollTop
      // if ( t < p){down=true}else{down=false}
      if (t < p) {
        down = true
      } else {
        down = false
      }
      t = p
      // 判断是否到底
      const sign = 10
      const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight
      if (scrollDistance <= sign && down) {
        binding.value()
      }
    })
  }
})
// 获取浏览器存储数据，localStorge
if (localStorage.getItem('TokenKeyToUser') !== null){
  store.commit('userStorage',JSON.parse(localStorage.getItem('userInfo')) );
  store.commit('tokenStorage',localStorage.getItem('TokenKeyToUser'));
} else {
  store.commit('userStorage', {});
  store.commit('tokenStorage','');
}

console.log('v2.2');
