var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "centerHome" },
    [
      _c("Monitor", {
        ref: "monitor",
        attrs: {
          requestDataManager: _vm.requestDataManager,
          onlineSortiesData: _vm.onlineSortiesData,
          onlineLayerData: _vm.onlineLayerData,
          allDeviceLayerData: _vm.allDeviceLayerData,
          netWorkIcon: _vm.netWorkIcon,
          allRequsetLoading: _vm.allRequsetLoading,
        },
        on: { setTimerRun: _vm.setTimerRun },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isHiddenAll,
              expression: "!isHiddenAll",
            },
          ],
          staticClass: "bottomWindow",
        },
        [
          _c("bottom-bar", {
            attrs: {
              selected: _vm.isShowDio,
              datas: {
                networkingTotal: _vm.deviceTotal,
                onlineTotal: _vm.onlineTotal,
                flyAreaTotal: _vm.flyAreaTotal,
                taskTotal: _vm.taskTotal,
              },
            },
            on: { showDio: _vm.showDio },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "rightWindow" },
        [
          _c("rightBar", {
            ref: "rightBar",
            attrs: { isHiddenAll: _vm.isHiddenAll },
            on: {
              hiddenAll: _vm.hiddenAll,
              showRightDioBtn: _vm.showRightDioBtn,
              chat: _vm.chat,
              switchMap: _vm.switchMap,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showData && !_vm.isHiddenAll && !_vm.clickOutsideLeftWindow
        ? _c("div", { staticClass: "leftWindow" }, [
            _c("div", [
              _c("div", { staticClass: "header" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isShowDio === 1,
                        expression: "isShowDio === 1",
                      },
                    ],
                    staticClass: "flyArea",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "button",
                        class: [_vm.isSelectBtn === 1 ? "isActive" : ""],
                        staticStyle: { "margin-left": "37px" },
                        on: {
                          click: function ($event) {
                            return _vm.selectBtn(1)
                          },
                        },
                      },
                      [_vm._v("\n            飞行面积统计\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "button",
                        class: [_vm.isSelectBtn === 2 ? "isActive" : ""],
                        staticStyle: { "margin-right": "33px" },
                        on: {
                          click: function ($event) {
                            return _vm.selectBtn(2)
                          },
                        },
                      },
                      [_vm._v("\n            月飞行面积对比\n          ")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isShowDio === 2,
                        expression: "isShowDio === 2",
                      },
                    ],
                    staticClass: "networkingDevices",
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("div", { staticClass: "networkingDeviceBtn" }, [
                      _c(
                        "div",
                        {
                          staticClass: "deviceButton",
                          class: [
                            _vm.connectedDeviceIndex === 1 ? "isActive" : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.selectDeviceType(1)
                            },
                          },
                        },
                        [_vm._v("\n              无人机\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "deviceButton",
                          class: [
                            _vm.connectedDeviceIndex === 3 ? "isActive" : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.selectDeviceType(3)
                            },
                          },
                        },
                        [_vm._v("\n              终端设备\n            ")]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isShowDio === 4,
                        expression: "isShowDio === 4",
                      },
                    ],
                    staticClass: "flyArea",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "button",
                        class: [_vm.isSelectBtn === 1 ? "isActive" : ""],
                        staticStyle: { "margin-left": "37px" },
                        on: {
                          click: function ($event) {
                            return _vm.selectBtn(1)
                          },
                        },
                      },
                      [_vm._v("\n            总任务统计\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "button",
                        class: [_vm.isSelectBtn === 2 ? "isActive" : ""],
                        staticStyle: { "margin-right": "33px" },
                        on: {
                          click: function ($event) {
                            return _vm.selectBtn(2)
                          },
                        },
                      },
                      [_vm._v("\n            月任务对比\n          ")]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "body" },
                [
                  _c("div", { staticStyle: { height: "33px" } }),
                  _vm._v(" "),
                  _c("dioContent", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          (_vm.isSelectBtn === 1 && _vm.showRingChart) ||
                          _vm.isShowDio === 2,
                        expression:
                          "(isSelectBtn === 1 && showRingChart) || isShowDio === 2",
                      },
                    ],
                    attrs: { datas: _vm.dioContentData },
                  }),
                  _vm._v(" "),
                  _vm.isSelectBtn === 2 && _vm.showBarChart
                    ? _c("comBar", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.isLoadingTask,
                            expression: "isLoadingTask",
                          },
                        ],
                        attrs: {
                          options: _vm.comBarData,
                          "element-loading-background": "rgba(0, 0, 0, 0)",
                        },
                        on: { rangeChange: _vm.onRangeChange },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "footer" }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShowDio === 3,
                    expression: "isShowDio === 3",
                  },
                ],
                staticClass: "onlineDevices",
              },
              [
                _c("div", { staticClass: "onlineDeviceTitle" }, [
                  _vm._v("在线设备"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.onlineDatas, function (val, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "div",
                      { staticClass: "onlineDataTitle", attrs: { div: "" } },
                      [
                        _c("div", { staticClass: "onlineDataTitleText" }, [
                          _c("span", { staticClass: "onlineDeviceTotal" }, [
                            _vm._v(_vm._s(val.total) + " "),
                          ]),
                          _c("span", [_vm._v(" " + _vm._s(val.name))]),
                        ]),
                        _vm._v(" "),
                        val.length > 6
                          ? _c(
                              "div",
                              {
                                staticClass: "onlineDataTitleImg",
                                on: {
                                  click: function ($event) {
                                    return _vm.onlineShowMore(index)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  style: {
                                    transform: val.isShowMore
                                      ? "rotate(180deg)"
                                      : "",
                                  },
                                  attrs: {
                                    src: require("@/assets/img/centerHome/caretdown.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "onlineTypeDatas",
                        style: { height: val.isShowMore ? "auto" : "78px" },
                      },
                      _vm._l(val.data, function (dataVal, dataKey, dataIndex) {
                        return _c(
                          "div",
                          { key: dataKey, staticClass: "onlineTypeData" },
                          [
                            _c("div", {
                              staticClass: "onlineDataColor",
                              style: { background: _vm.color[dataIndex] },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "name",
                                attrs: { title: dataKey },
                              },
                              [_vm._v(_vm._s(dataKey))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "data",
                                attrs: { title: dataVal },
                              },
                              [_vm._v(_vm._s(dataVal))]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowtTasksAndSorties,
              expression: "isShowtTasksAndSorties",
            },
          ],
          staticClass: "tasksAndSorties",
        },
        [
          _c("com-bj-dio", {
            ref: "ComBjDio",
            attrs: {
              isTasks: _vm.isTasks,
              requestDataManager: _vm.requestDataManager,
              onlineSortiesData: _vm.onlineSortiesData,
              deviceData: _vm.categoryDevice,
              isShowtTasksAndSorties: _vm.isShowtTasksAndSorties,
            },
            on: { toMore: _vm.toMultiViews },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showMultiViews
        ? _c("MultiMonitors", {
            attrs: {
              show: _vm.showMultiViews,
              onlineSortiesData: _vm.onlineSortiesData,
              onlineLayerData: _vm.onlineLayerData,
            },
            on: {
              close: function ($event) {
                _vm.showMultiViews = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "networkingDeviceTitle" }, [
      _c("div", { staticClass: "networkingName" }, [_vm._v("联网设备")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }