import { render, staticRenderFns } from "./warning.vue?vue&type=template&id=15c80bbc&scoped=true&"
import script from "./warning.vue?vue&type=script&lang=js&"
export * from "./warning.vue?vue&type=script&lang=js&"
import style0 from "./warning.vue?vue&type=style&index=0&id=15c80bbc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15c80bbc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/www/wwwroot/xhddgl.afuav.com/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15c80bbc')) {
      api.createRecord('15c80bbc', component.options)
    } else {
      api.reload('15c80bbc', component.options)
    }
    module.hot.accept("./warning.vue?vue&type=template&id=15c80bbc&scoped=true&", function () {
      api.rerender('15c80bbc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/warning.vue"
export default component.exports