import axios from 'axios'

/**
 *
 * @param {*} lnglat
 * @param {*} callback
 */
export function getAddress(lnglat) {
  return axios.get('http://api.tianditu.gov.cn/geocoder', {
    params: {
      postStr: JSON.stringify({
        lon: lnglat[0],
        lat: lnglat[1],
        ver: 1
      }),
      type: 'geocode',
      tk: process.env.VUE_APP_TIANDITU_KEY
    }
  })
}

/**
 *
 * @param {Object} options
 * @param {String} options.province 省名
 * @returns
 */
export function getDistrict(options) {
  return axios.get(`http://api.tianditu.gov.cn/v2/administrative`, {
    params: {
      ...options,
      tk: process.env.VUE_APP_TIANDITU_KEY
    }
  })
}
