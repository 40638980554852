var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "taskItem",
      style: {
        height:
          _vm.projectObj.status_name != "已创建，待推送" ? "230px" : "64px",
      },
    },
    [
      _c(
        "div",
        { staticClass: "task-item-top" },
        [
          _c(
            "el-checkbox",
            {
              model: {
                value: _vm.checked,
                callback: function ($$v) {
                  _vm.checked = $$v
                },
                expression: "checked",
              },
            },
            [_vm._v(_vm._s(_vm.projectObj.task_name))]
          ),
          _vm._v(" "),
          this.checked &&
          (_vm.projectObj.status_name === "已创建，待推送" ||
            _vm.projectObj.status_name === "已推送联盟") &&
          _vm.role === 1 &&
          _vm.projectObj.status == 2
            ? _c("div", { staticClass: "task-operation cursor iconfont" }, [
                _vm.projectObj.status_name === "已推送联盟" &&
                _vm.allianceState !== "已拒绝"
                  ? _c(
                      "div",
                      {
                        staticStyle: { "margin-right": "17px" },
                        on: {
                          click: function ($event) {
                            return _vm.operation("接单")
                          },
                        },
                      },
                      [
                        _c("span", [_vm._v("")]),
                        _vm._v("\n        接单\n      "),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.projectObj.status_name === "已推送联盟" &&
                _vm.allianceState !== "已拒绝"
                  ? _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.operation("拒绝")
                          },
                        },
                      },
                      [
                        _c("span", [_vm._v("")]),
                        _vm._v("\n        拒绝\n      "),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.authority
            ? _c(
                "div",
                {
                  staticClass: "task-item-redoProject cursor",
                  style: {
                    color:
                      _vm.projectObj.task_data_original_count === 0 &&
                      _vm.projectObj.task_data_result_count === 0
                        ? "rgb(187 183 183)"
                        : "#1890ff",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.reformBtn(_vm.projectObj)
                    },
                  },
                },
                [_vm._v("\n      重做\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "task-media-icon iconfont cursor",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.conversation(_vm.projectObj)
                },
              },
            },
            [_vm._v("\n      \n    ")]
          ),
          _vm._v(" "),
          _vm.authority
            ? _c(
                "div",
                {
                  staticClass: "task-item-icon iconfont cursor",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.instructions(_vm.projectObj)
                    },
                  },
                },
                [_vm._v("\n      \n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.projectObj.status_name != "已创建，待推送"
        ? _c("div", { staticClass: "task-item-cen" }, [
            _c("div", { staticClass: "item-body iconfont" }, [
              _c(
                "div",
                {
                  staticClass: "first-line",
                  class: {
                    reject:
                      _vm.projectObj.task_push_data.task_last_log_name ==
                      "已拒绝",
                  },
                  style: {
                    opacity:
                      _vm.projectObj.task_push_data.push_to_unit_datetime !==
                      null
                        ? "1"
                        : "0",
                  },
                  on: { click: _vm.directiveLog },
                },
                [
                  _vm.allianceState == ""
                    ? _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.projectObj.status_name === "已推送联盟"
                                ? _vm.role === 0
                                  ? "已推送联盟"
                                  : "巡护待接单"
                                : "已接单"
                            ) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.allianceState != ""
                    ? _c(
                        "div",
                        {
                          class: {
                            reject:
                              _vm.allianceState == "已超时" ||
                              _vm.allianceState == "已拒绝",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.allianceState) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-div" }, [
                _vm.projectObj.task_push_data.push_to_unit_datetime !== null
                  ? _c("div", { staticClass: "icon company" }, [
                      _vm._v("\n          \n        "),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.projectObj.task_push_data.push_to_unit_datetime === null
                  ? _c("div", { staticClass: "icon company vacancy" }, [
                      _vm._v("\n          \n        "),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { position: "relative" } }, [
                _c(
                  "div",
                  {
                    staticClass: "name over-dot",
                    style: {
                      opacity:
                        _vm.projectObj.task_push_data.unit_data.length > 0
                          ? "1"
                          : "0",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.massDetails("巡护")
                      },
                    },
                  },
                  [
                    _vm.projectObj.task_push_data.unit_data.length > 1
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.projectObj.task_push_data.unit_data[1]
                                .unit_name
                            )
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        style: {
                          paddingLeft:
                            _vm.projectObj.task_push_data.unit_data.length > 1
                              ? "20px"
                              : "0",
                        },
                        attrs: {
                          title:
                            _vm.projectObj.task_push_data.unit_data.length > 0
                              ? _vm.projectObj.task_push_data.unit_data[0]
                                  .unit_name
                              : "",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.projectObj.task_push_data.unit_data.length > 0
                                ? _vm.projectObj.task_push_data.unit_data[0]
                                    .unit_name
                                : ""
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.projectObj.task_push_data.unit_data.length > 1
                  ? _c(
                      "div",
                      { staticClass: "pcs", staticStyle: { right: "-32px" } },
                      [
                        _vm._v(
                          "\n          等" +
                            _vm._s(
                              _vm.projectObj.task_push_data.unit_data.length
                            ) +
                            "个\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-div time" }, [
                _c("div", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.projectObj.task_push_data.push_to_unit_datetime
                      ) +
                      "\n          "
                  ),
                  _vm.projectObj.task_push_data.push_to_unit_datetime !== null
                    ? _c("span", [_vm._v("")])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-div time" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.projectObj.task_push_data
                        .push_to_unit_confirm_datetime
                    ) +
                    "\n      "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "wire" }),
            _vm._v(" "),
            _c("div", { staticClass: "item-body two" }),
            _vm._v(" "),
            _c("div", { staticClass: "item-body iconfont" }, [
              _c(
                "div",
                {
                  staticClass: "first-line",
                  style: { opacity: _vm.userState ? "1" : "0" },
                  on: { click: _vm.directiveLog },
                },
                [
                  _c(
                    "div",
                    {
                      class: {
                        reject:
                          _vm.userState == "已拒绝" ||
                          _vm.userState == "已超时",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.userState) + "\n        "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "item-div" }, [
                _vm.projectObj.task_push_data.user_data.length > 1
                  ? _c("div", { staticClass: "icon company" }, [
                      _vm._v("\n          \n        "),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.projectObj.task_push_data.user_data.length <= 1
                  ? _c(
                      "div",
                      {
                        staticClass: "icon company",
                        class: {
                          vacancy:
                            _vm.projectObj.task_push_data.user_data.length ===
                            0,
                        },
                      },
                      [_vm._v("\n          \n        ")]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { position: "relative" } }, [
                _c(
                  "div",
                  {
                    staticClass: "name over-dot",
                    style: {
                      opacity:
                        _vm.projectObj.task_push_data.user_data.length > 0
                          ? "1"
                          : "0",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.massDetails("用户")
                      },
                    },
                  },
                  [
                    _vm.projectObj.task_push_data.user_data.length > 1
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.projectObj.task_push_data.user_data[1].name
                            )
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: { "max-width": "100px" },
                        style: {
                          paddingLeft:
                            _vm.projectObj.task_push_data.user_data.length > 1
                              ? "20px"
                              : "0",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.projectObj.task_push_data.user_data.length > 0
                                ? _vm.projectObj.task_push_data.user_data[0]
                                    .name
                                : ""
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.projectObj.task_push_data.user_data.length > 1
                  ? _c("div", { staticClass: "pcs" }, [
                      _vm._v(
                        "\n          等" +
                          _vm._s(
                            _vm.projectObj.task_push_data.user_data.length
                          ) +
                          "个\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item-div time" }, [
                _vm.projectObj.task_push_data.push_to_user_datetime !== null
                  ? _c("div", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.projectObj.task_push_data.push_to_user_datetime
                          ) +
                          "\n          "
                      ),
                      _c("span", [_vm._v("")]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.projectObj.task_push_data.push_to_user_confirm_datetime !==
              null
                ? _c("div", { staticClass: "item-div time" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.projectObj.task_push_data
                            .push_to_user_confirm_datetime
                        ) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.projectObj.task_name + "推送详情",
            modal: false,
            visible: _vm.dialogTableVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.gridData } },
            [
              _c("el-table-column", {
                attrs: { align: "center", property: "name", label: "名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  property: "user_canceled",
                  label: "状态",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "任务日志",
            top: "8vh",
            visible: _vm.directiveVisible,
            width: "30%",
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.directiveVisible = $event
            },
          },
        },
        _vm._l(_vm.directiveList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "pop-up",
              style: { color: item.log_from == "app" ? "#1182FB" : "#3C4353" },
            },
            [
              _c("div", { staticClass: "pop-up-time" }, [
                _vm._v(_vm._s(item.created_time)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pop-up-status" }, [
                _vm._v(_vm._s(item.status)),
              ]),
              _vm._v(" "),
              item.status === "上传任务进度"
                ? _c("div", { staticClass: "pop-up-progress" }, [
                    _c("div", { staticClass: "progress-bar" }, [
                      _c("div", { style: { width: _vm.progress + "%" } }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "progress-num" }, [
                      _vm._v(_vm._s(_vm.progress) + "%"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "interval",
                  style: {
                    opacity: index === _vm.directiveList.length - 1 ? 0 : 1,
                  },
                },
                [_c("div"), _vm._v(" "), _c("div"), _vm._v(" "), _c("div")]
              ),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }