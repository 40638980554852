import axios from "axios";

import AgoraRTC from "./agora"
let videoId = ""
let client = ""
export class SetUavLive {
    constructor(id, live, liveType, localId) {
        videoId = id
        if (liveType === 'rtmp') {
            this.startAliplayer(id, live.artc);
        } else {
            client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
            const uid = Math.floor(Math.random() * (Math.pow(2, 32))) + 1;
            this.getAgoraToken(localId, uid)
        }
    }
    // 获取到token
    getAgoraToken(localId, uid) {
        this.getFeimaMonitorData("/live/getAgoraToken", { flight_mission_id: localId, uid })
            .then((res) => {
                client.setClientRole('audience', { level: 2 });
                // add event listener to play remote tracks when remote user publishs.
                client.on("user-published", this.handleUserPublished);
                // client.on("user-unpublished", handleUserUnpublished);
                client.join(res.data.app_id, localId, res.data.token, uid);
                return null;
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // 监听主播加入
    async handleUserPublished(user, mediaType) {
        console.log(user, mediaType, client, videoId, 'this.videoId', this.subscribe);
        await client.subscribe(user, mediaType);
        if (mediaType === 'video') {
            user.videoTrack.play(videoId, { fit: "contain" });
        }
    }
    // 阿里云监听
    startAliplayer(id, artc) {
        if (!artc) return
        new Aliplayer({
            id,
            source: artc,
            width: "100%",
            height: "100%",
            autoplay: true,
            isLive: true,
            rePlay: false,
            playsinline: true,
            preload: true,
            controlBarVisibility: "hover",
            useH5Prism: true,
            diagnosisButtonVisible: false,
        },
            (player) => {
            })
    }

    getFeimaMonitorData(api, params) {
        return new Promise((resolve, reject) => {
            let url = 'https://api.feimarobotics.com/v2' + api;

            axios.defaults.headers.post["Content-Type"] =
                "application/json;charset=UTF-8";
            try {
                axios
                    .get(url, { params })
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            } catch (err) {
                reject(error);
            }
        })
    }

}