<template>
  <div class="flying">
    <div class="title">选择任务</div>
    <div class="content">
      <div class="type-select">
        <img
          v-show="nav == 1"
          class="task-type"
          src="@/assets/img/newFm/clickFm.png"
        />
        <img
          v-show="nav == 2"
          class="task-type"
          src="@/assets/img/newFm/fm.png"
          @click="getTaskList(1)"
        />
        <img
          v-show="nav == 2"
          class="task-type"
          src="@/assets/img/newFm/clickTy.png"
        />
        <img
          v-show="nav == 1"
          class="task-type"
          src="@/assets/img/newFm/ty.png"
          @click="getTaskList(2)"
        />
      </div>
      <div class="cen">
        <div class="nape nape-one nape-top-one">
          <span>任务名称</span>
          <el-input
            v-model="taskName"
            class="import scale"
            placeholder="请输入"
            clearable
          />
        </div>
        <div class="nape nape-two nape-top-one">
          <span>创建人</span>
          <el-input
            v-model="creator"
            class="import scale"
            placeholder="请输入"
            clearable
          />
        </div>
        <div class="nape nape-three nape-top-one">
          <span>创建时间</span>
          <el-date-picker
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            v-model="creationTime"
            class="import scale"
            type="daterange"
            align="right"
            unlink-panels
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </div>

        <div class="nape nape-one nape-top-two">
          <span>一级推送类型</span>
          <el-select
            v-model="stairType"
            class="import scale"
            clearable
            placeholder="请选择"
            @visible-change="dropDown"
          >
            <el-option
              v-for="item in TypeArr"
              :key="item.id"
              :value="item.name"
            />
          </el-select>
        </div>
        <div class="nape nape-two nape-top-two">
          <span>一级推送人</span>
          <el-input
            v-model="stairPeople"
            class="import scale"
            placeholder="请输入"
            clearable
          />
        </div>
        <div class="nape nape-three nape-top-two">
          <span>一级推送时间</span>
          <el-date-picker
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            v-model="stairTime"
            class="import scale"
            type="daterange"
            align="right"
            unlink-panels
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </div>

        <div class="nape nape-one nape-top-three">
          <span>二级推送类型</span>
          <el-select
            v-model="secondType"
            class="import scale"
            clearable
            placeholder="请选择"
            @visible-change="dropDown"
          >
            <el-option
              v-for="item in TypeArr"
              :key="item.id"
              :value="item.name"
            />
          </el-select>
        </div>
        <div class="nape nape-two nape-top-three">
          <span>二级推送人</span>
          <el-input
            v-model="secondPeople"
            class="import scale"
            placeholder="请输入"
            clearable
          />
        </div>
        <div class="nape nape-three nape-top-three">
          <span>二级推送时间</span>
          <el-date-picker
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            v-model="secondTime"
            class="import scale"
            type="daterange"
            align="right"
            unlink-panels
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </div>

        <div style="top: 203.2px" class="nape nape-one">
          <span>任务ID</span>
          <el-input
            v-model="taskID"
            class="import scale"
            placeholder="请输入"
            clearable
          />
        </div>
        <div class="nape nape-two nape-top-four">
          <span>发布门户</span>
          <el-select
            v-model="releasePortal"
            class="import scale"
            clearable
            placeholder="请选择"
            @visible-change="dropDown"
          >
            <el-option
              v-for="item in [
                '全部任务',
                '已发布至门户网站',
                '未发布至门户网站',
              ]"
              :key="item"
              :value="item"
            />
          </el-select>
        </div>
        <div class="nape nape-three nape-top-four">
          <span>舆情发布</span>
          <el-select
            v-model="releaseOpinion"
            class="import scale"
            clearable
            placeholder="请选择"
            @visible-change="dropDown"
          >
            <el-option
              v-for="item in [
                '全部任务',
                '已发布舆情',
                '未发布舆情',
                '已发布至门户网站',
                '未发布至门户网站',
              ]"
              :key="item"
              :value="item"
            />
          </el-select>
        </div>
      </div>
      <div class="form-option">
        <button class="inquire btn" type="default" @click="inquire">
          查询
        </button>
        <button class="reset btn" type="default" @click="reset">重置</button>
      </div>
      <div class="dividing-line" />
      <div class="bottom">
        <div v-for="(item, index) in list" :key="index" class="bottom-content">
          <div v-if="item.created_at" class="bottom-content-top flex-view">
            <div
              class="unfold-btn"
              v-if="selectType == 'task'"
              @click="ubfoldAlter(index, item.id)"
            >
              {{ unfoldArr[index] ? "-" : "+" }}
            </div>
            <div class="content-top-name">创建人：{{ item.mobile }}</div>
            <div class="content-top-time">
              创建时间：{{ item.created_at.slice(0, 19) }}
            </div>
          </div>
          <div
            v-if="item.created_at"
            :class="{
              contentCenVacancy:
                item.user_push_data === '' && item.unit_push_data === '',
            }"
            class="bottom-content-cen flex-view"
          >
            <div class="content-cen-name">{{ item.project_name }}</div>
            <div
              :class="{ 'content-cen-conceal': item.unit_push_data == '' }"
              class="content-cen-identity"
            >
              <div>推送人：{{ item.unit_push_data.push_user }}</div>
              <div v-if="item.unit_push_data">
                推送时间：{{ item.unit_push_data.push_datetime.slice(0, 19) }}
              </div>
              <div>推送类型：{{ item.unit_push_data.push_type }}</div>
              <div>推送进度：{{ item.unit_push_data.push_progress }}</div>
              <div>推送巡护：{{ item.unit_push_data.push_unit_qty }}</div>
              <div>推送人员：{{ item.unit_push_data.push_user_qty }}</div>
            </div>
            <div
              :class="{ 'content-cen-conceal': item.user_push_data == '' }"
              class="content-cen-identity content-cen-company"
            >
              <div>推送巡护：{{ item.user_push_data.push_unit_name }}</div>
              <div v-if="item.user_push_data">
                推送时间：{{ item.user_push_data.push_datetime.slice(0, 19) }}
              </div>
              <div>推送类型：{{ item.user_push_data.push_type }}</div>
              <div>推送进度：{{ item.user_push_data.push_progress }}</div>
              <div>推送巡护：{{ item.user_push_data.push_user_qty }}</div>
            </div>
            <div />
            <div />
            <div class="select-box" v-if="selectType == 'project'">
              <img
                v-show="item.id == selectProjectObj.id"
                class="select-icon"
                src="@/assets/img/chat/select.png"
              />
              <img
                v-show="item.id != selectProjectObj.id"
                class="select-icon"
                src="@/assets/img/chat/no_select.png"
                @click="selectTask(item, 'project')"
              />
            </div>
          </div>
          <div v-if="unfoldArr[index]" class="bottom-content-bot">
            <div v-for="(item2, index2) in item.arr" :key="index2 + '0'">
              <div class="content-list-title">{{ item2.block_name }}</div>
              <el-table
                ref="filterTable"
                :data="item2.task"
                style="width: 100%"
              >
                <el-table-column
                  align="center"
                  prop="task_name"
                  label="任务名称"
                  sortable
                />
                <el-table-column
                  align="center"
                  prop="id"
                  label="任务ID"
                  sortable
                />
                <el-table-column align="center" label="">
                  <template slot-scope="scope">
                    <div style="display: flex; justify-content: center">
                      <img
                        class="select-icon small"
                        v-show="scope.row.id == selectTaskObj.id"
                        src="@/assets/img/chat/select.png"
                      />
                      <img
                        class="select-icon small"
                        v-show="scope.row.id != selectTaskObj.id"
                        src="@/assets/img/chat/no_select.png"
                        @click="selectTask(scope.row, 'task')"
                      />
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <el-pagination
          class="bottom-el-pagination"
          background
          layout="prev, pager, next"
          :current-page="pageIndex"
          :total="sumPage"
          @current-change="currentChange"
        />
      </div>
    </div>
    <div class="foot">
      <div class="form-option">
        <button class="inquire btn" type="default" @click="confirmTask">
          确定
        </button>
        <button class="reset btn" type="default" @click="cancel">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      radio: "",
      sumPage: 100, // 总页数
      pageIndex: 1,
      nav: 1, //  页面飞马还是通用
      choice: 1, //  任务查询选择
      taskName: "", //  任务名称
      creator: "", // 创建人
      creationTime: "", //  创建时间
      stairType: "", // 一级推送类型
      TypeArr: [], //  推送类型数组
      stairPeople: "", // 一级推送人
      stairTime: "", // 一级推送时间
      secondType: "", // 二级推送类型
      secondPeople: "", // 二级推送人
      secondTime: "", // 二级推送时间
      taskID: "", //  任务id
      unfoldArr: [], //  判断是否展开
      allState: false, //  全部展开
      list: [], // 列表
      releasePortal: "", //  已发布门户
      releaseOpinion: "", //  发布舆情
      publicObj: {}, //  舆情发布组件绑定对象
      editPublicObj: {}, //  舆情编辑携带对象
      selectProjectObj: {
        // 选中的工程
        id: "",
        project_name: "",
      },
      selectTaskObj: {
        //选中的任务
        id: "",
        task_name: "",
      },
    };
  },
  mounted() {
    this.antiShake(this.taskList, 0);
  },
  computed: {
    ...mapState(["user", "token", "role", "currentUrl"]),
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData",
  },
  props: {
    taskStatus: [String, Number],
    selectType: {
      type: String,
      default: "project",
    },
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    /**
     * 选择任务类型
     */
    getTaskList(index) {
      this.reset();
      this.nav = index;
      this.taskList();
    },
    /**
     * 下拉框出现的时候
     */
    dropDown(data) {
      if (data && this.TypeArr.length == 0) {
        this.$apis
          .taskPushType({}, this.token)
          .then((data) => {
            this.TypeArr = data.data.data;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    /**
     * 点击重置
     */
    reset() {
      this.taskName = "";
      this.creator = "";
      this.creationTime = ["", ""];
      this.stairType = "";
      this.stairPeople = "";
      this.stairTime = ["", ""];
      this.secondType = "";
      this.secondPeople = "";
      this.secondTime = ["", ""];
      this.taskID = "";
    },
    /**
     * 点击查询
     */
    inquire() {
      this.antiShake(this.taskList, 0);
    },
    /**
     * 获取项目列表
     */
    taskList(num = 0) {
      let way = "listFeiMa";
      way = this.nav === 1 ? "listFeiMa" : "listCommon";
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const parameter = {
        page: this.pageIndex,
        type: this.nav,
        status: "",
        project_name: this.taskName,
        create_user_mobile: this.creator,
        created_at_from: this.creationTime === null ? "" : this.creationTime[0],
        created_at_to: this.creationTime === null ? "" : this.creationTime[1],
        first_push_user: this.stairPeople,
        push_unit_created_at_from:
          this.stairTime === null ? "" : this.stairTime[0],
        push_unit_created_at_to:
          this.stairTime === null ? "" : this.stairTime[1],
        second_push_user: this.secondPeople,
        push_user_created_at_form:
          this.secondTime === null ? "" : this.secondTime[0],
        push_user_created_at_to:
          this.secondTime === null ? "" : this.secondTime[1],
        task_id: this.taskID,
        user_level: num,
      };
      switch (this.releasePortal) {
        case "已发布至门户网站":
          parameter.is_portal = 1;
          break;
        case "未发布至门户网站":
          parameter.is_portal = 2;
          break;
        default:
          parameter.is_portal = 0;
          break;
      }
      switch (this.releaseOpinion) {
        case "已发布舆情":
          parameter.is_disaster = 1;
          break;
        case "未发布舆情":
          parameter.is_disaster = 2;
          break;
        case "已发布至门户网站":
          parameter.is_disaster = 3;
          break;
        case "未发布至门户网站":
          parameter.is_disaster = 4;
          break;
        default:
          parameter.is_disaster = 0;
          break;
      }
      if (this.stairType !== "") {
        parameter.first_push_type = this.stairType === "群发" ? 2 : 1;
      }
      if (this.secondType !== "") {
        parameter.second_push_type = this.secondType === "群发" ? 2 : 1;
      }
      switch (this.choice) {
        case 1:
          parameter.status = "";
          break;
        case 2:
          parameter.status = 4;
          if (this.role === 1) {
            parameter.status = 2;
          }
          break;
        case 3:
          parameter.status = 5;
          break;
        case 4:
          parameter.status = 3;
          if (this.role === 0) {
            parameter.status = 1;
          }
          break;
        case 5:
          parameter.status = 4;
          if (this.role === 0) {
            parameter.status = 2;
          }
          break;
        default:
          return;
      }

      parameter.status = this.taskStatus ? this.taskStatus : "";
      this.$apis
        .taskList(parameter, this.token, way)
        .then((data) => {
          this.sumPage = data.data.data.last_page * 10;
          this.pageIndex = data.data.data.current_page;
          this.list = [];
          this.list = data.data.data.data;
          const lengthNum = this.list.length;
          for (let i = 0; i < lengthNum; i++) {
            this.unfoldArr.push(false);
          }
          loading.close();
        })
        .catch((err) => {
          console.error(err);
          loading.close();
        });
    },
    /**
     * 列表每一项的全部展开
     * @param {number} index
     */
    ubfoldAlter(index, id) {
      if (!this.list[index].arr) {
        this.taskListTask(index, id);
      } else {
        this.unfoldArr.splice(index, 1, !this.unfoldArr[index]);
        this.allUnfold();
      }
    },
    /**
     * 获取子任务
     * @param {number} index
     * @param {number} id
     */
    taskListTask(index, id, pushUrl) {
      let way = "listFeiMaTask";
      way = this.nav === 1 ? "listFeiMaTask" : "listCommonTask";
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$apis
        .taskListTask(
          {
            project_id: id,
            sort: "asc",
          },
          this.token,
          way
        )
        .then((data) => {

          this.list[index].arr = data.data.data;
          loading.close();
          if (pushUrl) {
            this.$router.push({
              path: "/blockTaskPush",
              query: {
                id: id,
                nav: this.nav,
                name: this.list[index].project_name,
                mobile: this.list[index].mobile,
                push_flag: this.list[index].push_flag,
              },
            });
          } else {
            this.unfoldArr.splice(index, 1, !this.unfoldArr[index]);
            setTimeout(() => {
              this.allUnfold();
            }, 100);
          }
        })
        .catch((err) => {
          console.error(err);
          loading.close();
        });
    },
    /**
     * 全选状态判断
     */
    allUnfold() {
      for (let i = 0; i < this.unfoldArr.length; i++) {
        if (!this.unfoldArr[i]) {
          this.allState = false;
          return false;
        }
        this.allState = true;
      }
    },
    /**
     * 当前页码发生变化时
     */
    currentChange() {
      this.antiShake(this.taskList, 0);
    },
    /**
     * 选择任务
     */
    selectTask(item, type = "project") {
      if (type == "project") {
        this.selectProjectObj.id = item.id;
        this.selectProjectObj.project_name = item.project_name;
      } else {
        this.selectTaskObj.id = item.id;
        this.selectTaskObj.task_name = item.task_name;
      }
    },
    /**
     * 确定选择
     */
    confirmTask() {
      const obj = {};
      if (this.selectType == "project") {
        Object.assign(obj, this.selectProjectObj);
      } else {
        Object.assign(obj, this.selectTaskObj);
      }
      if (obj.id == "") {
        this.utils.$message("请先选择任务", "error");
        return;
      }

      this.$emit("selectTask", obj);
    },
    /**
     * 取消
     */
    cancel() {
      this.$emit("cancelSelect");
    },
    /**
     * 页面防抖函数
     * @param {fundtion} fn
     * @param {object} parameter
     */
    antiShake(fn, parameter) {
      // clearTimeout(time);
      // time = setTimeout(() => {
      fn(parameter);
      // }, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@//assets/iconfont/iconfont.css";

.flying {
  width: 100%;
  height: 694px;
  background: #fff;
  display: flex;
  flex-direction: column;
  .title {
    border-bottom: 1px solid #dde2ee;
    height: 63px;
    line-height: 63px;
    padding-left: 41px;
  }
  .content {
    padding: 0 50px;
    overflow-y: auto;
    flex: 1;
  }
  .foot {
    border-top: 1px solid #dde2dd;
    padding-right: 80px;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.top {
  margin-left: 30px;
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  span {
    color: rgba(0, 0, 0, 0.65);
  }
}

.nav {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 2vh;
  div {
    width: 18.4vw;
    max-width: 304px;
    max-height: 138px;
    height: 8.3vw;
    line-height: 8.3vw;
    background: rgba(255, 255, 255, 1);
    border-radius: 6.4px;
    margin-right: 1.5vw;
    box-sizing: border-box;
    text-align: center;
    font-size: 19.2px;
    span {
      // left: 7.4vw;
    }
  }

  div:nth-child(1) {
    border-bottom: 0px solid #7c6dea;
    box-shadow: 0px 4px 12px 0px rgba(194, 156, 81, 0.23);
  }
  div:nth-child(2) {
    border-bottom: 0px solid #ff4444;
    box-shadow: 0px 4px 12px 0px rgba(216, 115, 115, 0.16);
  }
  div:nth-child(3) {
    border-bottom: 0px solid #9bceb9;
    box-shadow: 0px 4px 12px 0px rgba(155, 206, 185, 0.28);
  }
  div:nth-child(4) {
    border-bottom: 0px solid #36c98d;
    box-shadow: 0px 4px 12px 0px rgba(147, 147, 190, 0.29);
  }

  .image {
    width: 3.96vw;
    height: 3.96vw;
    max-width: 65.6px;
    max-height: 65.6px;
    left: 2.216vw;
  }
  .choice1 {
    background: url("~@/assets/img/nav1.png") no-repeat center center;
    background-size: 100% 100%;
  }
  .choice2 {
    background: url("~@/assets/img/nav2.png") no-repeat center center;
    background-size: 100% 100%;
  }
  .choice3 {
    background: url("~@/assets/img/nav3.png") no-repeat center center;
    background-size: 100% 100%;
  }
  .choice4 {
    background: url("~@/assets/img/nav4.png") no-repeat center center;
    background-size: 100% 100%;
  }
  .choice {
    border-bottom-width: 0.43vw !important;
    background-size: 100% 100%;
  }
}

@mixin scale($scale, $navSize) {
  .scale {
    transform: scale($scale, $scale);
  }

  .posi {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: $navSize;
  }
}

@include scale($scale: 1, $navSize: 26px);

@media screen and(max-width: 1680px) {
  @include scale($scale: 0.95, $navSize: 26px);
}
@media screen and(max-width: 1550px) {
  @include scale($scale: 0.9, $navSize: 22px);
}
@media screen and(max-width: 1450px) {
  @include scale($scale: 0.85, $navSize: 20px);
  .content-cen-name {
    font-size: 18px !important;
  }
}
@media screen and(max-width: 1300px) {
  @include scale($scale: 0.8, $navSize: 18px);
  .table-top > div {
    font-size: 14px !important;
  }
  .content-cen-name {
    font-size: 17px !important;
  }
}

.type-select {
  background-color: #fff;
  margin: 78px 0 106px 30px;
  .task-type {
    width: 320px;
    height: 172px;
    margin-right: 28px;
    cursor: pointer;
  }
}

.select-icon {
  cursor: pointer;
}
.select-icon.small {
  width: 20px;
  height: 20px;
}

.cen {
  width: 100%;
  height: 324.8px;
  position: relative;
  margin: 0 auto;
  background-color: #fff;
  margin-bottom: 2vh;
  .nape {
    position: absolute;
    display: inline-block;
    width: 26%; // 332
    text-align: right;
    span {
      padding-right: 2%;
      color: #838a9d;
      font-size: 14px;
    }
    .import {
      display: inline-block;
      width: 55%;
      height: 40px;
    }
  }
  .nape-one {
    right: 72.3125%;
  }
  .nape-two {
    right: 46.9375%;
  }
  .nape-three {
    width: 34%; // 389
    right: 12%;
    .import {
      display: inline-block;
      width: 60%;
      height: 40px;
    }
  }
  .nape-top-one {
    top: 35.2px;
  }
  .nape-top-two {
    top: 91.2px;
  }
  .nape-top-three {
    top: 147.2px;
  }
  .nape-top-four {
    top: 203.2px;
  }

  .btn {
    position: absolute;
    bottom: 1.2vw;
    width: 112px;
    height: 40px;
    border-radius: 2px;
    border: 0;
    font-size: 16px;
  }

  .inquire {
    color: #fff;
    background-color: #1182fb;
    right: 11vw;
  }
  .reset {
    right: 2.95vw;
    color: #1182fb;
    background-color: #fff;
    border: 1px solid #1182fb;
  }
}

.form-option {
  display: flex;
  justify-content: flex-end;
  .btn {
    bottom: 2.5vw;
    width: 112px;
    height: 40px;
    border-radius: 2px;
    border: 0;
    font-size: 16px;
  }

  .inquire {
    color: #fff;
    background-color: #1182fb;
    margin-right: 22px;
  }
  .reset {
    color: #1182fb;
    background-color: #fff;
    border: 1px solid #1182fb;
  }
}

.dividing-line {
  margin: 39px 0;
  border-bottom: 1px dashed #a4aab9;
}

@media screen and (max-width: 1280px) {
  .bottom-el-select {
    left: 68.5% !important;
  }
}

@media screen and (max-width: 1520px) {
  .bottom-el-select {
    left: 74.8% !important;
  }
}
@media screen and (max-width: 1420px) {
  .bottom-el-select {
    left: 71.8% !important;
  }
}
.bottom {
  margin-bottom: 30px;
  position: relative;
  background-color: #fff;
  .bottom-el-select {
    display: inline-block;
    position: relative;
    top: 20px;
    left: 76%;
    width: 19.75vw;
    height: 0vw;
    border-radius: 2px;
    min-width: 295px;

    .el-select-power {
      width: 10.8vw;
      height: 2.1vw;
      margin-right: 1.3vw;
      min-width: 180px;
    }

    .el-select-button {
      width: 6vw;
      height: 1.8vw;
      min-width: 90px;
      min-height: 36px;
    }
  }

  .all {
    cursor: pointer;
    user-select: none;
    margin: 24px 22px;
    font-size: 0.843vw;
    width: 120px;

    span {
      display: inline-block;
      width: 17px;
      height: 17px;
      line-height: 17px;
      border-radius: 2px;
      border: 1px solid rgba(131, 138, 157, 1);
      text-align: center;
    }
  }

  .table-top {
    display: flex;
    padding: 1.6vw 0;
    border-top: 1px solid #dde2ee;
    div {
      flex-direction: column;
      width: 16.66667%;
      text-align: center;
      cursor: pointer;
      user-select: none;
      font-size: 14px;
      color: #3c4353;
      font-weight: 700;
    }
  }

  .bottom-content {
    line-height: 44.8px;
    border-radius: 2px;
    border: 1px solid rgba(221, 226, 238, 1);
    margin-bottom: 1.5vw;

    .flex-view {
      display: flex;
      div {
        flex-direction: column;
        display: flex;
        justify-content: center;
        width: 16.66667%;
        text-align: center;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
      }
    }

    .unfold-btn {
      width: 13.6px !important;
      height: 13.6px !important;
      line-height: 13.6px;
      border-radius: 2px;
      border: 1px solid rgba(131, 138, 157, 1);
      text-align: center;
      margin: 15.2px 0 15.2px 17.6px;
      cursor: pointer;
      user-select: none;
    }

    .bottom-content-top {
      background: rgba(221, 230, 239, 1);
      color: #3c4353;
      font-size: 0.84vw;
      height: 44.8px;
      .content-top-name {
        width: 20%;
        font-size: 14px;
      }
      .content-top-time {
        width: 28%;
        font-size: 14px;
      }
    }

    .bottom-content-cen {
      height: 11.68vw;
      max-height: 194px;
      min-height: 160px;
      text-align: center;

      .select-box {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 36px;
        }
      }
    }

    .contentCenVacancy {
      height: 4.68vw;
      line-height: 4.68vw;
    }

    .content-cen-name {
      height: 11.68vw;
      max-height: 194px;
      min-height: 160px;
      color: #1182fb;
      font-size: 18px;
    }

    .content-cen-identity {
      padding-left: 39px;
      height: 11.68vw;
      max-height: 194px;
      min-height: 160px;
      box-sizing: content-box;
      div {
        margin-top: 0.56vw;
        width: 100%;
        font-size: 12px;
        height: 19px;
        line-height: 19px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
      }
    }

    .content-cen-btn {
      width: 50px !important;
      text-align: center;
      margin-left: 10.25%;
      font-size: 14px;
      color: #1182fb;
    }
    .content-cen-delete {
      margin-left: 0;
      color: #a4aab9;
    }

    .content-cen-conceal {
      visibility: hidden;
    }

    .bottom-content-bot {
      .content-list {
        padding-left: 2.6vw;
        height: 48px;
        line-height: 48px;
        div {
          font-size: 14px;
        }
      }
      .content-list-title {
        height: 48px;
        line-height: 48px;
        color: rgba(0, 0, 0, 0.65);
        padding-left: 52px;
        border-top: 1px solid rgba(221, 226, 238, 1);
        border-bottom: 1px solid rgba(221, 226, 238, 1);
        font-size: 16px;
        font-weight: 700;
      }
      .state {
        span {
          display: inline-block;
          height: 28px;
          line-height: 28px;
          background: rgba(246, 255, 237, 1);
          border-radius: 2px;
          border: 1px solid #b7eb8f;
          padding: 0 12px;
          color: #52c41a;
          font-size: 12px;
        }
      }

      .instruct-btn {
        width: 14vw;
        line-height: 48px;
        span {
          display: inline-block;
          height: 2.9vh;
          line-height: 2.9vh;
          margin-top: 10px;
        }
        .shp {
          width: 2.7vw;
          border-radius: 2px;
          background: url("~@/assets/img/SHP.png") no-repeat center center;
          background-size: 100% 100%;
        }
        .task {
          width: 4.17vw;
          border-radius: 2px;
          background: url("~@/assets/img/mission_brief.png") no-repeat center center;
          background-size: 100% 100%;
        }
        .original {
          width: 2.7vw;
          border-radius: 2px;
          background: url("~@/assets/img/achievements.png") no-repeat center
            center;
          background-size: 100% 100%;
        }

        .achievement {
          width: 2.7vw;
          border-radius: 2px;
          background: url("~@/assets/img/raw_data.png") no-repeat center center;
          background-size: 100% 100%;
        }
      }

      .redness {
        border-color: #ffa39e !important;
        color: #ffa39e;
      }

      .order-btn {
        span {
          display: block;
          width: 96px;
          height: 36px;
          line-height: 36px;
          border-radius: 2px;
          text-align: center;
          margin: 6px auto 0;
          color: #1182fb;
          border: 1px solid rgba(17, 130, 251, 1);
        }
      }

      .postback-btn {
        color: #1182fb;
        cursor: pointer;
        user-select: none;
        div {
          display: inline-block;
          width: 40px;
        }

        .more {
          position: relative;
        }

        .task-popup {
          position: absolute;
          width: 8.3vw;
          height: 14.4vh;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
            0px 6px 16px 0px rgba(0, 0, 0, 0.08),
            0px 3px 6px -4px rgba(0, 0, 0, 0.12);
          border-radius: 2px;
          top: 36px;
          left: 100%;
          transform: translateX(-50%);
          z-index: 99;
          color: #000;
          div {
            width: 100%;
            height: 3.7vh;
            line-height: 3.7vh;
            text-align: center;
          }
        }
      }
    }
  }
  .bottom-el-pagination {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
