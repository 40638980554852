<template>
  <div>
    <div
      :class="{
        'animated slideInLeft fast drawer': runEnv == 'backend',
        'animated slideInLeft fast leftBar': runEnv == 'portal',
      }"
      v-show="drawer"
    >
      <div class="searchhead">
        <!-- 搜索框 -->
        <el-input
          class="searchInput"
          placeholder="请输入成果名称进行搜索"
          v-model="keyword"
          @keyup.enter.native="
            () => {
              search();
            }
          "
        >
          <i slot="suffix" @click="search" class="iconfont search">&#xe61d;</i>
        </el-input>
        <div class="wordBtn">
          <el-link type="primary" :underline="false" @click="accurateSearch"
            >精确筛选</el-link
          >
        </div>
      </div>
      <!-- 下拉菜单 -->
      <div class="collapse">
        <el-collapse v-model="activeNames" @change="collapseVal">
          <el-collapse-item
            v-for="(item, index) in unitList"
            :name="index"
            :key="index"
          >
            <template slot="title">
              <div class="getItem">
                <span class="titlesty">{{ item.unit_name }}</span>
                <el-switch
                  @change="switchTurnOnUnitAllResult(item, index)"
                  class="switchBar"
                  v-model="item.switch"
                ></el-switch>
              </div>
            </template>
            <el-radio
              v-for="(item, cindex) in unitList[index].acheList"
              :key="cindex"
              v-model="radio"
              label="1"
            >
              <div class="cardswi">
                <div class="tagLeft">
                  <span class="cgname">
                    {{ item.name }}
                    <i
                      v-if="item.project_name != null"
                      class="iconfont lianjie"
                      @click="taskdetails(item)"
                      >&#xe628;</i
                    >
                  </span>
                </div>
                <div class="tagRight">
                  <el-tag class="tagsuc" type="success">{{ item.type }}</el-tag>
                  <i
                    v-if="item.type === 'Tileset'"
                    class="iconfont setheight"
                    @click="setHeightDialog(item)"
                    >&#xe66d;</i
                  >
                  <span v-if="item.type != 'Tileset'" class="zhanwei"></span>
                  <i
                    @click="locateBtn(item)"
                    :style="{ color: activeindex === item.id ? '#1882fb' : '' }"
                    class="iconfont tagsbig"
                    >&#xe61b;</i
                  >
                  <el-switch
                    @change="switchTurnOn(item)"
                    class="switchBar"
                    v-model="item.switch"
                  ></el-switch>
                </div>
              </div>
            </el-radio>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <TaskDetail ref="childtask" :taskData="taskData" />
    <AccurateSearch
      :type="'result'"
      @searchDetail="setSearchForm"
      ref="childsearch"
    />
    <el-dialog
      title="修改海拔"
      :close-on-click-modal="false"
      :visible.sync="changeHeightDialog"
      class="taskBox"
      v-dialogDrag
      :modal="false"
    >
      <el-divider></el-divider>
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="海拔" prop="altitude">
          <el-input v-model="ruleForm.altitude" placeholder="请输入"></el-input
          >米
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="footBtn"
          v-loading="loading"
          type="primary"
          @click="setHeight"
          >确 定</el-button
        >
        <el-button class="footBtn" @click="changeHeightDialog = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>

    <!-- v-dialogDragCustom
        :modal="false"
        :append-to-body="false"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :lock-scroll="false"
        :visible.sync="historyTerminaldialogVisible"
        :title="terminalDialogTitle"
        v-if="historyTerminaldialogVisible" -->
    <el-dialog
      id="resultMessageId"
      title="数据信息"
      @close="closeResultDia"
      :modal="false"
      :append-to-body="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :lock-scroll="false"
      :visible.sync="isShowResultMessage"
      class="resultMessageStyle"
      v-loading="diaLoading"
      v-dialogDragCustom
    >
      <el-divider></el-divider>

      <div class="resultMessageText">
        <div class="resultMessageBox">
          <span class="resultMessageTitle">数据格式：</span
          ><span class="resultMessageData">{{ resultMessgae.type }}</span>
        </div>
        <div class="resultMessageBox">
          <span class="resultMessageTitle">数据名称：</span
          ><span class="resultMessageData">{{ resultMessgae.name }}</span>
        </div>
        <!-- <div class="resultMessageBox">
          <span class="resultMessageTitle">数据编号：</span
          ><span class="resultMessageData">{{ resultMessgae.type }}</span>
        </div> -->
        <div class="resultMessageBox">
          <span class="resultMessageTitle">飞行日期：</span
          ><span class="resultMessageData">{{ resultMessgae.mission_date_at ? resultMessgae.mission_date_at.split(' ')[0] : `无`}}</span>
        </div>
        <div class="resultMessageBox">
          <span class="resultMessageTitle">分辨率：</span
          ><span class="resultMessageData">{{ resultMessgae.resolution !== 0 ? resultMessgae.resolution : `无`}}</span>
        </div>
        <div class="resultMessageBox">
          <span class="resultMessageTitle">成果类型：</span
          ><span id="resultMessageDataResultId" class="resultMessageDataResult" >{{ resultMessgae.have_result_type !== '' ? resultMessgae.have_result_type : `无`}}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TaskDetail from "./taskDetail";
import AccurateSearch from "./accurateSearch";
import { mapState, mapMutations } from "vuex";
import EarthHelper from "@/components/earthOperator/earthHelper";
import Vue from "vue";

export default {
  components: {
    TaskDetail,
    AccurateSearch,
  },
  data() {
    return {
      runEnv: window.runEnv,
      loading: false,
      ruleForm: {
        //设置高度的表单
        id: "",
        altitude: 0,
      },
      changeHeightDialog: false,
      keyword: "",
      direction: "ltr",
      value: false,
      radio: "1",
      type: "",
      unitList: [],
      page: 1,
      unitid: "",
      fileArr: [],
      catalog: [],
      activeindex: "",
      poiArr: [],
      poiList: [],
      per_page: 10000,
      optionResultData: {}, //正在操作的成果数据
      Poikey: [],
      activeNames: [],
      temActiveNames: [],
      taskData: {},
      message: "", //地球实例
      resultData: {},
      isShowResultMessage: false,
      resultMessgae: "",
      diaLoading: false, //弹窗加载状态
    };
  },
  methods: {
    ...mapMutations(["chawidStorage", "chaintrStorage", "currentUrlStorage","setEarthLoading"]),
    // 搜索
    search() {
      this.getResultList(true, {
        name: this.keyword,
        per_page: 999,
      });
    },
    // 精确搜索dialog
    accurateSearch() {
      this.$refs.childsearch.turnon();
    },
    // 精确搜索
    setSearchForm(res) {
      let param = {
        unit_id: res.unit_id, //单位
        name: res.result_name,
        type: res.result_type,
        task_name: res.task_name,
        task_type: res.task_type,
        per_page: 999,
      };
      this.getResultList(true, param);
    },
    // 设置高程dialog
    setHeightDialog(param) {
      this.optionResultData = param;
      this.ruleForm.altitude = param.height ? parseFloat(param.height) : 0;
      this.changeHeightDialog = true;
      this.ruleForm.id = param.id;
      EarthHelper.addEntity({
        id: param.id, //成果id
        type: EarthHelper.getResultType(param.type), //EntityType
        data: {
          url: param.url,
        },
      });
    },
    // 设置高程
    setHeight() {
      let entity = EarthHelper.getEntity(this.ruleForm.id);
      if (entity) {
        entity.tileHeight(this.ruleForm.altitude);
        // 更新list中的记录
        this.$apis
          .saveDataResultHeight(
            { id: this.ruleForm.id, height: this.ruleForm.altitude },
            this.token
          )
          .then((res) => {
            if (res.data.status == 0) {
              this.optionResultData.height = this.ruleForm.altitude;
            }
          });
        this.changeHeightDialog = false;
      }
    },
    // 切换页面时关闭弹窗
    closeDialog(){
      this.isShowResultMessage = false
    },
    //监听左侧菜单
    changedrawer() {
      if (!this.drawer) {
        this.activeNames = [];
        this.getResultList();
      }
      this.chawidStorage(!this.drawer);
      this.chaintrStorage(false);
      this.currentUrlStorage(["首页", "目录查询", "数据目录"]);
      this.getEarthView()
      this.unitList.forEach((item,index)=>{
        this.unitList[index].switch = false
      })
    },
    getEarthView(){
      if (window.iWindow){
        this.message =   EarthHelper.getMessageService();
      if (this.message) {
      } else {
        setTimeout(() => {
          this.getEarthView()
        }, 1000);
      }
      }

    },
    //成果挂接任务dialog
    taskdetails(param) {
      setTimeout(() => {
        this.$refs.childtask.open();
      }, 0);
      this.taskData = param;
    },
    // 初始化
    async init() {
      await this.getUnitList();
      this.getResultList();
      this.getEarthView()

    },
    // 获取巡护列表
    getUnitList() {
      return new Promise((resolve, reject) => {
        this.$apis
          .unitList(
            { per_page: 100000000, get_all: "yes" },
            this.token,
            "?page=" + this.page
          )
          .then((data) => {
            this.unitList = data.data.data.data;
            resolve();
          })
          .catch((err) => {
            console.error(err);
            reject();
          });
      });
    },
    // 获取成果列表
    getResultList(isOpen = false, param = null) {
      if (param === null) {
        param = {
          unit_id: this.unitid,
          name: this.keyword,
          per_page: 999,
        };
      }
      if (this.runEnv == "portal") {
        param.is_portal = 1;
        param.get_all = "get_all";
      }
      this.$apis
        .listResult(param, this.token)
        .then((res) => {
          this.activeNames = [];
          if (res.data.status == 0) {
            let data = res.data.data.data;
            this.unitList.forEach((item, index) => {
              this.unitList[index].acheList = [];
              data.forEach((dataItem) => {
                if (dataItem.unit_id == item.id) {
                  dataItem.switch = false;
                  this.unitList[index].acheList.push(dataItem);
                  if (this.activeNames.indexOf(index) < 0 && isOpen) {
                    this.activeNames.unshift(index);
                  }
                }
              });
            });
          }
        })
        .catch((err) => {});
    },
    closeResultDia(){
      document.getElementById("resultMessageId").style.height = "235px"
    },
    //
    clickResult(entity) {
      this.closeResultDia()
      this.isShowResultMessage = true;
      this.resultMessgae = entity;
      this.diaLoading = true
      setTimeout(()=>{
        let resultMessageId = document.getElementById("resultMessageId");
        let resultMessageDataResultId = document.getElementById("resultMessageDataResultId")
        console.log(resultMessageDataResultId.clientHeight,resultMessageId.clientHeight);
        resultMessageId.style.height = (resultMessageId.clientHeight + resultMessageDataResultId.clientHeight)+'px'
        this.diaLoading = false

      },500)
    },
    // 定位按钮
    locateBtn(param, isLocate = true) {
      param.switch = true;
      this.activeindex = param.id;
      let typeNum = EarthHelper.getResultType(param.type);
      this.$forceUpdate();
      let that = this;
      EarthHelper.addEntity({
        id: param.id, //成果id
        type: typeNum, //EntityType
        data: {
          url: param.url,
        },
      }).then((entity) => {
        if (entity) {
          if (!that.resultData[entity.id]) {
            that.$set(that.resultData, entity.id, param);
          }
          // that.message.setClickFunction("test")
          that.message.setClickFunction(function (entity, id, popEntity) {
            that.clickResult(that.resultData[id]);
          });
          if (isLocate) {
            entity.locate();
          }

          entity.show(true);
          //渲染海拔
          if (param.height != null) {
            entity.tileHeight(param.height);
          }
        }
      });
    },
    // 显隐开关
    switchTurnOn(param) {
      this.$forceUpdate();
      let entity = EarthHelper.getEntity(param.id);
      if (entity) {
        entity.show(param.switch);
      }
    },
    // 关闭左侧菜单
    turnOff() {
      this.chawidStorage(false);
      this.chaintrStorage(false);
    },
    collapseVal(val) {
      // console.log(val, this.activeNames);
    },
    /**
     * 巡护选择显示所有的成果
     * item:成果数据列表
     * index:第几个巡护
     */
    switchTurnOnUnitAllResult(item, index) {
      // this.setEarthLoading(true)
      this.activeNames = [];
      // this.unitList[index].acheList.splice(0,1)
      if (this.unitList[index].switch) {
        item.acheList.forEach((item) => {
          let entity = EarthHelper.getEntity(item.id);
          // console.log(entity);
          if (entity) {
            entity.show(true);
          } else {
            // console.log(item);
            this.locateBtn(item, false);
          }
        });
        // console.log(item.acheList[4]);
        // this.locateBtn(item.acheList[4], false);
        this.message.flyToProvince({
          provinceId: 45,
          provinceName: "广西壮族自治区",
        });
      console.log(new Date());

      } else {
        item.acheList.forEach((item) => {
          let entity = EarthHelper.getEntity(item.id);
          if (entity) {
            entity.show(false);
          }
        });
      }

      // EarthHelper.addListEntity(item.acheList).then(entity=>{
      //   this.message.setClickFunction(function(entity){
      //       // that.clickResult(param)
      //       console.log(entity);
      //     });
      // });
      this.unitList[index].acheList.forEach((item, itemIndex) => {
        this.unitList[index].acheList[itemIndex]["switch"] =
          this.unitList[index].switch;
      });
      // this.setEarthLoading(false)

    },
  },
  created() {},
  beforeDestroy() {
    this.turnOff();
  },
  computed: {
    ...mapState(["user", "token", "intrdrawer", "drawer"]),
  },
  watch: {
    $route: "turnOff",
  },
  mounted() {
    this.init()
    if (this.$route.query.nav == "danwei") {
      this.currentUrlStorage(["首页", "用户管理", "巡护单位"]);
    }
    window.clickResult = this.clickResult;
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/iconfont/iconfont.css";
.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8px;
}
.footBtn {
  margin-left: 20px;
}
/deep/.el-dialog {
  width: 352px;
  /*height: 229px;*/
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-dialog__header {
  padding: 20px 20px 0 20px;
}
/deep/.el-input--medium {
  width: 164px;
}
.lianjie {
  height: 20px;
  width: 20px;
  color: #000000;
}
.lianjie:hover {
  background-color: #dde2ee;
}
.cgname {
  color: #828a9e;
  font-size: 16px;
}
.search {
  cursor: pointer;
  // display: block;
  position: relative;
  top: 25%;
}
.tagsbig {
  color: #a6aab8;
  font-size: 28px;
  margin-right: 4%;
  float: right;
}
.tagsbig:hover {
  color: #828a9e;
}
.zhanwei {
  width: 34px;
  float: right;
  height: 20px;
}
.setheight {
  float: right;
  font-size: 24px;
  color: #a6aab8;
  margin-right: 5px;
}
.setheight:hover {
  color: #828a9e;
}
/deep/.el-collapse-item__header {
  width: 100%;
}
.getItem {
  width: 100%;
  max-height: 50px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.drawer {
  width: 20%;
  min-width: 330px;
  position: absolute;
  left: 250px;
  top: 80px;
  z-index: 999;
  background-color: #fff;
  overflow-y: auto;
  height: calc(100% - 80px);
}
.drawer::-webkit-scrollbar {
  width: 0 !important;
}

.leftBar {
  width: 100%;
  position: absolute;
  z-index: 999;
  background-color: #fff;
  overflow-y: auto;
  height: 100%;
}

/deep/.el-tag.el-tag--success {
  background-color: #e6fffc;
  border-color: #1cd0bd;
  color: #1cd0bd;
  margin-top: 4px;
  border-radius: 2px;
}

.searchhead {
  -webkit-column-width: 100%;
  margin-top: 30px;
  margin-left: 12px;

  .searchInput {
    float: left;
    width: 236px;
  }

  .el-form {
    height: 0px !important;
  }

  .wordBtn {
    float: right;
    margin-right: 22px;
    margin-top: 8px;
  }
}

.titlesty {
  color: #3c4353;
  font-size: 16px;
  margin-left: 30px;
  display: inline-block;
  width: 73%;
  overflow: hidden;
  text-overflow: ellipsis; /* 超出部分显示省略号 */
  white-space: nowrap; /*规定段落中的文本不进行换行 */
}

.collapse {
  margin-top: 85px;
  width: 100%;

  .cardswi {
    width: 100%;
    height: 73px;
    border-top: 1px solid #dde2ee;

    .tagLeft {
      margin-left: 51px;
      margin-top: 12px;
    }

    .tagRight {
      width: 100%;
      float: right;
      margin-bottom: 10px;

      .tagsuc {
        margin-left: 51px;
      }

      .switchBar {
        margin-right: 20px;
        margin-top: 4px;
        float: right;
      }
    }
  }
}
/deep/.el-radio {
  width: 100%;
}
/deep/ .el-radio__input {
  position: absolute;
  top: 29px;
  left: 24px;
}

/deep/ .el-drawer__body {
  overflow-y: auto;
}

/deep/ .el-tag.el-tag--success {
  width: 75px;
}
/deep/.resultMessageStyle {
  width: 336px;
  height: 235px;
  left: calc(95% - 450px);
  top: calc(50% - 343px);
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
      color: #828a9e;
    font-size: 16px;
  .resultMessageText {
    padding-left: 21px;
    font-size: 16px;
    // margin-bottom: 30px;
    height: 100%;
    // font-weight: ;
    .resultMessageTitle {
      display: inline-block;
      width: 80px;
      text-align: left;
      margin-right: 10px;
    }
    .resultMessageBox {
      display: flex;
    }
    .resultMessageData {
      width: 190px;
      height: 36px;
      display: inline-block;
      overflow: hidden;
      // word-wrap:break-word;
      text-overflow: ellipsis; /* 超出部分显示省略号 */
      white-space: nowrap; /*规定段落中的文本不进行换行 */
    }
    .resultMessageDataResult {
      width: 190px;
      display: inline-block;
      word-wrap: break-word;
    }
    .resultType {
      height: 190px;

    }
  }
  .el-divider--horizontal{
    margin: 16px 0;
  }
  .el-dialog {
    box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  }
  > .el-dialog:first-child {
    width: 100%;
    height: 100%;
    margin-top: 0 !important;
    .el-dialog__header {
      // position: absolute;
      right: 0;
      z-index: 998;
      width: 100%;
      padding-top: 10px;
      // height: 70px;
      .chat .el-dialog__headerbtn {
        top: 10px;
      }
    }
    .el-dialog__body {
      padding: 0;
    }
  }
}
</style>
