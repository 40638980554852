<template>
  <!-- 权限管理 -->
  <div class="privile">
    <!-- 具体内容 -->
    <div class="body">
      <div class="cen">
        <div class="cen-left">
          <navTop />
          <div class="taxon">
            <div
              v-for="(item, index) in labelList"
              :key="item.name"
              :class="{ super: userListIndex == index + 1 }"
              @click="roleListNav(item.id)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="configuration-right">
          <div style="height: 1px; width: 100%" />
          <div v-show="userListIndex === 2" class="equipment-btn">
            <div
              :class="{ pitchOn: equipmentBtnNum === 0 }"
              @click="equipmentBtnNum = 0"
            >
              无人机
            </div>
            <div
              :class="{ pitchOn: equipmentBtnNum === 1 }"
              @click="equipmentBtnNum = 1"
            >
              终端设备
            </div>
            <div
              :class="{ pitchOn: equipmentBtnNum === -1 }"
              @click="equipmentBtnNum = -1"
            >
              测绘装备
            </div>
          </div>
          <el-menu
            v-show="userListIndex === 2"
            :default-active="activeIndex"
            class="el-menu-demo configuration-navMenu"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1">设备品牌</el-menu-item>
            <el-menu-item index="2">设备型号</el-menu-item>
            <el-menu-item index="3">设备状态</el-menu-item>
            <el-menu-item index="4" v-show="equipmentBtnNum === 1"
              >关联设备类型</el-menu-item
            >
          </el-menu>
          <div class="form-search">
            <div class="form-search-text">{{ configurationName }}</div>
            <div
              :style="{
                right: Number(activeIndex) === 2 ? '39.6%' : '142px',
                width: Number(activeIndex) === 2 ? '21.6%' : '234px',
              }"
              class="form-search-input"
            >
              <el-input
                v-model="searchData"
                placeholder="请输入内容"
                suffix-icon="el-icon-search"
                @keyup.enter.native="debounce(roleList, 200, userListIndex)"
                @change="debounce(roleList, 200, userListIndex)"
              />
            </div>
            <el-select
              v-if="Number(activeIndex) === 2 && userListIndex === 2"
              v-model="brandId"
              clearable
              placeholder="请选择"
              @change="handleSelect('2')"
              class="facility-select"
            >
              <el-option
                v-for="item in brandArr"
                :key="item.id"
                :value="item.name"
              >
                <span>{{ item.name }}</span>
              </el-option>
            </el-select>
            <div class="form-search-btn" @click="openBtn">{{ addBtnStr }}</div>
          </div>
          <div style="margin-top: 96px">
            <el-table
              :data="tableData"
              style="width: 100%"
              :height="userListIndex === 2 ? ' 500px' : ' 600px'"
            >
              <el-table-column
                prop="id"
                :label="tableDataId"
                width="200px"
                align="center"
              >
                <template slot-scope="scope">
                  <div v-if="scope.row.id !== ''">
                    {{ scope.row.id }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="data"
                :label="tableDataData"
                align="center"
              >
                <template slot-scope="scope">
                  <input
                    class="nameState"
                    type="text"
                    :placeholder="scope.row.data"
                    :disabled="scope.row.id !== editBoxKey"
                    :id="'id' + scope.row.id"
                    :ref="'ref' + scope.row.id"
                    @input="inputJudge(scope)"
                    @blur="cursorDisappears(scope)"
                  />
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="200px">
                <template slot-scope="scope">
                  <el-button
                    v-if="scope.row.id !== ''"
                    size="mini"
                    @click="handleEdit(scope)"
                    >编辑</el-button
                  >
                  <el-button
                    v-if="scope.row.id !== ''"
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
let timeout = setTimeout(() => {}, 100);
let handleSelectKey = "";
let inputScopeStr = "";
let editBoxData = ""; //  input 输入框的内容
export default {
  data() {
    return {
      editBoxKey: "", //  当点需要编辑的的框的key
      labelList: [
        {
          id: 1,
          name: " 目录查询",
        },
        {
          id: 2,
          name: "应急装备",
        },
        {
          id: 3,
          name: "任务下达",
        },
        {
          id: 4,
          name: "用户管理",
        },
        {
          id: 5,
          name: "空域查询",
        },
        {
          id: 6,
          name: "统计分析",
        },
      ], //  用户列表
      userListIndex: 1, //  用户点击的那个
      tableDataId: "品牌编号", //  列表的第一项
      tableDataData: "品牌名称", //  列表的第一项
      addBtnStr: "添加品牌", //  添加按钮的文字
      equipmentBtnNum: 0, //   用户选中的无人机还是队伍终端
      searchData: "", //  搜索框搜索的数据
      activeIndex: "1", //  顶部导航栏选中的那个
      configurationName: "设备品牌",
      tableData: [], //  列表的数据
      brandArr: [], //  应急装备的品牌列表
      brandId: "", //  应急装备时添加型号所选的品牌id
    };
  },
  computed: {
    ...mapState(["navMessage", "currentUrl", "user", "token"]),
  },
  created() {
    this.currentUrlStorage(["首页", "权限配置", "配置管理"]);
    this.navMessageStorage(8);
    this.roleListNav(1);
  },
  mounted() {},
  watch: {
    equipmentBtnNum() {
      this.brandArr = [];
      this.debounce(this.handleSelect, 200, this.activeIndex);
    },
  },
  methods: {
    ...mapMutations(["currentUrlStorage", "navMessageStorage"]),
    /**
     * 防抖函数
     */
    debounce(fn, wait, data, data2) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        fn(data, data2);
      }, wait);
    },
    roleListNav(index) {
      this.debounce(this.roleList, 10, index, true);
    },
    roleList(index, clearAway = false) {
      this.userListIndex = index;
      this.tableData = [];
      this.editBoxKey = "";
      if (clearAway) {
        this.searchData = "";
      }
      if (index !== 2) {
        this.activeIndex = "1";
      }
      switch (index) {
        case 1:
          this.configurationName = "兴趣点类型";
          this.tableDataId = "类型编号";
          this.tableDataData = "兴趣点类型";
          this.addBtnStr = "添加类型";
          this.getInterestType();
          break;
        case 2:
          this.configurationName = "设备品牌";
          this.tableDataId = "品牌编号";
          this.tableDataData = "品牌名称";
          this.addBtnStr = "添加品牌";
          this.tableData = [];
          this.debounce(this.handleSelect, 10, this.activeIndex);
          break;
        case 3:
          this.configurationName = "传感器类型";
          this.tableDataId = "编号";
          this.tableDataData = "名称";
          this.addBtnStr = "添加类型";
          this.debounce(this.taskGetSensorType, 10);
          this.tableData = [];
          break;
        case 4:
          this.configurationName = "用户管理";
          this.tableDataId = "编号";
          this.tableDataData = "名称";
          this.addBtnStr = "添加";
          this.tableData = [];
          break;
        case 5:
          this.configurationName = "空域查询";
          this.tableDataId = "编号";
          this.tableDataData = "名称";
          this.addBtnStr = "添加";
          this.tableData = [];
          break;
        case 6:
          this.configurationName = "统计分析";
          this.tableDataId = "编号";
          this.tableDataData = "名称";
          this.addBtnStr = "添加";
          this.tableData = [];
          break;
        default:
          this.tableData = [];
          return;
      }
    },
    /**
     *	顶部导航栏变化触发
     * @param {string} key
     */
    async handleSelect(key) {
      this.tableData = [];
      this.activeIndex = key;
      if (Number(handleSelectKey) !== Number(key)) {
        handleSelectKey = key;
        this.brandArr = [];
        this.brandId = "";
      }
      if (this.userListIndex === 2) {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        switch (Number(key)) {
          case 1:
            this.configurationName = "设备品牌";
            this.addBtnStr = "添加品牌";
            this.tableDataId = "品牌编号";
            this.tableDataData = "品牌名称";
            this.$apis
              .ListBrand(
                {
                  category_id: 2 - this.equipmentBtnNum,
                  keyword: this.searchData,
                },
                this.token
              )
              .then((data) => {
                loading.close();
                data.data.data.forEach((e, index) => {
                  this.tableData.push({
                    id: e.id,
                    data: e.name,
                  });
                });
              })
              .catch((err) => {
                loading.close();
                console.error(err);
              });
            break;
          case 2:
            this.configurationName = "设备型号";
            this.addBtnStr = "添加型号";
            this.tableDataId = "型号编号";
            this.tableDataData = "型号名称";

            if (this.brandArr.length === 0) {
              let brandData = await this.$apis.ListBrand(
                {
                  category_id: 2 - this.equipmentBtnNum,
                },
                this.token
              );
              this.brandArr = brandData.data.data;
              this.brandId = brandData.data.data[0].name;
            }
            let brandId = "";
            this.brandArr.forEach((e) => {
              if (this.brandId === e.name) brandId = e.id + "";
            });
            this.$apis
              .ListModel(
                {
                  category_id: 2 - this.equipmentBtnNum,
                  brand_id: brandId,
                  keyword: this.searchData,
                },
                this.token
              )
              .then((data) => {
                data.data.data.forEach((e, index) => {
                  this.tableData.push({
                    id: e.id,
                    data: e.name,
                  });
                });
                loading.close();
              })
              .catch((err) => {
                loading.close();
                console.error(err);
              });
            break;
          case 3:
            this.configurationName = "设备状态";
            this.addBtnStr = "添加状态";
            this.tableDataId = "状态编号";
            this.tableDataData = "状态名称";
            this.$apis
              .deviceListStatus(
                {
                  category_id: 2 - this.equipmentBtnNum,
                  keyword: this.searchData,
                },
                this.token
              )
              .then((data) => {
                data.data.data.forEach((e, index) => {
                  this.tableData.push({
                    id: e.id,
                    data: e.key,
                  });
                });
              })
              .catch((err) => {
                console.error(err);
              });

            loading.close();
            break;
          case 4:
            this.configurationName = "关联设备类型";
            this.addBtnStr = "添加关联类型";
            this.tableDataId = "类型编号";
            this.tableDataData = "类型名称";
            this.$apis
              .listEnum(
                {
                  type: "associated_device_type",
                  value: this.searchData
                },
                this.token
              )
              .then((data) => {
                data.data.data.forEach((e, index) => {
                  this.tableData.push({
                    id: e.id,
                    data: e.value,
                  });
                });
              })
              .catch((err) => {
                console.error(err);
              });

            loading.close();
            break;
          default:
            loading.close();
            return;
        }
      }
    },
    /**
     * 添加按钮
     */
    openBtn() {
      let scope = {
        row: {
          id: "",
          data: "",
        },
      };
      this.tableData.push(scope.row);
      setTimeout(() => {
        this.handleEdit(scope);
      }, 0);
    },

    /**
     * input获取光标
     */
    inputFocus(scope) {
      inputScopeStr = scope.row;
    },
    /**
     * input正则判断
     */
    inputJudge(scope) {
      let reg = /^[\u4E00-\u9FA5A-Za-z0-9]+$/;
      let inputStr = this.$refs["ref" + scope.row.id].value;
      if (!reg.test(inputStr[inputStr.length - 1])) {
        this.$message.error("仅支持输入中英文及数子!");
        this.$refs["ref" + scope.row.id].value = "";
      }
    },
    /**
     * input 光标的消失
     */
    cursorDisappears(scope) {
      let message = "确认修改?";
      let fn = this.handleEditAmend;
      if (scope.row.id === "") {
        message = "确认添加";
        fn = this.addBtnClick;
      }
      editBoxData = this.$refs["ref" + scope.row.id].value;
      if (
        editBoxData === "" ||
        (editBoxData === scope.row.data &&
          this.configurationName !== "传感器类型")
      ) {
        return;
      }
      this.$confirm(message, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.editBoxKey = "";
          fn(editBoxData, scope);
        })
        .catch(() => {
          this.$refs["ref" + scope.row.id].value = "";
          editBoxData = "";
          this.editBoxKey = "";
          if (scope.row.id === "") {
            this.tableData.pop();
          }
        });
    },
    /**
     * 表格的编辑
     */
    handleEdit(scope) {
      this.editBoxKey = scope.row.id;
      editBoxData = scope.row.data;
      document.querySelector("#id" + scope.row.id).focus();
      setTimeout(() => {
        document.querySelector("#id" + scope.row.id).focus();
      }, 0);
      document.getElementById("id" + scope.row.id).value = scope.row.data;
      return;
    },
    /**
     * 修改编辑请求
     */
    handleEditAmend(value, scope) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      switch (this.addBtnStr) {
        case "添加品牌":
          this.$apis
            .deviceUpdateBrand(
              {
                name: value,
                id: scope.row.id,
              },
              this.token
            )
            .then((data) => {
              loading.close();
              this.debounce(this.handleSelect, 500, this.activeIndex);
            })
            .catch((err) => {
              loading.close();
              console.error(err);
            });
          break;
        case "添加型号":
          this.$apis
            .deviceUpdateModel(
              {
                name: value,
                id: scope.row.id,
              },
              this.token
            )
            .then((data) => {
              loading.close();
              this.debounce(this.handleSelect, 500, this.activeIndex);
            })
            .catch((err) => {
              loading.close();
              console.error(err);
            });
          break;
        case "添加状态":
          this.$apis
            .deviceUpdateStatus(
              {
                category_id: 2 - this.equipmentBtnNum,
                key: value,
                id: scope.row.id,
              },
              this.token
            )
            .then((data) => {
              loading.close();
              this.debounce(this.handleSelect, 500, this.activeIndex);
            })
            .catch((err) => {
              loading.close();
              console.error(err);
            });

          break;
        case "添加类型":
          switch (this.configurationName) {
            case "兴趣点类型":
              this.dataSaveInterestType("update", value, scope.row.id);
              break;
            case "传感器类型":
              this.$alert(
                `<form id="userlist"><label>非激光</label><input type="radio" valse="1"  checked="checked" name="gender"><label style="margin-left:20px">激光</label><input type="radio" name="gender" valse="2"></form`,
                "请选择",
                {
                  dangerouslyUseHTMLString: true,
                  showCancelButton: true,
                  callback: (action) => {
                    if (action === "confirm") {
                      let type = document.getElementById("userlist").gender[0]
                        .checked
                        ? 1
                        : 2;
                      this.taskSaveSensorType(
                        "update",
                        value,
                        type,
                        scope.row.id
                      );
                      return;
                    }
                    this.$refs["ref" + scope.row.id].value = "";
                    editBoxData = "";
                    this.tableData.pop();
                  },
                }
              );
              break;

            default:
              break;
          }
          loading.close();
          break;
        case "添加关联类型":
          this.$apis
            .editEnum(
              {
                id: scope.row.id,
                value: value,
              },
              this.token
            )
            .then((res) => {
              this.getListEnum();
              loading.close();
            });
          break;
        default:
          loading.close();
          return;
      }
    },
    /**
     * 添加按钮
     * @param {String} value
     */
    addBtnClick(value) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      switch (this.addBtnStr) {
        case "添加品牌":
          this.$apis
            .deviceAddBrand(
              {
                name: value,
                category_id: 2 - this.equipmentBtnNum,
              },
              this.token
            )
            .then((data) => {
              loading.close();
              this.debounce(this.handleSelect, 500, this.activeIndex);
            })
            .catch((err) => {
              loading.close();
              console.error(err);
            });
          break;
        case "添加型号":
          if (this.brandId === "") {
            this.$message({
              showClose: true,
              message: "选择品牌后方能添加型号",
              type: "warning",
            });
            return;
          }
          let brandId = "";
          this.brandArr.forEach((e) => {
            if (this.brandId === e.name) brandId = e.id;
          });
          this.$apis
            .deviceAddModel(
              {
                name: value,
                brand_id: brandId,
                category_id: 2 - this.equipmentBtnNum,
              },
              this.token
            )
            .then((data) => {
              loading.close();
              this.debounce(this.handleSelect, 500, this.activeIndex);
            })
            .catch((err) => {
              loading.close();
              console.error(err);
            });
          break;
        case "添加状态":
          loading.close();
          let str = value;
          this.$prompt("请输入所需添加的状态的状态值", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            inputPattern: /^[0-9]+$/,
            inputErrorMessage: "内容格式不正确",
          })
            .then(({ value }) => {
              this.deviceAddStatus(str, value);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "取消添加",
              });
            });
          break;
        case "添加类型":
          loading.close();
          switch (this.configurationName) {
            case "兴趣点类型":
              this.dataSaveInterestType("new", value);
              break;
            case "传感器类型":
              this.$alert(
                `<form id="userlist"><label>非激光</label><input type="radio" valse="1"  checked="checked" name="gender"><label style="margin-left:20px">激光</label><input type="radio" name="gender" valse="2"></form`,
                "请选择",
                {
                  dangerouslyUseHTMLString: true,
                  showCancelButton: true,
                  callback: (action) => {
                    if (action === "confirm") {
                      let type = document.getElementById("userlist").gender[0]
                        .checked
                        ? 1
                        : 2;
                      this.taskSaveSensorType("new", value, type);
                      return;
                    }
                    this.$refs["ref" + scope.row.id].value = "";
                    editBoxData = "";
                    this.tableData.pop();
                  },
                }
              );
              break;

            default:
              break;
          }
          break;
        case "添加关联类型":
          this.$apis
            .addEnum(
              {
                type: "associated_device_type",
                value: value,
              },
              this.token
            )
            .then((res) => {
              this.getListEnum();
              loading.close();
            });
          break;
        default:
          loading.close();
          return;
      }
    },
    getListEnum() {
      this.tableData = [];
      this.$apis
        .listEnum(
          {
            type: "associated_device_type",
          },
          this.token
        )
        .then((data) => {
          data.data.data.forEach((e, index) => {
            this.tableData.push({
              id: e.id,
              data: e.value,
            });
          });
        });
    },
    /**
     * 表格的删除
     */
    handleDelete(scope) {
      this.$confirm("此操作将永久删除该内容, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          switch (this.addBtnStr) {
            case "添加品牌":
              this.$apis
                .deviceDeleteBrand(
                  {
                    id: scope.row.id,
                  },
                  this.token
                )
                .then((data) => {
                  loading.close();
                  this.debounce(this.handleSelect, 500, this.activeIndex);
                })
                .catch((err) => {
                  loading.close();
                  console.error(err);
                });
              break;
            case "添加型号":
              this.$apis
                .deviceDeleteModel(
                  {
                    id: scope.row.id,
                  },
                  this.token
                )
                .then((data) => {
                  loading.close();
                  this.debounce(this.handleSelect, 500, this.activeIndex);
                })
                .catch((err) => {
                  loading.close();
                  console.error(err);
                });
              break;
            case "添加状态":
              this.$apis
                .deviceDeleteStatus(
                  {
                    category_id: 2 - this.equipmentBtnNum,
                    id: scope.row.id,
                  },
                  this.token
                )
                .then((data) => {
                  loading.close();
                  this.debounce(this.handleSelect, 500, this.activeIndex);
                })
                .catch((err) => {
                  loading.close();
                  console.error(err);
                });
              break;
            case "添加类型":
              loading.close();

              switch (this.configurationName) {
                case "兴趣点类型":
                  this.dataDelInterestType(scope.row.id);
                  break;
                case "传感器类型":
                  this.taskDelSensorType(scope.row.id);
                  break;

                default:
                  break;
              }
              break;
             case "添加关联类型":
          this.$apis
            .delEnum(
              {
                id: scope.row.id
              },
              this.token
            )
            .then((res) => {
              this.getListEnum();
              loading.close();
            });
          break;
            default:
              loading.close();
              return;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /**
     * 获取传感器类型
     */
    taskGetSensorType() {
      this.$apis
        .taskGetSensorType(
          {
            keyword: this.searchData,
          },
          this.token
        )
        .then((data) => {
          data.data.data.forEach((e, index) => {
            this.tableData.push({
              id: e.id,
              data: e.name,
              type: e.type,
            });
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 保存传感器类型
     */
    taskSaveSensorType(action, name, type, id) {
      let obj = {
        action: action,
        name: name,
        type: type,
      };
      if (action === "update") {
        obj.id = id;
      }
      this.$apis
        .taskSaveSensorType(obj, this.token)
        .then((data) => {
          if (data.data.status === 0) {
            this.httpSucceed(data.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 删除传感器类型
     */
    taskDelSensorType(id) {
      this.$apis
        .taskDelSensorType({ id: id }, this.token)
        .then((data) => {
          if (data.data.status === 0) {
            this.httpSucceed(data.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 获取兴趣点类型
     */
    getInterestType() {
      this.$apis
        .getInterestType(
          {
            keyword: this.searchData,
          },
          this.token
        )
        .then((data) => {
          this.tableData = [];
          data.data.data.forEach((e, index) => {
            this.tableData.push({
              id: e.id,
              data: e.data_interest_type,
            });
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * @param {string} action
     * @param {string} data_interest_type
     * @param {Number} id
     * 保存兴趣点类型
     */
    dataSaveInterestType(action, data_interest_type, id) {
      const obj = {
        action: action,
        data_interest_type: data_interest_type,
      };
      if (action === "update") {
        obj.id = id;
      }
      this.$apis
        .dataSaveInterestType(obj, this.token)
        .then((data) => {
          if (data.data.status === 0) {
            this.httpSucceed(data.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * @param {Number} id
     * 删除兴趣点类型
     */
    dataDelInterestType(id) {
      this.$apis
        .dataDelInterestType(
          {
            id: id,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status === 0) {
            this.httpSucceed(data.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 添加设备状态
     */
    deviceAddStatus(str, index) {
      this.$apis
        .deviceAddStatus(
          {
            category_id: 2 - this.equipmentBtnNum,
            key: str,
            value: index, //  状态值
          },
          this.token
        )
        .then((data) => {
          this.debounce(this.handleSelect, 500, this.activeIndex);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    httpSucceed(message) {
      this.utils.$message(message);
      this.roleList(this.userListIndex, true);
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
.privile {
  padding-top: 40px;
  background-color: rgba(246, 247, 249, 1);
  width: 100%;
  height: 100%;
}
.body {
  width: 100%;
  margin-top: -2.54%;
  padding-bottom: 37px;

  .cen {
    position: relative;
  }

  .cen-left {
    position: absolute;
    top: 0;
    left: 1.61%;
    width: 18.75%;
    height: 89.17%;
    .cen-left-top {
      height: 56px;
      line-height: 56px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);

      .topName {
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .taxon {
      width: 100%;
      height: 88vh;
      background: rgba(235, 236, 239, 1);
      margin-left: 9px;
      box-sizing: border-box;
      font-size: 14px;
      div {
        line-height: 64px;
        border-bottom: 1px solid #a4aab9;
        padding-left: 16.09%;
        cursor: pointer;
      }

      .super {
        background-color: #dde2ee;
      }
    }
  }

  .configuration-right {
    width: 72.831%;
    height: 82vh;
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    margin-left: 21.93%;
    padding: 0 4.8% 40px 2.73%;
    position: relative;
    top: 50px;
    background-color: #fff;
    font-size: 14px;

    .equipment-btn {
      display: flex;
      margin-top: 36px;
      div {
        width: 6.25vw;
        height: 3.7vh;
        line-height: 3.7vh;
        text-align: center;
        border-radius: 0px 2px 2px 0px;
        border: 1px solid #a6aab8;
        color: #a6aab8;
        cursor: pointer;
      }
      div:nth-child(1) {
        border-right: 1px solid #1182fb !important;
      }
      div:nth-child(2) {
        border-left: 0 !important;
      }
      div:nth-child(3) {
        border-left: 0 !important;
      }
      .pitchOn {
        border: 1px solid #1182fb !important;
        color: #1182fb !important;
      }
    }

    .configuration-navMenu {
      width: 100%;
      margin-top: 32px;
    }

    .form-search {
      margin-top: 35px;
      position: relative;
      .form-search-text {
        color: rgba(0, 0, 0, 0.65);
        line-height: 19px;
        position: absolute;
        // width: 56px;
        height: 40px;
        line-height: 40px;
        text-align: left;
        left: 0;
      }
      .form-search-input {
        position: absolute;
        width: 234px;
        height: 40px;
        right: 142px;
      }

      .form-search-btn {
        width: 96px;
        height: 36px;
        line-height: 36px;
        border-radius: 2px;
        border: 1px solid #1182fb;
        text-align: center;
        color: #1182fb;
        position: absolute;
        right: 0;
        cursor: pointer;
      }

      .facility-select {
        position: absolute;
        right: 16%;
        width: 21%;
      }
    }
  }
}

.nameState {
  border: 0;
  text-align: center;
  line-height: 32px;
  height: 32px;
  color: #9e9e9e;
  box-sizing: border-box;
}
.nameState:focus {
  outline: none;
  border: 1px solid #1782fb !important;
}
</style>
