<template>
  <div class="uavBody">
    <navTop />
    <div class="topBar">
      <div class="statIng">
        <div class="countNum">
          <p class="titleNum">今日已推送任务</p>
          <span class="countOne">{{
            taskDataList.report_home_today_pushed
          }}</span>
          <span class="danwei">个</span>
        </div>
        <div class="growthrate">
          <span
            v-if="
              taskDataList.report_home_today_pushed == 0 &&
              taskDataList.report_home_yesterday_pushed != 0
            "
            class="growthNum"
            >-100%</span
          >
          <span
            v-if="
              taskDataList.report_home_yesterday_pushed == 0 &&
              taskDataList.report_home_today_pushed != 0
            "
            class="growthNum"
            >+100%</span
          >
          <span
            v-if="
              taskDataList.report_home_yesterday_pushed == 0 &&
              taskDataList.report_home_today_pushed == 0
            "
            class="growthNum"
            >+100%</span
          >
          <span
            v-if="
              taskDataList.report_home_today_pushed != 0 &&
              taskDataList.report_home_yesterday_pushed != 0 &&
              yesterday_push
            "
            class="growthNum"
            >{{ yesterday_push > 0 ? "+" : "-"
            }}{{ yesterday_push.toFixed(2) }}%</span
          >
          <p class="growthWord">相较昨日</p>
        </div>
        <div class="imgBox">
          <img class="topBgcImg" src="../../assets/img/bg1.png" alt />
        </div>
      </div>
      <div class="statIng">
        <div class="countNum">
          <p class="titleNum">今日未推送任务</p>
          <span class="countTwo">{{
            taskDataList.report_home_today_not_pushed
          }}</span>
          <span class="danweiTwo">个</span>
        </div>
        <div class="growthrate">
          <span
            v-if="
              taskDataList.report_home_today_not_pushed == 0 &&
              taskDataList.report_home_yesterday_not_pushed != 0
            "
            class="growthNum"
            >-100%</span
          >
          <span
            v-if="
              taskDataList.report_home_yesterday_not_pushed == 0 &&
              taskDataList.report_home_today_not_pushed != 0
            "
            class="growthNum"
            >+100%</span
          >
          <span
            v-if="
              taskDataList.report_home_yesterday_not_pushed == 0 &&
              taskDataList.report_home_today_not_pushed == 0
            "
            class="growthNum"
            >+100%</span
          >
          <span
            v-if="
              taskDataList.report_home_today_not_pushed != 0 &&
              taskDataList.report_home_yesterday_not_pushed != 0 &&
              yesterday_not_push
            "
            class="growthNum"
            >{{ yesterday_not_push > 0 ? "+" : "-"
            }}{{ yesterday_not_push.toFixed(2) }}%</span
          >
          <p class="growthWord">相较昨日</p>
        </div>
        <div class="imgBox">
          <img class="topBgcImg" src="../../assets/img/bg2.png" alt />
        </div>
      </div>
      <div class="statIng">
        <div class="countNum">
          <p class="titleNum">历史已推送任务</p>
          <span class="countThr">{{
            taskDataList.report_home_history_pushed_count
          }}</span>
          <span class="danweiThr">个</span>
        </div>
        <div class="imgBox">
          <img class="topBgcImg" src="../../assets/img/bg3.png" alt />
        </div>
      </div>
      <div class="statIng">
        <div class="countNum">
          <p class="titleNum">历史未推送任务</p>
          <span class="countFour">{{
            taskDataList.report_home_history_not_pushed_count
          }}</span>
          <span class="danweiFour">个</span>
        </div>
        <div class="imgBox">
          <img class="topBgcImg" src="../../assets/img/bg4.png" alt />
        </div>
      </div>
    </div>
    <div class="bodyBar">
      <div class="leftBar">
        <div class="titleBar">统计分析</div>
        <el-divider></el-divider>
        <div class="middleBar">
          <div class="totalBar">
            <span class="wordBtn totalTitle">总分任务</span>
            <span
              class="wordBtn zongtask"
              @click="zongTask"
              :style="{ color: zongsty }"
              >总任务</span
            >
            <span
              class="wordBtn numtask"
              @click="numTask"
              :style="{ color: numsty }"
              >分任务</span
            >
          </div>
          <div class="totalBar">
            <span class="wordBtn totalTitle">任务类型</span>
            <span
              class="wordBtn alltask"
              @click="allTaskOne"
              :style="{ color: allstyOne }"
              >全部任务</span
            >
            <span
              class="wordBtn zongtask"
              @click="zongTaskOne"
              :style="{ color: zongstyOne }"
              >航线任务</span
            >
            <span
              class="wordBtn numtask"
              @click="numTaskOne"
              :style="{ color: numstyOne }"
              >通用任务</span
            >
          </div>
          <div class="totalBar">
            <span class="wordBtn totalTitle">推送类型</span>
            <span
              class="wordBtn alltask"
              @click="allTaskTwo"
              :style="{ color: allstyTwo }"
              >全部任务</span
            >
            <span
              class="wordBtn zongtask"
              @click="zongTaskTwo"
              :style="{ color: zongstyTwo }"
              >指派任务</span
            >
            <span
              class="wordBtn numtask"
              @click="numTaskTwo"
              :style="{ color: numstyTwo }"
              >群发任务</span
            >
          </div>
          <div class="totalBar">
            <span class="wordBtn wordBtnTime totalTitle">推送时间</span>
            <el-button @click="getday" class="roundBtn" round plain
              >今日</el-button
            >
            <el-button @click="getweek" class="roundBtn" round plain
              >本周</el-button
            >
            <el-button @click="getmonth" class="roundBtn" round plain
              >本月</el-button
            >
          </div>
          <div class="block">
            <el-date-picker
              v-model="DateRange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getDateRange"
            ></el-date-picker>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="progress">
          <div class="gressline">
            <div class="numCount">
              <span
                v-if="this.project_type === 1"
                style="color: #3c4353; margin-right: 10px"
                >{{ taskDataList.project_or_task_count_1 }}</span
              >
              <span
                v-if="this.project_type === 2"
                style="color: #3c4353; margin-right: 10px"
                >{{ taskDataList.project_or_task_count_2 }}</span
              >
              |
              <span
                style="
                  font-size: 12px;
                  margin-left: 10px;
                  line-height: 20px;
                  color: #a6aab8;
                "
                >{{ taskDataList.project_or_task_total_count }}个</span
              >
            </div>
            <span class="progessWord">任务个数</span>
            <el-progress
              v-if="this.taskNum"
              class="progreBar first"
              :show-text="false"
              :percentage="this.taskNum"
            ></el-progress>
          </div>
          <div class="gressline">
            <div class="numCount">
              <span
                v-if="this.project_type === 1"
                style="color: #3c4353; margin-right: 10px"
                >{{ taskDataList.push_unit_count_1 }}</span
              >
              <span
                v-if="this.project_type === 2"
                style="color: #3c4353; margin-right: 10px"
                >{{ taskDataList.push_unit_count_2 }}</span
              >
              |
              <span
                style="
                  font-size: 12px;
                  margin-left: 10px;
                  line-height: 20px;
                  color: #a6aab8;
                "
                >{{ taskDataList.push_unit_total_count }}个</span
              >
            </div>
            <span class="progessWord">推送巡护单位</span>
            <el-progress
              v-if="this.terminal_unit"
              class="progreBar"
              :show-text="false"
              :percentage="terminal_unit"
              color="#C75CDF"
              :format="format"
            ></el-progress>
          </div>
          <div class="gressline">
            <div class="numCount">
              <span
                v-if="this.project_type === 1"
                style="color: #3c4353; margin-right: 10px"
                >{{ taskDataList.push_user_count_1 }}</span
              >
              <span
                v-if="this.project_type === 2"
                style="color: #3c4353; margin-right: 10px"
                >{{ taskDataList.push_user_count_2 }}</span
              >
              |
              <span
                style="
                  font-size: 12px;
                  margin-left: 10px;
                  line-height: 20px;
                  color: #a6aab8;
                "
                >{{ taskDataList.push_user_total_count }}个</span
              >
            </div>
            <span class="progessWord">推送终端用户</span>
            <el-progress
              v-if="this.terminal_user"
              class="progreBar"
              :show-text="false"
              :percentage="terminal_user"
              color="#15BEA5"
            ></el-progress>
          </div>
          <div class="gressline">
            <div class="numCount">
              <span
                v-if="this.project_type === 1"
                style="color: #3c4353; margin-right: 10px"
                >{{ taskDataList.confirm_unit_count_1 }}</span
              >
              <span
                v-if="this.project_type === 2"
                style="color: #3c4353; margin-right: 10px"
                >{{ taskDataList.confirm_unit_count_2 }}</span
              >
              |
              <span
                style="
                  font-size: 12px;
                  margin-left: 10px;
                  line-height: 20px;
                  color: #a6aab8;
                "
                >{{ taskDataList.confirm_unit_total_count }}个</span
              >
            </div>
            <span class="progessWord">接单巡护单位</span>
            <el-progress
              v-if="this.receiving_unit"
              class="progreBar"
              :show-text="false"
              :percentage="receiving_unit"
              color="#FF5C5E"
            ></el-progress>
          </div>
          <div class="gressline">
            <div class="numCount">
              <span
                v-if="this.project_type === 1"
                style="color: #3c4353; margin-right: 10px"
                >{{ taskDataList.confirm_user_count_1 }}</span
              >
              <span
                v-if="this.project_type === 2"
                style="color: #3c4353; margin-right: 10px"
                >{{ taskDataList.confirm_user_count_2 }}</span
              >
              |
              <span
                style="
                  font-size: 12px;
                  margin-left: 10px;
                  line-height: 20px;
                  color: #a6aab8;
                "
                >{{ taskDataList.confirm_user_total_count }}个</span
              >
            </div>
            <span class="progessWord">接单巡护用户</span>
            <el-progress
              v-if="this.receiving_user"
              class="progreBar"
              :show-text="false"
              :percentage="receiving_user"
              color="#F5BB4B"
            ></el-progress>
          </div>
        </div>
      </div>
      <div class="rightBar">
        <div class="rTopBar">
          <div class="topTitle">
            <span class="titleWord">月推送任务</span>
            <div class="blocks">
              <el-date-picker
                v-model="barchart"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="getBarchart"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </div>
          </div>
          <el-divider></el-divider>
          <!-- echart -->
          <div id="myChart"></div>
        </div>
        <div class="rBotBar">
          <div class="topTitle">
            <span class="titleWord">任务接单时长类别占比</span>
            <div class="btnGroup">
              <el-button-group>
                <el-button type="primary" plain>全部任务</el-button>
                <el-button type="primary" plain>航线任务</el-button>
                <el-button type="primary" plain>通用任务</el-button>
              </el-button-group>
            </div>
          </div>
          <el-divider></el-divider>
          <div id="sector"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    let that = this;
    return {
      dateRang: [new Date(), ""],
      pickerOptions: {
        disabledDate(time) {
          let startyear = that.dateRang[0].getFullYear() - 1;
          let endYear = that.dateRang[0].getFullYear() + 1;

          let endDate =
            endYear +
            "-" +
            (that.dateRang[0].getMonth() + 1) +
            "-" +
            that.dateRang[0].getDate();
          let startDate =
            startyear +
            "-" +
            (that.dateRang[0].getMonth() + 1) +
            "-" +
            that.dateRang[0].getDate();
          let _endDate = new Date(endDate);
          let _startDate = new Date(startDate);
          //即大于开始时间小于结束时间
          return (
            time.getTime() > _endDate.getTime() ||
            time.getTime() < _startDate.getTime()
          );
        },
        onPick({ minDate, maxDate }) {
          //重新设置开始时间
          that.dateRang[0] = minDate;
        },
      },
      zongsty: "#3C4353",
      numsty: "#3C4353",
      allstyOne: "#3C4353",
      zongstyOne: "#3C4353",
      numstyOne: "#3C4353",
      allstyTwo: "#3C4353",
      zongstyTwo: "#3C4353",
      numstyTwo: "#3C4353",
      DateRange: [],
      barchart: [],
      taskDataList: [],
      yesterday_push: "",
      yesterday_not_push: "",
      type: "project",
      project_type: 1,
      push_type: 1,
      start_datetime: "",
      end_datetime: "",
      year: "",
      month: "",
      date: "",
      week: "",
      taskNum: 0,
      terminal_unit: 0,
      terminal_user: 0,
      receiving_unit: 0,
      receiving_user: 0,
      month_data_start_date: "",
      month_data_end_date: "",
    };
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    zongTask() {
      this.zongsty = this.zongsty == "#3C4353" ? "#1182FB" : "#3C4353";
      this.numsty = "#3C4353";
      this.type = "project";
      this.getTaskData(this.type);
    },
    numTask() {
      this.numsty = this.numsty == "#3C4353" ? "#1182FB" : "#3C4353";
      this.zongsty = "#3C4353";
      this.type = "task";
      this.getTaskData(this.type);
    },
    allTaskOne() {
      this.allstyOne = this.allstyOne == "#3C4353" ? "#1182FB" : "#3C4353";
      this.zongstyOne = "#3C4353";
      this.numstyOne = "#3C4353";
    },
    zongTaskOne() {
      this.zongstyOne = this.zongstyOne == "#3C4353" ? "#1182FB" : "#3C4353";
      this.allstyOne = "#3C4353";
      this.numstyOne = "#3C4353";
      this.project_type = 1;
      this.getTaskData(this.type, this.project_type);
    },
    numTaskOne() {
      this.numstyOne = this.numstyOne == "#3C4353" ? "#1182FB" : "#3C4353";
      this.allstyOne = "#3C4353";
      this.zongstyOne = "#3C4353";
      this.project_type = 2;
      this.getTaskData(this.type, this.project_type);
    },
    allTaskTwo() {
      this.allstyTwo = this.allstyTwo == "#3C4353" ? "#1182FB" : "#3C4353";
      this.zongstyTwo = "#3C4353";
      this.numstyTwo = "#3C4353";
    },
    zongTaskTwo() {
      this.zongstyTwo = this.zongstyTwo == "#3C4353" ? "#1182FB" : "#3C4353";
      this.allstyTwo = "#3C4353";
      this.numstyTwo = "#3C4353";
      this.push_type = 1;
      this.getTaskData(this.type, this.project_type, this.push_type);
    },
    numTaskTwo() {
      this.numstyTwo = this.numstyTwo == "#3C4353" ? "#1182FB" : "#3C4353";
      this.zongstyTwo = "#3C4353";
      this.allstyTwo = "#3C4353";
      this.push_type = 2;
      this.getTaskData(this.type, this.project_type, this.push_type);
    },
    getday() {
      let nowDate = new Date();
      let myDate = new Date();
      //获取当前年
      this.year = myDate.getFullYear();
      //获取当前月
      this.month = myDate.getMonth() + 1;
      //获取当前日
      this.date = myDate.getDate();
      this.month_data_start_date = this.year + "-" + "01-01";
      this.month_data_end_date = this.year + "-" + "12-31";
      //获取当前时间
      this.start_datetime =
        this.year +
        "-" +
        this.convert(this.month) +
        "-" +
        this.convert(this.date) +
        " " +
        "00:00:00";
      this.end_datetime =
        this.year +
        "-" +
        this.convert(this.month) +
        "-" +
        this.convert(this.date) +
        " " +
        "23:59:59";
      this.getTaskData(
        this.type,
        this.project_type,
        this.push_type,
        this.start_datetime,
        this.end_datetime
      );
    },
    //日期时间处理
    convert(val) {
      return val < 10 ? "0" + val : val;
    },
    getweek() {
      this.getMonday("s", 0);
      this.getMonday("e", 0);
      this.getTaskData(
        this.type,
        this.project_type,
        this.push_type,
        this.start_datetime,
        this.end_datetime
      );
    },
    getMonday(type, dates) {
      var now = new Date();
      var nowTime = now.getTime();
      var day = now.getDay();
      var longTime = 24 * 60 * 60 * 1000;
      var n = longTime * 7 * (dates || 0);
      if (type == "s") {
        var dd = nowTime - (day - 1) * longTime + n;
      }
      if (type == "e") {
        var dd = nowTime + (7 - day) * longTime + n;
      }
      dd = new Date(dd);
      var y = dd.getFullYear();
      var m = dd.getMonth() + 1;
      var d = dd.getDate();
      m = m < 10 ? "0" + m : m;
      d = d < 10 ? "0" + d : d;
      if (type == "s") {
        var day = y + "-" + m + "-" + d + " " + "00:00:00";
        this.start_datetime = day;
      }
      if (type == "e") {
        var day = y + "-" + m + "-" + d + " " + "23:59:59";
        this.end_datetime = day;
      }
    },
    getmonth() {
      this.getMonth("s", 0);
      this.getMonth("e", 0);
      this.getTaskData(
        this.type,
        this.project_type,
        this.push_type,
        this.start_datetime,
        this.end_datetime
      );
    },
    getMonth(type, months) {
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth() + 1;
      if (Math.abs(months) > 12) {
        months = months % 12;
      }
      if (months != 0) {
        if (month + months > 12) {
          year++;
          month = (month + months) % 12;
        } else if (month + months < 1) {
          year--;
          month = 12 + month + months;
        } else {
          month = month + months;
        }
      }
      month = month < 10 ? "0" + month : month;
      var date = d.getDate();
      var firstday = year + "-" + month + "-" + "01" + " " + "00:00:00";
      var lastday = "";
      if (
        month == "01" ||
        month == "03" ||
        month == "05" ||
        month == "07" ||
        month == "08" ||
        month == "10" ||
        month == "12"
      ) {
        lastday = year + "-" + month + "-" + 31 + " " + "23:59:59";
      } else if (month == "02") {
        if (
          (year % 4 == 0 && year % 100 != 0) ||
          (year % 100 == 0 && year % 400 == 0)
        ) {
          lastday = year + "-" + month + "-" + 29;
        } else {
          lastday = year + "-" + month + "-" + 28;
        }
      } else {
        lastday = year + "-" + month + "-" + 30;
      }
      var day = "";
      if (type == "s") {
        day = firstday;
        this.start_datetime = day;
      } else {
        day = lastday;
        this.end_datetime = day;
      }
    },
    format(percentage) {
      return percentage === 100 ? "" : `${percentage}%`;
    },
    getDateRange() {
      this.start_datetime = this.DateRange[0] + " " + "00:00:00";
      this.end_datetime = this.DateRange[1] + " " + "23:59:59";
      this.getTaskData(
        this.type,
        this.project_type,
        this.push_type,
        this.start_datetime,
        this.end_datetime,
        this.month_data_start_date,
        this.month_data_end_date
      );
    },
    getBarchart() {
      this.month_data_start_date = this.barchart[0];
      this.month_data_end_date = this.barchart[1];
      this.getTaskData(
        this.type,
        this.project_type,
        this.push_type,
        this.start_datetime,
        this.end_datetime,
        this.month_data_start_date,
        this.month_data_end_date
      );
      this.drawLine();
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.querySelector("#myChart"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{a}:  <br/>      {c}个",
        },
        legend: {
          data: ["航线任务", "通用任务"],
          textStyle: {
            color: "#ccc",
          },
        },
        xAxis: [
          {
            data: [],
            axisLabel: {
              interval: 0,
              show: false,
            },
          },
          {
            data: [],
            axisLabel: {
              interval: 0,
              show: true,
              rotate: 38,
              fontSize: 11,
            },
            axisLine: {
              show: false,
              lineStyle: {
                // color: "#10899A"
              },
            },
            axisTick: {
              show: false,
            },
            gridIndex: 1,
          },
        ],
        yAxis: [
          {
            splitLine: {
              //网格线
              lineStyle: {
                type: "dotted：虚线", //设置网格线类型 dotted：虚线   solid:实线
              },
              show: true, //隐藏或显示
            },
            axisLine: {
              lineStyle: {
                color: "#69789E",
              },
            },
          },
          {
            type: "value",
            show: true,
          },
          {
            gridIndex: 1,
            show: false,
          },
        ],
        grid: [
          {
            width: "80%",
            left: "10%",
          },
          {
            left: "13.3%",
            width: "80%",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 8,
            data: [],
            itemStyle: {
              barBorderRadius: 10,
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#1CD0BD", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#1CD0BD", // 100% 处的颜色
                  },
                ],
              },
            },
          },
          {
            type: "bar",
            barWidth: 8,
            data: [],
            itemStyle: {
              barBorderRadius: 10,
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#1980FF", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#1980FF", // 100% 处的颜色
                  },
                ],
              },
            },
          },
        ],
      });
      this.$apis
        .getTaskData(
          {
            type: this.type,
            project_type: this.project_type,
            push_type: this.push_type,
            start_datetime: this.start_datetime,
            end_datetime: this.end_datetime,
            month_data_start_date: this.month_data_start_date,
            month_data_end_date: this.month_data_end_date,
          },
          this.token
        )
        .then((data) => {
          let tyArr = JSON.parse(
            data.data.data.report_home_12_month.common_data
          );
          let fmArr = JSON.parse(
            data.data.data.report_home_12_month.feima_data
          );
          let monthArr = JSON.parse(
            data.data.data.report_home_12_month.month_data
          );
          myChart.setOption({
            xAxis: [
              {},
              {
                data: monthArr,
              },
            ],
            series: [
              {
                name: "航线任务",
                data: fmArr,
              },
              {
                name: "通用任务",
                data: tyArr,
              },
            ],
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    sector() {
      this.$apis
        .getTaskData(
          {
            type: this.type,
            project_type: this.project_type,
            push_type: this.push_type,
            start_datetime: this.start_datetime,
            end_datetime: this.end_datetime,
            month_data_start_date: this.month_data_start_date,
            month_data_end_date: this.month_data_end_date,
          },
          this.token
        )
        .then((data) => {
          // 基于准备好的dom，初始化echarts实例
          let sector = this.$echarts.init(document.querySelector("#sector"));
          const colorList = [
            "#47A2FF ",
            "#53C8D1",
            "#59CB74",
            "#FBD444",
            "#7F6AAD",
            "#585247",
          ];
          // 绘制图表
          let option = {
            title: {
              text: "任务接单",
              subtext: data.data.data.project_or_task_total_count + "个",
              textStyle: {
                fontSize: 12,
                color: "#838A9D",
                lineHeight: 5,
              },
              subtextStyle: {
                fontSize: 28,
                color: "#333",
              },
              textAlign: "center",
              left: "24.8%",
              top: "40%",
            },
            tooltip: {
              trigger: "item",
              formatter: "{a}{b}  {d}%      {c}个",
            },
            legend: {
              type: "scroll",
              orient: "vertical",
              right: "15%",
              top: "center",
              itemGap: 30,
              selectedMode: false,
              icon: "pin",
              data: ["1小时内", "2小时内", "24小时内", "24小时以上", "未接单"],
              textStyle: {
                color: "#77899c",
                rich: {
                  uname: {
                    width: 100,
                  },
                  unum: {
                    color: "#4ed139",
                    width: 40,
                    align: "right",
                  },
                },
              },
              formatter: function (name) {
                var oa = option.series[0].data;
                var num =
                  oa[0].value +
                  oa[1].value +
                  oa[2].value +
                  oa[3].value +
                  oa[4].value;
                for (var i = 0; i < option.series[0].data.length; i++) {
                  if (name == oa[i].name) {
                    return (
                      name +
                      "     " +
                      ((oa[i].value / num) * 100).toFixed(2) +
                      "%   " +
                      oa[i].value +
                      "个"
                    );
                  }
                }
              },
            },
            color: colorList,
            series: [
              {
                name: "",
                type: "pie",
                radius: [60, 90],
                center: ["25%", "50%"],
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                itemStyle: {
                  borderWidth: 3,
                  borderColor: "#fff",
                },
                data: [
                  {
                    name: "1小时内",
                    value: data.data.data.task_confirm_time_count.type_1_count,
                  },
                  {
                    name: "2小时内",
                    value: data.data.data.task_confirm_time_count.type_2_count,
                  },
                  {
                    name: "24小时内",
                    value: data.data.data.task_confirm_time_count.type_3_count,
                  },
                  {
                    name: "24小时以上",
                    value: data.data.data.task_confirm_time_count.type_4_count,
                  },
                  {
                    name: "未接单",
                    value: data.data.data.task_confirm_time_count.type_5_count,
                  },
                ],
              },
            ],
          };
          sector.setOption(option);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getTaskData() {
      this.$apis
        .getTaskData(
          {
            type: this.type,
            project_type: this.project_type,
            push_type: this.push_type,
            start_datetime: this.start_datetime,
            end_datetime: this.end_datetime,
            month_data_start_date: this.month_data_start_date,
            month_data_end_date: this.month_data_end_date,
          },
          this.token
        )
        .then((data) => {
          this.taskDataList = data.data.data;
          this.yesterday_push =
            ((this.taskDataList.report_home_today_pushed -
              this.taskDataList.report_home_yesterday_pushed) /
              this.taskDataList.report_home_yesterday_pushed) *
            100;
          this.yesterday_not_push =
            ((this.taskDataList.report_home_today_not_pushed -
              this.taskDataList.report_home_yesterday_not_pushed) /
              this.taskDataList.report_home_yesterday_not_pushed) *
            100;
          if (this.project_type === 1) {
            this.taskNum =
              (this.taskDataList.project_or_task_count_1 /
                this.taskDataList.project_or_task_total_count) *
              100;
            this.terminal_unit =
              (this.taskDataList.push_unit_count_1 /
                this.taskDataList.push_unit_total_count) *
              100;
            this.terminal_user =
              (this.taskDataList.push_user_count_1 /
                this.taskDataList.push_user_total_count) *
              100;

            this.receiving_unit =
              (this.taskDataList.confirm_unit_count_1 /
                this.taskDataList.confirm_unit_total_count) *
              100;

            this.receiving_user =
              (this.taskDataList.confirm_user_count_1 /
                this.taskDataList.confirm_user_total_count) *
              100;
          } else if (this.project_type === 2) {
            this.taskNum =
              (this.taskDataList.project_or_task_count_2 /
                this.taskDataList.project_or_task_total_count) *
              100;
            this.terminal_unit =
              (this.taskDataList.push_unit_count_2 /
                this.taskDataList.push_unit_total_count) *
              100;
            this.terminal_user =
              (this.taskDataList.push_user_count_2 /
                this.taskDataList.push_user_total_count) *
              100;

            this.receiving_unit =
              (this.taskDataList.confirm_unit_count_2 /
                this.taskDataList.confirm_unit_total_count) *
              100;

            this.receiving_user =
              (this.taskDataList.confirm_user_count_2 /
                this.taskDataList.confirm_user_total_count) *
              100;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },

  created() {
    this.getday();
  },
  computed: {
    ...mapState(["navMessage", "token", "user"]),
  },
  mounted() {
    this.drawLine();
    this.sector();
    if (this.$route.query.nav == "renwusta") {
      this.currentUrlStorage(["首页", "统计分析", "任务统计"]);
    }
    //实现自适应部分
    this.resizefun = () => {
      this.$echarts.init(document.getElementById("myChart")).resize();
      this.$echarts.init(document.getElementById("sector")).resize();
    };
    window.addEventListener("resize", this.resizefun);
  },
  //移除事件监听，避免内存泄漏
  beforeDestroy() {
    window.removeEventListener("resize", this.resizefun);
    this.resizefun = null;
  },
};
</script>

<style lang="less" scoped>
.blocks {
  margin-right: 2vw;
}
/deep/.el-divider--horizontal {
  margin: 15px 0 0px 0;
}
.growthrate {
  float: right;
  margin-left: 17%;
  margin-top: 5px;
  position: absolute;
  .growthNum {
    font-size: 16px;
    color: #1cd0bd;
  }
  .growthWord {
    font-size: 12px;
    color: #a6aab8;
    margin: 2px 0px;
  }
}
.uavBody {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  padding: 0px 30px 45px 30px;
}
.topBar {
  width: 100%;
  height: 14.3%;
  display: flex;
  justify-content: space-between;
}
.statIng {
  float: left;
  .topBgcImg {
    width: 100%;
    height: 100%;
  }
}
.countNum {
  position: absolute;
  margin-top: 1.5%;
  margin-left: 15px;
  .titleNum {
    color: #a6aab8;
    font-size: 14px;
  }
  .countOne {
    color: #507fe7;
    font-size: 40px;
  }
  .danwei {
    color: #507fe7;
  }
}
.countTwo {
  color: #d265e6;
  font-size: 40px;
}
.danweiTwo {
  color: #d265e6;
}
.countThr {
  color: #15bea5;
  font-size: 40px;
}
.danweiThr {
  color: #15bea5;
}
.countFour {
  color: #ff5c5e;
  font-size: 40px;
}
.danweiFour {
  color: #ff5c5e;
}
.bodyBar {
  width: 100%;
  height: calc(100% - 15% - 70px);
  margin-top: 1%;
  float: left;
  .leftBar {
    float: left;
    height: 100%;
    width: 42%;
    background-color: #ffff;
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    .titleBar {
      color: #838a9d;
      font-size: 16px;
      margin-left: 2vw;
      margin-top: 2vh;
    }
    .middleBar {
      width: 28vw;
      margin-left: 2vw;
      margin-top: 3vh;
      .totalBar {
        display: flex;
        justify-content: space-between;
        margin-top: 3vh;
        font-size: 16px;
        .totalTitle {
          color: #838a9d;
        }
        .wordBtn {
          width: 100px;
          margin-right: 20px;
          cursor: pointer;
          font-size: 14px;
        }
        .roundBtn {
          height: 40px;
          width: 95px;
        }
        .wordBtnTime {
          line-height: 40px;
        }
      }
    }
  }
  .progress {
    margin-top: 2vh;
    .gressline {
      width: 100%;
      height: 5vh;
      .progessWord {
        float: left;
        margin-left: 2vw;
        color: #838a9d;
        font-size: 14px;
      }
      .progreBar {
        width: 20vw;
        float: left;
        margin-left: 3vw;
      }
      .numCount {
        width: 100%;
        height: 2vh;
        display: flex;
        justify-content: flex-end;
        padding-right: 4vw;
        margin-bottom: 5px;
      }
      .first {
        margin-left: 5vw;
      }
    }
  }
  .rightBar {
    float: right;
    width: 56.5%;
    height: 100%;
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    .rTopBar {
      width: 100%;
      height: 55%;
      float: left;
      background-color: #fff;
      .topTitle {
        width: 100%;
        margin-top: 1vh;
        display: flex;
        justify-content: space-between;
        line-height: 36px;
        .titleWord {
          color: #838a9d;
          font-size: 20px;
          margin-left: 2.5vw;
          font-size: 16px;
        }
        .feima {
          width: 20px;
          height: 20px;
          background-color: #1fd0be;
          margin-top: 0.5vh;
        }
        .tongyong {
          width: 20px;
          height: 20px;
          background-color: #1980ff;
          margin-top: 0.5vh;
        }
      }
      #myChart {
        width: 45vw;
        height: 30vh;
        margin-left: 1vw;
      }
    }
    .rBotBar {
      width: 100%;
      height: 42.7%;
      background-color: #fff;
      margin-top: 2%;
      float: left;
      .topTitle {
        width: 100%;
        margin-top: 1vh;
        display: flex;
        justify-content: space-between;
        line-height: 36px;
      }
      #sector {
        width: 46vw;
        height: 22vh;
      }
      .titleWord {
        color: #838a9d;
        font-size: 20px;
        margin-left: 2.5vw;
        font-size: 16px;
      }
      .btnGroup {
        margin-right: 2.5vw;
      }
    }
  }
}
.block {
  margin-top: 3vh;
  margin-left: 2vw;
}
.imgBox {
  width: 18vw;
  height: 13vh;
}
/deep/.el-range-editor.el-input__inner {
  border-radius: 20px;
}
/deep/.el-progress-bar__outer {
  height: 12px !important;
}
/deep/.el-button-group .el-button--primary:first-child {
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
}
/deep/.el-button-group .el-button--primary:last-child {
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}
@media only screen and (max-width: 1465px) {
  /deep/.el-date-editor--daterange.el-input__inner {
    width: 250px;
  }
  .uavBody {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    padding: 0px 30px 30px 30px;
  }
  /deep/.el-divider--horizontal {
    margin: 15px 0 0px 0;
  }
  .countNum {
    position: absolute;
    margin-top: 2%;
    margin-left: 5px;
    .titleNum {
      color: #a6aab8;
      font-size: 12px;
    }
    .countOne {
      color: #507fe7;
      font-size: 34px;
    }
    .danwei {
      color: #507fe7;
    }
  }
  .countTwo {
    color: #d265e6;
    font-size: 34px;
  }
  .countThr {
    color: #15bea5;
    font-size: 34px;
  }
  .countFour {
    color: #ff5c5e;
    font-size: 34px;
  }
  .bodyBar {
    width: 100%;
    height: calc(100% - 15% - 70px);
    margin-top: 2vh;
    float: left;
    .leftBar {
      float: left;
      height: 100%;
      width: 42%;
      background-color: #ffff;
      .titleBar {
        color: #838a9d;
        font-size: 18px;
        margin-left: 2vw;
        margin-top: 20px;
      }
    }
    .rightBar {
      float: right;
      width: 56.5%;
      height: 100%;
      background-color: #fff;
    }
  }
  .bodyBar {
    width: 100%;
    height: 76%;
    margin-top: 2vh;
    float: left;
    .leftBar {
      float: left;
      height: 100%;
      width: 42%;
      background-color: #ffff;
      box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      .titleBar {
        color: #838a9d;
        font-size: 16px;
        margin-left: 2vw;
        margin-top: 1vh;
      }
      .middleBar {
        width: 25vw;
        margin-left: 2vw;
        margin-top: 1vh;
        font-size: 14px;
        .totalBar {
          display: flex;
          justify-content: space-between;
          margin-top: 2vh;
          font-size: 16px;
          .totalTitle {
            color: #838a9d;
          }
          .wordBtn {
            width: 100px;
            margin-right: 20px;
            font-size: 14px;
          }
          .roundBtn {
            height: 40px;
            width: 95px;
          }
          .wordBtnTime {
            line-height: 40px;
          }
        }
      }
    }
    .progress {
      margin-top: 2vh;
      .gressline {
        width: 100%;
        height: 5vh;
        .progessWord {
          float: left;
          margin-left: 1vw;
          color: #838a9d;
          font-size: 14px;
        }
        .progreBar {
          width: 20vw;
          float: left;
          margin-left: 2vw;
        }
        .numCount {
          width: 100%;
          height: 2vh;
          display: flex;
          justify-content: flex-end;
          padding-right: 4vw;
          margin-bottom: 5px;
        }
        .first {
          margin-left: 4.5vw;
        }
      }
    }
    .rightBar {
      float: right;
      width: 56.5%;
      height: 0%;
      box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      background-color: #f0f0f0;
      .rTopBar {
        width: 100%;
        height: 35vh;
        float: left;
        background-color: #fff;
        box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
        .topTitle {
          width: 100%;
          margin-top: 0.3svh;
          display: flex;
          justify-content: flex-start;
          line-height: 36px;
          .titleWord {
            color: #838a9d;
            font-size: 16px;
            margin-left: 2.5vw;
            font-size: 16px;
          }
          .feima {
            width: 20px;
            height: 20px;
            background-color: #1fd0be;
            margin-top: 0.5vh;
            margin-left: 8px;
          }
          .tongyong {
            width: 20px;
            height: 20px;
            background-color: #1980ff;
            margin-top: 0.5vh;
            margin-left: 8px;
          }
          .blocks {
            width: 150px;
            margin-left: 8px;
          }
          .feimaWord {
            font-size: 14px;
            margin-left: 2px;
          }
          .tyWord {
            font-size: 14px;
            margin-left: 2px;
          }
        }
        #myChart {
          width: 40vw;
          height: 29vh;
          margin-left: 1vw;
        }
      }
      .rBotBar {
        width: 100%;
        height: 32vh;
        background-color: #fff;
        margin-top: 2%;
        float: left;
        box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
        .topTitle {
          width: 100%;
          margin-top: 1vh;
          display: flex;
          justify-content: space-between;
          line-height: 36px;
        }
        #sector {
          width: 46vw;
          height: 24vh;
        }
        .titleWord {
          color: #838a9d;
          font-size: 16px;
          margin-left: 0.5vw;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
