<template>
  <!-- 设置弹框 -->
  <div class="setBar" v-show="setVisible">
    <!-- 飞马 -->
    <div style="position: relative">
      <label class="allselect">
        <input class="selectInp" type="checkbox" v-model="feimaVisible" @click="hideOrshowMassMaker('feima',feimaVisible)"/>
        <div class="circle"></div>
        <span class="flytit">飞马无人机</span>
      </label>
      <label class="select">
        <input
          type="checkbox"
          class="selectInp"
          id="Runoob"
          value="正在飞行"
          @click="hideOrshowMassMaker('onlineFeima',onlineFeimaMarkerVisible)"
          v-model="onlineFeimaMarkerVisible"
        />
        <span
          :style="{
            color: onlineFeimaMarkerVisible
              ? 'rgb(50, 247, 147)'
              : 'rgb(164, 174, 226)',
          }"
          class="flystatus"
          >正在飞行({{ feimaOnlineCount }})</span
        >
      </label>
      <div
        @click="showFilterForm('feima')"
        style="position: absolute; bottom: 0px; left: 20px"
      >
        <i class="iconfont zdhistory">&#xe66e;</i>
      </div>
      <label class="select">
        <input
          type="checkbox"
          class="historyInp"
          id="Google"
          value="历史飞行"
          @click="hideOrshowMassMaker('historyFeima',historyFeimaMarkerVisible)"
          v-model="historyFeimaMarkerVisible"
        />
        <span
          :style="{
            color: historyFeimaMarkerVisible
              ? 'rgb(50, 247, 147)'
              : 'rgb(164, 174, 226)',
          }"
          class="flystatus"
          >历史飞行({{ feimaHistoryFliterCount }})</span
        >
      </label>
      <div style="clear: both"></div>
    </div>
    <!-- 通用 -->
    <div style="position: relative">
      <label class="universal">
        <input type="checkbox" class="univerInp" v-model="commonVisible" @click="hideOrshowMassMaker('common',commonVisible)"/>
        <div class="circleOne"></div>
        <span class="univerfly">通用无人机</span>
      </label>
      <label class="select">
        <input
          type="checkbox"
          class="selectInp"
          id="Runoob"
          value="正在飞行"
          v-model="onlineCommonMarkerVisible"
          @click="hideOrshowMassMaker('onlineCommon',onlineCommonMarkerVisible)"
        />
        <span
          :style="{
            color: onlineCommonMarkerVisible
              ? 'rgb(73, 178, 255)'
              : 'rgb(164, 174, 226)',
          }"
          class="flystatus"
          >正在飞行({{ commonOnlineCount }})</span
        >
      </label>
      <div
        @click="showFilterForm('common')"
        style="position: absolute; bottom: 0px; left: 20px"
      >
        <i class="iconfont zdhistory">&#xe66e;</i>
      </div>
      <label class="select">
        <input
          type="checkbox"
          class="historyInp"
          id="Google"
          value="历史飞行"
          @click="hideOrshowMassMaker('historyCommon',historyCommonMarkerVisible)"
          v-model="historyCommonMarkerVisible"
        />
        <span
          :style="{
            color: historyCommonMarkerVisible
              ? 'rgb(73, 178, 255)'
              : 'rgb(164, 174, 226)',
          }"
          class="flystatus"
          >历史飞行({{ commonHistoryFliterCount }})</span
        >
      </label>
      <div style="clear: both"></div>
    </div>
    <!-- 终端 -->
    <div style="position: relative">
      <label class="teamTerminal">
        <input type="checkbox" class="teamInp" v-model="terminalVisible" @click="hideOrshowMassMaker('terminal',terminalVisible)"/>
        <div class="circleTwo"></div>
        <span class="teamfly">林业巡护</span>
      </label>
      <label class="select">
        <input
          checked
          type="checkbox"
          class="selectInp"
          id="Runoob"
          v-model="onlineTerminalMarkerVisible"
           @click="hideOrshowMassMaker('onlineTerminal',onlineTerminalMarkerVisible)"
          value="在线终端"
        />
        <span
          :style="{
            color: onlineTerminalMarkerVisible
              ? 'rgb(251, 68, 57)'
              : 'rgb(164, 174, 226)',
          }"
          class="flystatus"
          >在线终端({{ terminalOnlineCount }})</span
        >
      </label>
      <div class="trajectoryBar">
        <span class="trajectory">移动轨迹</span>
        <el-switch
          class="switchs"
          @change="changeOnlineTermianalTrackVisible"
          v-model="onlineTerminalTrackVisible"
        ></el-switch>
      </div>
      <div
        @click="showFilterForm('terminal')"
        style="position: absolute; bottom: 0px; left: 20px"
      >
        <i class="iconfont zdhistory">&#xe66e;</i>
      </div>
      <label class="select">
        <input
          type="checkbox"
          class="historyInp"
          id="Google"
          value="历史终端"
          @click="hideOrshowMassMaker('historyTerminal',historyTerminalMarkerVisible)"
          v-model="historyTerminalMarkerVisible"
        />
        <span
          :style="{
            color: historyTerminalMarkerVisible
              ? 'rgb(251, 68, 57)'
              : 'rgb(164, 174, 226)',
          }"
          class="flystatus"
          >历史终端({{ terminalHistoryFliterCount }})</span
        >
      </label>
      <div style="clear: both"></div>
    </div>

    <el-divider class="hhh"></el-divider>
    <div class="switchGroup">
      <div class="topSwitch">
        <span class="gaudMap">默认地图</span>
        <el-switch
          class="switchTurn"
          @change="guideMap"
          v-model="isGuideMap"
          :disabled="isGuideMap"
        ></el-switch>
      </div>
      <div class="midSwitch">
        <span class="skyMap">天地图</span>
        <el-switch
          class="switchTurn"
          @change="skyMap"
          v-model="isSkyMap"
          :disabled="isSkyMap"
        ></el-switch>
      </div>
      <div class="footSwitch">
        <span class="imageTile">影像瓦片</span>
        <el-switch
          class="switchTurn"
          @change="imageTile"
          v-model="isImageTile"
          :disabled="isImageTile"
        ></el-switch>
      </div>
    </div>
    <!-- 时间筛选框 -->
    <div class="uavBar">
      <el-dialog
        title="开放日期"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        class="taskBox"
        :modal="false"
        v-dialogDrag
      >
        <el-divider></el-divider>
        <div class="detailBar">
          <div class="formBox">
            <div class="chooseHistr" v-show="filterType == 'terminal'">
              <span class="histrWord">移动轨迹</span>
              <el-switch
                class="switchs"
                @change="changeTrajectory"
                v-model="historyTerminalTrackVisible"
              ></el-switch>
            </div>
            <div class="totalBar">
              <div
                @click="getday"
                class="roundBtn"
                :class="{
                  'is-click': selectTimeType == 'day',
                  'click-disable': filterType == 'terminal' && track == 0,
                }"
                round
                plain
              >
                今日
              </div>
              <div
                @click="getweek"
                class="roundBtn"
                :class="{
                  'is-click': selectTimeType == 'week',
                  'click-disable': filterType == 'terminal' && track == 0,
                }"
                round
                plain
              >
                本周
              </div>
              <div
                @click="getmonth"
                class="roundBtn"
                :class="{
                  'is-click': selectTimeType == 'month',
                  'click-disable': filterType == 'terminal' && track == 0,
                }"
                round
                plain
              >
                本月
              </div>
            </div>
            <div class="block">
              <el-date-picker
                :disabled="
                  (filterType == 'terminal' && track == 0) || selectTimeType != ''
                "
                v-model="dateRange"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="getDateRange"
              ></el-date-picker>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" v-loading="loading" @click="confirm"
            >确 定</el-button
          >
          <el-button @click="dialogVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import timeJs from "@/utils/time";
export default {
  props: [
    "feimaOnlineCount",
    "feimaHistoryFliterCount",
    "commonOnlineCount",
    "commonHistoryFliterCount",
    "terminalOnlineCount",
    "terminalHistoryFliterCount",
  ],
  data() {
    return {
      onlineTerminalMarkerVisible: true, //在线终端显示图标
      onlineTerminalTrackVisible: false, //在线终端移动轨迹

      historyTerminalMarkerVisible: false, //历史终端显示图标
      historyTerminalTrackVisible: false, //历史终端移动轨迹

      onlineFeimaMarkerVisible: true, //在线飞马显示图标
      historyFeimaMarkerVisible: false, //历史飞马显示图标

      onlineCommonMarkerVisible: true, //在线通用显示图标
      historyCommonMarkerVisible: false, //历史通用显示图标

      loading: false,
      dialogVisible: false,
      setVisible: false,

      isGuideMap: true, //高德地图
      isSkyMap: false, //天地图
      isImageTile: false, //影像瓦片

      start_datetime: "",
      end_datetime: "",

      dateRange: [],
      track: 0,

      filterType: "feima", //筛选类型
      selectTimeType: "", //选择时间类型
    };
  },
  methods: {
    hideOrshowMassMaker(name,markerVisible){
      this.$emit("hideOrshowMassMaker",{
        name: name,
        markerVisible:markerVisible
      })
    },
    open() {
      this.setVisible = !this.setVisible;
    },
    // 筛选
    showFilterForm(type) {
      this.filterType = type;
      this.dialogVisible = true;
    },
    // 确定按钮
    confirm() {
      if (this.filterType == "feima") {
        this.$emit("changeHistoryFeima", {
          start_datetime: this.start_datetime,
          end_datetime: this.end_datetime,
        });
        this.historyFeimaMarkerVisible = true;
        // this.hideOrshowMassMaker('historyFeima',false)
      } else if (this.filterType == "common") {
        this.$emit("changeHistoryCommon", {
          start_datetime: this.start_datetime,
          end_datetime: this.end_datetime,
        });
        this.historyCommonMarkerVisible = true;
        // this.hideOrshowMassMaker('historyCommon',false)
      } else {
        this.$emit("changeHistoryTerminal", {
          start_datetime: this.track ? this.start_datetime : null,
          end_datetime: this.track ? this.end_datetime : null,
          track: this.track == 1 ? true : false,
        });
        this.historyTerminalMarkerVisible = true;
        // this.hideOrshowMassMaker('historyTerminal',false)
      }
      this.dialogVisible = false;
    },
    //更改所有在线终端轨迹的显隐
    changeOnlineTermianalTrackVisible() {
      this.$emit(
        "changeOnlineTermianalTrackVisible",
        this.onlineTerminalTrackVisible
      );
    },
    // 获取开关状态
    changeTrajectory() {
      if (this.historyTerminalTrackVisible == true) {
        this.track = 1;
      } else {
        this.track = 0;
      }
    },
    // 获取选择的时间
    getDateRange() {
      this.start_datetime = this.dateRange[0] + " " + "00:00:00";
      this.end_datetime = this.dateRange[1] + " " + "23:59:59";
    },
    // 今日
    getday() {
      this.start_datetime = timeJs.getYmdTime(new Date()) + " " + "00:00:00";
      this.end_datetime = timeJs.getYmdTime(new Date()) + " " + "23:59:59";
      //时间筛选框赋值
      this.dateRange = [this.start_datetime, this.end_datetime];
      this.selectTimeType = this.selectTimeType == "day" ? "" : "day";
    },
    // 本周
    getweek() {
      this.start_datetime = timeJs.getFirstDayOfWeek() + " " + "00:00:00";
      this.end_datetime = timeJs.getLastDayOfWeek() + " " + "23:59:59";
      //时间筛选框赋值
      this.dateRange = [this.start_datetime, this.end_datetime];
      this.selectTimeType = this.selectTimeType == "week" ? "" : "week";
    },

    // 本月
    getmonth() {
      this.start_datetime = timeJs.getFirstDayOfMonth() + " " + "00:00:00";
      this.end_datetime = timeJs.getLastDayOfMonth() + " " + "23:59:59";
      //时间筛选框赋值
      this.dateRange = [this.start_datetime, this.end_datetime];
      this.selectTimeType = this.selectTimeType == "month" ? "" : "month";
    },

    // 高德地图
    guideMap() {
      this.isSkyMap = false;
      this.isImageTile = false;
      this.$emit("guideMap");
    },
    // 天地图
    skyMap() {
      this.isGuideMap = false;
      this.isImageTile = false;
      this.$emit("skyMap");
    },
    // 影像瓦片
    imageTile() {
      this.isGuideMap = false;
      this.isSkyMap = false;
      this.$emit("imageTile");
    },
  },
  watch: {},
  computed: {
    feimaVisible: {
      get() {
        return this.onlineFeimaMarkerVisible && this.historyFeimaMarkerVisible;
      },
      set(value) {
        this.onlineFeimaMarkerVisible = value;
        this.historyFeimaMarkerVisible = value;
      },
    },
    commonVisible: {
      get() {
        return (
          this.onlineCommonMarkerVisible && this.historyCommonMarkerVisible
        );
      },
      set(value) {
        this.onlineCommonMarkerVisible = value;
        this.historyCommonMarkerVisible = value;
      },
    },
    terminalVisible: {
      get() {
        return (
          this.onlineTerminalMarkerVisible && this.historyTerminalMarkerVisible
        );
      },
      set(value) {
        this.onlineTerminalMarkerVisible = value;
        this.historyTerminalMarkerVisible = value;
      },
    },
    ...mapState(["user", "token"]),
  },
  created() {},
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__header {
  padding-bottom: 0;
}
/deep/.el-button.is-round {
  border-radius: 0px;
}
/deep/.el-range-editor.el-input__inner {
  width: 315px;
  margin: 15px 0 0 37px;
}
.totalBar {
  height: 32px;
  width: 100%;
  margin: 20px 0 0 22px;
  display: flex;
  justify-content: space-around;
}
.roundBtn {
  height: 32px;
  width: 80px;
  border: 1px solid #dcdfe6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.roundBtn.is-click {
  color: #1890ff;
  border: 1px solid #1890ff;
}
.roundBtn.click-disable {
  color: #dcdfe6;
  cursor: not-allowed;
  border: 1px solid #dcdfe6;
}
.detailBar {
  margin-top: 12px;
}
.histrWord {
  font-size: 14px;
  color: #3c4353;
  margin: 0 15px 0 37px;
}
/deep/.el-dialog {
  height: 307px;
  width: 389px;
}
/deep/.el-dialog__body {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
/deep/.el-divider--horizontal {
  margin: 24px 0 12px 0;
}
.zdhistory {
  font-size: 16px;
  color: #ffffff;
  margin-left: 8px;
  display: table-caption;
  cursor: pointer;
}
.setBar {
  position: absolute;
  width: 250px;
  height: 602px;
  background-color: #030d32;
  right: 22%;
  top: 15%;
  z-index: 1000;
  border-radius: 6px;
  opacity: 0.9;
}
.allselect {
  width: 85%;
  float: left;
  margin-left: 30px;
  color: #ffffff;
  font-weight: normal;
  margin-top: 30px;
}
.selectInp {
  float: right;
  width: 20px;
  height: 20px;
  float: left;
}
.univerInp {
  float: right;
  width: 20px;
  height: 20px;
  float: left;
}
.teamInp {
  float: right;
  width: 20px;
  height: 20px;
  float: left;
}
.circle {
  float: left;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #32f793;
  margin-left: 5px;
  margin-top: 3px;
}
.circleOne {
  float: left;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #49b2ff;
  margin-left: 5px;
  margin-top: 3px;
}
.circleTwo {
  float: left;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #fb4439;
  margin-left: 5px;
  margin-top: 3px;
}
.flytit {
  float: left;
  margin-left: 5px;
  font-size: 14px;
}
.select {
  width: 85%;
  float: left;
  margin-left: 50px;
  color: #ffffff;
  font-weight: normal;
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.flystatus {
  float: left;
  margin-left: 20px;
  font-size: 14px;
}
.historyInp {
  float: right;
  width: 20px;
  height: 20px;
  float: left;
}
.univerfly {
  float: left;
  margin-left: 5px;
  font-size: 14px;
}
.teamfly {
  float: left;
  margin-left: 5px;
  font-size: 14px;
}
.universal {
  width: 85%;
  float: left;
  margin-left: 30px;
  color: #ffffff;
  font-weight: normal;
  margin-top: 25px;
}
.teamTerminal {
  width: 85%;
  float: left;
  margin-left: 30px;
  color: #ffffff;
  font-weight: normal;
  margin-top: 25px;
}
.trajectoryBar {
  float: left;
  width: 40%;
  margin-left: 90px;
  margin-top: 5px;
  .trajectory {
    color: #a4aee2;
    float: left;
    font-size: 14px;
  }
  .switchs {
    float: right;
  }
}
.hhh {
  float: left;
  background-color: #3d4885;
}
.switchTurn {
  float: right;
}
.switchGroup {
  margin-left: 50px;
  float: left;
  margin-top: 10px;
  .topSwitch {
    width: 70%;
    float: left;
    .gaudMap {
      float: left;
      color: #ffffff;
      font-size: 14px;
    }
  }
  .midSwitch {
    width: 70%;
    float: left;
    margin-top: 15px;
    .skyMap {
      float: left;
      color: #ffffff;
      font-size: 14px;
    }
  }
  .footSwitch {
    width: 70%;
    float: left;
    margin-top: 15px;
    .imageTile {
      float: left;
      color: #ffffff;
      font-size: 14px;
    }
  }
}
</style>
