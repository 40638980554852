var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "taskBox",
          attrs: {
            title: "任务详情",
            visible: _vm.dialogVisible,
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            click: _vm.open,
          },
        },
        [
          _c("el-divider"),
          _vm._v(" "),
          _c("div", { staticClass: "detailBar" }, [
            _c("div", { staticClass: "detailLeft" }, [
              _c("p", { staticClass: "detailLine" }, [
                _c("span", { staticClass: "datailTit" }, [_vm._v("数据名称")]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dataContent",
                    attrs: { title: _vm.taskData.name },
                  },
                  [_vm._v(_vm._s(_vm.taskData.name))]
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "detailLine" }, [
                _c("span", { staticClass: "datailTit" }, [_vm._v("发布账号")]),
                _vm._v(" "),
                _c("span", { staticClass: "dataContent" }, [
                  _vm._v(_vm._s(_vm.taskData.user_mobile)),
                ]),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "detailLine" }, [
                _c("span", { staticClass: "datailTit" }, [_vm._v("数据类型")]),
                _vm._v(" "),
                _c("span", { staticClass: "dataContent" }, [
                  _vm._v(_vm._s(_vm.taskData.type)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "detailRight" }, [
              _c("p", { staticClass: "detailLine" }, [
                _c("span", { staticClass: "datailTit" }, [_vm._v("巡护单位")]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dataContent",
                    attrs: { title: _vm.taskData.unit_name },
                  },
                  [_vm._v(_vm._s(_vm.taskData.unit_name))]
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "detailLine" }, [
                _c("span", { staticClass: "datailTit" }, [_vm._v("发布时间")]),
                _vm._v(" "),
                _c("span", { staticClass: "dataContent" }, [
                  _vm._v(_vm._s(_vm.taskData.published_at)),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c("div", { staticClass: "detailBar" }, [
            _c("div", { staticClass: "detailLeft" }, [
              _c("p", { staticClass: "detailLine" }, [
                _c("span", { staticClass: "datailTit" }, [_vm._v("任务 ID")]),
                _vm._v(" "),
                _c("span", { staticClass: "dataContent" }, [
                  _vm._v(_vm._s(_vm.taskData.task_id)),
                ]),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "detailLine" }, [
                _c("span", { staticClass: "datailTit" }, [_vm._v("任务名称")]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dataContent",
                    attrs: { title: _vm.taskData.project_name },
                  },
                  [_vm._v(_vm._s(_vm.taskData.project_name))]
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "detailLine" }, [
                _c("span", { staticClass: "datailTit" }, [_vm._v("任务日期")]),
                _vm._v(" "),
                _c("span", { staticClass: "dataContent" }, [
                  _vm._v(_vm._s(_vm.taskData.project_created_at)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "detailRight" }, [
              _c("p", { staticClass: "detailLine" }, [
                _c("span", { staticClass: "datailTit" }, [_vm._v("任务类型")]),
                _vm._v(" "),
                _c("span", { staticClass: "dataContent" }, [
                  _vm._v(
                    _vm._s(
                      _vm.taskData.task_type == 1 ? "航线任务" : "通用任务"
                    )
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }