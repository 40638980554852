var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading || _vm.allRequsetLoading,
          expression: "isLoading || allRequsetLoading",
        },
      ],
      staticClass: "task",
    },
    [
      _c(
        "div",
        { staticClass: "historyTerminaldialog" },
        [
          _vm.historyTerminaldialogVisible
            ? _c(
                "el-dialog",
                {
                  directives: [
                    { name: "dialogDragCustom", rawName: "v-dialogDragCustom" },
                  ],
                  ref: "deviceDataRef",
                  staticClass: "chat",
                  attrs: {
                    modal: false,
                    "append-to-body": false,
                    "modal-append-to-body": false,
                    "close-on-click-modal": false,
                    "close-on-press-escape": false,
                    "lock-scroll": false,
                    visible: _vm.historyTerminaldialogVisible,
                    title: _vm.terminalDialogTitle,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.historyTerminaldialogVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "dialog-header",
                      attrs: { slot: "title" },
                      slot: "title",
                    },
                    [
                      _vm.terminal.dialogType === "drone"
                        ? _c("span", [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.monitorType === "list",
                                    expression: "monitorType === 'list'",
                                  },
                                ],
                                staticClass: "dia_title",
                              },
                              [_vm._v("任务信息")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.monitorType === "point",
                                    expression: "monitorType === 'point'",
                                  },
                                ],
                                staticClass: "dia_title",
                              },
                              [_vm._v("设备信息")]
                            ),
                            _vm._v(" "),
                            _vm.terminal.dialogType === "drone"
                              ? _c("span", [
                                  _vm.terminal.droneInfo.brand_name !== "彩虹"
                                    ? _c("span", { staticClass: "dia_name" }, [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.brand_name) +
                                            "\n            "
                                        ),
                                      ])
                                    : _c("span", { staticClass: "dia_name" }, [
                                        _vm._v(" 彩虹无人机 "),
                                      ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.terminal.planeType ===
                                              "feima" &&
                                            _vm.monitorType === "list",
                                          expression:
                                            "\n                terminal.planeType === 'feima' && monitorType === 'list'\n              ",
                                        },
                                      ],
                                      staticClass: "trajectoryBar",
                                      on: { click: _vm.lookParams },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: { cursor: "pointer" },
                                        attrs: {
                                          src: require("@/assets/img/homeDialog/moreInformation.png"),
                                        },
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.terminal.dialogType === "terminal"
                        ? _c("span", { staticClass: "dia_title" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm._f("filterAppName")(_vm.terminalName)
                                ) +
                                "\n          "
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.terminal.droneInfo &&
                                      _vm.terminal.droneInfo.mission_id,
                                    expression:
                                      "terminal.droneInfo && terminal.droneInfo.mission_id",
                                  },
                                ],
                                staticClass: "trajectoryBar",
                              },
                              [
                                _c("span", { staticClass: "trajectory" }, [
                                  _vm._v("移动轨迹"),
                                ]),
                                _vm._v(" "),
                                _c("el-switch", {
                                  staticClass: "switchTurn",
                                  on: { change: _vm.showThisTrack },
                                  model: {
                                    value: _vm.isShowThisTrack,
                                    callback: function ($$v) {
                                      _vm.isShowThisTrack = $$v
                                    },
                                    expression: "isShowThisTrack",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm.terminal.dialogType === "params"
                        ? _c("div", [
                            _c(
                              "span",
                              {
                                staticClass: "dia_name",
                                staticStyle: { cursor: "pointer" },
                                on: { click: _vm.returnDronne },
                              },
                              [_vm._v("\n            返回\n          ")]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("dtuPop", {
                    ref: "dtupop",
                    attrs: {
                      terminalData: _vm.terminal,
                      trackButtonValue: _vm.trackButtonValue,
                      monitorType: _vm.monitorType,
                      nowShowLayer: _vm.nowShowLayer,
                    },
                    on: { setLocationInMap: _vm.setLocationInMap },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "map", attrs: { id: "map1" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }