<template>
  <div>
    <!-- 弹出层 -->
    <el-dialog
      class="fmdialog"
      title="添加任务群组成员"
      :close-on-click-modal="false"
      @click="open"
      :visible.sync="outerVisible"
      v-dialogDrag
    >
      <el-divider></el-divider>
      <div class="sidersize"></div>
      <div class="fmboxLeft">
        <!-- tag -->
        <div class="fmTags">
          <div
            v-for="(item, index) in checkedCities"
            @click="handleClose(index)"
            class="iconfont cursor"
            :key="index"
          >
            {{ item.unit_name ? item.unit_name : item.name }}
            <span>&#xe621;</span>
          </div>
        </div>
        <!-- button -->
        <div slot="footer" class="csLeft">
          <el-button class="fmButton" type="primary" @click="handAddReg"
            >确 定({{ this.checkedCities.length }}个)</el-button
          >
          <el-button class="fmButton" @click="handCancel">取 消</el-button>
        </div>
      </div>
      <!-- 右侧 -->
      <div class="csRight">
        <el-input
          class="searchinput"
          placeholder="请输入账号/账户名称进行搜索"
          suffix-icon="iconfont iconsousuo-copy"
          @keyup.enter.native="getUnitList"
          @input="fucusAll(keywordAll)"
          v-model="keywordAll"
        >
          <i slot="suffix" @click="getUnitList" class="iconfont searchAll"
            >&#xe61d;</i
          >
        </el-input>
        <div class="fmradioTit">
          <p>{{ this.grounpObj.unit_name }}</p>
        </div>
        <!-- 单选框 -->
        <div class="fmradio">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group
            class="unitlist"
            v-model="checkedCities"
            @change="handleCheckedCitiesChange"
          >
            <el-checkbox
              v-for="(item, index) in cities"
              :label="item"
              :key="index"
            >
              {{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState,mapMutations } from "vuex";
export default {
  props: {
    grounpObj: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      checked: true,
      checkAll: false,
      checkedCities: [],
      cities: [],
      isIndeterminate: true,
      chooseNameList: [],
      tags: [],
      outerVisible: false,
      innerVisible: false,
      keywordAll: ""
    };
  },
  methods: {
    open () {
      this.outerVisible=true;
      this.getUnitList();
    },
    fucusAll (keywordAll) {
      if(keywordAll=="") {
        this.getUnitList();
      }
    },
    // 添加按钮
    handAddReg () {
      this.$emit("handAddReg",this.checkedCities);
      this.outerVisible=false;
    },
    // 关闭dialog
    handCancel () {
      this.outerVisible=false;
    },
    // 移除Tag
    handleClose (index) {
      if(index!=undefined) {
        this.checkedCities.splice(index,1);
        this.estimateAllChange();
      }
    },
    handleCheckAllChange (data) {
      if(data) {
        for(let i=0;i<this.cities.length;i++) {
          this.checkedCities.push(this.cities[i]);
          this.tags.push(this.cities[i]);
        }
      } else {
        this.checkedCities=[];
      }
      this.isIndeterminate=false;
    },
    estimateAllChange () {
      if(this.checkedCities.length==this.cities.length) {
        this.checkAll=true;
      } else {
        this.checkAll=false;
      }
    },
    handleCheckedCitiesChange (value) {
      this.estimateAllChange();
    },
    // 获取巡护用户列表
    getUnitList () {
      this.$apis
        .unituserList(
          {
            page: 1,
            unit_id: this.grounpObj.id,
            per_page: 100000000,
            keyword: this.keywordAll
          },
          this.token
        )
        .then(data => {
         
          this.cities=data.data.data.data;
        })
        .catch(err => { });
    }
  },
  created () { },
  computed: {
    ...mapState(["user","token"])
  }
};
</script>
<style lang="less" scoped>
.searchAll {
  cursor: pointer;
  margin-top: 5px;
  display: block;
}
.cursor {
  margin-left: 35px;
  line-height: 40px;
}
.unitlist {
  height: 325px;
  overflow-y: auto;
  width: 250px;
}
/deep/.el-dialog {
  width: 800px !important;
  height: 584px !important;
  margin-left: 30% !important;
}
/deep/.el-dialog__title {
  font-size: 20px;
  margin-left: 55px;
  line-height: 20px !important;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-dialog__header {
  height: 50px;
}
.demo-ruleForm {
  margin-top: 37px;
  width: 370px;
}
/deep/.el-form-item__content {
  margin-left: 35px !important;
}
/deep/.el-dialog__footer {
  margin-right: 58px;
}
/deep/.el-checkbox {
  width: 200px !important;
}
.el-divider--horizontal {
  margin: 0px;
  margin-top: 10px;
}
.fmButton {
  width: 131px;
}
.sidersize {
  width: 1px;
  height: 523px;
  background: rgba(221, 226, 238, 1);
  position: absolute;
  left: 50%;
}
.el-transfer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.csLeft {
  float: left;
  margin-left: 40px;
}
.csRight {
  float: right;
  width: 300px;
  margin-top: 25px;
  margin-right: 30px;
  .fmradio {
    margin-right: 35px;
    margin-top: 15px;
    overflow-y: auto;
    float: right;
  }
}
.el-checkbox {
  width: 100%;
  margin-bottom: 22px;
}
.el-tag--medium {
  display: flow-root;
  width: 85px;
  margin-top: 10px;
  margin-left: 40px;
}
.fmboxLeft {
  float: left;
}
.fmTags {
  height: 460px;
  overflow-y: auto;
}
.searchinput {
  right: 25px;
}
@media only screen and (max-width: 1670px) {
  /deep/.el-dialog {
    width: 680px !important;
    height: 584px !important;
    margin-left: 20% !important;
  }
}
</style>