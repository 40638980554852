<template>
  <div class="Navbar">
    <div class="grid-content bg-purple">
      <div class="grid-content">
        <h2 class="top-title">无人机巡护指挥调度管理平台</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.Navbar {
  height: 80px;
  background: linear-gradient(
    270deg,
    rgba(3, 13, 50, 1) 0%,
    rgba(6, 57, 176, 1) 100%
  );
  width: 100%;
  float: left;
  .grid-content {
    float: left;
    margin-right: 20px;
    display: flex;
    .top-title {
      font-style: oblique;
      color: #fff;
      font-size: 30px;
      line-height: 30px;
      margin-left: 50px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    }
  }
  .division {
    width: 1px;
    height: 30px;
    background: rgba(166, 170, 184, 1);
    float: left;
    margin-top: 25px;
  }
  .weather {
    float: left;
    width: 190px;
    height: 30px;
    margin-left: 20px;
    line-height: 80px;
    .sztemp {
      color: #dee3e5;
      width: 35px;
      height: 21px;
      font-size: 16px;
    }
    .weathImg {
      width: 20px;
      height: 14px;
    }
  }
}
</style>
