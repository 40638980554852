<template>
  <div style="height: 100%" v-loading="earthLoading">
    <iframe
      ref="iframe"
      style="width: 100%; height: calc(100vh - 70px)"
      src="/result/index.html"
      frameborder="0"
    />
  </div>
</template>

<script>
import AddTag from "@/components/messageBox/addTag";
import EarthHelper from "@/components/earthOperator/earthHelper";
import { mapState, mapMutations } from "vuex";
import SparkMD5 from "spark-md5";
export default {
  components: {
    AddTag,
  },
  data() {
    return {
      fileOptionData: [
        {
          name: "实景三维模型",
          value: "实景三维模型",
        },
        {
          name: "原始影像",
          value: "原始影像",
        },
        {
          name: "原始数据",
          value: "原始数据",
        },
        {
          name: "标准点云",
          value: "标准点云",
        },
        {
          name: "分类点云",

          value: "分类点云",
        },
        {
          name: "快拼",
          value: "快拼",
        },
        {
          name: "TDOM",

          value: "TDOM",
        },
        {
          name: "DOM",

          value: "DOM",
        },
        {
          name: "DSM",

          value: "DSM",
        },
        {
          name: "DEM",

          value: "DEM",
        },
        {
          name: "DLG",

          value: "DLG",
        },
        {
          name: "其他",

          value: "其他",
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["serviceStorage", "clearInterestTypeList"]),
    /**
     * 获取宝略window对象
     */
    getWindow() {
      window.iWindow = this.$refs.iframe.contentWindow;
      // window.iWindow.MessageService.flyToProvince({provinceId:45,provinceName:"广西壮族自治区",zoom:8})
    },
    /**
     * 设置显示的省份
     */
    setProvince() {
      const Message = EarthHelper.getMessageService();
      if (Message) {
        Message.flyToProvince({
          provinceId: 45,
          provinceName: "广西壮族自治区",
        });
        Message.setFileOption(
          this.fileOptionData,"测试"
        );
      } else {
        setTimeout(() => {
          this.setProvince();
        }, 1000);
      }

      //
    },
    feimaBtn() {
      this.sendMessag();
    },
    /**
     * @description: 下载Datamanager
     * @param {type:object} :
     * @return:
     */
    downDatamanager() {
      this.$apis
        .upgrade({ category: "data", version_int: "1.5.0" }, this.token)
        .then((data) => {
          if (data.data.status == 0) {
            const Message = EarthHelper.getMessageService();
            let poi = Message.setUrl(data.data.data.file_url);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 设置poi的类型选项
    setPoiOption() {
      this.$apis
        .getInterestType({}, this.token)
        .then((data) => {
          if (data.data.status == 0) {
            let Poi = data.data.data;
            let PoiOption = [];
            this.clearInterestTypeList();
            for (let i = 0; i < Poi.length; i++) {
              PoiOption.push({
                value: Poi[i].id,
                name: Poi[i].data_interest_type,
              });
              this.interestTypeList.push({
                id: Poi[i].id,
                data_interest_type: Poi[i].data_interest_type,
              });
            }

            const Message = EarthHelper.getMessageService();
            let poiType = Message.setPoiOption(PoiOption);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    var _this = this;
    this.getWindow();
    if (_this.$refs.iframe.attachEvent) {
      _this.$refs.iframe.attachEvent("onload", function () {
        // IE
        _this.downDatamanager();
        _this.setPoiOption();
      });
    } else {
      _this.$refs.iframe.onload = function () {
        // 非IE
        _this.downDatamanager();
        _this.setPoiOption();
      };
    }
    this.setProvince();
  },
  computed: {
    ...mapState(["user", "token", "interestTypeList",'earthLoading']),
  },
};
</script>

<style lang="less" scoped>
.feimabtn {
  margin-left: 100px;
}
</style>
