// 群组
import {
  fly,
  baseURL,
  start,
  finishRes,
  finishErr,
  deploy
} from '../fly.js'


// 添加请求拦截器
fly.interceptors.request.use(
  (request) => {
    start(request)
  }
)

// 添加响应拦截器，响应拦截器会在then/catch处理之前执行
fly.interceptors.response.use(
  (response) => {
    finishRes(response)
  },
  (err) => {
    finishErr(err)
  }
)

//  群组列表
export const grouplist = (data, token) => fly.post(
  baseURL + '/group/list',
  data, deploy(30000, token)
)
// 所有巡护管理员列表
export const getAllUnitAdminUser = (data, token) => fly.get(
  baseURL + '/unituser/getAllUnitAdminUser',
  data, deploy(30000, token)
)

//  创建群组
export const groupadd = (data, token) => fly.post(
  baseURL + '/group/add',
  data, deploy(30000, token)
)

//  删除群组
export const groupdel = (data, token) => fly.post(
  baseURL + '/group/del',
  data, deploy(30000, token)
)

//  删除群组用户
export const delgroupuser = (data, token) => fly.post(
  baseURL + '/groupuser/del',
  data, deploy(30000, token)
)

//  群组信息
export const groupinfo = (data, token, num) => fly.post(
  baseURL + '/groupuser/del/' + num,
  data, deploy(30000, token)
)

//  编辑群组
export const groupupdate = (data, token) => fly.post(
  baseURL + '/group/update',
  data, {
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
      token: token
    },
    parseJson: true
  }
)

//  新建群组用户
export const groupuseradd = (data, token) => fly.post(
  baseURL + '/groupuser/add',
  data, {
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
      token: token
    },
    parseJson: true
  }
)

//  群组用户列表
export const groupuserList = (data, token) => fly.post(
  baseURL + '/groupuser/list',
  data, deploy(30000, token)
)
