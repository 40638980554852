<template>
  <div class="settingNav">
    <navTop />
    <div class="setBody">
      <div class="number">
        <p class="numtit">手机号码</p>
        <p class="numword">{{ telphoneNumber }}</p>
      </div>
      <div class="changeBtn">
        <el-button
          class="local"
          type="primary"
          @click="dialogVisible = 'verify_mobile'"
          >更改</el-button
        >
      </div>
    </div>
    <div class="setBody">
      <div class="number">
        <p class="numtit">账户密码</p>
        <p class="numword">已设置，可通过账户密码登录</p>
      </div>
      <div class="changeBtn">
        <el-button class="local" type="primary" @click="dialogVisible = 'pwd'"
          >更改</el-button
        >
      </div>
    </div>
    <div class="setBody">
      <div class="number">
        <p class="numtit">巡护单位</p>
        <p class="numword">{{ this.user.user_unit.unit_name }}</p>
        <p class="numword">{{ this.user.user_unit.unit_code }}</p>
      </div>
      <div class="changeBtn">
        <el-button class="local" type="primary" @click="nodifyUnit"
          >更改</el-button
        >
      </div>
    </div>
    <ModifyUnit ref="modify" />

    <el-dialog
      title="身份验证"
      class="phone"
      :visible="dialogVisible === 'verify_mobile'"
      :before-close="handleClose"
      width="20%"
    >
      <el-input disabled class="phone-el-input" :value="user.mobile" />
      <el-input
        :minlength="4"
        :maxlength="4"
        style="margin-top: 20px; margin-bottom: 40px"
        v-model="smsCode"
        class="phone-el-input"
        placeholder="请输入验证码"
      />
      <div @click="getCode" class="code-btn">{{ getCodeText }}</div>
      <el-button
        class="local"
        style="margin-left: 12%"
        type="primary"
        @click="codeBtn"
        >验证</el-button
      >
      <el-button
        class="local"
        style="margin-left: 18%"
        @click="dialogVisible = false"
        >取消</el-button
      >
    </el-dialog>

    <el-dialog
      title="更改手机号"
      class="phone iconfont"
      :visible="dialogVisible === 'change_mobile_new'"
      :before-close="handleClose"
      width="20%"
    >
      <el-input
        type="text"
        :minlength="11"
        :maxlength="11"
        v-model="newMobile"
        class="phone-el-input"
        placeholder="请输入手机号"
      />
      <el-input
        type="text"
        :minlength="4"
        :maxlength="4"
        style="margin-top: 20px; margin-bottom: 40px"
        v-model="newSmsCode"
        class="phone-el-input"
        placeholder="请输入验证码"
      />
      <div @click="getCode" class="code-btn">{{ getCodeText }}</div>
      <el-button
        class="local"
        style="margin-left: 12%"
        :disabled="newMobile === ''"
        @click="userUpdateMobile"
        type="primary"
        >确定</el-button
      >
      <el-button
        class="local"
        style="margin-left: 18%"
        @click="dialogVisible = false"
        >取消</el-button
      >
    </el-dialog>

    <el-dialog
      title="更改账户密码"
      class="phone iconfont"
      :visible="dialogVisible === 'pwd'"
      :before-close="handleClose"
      width="20%"
    >
      <el-input
        type="password"
        v-model="pwd"
        class="phone-el-input"
        placeholder="请输入当前密码"
      />
      <el-input
        type="password"
        style="margin-top: 20px"
        v-model="newPwd"
        class="phone-el-input"
        placeholder="请输入新密码"
      />
      <el-input
        type="password"
        style="margin-top: 20px; margin-bottom: 40px"
        v-model="newPwd2"
        class="phone-el-input"
        placeholder="请确认新密码"
      />
      <el-button
        class="local"
        style="margin-left: 12%"
        type="primary"
        @click="userEditPassword"
        >确定</el-button
      >
      <el-button
        class="local"
        style="margin-left: 18%"
        @click="dialogVisible = false"
        >取消</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ModifyUnit from "../../components/messageBox/modifyUnit";
export default {
  components: {
    ModifyUnit,
  },
  data() {
    return {
      telphoneNumber: "",
      newMobile: "", //  新手机号
      smsCode: "", //  验证码
      newSmsCode: "", //  新手机验证码
      pwd: "",
      newPwd: "",
      newPwd2: "",
      dialogVisible: false,
      getCodeText: "获取验证码",
    };
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),

    /**
     * 获取验证码
     */
    getCode() {
      this.$apis
        .note(
          {
            action: this.dialogVisible,
            mobile: this.newMobile === "" ? this.user.mobile : this.newMobile,
          },
          this.token
        )
        .then((data) => {
         
          if (data.data.status === 0) {
            this.$message({
              message: "验证码发送成功",
              type: "success",
            });
            this.getCodeText = 60;
            this.countdown();
          } else {
            this.getCodeText = "1";
            this.countdown();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 验证码倒计时
     */
    countdown() {
      if (this.getCodeText != 0) {
        setTimeout(() => {
          this.getCodeText--;
          this.countdown();
        }, 1000);
        return;
      }
      this.getCodeText = "获取验证码";
    },
    /**
     * 验证手机号
     */
    codeBtn() {
      if (this.smsCode.length != 4) {
        this.utils.$notifyError("错误", "验证码错误");
        return;
      }
      this.$apis
        .userVerifyMobile(
          {
            mobile: this.user.mobile,
            sms_code: this.smsCode,
          },
          this.token
        )
        .then((data) => {
         
          if (data.data.status === 0) {
            this.getCodeText = "1";
            const loading = this.$loading({
              lock: true,
              text: "加载中",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 1)",
            });
            setTimeout(() => {
              loading.close();
              this.dialogVisible = "change_mobile_new";
            }, 1100);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 修改手机号
     */
    userUpdateMobile() {
      if (this.newSmsCode.length != 4) {
        this.utils.$notifyError("错误", "验证码错误");
        return;
      }
      this.$apis
        .userUpdateMobile(
          { new_mobile: this.newMobile, sms_code: this.newSmsCode },
          this.token
        )
        .then((data) => {
         
          if (data.data.status == 0) {
            this.$message({
              message: data.data.message,
              type: "success",
            });
            this.dialogVisible = false;
            this.newSmsCode = "";
            this.newMobile = "";
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 修改密码
     */
    userEditPassword() {
      if (
        this.newPwd !== this.newPwd2 ||
        this.newPwd === "" ||
        this.pwd === ""
      ) {
        this.utils.$notifyError("错误", "两次密码不一");
        return;
      }
      this.$apis
        .userEditPassword(
          { old_password: this.pwd, new_password: this.newPwd },
          this.token
        )
        .then((data) => {
         
          if (data.data.status == 0) {
            this.$message({
              message: data.data.message,
              type: "success",
            });
            this.dialogVisible = false;
            this.newPwd = "";
            this.pwd = "";
            this.newPwd2 = "";
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    // 更改巡护单位
    nodifyUnit() {
      this.$refs.modify.open();
    },
    // 修改手机号
    modifyMobile() {},
  },
  mounted() {
    if (this.$route.query.nav == "set") {
      this.currentUrlStorage(["首页", "个人中心", "账户设置"]);
    }
    const num = this.user.mobile.slice(-7, -3);
    this.telphoneNumber = this.user.mobile.replace(num, "****");
  },
  computed: {
    ...mapState(["user", "token"]),
  },
};
</script>

<style lang="less" scoped>
.settingNav {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  padding: 0px 30px;
}
.number {
  width: 200px;
  margin-left: 5%;
  text-align: center;
}
.changeBtn {
  margin-right: 5%;
}
.setBody {
  width: 100%;
  height: 104px;
  background-color: #ffffff;
  box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.numtit {
  color: #3c4353;
  font-size: 14px;
}
.numword {
  color: #838a9d;
  font-size: 12px;
}

.phone {
  .code-btn {
    position: absolute;
    top: 150px;
    left: 56%;
    color: #1182fb;
    font-size: 14px;
    cursor: default;
  }
  .phone-el-input {
    width: 16vw;
    margin: 0 auto;
  }
}
</style>
