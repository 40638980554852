// 任务相关接口
import {
    fly,
    baseURL,
    start,
    finishRes,
    finishErr,
    deploy
} from '../fly.js'

// 添加请求拦截器
fly.interceptors.request.use(
    (request) => {
        start(request)
    }
)

// 添加响应拦截器，响应拦截器会在then/catch处理之前执行
fly.interceptors.response.use(
    (response) => {
        finishRes(response)
    },
    (err) => {
        finishErr(err)
    }
)

//  添加设备
export const AddCategory = (data, token) => fly.post(
    baseURL + '/device/addCategory',
    data, deploy(30000, token)
)

//  添加类别
export const AddDevice = (data, token) => fly.post(
    baseURL + '/device/addDevice',
    data, deploy(30000, token)
)


// 类别查询
export const ListCategory = (data, token) => fly.post(
    baseURL + '/device/listCategory',
    data, deploy(30000, token)
)

//  设备查询
export const ListDevice = (data, token) => fly.post(
    baseURL + '/device/listDevice',
    data, deploy(30000, token)
)

// 品牌查询
export const ListBrand = (data, token) => fly.post(
    baseURL + '/device/listBrand',
    data, deploy(30000, token)
)

// 型号查询
export const ListModel = (data, token) => fly.post(
    baseURL + '/device/listModel',
    data, deploy(30000, token)
)

// 状态查询
export const ListStatus = (data, token) => fly.post(
    baseURL + '/device/listStatus',
    data, deploy(30000, token)
)

// 更新设备
export const UpdateDevice = (data, token) => fly.post(
    baseURL + '/device/updateDevice',
    data, deploy(30000, token)
)

// 删除设备
export const delteDev = (data, token) => fly.post(
    baseURL + '/device/deleteDevice',
    data, deploy(30000, token)
)

// 添加品牌
export const deviceAddBrand = (data, token) => fly.post(
    baseURL + '/device/addBrand',
    data, deploy(30000, token)
)

// 添加型号
export const deviceAddModel = (data, token) => fly.post(
    baseURL + '/device/addModel',
    data, deploy(30000, token)
)

// 删除品牌
export const deviceDeleteBrand = (data, token) => fly.post(
    baseURL + '/device/deleteBrand',
    data, deploy(30000, token)
)

// 删除型号
export const deviceDeleteModel = (data, token) => fly.post(
    baseURL + '/device/deleteModel',
    data, deploy(30000, token)
)

// 更新品牌
export const deviceUpdateBrand = (data, token) => fly.post(
    baseURL + '/device/updateBrand',
    data, deploy(30000, token)
)

// 更新型号
export const deviceUpdateModel = (data, token) => fly.post(
    baseURL + '/device/updateModel',
    data, deploy(30000, token)
)

// 飞马在线设备
export const getFeiMaLocation = (data, token) => fly.post(
    baseURL + '/location/getFeiMaLocation',
    data, deploy(30000, token)
)

// 飞马历史架次
export const getFeiMaHistoryFlight = (data, token) => fly.post(
    baseURL + '/location/getFeiMaHistoryFlight',
    data, deploy(30000, token)
)

// 在线/离线通用无人机
export const getCommonPlane = (data, token) => fly.post(
    baseURL + '/location/getCommonPlane',
    data, deploy(30000, token)
)

// 在线和历史终端
export const getTerminal = (data, token) => fly.post(
    baseURL + '/location/getTerminal',
    data, deploy(30000, token)
)

// 获取不同设备坐标位置
export const getDeviceLocation = (data, token) => fly.post(
    baseURL + '/location/getDeviceLocation',
    data, deploy(30000, token)
)


// 获取设备的详细信息
export const getDeviceDetailInfo = (data, token) => fly.post(
    baseURL + '/location/getDeviceDetailInfo',
    data, deploy(30000, token)
)
// 设备的监控数据
export const getDeviceMonitorData = (data, token) => fly.post(
  baseURL + '/location/getDeviceMonitorData',
  data, deploy(120000, token)
)

// 获取直播url
export const getLiveUrl = (data, token) => fly.post(
    baseURL + '/device/getLiveUrl',
    data, deploy(30000, token)
  )
// 获取视频列表
export const getLiveList = (data, token) => fly.post(
    baseURL + '/deviceMissionRecord/all',
    data, deploy(30000, token)
)