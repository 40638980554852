import { getDistance } from "../Tools/geographic.js";
import { planeM } from "../Tools/getPlaneImage.js";
import { wgs84togcj02, gcj02towgs84 } from "../Tools/transfrom.js";
import { PathConfig } from "../index.js";

let _isFixedHeight = false; // 定高航线

export default class FmMonitorBase {
  constructor(options) {
    const {  platform, } = options;
    this.platform = platform;
    // 处理后的数据存储在这里
    this.flightPanelDatas = new FlightPanel();
    this.droneInfo = new DroneInfo();

    this.flightMission = options.flight_data
    this.isBackPlay = false
    // 已飞航线
    this.flyingPoints = new Proxy([], {
      set: (target, propKey, value, receiver) => {
        if (typeof value === "object") {
          // console.log(value)
          const {latitude:lat, longitude:lng, altitude:alt, } = value
          let val = this.toWgs84Position({lat, lng, alt,});
          this.onPositionUpdated(val);
        }
        return Reflect.set(target, propKey, value, receiver);
      },
    });
    // 初始航线/任务航线
    this.baseLinePoints = [];

    // 辅助数据
    // 地图初始经纬度
    this.latitude = "";
    this.longitude = "";
    this.currentPosition = new Position(); // 飞机当前位置， gcj02
    this.wgs84CurrentPosition = new Position(); // 飞机当前位置, wgs84
    // 航线起始点
    this.homePoint = {
      latitude: "",
      longitude: "",
    };
    this.endPoint = {
      latitude: "",
      longitude: "",
    };
    this.planeType = ""; // 飞机型号
    this.planeRotate = 0; // 飞机角度
    // this.playType = ''
    this._currentYaw = 0; // 飞机yaw
    this._speedGround = 0; // 速度
    this._currentGroundSpeed = 0;
    this._currentAirSpeed = 0;
    this._lastUpdateLocationTime = "";
    this._lastUpdateAttitudeTime = "";
    this.isLoading = true;
    this.coordSystem = this.getFlightCoordSystem();
  }
    /**
       * 获取飞行任务坐标系
       * @returns
       */
    getFlightCoordSystem() {
      if (typeof this.flightMission.extra_data === 'string') {
        try {
          this.flightMission.extra_data = JSON.parse(this.flightMission.extra_data);
        } catch (e) {
          console.log('解析extraData失败');
        }
      }
      if (this.flightMission.extra_data.coordinate_system) {
        return this.flightMission.extra_data.coordinate_system;
      }
      if (planeM.isDjiDevice(this.flightMission.plane_type)) {
        return 'GCJ02';
      }
      return 'WGS84';
    }
  // 获取flightMissionInfo
  getFlightMisissionInfo(flightMissionInfo) {
    if (typeof flightMissionInfo === "string") {
      return JSON.parse(flightMissionInfo);
    } else {
      return flightMissionInfo;
    }
  }
  // 初始各项数据, 数据处理
  initDatas(flightMission) {
    this.planeType = flightMission.plane_type;

    this.getAirline(flightMission)

    this.getFlightMissionDatas(flightMission);
  }
  // 初始化航线数据
  getAirline(flightMission) {
    this.getHomePoint(flightMission)

    let pointString = flightMission["task_coordinate_waypoints"];
    if (
      pointString == "undefined" ||
      pointString == "" ||
      pointString == null
    ) {
      return;
    }
    let polineDataArray = pointString.split("FF");
    if (polineDataArray.length >= 2) {
      let entryDirection = parseInt(flightMission["entry_direction"]);
      if (entryDirection == 2 || entryDirection == 4) {
        pointString = polineDataArray[1];
      } else {
        pointString = polineDataArray[0];
      }
    }
    // 获取航线polyline
    let pointItem = pointString.split(";"); // 坐标数组
    let ret = [],
      index = 0;
    for (var i in pointItem) {
      var arrayItem = pointItem[i].split(",");

      if (arrayItem.length >= 2) {

        const alt = this.getFlightAltitude(arrayItem[2], flightMission, i)
        let posi = this.toGcj02Position({ lat: parseFloat(arrayItem[0]), lng: parseFloat(arrayItem[1]), alt })

        ret[index] = posi;
      }
      index++;
    }
    ret.unshift(this.homePoint);
    ret.push(this.homePoint);
    this.baseLinePoints = [...ret];
    if (this.baseLinePoints.length !== 0) {
      this.flightPanelDatas.total_point_count = this.baseLinePoints.length - 2;
    }

     // 结束点
     let endPoint = this.baseLinePoints.slice(-2)[0];
     const endAlt = this.getFlightAltitude(endPoint.altitude, flightMission); // 海拔高度
     this.endPoint = { ...endPoint, altitude: endAlt }
  }
  // 获取起飞点
  getHomePoint(flightMission) {
    // 起飞点
    let alt = this.getHomeElevation(flightMission);
    let homePoint = this.toGcj02Position({
      lat: parseFloat(flightMission["home_lat"]),
      lng: parseFloat(flightMission["home_lng"]),
      alt
    })

    this.homePoint = homePoint;
    this.latitude = homePoint.latitude;
    this.longitude = homePoint.longitude;
  }
  // 获取航线任务数据
  getFlightMissionDatas(flightMission) {
    if (flightMission["created_at"] != "") {
      let date = new Date(this.flightMission.created_at); //将起飞时间转为时间戳。
      this.flightMission["flyCreated_at"] = date.valueOf();
      this.flightMission.created_at = flightMission["created_at"].split(" ")[0];

    }

    // 航点进度初始化
    this.flightPanelDatas.flight_progress = `${this.flightPanelDatas.finished_point_count}/${this.flightPanelDatas.total_point_count}`;
    // 经纬度显示值初始化
    this.flightPanelDatas.latitude = parseFloat(this.latitude).toFixed(6);
    this.flightPanelDatas.longitude = parseFloat(this.longitude).toFixed(6);

    this.flightMission.startLat = this.homePoint.latitude;
    this.flightMission.startLng = this.homePoint.longitude;
    // this.lastUas.uas_odometer_value = res['lastUas']['uas_odometer_value'];

    //初始化目标高度
    if (flightMission["flight_relative_altitude"]) {
      let target_speed = new Number(flightMission["default_air_speed"]);

      const updatedHeightSpeed = {
        target_height: flightMission["flight_relative_altitude"].toFixed(1) + "m",
        target_speed: target_speed.toFixed(1) + "m/s",
      };
      this.flightPanelDatas = {
        ...this.flightPanelDatas,
        ...updatedHeightSpeed,
      };
    }
  }
  // 更新航线/飞机状态数据
  updateDatItem(dataItem) {
    if (!dataItem) {
      return;
    }
    // 协议调整兼容,
    let item;
    if(!dataItem.message) {
      item = {}
      // 值类型为数组，其中第0项为时间戳，第1项为值
      Object.keys(dataItem).forEach(key=>{
        if(!key || !dataItem[key][0] || !dataItem[key][0][1]===undefined) return
        const val = dataItem[key][0][1]
        if(key=="flight_mission_id") {
          item[key] = val
        } else {
          item[key] = parseFloat(val)
        }
      })
    } else {
      try {
        item = dataItem.message[0] && dataItem.message[0][1] ? JSON.parse(dataItem.message[0][1]) : undefined
      } catch (error) {
        console.log(error)
      }
    }
    if(!item) return
    if (item.msg_id == 29 || item.msg_id == 168) {
      this.flightPanelDatas.fixPlane = false;
    }
    this.processMsg(item)
  }

  // 更新航线/飞机状态数据
  updateLogDataItem(dataArray) {
    if (!dataArray) {
      return;
    }

    for (let index = 0; index < dataArray.length; index++) {
      if(!dataArray[index]) return

      const item = dataArray[index][0][1] && JSON.parse(dataArray[index][0][1])
      // item && (item.time_boot_ms = dataArray[index][0][0])
      if(!item) return
      if (item.msg_id == 29 || item.msg_id == 168) {
        this.flightPanelDatas.fixPlane = false;
      }
      this.processMsg(item)
    }
  }
  /**
   * 更新mqtt数据，复用之前的processMsg函数进行处理
   * @param {*} data 获取到的数据
   * @returns 
   */
  updateMqttData(data){
      // item && (item.time_boot_ms = dataArray[index][0][0])
      if(!data) return
      if (data.msg_id == 29 || data.msg_id == 168) {
        this.flightPanelDatas.fixPlane = false;
      }
      this.processMsg(data)
  }
  processMsg(item) {
    switch (item.msg_id || item.msgid) {
      case 1:
        this.updatePowerNDevice(item); // 更新飞机电池状态与控制设备信息
        this.updateFlyMode(item); // 更新飞机飞行模式
        break;
        case 2:
          if(!planeM.isDjiDevice(this.planeType) || this.coordSystem === 'WGS84') {
            this.updateCurrentTime(parseInt(item.time_boot_ms / 1000)); // 更新飞机电池状态与控制设备信息
          }
        // this.updateCurrentTime(parseInt(item.time_boot_ms / 1000)); // 更新飞机电池状态与控制设备信息
        break;
      case 24:
        this.updateGPS(item); // 更新GPS
        break;
      case 30:
        this.updateTimeNAngle(item); // 延时更新角度
        break;
      case 33:
        if(planeM.isDjiDevice(this.planeType) && this.coordSystem !== 'WGS84') {
          this.updateCurrentTime(item.time_boot_ms); // 更新飞机电池状态与控制设备信息
        }
        this.updateTimeNLocation(item); // 延时更新位置
        break;
      case 42:
        this.updateFlightPoint(item["seq"]);
        break;
      case 56:
        this.updatePowerRemain(item); // 更新电量信息
        break;
      case 74:
        this.updateSpeed(item.air_speed || item.airspeed); // 更新速度
        this.updateMileage(item.current_mileage); // 更新里程
        break;
      case 168:
        this.updateWindSpeed(item); // 更新风速
        break;
      case 180:
        this.flightPanelDatas.photo_count = item["img_idx"]; // 更新照片数量
        break;
      default:
        break;
    }
  }
  // 更新飞行模式
  updateFlyMode(data) {
    const { extended_status } = data
    // 只有V系列需要更新该模式字段
    if(this.flightMission.plane_type.indexOf('V') !== -1 ) {
      this.droneInfo.fly_mode = extended_status & 32 ? '固定翼模式' : '多旋翼模式'
    }
  }
  updatelocation(dataArray) {
    if (!dataArray) {
      console.log("no data");
      return;
    }
    // console.log(dataArray);
    // for (let index = 0; index < dataArray.length; index++) {
      let item = dataArray;

      if (item.msgid == 33 || item.msg_id == 33) {

        const { time_boot_ms, lat, lon, lng, alt, relative_alt } = item;
        if (lat && (lng || lon)){
          let position = this.toGcj02Position({
            lat: lat || lat || this.currentPosition.latitude,
            lng: lon || this.currentPosition.longitude,
            alt: alt || this.currentPosition.altitude,
            relative_alt: relative_alt
          });
          // let position = gcj02towgs84( lon, lat, alt,)
          // console.log("快进高度",alt);
          // const position = wgs84togcj02(lng || lon, lat, alt); // 坐标转换
          this.updateBackPlay(position);
        }
      }
    // }
  }
  // 更新风速
  updateWindSpeed(data) {
    let speed = "-";
    // 经讨论后端不再返回speed字段，统一由前端计算风速，20220422 14：27
    // if (!planeM.isNewProtocolDevice(this.planeType)) {
      speed = Math.sqrt(Math.pow(data.x, 2) + Math.pow(data.y, 2) + Math.pow(data.z, 2));
    // } else {
    //   speed = data.speed;
    // }
    this.flightPanelDatas.windSpeed = Math.abs(Math.round(speed)) + "m/s";
  }
  // 更新压力信息
  updateTemperatire(press) {
    var temp = Math.floor(parseFloat(press["temperature"]) / 100);
    this.droneInfo.temperature = parseInt(temp);
  }
  // 更新飞机电量与控制设备信息
  updatePowerNDevice(item) {
    if (!planeM.isNewProtocolDevice(this.planeType)) {
      let power = item["battery_remaining"];

      if (power == 0) {
        power = item["battery_remaining_mah"];
      }

      if (!power) {
        power = 0;
      }

      // this.updatePowerInfo(power, item.failsafe_status, item.extended_status);
      this.updateControlDeviceInfo(item);
    }

    if (item["distance"]) {
      let mileage = new Number(item["distance"] / 1000);

      if (mileage >= 0) {
        this.flightPanelDatas.flight_miles = mileage.toFixed(1) + "km";
      }
    }
  }
  // 更新电池信息
  updateBatteryInfo(item){
    const batteryCount = planeM.getBatteryNumOfPlane(this.flightMission.plane_type)

    const updateStatByType = (state, bit, info, statesArr)=> {
      if(state & bit) {
        statesArr.push(info)
      }
    }

    const getSafeStates = (safestate) => {
      const stateArr = [] // 安全状态数组

      const updateStat = (bit, info,) => {
        updateStatByType(safestate, bit, info, stateArr)
      }
      updateStat(0x0001, '电池失效')
      updateStat(0x0002, '欠压')
      updateStat(0x0004, '放电过流')
      updateStat(0x0008, '放电超温')
      updateStat(0x0010, '放电低温')
      updateStat(0x0020, '放电短路')
      updateStat(0x0040, '电池不平衡')
      updateStat(0x0080, '放电MOS超温')
      updateStat(0x0100, '过压')
      updateStat(0x0200, '')
      updateStat(0x0400, '充电过流')
      updateStat(0x0800, '充电超温')
      updateStat(0x1000, '充电低温')

      return stateArr
    }
    const getOtherSates = (otherstate) => {
      const stateArr = [] // 其他状态数组
      const updateStat = (bit, info)=> {
        updateStatByType(otherstate, bit, info, stateArr)
      }
      updateStat(0x0001, 'BMS开关机状态')
      updateStat(0x0002, '自放电状态')
      updateStat(0x0004, '锁定状态')
      updateStat(0x0008, '放电MOS状态')
      updateStat(0x0010, '充电MOS状态')
      updateStat(0x0020, '飞行状态')
      updateStat(0x0040, '满充状态')
      updateStat(0x0080, '预充电MOS状态')
      updateStat(0x0100, '在线状态')
      updateStat(0x0200, '电池学习状态')

      return stateArr
    }
    for (let index=0;index<batteryCount;index++) {

      const safeState = item[`battery${index}_safeState`]
      const otherState = item[`battery${index}_otherstate`]

      this.droneInfo.safeState = getSafeStates(safeState)
      this.droneInfo.otherState = getOtherSates(otherState)

    }
  }

  // 更新飞机电量信息
  updatePowerInfo(batteryRemain, failsafe_status, extended_status) {

    if (failsafe_status & 1) this.updateWarning("智能电池故障", "error");
    if (failsafe_status & 2) this.updateWarning("智能电池故障", "error");
    if (failsafe_status & 4) this.updateWarning("电量低于5%", "error");
    if (failsafe_status & 8) this.updateWarning("GPS失锁", "error");
    if (failsafe_status & 16) this.updateWarning("链路中断", "error");
    if (failsafe_status & 32) this.updateWarning("IMU故障", "error");
    if (failsafe_status & 64) this.updateWarning("姿态异常", "error");
    if (failsafe_status & 128) this.updateWarning("大风异常", "error");
    if (extended_status & 1) this.updateWarning("已开伞", "ready");
  }
  // 更新飞机电量
  updatePowerRemain(item) {
    const batteryCount = planeM.getBatteryNumOfPlane(this.flightMission.plane_type)

    for (let index = 0; index < batteryCount; index++) {
      const prefix = `[${index}]`;
      if (item[prefix+'remaining'] !== 0){
        this.droneInfo[`drone_battery_percent_${index}`] = `${item[`${prefix}remaining`]}%`;
        this.droneInfo[`drone_battery_voltage_${index}`] = `${item[`${prefix}voltage`] / 1000}V`;
        this.droneInfo[`drone_battery_current_${index}`] = `${item[`${prefix}current`]}mA`;
        this.droneInfo[`drone_battery_temperature_${index}`] = `${item[`[${index}]` + 'temperature'] / 100}℃`;
      }
    }
  }
  updateControlDeviceInfo(item) {
    //图传

    let signalType = "2.4G";

    if (item["onboard_control_sensors_health"] == 1) {
      signalType = "5.8G";
    }

    this.droneInfo.hd_signal_sup = signalType;

    const baseUrl = PathConfig.signalImageBaseUrl;

    let hdSignal = "";
    if (parseInt(item["extended_status"]) <= 5) {
      hdSignal = item["extended_status"];
    } else {
      hdSignal = "5";
    }

    this.droneInfo.hd_signal_icon = baseUrl + hdSignal + ".png"; //遥控器信号
  }
  // 更新GPS信息
  updateGPS(data) {
    const { satellites, pdop, fix_type } = data;
    this.droneInfo.gps_signal_sup = satellites + "";

    let gpsSignal = 5;

    if (satellites - 3 < gpsSignal) {
      gpsSignal = satellites - 3;

      if (gpsSignal < 0) {
        gpsSignal = 0;
      }
    }
    if(pdop) this.droneInfo.pdop = pdop / 100;
    if (satellites)
      this.droneInfo.gps_signal_icon = PathConfig.signalImageBaseUrl + gpsSignal + ".png";

    // gps及rtk
    const upperBytes = (fix_type & 0xf0) >> 4  // rtk 高四位
    const lowerBytes = fix_type & 0x0f  // gps 低四位
    this.updateRtkStatus(upperBytes)
    this.updateGpsStatus(lowerBytes)
  }
  // 更新RTK状态
  updateRtkStatus(status) {
    let rtkStatus = '未连接'
    switch(status) {
      case 0:
        rtkStatus = '未连接'
        break;
      case 4:
        rtkStatus = '固定解'
        break;
      case 5:
        rtkStatus = '浮点解'
        break;
    }
    this.droneInfo.rtk_status = rtkStatus
  }
  // 更新GPS状态
  updateGpsStatus(status) {
    let gpsStatus = 'NO FIX'
    switch(status) {
      case 2:
        gpsStatus = '3D+RTK'
        break;
      case 3:
        gpsStatus = '3D+DGPS'
        break;
      case 4:
        gpsStatus = '3D'
        break;
      case 5:
        gpsStatus = '2D'
        break;
      default:
        gpsStatus = 'NO FIX'
        break;
    }
    this.droneInfo.gps_status = gpsStatus
  }
  // 延时更新角度
  updateTimeNAngle(data) {

    const { time_boot_ms, yaw, roll, pitch } = data;

    // 过滤延时的包
    if (time_boot_ms) {
      const _curTime = parseInt(time_boot_ms);

      if (this._lastUpdateAttitudeTime) {
        if (this._lastUpdateAttitudeTime < _curTime) {
          this._lastUpdateAttitudeTime = _curTime;
        } else {
          // console.log("丢掉一个角度包");
          return;
        }
      } else {
        this._lastUpdateAttitudeTime = _curTime;
      }

      // let timeStamp = parseInt(time_boot_ms); //因为这个页面只针对了两种机型,所以是对的。
      // let timeStamp = parseInt(_curTime / 1000);
      // this.updateCurrentTime(timeStamp);
    }

    // 角度更新
    let yawdeg = (yaw * 180) / Math.PI;
    let rolldeg = (roll * 180) / Math.PI;
    let pitchdeg = (pitch * 180) / Math.PI;

    if (planeM.isDjiDevice(this.planeType)) {
      yawdeg = yaw;
      rolldeg = roll;
      pitchdeg = pitch;
    } else if (planeM.isNewProtocolDevice(this.planeType)) {
      yawdeg = -yawdeg; //D200机头反向
    }

    //固定翼飞机
    if (!planeM.isPlaneDevice(this.planeType)) {
      this.updateAngle_fix({ roll: rolldeg, yaw: yawdeg, pitch: pitchdeg });
    } else {
      this.updateAngle({
        roll: rolldeg,
        yaw: yawdeg,
        pitch: pitchdeg,
      });
    }

    this.updatePitchYawRoll({
      roll: rolldeg,
      yaw: yawdeg,
      pitch: pitchdeg,
    })
  }
  updateAngle(angleInfo) {
    if (
      this._currentYaw != angleInfo.yaw &&
      Math.abs(this._currentYaw - angleInfo.yaw) > 2
    ) {
      (this._currentYaw = angleInfo.yaw), (this.planeRotate = 0); // _this._updateMakerAngle(angleInfo.yaw);

      this.updateAttitudeAngle(angleInfo.yaw); //console.info("角度"+angleInfo.yaw);
    }
  }
  updateAngle_fix(angleInfo) {
    if (
      this._currentYaw != angleInfo.yaw &&
      Math.abs(this._currentYaw - angleInfo.yaw) > 5
    ) {
      this._currentYaw = angleInfo.yaw;
      this.planeRotate = 0;
      this.updateAttitudeAngle(angleInfo.yaw);
    }
  }
  // 姿态盘角度 (0 ~ 360) 正北为0 顺时针递增
  updateAttitudeAngle(angle) {
    const real = parseInt(angle) + 270;
    this.flightPanelDatas.angle = real + "°";

    this.planeRotate = parseInt(angle);
  }
  // 姿态更新
  updatePitchYawRoll(angle) {
    this.flightPanelDatas = {
      ...this.flightPanelDatas,
      ...angle
    };
  }
  onReceiveData(data) {
    this.processMsg(data);
  }
  // 更新时间
  updateCurrentTime(time) {
    if(!planeM.isDjiDevice(this.planeType)) {
      const nowTime = Date.now();
      time = parseInt((nowTime - this.flightMission.flyCreated_at)/ 1000) ;
    }

    const hour = parseInt((time / 60 / 60) % 60);
    let hourStr = "" + hour;

    if (hour < 10) {
      hourStr = "0" + hourStr;
    }

    const minute = parseInt((time / 60) % 60);
    let minuteStr = "" + minute;

    if (minute < 10) {
      minuteStr = "0" + minuteStr;
    }

    let secondStr = "" + (time % 60);

    if (time % 60 < 10) {
      secondStr = "0" + secondStr;
    }
    this.flightPanelDatas.flight_duration =
      hour + ":" + minuteStr + ":" + secondStr;
  }
  // 延时更新位置信息
  updateTimeNLocation(data,isBackPlay=false) {
    let { time_boot_ms, lat, lon, alt, relative_alt } = data;
    //过滤延时的包
    if (time_boot_ms) {
      const curTime = parseInt(time_boot_ms);

      if (this._lastUpdateLocationTime) {
        if (this._lastUpdateLocationTime < curTime || Math.abs(this._lastUpdateLocationTime - curTime) > 1000) {
          this._lastUpdateLocationTime = curTime;
        } else {
          // console.log("丢掉一个位置包");
          return;
        }
      } else {
        this._lastUpdateLocationTime = curTime;
      }
    }

    // console.log("更新飞机速度");
    if (planeM.isNewProtocolDevice(this.planeType)) {
      const speedX = data.vx / 100.0;
      const speedY = data.vy / 100.0;
      const speedZ = data.vz / 100.0;

      try {
        const groundSpeed = Math.sqrt(
          speedX * speedX + speedZ * speedZ
        ).toFixed(1);

        // this.flightPanelDatas.speed = groundSpeed + "m/s";
        this.flightPanelDatas.speedZ = speedZ + "m/s";
      } catch (e) {}
    } else {
      const speedZ = data.vz / 100.0;
      this.flightPanelDatas.speedZ = speedZ + "m/s";
    }
    let position = this.toGcj02Position({
      lat: lat || lat || this.currentPosition.latitude,
      lng: lon || this.currentPosition.longitude,
      alt: alt || this.currentPosition.altitude,
      relative_alt: relative_alt
    });
    if (isBackPlay){
      return position;
    }
    this.updatePlaneGpsTextInfo(position); // 更新飞机GPS信号.
    this.updatePlaneLocation(position); // 更新飞机位置


    // 获取飞机距离与高度
    let dist = getDistance(
      this.homePoint["latitude"],
      this.homePoint["longitude"],
      position.latitude,
      position.longitude
    );
    // dist = Math.round(dist * 1000)
    dist = dist < 1000 ? dist + "m" : Math.round(dist / 10) / 100 + "km";

    this.flightPanelDatas.drone_pilot_distance = dist;
    this.droneInfo.drone_pilot_distance = dist;

    // this.flightPanelDatas.altitude = Math.floor(this.isBackPlay ? alt/1000 : alt) + "m";
    // this.flightPanelDatas.drone_height = Math.floor(this.isBackPlay ? relative_alt/1000 : relative_alt) + "m";
     this.flightPanelDatas.altitude = Math.floor(alt) + "m";
    //  if (this.isBackPlay){
    //    let home_alt = this.getHomeElevation(this.flightMission)
    //    relative_alt = this.currentPosition.altitude-home_alt > 0 ? this.currentPosition.altitude-home_alt : 0
    //  }
    this.flightPanelDatas.drone_height = Math.floor(relative_alt) + "m";
  }

  /**
   * 更新飞机的位置,请就偏后再设置进来
   * @param point
   */
  updatePlaneGpsTextInfo(point) {
    try {
      (this.flightPanelDatas.latitude = point.latitude.toFixed(6) + ""),
        (this.flightPanelDatas.longitude = point.longitude.toFixed(6) + "");
    } catch (e) {}
  }
  /**
   * 更新飞机的位置,请就偏后再设置进来
   * 只做标记点的初始化和currentPosition属性的更新。
   * 标记点marker更新请在外部自行处理
   * @param point
   */
  updatePlaneLocation(point) {

    this.currentPosition = point;
    this.wgs84CurrentPosition = gcj02towgs84( point.longitude, point.latitude, point.altitude,)
    this.updateFlightLines(point);
  }
  /**
   * 回放的更新航线和里程
   * @param {*} position
   */
   updateBackPlay(position) {
    let val;
    if (planeM.isDjiDevice(this.planeType)) {
      val = gcj02towgs84(position.longitude, position.latitude);
    } else {
      val = position;
    }
    this.backPlayMove(val);
  }
  /**
   * 更新已经飞过的航线和里程
   * @param position
   */
  updateFlightLines(position) {
    if (this.flyingPoints != null) {
      this.flyingPoints.push(position);
    } else {
      this.flyingPoints = [position];
    }
  }

  /**
   * 更新警告信息
   * @param text
   * @param type
   */
  updateWarning(text, type) {
    let color = "#FF3250";
    let iconIsOpen = false;

    if (type == "error") {
      color = "#FF3250";
    } else if (type == "normal") {
      color = "#1AAF5D";
    } else if (type == "warning") {
      color = "#FFCD00";
    } else if (type == "ready") {
      color = "#3485F7";
      iconIsOpen = true;
    }

    if (iconIsOpen) {
      // $("#pilot-icon").show();
    } else {
      // $("#pilot-icon").hide();
    } // $("#flight-tip-pilot").css("background-color", color);
    // $("#flght-tip-content").text(text);
    // $("#flight-tip").fadeIn(2000, function () {
    //   setTimeout(function () {
    //     $("#flight-tip").fadeOut(1500);
    //   }, 3000);
    // });
  }
  //更新航点信息
  updateFlightPoint(point) {
    const { finished_point_count } = this.flightPanelDatas;
    if (Number(finished_point_count) != point && point < 1000) {
      this.flightPanelDatas.finished_point_count = point + "";
      this.flightPanelDatas.flight_progress = `${point}/${this.flightPanelDatas.total_point_count}`;
    }
  }
  // 更新里程
  updateMileage(current_mileage) {
    if (current_mileage) {
      try {
        var mileage = new Number(current_mileage / 1000);

        this.flightPanelDatas.flight_miles = mileage.toFixed(3);
      } catch (e) {}
    }
  }
  /**
   * 更新速度
   * @param groundSpeed 对地速度
   * @param airSpeed 空中速度
   */
  updateSpeed(airSpeed) {
    if (airSpeed){
      let groundSpeed = Number(airSpeed).toFixed(1);
      airSpeed = Number(airSpeed).toFixed(1);

      if (groundSpeed != this._currentGroundSpeed) {
        (this._currentGroundSpeed = groundSpeed),
          (this._speedGround = groundSpeed);
        this.flightPanelDatas.speed = groundSpeed + "m/s";
      }

      if (airSpeed != this._currentAirSpeed) {
        this._currentAirSpeed = airSpeed;
      }
    }

  }
  // 格式化plane数据
  formatPlane(plane) {
    if (plane) {
      if (plane["type"] == 0) {
        plane.validity = "永久";
      } else {
        plane.validity = plane["expired_at"];
      }
    } else {
      plane = {
        validity: "------",
      };
    }
    return plane;
  }
  // 格式化uasInfo
  formatUasInfo(uasInfo) {
    let newVal = {};
    if (uasInfo[0]["created_at"] !== "") {
      newVal.startTime = uasInfo[0]["created_at"].split(" ")[1];
    }
    if (uasInfo[1] != undefined) {
      newVal.endTime = uasInfo[0]["created_at"].split(" ")[1];
      newVal.factFlight =
        uasInfo[1]["uas_odometer_value"] - uasInfo[0]["uas_odometer_value"];
    } else {
      newVal.factFlight = "------";
    }
    return newVal;
  }
  // 格式化keyInfo
  formatKeyInfo(keyInfo) {
    if (!keyInfo) {
      keyInfo.key_id = "------";
    }
    return keyInfo;
  }
  // 格式化taskInfo
  formatTaskInfo(taskInfo) {
    if (!taskInfo) {
      taskInfo = {
        photo_count: "-",
        work_scale: "",
        total_mileage: "",
      };
    }
    return taskInfo;
  }
  // 格式化planeInfo
  formatPlaneInfo(planeInfo) {
    if (!planeInfo) {
      planeInfo = {
        uas_serial_id: "------",
        name: "",
      };
    }
    return planeInfo;
  }

  initLastUpdateTime() {
    this._lastUpdateAttitudeTime = null;
    this._lastUpdateLocationTime = null;
  }

    // 航线高度取值逻辑
  // 1. 第一个点航线的高度为0， 取home_elevation + flight_relative_altitude
  // 2. 第一个点航线的高度不为0，但是等于flight_relative_altitude， 取home_elevation + flight_relative_altitude
  // 3. 第一个点航线高度不为0，且大于起飞点高度，但是低于安全高度（50），取home_elevation + flight_relative_altitude
  // 3. 第一个点航线高度大于等于home_elevation + flight_relative_altitude， 取航线高度
  getFlightAltitude(alt, flightMission, pointIndex) {
    !alt && (alt = 0)
    alt = parseFloat(alt);
    const { home_elevation, flight_relative_altitude, flight_altitude } = flightMission

    const homeAlt = parseFloat(home_elevation)
    const flightRelAlt = parseFloat(flight_relative_altitude)
    const homePlusRelAlt = homeAlt + flightRelAlt
    // 判断是否定高
    if(pointIndex && parseInt(pointIndex) === 0 ) {
      if(alt === 0 || alt === flightRelAlt || ( alt > homeAlt && alt - homeAlt < 50)) {
        _isFixedHeight = true
      } else {
        _isFixedHeight = false
      }
    }

    // 是定高，则
    if(_isFixedHeight) {
      if (planeM.isDjiDevice(this.planeType)) {
        alt = flight_altitude // D000下的大疆无人机，inspare, phantom 4, marvic， 高度都使用flight_altitude
      } else {
        alt = homePlusRelAlt
      }
    }

    return alt
  }
 /**
  * wgs84坐标转化为gcj02坐标
  * @param { Object } position, { lat, lng/lon, alt, relative_alt }
  * @returns { longitude, latitude, altitude }
  */
  toGcj02Position(position) {
    const { lat, lng, lon, alt, relative_alt } = position
    if(planeM.isDjiDevice(this.planeType) && this.coordSystem !== 'WGS84') {
      position = {
        latitude: lat,
        longitude: lng || lon,
        altitude: relative_alt || alt || 0,
      }
    } else {
      position = wgs84togcj02(lng, lat, alt || 0 ); // 坐标转换
    }

    return position
  }
  /**
  * gcj02坐标转化为wgs84坐标
  * @param { Object } position, { lat, lng/lon, alt, relative_alt }
  * @returns  { longitude, latitude, altitude }
  */
  toWgs84Position(position) {
    const { lat, lng, lon, alt, relative_alt } = position

    if(planeM.isDjiDevice(this.planeType) && this.coordSystem !== 'WGS84') {
      position = gcj02towgs84(lng || lon, lat, relative_alt || alt || 0 ); // 坐标转换
    } else {
      position = {
        latitude: lat,
        longitude: lng || lon,
        altitude: alt,
      }
    }
    return position
  }
  getHomeElevation(flightMission) {
    let alt;
    if (planeM.isDjiDevice(this.planeType)) {
      alt = parseFloat(flightMission["flight_altitude"]) - parseFloat(flightMission["flight_relative_altitude"])  // D000下的大疆无人机，inspare, phantom 4, marvic， 高度都使用flight_altitude
    } else {
      alt = parseFloat(flightMission["home_elevation"])
    }

    return alt
  }
  /**
   * 监听位置改变
   * @param {Object} e
   */
  onPositionUpdated(e) {}

}

export class FlightPanel {
  constructor() {
    this.drone_pilot_distance = "";
    this.latitude = 0;
    this.longitude = 0;
    this.temperature = "0°";
    this.windSpeed = "0m/s";
    this.speed = "0m/s";
    this.speedZ="0m/s";
    this.target_speed = "0m/s";
    this.angle = "0°";
    this.pitch = 0;
    this.yaw = 0;
    this.roll = 0;
    this.target_height = "0m";
    this.drone_height = "0m";
    this.flight_miles = "0km";
    this.flight_duration = "00:00:00";
    this.finished_point_count = 0;
    this.total_point_count = 0;
    this.photo_count = 0;
    this.fixPlane = true;
  }
}

export class DroneInfo {
  constructor() {
    this.drone_pilot_distance = "";
    this.gps_signal_icon = `${PathConfig.signalImageBaseUrl}5.png`;
    this.gps_signal_sup = "";
    this.hd_signal_icon = `${PathConfig.signalImageBaseUrl}5.png`;
    this.hd_signal_sup = "2.4G";
    this.drone_battery_percent = "";
    this.drone_battery_percent_2 = "";
    this.drone_battery_percent_3 = "";
    this.pdop = "1.0";
    this.rtk_status = '未连接';
    this.fly_mode = '';
    this.gps_status = 'NO FIX';
  }
}

export class Position {
  constructor() {
    this.latitude = 0;
    this.longitude = 0;
    this.altitude = 0;
  }
}
