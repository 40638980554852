<template>
  <div class="uavBody">
    <navTop />
    <div class="bodyBar">
      <div class="titleBar">待审核申请数据</div>
      <el-divider></el-divider>
      <div class="top">
        <el-form
          class="formbox"
          ref="ruleForm"
          :model="ruleForm"
          label-width="100px"
        >
          <el-form-item
            class="userForm"
            prop="status"
            label="状态查询"
            style="width: 300px"
          >
            <el-select
              v-model="ruleForm.status"
              placeholder="请选择"
              @change="getStatus"
              filterable
              clearable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="userForm"
            prop="data_apply_no"
            label="订单号"
            style="width: 300px"
          >
            <el-input
              placeholder="请输入"
              v-model="ruleForm.data_apply_no"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="userForm"
            prop="mobile"
            label="用户账号"
            style="width: 340px"
          >
            <el-input placeholder="请输入" v-model="ruleForm.mobile"></el-input>
          </el-form-item>
          <div class="footBtn">
            <div class="fmBtn">
              <el-button class="searchBtn" type="primary" @click="searchBtn"
                >查&nbsp;&nbsp;&nbsp;&nbsp;询</el-button
              >
              <el-button
                class="reset"
                @click="resetForm('ruleForm')"
                type="primary"
                plain
                >重&nbsp;&nbsp;&nbsp;&nbsp;置</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <div class="tableBar">
        <el-table
          height="85%"
          :stripe="true"
          :header-cell-style="{ background: '#DDE6EF' }"
          :data="tableData"
        >
          <el-table-column
            align="center"
            prop="data_apply_no"
            label="订单号"
            class="number"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="mobile"
            label="用户账号"
          ></el-table-column>
          <el-table-column align="center" prop="disaster_name" label="状态">
            <template slot-scope="scope">
              <span class="shstatus" v-if="scope.row.status == 1">审核中</span>
              <span v-if="scope.row.status == 2">待确认</span>
              <span v-if="scope.row.status == 3">已退回</span>
              <span v-if="scope.row.status == 4">已完成</span>
              <span v-if="scope.row.status == 5">已终止</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="category"
            label="数据申请类型"
            width="300"
          >
            <template slot-scope="scope">
              <span
                class="typeline"
                v-for="(item, index) in JSON.parse(scope.row.category)"
                :key="index"
                >{{ item }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="integrality"
            label="数据完整性"
            width="200"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.integrality == 0"
                >可接受只覆盖部分区域</span
              >
              <span v-if="scope.row.integrality == 1">只接受只全区域覆盖</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="open_date"
            label="开放日期"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="open_time"
            label="开放期"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="created_at"
            label="创建时间"
            width="150"
          ></el-table-column>
          <el-table-column align="center" label="审核">
            <template slot-scope="scope">
              <el-button
                @click="acceptance(scope.row)"
                type="text"
                :disabled="scope.row.status != 1"
                >审核</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button @click="editBtn(scope.row)" type="text"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="block">
          <el-pagination
            @current-change="currentchange"
            @next-click="handleNext"
            @prev-click="handlePrev"
            :current-page="current_page"
            :page-size="this.per_page"
            layout="total,  prev, pager, next, jumper"
            :total="this.total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      current_page: 1, //当前页
      total: 0, //总条目数
      per_page: 9, //每页多少条
      page: 1,
      options: [
        {
          value: "1",
          label: "审核中",
        },
        {
          value: "2",
          label: "待确认",
        },
        {
          value: "3",
          label: "已退回",
        },
        {
          value: "4",
          label: "已完成",
        },
      ],
      ruleForm: {
        status: "",
        data_apply_no: "",
        mobile: "",
      },
      tableData: [],
    };
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    resetForm(ruleForm) {
      this.$refs["ruleForm"].resetFields();
    },
    // 获取status
    getStatus(param) {
      this.ruleForm.status = param;
    },
    // 查询
    searchBtn() {
      this.page = 1;
      this.manageApplyData();
    },
    // 审核按钮
    acceptance(param) {
      this.$router.push({ path: "/auditpage", query: { id: param.id } });
    },
    // 详情按钮
    editBtn(param) {
      this.$router.push({ path: "/datadetails", query: { id: param.id } });
    },
    // 分页
    handleNext() {
      this.page++;
      this.manageApplyData();
    },
    handlePrev() {
      this.page--;
      this.manageApplyData();
    },
    currentchange(page) {
      this.page = page;
      this.manageApplyData();
    },
    // 申请数据
    manageApplyData() {
      this.$apis
        .manageApplyData(
          {
            per_page: 9,
            page: this.page,
            data_apply_no: this.ruleForm.data_apply_no,
            status: this.ruleForm.status,
            mobile: this.ruleForm.mobile,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status == 0) {
            this.tableData = data.data.data.data;
            this.total = data.data.data.total;
            this.current_page = data.data.data.current_page;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  created() {
    this.manageApplyData();
  },
  computed: {
    ...mapState(["token"]),
  },
  mounted() {
    if (this.$route.query.nav == "dman") {
      this.currentUrlStorage(["首页", "后台管理", "数据申请管理"]);
    }
  },
};
</script>

<style lang="less" scoped>
.block {
  float: left;
  margin-top: 15px;
}
.typeline {
  margin-right: 5px;
}
.shstatus {
  color: #fd5c5e;
}
.bodyBar {
  width: 100%;
  height: 90%;
  float: left;
  background-color: #fff;
  padding: 23px 20px 0px 20px;
}
.uavBody {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  padding: 0px 30px 15px 30px;
}
.titleBar {
  font-size: 16px;
  color: #c7c7c7;
}
/deep/.el-input {
  width: 200px;
}
/deep/.el-form-item {
  float: left;
  width: 35%;
  margin-left: 5px;
}
.blok {
  color: #fd5c5e;
}
.tableBar {
  height: 90%;
}
/deep/.el-pagination__editor.el-input {
  width: 50px !important;
}
/deep/ .el-table .cell {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
}
/deep/ .el-table .cell:hover {
  text-overflow: inherit;
  overflow: visible;
  white-space: pre-line;
}
</style>