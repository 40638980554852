<template>
  <!-- 注册账号 -->
  <div class="centerWindow">
    <div class="loginBgc">
      <div class="fmWelcome">
        <div class="fmWelLogin">
          <p class="sayHello">您好,</p>
          <p class="welLogin">欢迎登录</p>
          <p class="fmsider"></p>
          <p class="fmTitle">无人机巡护指挥调度管理平台</p>
          <!-- <p class="sonTitle">调度中心端</p>调度中心端 -->
        </div>
      </div>
      <div class="fmLoginNav">
        <div class="loginNav">
          <div class="loginBox">
            <p class="loginTitle">身份验证</p>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="FmInput"
              v-if="flagOne"
            >
              <el-form-item ref="mobile" prop="mobile" class="demo-ruleForm">
                <el-input
                  placeholder="请输入手机号"
                  v-model="ruleForm.mobile"
                ></el-input>
              </el-form-item>
              <el-form-item prop="sms_code" class="demo-ruleForm">
                <el-input
                  placeholder="请输入验证码"
                  v-model="ruleForm.sms_code"
                >
                  <el-button
                    @click="getRegister"
                    class="getVerification"
                    slot="suffix"
                    type="text"
                    :disabled="disabled"
                    :sms_code="ruleForm.sms_code"
                  >
                    <!-- 获取验证码 -->
                    {{ btnTitle }}
                  </el-button>
                </el-input>
              </el-form-item>
              <el-form-item
                ref="password"
                prop="password"
                class="demo-ruleForm"
              >
                <el-input
                  placeholder="请输入密码"
                  v-model="ruleForm.password"
                  type="password"
                ></el-input>
              </el-form-item>
              <el-form-item class="SignInBar">
                <el-button class="loginBar" type="primary" @click="userRegister"
                  >下&nbsp;一&nbsp;步</el-button
                >
              </el-form-item>
            </el-form>
            <div class="footerBar" v-if="flagOne">
              <el-link class="register" @click="registerNum" :underline="false"
                >登录账号</el-link
              >
            </div>
            <div class="regisSuccess" v-if="FlagTwo">
              <img class="loginImg" src="../../assets/img/reg-login.png" alt />
              <p class="rrgisWord">重置密码成功 !</p>
              <p class="logintext">{{ timeDown }}s后自动跳转</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let rule = /^1[345789][0-9]{9}$/;
export default {
  data() {
    return {
      timeDown: 3,
      disabled: false, //是否可点击
      btnTitle: "获取验证码",
      ruleForm: {
        mobile: "", //手机号
        sms_code: "", // 验证码
        password: "",
      },
      rules: {
        mobile: [
          { required: true, message: "手机号码不能为空", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确手机号", trigger: "blur" },
        ],
        sms_code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 4, max: 4, message: "请输入正确验证码", trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            transform: (value) => value,
            trigger: "blur",
          },
          {
            type: "string",
            message: "请输入不包含空格的字符",
            trigger: "blur",
            transform(value) {
              if (value && value.indexOf(" ") === -1) {
                return value;
              } else {
                return false;
              }
            },
          },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              var passwordreg = /().{6,16}/;
              if (!passwordreg.test(value)) {
                callback(
                  new Error("密码必须由数字、字母、特殊字符组合,请输入6-16位")
                );
              } else {
                callback();
              }
            },
          },
        ],
      },
      page: 1,
      flagOne: true, //整个表单隐藏
      FlagTwo: false, //注册成功后的状态
    };
  },
  methods: {
    registerNum() {
      this.$router.push("/");
    },
    // 短信通过下一步按钮
    userRegister() {
      // validate：验证当前表单元素中所有的规则
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.fogetPassData();
          this.flagOne = false;
          this.FlagTwo = true;
        } else {
        }
      });
    },
    // 3秒后自动跳转
    countDown() {
      let time = 3;
      let timer = setInterval(() => {
        if (time > 0) {
          this.timeDown = time;
          time--;
        } else if (time == 0) {
          this.$router.push("/");
          clearInterval(timer);
        }
      }, 1000);
    },
    // 新密码数据提交
    fogetPassData() {
      this.$apis
        .resetPassword({
          mobile: this.ruleForm.mobile,
          password: this.ruleForm.password,
          sms_code: this.ruleForm.sms_code,
        })
        .then((data) => {
          if (data.data.status == 0) {
            this.countDown();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 发送验证码
    getRegister() {
      // 开启倒计时
      if (this.ruleForm.mobile.search(rule) != 0) {
        this.$message.error("手机号不能为空或不合法");
        return;
      } else {
        // 发送验证码请求
        this.$apis
          .note({
            action: "reset_password",
            mobile: this.ruleForm.mobile,
          })
          .then((data) => {
            if (data.data.status == 0) {
              // 倒计时
              this.validateBtn();
              this.$message({
                message: data.data.message,
                type: "success",
              });
            }
          })
          .catch((err) => {});
      }
    },
    validateBtn() {
      //倒计时
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.disabled = false;
          this.btnTitle = "获取验证码";
        } else {
          this.btnTitle = time + "秒后重试";
          this.disabled = true;
          time--;
        }
      }, 1000);
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
.regisSuccess {
  margin-top: 150px;
  text-align: center;
  .loginImg {
    width: 70px;
    height: 70px;
    margin: 0 auto;
  }
  .rrgisWord {
    font-size: 24px;
    line-height: 32px;
    height: 32px;
  }
  .logintext {
    height: 32px;
    font-size: 14px;
    color: rgba(131, 138, 157, 1);
    line-height: 32px;
  }
}
.footerBar {
  width: 370px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 30px;
  .register {
    cursor: pointer;
  }
}
.centerWindow {
  width: 100%;
  height: 100%;

  overflow: auto;
  background: url("../../assets/img/bgc-login.png") no-repeat center;
  background-size: 100% 100%;
  .loginBgc {
    width: 100%;
    height: 100%;
    min-width: 1280px;
    min-height: 600px;
    display: flex;
    .fmWelcome {
      display: block;
      margin: 0 auto;
      padding-top: 25vh;
      margin-left: 6%;
      .sayHello {
        width: 120px;
        height: 52px;
        font-size: 40px;
        font-family: MicrosoftYaHei;
        color: rgba(255, 255, 255, 1);
        line-height: 52px;
      }
      .welLogin {
        width: 160px;
        height: 52px;
        line-height: 82px;
        font-size: 40px;
        color: rgba(255, 255, 255, 1);
      }
      .fmsider {
        width: 166px;
        height: 4px;
        background: rgba(255, 255, 255, 0.43);
        margin-top: 59px;
      }
      .fmTitle {
        width: 850px;
        height: 74px;
        font-size: 56px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        line-height: 74px;
        margin-top: 51px;
      }
      .sonTitle {
        width: 250px;
        height: 64px;
        font-size: 48px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        line-height: 64px;
        margin-top: 53px;
      }
    }
  }
  .fmLoginNav {
    padding: 68px 68px 0px 0px;
    .loginNav {
      width: 615px;
      min-height: 720px;
      height: calc(100vh - 136px);
      background: rgba(255, 255, 255, 0.9);
      border-radius: 20px;
      padding: 50px 164px 50px 127px;
      .loginBox {
        width: 100%;
        height: 100%;
        .loginTitle {
          width: 128px;
          height: 42px;
          font-size: 32px;
          color: rgba(59, 66, 83, 1);
          line-height: 167px;
        }
        .demo-ruleForm {
          margin-top: 40px;
          width: 370px;
          .getVerification {
            color: #0094ff;
            margin-right: 20px;
            cursor: pointer;
          }
        }
        .oldPhone {
          margin-top: 120px;
          width: 370px;
          font-size: 18px;
          color: #838a9d;
        }
        .fmSelect {
          width: 370px;
        }
        .SignInBar {
          margin-top: 150px;
          .loginBar {
            font-size: 20px;
            width: 370px;
            height: 55px;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
.FmInput {
  margin-top: 110px;
}
/deep/ .el-input__inner {
  border-radius: 8px;
  height: 44px;
  border: 2px solid rgba(166, 170, 184, 1);
  background-color: rgba(255, 255, 255, 0.3);
}
/deep/.el-divider__text {
  background-color: transparent;
}
@media only screen and (max-width: 1650px) {
  .centerWindow {
    width: 100%;
    height: 100%;

    .loginBgc {
      min-width: 1280px;
      min-height: 800px;

      width: 100%;
      height: 100%;
      overflow: hidden;
      background: url("../../assets/img/bgc-login.png") no-repeat center;
      background-size: 100% 100%;
      .fmWelcome {
        display: block;
        margin-left: 6%;
        .sayHello {
          width: 120px;
          height: 52px;
          font-size: 30px;
          font-family: MicrosoftYaHei;
          color: rgba(255, 255, 255, 1);
          line-height: 52px;
        }
        .welLogin {
          width: 160px;
          height: 52px;
          line-height: 82px;
          font-size: 30px;
          color: rgba(255, 255, 255, 1);
        }
        .fmsider {
          width: 166px;
          height: 4px;
          background: rgba(255, 255, 255, 0.43);
          margin-top: 59px;
        }
        .fmTitle {
          width: 100%;
          height: 74px;
          font-size: 36px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          line-height: 74px;
          margin-top: 51px;
        }
        .sonTitle {
          width: 240px;
          height: 64px;
          font-size: 28px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          line-height: 64px;
          margin-top: 53px;
        }
      }
    }
    .fmLoginNav {
      display: block;
      margin-top: 10%;
      .loginNav {
        width: 480px;
        height: 530px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 20px;
        float: right;
        padding: 25px 70px 45px 58px;
        .loginBox {
          width: 100%;
          height: 100%;
          .loginTitle {
            width: 128px;
            height: 42px;
            font-size: 32px;
            color: rgba(59, 66, 83, 1);
            line-height: 2px;
            margin-top: 32px;
          }
          .loginNavbar {
            margin-top: 35px;
          }
          .demo-ruleForm {
            margin-top: 37px;
            width: 370px;
          }
          .SignInBar {
            margin-top: 100px;
            .loginBar {
              font-size: 20px;
              width: 370px;
              height: 55px;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
  .FmInput {
    margin-top: 90px;
  }
}
</style>
