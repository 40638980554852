// 群组
import {
    fly,
    baseURL,
    start,
    finishRes,
    finishErr,
    deploy
  } from '../fly.js'
  
  // 添加请求拦截器
  fly.interceptors.request.use(
    (request) => {
      start(request)
    }
  )
  
  // 添加响应拦截器，响应拦截器会在then/catch处理之前执行
  fly.interceptors.response.use(
    (response) => {
      finishRes(response)
    },
    (err) => {
      finishErr(err)
    }
  )

  // 获取项目巡护
export const chatListUnit = (data, token) => fly.post(
  baseURL + '/chat/listUnit', data, deploy(30000, token)
)

  // 获取项目用户
export const chatListUser = (data, token) => fly.post(
  baseURL + '/chat/listUser', data, deploy(30000, token)
)

 // 获取项目群组
export const chatListGroup = (data, token) => fly.post(
  baseURL + '/chat/listGroup', data, deploy(30000, token)
)

 // 获取项目群组用户
export const chatListGroupUser = (data, token) => fly.post(
  baseURL + '/chat/listGroupUser', data, deploy(30000, token)
)


  // 获取聊天房间
export const chatRoomList = (data, token) => fly.get(
    baseURL + '/chat/roomList', data, deploy(30000, token)
)

 // 创建聊天房间
export const chatCreateRoom = (data, token) => fly.post(
  baseURL + '/chat/createRoom', data, deploy(30000, token)
)

// 获取聊天记录
export const chatRecord = (data, token) => fly.get(
    baseURL + '/chat/getRecord', data, deploy(30000, token)
)

// 发送消息
export const chatSendMsg = (data, token) => fly.post(
  baseURL + '/chat/sendMsg', data, deploy(30000, token)
)

// 添加聊天成员
export const chatAddRoomUser = (data, token) => fly.post(
  baseURL + '/chat/addRoomUser', data, deploy(30000, token)
)

// 聊天未读消息
export const chatUnreadNum = (data, token) => fly.get(
  baseURL + '/chat/unreadNum', data, deploy(30000, token)
)

// 发起音视频通讯
export const chatOpenMedia = (data, token) => fly.post(
  baseURL + '/chat/openMedia', data, deploy(30000, token)
)

// 获取音视频成员信息
export const chatRoomUser = (data, token) => fly.get(
  baseURL + '/chat/getRoomUser', data, deploy(30000, token)
)