var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.vloading,
          expression: "vloading",
        },
      ],
      staticClass: "taskList",
    },
    [
      _c("publish-task", {
        attrs: { isComponents: _vm.isComponents },
        on: {
          getMap: _vm.getMap,
          showTable: _vm.showTable,
          changeMap: _vm.changeMap,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "switchType" }, [
        _c("div", { ref: "switchActiveRef", staticClass: "switchActive" }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "drone",
            style: { color: _vm.task_type === "无人机" ? "#fff" : "#000" },
            on: {
              click: function ($event) {
                return _vm.changeSwitchType("drone")
              },
            },
          },
          [_vm._v("\n        无人机\n      ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "drone",
            style: { color: _vm.task_type === "非无人机" ? "#fff" : "#000" },
            on: {
              click: function ($event) {
                return _vm.changeSwitchType("onDrone")
              },
            },
          },
          [_vm._v("\n        非无人机\n      ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "drone",
            style: { color: _vm.task_type === "火情上报" ? "#fff" : "#000" },
            on: {
              click: function ($event) {
                return _vm.changeSwitchType("fire")
              },
            },
          },
          [_vm._v("\n        火情上报\n      ")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showOrHideTable,
              expression: "showOrHideTable",
            },
          ],
          ref: "search",
          staticClass: "search",
        },
        [
          _c("el-card", { attrs: { shadow: "never" } }, [
            _c("div", { ref: "searchTable", staticClass: "searchTable" }, [
              _c("div", { staticClass: "searchTableBox" }, [
                _c("span", { staticClass: "searchTitle" }, [
                  _vm._v(" 发布ID "),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "searchData" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入", size: "small" },
                      model: {
                        value: _vm.searchForm.demand_order_no,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "demand_order_no", $$v)
                        },
                        expression: "searchForm.demand_order_no",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "searchTableBox" }, [
                _c("span", { staticClass: "searchTitle" }, [
                  _vm._v(" 任务名称 "),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "searchData" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入", size: "small" },
                      model: {
                        value: _vm.searchForm.task_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "task_name", $$v)
                        },
                        expression: "searchForm.task_name",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "searchTableBox" }, [
                _c("span", { staticClass: "searchTitle" }, [
                  _vm._v(" 任务来源 "),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "searchData" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.searchForm.order_source,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "order_source", $$v)
                          },
                          expression: "searchForm.order_source",
                        },
                      },
                      _vm._l(_vm.order_sourceList, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.pathQuery !== "draft",
                      expression: "pathQuery !== 'draft'",
                    },
                  ],
                  staticClass: "searchTableBox",
                },
                [
                  _c("span", { staticClass: "searchTitle" }, [
                    _vm._v(" 发布人 "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "searchData" },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", size: "small" },
                        model: {
                          value: _vm.searchForm.created_user_mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "created_user_mobile", $$v)
                          },
                          expression: "searchForm.created_user_mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "searchTableBox" }, [
                _c("span", { staticClass: "searchTitle" }, [
                  _vm._v(" 发布方式 "),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "searchData" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.searchForm.created_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "created_type", $$v)
                          },
                          expression: "searchForm.created_type",
                        },
                      },
                      _vm._l(_vm.created_typeList, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.pathQuery !== "toAudit" &&
                        _vm.pathQuery !== "draft",
                      expression:
                        "pathQuery !== 'toAudit' && pathQuery !== 'draft'",
                    },
                  ],
                  staticClass: "searchTableBox",
                },
                [
                  _c("span", { staticClass: "searchTitle" }, [
                    _vm._v(" 核发人 "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "searchData" },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", size: "small" },
                        model: {
                          value: _vm.searchForm.order_audit_user_mobile,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchForm,
                              "order_audit_user_mobile",
                              $$v
                            )
                          },
                          expression: "searchForm.order_audit_user_mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.pathQuery !== "toAudit" &&
                        _vm.pathQuery !== "back" &&
                        _vm.pathQuery !== "draft",
                      expression:
                        "\n            pathQuery !== 'toAudit' &&\n            pathQuery !== 'back' &&\n            pathQuery !== 'draft'\n          ",
                    },
                  ],
                  staticClass: "searchTableBox",
                },
                [
                  _c("span", { staticClass: "searchTitle" }, [
                    _vm._v(" 完善人 "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "searchData" },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", size: "small" },
                        model: {
                          value: _vm.searchForm.order_complete_user_mobile,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchForm,
                              "order_complete_user_mobile",
                              $$v
                            )
                          },
                          expression: "searchForm.order_complete_user_mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.pathQuery !== "toAudit" &&
                        _vm.pathQuery !== "back" &&
                        _vm.pathQuery !== "draft",
                      expression:
                        "\n            pathQuery !== 'toAudit' &&\n            pathQuery !== 'back' &&\n            pathQuery !== 'draft'\n          ",
                    },
                  ],
                  staticClass: "searchTableBox",
                },
                [
                  _c("span", { staticClass: "searchTitle" }, [
                    _vm._v(" 派单人 "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "searchData" },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", size: "small" },
                        model: {
                          value: _vm.searchForm.order_send_user_mobile,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchForm,
                              "order_send_user_mobile",
                              $$v
                            )
                          },
                          expression: "searchForm.order_send_user_mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "searchTableBox" }, [
                _c("span", { staticClass: "searchTitle" }, [
                  _vm._v(" 截止工期 "),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "searchData" },
                  [
                    _c("el-date-picker", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "date",
                        size: "small",
                        "value-format": "yyyy-MM-dd",
                        placeholder: "选择日期",
                      },
                      model: {
                        value: _vm.searchForm.end_date,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "end_date", $$v)
                        },
                        expression: "searchForm.end_date",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.pathQuery !== "toAudit" &&
                        _vm.pathQuery !== "back" &&
                        _vm.pathQuery !== "draft",
                      expression:
                        "\n            pathQuery !== 'toAudit' &&\n            pathQuery !== 'back' &&\n            pathQuery !== 'draft'\n          ",
                    },
                  ],
                  staticClass: "searchTableBox",
                },
                [
                  _c("span", { staticClass: "searchTitle" }, [
                    _vm._v(" 任务状态 "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "searchData" },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { size: "small" },
                          model: {
                            value: _vm.searchForm.demand_status,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "demand_status", $$v)
                            },
                            expression: "searchForm.demand_status",
                          },
                        },
                        _vm._l(_vm.taskStatusList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { ref: "searchBtn", staticClass: "searchBtn" },
              [
                _c(
                  "el-button",
                  { attrs: { size: "small" }, on: { click: _vm.resetSearch } },
                  [_vm._v("重置")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.searchTask },
                  },
                  [_vm._v("查询")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.pathQuery !== "draft",
                        expression: "pathQuery !== 'draft'",
                      },
                    ],
                    attrs: { type: "text", size: "small" },
                    on: { click: _vm.hideSearch },
                  },
                  [
                    _vm._v(_vm._s(_vm.searchText) + "\n          "),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.searchText === "展开",
                          expression: "searchText==='展开'",
                        },
                      ],
                      staticClass: "el-icon-arrow-down",
                    }),
                    _vm._v(" "),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.searchText === "收起",
                          expression: "searchText==='收起'",
                        },
                      ],
                      staticClass: "el-icon-arrow-up",
                    }),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showOrHideTable,
              expression: "showOrHideTable",
            },
          ],
          staticClass: "dataList",
          attrs: { id: "datalist" },
        },
        [
          _c(
            "el-card",
            {
              staticClass: "datalistCard",
              staticStyle: { height: "347px" },
              attrs: { shadow: "never" },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    height: "244px",
                    overflow: "auto",
                    width: "97%",
                    "margin-left": "20px",
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.isLoading,
                          expression: "isLoading",
                        },
                      ],
                      staticStyle: { width: "100%", "font-size": "13px" },
                      attrs: {
                        data: _vm.taskListData,
                        height: "244",
                        "highlight-current-row": "",
                        size: "small",
                      },
                      on: { "current-change": _vm.handleCurrentChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "demand_order_no",
                          label: "发布ID",
                          width: _vm.tableTdWidth.demand_order_no,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", [
                                  _c("span", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.row.is_read === 0,
                                        expression: "scope.row.is_read === 0",
                                      },
                                    ],
                                    staticClass: "isNotRead",
                                  }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(scope.row.demand_order_no) +
                                        "\n              "
                                    ),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "task_name",
                          label: "任务名称",
                          width: _vm.tableTdWidth.task_name,
                          "show-overflow-tooltip": true,
                          "min-width": "200",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.task_name
                                  ? _c("span", [
                                      _vm._v(_vm._s(scope.row.task_name)),
                                    ])
                                  : _c("span", [
                                      _c("div", { staticClass: "noData" }),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "order_source",
                          label: "任务来源",
                          width: _vm.tableTdWidth.order_source,
                          "show-overflow-tooltip": true,
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "created_user_mobile",
                          label: "发布人",
                          width: _vm.tableTdWidth.created_user_mobile,
                          "show-overflow-tooltip": true,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      trigger: "click",
                                      placement: "right",
                                      title: "发布人",
                                      width: "263",
                                      "popper-class": "information123",
                                      effect: "light",
                                      "popper-options": _vm.popperOp,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "border-top":
                                            "1px solid rgba(0, 0, 0, 0.06)",
                                          width: "100%",
                                          "padding-top": "8px",
                                          "line-height": "20px",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "creatUnitName" },
                                          [
                                            _vm._v(
                                              "账户: " +
                                                _vm._s(
                                                  scope.row.created_user_mobile
                                                )
                                            ),
                                          ]
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "creatUnitName" },
                                          [
                                            _vm._v(
                                              "姓名: " +
                                                _vm._s(
                                                  _vm.orderData
                                                    .created_user_name
                                                )
                                            ),
                                          ]
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "creatUnitName" },
                                          [
                                            _vm._v(
                                              "账户类型: " +
                                                _vm._s(
                                                  _vm.orderData
                                                    .created_user_role_name
                                                )
                                            ),
                                          ]
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "creatUnitName",
                                            attrs: {
                                              title:
                                                _vm.orderData
                                                  .created_user_unit_name,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "单位: " +
                                                _vm._s(
                                                  _vm.orderData
                                                    .created_user_unit_name
                                                )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "creatUnitName" },
                                          [
                                            _vm._v(
                                              "发布时间: " +
                                                _vm._s(_vm.orderData.created_at)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "name-wrapper",
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "orderDataName",
                                            staticStyle: {
                                              color: "rgb(96, 98, 102)",
                                            },
                                            attrs: { type: "text" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.created_user_name
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "created_type",
                          label: "发布方式",
                          width: _vm.tableTdWidth.releaseType,
                        },
                      }),
                      _vm._v(" "),
                      _vm.pathQuery !== "toAudit" && _vm.pathQuery !== "draft"
                        ? _c("el-table-column", {
                            attrs: {
                              prop: "audit_user_mobile",
                              label: "核发人",
                              "show-overflow-tooltip": true,
                              width: _vm.tableTdWidth.audit_user_mobile,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.audit_user_mobile
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    trigger: "click",
                                                    placement: "right",
                                                    title: "核发人",
                                                    width: "263",
                                                    "popper-class":
                                                      "information123",
                                                    effect: "light",
                                                    "popper-options":
                                                      _vm.popperOp,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "border-top":
                                                          "1px solid rgba(0, 0, 0, 0.06)",
                                                        width: "100%",
                                                        "padding-top": "8px",
                                                        "line-height": "20px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "creatUnitName",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "账户: " +
                                                              _vm._s(
                                                                scope.row
                                                                  .audit_user_mobile
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "creatUnitName",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "账户类型: 超级管理员"
                                                          ),
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _vm.auditData
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "creatUnitName",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "单位: " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .auditData
                                                                      .unit_name
                                                                  )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _vm.auditData
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "creatUnitName",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "审核时间: " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .auditData
                                                                      .created_at
                                                                  )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "name-wrapper",
                                                      attrs: {
                                                        slot: "reference",
                                                      },
                                                      slot: "reference",
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticClass:
                                                            "orderDataName",
                                                          staticStyle: {
                                                            color:
                                                              "rgb(96, 98, 102)",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row.audit
                                                                .audit_user_name
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c("span", [
                                            _c("div", {
                                              staticClass: "noData",
                                            }),
                                          ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              778708540
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pathQuery !== "toAudit" &&
                      _vm.pathQuery !== "draft" &&
                      _vm.pathQuery !== "back"
                        ? _c("el-table-column", {
                            attrs: {
                              prop: "order_complete_user_mobile",
                              label: "完善人",
                              "show-overflow-tooltip": true,
                              width:
                                _vm.tableTdWidth.order_complete_user_mobile,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.order_complete_user_mobile
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    trigger: "click",
                                                    placement: "right",
                                                    title: "完善人",
                                                    width: "263",
                                                    "popper-class":
                                                      "information123",
                                                    effect: "light",
                                                    "popper-options":
                                                      _vm.popperOp,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "border-top":
                                                          "1px solid rgba(0, 0, 0, 0.06)",
                                                        width: "100%",
                                                        "padding-top": "8px",
                                                        "line-height": "20px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "creatUnitName",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "账户: " +
                                                              _vm._s(
                                                                scope.row
                                                                  .order_complete_user_mobile
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "creatUnitName",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "账户类型: 超级管理员"
                                                          ),
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "creatUnitName",
                                                          attrs: {
                                                            title:
                                                              _vm.orderData
                                                                .order_complete_user_unit_name,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "单位: " +
                                                              _vm._s(
                                                                _vm.orderData
                                                                  .order_complete_user_unit_name
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "creatUnitName",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "完善时间: " +
                                                              _vm._s(
                                                                _vm.orderData
                                                                  .order_complete_datetime
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "name-wrapper",
                                                      attrs: {
                                                        slot: "reference",
                                                      },
                                                      slot: "reference",
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticClass:
                                                            "orderDataName",
                                                          staticStyle: {
                                                            color:
                                                              "rgb(96, 98, 102)",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row
                                                                .order_complete_user_name
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c("span", [
                                            _c("div", {
                                              staticClass: "noData",
                                            }),
                                          ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3898634546
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pathQuery !== "toAudit" &&
                      _vm.pathQuery !== "draft" &&
                      _vm.pathQuery !== "back"
                        ? _c("el-table-column", {
                            attrs: {
                              prop: "order_send_user_mobile",
                              label: "派单人",
                              width: _vm.tableTdWidth.order_send_user_mobile,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.order_send_user_name
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    trigger: "click",
                                                    placement: "right",
                                                    title: "派单人",
                                                    width: "263",
                                                    "popper-class":
                                                      "information123",
                                                    effect: "light",
                                                    "popper-options":
                                                      _vm.popperOp,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "border-top":
                                                          "1px solid rgba(0, 0, 0, 0.06)",
                                                        width: "100%",
                                                        "padding-top": "8px",
                                                        "line-height": "20px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "creatUnitName",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "账户: " +
                                                              _vm._s(
                                                                scope.row
                                                                  .order_send_user_mobile
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "creatUnitName",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "账户类型: 超级管理员"
                                                          ),
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "creatUnitName",
                                                          attrs: {
                                                            title:
                                                              _vm.orderData
                                                                .order_send_user_unit_name,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "单位: " +
                                                              _vm._s(
                                                                _vm.orderData
                                                                  .order_send_user_unit_name
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                      _c("br"),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "creatUnitName",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "派单时间: " +
                                                              _vm._s(
                                                                _vm.orderData
                                                                  .order_send_datetime
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "name-wrapper",
                                                      attrs: {
                                                        slot: "reference",
                                                      },
                                                      slot: "reference",
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticClass:
                                                            "orderDataName",
                                                          staticStyle: {
                                                            color:
                                                              "rgb(96, 98, 102)",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.row
                                                                .order_send_user_name
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c("span", [
                                            _c("div", {
                                              staticClass: "noData",
                                            }),
                                          ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              195959989
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "end_date",
                          label: "截止工期",
                          width: _vm.tableTdWidth.end_date,
                          "show-overflow-tooltip": true,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.end_date
                                  ? _c("span", [
                                      _vm._v(_vm._s(scope.row.end_date)),
                                    ])
                                  : _c("span", [
                                      _c("div", { staticClass: "noData" }),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "任务详情",
                          width: _vm.tableTdWidth.taskInformation,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.lookTaskDetails(
                                          scope.row.id,
                                          scope.row.order_complete_user_mobile,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("查看")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        key: "taskStatus",
                        attrs: {
                          label: "任务状态",
                          "min-width": "100",
                          "show-overflow-tooltip": true,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.status !== 3
                                  ? _c("span", [
                                      scope.row.status === 2
                                        ? _c("span", [
                                            _c("span", {
                                              staticClass: "point",
                                              staticStyle: {
                                                "background-color": "#fac419",
                                              },
                                            }),
                                            _vm._v("\n                待核发"),
                                          ])
                                        : scope.row.status === 3
                                        ? _c("span", [
                                            _c("span", {
                                              staticClass: "point",
                                              staticStyle: {
                                                "background-color": "#fac419",
                                              },
                                            }),
                                            _vm._v("待处理"),
                                          ])
                                        : scope.row.status === 4
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    trigger: "hover",
                                                    placement: "right",
                                                    width: "263",
                                                    "popper-class":
                                                      "information123",
                                                    effect: "light",
                                                    "popper-options":
                                                      _vm.popperOp,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                        "padding-top": "8px",
                                                        "line-height": "20px",
                                                        display: "flex",
                                                        "align-items": "center",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-warning",
                                                        staticStyle: {
                                                          color: "#faad14",
                                                          "font-size": "21px",
                                                          "margin-right":
                                                            "10px",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            display:
                                                              "inline-block",
                                                            width: "280px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            '您提交的任务，因"' +
                                                              _vm._s(
                                                                scope.row.audit
                                                                  .reason
                                                              ) +
                                                              '"的原因无法核发，予以退回。'
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "name-wrapper",
                                                      attrs: {
                                                        slot: "reference",
                                                      },
                                                      slot: "reference",
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass: "point",
                                                        staticStyle: {
                                                          "background-color":
                                                            "rgb(255, 42, 0)",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticStyle: {
                                                            color:
                                                              "rgb(96, 98, 102)",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                        },
                                                        [_vm._v("已退回")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              "\n                草拟\n              "
                                            ),
                                          ]),
                                    ])
                                  : _c("span", [
                                      scope.row.status_cancel === 1
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    trigger: "hover",
                                                    placement: "right",
                                                    width: "263",
                                                    "popper-class":
                                                      "information123",
                                                    effect: "light",
                                                    "popper-options":
                                                      _vm.popperOp,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                        "padding-top": "8px",
                                                        "line-height": "20px",
                                                        display: "flex",
                                                        "align-items": "center",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-warning",
                                                        staticStyle: {
                                                          color: "#faad14",
                                                          "font-size": "21px",
                                                          "margin-right":
                                                            "10px",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            display:
                                                              "inline-block",
                                                            width: "280px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            '您提交的任务，因"' +
                                                              _vm._s(
                                                                scope.row.audit
                                                                  .reason
                                                              ) +
                                                              '"的原因无法执行，被迫中止'
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "name-wrapper",
                                                      attrs: {
                                                        slot: "reference",
                                                      },
                                                      slot: "reference",
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass: "point",
                                                        staticStyle: {
                                                          "background-color":
                                                            "#FF2A00",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticStyle: {
                                                            color:
                                                              "rgb(96, 98, 102)",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                        },
                                                        [_vm._v("已中止")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c("span", [
                                            scope.row.status_task === 0
                                              ? _c("span", [
                                                  _c("span", {
                                                    staticClass: "point",
                                                    staticStyle: {
                                                      "background-color":
                                                        "#FAC419",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "待处理\n                  "
                                                  ),
                                                ])
                                              : scope.row.status_task === 1
                                              ? _c("span", [
                                                  _c("span", {
                                                    staticClass: "point",
                                                    staticStyle: {
                                                      "background-color":
                                                        "#02BE00",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "已外部协作\n                  "
                                                  ),
                                                ])
                                              : _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.jumpPath(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", {
                                                      staticClass: "point",
                                                      staticStyle: {
                                                        "background-color":
                                                          "#02BE00",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "已内部派单\n                  "
                                                    ),
                                                  ]
                                                ),
                                          ]),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _vm.userInfo.is_admin === 1 &&
                      (_vm.pathQuery !== "all" ||
                        _vm.pathQuery !== "toAudit" ||
                        _vm.pathQuery !== "published" ||
                        _vm.pathQuery !== "back" ||
                        _vm.pathQuery !== "draft")
                        ? _c("el-table-column", {
                            key: "status",
                            attrs: {
                              label: "任务处理",
                              width: _vm.tableTdWidth.taskBtn,
                              "min-width": "200",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.status === 2
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.audit(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("核发")]
                                              ),
                                              _vm._v(" "),
                                              _c("span", {
                                                staticClass: "line",
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: { color: "red" },
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.rebut(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("退回")]
                                              ),
                                            ],
                                            1
                                          )
                                        : scope.row.status === 3
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    disabled: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.audit(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("外部协作")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    disabled:
                                                      scope.row
                                                        .status_cancel === 1 ||
                                                      scope.row.status_task !==
                                                        0,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.setDemandInternalOrder(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("内部派单")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  style: {
                                                    color:
                                                      scope.row
                                                        .status_cancel === 1
                                                        ? ""
                                                        : "red",
                                                  },
                                                  attrs: {
                                                    type: "text",
                                                    disabled:
                                                      scope.row
                                                        .status_cancel === 1,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.demandCancelOrder(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("中止")]
                                              ),
                                            ],
                                            1
                                          )
                                        : scope.row.status === 4
                                        ? _c("span", [
                                            _c("div", {
                                              staticClass: "noData",
                                            }),
                                          ])
                                        : _c(
                                            "span",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.public(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("发布")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: { color: "red" },
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.del(scope.row)
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              827042081
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.status !== "",
                      expression: "status !==''",
                    },
                  ],
                  staticClass: "allHaveBeenRead",
                  on: { click: _vm.allRead },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/planTask/brush.png"),
                      alt: "",
                    },
                  }),
                  _vm._v("全部已读\n      "),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("span", { staticStyle: { "margin-right": "20px" } }, [
                    _vm._v("共 " + _vm._s(_vm.totalPage) + "条"),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v(" 每页" + _vm._s(_vm.per_page) + "条 ")]),
                  _vm._v(" "),
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.nowPage,
                      "page-size": _vm.per_page,
                      layout: "prev, pager, next, jumper",
                      total: _vm.totalPage,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.setPage,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "taskDetail" },
        [
          _vm.taskDetailShow
            ? _c(
                "el-dialog",
                {
                  directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
                  attrs: {
                    title: "任务详情",
                    visible: _vm.taskDetailShow,
                    width: "382px",
                    modal: false,
                    "append-to-body": false,
                    "modal-append-to-body": false,
                    "lock-scroll": false,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.taskDetailShow = $event
                    },
                    closeForm: _vm.handleClose,
                  },
                },
                [
                  _c("taskDetail", {
                    ref: "taskDetail",
                    attrs: {
                      id: _vm.id,
                      releaseShow: _vm.taskDetailShow,
                      pathQuery: _vm.pathQuery,
                      showAllData: _vm.showAllData,
                    },
                    on: {
                      closeForm: _vm.closeForm,
                      showCompleteOrder: _vm.showCompleteOrder,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.closeForm },
                        },
                        [_vm._v("关闭")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.afterComplete, width: "382px" },
          on: {
            "update:visible": function ($event) {
              _vm.afterComplete = $event
            },
          },
        },
        [
          _c("i", {
            staticClass: "el-icon-warning",
            staticStyle: {
              color: "#faad14",
              "font-size": "21px",
              "margin-left": "15px",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { staticStyle: { display: "inline-block", width: "280px" } },
            [_vm._v("请对该任务进行派单。")]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { attrs: { type: "primary", disabled: "" } }, [
                _vm._v("外部协作"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.demandInternalOrder },
                },
                [_vm._v("内部派单")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.demandCancelOrder } }, [
                _vm._v("中止"),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.promptDia, width: "382px" },
          on: {
            "update:visible": function ($event) {
              _vm.promptDia = $event
            },
          },
        },
        [
          _c("i", {
            staticClass: "el-icon-warning",
            staticStyle: {
              color: "#faad14",
              "font-size": "21px",
              "margin-left": "15px",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { staticStyle: { display: "inline-block", width: "280px" } },
            [_vm._v("该任务发布方式为一键发布，请优先进行任务完善。")]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.promptDia = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.demandInternalOrder()
                    },
                  },
                },
                [_vm._v("跳过完善")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.setDetailData },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.isShowReason,
            width: "382px",
            modal: false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowReason = $event
            },
          },
        },
        [
          _c("i", {
            staticClass: "el-icon-warning",
            staticStyle: {
              color: "#faad14",
              "font-size": "21px",
              "margin-left": "15px",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { staticStyle: { display: "inline-block", width: "280px" } },
            [
              _vm._v(
                '您提交的任务，因"' +
                  _vm._s(_vm.reason) +
                  '"的原因无法' +
                  _vm._s(_vm.backOrStop)
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isShowReason = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.isShowReason = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.completeInformationShow
        ? _c(
            "div",
            [
              _c("releaseForm", {
                attrs: {
                  listDrawShapeData: _vm.orderData.survey_area,
                  completeData: _vm.completeData,
                  releaseShow: _vm.completeInformationShow,
                  title: "完善任务",
                  userInfo: _vm.orderUserInfo,
                },
                on: {
                  closeForm: _vm.handleClose,
                  completeAkeyOrder: _vm.completeAkeyOrder,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }