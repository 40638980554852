var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShowVideoList,
          expression: "isShowVideoList",
        },
      ],
      staticClass: "video-list",
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "video-item-list" },
        _vm._l(_vm.videoList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "video-item",
              class: { activeVideo: _vm.nowPlayVideoIndex === index },
              on: {
                click: function ($event) {
                  return _vm.playVideo(item, index)
                },
              },
            },
            [
              _c("div", { staticClass: "video-info" }, [
                _c("div", [_c("p", [_vm._v(_vm._s(item.name))])]),
                _vm._v(" "),
                _c("div", { staticClass: "create-at" }, [
                  _c("p", [_vm._v(_vm._s(item.createAt))]),
                ]),
              ]),
              _vm._v(" "),
              _vm.nowPlayVideoIndex === index
                ? _c("div", [
                    _c("p", { staticStyle: { color: "#DBEBEB" } }, [
                      _vm._v("正在播放"),
                    ]),
                  ])
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [_c("p", [_vm._v("视频列表")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }