<template>
  <div class="map newGeneric">
    <!-- 地图 -->
    <div class="amap-wrapper">
      <el-amap
        :amap-manager="amapManager"
        class="amap-box"
        :vid="'amap-vue'"
        :events="events"
        :plugin="plugin"
        :zoom="zoom"
        :center="center"
      >
        <el-amap-marker
          v-for="(marker, index) in markers"
          :key="index"
          :position="marker.position"
          :events="marker.events"
          :visible="marker.visible"
          :icon="marker.icon"
          :content="marker.template"
          :draggable="marker.draggable"
        />
        <el-amap-polygon
          v-for="(polygon, index) in tempolygons"
          v-if="polygon != []"
          :key="'polygon' + index"
          :ref="`polygon_${index}`"
          bubble="true"
          :path="polygon.path"
          :stroke-color="polygon.strokeColor"
          :fill-color="polygon.fillColor"
          :fill-opacity="polygon.fillOpacity"
          :draggable="polygon.draggable"
          :events="polygon.events"
        />
        <el-amap-polygon
          v-for="(polygon, index) in polygonArr"
          v-if="polygon != []"
          :key="index + '00a'"
          :ref="`polygon_${index}`"
          :path="polygon.path"
          :stroke-color="polygon.strokeColor"
          :fill-color="polygon.fillColor"
          :fill-opacity="polygon.fillOpacity"
          :draggable="polygon.draggable"
          :events="polygon.events"
        />
        <!-- <span v-for="(polygonArrs, index) in temPolygonArrs" :key="index+'tem'">
          <el-amap-polygon
            v-for="(polygon, index) in polygonArrs"
            v-if="polygon != []"
            :key="index + '00aa'"
            :ref="`polygon_${index}`"
            :path="polygon.path"
            :stroke-color="polygon.strokeColor"
            :fill-color="polygon.fillColor"
            :fill-opacity="polygon.fillOpacity"
            :draggable="polygon.draggable"
            :events="polygon.events"
          />
        </span> -->
        <div class="iconfont" />
        <!-- <div
          :class="{ pitchOn: alertShow == false }"
          class="iconfont construction cursor"
          @click="newTask"
        >
          &#xe666;
        </div> -->
        <div v-if="alertShow" class="alert-left">
          <!-- 弹出新建任务 -->
          <div class="iconfont headline">
            <div class>新建通用任务</div>
            <div class="closeBtn" @click="newTask">&#xe621;</div>
          </div>
          <div class="eliminate">
            <div class="eliminate-title">
              绘制测区
              <div @click="$refs.kmlInputEl.click()">导入KML</div>
              <input
                type="file"
                ref="kmlInputEl"
                style="display: none"
                accept=".kml, .KML"
                @change="uploadKmlFile"
              />
            </div>
            <div class="eliminate-img" />
            <div class="eliminate-hint">
              请在地图中绘制您想要飞行的测区范围
              <br />（注：至少选择四个点进行绘制）
            </div>
            <div class="eliminate-btn-div">
              <div class="eliminate-btn cursor" @click="obliterateall">
                清除重绘
              </div>
              <div class="eliminate-btn cursor" @click="finishDrawing">
                结束绘制
              </div>
            </div>
          </div>
          <div class="list">
            <div class="topic">
              <div class="topic-view">任务名称</div>
              <el-input
                v-model="taskName"
                class="topic-select"
                placeholder="样例：20210312钦州市青塘镇"
              />
            </div>
            <div class="topic">
              <div class="topic-view">传感器</div>
              <el-select
                v-model="sensor"
                class="topic-select"
                placeholder="请选择"
                @change="topicSelect"
              >
                <el-option
                  v-for="item in sensorArr"
                  :key="item.name"
                  :value="item.name"
                />
              </el-select>
            </div>
            <div class="topic">
              <div class="topic-view">授权空域</div>
              <el-radio-group v-model="airspace" class="topic-el-radio">
                <el-radio
                  v-for="(item, index) in airspaceArr"
                  :key="item.name"
                  :label="item.name"
                  >{{ item.name }}</el-radio
                >
              </el-radio-group>
            </div>
            <div class="topic">
              <div class="topic-view">任务性质</div>
              <el-radio-group v-model="taskNature" class="topic-el-radio">
                <el-radio
                  v-for="(item, index) in taskNatureArr"
                  :key="item.name"
                  :label="item.name"
                  >{{ item.name }}</el-radio
                >
              </el-radio-group>
            </div>
            <div v-if="sensorNum == 1" class="topic">
              <div class="topic-view">影像分辨率</div>
              <el-input
                v-model="imageSize"
                class="topic-select"
                type="number"
                :clearable="true"
                :max="100"
                placeholder="请输入内容"
              />&nbsp;&nbsp;cm
            </div>
            <div v-if="sensorNum == 1" class="topic">
              <div class="topic-view">航向重叠度</div>
              <el-input
                v-model="courseOverlap"
                class="topic-select"
                type="number"
                :clearable="true"
                placeholder="请输入内容"
              />&nbsp;&nbsp;%
            </div>
            <div v-if="sensorNum == 1" class="topic">
              <div class="topic-view">旁向重叠度</div>
              <el-input
                v-model="lateralOverlap"
                class="topic-select"
                type="number"
                :clearable="true"
                placeholder="请输入内容"
              />&nbsp;&nbsp;%
            </div>
            <div v-if="sensorNum == 2" class="topic">
              <div class="topic-view">激光点密度</div>
              <el-input
                v-model="laserDot"
                class="topic-select"
                type="number"
                :clearable="true"
                placeholder="请输入内容"
              />&nbsp;&nbsp;p/m²
            </div>
            <div v-if="sensorNum == 2" class="topic">
              <div class="topic-view">航带重叠度</div>
              <el-input
                v-model="lateralOverlap"
                class="topic-select"
                type="number"
                :clearable="true"
                placeholder="请输入内容"
              />&nbsp;&nbsp;%
            </div>
            <div class="" style="margin-left: 80px; margin-top: 4%">
              <!-- <div class="topic-view"></div> -->
              <el-tooltip
                class="item"
                effect="light"
                content="上传详细说明文件"
                placement="right-start"
              >
                <el-upload
                  class="upload-demo"
                  action="aaaa"
                  accept="All"
                  :file-list="fileList"
                  :on-change="FileUpload"
                  :auto-upload="false"
                  :on-remove="handleRemove"
                >
                  <span class="uploadBtn" slot="trigger">
                    <img src="@/assets/img/upload.png" alt="" width="20" />
                    上传
                  </span>
                  <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
                </el-upload>
              </el-tooltip>
            </div>
          </div>
          <div class="alert-btn">
            <!-- <div class="new-btn cursor" @click="newBtn">新建</div> -->
            <el-button
              class="new-btn"
              type="primary"
              :disabled="!drawingConfirmation"
              @click="newBtn"
              >新建</el-button
            >
            <div class="cancel-btn cursor" @click="newTask">取消</div>
          </div>
        </div>

        <div class="switching">
          <el-select
            v-model="switchingValue"
            @change="switchingChange"
            placeholder="请选择"
          >
            <el-option
              v-for="item in switchingArr"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.platform
              }}</span>
            </el-option>
          </el-select>
        </div>
      </el-amap>
    </div>
    <!-- <div class="toolbar">position: [{{ lng }}, {{ lat }}]</div> -->
  </div>
</template>

<script>
let len = 0; // 缓存的方块个数
let indexes = 0; //  创建的是第几个任务
let area = 0; //  所绘画的多边形面积
let polygon = [];
const turf = require("@turf/turf");
import { AMapManager, elAmapMarker, elAmapPolygon } from "vue-amap";
import { mapState, mapMutations } from "vuex";
const amapManager = new AMapManager();
import coordtransform from "@/utils/transform.js";
import defaultConstants from "@/utils/default-constants";
import { v4 as uuidv4 } from 'uuid';
export default {
  name: "GeneralTask",
  data() {
    const self = this;
    return {
      drawingConfirmation: false, //  确认结束绘制
      taskName: "", //  任务名称
      airspace: "", //  授权空域
      airspaceArr: [], //  授权空域数组
      taskNature: "", //  任务性质
      taskNatureArr: [], //  任务性质数组
      imageSize: "", //  影像分辨率
      courseOverlap: "", // 航向重叠度
      lateralOverlap: "", // 旁向重叠度
      laserDot: "", //  激光点密度
      // beltOverlap: '', // 航带重叠度
      sensor: "", //  传感器
      sensorArr: [], //  传感器数组
      sensorNum: 1,
      alertShow: true, //  左侧alert弹框是否显示
      zoom: 7, // 缩放级别
      center: defaultConstants.mapDefaultLocation, //  地图中心
      lng: "",
      lat: "",
      amapManager: amapManager,
      tempolygons: [],
      polygons: [
        {
          draggable: false,
          strokeColor: "#49B2FF",
          fillColor: "#47ABF5",
          fillOpacity: 0.5,
          path: [],
        },
      ], //  多边形的绑定数据
      polygonArr: [
        {
          draggable: false,
          strokeColor: "#49B2FF",
          fillColor: "#47ABF5",
          fillOpacity: 0.5,
          path: [],
        },
      ], //  方框网格的绑定数据
      markers: [], //  点击地图标点的展示
      elMap: {},
      tianditutext: {},
      tiandituLayer: {},
      tianditutextUrl:
        "http://t{0,1,2,3,4,5,6,7}.tianditu.gov.cn/DataServer?T=cva_w&tk=" +
        process.env.VUE_APP_TIANDITU_KEY +
        "&x=[x]&y=[y]&l=[z]",
      events: {
        /**
         * @param {Object} e
         */
        init(e) {
          self.googleLayer = new AMap.TileLayer({
            getTileUrl:
              "//wprd0{1,2,3,4}.is.autonavi.com/appmaptile?&x=[x]&y=[y]&z=[z]&lang=zh_cn&size=1&scl=1&style=6",
            zIndex: 99,
            zooms: [3, 20],
          });
          // 天地图影像图层
          self.tiandituLayer = new AMap.TileLayer({
            getTileUrl:
              // http://t0.tianditu.gov.cn/img_w/wmts
              "http://t{0,1,2,3,4,5,6,7}.tianditu.gov.cn/DataServer?T=img_w&tk=" +
              process.env.VUE_APP_TIANDITU_KEY +
              "&x=[x]&y=[y]&l=[z]",
            zIndex: 100,
          });
          // 天地图文字图层
          self.tianditutext = new AMap.TileLayer({
            getTileUrl:
              // http://t0.tianditu.gov.cn/img_w/wmts
              self.tianditutextUrl,
            zIndex: 100,
          });
          self.elMap = e;
          // 使用天地图图层
          // self.googleLayer.setMap(null);
          self.tiandituLayer.setMap(self.elMap);
          self.tianditutext.setMap(self.elMap);
          // e.setMapStyle("amap://styles/macaron"); // 自定义的高德地图的样式，我选的是马卡龙
        },
        /**
         * 点击页面，获取当前点击位置的坐标
         * @param {Object} e
         */
        click(e) {
          const { lng, lat } = e.lnglat;
          self.lng = lng;
          self.lat = lat;
        },
      },
      /**
       * 高德插件
       */
      locationMarker: [],
      plugin: [
        {
          pName: "Geolocation",
          showMarker: false,
          events: {
            init(o) {
              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  self.center = [result.position.lng, result.position.lat];
                  self.locationMarker = [
                    result.position.lng,
                    result.position.lat,
                  ];
                }
              });
            },
          },
        },
        // {
        //   pName: "MapType",
        //   defaultType: -1,
        //   events: {
        //     init(o) {
        //       //console.log(o);
        //     },
        //   },
        // },
      ],
      blockNautica: "", //  区块坐标字符串
      taskNauticaArr: [], //  任务坐标字符串数组
      toLeadKml: false, //   当前区域是否为kml导入
      switchingValue: "天地图影像", //  地图切换
      switchingArr: [
        { name: "天地图影像", platform: "天地图" },
        { name: "电子地图", platform: "高德" },
        { name: "影像地图", platform: "高德" },
      ], //  能切换的地图名称
      markersData: [],
      temPolygonArr: [],
      temPolygonArrs: [],
      temBlockNautica: [],
      allLngLat: [], //所有的经纬度，用于查找东北角及西南角
      isTransfrom: false, // 记录gcj02已经转为wgs84
      locationMarkerLayer: "", //点位图标图层
      arrList: [[]], //存储kml区块坐标
      fileList: [], //文件列表
      formData: new FormData(),
      guid: uuidv4().replace(/[-]/g,"")
    };
  },
  computed: {
    ...mapState(["user", "token", "role", "currentUrl"]),
  },
  watch: {
    taskName() {
      // if (this.taskName.length > 15) {
      //   this.utils.$notifyError("错误", "名称长度应在1-15内");
      //   this.taskName = this.taskName.slice(0, 14);
      // }
    },
    /**
     * 当lng的值发生变化是，页面新增点位
     */
    lng() {
      if (this.alertShow != false) {
        if (this.toLeadKml) {
          this.arrList = [[]];
          this.obliterate(); //清除绘制
          this.toLeadKml = false;
        }
        const obj = {
          position: [this.lng, this.lat],
          events: {},
          visible: true,
          draggable: false,
          template:
            '<div style="width: 8px;height: 8px;background: #000;border: 2px solid #49B2FF;border-radius: 50%;margin: 28px 0 0 6px"></div>',
        };

        this.markers.push(obj);
        this.markersData.push(obj);
        // this.arrList[0].push(coordtransform.gcj02towgs84(this.lng, this.lat));
        this.arrList[0].push([this.lng, this.lat]);

        this.drawingConfirmation = false;
        if (this.markers.length > 3 && this.alertShow != false) {
          // this.isIntersect(polygon)
          polygon = [];
          this.markersDraw(this.markersData);
          this.tempolygons = JSON.parse(JSON.stringify(this.polygons));
        } else {
        }
      }
    },
    /**
     * 影像分辨率更新判断
     */
    imageSize() {
      if (this.imageSize < 0) {
        this.imageSize = 0;
      }
      if (this.imageSize > 100) {
        this.imageSize = 100;
      }
      if (this.imageSize.length > 3) {
        this.imageSize = this.imageSize.slice(0, 4);
      }
    },
    /**
     * 航向重叠度更新判断
     */
    courseOverlap() {
      if (this.courseOverlap < 0) {
        this.courseOverlap = 0;
      }
      if (this.courseOverlap > 100) {
        this.courseOverlap = 100;
      }
      if (this.courseOverlap.length > 3) {
        this.courseOverlap = this.courseOverlap.slice(0, 4);
      }
    },
    /**
     * 旁向重叠度更新判断
     */
    lateralOverlap() {
      if (this.lateralOverlap < 0) {
        this.lateralOverlap = 0;
      }
      if (this.lateralOverlap > 100) {
        this.lateralOverlap = 100;
      }
      if (this.lateralOverlap.length > 3) {
        this.lateralOverlap = this.lateralOverlap.slice(0, 4);
      }
    },
    laserDot() {
      if (this.laserDot < 0) {
        this.laserDot = 0;
      }
      if (this.laserDot > 100) {
        this.laserDot = 100;
      }
      if (this.laserDot.length > 3) {
        this.laserDot = this.laserDot.slice(0, 4);
      }
    },
  },
  created() {
    if (this.role === 2) {
      this.$confirm("暂无操作权限", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {
          this.$router.go(-1);
        });
    }
  },
  mounted() {
    /**
     *  传感器类型接口
     */
    this.$apis
      .taskGetSensorType({}, this.token)
      .then((data) => {
        this.sensorArr = data.data.data;
      })
      .catch((err) => {
        console.error(err);
      });
    /**
     *  授权空域接口
     */
    this.$apis
      .taskGetAuthAreaStatus({}, this.token)
      .then((data) => {
        this.airspaceArr = data.data.data;
      })
      .catch((err) => {
        console.error(err);
      });
    /**
     *  任务性质接口
     */
    this.$apis
      .taskGetChargeProperty({}, this.token)
      .then((data) => {
        this.taskNatureArr = data.data.data;
      })
      .catch((err) => {
        console.error(err);
      });
    this.setInitLocation();
  },
  methods: {
    // 地图定位完成后加载定位图标
    setInitLocation() {
      let that = this;
      if (this.locationMarker.length > 0) {
        this.locationMarker = coordtransform.gcj02towgs84(
          this.locationMarker[0],
          this.locationMarker[1]
        );
        this.setLocationMarker();
      } else {
        setTimeout(function () {
          that.setInitLocation();
        }, 2000);
      }
    },
    setLocationMarker() {
      let endIcon = new AMap.Icon({
        size: new AMap.Size(23, 23),
        image: "//webapi.amap.com/theme/v1.3/markers/b/loc.png",
        imageSize: new AMap.Size(23, 23),
        imageOffset: new AMap.Pixel(0, 0),
      });
      if (this.locationMarkerLayer !== "") {
        this.locationMarkerLayer.setMap(null);
      }
      this.locationMarkerLayer = new AMap.Marker({
        position: this.locationMarker,
        icon: endIcon,
        zIndex: 112,
        offset: new AMap.Pixel(-11.5, -11.5),
      });
      this.elMap.add(this.locationMarkerLayer);
      // this.elMap.setCenter(this.locationMarker);
    },
    /**
     * 下拉框选择
     */
    switchingChange(e) {
      if (e === "电子地图") {
        this.tiandituLayer.setMap(null);
        this.tianditutext.setMap(null);
        this.googleLayer.setMap(null);
        if (!this.isTransfrom) {
          this.locationMarker = coordtransform.wgs84togcj02(
            this.locationMarker[0],
            this.locationMarker[1]
          );
          this.isTransfrom = true;
          this.transformationCoordinate();
          this.setLocationMarker();
        }

        // new AMap.Map("amap-vue", {
        //   layers: [new AMap.TileLayer()],
        //   zoom: this.elMap.getZoom(),
        // });
      } else if (e === "影像地图") {
        this.tiandituLayer.setMap(null);
        this.tianditutext.setMap(null);
        this.googleLayer.setMap(this.elMap);
        if (!this.isTransfrom) {
          this.locationMarker = coordtransform.wgs84togcj02(
            this.locationMarker[0],
            this.locationMarker[1]
          );
          this.isTransfrom = true;
          this.transformationCoordinate();
          this.setLocationMarker();
        }
        // new AMap.Map("amap-vue", {
        //   center: this.center,
        //   layers: [new AMap.TileLayer.Satellite()],
        //   zoom: this.elMap.getZoom(),
        // });
      } else {
        this.googleLayer.setMap(null);
        this.tiandituLayer.setMap(this.elMap);
        this.tianditutext.setMap(this.elMap);

        this.locationMarker = coordtransform.gcj02towgs84(
          this.locationMarker[0],
          this.locationMarker[1]
        );
        this.isTransfrom = false;
        this.transformationCoordinate();
        this.setLocationMarker();
      }
    },
    // 切换地图时，多边形坐标转换
    transformationCoordinate() {
      this.obliterate();
      this.arrList.forEach((lnglats, index) => {
        if (lnglats.length !== 0) {
          var markersdata = [];
          lnglats.forEach((lnglat, lnglatIndex) => {
            let lotlatArr;
            if (this.isTransfrom) {
              lotlatArr = coordtransform.wgs84togcj02(lnglat[0], lnglat[1]);
            } else {
              lotlatArr = coordtransform.gcj02towgs84(lnglat[0], lnglat[1]);
            }
            const obj = {
              position: lotlatArr,
              events: {},
              visible: true,
              draggable: false,
              template:
                '<div style="width: 8px;height: 8px;background: #000;border: 2px solid #49B2FF;border-radius: 50%;margin: 28px 0 0 6px"></div>',
            };
            // this.markers.push(obj);
            this.arrList[index][lnglatIndex] = lotlatArr;
            markersdata.push(JSON.parse(JSON.stringify(obj)));
          });
          this.drawingConfirmation = false;
          this.markersDraw(markersdata);
          indexes++;
        }
      });
      this.tempolygons = JSON.parse(JSON.stringify(this.polygons));
    },
    /**
     * kml导入的input触发
     */
    async uploadKmlFile(e) {
      indexes = 0;
      let file = e.target.files[0];
      if (file === undefined) {
        return;
      }
      this.toLeadKml = true;
      this.obliterate();
      this.allLngLat = [];
      //
      this.arrList = [];
      let datas = await this.utils.getKmlPolygonStr(e);

      let markerLength = datas.length;
      // if (markerLength<30){

      // }
      let temMarkers = [];
      datas.forEach((data, dataIndex) => {
        let arrStr = data.split(";");
        var markersdata = [];

        this.arrList[dataIndex] = [];
        arrStr.forEach((e, index) => {
          let lotlatArr;
          if (this.isTransfrom) {
            // let lotlatArr = [e.split(",")[0], e.split(",")[1]];
            lotlatArr = coordtransform.wgs84togcj02(
              e.split(",")[0],
              e.split(",")[1]
            );
          } else {
            lotlatArr = [Number(e.split(",")[0]), Number(e.split(",")[1])];
          }

          // console.log(typeof(lotlatArr),typeof([e.split(",")[0], e.split(",")[1]]));
          // arr[i] = [lan[0], lan[1]]
          this.arrList[dataIndex].push(lotlatArr);
          this.allLngLat.push(lotlatArr); //所有经纬度，用于查找东北角及西南角
          const obj = {
            position: lotlatArr,
            events: {},
            visible: true,
            draggable: false,
            template:
              '<div style="width: 8px;height: 8px;background: #000;border: 2px solid #49B2FF;border-radius: 50%;margin: 28px 0 0 6px"></div>',
          };
          // this.markers.push(obj);
          temMarkers.push(obj);
          markersdata.push(JSON.parse(JSON.stringify(obj)));
        });

        // this.center = arrList[0];
        this.drawingConfirmation = false;
        this.markersDraw(markersdata);
        indexes++;
      });
      // this.elMap.setZoom(11);
      this.setBounds();
      // console.log(this.tempolygons, this.polygons);
      // for (let i = 0; i < 8; i++) {
      //   this.tempolygons.push(this.polygons[i]);
      // }
      this.tempolygons = JSON.parse(JSON.stringify(this.polygons));
      // this.markers = JSON.parse(JSON.stringify(temMarkers))
    },
    /**
     * markers点的绘制
     */
    markersDraw(data) {
      this.polygons[indexes] = {
        draggable: false,
        strokeColor: "#49B2FF",
        fillColor: "#47ABF5",
        fillOpacity: 0.5,
        path: [],
      };
      const arr = []; //  用来存储画的多边形的坐标数组
      const temArr = []; //  用来存储画的多边形的坐标数组

      const lengthNum = data.length;
      // for (let i = 0; i < lengthNum; i++) {
      //   data[i].position = coordtransform.wgs84togcj02(data[i].position[0],data[i].position[1])
      // }
      for (let i = 0; i < lengthNum; i++) {
        this.polygons[indexes].path[i] = data[i].position;
        arr[i] = data[i].position;
        temArr[i] = data[i].position;
      }
      this.blockNautica = "";
      for (const i in arr) {
        if (!this.isTransfrom) {
          temArr[i] = coordtransform.wgs84togcj02(temArr[i][0], temArr[i][1]);
        }
        // console.log(arr[i][0], arr[i][1]);
        const str = JSON.stringify(temArr[i]).slice(1, -1);
        this.blockNautica = this.blockNautica + "" + str + ";";
      }
      if (this.toLeadKml) {
        this.temBlockNautica.push(this.blockNautica);
      } else {
        this.temBlockNautica[0] = this.blockNautica;
      }

      if (this.toLeadKml) {
        let lotlat = arr[arr.length - 1];
        // arr.push(lotlat);
        if (
          lotlat[0] !== data[0].position[0] &&
          lotlat[1] !== data[0].position[1]
        ) {
          arr.push(data[0].position);
        }
      } else {
        arr.push(data[0].position);
      }
      polygon.push(
        turf.polygon([arr], {
          name: "poly1",
        })
      );
      return;
      // polygon = turf.polygon([arr], {name: "poly1",})
    },
    /**
     * 传感器Select 选择器值发生变化
     * @param {Object} data
     */
    topicSelect(data) {
      this.judgeSensor();
    },
    /**
     * 判断传感器类型
     */
    judgeSensor() {
      for (const value in this.sensorArr) {
        if (this.sensorArr[value].name == this.sensor) {
          this.sensorNum = this.sensorArr[value].type;
          return;
        }
      }
    },
    /**
     * 新建通用任务
     * @param {string} blockCoordinates
     * @param {Array} tasksArr
     */
    async taskAddCommon(blockCoordinates, tasksArr) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      await this.$apis
        .taskAddCommon(
          {
            project: {
              project_name: this.taskName,
              sensor_type: this.sensor,
              local_id: this.guid,
              auth_area: this.airspace,
              charge_property: this.taskNature,
              average_ground_resolution: this.imageSize,
              longitudinal_overlap: this.courseOverlap,
              lateral_overlap: this.lateralOverlap,
              point_cloud_density: this.laserDot,
              blocks: tasksArr

              // blocks: [
              //   {
              //     survey_area_name: "区块1",
              //     survey_area_coordinate_points: blockCoordinates,
              //     tasks: tasksArr,
              //   },
              //   {
              //     survey_area_name: "区块2",
              //     survey_area_coordinate_points: blockCoordinates,
              //     tasks: tasksArr,
              //   },
              // ],
            },
          },
          this.token
        )
        .then((data) => {
          loading.close();
          indexes++;
          this.markers = [];
          this.markersData = [];
          this.toLeadKml = true; // 以便手动画区块时清除之前绘制
          const da =
            "任务名称：" +
            this.taskName +
            " 任务ID：" +
            data.data.data.project_id +
            "";
          let that = this;
          if (this.fileData !== undefined) {
            this.fileData.append("action", "upload");
            this.fileData.append("project_id", data.data.data.project_id);

            this.$apis
              .taskAddCommon(this.fileData, this.token)
              .then((res) => {});
          }

          if (this.role === 1) {
            this.$confirm(da, data.data.message, {
              confirmButtonText: "推送",
              cancelButtonText: "暂不推送",
              type: "success",
              center: true,
              showClose: false,
            })
              .then(() => {
                this.$confirm("请问该任务自行推送还是分享至超管进行推送？", {
                  confirmButtonText: "自行推送",
                  cancelButtonText: "超管推送",
                  center: true,
                  showClose: false,
                })
                  .then(() => {
                    // 自行推送
                    this.pushTag(data.data.data.project_id, "self");
                  })
                  .catch(() => {
                    // 超管推送
                    this.pushTag(data.data.data.project_id, "admin");
                  });
              })
              .catch(() => {
                this.$alert(
                  '如需再次推送，请至"任务下达"—"通用任务"—"待推送任务"列表中进行推送。',
                  {
                    confirmButtonText: "知道了",
                    showClose: false,
                    callback: (action) => {
                      // this.$message({
                      //   type: 'info',
                      //   message: `action: ${ action }`
                      // });
                    },
                  }
                );
              });
          } else {
            this.$alert(
              '创建成功如需推送，请至"任务下达"—"通用任务"—"待推送任务"列表中进行推送。',
              {
                confirmButtonText: "知道了",
                showClose: false,
                callback: (action) => {
                  // this.$message({
                  //   type: 'info',
                  //   message: `action: ${ action }`
                  // });
                },
              }
            );
          }
        })
        .catch((err) => {
          console.error(err);
          loading.close();
        });
    },
    /**
     * 选择推送
     * @param {Object} project_id
     * @param {Object} push_flag
     */
    pushTag(project_id, push_flag) {
      this.$apis
        .taskAddCommon(
          {
            action: "update",
            project_id: project_id,
            push_flag: push_flag,
          },
          this.token
        )
        .then((data) => {
          this.utils.$notifySuccess("成功", data.data.message);
          this.taskName = "";
          this.sensor = "";
          this.airspace = "";
          this.taskNature = "";
          this.imageSize = "";
          this.courseOverlap = "";
          this.lateralOverlap = "";
          this.laserDot = "";
          this.blockNautica = "";
          this.taskNauticaArr = [];
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 新建通用任务发送请求前判断
     */
    judgeTaskAdd() {
      if (this.taskName == "") {
        this.utils.$notifyError("错误", "请填写任务名称");
        return;
      }
      if (this.sensor == "") {
        this.utils.$notifyError("错误", "请选择传感器");
        return;
      }
      if (this.airspace == "") {
        this.utils.$notifyError("错误", "请选择授权空域");
        return;
      }
      if (this.taskNature == "") {
        this.utils.$notifyError("错误", "任务性质必选");
        return;
      }
      if (this.sensorNum == 1) {
        if (
          this.imageSize == "" ||
          this.courseOverlap == "" ||
          this.lateralOverlap == ""
        ) {
          this.utils.$notifyError("错误", "请填写完整任务信息");
          return;
        }
      } else if (this.sensorNum == 2) {
        if (this.laserDot == "" || this.lateralOverlap == "") {
          this.utils.$notifyError("错误", "请填写完整任务信息");
          return;
        }
      }
      if (
        this.blockNautica === "" ||
        this.taskNauticaArr === [] ||
        this.polygons[indexes] === false
      ) {
        this.utils.$notifyError("错误", "请选定区域");
        return;
      }

      this.taskAddCommon(this.blockNautica, this.taskNauticaArr);
    },
    /**
     * 绘制完多边形上传新建任务
     */
    newBtn() {
      this.judgeTaskAdd();
    },
    /**
     * 结束绘制
     */
    finishDrawing() {
      this.drawingConfirmation = true;
      this.isIntersect(polygon);
    },
    obliterateall() {
      this.arrList = [[]];
      this.obliterate();
    },
    /**
     * 清除重绘当前多边形的方法
     * 清除页面中的缓存方格
     */
    obliterate() {
      if (this.toLeadKml) {
        for (let i = 0; i < indexes; i++) {
          this.tempolygons[i] = false;
          this.polygons[i] = false;
        }
      } else {
        this.tempolygons[0] = false;
        this.polygons[0] = false;
      }

      indexes = 0;
      this.markers = [];
      this.markersData = [];

      // this.polygonArr.splice(this.polygonArr.length - len, len);
      len = 0;
      this.polygonArr = [];
      this.taskNauticaArr = [];
      this.temBlockNautica = []; //任务坐标及第几个任务
      this.drawingConfirmation = false;
      polygon = [];
    },
    /**
     * 点击显示左边的alert新建任务弹框
     * 调用清除
     */
    newTask() {
      this.obliterate();
      // this.alertShow = !this.alertShow;
    },
    /**
     * 绘制完多边形判断Polygon是否为自相交
     * @param {Object} polygon turfpolygon类型
     */
    isIntersect(polygons) {
      let isPrompt = [];
      polygons.forEach((polygon) => {
        var kinks = turf.kinks(polygon);
        if (kinks.features.length > 0) {
          // 存在自相交情况
          this.$message({
            showClose: true,
            message: "错误，多边形不合法",
            type: "error",
          });
          this.obliterate();
        } else {
          // 不存在自相交情况
          area = turf.area(polygon) / 1000000;

          if (area < process.env.VUE_APP_MAP_AREA) {
            // area > 1 ? this.promptArea(polygon) : this.noPromptArea();
            if (area > 1) isPrompt.push(polygon);
          } else {
            this.drawingConfirmation = false;
            this.$message({
              message: "绘画面积过大",
              type: "warning",
            });
          }
        }
      });
      // if (isPrompt.length > 0) {
      //   this.promptArea(isPrompt);
      // } else {
      this.noPromptArea();
      // }
    },
    /**
     * 选择多边形不需要拆分
     */
    noPromptArea() {
      // blocks: [
      //           {
      //             survey_area_name: "区块1",
      //             survey_area_coordinate_points: blockCoordinates,
      //             tasks: tasksArr,
      //           },
      //         ],
      this.temBlockNautica.forEach((val, index) => {
        // if (!this.isTransfrom) {
        //   val = coordtransform.wgs84togcj02(val[0], val[1]);
        // }
        let local_id = uuidv4().replace(/[-]/g,"")
        this.taskNauticaArr[index] = {
          survey_area_name: "区块" + (index + 1),
          survey_area_coordinate_points: this.blockNautica,
          survey_area_guid: local_id,
          local_id: local_id,
          block_type: 0
        };
        this.taskNauticaArr[index]["tasks"] = [];
        this.taskNauticaArr[index]["tasks"].push({
          flight_task_name: "任务1",
          task_coordinate_points: val,
        });

        // this.taskNauticaArr[index] = {
        //   flight_task_name: "任务1",
        //   task_coordinate_points: val,
        // }
      });
      // console.log(this.taskNauticaArr);
      // this.taskNauticaArr[0] = {
      //   flight_task_name: "任务1",
      //   task_coordinate_points: this.blockNautica,
      // };
    },
    /**
     * 判断多边形是否需要拆分
     */
    promptArea(polygon) {
      this.polygonArr = []; // 清空网格
      this.$prompt(
        "该测区面积较大，需要拆分，请输入最小拆分面积(单位:平方公里)",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          // inputPattern: /^[+]?[1-9][1-9]*$/,
          inputPattern: /^[+]?[1-9][0-9]{0,1}$/,
          inputErrorMessage: "请输入大于0小于100的正整数，单位平方千米",
        }
      )
        .then(({ value }) => {
          if (value > area) {
            this.noPromptArea();
            return;
          }
          let sides = 1; //  绘画的方格边长
          if (value <= 2) {
            sides = 1.8;
          } else if (value <= 6) {
            sides = 2;
          } else {
            sides = 2.2;
          }
          polygon.forEach((data) => {
            this.temPolygonArr = [];
            this.acoverage(data, value, sides);
            this.temPolygonArrs.push(this.temPolygonArr);
          });
          // this.acoverage(polygon, value, sides);
        })
        .catch((err) => {
          console.error(err);
          this.$message({
            type: "info",
            duration: 1500,
            message: "取消拆分",
          });
          this.noPromptArea();
        });
    },
    /**
     * 放大外多边形的，获取覆盖绘画多边形的网格
     * @param {Object} polygon
     * @param {number} value
     * @param {number} sides
     */
    acoverage(polygon, value, sides) {
      this.polygonArr.splice(this.polygonArr.length - len, len);
      const bbox = turf.bbox(polygon); // 边界框
      // bboxPolygon Takes a bbox and returns an equivalent polygon.
      const bboxPolygon = turf.bboxPolygon(bbox).geometry.coordinates[0]; //  获取覆盖所画多边形的外围四边形
      const polygon2 = turf.polygon([bboxPolygon], {
        name: "poly2",
      });
      const scaledPoly = turf.transformScale(polygon2, sides); //  将外多边形放大
      const bbox2 = turf.bbox(scaledPoly);
      const options = { units: "kilometers", mask: scaledPoly };
      const squareGrid = turf.squareGrid(bbox2, value, options); //  获得覆盖多边形的网格坐标
      this.check(squareGrid.features, polygon);
    },
    /**
     *添加等待提示，循环绘画方法
     * @param {Array} arr
     * @param {Object} polygon
     */
    check(arr, polygon) {
      // 循环函数，绘画拆分的每个正方形方格
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      len = arr.length;

      this.taskNauticaArr = [];
      // let temData = []

      for (let i = 0; i < len; i++) {
        this.cropdef(arr[i], polygon); //arr[i]为一个网格
        // temData.push(this.temPolygonArr)
      }
      loading.close();
    },
    /**
     * 裁剪函数，获取与内多边形 polygons 重合部分
     * @param {Object} obj
     * @param {Object} polygon
     */
    cropdef(obj, polygon) {
      const clipped = turf.intersect(obj, polygon); //  裁剪每个小网格与多边形相交的补发部分显示出来
      if (clipped) {
        const lengthtype = this.taskNauticaArr.length;
        this.taskNauticaArr[lengthtype] = {
          flight_task_name: "任务" + lengthtype,
          task_coordinate_points: "",
        }; //  api请求所需
        const clippedArr = clipped.geometry.coordinates[0];
        const lengthNum = clippedArr.length;
        for (let j = 0; j < lengthNum - 1; j++) {
          // 将turf返回的网格坐标数组，转换为发送请求所需的字符串
          const str = JSON.stringify(clippedArr[j]).slice(1, -1);
          this.taskNauticaArr[lengthtype].task_coordinate_points =
            this.taskNauticaArr[lengthtype].task_coordinate_points + str + ";";
        }
        this.addDrawPolygon(clipped); // 绘画函数
      }
    },
    /**
     * 绘画函数
     * @param {Object} clipped
     */
    addDrawPolygon(clipped) {
      const index = this.polygonArr.length;
      const temIndex = this.temPolygonArr.length;
      this.polygonArr[index] = {
        draggable: false,
        strokeColor: "#49B2FF",
        fillColor: "#47ABF5",
        fillOpacity: 0.5,
        path: [],
      }; //  多边形的样式
      this.temPolygonArr[temIndex] = {
        draggable: false,
        strokeColor: "#49B2FF",
        fillColor: "#47ABF5",
        fillOpacity: 0.5,
        path: [],
      }; //  多边形的样式
      const coordinates = clipped.geometry.coordinates[0];
      const coordinatesLeng = coordinates.length;
      for (let j = 0; j < coordinatesLeng - 1; j++) {
        this.polygonArr[index].path[j] = coordinates[j];
        this.temPolygonArr[temIndex].path[j] = coordinates[j]; //将拆分的网格存储到临时的数组中，coordinates[j]是一个拆分的网格坐标
      }
      // this.temPolygonArr.push(temData);
    },
    // 导入kml后获取东北角和西南角，设置可视化区域
    setBounds() {
      let NorthLng = 0;
      let EastLat = 0;
      let WestLng = 0;
      let SouthLat = 0;
      let lng = [];
      let lat = [];
      this.allLngLat.forEach((lnglat) => {
        lng.push(lnglat[0]);
        lat.push(lnglat[1]);
      });
      WestLng = Math.min.apply(Math, lng);
      SouthLat = Math.min.apply(Math, lat);
      NorthLng = Math.max.apply(Math, lng);
      EastLat = Math.max.apply(Math, lat);
      // let mybounds = new AMap.Bounds([WestLng,SouthLat], [NorthLng,EastLat]);
      // this.elMap.setBounds(mybounds);
      var mybounds = new AMap.Bounds(
        [WestLng - 0.01, SouthLat],
        [NorthLng, EastLat]
      ); //之所以减0.01是因为左边有窗口会遮盖到显示区域
      this.elMap.setBounds(mybounds);
    },
    FileUpload(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; // 这一步，是 展示最后一次选择的csv文件
      }

      const formData = new FormData();
      formData.append("file", file.raw);
      this.fileData = formData;
      // console.log(this.fileData.get("file"),"FileUpload", file.raw);
    },
    handleRemove(file, fileList) {
      this.fileData = this.formData.delete("file");
    },
    handleExceed(files, fileList) {
      // const formData = new FormData();
      // formData.append("file", files);
      // this.fileData = formData;
      // // fileList[0] = files;
      // console.log(fileList,files,formData);
      // this.fileList = fileList;
      // this.$message.warning(`已上传一个文件，请先删除原有上传文件`);
    },
    uploadFile() {},
  },
};
</script>

<style lang="scss">
@import url("../../../assets/iconfont/iconfont.css");

.NavBiu {
  float: left;
}

@mixin amap($height) {
  .amap-wrapper {
    position: fixed;
    top: $height;
    right: 0;
    bottom: 0;
    left: 0;
    top: 70px !important;
  }
}
@include amap($height: 80px);

// @media screen and(min-height: 900px) {
//   @include amap($height: 80px);
// }
// @media screen and(min-height: 1000px) {
//   @include amap($height: 80px);
// }
.newGeneric {
  .construction {
    width: 44px;
    height: 44px;
    line-height: 44px;
    font-size: 45px;
    text-align: center;
    position: fixed;
    top: 124px;
    right: 25px;
    color: #1182fb;
    background-color: #fff;
    border-radius: 50%;
    &:hover {
      color: #1182fb;
      background-color: #fff;
    }
  }

  .pitchOn {
    color: #1a1a1a;
    background-color: #1182fb;
  }

  .alert-left::-webkit-scrollbar {
    width: 0 !important;
  }
  .alert-left {
    width: 29.895vw;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 8px 8px 4px rgba(226, 226, 226, 0.5);
    border-radius: 4px;
    position: fixed;
    left: 0;
    bottom: 0;
    top: 109px;
    z-index: 999;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .headline {
    width: 100%;
    height: 64px;
    line-height: 64px;
    box-sizing: border-box;
    padding-left: 41px;
    text-align: left;
  }
  .headline > div {
    display: inline-block;
    width: 83.2752%;
  }

  .closeBtn {
    width: 25px !important;
    height: 64px;
    line-height: 64px;
    text-align: center;
  }

  .eliminate {
    width: 22.86vw;
    // height: 22vw;
    border-bottom: 1px dashed #a6aab8;
    margin: 0 auto;
    margin-bottom: 1.56vw;
    padding-bottom: 1.61vw;
  }

  .eliminate > div {
    margin: 0 auto;
  }

  .eliminate-title {
    text-align: left;
    margin-bottom: 18px !important;
    display: flex;
    div {
      margin-left: 66%;
      color: rgba(23, 130, 251, 1);
      cursor: pointer;
    }
  }

  .eliminate-img {
    width: 100%;
    height: 13.96vw;
    background: url("../../../assets/img/draw.png") no-repeat center center;
    background-size: 100% 100%;
    border-radius: 15px;
  }

  .eliminate-hint {
    font-size: 16px;
    margin-top: 5.172% !important;
    text-align: center;
  }

  .eliminate-btn-div {
    display: flex;
  }

  .eliminate-btn {
    width: 112px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 86, 255, 1);
    border-radius: 2px;
    color: #fff;
    text-align: center;
    margin: 14px auto 0;
  }

  .list {
    box-sizing: border-box;
    padding: 0 9.5%;
    position: relative;
  }
  .list > div > div {
    display: inline-block;
  }

  .topic {
    width: 20.8vw;
    min-width: 360px;
    height: 22px;
    text-align: right;
    margin-top: 0.73vw;
    display: flex;
    height: 2.08vw;
    line-height: 2.08vw;
    // .el-tooltip {
    //   border: 1px solid #fff !important;
    // }
    .topic-view {
      flex-direction: column;
      width: 80px;
      text-align: right;
      font-size: 16px;
      color: #838a9d;
    }
    .topic-select {
      width: 12.8vw;
      height: 2.08vw;
      margin-left: 1.45vw;
    }
    .topic-el-radio {
      width: 13.8vw;
      height: 2.08vw;
      line-height: 2.08vw;
      margin-left: 1.45vw;
      display: flex;
      align-items: center;
    }
  }
  /deep/.el-upload-list {
    .el-upload-list__item {
      width: 230px !important;
    }
  }
  .uploadBtn {
    width: 83px;
    height: 32px;
    border-radius: 2px;
    border: 1px solid #838a9d;
    font-size: 14px;
    color: #838a9d;
    display: flex;
    align-items: center;
    margin-left: 1.45vw;
    // position: absolute;
    // right: 29%;
    // bottom: -29px;
    img {
      margin: 0 8px;
    }
  }
  .alert-btn {
    padding: 2.2vw 0px;
  }
  .alert-btn > div {
    width: 112px;
    height: 35px;
    line-height: 35px;
    border-radius: 2px;
    text-align: center;
  }

  .new-btn {
    background: #1182fb;
    color: #fff;
    float: left;
    margin-left: 123px;
  }

  .cancel-btn {
    background: #fff;
    color: #1182fb;
    float: left;
    margin-left: 50px;
  }
  @media only screen and (max-width: 1650px) {
    .alert-left {
      width: 29.895%;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 8px 8px 4px rgba(226, 226, 226, 0.5);
      border-radius: 4px;
      position: fixed;
      left: 0;
      bottom: 0;
      top: 109px;
      z-index: 999;
      overflow-y: auto;
    }
    .alert-btn > div {
      width: 112px;
      height: 35px;
      line-height: 35px;
      border-radius: 2px;
      text-align: center;
    }
    .cancel-btn {
      background: #fff;
      color: #1182fb;
      float: left;
      margin-left: 50px;
    }
    .new-btn {
      background: #1182fb;
      color: #fff;
      float: left;
      margin-left: 50px;
    }
  }

  .switching {
    position: fixed;
    right: 5%;
    bottom: 5%;
  }
}

</style>
