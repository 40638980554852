<template>
  <div class="apply">
    <div class="palyBar">
      <div class="imgBar">
        <img
          class="feimaimg"
          src="@/assets/img/airspace／apply／nor.png"
          alt
        />
      </div>
      <span class="wordBar">我想要申请空域</span>
      <el-button
        title="请绘制合法空域"
        @click="open"
        type="primary"
        plain
        :disabled="!legal"
        >申请空域</el-button
      >
    </div>
    <el-dialog
      title="申请空域"
      :visible.sync="dialogVisible"
      width="500px"
      :close-on-click-modal="false"
      :before-close="handleClose"
      v-dialogDrag
    >
      <el-divider></el-divider>
      <div class="bodyBar">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          :rules="rules"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="空域名称" prop="air_space_name">
            <el-input
              v-model="ruleForm.air_space_name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="空域中心点" prop="coordinate_point">
            <span>{{ ruleForm.coordinate_point }}</span>
          </el-form-item>
          <el-form-item label="作业高度（真高）" prop="flight_altitude">
            <el-input
              v-model="ruleForm.flight_altitude"
              placeholder="请输入"
            ></el-input
            >米
          </el-form-item>
          <el-form-item label="航飞作业单位名称" prop="flight_company_name">
            <el-input
              v-model="ruleForm.flight_company_name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="委托巡护单位名称" prop="unit_name">
            <el-input
              v-model="ruleForm.unit_name"
              placeholder="请输入"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="规划飞行窗口" prop="flight_time">
            <el-date-picker
              v-model="ruleForm.flight_time"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getBarchart"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="加载申请函" prop="application_letter">
            <div class="snapshotbar">
              <input
                type="file"
                class="imgupload"
                @change="uploadImg"
                accept=".doc, .docx, .pdf"
              />
              <div class="snapshotBar">
                <img
                  class="uploadImg"
                  src="@/assets/img/Upload／pic .png"
                  alt
                />
                <p class="describe">
                  {{ fileName == "" ? "点击或将文件拖拽到这里上传" : fileName }}
                </p>
              </div>
            </div>
          </el-form-item>
          <el-form-item label prop="application_letter">
            <div class="template">
              <i class="iconfont">&#xe659;</i>
              <el-button @click="download" type="text"
                >下载申请函模版</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
      <el-divider></el-divider>
      <span slot="footer" class="dialog-footer">
        <el-button class="footBtn" @click="sureBtn" type="primary"
          >确 认</el-button
        >
        <el-button class="footBtn" @click="dialogVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
let file=[];
let files={};
let formData=new FormData();
import { mapState,mapMutations } from "vuex";
export default {
  props: {
    legal: {
      type: Boolean,
    },
    centerStr: {
      type: String,
      default: "",
    },
    latitudeAndLongitudeStr: {
      type: String,
      default: "",
    },
  },
  data () {
    let that=this;
    return {
      fileName: "",
      dialogVisible: false,
      ruleForm: {
        air_space_name: "",
        coordinate_point: "",
        flight_altitude: "",
        survey_area_coordinate_points: "",
        unit_name: "",
        flight_company_name: "",
        flight_start_time: "",
        flight_end_time: "",
        flight_time: [],
      },
      fileUrl: "",
      rules: {
        air_space_name: [
          { required: true,message: "空域名称不能为空",trigger: "blur" },
          { max: 16,message: "最大不超过16个字符",trigger: "blur" },
        ],
        flight_company_name: [
          { required: true,message: "作业单位名称不能为空",trigger: "blur" },
        ],
        flight_time: [
          { required: true,message: "规划飞行窗口不能为空",trigger: "blur" },
        ],
        flight_altitude: [
          { required: true,message: "请输入作业高度",trigger: "blur" },
          {
            validator (rule,value,callback) {
              if(
                Number.isInteger(Number(value))&&
                Number(value)>0&&
                Number(value)<10000
              ) {
                callback();
              } else {
                callback(new Error("请输入1-10000的正整数"));
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    open () {
      this.dialogVisible=true;
      let arr=[]
      arr.push(this.centerStr.split(','))
      let point=arr[0]
      let lng=Number(point[0]).toFixed(8)
      let lat=Number(point[1]).toFixed(8)
      this.ruleForm.coordinate_point=lng+','+lat;
    },
    getBarchart () {
      this.ruleForm.flight_start_time=this.ruleForm.flight_time[0]+" "+"00:00:00";
      this.ruleForm.flight_end_time=this.ruleForm.flight_time[1]+" "+"23:59:59";
    },
    handleClose (done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
    uploadImg (e) {
      files=e.target.files[0];
      this.fileName=files.name
      this.formDatas(files,1);
    },
    formDatas (data,index) {
      let file=data;
     
      let fileReader=new FileReader();
      fileReader.readAsBinaryString(file);
      fileReader.onload=(e) => {
        if(index==1) {
          formData.append("application_letter",file,file.name);
          formData.append("air_space_name",this.ruleForm.air_space_name);
          formData.append("coordinate_point",this.ruleForm.coordinate_point);
          formData.append("flight_altitude",this.ruleForm.flight_altitude);
          formData.append(
            "survey_area_coordinate_points",
            this.latitudeAndLongitudeStr
          );
          formData.append(
            "flight_company_name",
            this.ruleForm.flight_company_name
          );
          formData.append("flight_start_time",this.ruleForm.flight_start_time);
          formData.append("flight_end_time",this.ruleForm.flight_end_time);
        } else {
        }
      };
    },
    sureBtn () {
      const loading=this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$apis
        .apply(formData,this.token)
        .then((data) => {
          if(data.data.status==0) {
            this.$message({
              message: data.data.message,
              type: "success",
            });
            this.dialogVisible=false;
          }
         
          loading.close();
        })
        .catch((err) => {
          console.error(err);
          loading.close();
        });
    },
    download () {
      window.open(window.apihost+"/file/关于任务申请-空域申请的函(模板).docx");
    },
  },
  created () {
    this.ruleForm.unit_name=this.user.user_unit.unit_name;
  },
  computed: {
    ...mapState(["user","token"]),
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form-item__label {
  width: 150px !important;
}
/deep/.el-input {
  width: 220px;
}
/deep/.el-dialog {
  height: 670px !important;
  overflow-y: auto;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-divider--horizontal {
  margin: 12px 0;
}
/deep/.el-range-editor--medium.el-input__inner {
  width: 220px;
}
.template {
  float: right;
  margin-right: 10px;
}
/deep/.el-form-item__error {
  margin-left: 150px;
}
.iconfont {
  color: #1782fb;
}
.snapshotbar {
  float: left;
  line-height: 45px;
  .imgupload {
    font-size: 0;
    width: 224px;
    height: 130px;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }
  .snapshotBar {
    width: 224px;
    height: 130px;
    background-color: #f6f7f9;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    .uploadImg {
      margin-left: 89px;
      margin-top: 20px;
    }
    .describe {
      color: #838a9d;
      font-size: 13px;
      text-align: center;
      line-height: 14px;
    }
  }
}
.apply {
  width: 150px;
  height: 200px;
  background-color: #fff;
  position: absolute;
  top: 2%;
}
.palyBar {
  text-align: center;
}
.imgBar {
  cursor: pointer;
  margin-top: 15px;
}
.wordBar {
  font-size: 14px;
  color: #b4b4b4;
  line-height: 36px;
  margin-bottom: 10px;
}
.bodyBar {
  width: 80%;
  height: 100%;
  margin-left: 5%;
  margin-top: 20px;
}
@media only screen and (min-width: 1920px) {
  .apply {
    right: 25%;
  }
}
@media screen and (min-width: 1750px) and (max-width: 1920px) {
  .apply {
    right: 25%;
  }
}
@media screen and (min-width: 1570px) and (max-width: 1749px) {
  .apply {
    right: 28%;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1569px) {
  .apply {
    right: 35%;
  }
}
@media only screen and (max-width: 1365px) {
  .apply {
    right: 40%;
  }
}
</style>