var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-popover",
        { attrs: { placement: "right", width: "200", trigger: "click" } },
        [
          _c("i", {
            staticClass: "el-icon-warning",
            staticStyle: { color: "#FAAD14" },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "fmAsk" }, [
            _vm._v("请问平台内人员绑定还是平台外人员绑定？"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "8px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.inplatform },
                },
                [_vm._v("平台内")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini", plain: "" },
                  on: { click: _vm.sendMsg },
                },
                [_vm._v("平台外")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "addLmReg",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _c("div", { staticClass: "addButton" }, [
                _c("i", { staticClass: "el-icon-plus loginIcon" }),
                _vm._v(" "),
                _c("span", { staticClass: "addregister" }, [
                  _vm._v("添加巡护绑定"),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }