var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "centerWindow" }, [
    _c("div", { staticClass: "loginBgc" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "fmLoginNav" }, [
        _c("div", { staticClass: "loginNav" }, [
          _c(
            "div",
            { staticClass: "loginBox" },
            [
              _c("p", { staticClass: "loginTitle" }, [_vm._v("身份验证")]),
              _vm._v(" "),
              _vm.flagOne
                ? _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "FmInput",
                      attrs: {
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "mobile",
                          staticClass: "demo-ruleForm",
                          attrs: { prop: "mobile" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入手机号" },
                            model: {
                              value: _vm.ruleForm.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "mobile", $$v)
                              },
                              expression: "ruleForm.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "demo-ruleForm",
                          attrs: { prop: "sms_code" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入验证码" },
                              model: {
                                value: _vm.ruleForm.sms_code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "sms_code", $$v)
                                },
                                expression: "ruleForm.sms_code",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "getVerification",
                                  attrs: {
                                    slot: "suffix",
                                    type: "text",
                                    disabled: _vm.disabled,
                                    sms_code: _vm.ruleForm.sms_code,
                                  },
                                  on: { click: _vm.getRegister },
                                  slot: "suffix",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.btnTitle) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          ref: "password",
                          staticClass: "demo-ruleForm",
                          attrs: { prop: "password" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入密码",
                              type: "password",
                            },
                            model: {
                              value: _vm.ruleForm.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "password", $$v)
                              },
                              expression: "ruleForm.password",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "SignInBar" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "loginBar",
                              attrs: { type: "primary" },
                              on: { click: _vm.userRegister },
                            },
                            [_vm._v("下 一 步")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.flagOne
                ? _c(
                    "div",
                    { staticClass: "footerBar" },
                    [
                      _c(
                        "el-link",
                        {
                          staticClass: "register",
                          attrs: { underline: false },
                          on: { click: _vm.registerNum },
                        },
                        [_vm._v("登录账号")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.FlagTwo
                ? _c("div", { staticClass: "regisSuccess" }, [
                    _c("img", {
                      staticClass: "loginImg",
                      attrs: {
                        src: require("../../assets/img/reg-login.png"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "rrgisWord" }, [
                      _vm._v("重置密码成功 !"),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "logintext" }, [
                      _vm._v(_vm._s(_vm.timeDown) + "s后自动跳转"),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fmWelcome" }, [
      _c("div", { staticClass: "fmWelLogin" }, [
        _c("p", { staticClass: "sayHello" }, [_vm._v("您好,")]),
        _vm._v(" "),
        _c("p", { staticClass: "welLogin" }, [_vm._v("欢迎登录")]),
        _vm._v(" "),
        _c("p", { staticClass: "fmsider" }),
        _vm._v(" "),
        _c("p", { staticClass: "fmTitle" }, [
          _vm._v("无人机巡护指挥调度管理平台"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }