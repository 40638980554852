var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          staticClass: "taskBox",
          attrs: {
            title: "新建任务群组",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("el-divider"),
          _vm._v(" "),
          _c("div", { staticClass: "detailBar" }, [
            _c(
              "div",
              { staticClass: "formBox" },
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "formbox",
                    attrs: {
                      rules: _vm.rules,
                      model: _vm.ruleForm,
                      "label-width": "80px",
                      "hide-required-asterisk": true,
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "userForm",
                        attrs: { prop: "name", label: "群组名称" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.ruleForm.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "name", $$v)
                            },
                            expression: "ruleForm.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "userForm",
                        attrs: { prop: "source", label: "创建源" },
                      },
                      [
                        _c("el-input", {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.ruleForm.source,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "source", $$v)
                            },
                            expression: "ruleForm.source",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "userForm",
                        attrs: { prop: "admin", label: "管理员" },
                      },
                      [
                        _c("el-input", {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.ruleForm.admin,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "admin", $$v)
                            },
                            expression: "ruleForm.admin",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "userForm",
                        attrs: { prop: "Allianceunit", label: "巡护单位" },
                      },
                      [
                        _c("el-input", {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.ruleForm.Allianceunit,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "Allianceunit", $$v)
                            },
                            expression: "ruleForm.Allianceunit",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "userForm",
                        attrs: { prop: "adduser", label: "添加成员" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "userList" },
                          [
                            _vm._l(_vm.unitUserList, function (item, index) {
                              return _c(
                                "span",
                                { key: index, staticClass: "unitUser" },
                                [_vm._v(_vm._s(item.name))]
                              )
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "addImg",
                              attrs: {
                                src: require("@/assets/img/add.png"),
                                alt: "",
                              },
                              on: { click: _vm.goAdduser },
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("el-divider"),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "footBtn",
                  attrs: { type: "primary" },
                  on: { click: _vm.newGrounp },
                },
                [_vm._v("确 认")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "footBtn",
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("AddMember", {
        ref: "addmem",
        attrs: { grounpObj: _vm.grounpObj },
        on: { handAddReg: _vm.getChooseList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }