var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        title: "回传" + _vm.name + "数据",
        visible: _vm.dialogVisible,
        width: "687px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "bodyBar" }, [
        _c("div", { staticClass: "row" }, [
          _c("span", { staticClass: "name" }, [_vm._v("任务名称")]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.item.task_name))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("span", { staticClass: "name" }, [_vm._v("任务ID")]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.item.id))]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row textarea" },
          [
            _c("span", { staticClass: "name" }, [_vm._v("任务名称描述")]),
            _vm._v(" "),
            _c("el-input", {
              staticClass: "area",
              attrs: {
                type: "textarea",
                maxlength: "500",
                "show-word-limit": true,
                placeholder: "请输入",
              },
              model: {
                value: _vm.textarea,
                callback: function ($$v) {
                  _vm.textarea = $$v
                },
                expression: "textarea",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("span", { staticClass: "name" }, [_vm._v("回传数据类型")]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.name) + "数据")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("span", { staticClass: "name" }, [_vm._v("回传平台")]),
          _vm._v(" "),
          _c("span", [_vm._v("调度中心端")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("span", { staticClass: "name" }, [_vm._v("回传账户")]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.user.mobile))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("span", { staticClass: "name" }, [_vm._v("隶属巡护单位")]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.user.user_unit.unit_name))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row", staticStyle: { margin: "30px 0" } }, [
          _c("span", { staticClass: "name" }, [_vm._v("回传数据包络")]),
          _vm._v(" "),
          _c("span", { staticClass: "segmentation" }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row checkList" },
          [
            _c("span", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
            _vm._v(" "),
            _c(
              "el-checkbox-group",
              {
                model: {
                  value: _vm.checkList,
                  callback: function ($$v) {
                    _vm.checkList = $$v
                  },
                  expression: "checkList",
                },
              },
              _vm._l(_vm.checkListArr, function (item, index) {
                return _c("el-checkbox", {
                  key: index,
                  staticClass: "list-check",
                  attrs: { label: item.name },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row uploading" },
        [
          _c("span", { staticClass: "name" }, [_vm._v("文件")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { color: "#1782fb" },
              on: { click: _vm.toPostBack },
            },
            [_vm._v("上传文件")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-right": "24px" },
              attrs: { type: "primary" },
              on: { click: _vm.postback },
            },
            [_vm._v("回传")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "margin-right": "36px" },
              on: { click: _vm.cancel },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }