var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "deviceData", class: _vm._module }, [
    _vm.deviceNameList.indexOf(_vm.terminal.brand) !== -1
      ? _c("div", { staticClass: "setLineHeight" }, [
          _c("span", { staticClass: "deviceTitle" }, [_vm._v("所属行政区")]),
          _c(
            "span",
            {
              staticClass: "deviceData",
              staticStyle: { width: "190px" },
              attrs: { title: _vm.terminal.region },
            },
            [
              _vm._v(
                _vm._s(
                  _vm._f("filterData")(_vm.terminal.msg_data.user_data.region)
                )
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "setLineHeight" }, [
      _c("span", { staticClass: "deviceTitle" }, [
        _vm._v(_vm._s(_vm._f("filterDeviceUnitName")(_vm.terminal.brand))),
      ]),
      _c(
        "span",
        {
          staticClass: "deviceData",
          staticStyle: { width: "190px" },
          attrs: { title: _vm.terminal.unit_name },
        },
        [_vm._v(_vm._s(_vm._f("filterData")(_vm.terminal.unit_name)))]
      ),
    ]),
    _vm._v(" "),
    _vm.terminal.holder_dept !== undefined || _vm.terminal.holder_dept === ""
      ? _c("div", { staticClass: "status setLineHeight" }, [
          _c("div", { staticClass: "setDisplay" }, [
            _c("span", { staticClass: "deviceTitle" }, [_vm._v("归属部门")]),
            _vm.terminal.holder_dept !== ""
              ? _c(
                  "span",
                  {
                    staticClass: "deviceData",
                    staticStyle: { width: "190px" },
                    attrs: { title: _vm.terminal.holder_dept },
                  },
                  [_vm._v(_vm._s(_vm.terminal.holder_dept))]
                )
              : _c("span", { staticClass: "deviceData" }, [_vm._v("无")]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.terminal.brand === "测绘装备"
      ? _c("div", { staticClass: "setLineHeight" }, [
          _c(
            "span",
            {
              staticClass: "deviceTitle",
              staticStyle: { "margin-right": "9px" },
            },
            [_vm._v("设备品牌")]
          ),
          _c(
            "span",
            {
              staticClass: "deviceData",
              staticStyle: { width: "190px" },
              attrs: { title: _vm.terminal.device_brands_name },
            },
            [
              _vm._v(
                _vm._s(_vm._f("filterData")(_vm.terminal.device_brands_name))
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "setLineHeight" }, [
      _c("span", { staticClass: "deviceTitle userName" }, [
        _vm._v(
          _vm._s(_vm.terminal.msg_data.holder_user_data ? "持有人" : "使用人")
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "deviceData",
          staticStyle: { width: "78px" },
          attrs: { title: _vm.terminal.name },
        },
        [_vm._v(_vm._s(_vm._f("filterData")(_vm.terminal.name)))]
      ),
      _c("span", { staticClass: "phone" }, [
        _vm._v(_vm._s(_vm._f("filterMobile")(_vm.terminal.mobile))),
      ]),
    ]),
    _vm._v(" "),
    _vm.terminal.brand === "监控模块"
      ? _c("div", { staticClass: "setLineHeight" }, [
          _c(
            "span",
            {
              staticClass: "deviceTitle",
              staticStyle: { "margin-right": "9px", width: "74px" },
            },
            [_vm._v("关联设备")]
          ),
          _c(
            "span",
            {
              staticClass: "deviceData",
              staticStyle: { width: "50px" },
              attrs: { title: _vm.terminal.associated_device_type },
            },
            [
              _vm._v(
                _vm._s(
                  _vm._f("filterData")(_vm.terminal.associated_device_type)
                )
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "deviceData",
              staticStyle: { width: "140px", "margin-left": "11px" },
              attrs: { title: _vm.terminal.associated_device_software_code },
            },
            [_vm._v(_vm._s(_vm.terminal.associated_device_software_code))]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }