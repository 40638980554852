<template>
  <!-- 编辑巡护单位 -->
  <el-dialog
    title="编辑巡护单位"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    class="taskBox"
    v-dialogDrag
  >
    <el-divider></el-divider>
    <div class="detailBar">
      <div class="formBox">
        <el-form
          class="formbox"
          ref="ruleForm"
          :rules="rules"
          :model="ruleForm"
          label-width="80px"
        >
          <el-form-item class="userForm" prop="name" label="巡护单位">
            <el-input
              placeholder="请输入"
              v-model="ruleForm.unit_name"
            ></el-input>
          </el-form-item>
          <el-form-item class="userForm" prop="code" label="巡护代码">
            <el-input
              placeholder="请输入"
              v-model="ruleForm.code"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item class="userForm" prop="unit_password" label="登录密码">
            <el-input
              type="password"
              placeholder="**********"
              v-model="ruleForm.unit_password"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-divider></el-divider>
    <span slot="footer" class="dialog-footer">
      <el-button class="footBtn" type="primary" @click="editUnit"
        >确 认</el-button
      >
      <el-button class="footBtn" @click="dialogVisible = false"
        >取 消</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { mapState,mapMutations } from "vuex";
export default {
  inject: ["reload"],
  props: {
    currentParam: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      ruleForm: {
        unit_name: "", //设备ID
        code: "",
        unit_password: "", //手机号
        id: ""
      },
      rules: {
        name: [],
        code: [],
        unit_password: [
          { required: true,message: "请输入密码",trigger: "blur" },
          { min: 6,message: "最小不少于六位",trigger: "blur" }
        ]
      },
      dialogVisible: false
    };
  },
  methods: {
    openEdit () {
      this.dialogVisible=true;
      this.ruleForm.id=this.currentParam.id;
      this.ruleForm.unit_name=this.currentParam.unit_name;
      this.ruleForm.code=this.currentParam.unit_code;
    },
    editUnit () {
      this.$apis
        .edit(
          {
            unit_id: this.ruleForm.id,
            unit_name: this.ruleForm.unit_name,
            unit_password: this.ruleForm.unit_password
          },
          this.token
        )
        .then(data => {
          if(data.data.status==0) {
            this.dialogVisible=false;
            this.$message({
              message: "编辑成功",
              type: "success"
            });
          }
          this.reload();
         
        })
        .catch(err => {
          console.error(err);
        });
    }
  },
  created () { },
  computed: {
    ...mapState(["user","token"])
  }
};
</script>

<style lang="less" scoped>
.formBox {
  margin-top: 50px !important;
  margin-left: 30px;
  margin-bottom: 50px;
}
/deep/.el-dialog {
  width: 460px !important;
  height: 400px !important;
}
.footBtn {
  width: 140px;
  height: 48px;
  margin-left: 20px;
}
.el-input {
  width: 277px;
}
</style>