var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "show-close": false,
            visible: this.forgetVisible,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(this, "forgetVisible", $event)
            },
          },
        },
        [
          _vm.flagOne
            ? _c(
                "div",
                { staticClass: "registerNav" },
                [
                  _c("div", { staticClass: "loginLogo" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/new-logo.png"),
                        alt: "",
                        width: "60px",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "loginTit" }, [
                    _c("span", { staticClass: "loginword" }, [
                      _vm._v("无人机巡护指挥调度管理平台"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "getawaytit" }, [
                    _c("span", { staticClass: "getaword" }, [
                      _vm._v("【公众门户网站】"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "loginInput",
                      attrs: {
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "regInputBar",
                          attrs: { prop: "mobile" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入手机号" },
                            model: {
                              value: _vm.ruleForm.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "mobile", $$v)
                              },
                              expression: "ruleForm.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "demo-ruleForm",
                          attrs: { prop: "sms_code" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入验证码" },
                              model: {
                                value: _vm.ruleForm.sms_code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "sms_code", $$v)
                                },
                                expression: "ruleForm.sms_code",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "getVerification",
                                  attrs: {
                                    slot: "suffix",
                                    type: "text",
                                    disabled: _vm.disabled,
                                    sms_code: _vm.ruleForm.sms_code,
                                  },
                                  on: { click: _vm.getRegister },
                                  slot: "suffix",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.btnTitle) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "regInputBar",
                          attrs: { prop: "password" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入密码",
                              type: "password",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.ruleForm.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "password", $$v)
                              },
                              expression: "ruleForm.password",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "regInputBar",
                          attrs: { prop: "surePassword" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "password",
                              placeholder: "请确认密码",
                              autocomplete: "off",
                            },
                            model: {
                              value: _vm.ruleForm.surePassword,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "surePassword", $$v)
                              },
                              expression: "ruleForm.surePassword",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { staticClass: "regBar" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "loginBar",
                              attrs: { loading: _vm.loading, type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.userRegister("ruleForm")
                                },
                              },
                            },
                            [_vm._v("完  成")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "footerBar" },
                    [
                      _c(
                        "el-link",
                        {
                          staticClass: "forgetPsw",
                          attrs: { underline: false },
                          on: { click: _vm.loginBtn },
                        },
                        [_vm._v("登录账号")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.FlagTwo
            ? _c("div", { staticClass: "regisSuccess" }, [
                _c("img", {
                  staticClass: "loginImg",
                  attrs: {
                    src: require("../assets/img/gatewayImg/Icon／Fill／Check-Circle-Fill.png"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "rrgisWord" }, [
                  _vm._v("重置密码成功 !"),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "logintext" }, [
                  _vm._v(_vm._s(_vm.timeDown) + "s后自动跳转"),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }