<template>
  <div>
    <!-- 新建用户 -->
    <el-dialog
      title="更改巡护单位"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      class="taskBox"
      v-dialogDrag
    >
      <el-divider></el-divider>
      <div class="detailBar">
        <div class="unitInfo">
          <span class="numword"
            >当前巡护单位：{{ this.user.user_unit.unit_name }}</span
          >
          <p class="numword">巡护代码：{{ this.user.user_unit.unit_code }}</p>
        </div>
        <div class="formBox">
          <el-form
            class="formbox"
            ref="ruleForm"
            :model="ruleForm"
            label-width="80px"
          >
            <el-form-item class="userForm" prop="unit_id" label="巡护单位">
              <el-select
                v-model="ruleForm.unit_id"
                placeholder="请选择新的巡护单位（可搜索）"
                clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in unitData"
                  :key="index"
                  :label="item.unit_name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-divider></el-divider>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="footBtn"
          :loading="loading"
          @click="addBtn"
          type="primary"
          >确 认</el-button
        >
        <el-button class="footBtn" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      ruleForm: {
        unit_id: "",
      },
      unitData: [],
      page: 1,
    };
  },
  methods: {
    ...mapMutations(["userStorage"]),
    open() {
      this.dialogVisible = true;
    },
    getUserInfo() {
      this.$apis
        .info({}, this.token)
        .then((data) => {
          this.userStorage(data.data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addBtn() {
      this.$apis
        .updateUnit(
          {
            unit_id: this.ruleForm.unit_id,
          },
          this.token
        )
        .then((data) => {
         
          if (data.data.status == 0) {
            this.$message({
              message: data.data.message,
              type: "success",
            });
            this.getUserInfo();
            this.ruleForm.unit_id = "";
            this.dialogVisible = false;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 巡护列表
    unitsList() {
      this.$apis
        .unitList(
          { per_page: 1000000000, get_all: "yes" },
          this.token,
          "?page=" + this.page
        )
        .then((data) => {
          this.unitData = this.unitData.concat(data.data.data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  created() {
    this.unitsList();
  },
  computed: {
    ...mapState(["user", "token"]),
  },
};
</script>

<style lang="less" scoped>
.unitInfo {
  margin-left: 110px;
}
.numword {
  color: #838a9d;
  font-size: 14px;
}
.sign {
  width: 270px;
  font-size: 12px;
  color: #9b9da3;
  margin: 5px 0 0 80px;
}
.formBox {
  margin-top: 30px !important;
  margin-left: 30px;
}
/deep/.el-dialog {
  width: 460px !important;
  height: 340px !important;
  margin-left: 73vh !important;
  margin-top: 20vh !important;
}
.footBtn {
  width: 140px;
  height: 48px;
  margin-left: 20px;
}
/deep/.el-input {
  width: 235px;
}
/deep/.el-form-item__content {
  line-height: 16px;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-dialog__footer {
  text-align: center;
}
/deep/.el-dialog__title {
  margin-left: 25px;
  line-height: 50px;
}
/deep/.el-dialog__header {
  height: 50px;
}
/deep/.el-input--medium .el-input__inner {
  margin-top: 0px !important;
  margin-left: 0px !important;
}
</style>