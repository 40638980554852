var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "singleMonitor",
    },
    [
      _c(
        "div",
        {
          staticClass: "main",
          class: { "history-map-small": _vm.curContentType === "video" },
          attrs: { id: "mainWrapper" + _vm.source },
        },
        [
          _c("div", {
            staticClass: "map",
            class: { setBorder: !_vm.moreScreen },
            attrs: { id: _vm.software_code + _vm.source },
            on: { click: _vm.clickMonitor },
          }),
        ]
      ),
      _vm._v(" "),
      _vm.moreScreen
        ? _c("span", [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShowData,
                    expression: "isShowData",
                  },
                ],
                staticClass: "plane_data",
              },
              [
                _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                  _c("ul", [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "snIdData hideLength",
                        attrs: { title: _vm.snId },
                      },
                      [_vm._v(_vm._s(_vm.snId))]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("deviceData", {
                  attrs: {
                    terminal: _vm.listDeviceData,
                    setStyle: _vm.setStyle,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.planeType === "common"
              ? _c("div", { staticClass: "showDatas" }, [
                  _vm.brandName !== "彩虹"
                    ? _c("span", [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showData["仪表盘"],
                                expression: "showData['仪表盘']",
                              },
                            ],
                            ref: "realTime",
                            staticClass: "realTime",
                          },
                          [
                            _c("img", {
                              staticClass: "realTime_border",
                              staticStyle: { top: "-3px", left: "-2px" },
                              attrs: {
                                src: require("@/assets/img/homeDialog/leftTop.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "realTime_border",
                              staticStyle: { bottom: "-161px", left: "-33px" },
                              attrs: {
                                src: require("@/assets/img/homeDialog/leftBottom.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "realTime_border",
                              staticStyle: { right: "-206px", top: "-2px" },
                              attrs: {
                                src: require("@/assets/img/homeDialog/rightTop.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "realTime_border",
                              staticStyle: {
                                bottom: "-161px",
                                right: "-175px",
                              },
                              attrs: {
                                src: require("@/assets/img/homeDialog/rightBottom.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "realTime_body" }, [
                              _c("div", { staticClass: "lnglat" }, [
                                _c("div", { staticClass: "setImg" }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/homeDialog/lat.png"),
                                      alt: "",
                                      width: "20",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lnglat_title" }, [
                                    _vm._v("纬度"),
                                  ]),
                                  _c("span", { staticClass: "lnglat_data" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("saveLnglat1")(
                                          _vm.monitorPlaneData.Latitude ||
                                            _vm.monitorPlaneData.lat
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "setImg" }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/homeDialog/lng.png"),
                                      alt: "",
                                      width: "20",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lnglat_title" }, [
                                    _vm._v("经度"),
                                  ]),
                                  _c("span", { staticClass: "lnglat_data" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("saveLnglat1")(
                                          _vm.monitorPlaneData.Longitude ||
                                            _vm.monitorPlaneData.lon
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "line" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "realTime_data" }, [
                                _c("div", { staticClass: "time" }, [
                                  _c("table", [
                                    _c("tr", [
                                      _c("td", { staticClass: "title" }, [
                                        _vm._v("更新时间"),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "data" }, [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm._f("filtTimeStamp")(
                                                _vm.monitorPlaneData.TimeStamp
                                              )
                                            ) +
                                            "\n                    "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", { staticClass: "title" }, [
                                        _vm._v("飞行时间"),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "data" }, [
                                        _vm._v(_vm._s(_vm.flyTime)),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", { staticClass: "title" }, [
                                        _vm._v("海拔高度"),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "data" }, [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm._f("flitTowData")(
                                                _vm.monitorPlaneData.Altitude
                                              )
                                            ) +
                                            "\n                    "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", { staticClass: "title" }, [
                                        _vm._v("航迹角"),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "data" }, [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              Number(
                                                _vm.monitorPlaneData.Course
                                              ).toFixed(1)
                                            ) +
                                            "°\n                    "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "speed" }, [
                                  _vm._m(1),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "data" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm._f("flitOneData")(
                                            _vm.monitorPlaneData.Gs
                                          )
                                        ) +
                                        "m/s\n                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm._m(2),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "data",
                                      staticStyle: { color: "#35d9a7" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm._f("flitTowData")(
                                              _vm.monitorPlaneData.Height
                                            )
                                          ) +
                                          "m\n                "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.brandName === "彩虹"
                    ? _c("span", [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showData["仪表盘"],
                                expression: "showData['仪表盘']",
                              },
                            ],
                            staticClass: "realTime",
                            staticStyle: { height: "138px" },
                          },
                          [
                            _c("img", {
                              staticClass: "realTime_border",
                              staticStyle: { top: "-3px", left: "-2px" },
                              attrs: {
                                src: require("@/assets/img/homeDialog/leftTop.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "realTime_border",
                              staticStyle: { bottom: "-114px", left: "-33px" },
                              attrs: {
                                src: require("@/assets/img/homeDialog/leftBottom.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "realTime_border",
                              staticStyle: { right: "-206px", top: "-2px" },
                              attrs: {
                                src: require("@/assets/img/homeDialog/rightTop.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "realTime_border",
                              staticStyle: {
                                bottom: "-114px",
                                right: "-175px",
                              },
                              attrs: {
                                src: require("@/assets/img/homeDialog/rightBottom.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "realTime_body" }, [
                              _c("div", { staticClass: "lnglat" }, [
                                _c("div", { staticClass: "setImg" }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/homeDialog/lat.png"),
                                      alt: "",
                                      width: "20",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lnglat_title" }, [
                                    _vm._v("纬度"),
                                  ]),
                                  _c("span", { staticClass: "lnglat_data" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("saveLnglat1")(
                                          _vm.monitorPlaneData.Latitude ||
                                            _vm.monitorPlaneData.lat
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "setImg" }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/homeDialog/lng.png"),
                                      alt: "",
                                      width: "20",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lnglat_title" }, [
                                    _vm._v("经度"),
                                  ]),
                                  _c("span", { staticClass: "lnglat_data" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("saveLnglat1")(
                                          _vm.monitorPlaneData.Longitude ||
                                            _vm.monitorPlaneData.lon
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "line" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "realTime_data" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "CaiHonTime",
                                    staticStyle: { border: "none" },
                                  },
                                  [
                                    _c("table", [
                                      _c("tr", [
                                        _c("td", { staticClass: "title" }, [
                                          _vm._v("更新时间"),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", { staticClass: "data" }, [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm._f("filtTimeStamp")(
                                                  _vm.monitorPlaneData.TimeStamp
                                                )
                                              ) +
                                              "\n                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", { staticClass: "title" }, [
                                          _vm._v("飞行时间"),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", { staticClass: "data" }, [
                                          _vm._v(_vm._s(_vm.flyTime)),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("tr", [
                                        _c("td", { staticClass: "title" }, [
                                          _vm._v("海拔高度"),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", { staticClass: "data" }, [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                Number(
                                                  _vm.monitorPlaneData.Altitude
                                                ).toFixed(1)
                                              ) +
                                              "\n                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", { staticClass: "title" }, [
                                          _vm._v("航迹角"),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", { staticClass: "data" }, [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                Number(
                                                  _vm.monitorPlaneData.Course
                                                ).toFixed(1)
                                              ) +
                                              "°\n                    "
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ])
              : _c("div", [
                  _vm.feimaManager.fmMonitor !== undefined
                    ? _c("span", { staticClass: "showDatas" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showData["仪表盘"],
                                expression: "showData['仪表盘']",
                              },
                            ],
                            staticClass: "realTime",
                          },
                          [
                            _c("img", {
                              staticClass: "realTime_border",
                              staticStyle: { top: "-3px", left: "-2px" },
                              attrs: {
                                src: require("@/assets/img/homeDialog/leftTop.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "realTime_border",
                              staticStyle: { bottom: "-161px", left: "-33px" },
                              attrs: {
                                src: require("@/assets/img/homeDialog/leftBottom.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "realTime_border",
                              staticStyle: { right: "-206px", top: "-2px" },
                              attrs: {
                                src: require("@/assets/img/homeDialog/rightTop.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "realTime_border",
                              staticStyle: {
                                bottom: "-161px",
                                right: "-175px",
                              },
                              attrs: {
                                src: require("@/assets/img/homeDialog/rightBottom.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "realTime_body" }, [
                              _c("div", { staticClass: "lnglat" }, [
                                _c("div", { staticClass: "setImg" }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/homeDialog/lat.png"),
                                      alt: "",
                                      width: "20",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lnglat_title" }, [
                                    _vm._v("纬度"),
                                  ]),
                                  _vm.feimaManager.fmMonitor !== undefined
                                    ? _c(
                                        "span",
                                        { staticClass: "lnglat_data" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.feimaManager.fmMonitor
                                                .flightPanelDatas.latitude
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "setImg" }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/homeDialog/lng.png"),
                                      alt: "",
                                      width: "20",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "lnglat_title" }, [
                                    _vm._v("经度"),
                                  ]),
                                  _vm.feimaManager.fmMonitor !== undefined
                                    ? _c(
                                        "span",
                                        { staticClass: "lnglat_data" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.feimaManager.fmMonitor
                                                .flightPanelDatas.longitude
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "line" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "realTime_data" }, [
                                _c("div", { staticClass: "time" }, [
                                  _c("table", [
                                    !_vm.isHistory
                                      ? _c("tr", [
                                          _c("td", { staticClass: "title" }, [
                                            _vm._v("更新时间"),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", { staticClass: "data" }, [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(_vm.fmUpdateAt) +
                                                "\n                    "
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.brandName !== "大疆"
                                      ? _c("tr", [
                                          _c("td", { staticClass: "title" }, [
                                            _vm._v("海拔高度"),
                                          ]),
                                          _vm._v(" "),
                                          _vm.feimaManager.fmMonitor
                                            .flightPanelDatas &&
                                          _vm.feimaManager.fmMonitor
                                            .flightPanelDatas.altitude
                                            ? _c(
                                                "td",
                                                { staticClass: "data" },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.isPackage
                                                          ? Math.round(
                                                              Number(
                                                                _vm.feimaManager.fmMonitor.flightPanelDatas.altitude.split(
                                                                  "m"
                                                                )[0]
                                                              ) / 1000
                                                            )
                                                          : _vm.feimaManager
                                                              .fmMonitor
                                                              .flightPanelDatas
                                                              .altitude
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", { staticClass: "title" }, [
                                        _vm._v("航迹角"),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "data" }, [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.feimaManager.fmMonitor
                                                .flightPanelDatas.angle
                                            ) +
                                            "\n                    "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "speed" }, [
                                  _c("div", { staticClass: "title" }, [
                                    _c("span", { staticClass: "flyName" }, [
                                      _vm._v("飞行速度"),
                                    ]),
                                    _c("span", { staticClass: "goalSpeed" }, [
                                      _vm._v(
                                        "目标\n                    " +
                                          _vm._s(
                                            _vm.feimaManager.fmMonitor.flightPanelDatas.target_speed.split(
                                              "m"
                                            )[0]
                                          )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "data" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.feimaManager.fmMonitor
                                            .flightPanelDatas.speed
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { color: "#35d9a7" },
                                    },
                                    [
                                      _c("span", { staticClass: "flyName" }, [
                                        _vm._v("飞行高度"),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "goalSpeed goalHeight" },
                                        [
                                          _vm._v(
                                            "目标\n                    " +
                                              _vm._s(
                                                _vm.feimaManager.fmMonitor.flightPanelDatas.target_height.split(
                                                  "m"
                                                )[0]
                                              )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.feimaManager.fmMonitor.flightPanelDatas &&
                                  _vm.feimaManager.fmMonitor.flightPanelDatas
                                    .drone_height
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "data",
                                          staticStyle: { color: "#35d9a7" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.isPackage
                                                  ? Math.round(
                                                      Number(
                                                        _vm.feimaManager.fmMonitor.flightPanelDatas.drone_height.split(
                                                          "m"
                                                        )[0]
                                                      ) / 1000
                                                    )
                                                  : _vm.feimaManager.fmMonitor
                                                      .flightPanelDatas
                                                      .drone_height
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "planeData" }, [
                          _vm._m(3),
                          _vm._v(" "),
                          _c("div", { staticClass: "planeDataBody" }, [
                            _c("div", { staticClass: "peopleDistance" }, [
                              _c("div", { on: { click: _vm.showSpellIt } }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/img/homeDialog/peopleDistance.png"),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "data" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.feimaManager.fmMonitor
                                        .flightPanelDatas.drone_pilot_distance
                                    ) +
                                    "\n              "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "line" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "datas" }, [
                              _c("div", { staticClass: "setData" }, [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("航点"),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "textData" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.feimaManager.fmMonitor
                                        .flightPanelDatas.flight_progress
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "setData" }, [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("里程"),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "textData" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.feimaManager.fmMonitor
                                        .flightPanelDatas.flight_miles
                                    )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "setData" }, [
                                _c("span", { staticClass: "title" }, [
                                  _vm._v("照片"),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "textData" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.feimaManager.fmMonitor
                                        .flightPanelDatas.photo_count
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "control" }, [
              _vm.planeFlightData
                ? _c("div", { style: { top: _vm.playBackTop } }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/img/homeDialog/playback.png"),
                        alt: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.showBack = !_vm.showBack
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.showBack && _vm.feimaManager !== ""
                      ? _c(
                          "div",
                          { staticClass: "playback" },
                          [
                            _c("playBack", {
                              ref: "playBack",
                              attrs: {
                                endTime: _vm.endTime,
                                FlightTime: _vm.monitorPlaneData.FlightTime,
                                allTimeS: _vm.allTimeS,
                              },
                              on: {
                                pause: _vm.pause,
                                openBackPlay: _vm.openBackPlay,
                                moveProgress: _vm.moveProgress,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.software_code !== "-1",
                      expression: "software_code !== '-1'",
                    },
                  ],
                  staticClass: "show",
                  staticStyle: { top: "31%" },
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isControl !== 2,
                        expression: "isControl !== 2",
                      },
                    ],
                    attrs: {
                      src: require("@/assets/img/homeDialog/setShow.png"),
                      alt: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickControl(2)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isControl === 2,
                        expression: "isControl === 2",
                      },
                    ],
                    attrs: {
                      src: require("@/assets/img/homeDialog/selectShowOrHidden.png"),
                      alt: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickControl(2)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isControl === 2,
                          expression: "isControl === 2",
                        },
                      ],
                      staticClass: "showView",
                    },
                    [
                      _c("showView", {
                        attrs: { showData: _vm.showData },
                        on: { selectShow: _vm.selectShow },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { top: "32%" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/homeDialog/switchMap.png"),
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.clickControl(3)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isControl === 3,
                        expression: "isControl === 3",
                      },
                    ],
                    staticClass: "switchMap",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clickMap",
                        on: {
                          click: function ($event) {
                            return _vm.switchMap(1)
                          },
                        },
                      },
                      [
                        _vm.mapIndex === 1
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/img/homeDialog/selectGaode.png"),
                                alt: "",
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: require("@/assets/img/homeDialog/gaode.png"),
                                alt: "",
                              },
                            }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "mapType",
                            class: _vm.mapIndex === 1 ? "isMap" : "",
                          },
                          [_vm._v("\n              默认地图\n            ")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "clickMap",
                        on: {
                          click: function ($event) {
                            return _vm.switchMap(2)
                          },
                        },
                      },
                      [
                        _vm.mapIndex === 2
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/img/homeDialog/selectTianditu.png"),
                                alt: "",
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: require("@/assets/img/homeDialog/tianditu.png"),
                                alt: "",
                              },
                            }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "mapType",
                            class: _vm.mapIndex === 2 ? "isMap" : "",
                          },
                          [_vm._v("\n              天地图\n            ")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "clickMap",
                        on: {
                          click: function ($event) {
                            return _vm.switchMap(3)
                          },
                        },
                      },
                      [
                        _vm.mapIndex === 3
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/img/homeDialog/selectYingxiang.png"),
                                alt: "",
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: require("@/assets/img/homeDialog/yingxiang.png"),
                                alt: "",
                              },
                            }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "mapType",
                            class: _vm.mapIndex === 3 ? "isMap" : "",
                          },
                          [_vm._v("\n              影像瓦片\n            ")]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.software_code !== "-1",
                      expression: "software_code !== '-1'",
                    },
                  ],
                  staticStyle: { top: "33.5%" },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/homeDialog/mobileBrowsing.png"),
                      alt: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickControl(4)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isControl === 4,
                          expression: "isControl === 4",
                        },
                      ],
                      staticClass: "qrCode",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vueQr" },
                        [
                          _c("vue-qr", {
                            staticClass: "qr",
                            attrs: {
                              size: 172,
                              margin: 0,
                              "auto-color": true,
                              "dot-scale": 1,
                              text: _vm.toDetailUrl,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", [_vm._v("扫码手机观看")]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { top: "43%" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/homeDialog/redThread.png"),
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.clickControl(5)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.software_code !== "-1" && !_vm.isHistory,
                      expression: "software_code !== '-1' && !isHistory",
                    },
                  ],
                  staticStyle: { top: "46%" },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/homeDialog/video.png"),
                      alt: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clickControl(6)
                      },
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isControl === 6 && _vm.planeType !== "common",
                    expression: "isControl === 6 && planeType !== 'common'",
                  },
                ],
                staticClass: "video",
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !(
                          _vm.mainContentType === "video" && _vm.isMapHidden
                        ),
                        expression:
                          "!(mainContentType === 'video' && isMapHidden)",
                      },
                    ],
                    staticClass: "popuper",
                    attrs: { id: "popuper" + _vm.source },
                    on: {
                      click: function ($event) {
                        return _vm.switchVideoMap("popuper")
                      },
                    },
                  },
                  [
                    _c("video", {
                      staticClass: "videoBody",
                      attrs: {
                        autoplay: "",
                        muted: "",
                        id: "videoBody" + _vm.source,
                      },
                      domProps: { muted: true },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "footer" }, [
                  _c("div", { staticClass: "left" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.mainContentType === "map",
                            expression: "mainContentType === 'map'",
                          },
                        ],
                      },
                      [_vm._v("直播中")]
                    ),
                    _vm._v(" "),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.mainContentType === "map",
                          expression: "mainContentType === 'map'",
                        },
                      ],
                      staticClass: "playIcon",
                      attrs: {
                        src: require("@/assets/img/homeDialog/live.png"),
                        alt: "",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "right" }, [
                    _vm.mainContentType === "map"
                      ? _c("div", {
                          staticClass: "videoListIcon",
                          class: [
                            _vm.isShowVideoList ? "videoListHl" : "videoList",
                          ],
                          on: { click: _vm.showVideoList },
                        })
                      : _c("div", {
                          staticClass: "videoListIcon",
                          class: [_vm.isMapHidden ? "showMap" : "hideMap"],
                          on: { click: _vm.hidePopupMap },
                        }),
                  ]),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canFly && _vm.planeType !== "common"
        ? _c(
            "div",
            { staticClass: "spell" },
            [
              _c("spell-it", {
                ref: "spellIt",
                attrs: {
                  mission_id: _vm.mission_id,
                  flight_task_name: _vm.flight_task_name,
                  isHistory: _vm.planeFlightData,
                },
                on: {
                  spellitImg: _vm.spellitImg,
                  clearSingleTileImagers: _vm.clearSingleTileImagers,
                  clearTileLayer: _vm.clearTileLayer,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isHistory && _vm.feimaManager
        ? _c("ComVideoList", {
            staticClass: "com-video-list",
            attrs: { missionId: _vm.mission_id },
            on: { setVideoUrl: _vm.setVideoUrl },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.videoUrl,
              expression: "videoUrl",
            },
          ],
          staticClass: "video-body",
          class: { "video-player-full": _vm.curContentType === "video" },
        },
        [
          _vm.curContentType !== "video"
            ? _c(
                "div",
                {
                  staticClass: "close",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.closeHistoryVideo($event)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/homeDialog/littleBtn/off-pre.png"),
                      alt: "",
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "100%" } }, [
            _c("video", {
              attrs: {
                id: "historyVideo",
                controlslist: "noplaybackrate",
                src: _vm.videoUrl,
                controls: "",
                autoplay: "",
                muted: "",
                loop: "loop",
              },
              domProps: { muted: true },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onVideoClick($event)
                },
              },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "snIdTitle" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/homeDialog/snId.png"), alt: "" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "text" }, [_vm._v("SN码")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("飞行速度")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "title", staticStyle: { color: "#35d9a7" } },
      [_c("span", [_vm._v("飞行高度")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bcg" }, [
      _c("img", {
        staticClass: "realTime_border1",
        staticStyle: { top: "-3px", left: "-2px" },
        attrs: { src: require("@/assets/img/homeDialog/leftTop.png"), alt: "" },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "realTime_border1",
        staticStyle: { bottom: "-1px", left: "-1px" },
        attrs: {
          src: require("@/assets/img/homeDialog/leftBottom.png"),
          alt: "",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "realTime_border1",
        staticStyle: { right: "-1px", top: "-1px" },
        attrs: {
          src: require("@/assets/img/homeDialog/rightTop.png"),
          alt: "",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "realTime_border1",
        staticStyle: { bottom: "-1px", right: "-1px" },
        attrs: {
          src: require("@/assets/img/homeDialog/rightBottom.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }