import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";

import createPersistedState from "vuex-persistedstate"; // 持久化存储

Vue.use(Vuex);

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context("./modules", true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

const store = new Vuex.Store({
  state: {
    newTaskNum: false, //  新建任务的弹框
    user: {
      created_at: "",
      deleted_at: null,
      id: "",
      is_admin: "0",
      is_unit: "0",
      mobile: "",
      name: "",
      role_id: "",
      role_name: "",
      unit_id: "",
      updated_at: "",
      user_unit: {
        user_unit: "",
        unit_name: ""
      }
    }, //  用户信息
    token: "", //  令牌
    unit_token: "", // 巡护账号token
    unit: {
      created_at: "",
      id: "",
      unit_code: "",
      unit_name: "",
      unit_pinyin: "",
      updated_at: "",
      user_id: ""
    },
    drawer: false,
    intrdrawer: false,
    resultbased: true, // 目录查询
    Jurisdiction: true,
    navMessage: "0", //  导航栏跳转
    currentUrl: ["首页", "任务下达", "航线任务"], //  头部当前所在路径
    role: 2, //  登录的角色权限,0超管，1巡护管理，2用户
    chatDialog: {
      // 聊天dialog
      show: false,
      key: "",
      window: "home",
      project: {
        id: "",
        name: ""
      },
      task: {
        id: ""
      },
      unread_num: 0, //未读数
      unread_num_arr: [], //项目未读数数组
      new_msg: {
        //新消息结构体
        wait_handle: 0,
        cmd: "",
        data: {}
      }
    },
    chatAddDialog: {
      // 聊天发起框、成员框dialog
      show: false,
      key: "",
      type: 1, // 操作类型 1 添加群聊 2 添加音视频 3 添加成员
      result: {}, // 添加成功返回
      add_user_data: {} // 添加成员时，传递的数据
    },
    loginVisible: false,
    registerVisible: false,
    forgetVisible: false,
    mediaRequestDialog: {
      // 音视频请求dialog
      show: false,
      photo: "",
      name: "",
      url: "",
      room_id: ""
    },
    mediaDialog: {
      // 音视频dialog
      show: false,
      url: "",
      user: [],
      join_user: []
    },
    centerDialogVisible: true, // 控制引导页显隐
    msgCenter: {
      // 消息中心监听
      new_msg: {
        wait_handle: 0,
        cmd: "",
        data: {}
      },
      unread_num: {
        // 未读数
        task: 0,
        task_command: 0,
        task_file: 0,
        data_apply: 0,
        warning: 0,
        disaster: 0
      }
    },
    taskWslisten: {
      // 任务websocket监听
      new_msg: {
        wait_handle: 0,
        cmd: "",
        data: {}
      }
    },
    interestTypeList: [], //兴趣点类型
    monitorPlaneData: {
      Altitude: 0,
      Coordinate: "WGS-84",
      Course: 0,
      FlightTime: 0,
      Gs: 0,
      Height: 0,
      Latitude: '00.000000',
      Longitude: '00.0000000',
      ManufactureID: "",
      OrderID: "",
      TimeStamp: "",
      UASID: "",
      software_code: "",
    },
    orderQty: {
    "status_1": 0,
    "status_2": 0,
    "status_3": 0,
    "status_4": 0 },
    earthLoading: false
  },
  modules,
  getters,
  mutations: {
    newTaskNumStorage(state, data) {
      state.newTaskNum = data;
    },
    userStorage(state, data) {
      state.user = data;
      if (state.user.is_admin == 1) {
        state.role = 0;
      } else if (state.user.is_admin == 0 && state.user.is_unit == 1) {
        state.role = 1;
      } else if (state.user.is_admin == 0 && state.user.is_unit == 0) {
        state.role = 2;
      }
    },
    loginStorage(state, data) {
      state.loginVisible = data;
    },
    registerStorage(state, data) {
      state.registerVisible = data;
    },
    forgetStorage(state, data) {
      state.forgetVisible = data;
    },
    tokenStorage(state, data) {
      state.token = data;
    },
    unitStorage(state, data) {
      state.unit_token = data;
    },
    unituserStorage(state, data) {
      state.unit = data;
    },
    chawidStorage(state, data) {
      state.drawer = data;
    },
    chaintrStorage(state, data) {
      state.intrdrawer = data;
    },
    achivevStorage(state, data) {
      state.resultbased = data;
    },
    jurisidStorage(state, data) {
      state.Jurisdiction = data;
    },
    navMessageStorage(state, data) {
      state.navMessage = data;
    },
    visibleStorage(state, data) {
      state.centerDialogVisible = data;
    },
    currentUrlStorage(state, data) {
      state.currentUrl = data;
    },
    roleStorage(state, data) {
      state.role = data;
    },
    logOut(state) {
      sessionStorage.clear();
      localStorage.removeItem("TokenKeyToUser");
      localStorage.removeItem("userInfo");
      state.user = {};
      state.token = null;
      state.msgCenter.unread_num.task = 0;
      state.msgCenter.unread_num.task_command = 0;
      state.msgCenter.unread_num.task_file = 0;
    },
    logOutUnit(state) {
      sessionStorage.clear();
      state.unit = {};
      state.unit_token = null;
    },
    chatDialogStorage(state, data) {
      state.chatDialog = data;
    },
    newChatMsg(state, cmd, data) {
      state.chatDialog.new_msg.wait_handle = 1;
      state.chatDialog.new_msg.cmd = cmd;
      state.chatDialog.new_msg.data = data;
    },
    clearInterestTypeList(state) {
      state.interestTypeList = [];
    },
    setMonitorPlaneData(state, data) {
      state.monitorPlaneData = data;
    },
    setOrderQty(state, data) {
      state.orderQty = data
    },
    setEarthLoading(state,data){
      state.earthLoading =data
    }
  },
  actions: {},
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
});

export default store;
