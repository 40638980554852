<template>
  <div class="allianceNav">
    <navTop />
    <div class="allianceBody">
      <div class="navBody">
        <div class="topNav">
          <div class="leftBtn">
            <el-button v-if="showOne" type="primary" plain @click="newUser"
              >新建用户</el-button
            >
            <el-button
              v-if="!showOne"
              type="primary"
              plain
              @click="addgroupUser"
              >添加任务群组成员</el-button
            >
          </div>
          <div class="rightInp">
            <!-- 搜索框 -->
            <el-input
              class="alliInput"
              placeholder="请输入账号/账户名称进行搜索"
              @keyup.enter.native="search"
              @input="fucusAll(keywordAll)"
              v-model="keywordAll"
            >
              <i slot="suffix" @click="search" class="iconfont searchAll"
                >&#xe61d;</i
              >
            </el-input>
          </div>
        </div>
        <el-divider class="sider"></el-divider>
        <div class="midlleNav">
          <div class="tables" v-if="showOne">
            <span class="feimaTit">巡护单位：</span>
            <span class="titname">{{ unitname }}</span
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span class="feimaTit">巡护代码：</span>
            <span class="titname">{{ unitcode }}</span>
          </div>
          <div class="tables" v-if="!showOne">
            <span class="feimaTit">任务群组：</span>
            <span class="titname">{{ groupname }}</span>
          </div>
          <div class="tabList">
            <div class="unitNav">
              <el-table
                height="100%"
                :data="userterminalList"
                style="width: 100%"
                :stripe="true"
                :header-cell-style="{ background: '#DDE6EF' }"
                v-if="showOne"
              >
                <el-table-column
                  label="账号"
                  width="180"
                  class="number"
                  align="center"
                >
                  <template slot-scope="scope">
                    <i v-if="scope.row.is_admin == 1" class="iconfont icon-VIP"
                      >&#xe632;</i
                    >
                    <i
                      v-if="scope.row.is_admin == 0 && scope.row.is_unit == 1"
                      class="iconfont icon-pre"
                      >&#xe605;</i
                    >
                    <i
                      v-else-if="
                        scope.row.is_admin !== 1 && scope.row.is_unit !== 1
                      "
                      class="iconfont icon-member"
                    ></i>
                    <img
                      class="userhead"
                      src="../../assets/img/user-default-head-small.png"
                      alt
                    />
                    {{ scope.row.mobile }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="账户名称"
                  align="center"
                ></el-table-column>
                <el-table-column label="账户权限" align="center">
                  <template slot-scope="scope">
                    <el-popover placement="right" width="140" trigger="click">
                      <div class="jurisdiction-list">
                        <div
                          v-for="(item, index) in gridData"
                          :class="{
                            'jurisdiction-list-div':
                              jurisdictionIndex === item.id,
                          }"
                          :key="index"
                          @click="jurisdictionClick(scope.row, item)"
                        >
                          {{ item.name }}
                        </div>
                      </div>
                      <el-button
                        @click="rolechildList(scope.row)"
                        slot="reference"
                      >
                        {{ scope.row.role_name }}</el-button
                      >
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column
                  label="是否为超管"
                  v-if="showOne"
                  width="100"
                  align="center"
                >
                  <template v-if="showOne" slot-scope="scope">
                    <el-link
                      title="点击更改"
                      @click="setUser(scope.row)"
                      v-if="scope.row.is_admin == 1"
                      :underline="false"
                      >是</el-link
                    >
                    <el-link
                      title="点击更改"
                      @click="setUser(scope.row)"
                      v-else
                      :underline="false"
                      >否</el-link
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="register_channel"
                  label="账户类型"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="created_at_format"
                  label="注册时间"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="holder_dept"
                  width="70"
                  v-if="showOne"
                  label="操作"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-button
                      class="feimabtn"
                      @click="delUnitUser(scope.row)"
                      type="text"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-table
                :stripe="true"
                height="100%"
                style="width: 100%"
                :header-cell-style="{ background: '#DDE6EF' }"
                :data="groupUserList"
                v-if="!showOne"
              >
                <el-table-column
                  label="账号"
                  width="180"
                  class="number"
                  align="center"
                >
                  <template slot-scope="scope">
                    <i
                      v-if="scope.row.user_id == dataid"
                      class="iconfont icon-groupUser"
                      >&#xe62a;</i
                    >
                    <i
                      v-else-if="scope.row.user_id !== dataid"
                      class="iconfont icon-member"
                    ></i>
                    <img
                      class="userhead"
                      src="../../assets/img/user-default-head-small.png"
                      alt
                    />
                    {{ scope.row.mobile }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="账户名称"
                  align="center"
                ></el-table-column>

                <el-table-column label="成员类型" align="center">
                  <template slot-scope="scope">{{
                    scope.row.user_id == dataid ? "管理员" : "成员"
                  }}</template>
                </el-table-column>

                <el-table-column
                  prop="created_at"
                  label="注册时间"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="holder_dept"
                  v-if="!showOne"
                  label="操作"
                  align="center"
                >
                  <template v-if="!showOne" slot-scope="scope">
                    <el-button
                      class="editBtn"
                      @click="details(scope.row)"
                      type="text"
                      >详情</el-button
                    >
                    <el-button
                      class="feimabtn"
                      @click="delUnit(scope.row)"
                      type="text"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
              <div v-if="showOne" class="block">
                <el-pagination
                  :current-page="current_page"
                  :page-size="this.per_page"
                  layout="total,  prev, pager, next, jumper"
                  :total="this.total"
                  @current-change="currentchange"
                  @next-click="handleNext"
                  @prev-click="handlePrev"
                />
                <div class="footNav">
                  <p v-if="showOne" class="numalliance">
                    巡护终端用户：共{{ total }}个
                    <el-button
                      v-if="role !== 2"
                      @click="exportFile"
                      type="primary"
                      plain
                      class="exportFileStyle"
                      size="mini"
                      >导出</el-button
                    >
                  </p>
                  <p v-if="!showOne" class="numalliance">
                    任务群组成员：共{{ this.groupUserList.length }}个
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="operationBtn">
        <div class="searchInp">
          <el-input
            type="text"
            v-model="keyword"
            class="seainput"
            placeholder="请输入巡护单位搜索"
            @keyup.enter.native="unitsList"
            @input="fucus(keyword)"
          >
            <i slot="suffix" @click="unitsList" class="iconfont search"
              >&#xe61d;</i
            >
          </el-input>
        </div>
        <el-divider class="sider"></el-divider>
        <!-- 巡护单位列表 -->
        <div
          class="unitlist"
          v-infinite-scroll="load"
          infinite-scroll-disabled="disabled"
        >
          <el-collapse accordion>
            <div
              class="hhhh"
              @click="getItem(item)"
              v-for="(item, index) in unitData"
              :key="index"
            >
              <div class="baoBar" @click="titBtn(item)">
                <el-collapse-item>
                  <template>
                    <div class="collapse-title" slot="title">
                      <p :title="item.unit_name" class="unitName">
                        {{ item.unit_name }}
                      </p>
                      <i @click="iconBtn(item)" class="iconfont more"
                        >&#xe633;</i
                      >
                    </div>
                  </template>
                  <div
                    class="Photography"
                    v-for="(item, cindex) in groupData"
                    :key="cindex"
                  >
                    <div
                      class="photos"
                      :style="{
                        background: groupId == item.id ? '#dde2ee' : '',
                      }"
                      @click="addgroupUserBtn(item), (showOne = false)"
                    >
                      <span class="photoname">{{ item.name }}</span>
                      <i @click="SonBtn(item)" class="iconfont more"
                        >&#xe633;</i
                      >
                    </div>
                  </div>
                </el-collapse-item>
              </div>
            </div>
          </el-collapse>
          <p v-if="loading" style="margin-top: 10px" class="loading">
            <i class="el-icon-loading"></i>
          </p>
          <!-- <p v-if="noMore" class="noMore">没有更多了</p> -->
        </div>
        <div class="footBar">
          <p>巡护单位：共{{ userRelated.unit_count }}个</p>
          <p>用户总数：共{{ userRelated.user_count }}个</p>
          <p>任务群组：共{{ userRelated.group_count }}个</p>
        </div>
        <el-dialog
          title="新建任务群组"
          :visible.sync="Fdialog"
          width="20%"
          :close-on-click-modal="false"
        >
          <span>新建一个任务群组</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="Fdialog = false">取 消</el-button>
            <el-button type="primary" @click="newTask">新 建</el-button>
          </span>
        </el-dialog>
        <el-dialog
          title="更多操作"
          :visible.sync="Sdialog"
          width="25%"
          :close-on-click-modal="false"
        >
          <span slot="footer" class="dialog-footer">
            <el-button @click="delGrounp">删除任务群组</el-button>
            <el-button type="primary" @click="editGrounp"
              >编辑任务群组</el-button
            >
          </span>
        </el-dialog>
      </div>
    </div>
    <AddUser ref="adduser" :unitID="unitID" @refresh="refresh" />
    <NewTask ref="newtask" :grounpId="grounpId" />
    <delGroupUser
      ref="deluser"
      :groupUser="groupUser"
      @refresh="refGroupUserList"
    />
    <EditGroup ref="editgroup" :grounpInfo="grounpInfo" :groupObj="groupObj" />
    <AddMember
      ref="paltform"
      :grounpObj="grounpObj"
      @handAddReg="getChooseList"
      @refresh="refGroupUserList"
    />
    <Detail ref="detail" :detailObj="detailObj" :detailList="detailList" />
    <Choose ref="reference" :userform="userform" @refresh="refresh" />
    <delUnitUser
      :delStr="'alliance'"
      ref="delunituser"
      @refresh="refresh"
      :unitUser="unitUser"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import AddUser from "@/components/messageBox/addUser";
import NewTask from "@/components/messageBox/newTask";
import delGroupUser from "@/components/messageBox/delGroupUser";
import delUnitUser from "@/components/messageBox/delUnitUser";
import EditGroup from "@/components/messageBox/editGroup";
import InPlatForm from "@/components/messageBox/inplatform";
import Detail from "@/components/messageBox/details";
import Choose from "@/components/messageBox/choose";
import AddMember from "@/components/messageBox/addMember";
import { exportFile } from "@/utils/exportFile";
export default {
  inject: ["reload"],
  components: {
    AddUser,
    NewTask,
    delGroupUser,
    EditGroup,
    AddMember,
    Detail,
    Choose,
    delUnitUser,
  },
  data() {
    return {
      jurisdictionIndex: 0,
      gridData: [
        {
          created_at: "2020-09-16 17:11:56",
          id: 8,
          name: "超管111",
          role_id: 3,
          updated_at: "2020-09-28 17:33:37",
          user_id: 64,
        },
      ],
      Fdialog: false,
      Sdialog: false,
      Ydialog: false,
      userRelated: {},
      userterminalList: [],
      dialogVisible: false,
      unitInfo: [],
      unitname: "",
      unitcode: "",
      unitNum: "",
      page: 1, //起始页
      loading: false,
      totalPages: "", // 总页数
      unitData: [], // 数组
      groupData: [], // 群组列表
      unitID: {},
      unitUser: {},
      groupUser: {},
      grounpId: {},
      grounpInfo: {},
      groupObj: {},
      groupIdList: {},
      detailObj: {},
      detailList: {},
      userform: {},
      grounpObj: {},
      showOne: true,
      showTwo: false,
      flag: false,
      keyword: "",
      keywordAll: "",
      unitUserList: [],
      userIdList: [],
      groupId: "",
      userIdentity: [],
      dataid: 0,
      groupUserList: [],
      groupname: "",
      item: {},
      current_page: 1, // 当前页
      total: 0, // 总条目数
      per_page: 8, // 每页多少条
    };
  },
  mounted() {
    if (this.$route.query.nav == "zhongduanuser") {
      this.currentUrlStorage(["首页", "用户管理", "巡护终端用户"]);
    }
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    /**
     * 获取子角色
     */
    rolechildList(data) {
      this.gridData = [];

      this.$apis
        .rolechildList(
          {
            role_id: data.role_id,
          },
          this.token
        )
        .then((e) => {
          this.gridData = e.data.data;
          this.gridData.unshift({
            id: 0,
            name:
              data.role_id === 3
                ? "超级管理员"
                : data.role_id === 2
                ? "巡护管理员"
                : "巡护终端用户",
          });
          this.jurisdictionIndex = data.role_child_id;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 切换当前所选账户的角色
     */
    jurisdictionClick(user, role_child) {
      this.$apis
        .setChild(
          {
            user_id: user.id,
            role_child_id: role_child.id,
          },
          this.token
        )
        .then((data) => {
          this.jurisdictionIndex = role_child.id;
          this.userList();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    refresh() {
      this.userList();
    },
    refGroupUserList() {
      this.addgroupUserBtn(this.item);
    },
    refreshGroup() {
      this.groupSearch();
      this.Sdialog = false;
    },
    // 接收选中的用户数组
    getChooseList(param) {
      this.unitUserList = param;
      for (let i = 0; i < this.unitUserList.length; i++) {
        this.userIdList.push(this.unitUserList[i].id);
      }
      this.addUser();
      this.addgroupUserBtn(this.item);
    },
    // 添加群组用户
    addUser() {
      this.$apis
        .groupuseradd(
          {
            group_id: this.groupId,
            user_id: this.userIdList,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status == 0) {
            this.addgroupUserBtn(this.item);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fucus(keyword) {
      if (keyword == "") {
        this.unitsList();
      }
    },
    fucusAll(keywordAll) {
      if (keywordAll == "") {
        this.search();
      }
    },
    getItem(item) {
      this.groupId = "";
      this.detailList = item;
      this.groupObj = item;
      this.grounpObj = item;
      // 阻止事件冒泡
      if (event && event.stopPropagation) {
        event.stopPropagation(); // 非IE浏览器
        this.showOne = true;
      } else {
        event.cancelBubble = true; //IE浏览器
      }
    },
    // 删除群组
    delGrounp() {
      this.$confirm("此操作将删除该群组, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$apis
            .groupdel(
              {
                group_id: this.grounpInfo.id,
              },
              this.token
            )
            .then((data) => {
              if (data.data.status == 0) {
                this.reload();
                this.Sdialog = false;
                this.$message({
                  message: data.data.message,
                  type: "success",
                });
              } else {
                this.$message({
                  message: data.data.message,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 编辑群组
    editGrounp() {
      this.$refs.editgroup.openAddUser();
    },
    /**
     * 搜索中间转接
     */
    search() {
      this.page = 1;
      this.userList();
    },
    // 获取巡护下群组和所有用户
    userList() {
      if (this.showOne) {
        let id = this.unitID.id === undefined ? "" : this.unitID.id;
        if (id === "") {
          return;
        }
        this.$apis
          .unituserList(
            {
              page: this.page,
              unit_id: id,
              keyword: this.keywordAll,
              per_page: 8,
            },
            this.token
          )
          .then((data) => {
            this.userterminalList = data.data.data.data;
            this.unitNum = this.userterminalList.length;
            this.unitInfo = this.unitID;
            this.unitname = this.unitInfo.unit_name;
            this.unitcode = this.unitInfo.unit_code;
            this.total = data.data.data.total;
          })
          .catch((err) => {
            console.error(err);
          });
        return;
      }
      this.$apis
        .groupuserList(
          {
            group_id: this.groupId,
            keyword: this.keywordAll,
          },
          this.token
        )
        .then((data) => {
          this.groupUserList = data.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    titBtn(param) {
      this.page = 0;

      this.unitID = param;
      this.groupSearch(param);
      this.userList();
    },
    // 设置超管
    setUser(param) {
      this.flag = !this.flag;
      this.userform = param;
      setTimeout(() => {
        this.$refs.reference.open();
      }, 0);
    },
    // 删除巡护成员
    delUnitUser(item) {
      this.unitUser = item;
      setTimeout(() => {
        this.$refs.delunituser.openDelEdit();
      }, 0);
    },
    // 删除群组成员
    delUnit(item) {
      this.groupUser = item;
      setTimeout(() => {
        this.$refs.deluser.openDelEdit();
      }, 0);
    },
    // 查看群组成员信息
    details(item) {
      this.detailObj = item;
      setTimeout(() => {
        this.$refs.detail.open();
      }, 0);
    },
    // 巡护更多操作
    iconBtn(param) {
      this.grounpId = param;
      // 阻止事件冒泡
      if (event && event.stopPropagation) {
        event.stopPropagation(); // 非IE浏览器
        this.Fdialog = true;
      } else {
        event.cancelBubble = true; //IE浏览器
      }
    },
    load() {
      //滑到底部时进行加载
      this.loading = true;
      setTimeout(() => {
        this.page += 1; //页数+1
        this.unitsList(); //调用接口，此时页数+1，查询下一页数据
      }, 1000);
    },
    // 群组更多操作
    SonBtn(item) {
      this.grounpInfo = item;
      this.Sdialog = true;
    },
    // 获取用户相关数量
    getRelatedCount() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$apis
        .getUserRelatedCount({}, this.token)
        .then((data) => {
          this.userRelated = data.data.data;
          loading.close();
        })
        .catch((err) => {
          loading.close();
          console.error(err);
        });
    },
    // 巡护列表
    unitsList() {
      let unitCode = this.user.is_unit;
      if (this.user.is_admin == 0 && this.user.is_unit == 1) {
        unitCode = this.user.is_unit;
      } else {
        unitCode = null;
      }
      this.$apis
        .unitList(
          { per_page: 100000000, keyword: this.keyword },
          this.token,
          "?page=" + this.page,
          unitCode
        )
        .then((data) => {
          this.unitData = data.data.data.data;
          this.totalPages = data.data.data.last_page;
          this.loading = false;
          const param = data.data.data.data.id;

          this.unitData.forEach((e, index) => {
            if (e.unit_code === this.user.user_unit.unit_code) {
              this.getItem(e);
              this.titBtn(e);
            }
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 群组列表
    groupSearch(param) {
      this.$apis
        .grouplist(
          {
            channel: "web",
            unit_id: param.id,
            page: 1,
          },
          this.token
        )
        .then((data) => {
          this.groupData = data.data.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 新建用户
    newUser() {
      this.$refs.adduser.openAddUser();
    },
    // 新建任务群组
    newTask() {
      this.Fdialog = false;
      this.$refs.newtask.openAddUser();
    },
    // 添加群组成员
    addgroupUser() {
      this.$refs.paltform.open();
    },
    getGroupUser() {},
    // 分页
    handleNext() {
      this.page++;
      this.userList(this.page, this.per_page);
    },
    handlePrev() {
      this.page--;
      this.userList(this.page, this.per_page);
    },
    currentchange(page) {
      this.page = page;
      this.userList(this.page, this.per_page);
    },
    // 获取群组下成员
    addgroupUserBtn(item) {
      this.groupId = item.id;
      this.dataid = item.user_id;
      this.groupname = item.name;
      this.item = item;
      this.$apis
        .groupuserList(
          {
            group_id: this.groupId,
          },
          this.token
        )
        .then((data) => {
          this.groupUserList = data.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
      // 阻止事件冒泡
      if (event && event.stopPropagation) {
        event.stopPropagation(); // 非IE浏览器
        this.showOne = false;
        this.groupIdList = item;
      } else {
        event.cancelBubble = true; //IE浏览器
      }
    },
    // 导出文件
    exportFile() {
      let id = this.unitID.id === undefined ? "" : this.unitID.id;
      this.$apis
        .exportUnitUser(
          { unit_id: id, unit_name: this.unitname, unit_code: this.unitcode },
          this.token
        )
        .then((data) => {
          exportFile(data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  created() {
    this.getRelatedCount();
    this.unitsList();
  },
  computed: {
    ...mapState(["user", "token", "role"]),
  },
};
</script>

<style lang="less" scoped>
.block {
  position: absolute;
  bottom: 70px;
  margin-left: 10px;
  width: 70%;
  display: flex;
  justify-content: space-between
}
/deep/.el-table .cell {
  display: flex;
  justify-content: center;
  align-items: center;
}
.userhead {
  width: 32px;
  height: 32px;
  margin-right: 5px;
}
.icon-VIP {
  font-size: 14px;
  color: #1182fb;
  margin-right: 10px;
  cursor: pointer;
}
.icon-groupUser {
  font-size: 16px;
  color: #3c4353;
  margin-right: 10px;
  cursor: pointer;
}
.icon-member {
  margin-right: 29px;
}
.icon-pre {
  font-size: 14px;
  color: #faad14;
  margin-right: 10px;
  cursor: pointer;
}
.feimabtn {
  font-size: 14px;
  margin-left: 1%;
  color: #ff5c5e;
}
.search {
  cursor: pointer;
  margin-top: 14px;
  display: block;
}
.searchAll {
  cursor: pointer;
  margin-top: 5px;
  display: block;
  position: relative;
  top: 20%;
}
.line {
  width: 100%;
  float: left;
}
.arrowTransform {
  width: 12px;
  height: 12px;
  transition: 0.2s;
  transform-origin: center;
  transform: rotateZ(-90deg);
  cursor: pointer;
}
.arrowTransformReturn {
  width: 12px;
  height: 12px;
  transition: 0.2s;
  transform-origin: center;
  transform: rotateZ(0deg);
  cursor: pointer;
}
.usernum {
  float: left;
}
.usersNames {
  float: left;
}
.username {
  font-size: 14px;
  float: left;
  width: 100px;
}
.identity {
  float: left;
  font-size: 14px;
}
.yesNo {
  float: left;
  font-size: 14px;
  cursor: pointer;
  .arrow {
    color: #595959;
  }
}
.binding {
  float: left;
  font-size: 14px;
}
.footerBtn {
  float: left;
}
.loading {
  text-align: center;
  font-size: 16px;
}
.noMore {
  text-align: center;
  font-size: 16px;
  color: #ccc;
  margin-top: 15px;
}
.unitlist {
  overflow-y: auto;
  height: 75%;
}
@import url("../../assets/iconfont/iconfont.css");
/deep/.el-collapse-item__header {
  background-color: transparent;
}
/deep/.el-collapse-item__wrap {
  background-color: transparent;
}
/deep/.el-collapse-item__header.is-active {
  background-color: #dde2ee;
}
/deep/.el-collapse-item__arrow {
  margin-left: 5%;
}
.footBar {
  position: absolute;
  bottom: 2%;
  left: 3%;
  font-size: 14px;
  color: #606266;
}
.fmwordBtn {
  margin-top: -10px;
}
.operationBtn {
  width: 22%;
  height: 100%;
  background-color: #ebecef;
}
.collapse-title {
  flex: 1 0 90%;
  order: 1;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: rgba(60, 67, 83, 1);
}
.unitName {
  width: 180px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: left;
  margin: 0;
}
.el-collapse-item__header {
  flex: 1 0 auto;
  order: -1;
}
.more:hover {
  background-color: #cfd3de;
}
.more {
  width: 60px;
  height: 47px;
  font-size: 22px;
  text-align: center;
  float: right;
}
.photos:hover {
  background-color: #dde2ee;
}
.photos {
  height: 47px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: rgba(60, 67, 83, 1);
  display: flex;
  justify-content: space-between;
  line-height: 50px;
  cursor: pointer;
  .photoname {
    margin-left: 20%;
  }
}
/deep/.el-dialog {
  margin-top: 30vh;
}
/deep/.el-collapse {
  width: 96%;
  height: 92%;
}
/deep/.el-input--medium .el-input__inner {
  width: 95%;
  margin-left: 8px;
  margin-top: 10px;
}
/deep/.el-pagination__editor.el-input .el-input__inner {
  width: 40px;
  margin-left: 0;
  margin-top: 0;
}
/deep/.el-input__suffix {
  margin-right: 15px;
  margin-top: 5px;
}
.titleInfor {
  text-align: center;
  .code {
    margin-left: 30px;
  }
}
.codeList {
  margin-top: 50px;
}
.tableTit {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
}
.feimaTit {
  color: #838a9d;
  font-size: 16px;
  margin-left: 50px;
}
.titname {
  font-size: 14px;
  color: #606266;
}
.tabList {
  width: 100%;
  height: calc(100vh - 440px);
  margin-top: 2%;
  .tables {
    background-color: #dde6ef;
    height: 56px;
    line-height: 56px;
  }
  .unitNav {
    height: 100%;
    overflow-y: auto;
  }
}
.userline {
  height: 48px;
  width: 100%;
  line-height: 48px;
  display: flex;
  justify-content: space-evenly;
}
.userAlian {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #1182fb;
  font-size: 26px;
  color: #ffffff;
}
.alliInput {
  width: 300px;
}
.tableData {
  border-collapse: collapse;
  .tabTitle {
    height: 56px;
  }
}
.tableFile {
  height: 30px;
}
.tableTitle {
  height: 30px;
}

table tr:nth-child(n + 2) {
  background-color: #f6f6f6;
}

table tr:nth-child(even) {
  background: #fff;
}
.allianceNav {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  padding: 0px 30px;
  .allianceBody {
    width: 100%;
    height: calc(100vh - 150px);
    background-color: #ffffff;
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    .navBody {
      width: 78%;
      height: 100%;
      padding: 0 10px;
      float: right;
      .topNav {
        height: 50px;
        display: flex;
        justify-content: space-between;
        .leftBtn {
          margin-top: 50px;
        }
        .rightInp {
          margin-top: 50px;
        }
      }
      .sider {
        margin-top: 80px;
      }
      .midlleNav {
        margin-top: 20px;
      }
      .footNav {
        // position: absolute;
        // bottom: 100px;
        // right: 80px;
        .numalliance {
          // float: right;
          color: #606266;
          font-size: 14px;
          margin: 4px;
        }
      }
    }
  }
}
@media screen and (min-width: 1670px) and (max-width: 1708px) {
  .username {
    font-size: 14px;
    width: 100px;
  }
  .usersNames {
    float: left;
    margin-left: 9%;
  }
  .usernum {
    margin-left: 2%;
    float: left;
  }
  .identity {
    float: left;
    font-size: 14px;
    margin-left: 7%;
  }
  .yesNo {
    float: left;
    font-size: 14px;
    margin-left: 4%;
    cursor: pointer;
  }
  .binding {
    float: left;
    font-size: 14px;
    margin-left: 13%;
  }
  .footerBtn {
    margin-left: 9%;
    float: left;
    width: 100px;
  }
  .tabList {
    height: calc(100vh - 440px);
  }
}
@media only screen and (max-width: 1670px) {
  .unitlist {
    overflow-y: auto;
    height: 65%;
  }
  .noMore {
    text-align: center;
    font-size: 12px;
    color: #ccc;
    margin-top: 15px;
  }
  .allianceNav {
    width: 100%;
    height: 100%;
    background-color: #f8f8ff;
    padding: 0px 30px;
    .allianceBody {
      width: 100%;
      height: calc(100vh - 150px);
      background-color: #ffffff;
      box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      .navBody {
        width: 78%;
        height: 100%;
        padding: 0 2%;
        float: right;
        .topNav {
          height: 50px;
          display: flex;
          justify-content: space-between;
          .leftBtn {
            margin-top: 20px;
          }
          .rightInp {
            margin-top: 10px;
          }
        }
        .sider {
          margin-top: 30px;
        }
        .midlleNav {
          margin-top: 20px;
        }
        .footNav {
          // position: absolute;
          // bottom: 100px;
          // right: 80px;
        }
      }
    }
  }
  .collapse-title {
    display: block;
    font-size: 13px;
    font-family: MicrosoftYaHei;
    color: rgba(60, 67, 83, 1);
  }
  .more:hover {
    background-color: #cfd3de;
  }
  .more {
    width: 60px;
    height: 47px;
    font-size: 16px;
    text-align: center;
  }
  .photos:hover {
    background-color: #dde2ee;
  }
  .photos {
    height: 47px;
    font-size: 13px;
    font-family: MicrosoftYaHei;
    color: rgba(60, 67, 83, 1);
    display: flex;
    justify-content: space-between;
    line-height: 50px;
    cursor: pointer;
    .photoname {
      margin-left: 20%;
    }
  }
  .tableTit {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.65);
  }
  .number {
    font-size: 14px;
  }
  .username {
    font-size: 14px;
    width: 100px;
  }
  .usersNames {
    float: left;
    margin-left: 9%;
  }
  .usernum {
    margin-left: 2%;
    float: left;
  }
  .identity {
    float: left;
    font-size: 14px;
    margin-left: 1%;
  }
  .yesNo {
    float: left;
    font-size: 14px;
    margin-left: 4%;
    cursor: pointer;
  }
  .binding {
    float: left;
    font-size: 14px;
    margin-left: 13%;
  }
  .footerBtn {
    margin-left: 9%;
    float: left;
    width: 100px;
  }
  .footNav {
    // position: absolute;
    // bottom: 12% !important;
    // right: 9% !important;
  }
  .fmwordBtn {
    margin-top: -20px;
  }
  .delserve {
    margin-bottom: 4px;
  }
  .footBar {
    position: absolute;
    bottom: 12%;
    left: 8%;
    font-size: 12px;
    color: rgba(60, 67, 83, 1);
  }
  /deep/.el-input--medium .el-input__inner {
    width: 95%;
    margin-left: 5px;
    margin-top: 10px;
  }
  .unitName {
    width: 120px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    float: left;
    margin: 0;
  }
}

.jurisdiction-list {
  div {
    width: 100%;
    height: 32px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
  }

  .jurisdiction-list-div {
    background: #dde5ee !important;
  }
}
</style>
