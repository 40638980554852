<template>
  <div v-if="downloadLength !== 0" class="mask-layer">
    <div></div>
    <div class="iconfont">&#xe644;</div>
    <div>当前{{ downloadLength }}个文件正在下载中，请稍后</div>
  </div>
</template>

<script>
/**
 * 下载文件加载组件
 */
import { mapState, mapMutations } from "vuex";
export default {
  name: "downloadLoad",
  props: {
    downloadLength: {
      type: Number,
      data: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["currentUrl", "role"]),
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
  },
};
</script>

<style lang="scss" scoped>
.mask-layer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.7;
  z-index: 99999999;
  div:nth-child(1) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    z-index: 999999999;
  }
  div:nth-child(2) {
    position: fixed;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999999999;
    color: #409eff;
    font-size: 18px;
    animation-name: turnZ;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  div:nth-child(3) {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999999999;
    color: #409eff;
  }

  @keyframes turnZ {
    0% {
      transform: translate(-50%, -50%) rotateZ(0deg);
    }
    25% {
      transform: translate(-50%, -50%) rotateZ(90deg);
    }
    50% {
      transform: translate(-50%, -50%) rotateZ(180deg);
    }
    75% {
      transform: translate(-50%, -50%) rotateZ(270deg);
    }
    100% {
      transform: translate(-50%, -50%) rotateZ(360deg);
    }
  }
}
</style>
