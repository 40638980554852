var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "100%", height: "100%" } }, [
    _vm.runEnv === "backend"
      ? _c(
          "span",
          { staticStyle: { width: "100%", height: "100%" } },
          [_c("newHome")],
          1
        )
      : _c(
          "span",
          [
            _c("div", { staticClass: "LoginHome" }, [
              _c("div", { staticClass: "oneBar" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.leftTopStatus,
                        expression: "leftTopStatus",
                      },
                    ],
                    staticClass: "echartsOne",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "titleWBar" },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("任务下达"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "details",
                            attrs: { type: "text" },
                            on: { click: _vm.jumpGmTask },
                          },
                          [_vm._v("详情")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "flyNum" }, [
                      _c("div", { staticClass: "leftBar" }, [
                        _c("img", {
                          staticClass: "iconImg",
                          attrs: {
                            src: require("../../assets/img/homeImg/right.png"),
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "countleft" }, [
                          _vm._v(
                            _vm._s(_vm.homeDataList.report_home_today_pushed)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "danwei" }, [_vm._v("个")]),
                        _vm._v(" "),
                        _c("p", { staticClass: "describe" }, [
                          _vm._v("今日已推送任务"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "rightBar" }, [
                        _c("img", {
                          staticClass: "iconImg",
                          attrs: {
                            src: require("../../assets/img/homeImg/left.png"),
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "countleft" }, [
                          _vm._v(
                            _vm._s(
                              _vm.homeDataList.report_home_today_not_pushed
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "danwei" }, [_vm._v("个")]),
                        _vm._v(" "),
                        _c("p", { staticClass: "describe" }, [
                          _vm._v("今日未推送任务"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pushCount" }, [
                      _c("div", { staticClass: "topPush" }, [
                        _c("span", { staticClass: "pushtask" }, [
                          _vm._v("历史已推送任务"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "progressBar" },
                          [
                            _c("el-progress", {
                              staticClass: "progress",
                              attrs: {
                                percentage: _vm.historyPush,
                                color: _vm._f("taskProgressColor")(
                                  _vm.historyPush
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "botPush" }, [
                        _c("span", { staticClass: "pushtask" }, [
                          _vm._v("历史未推送任务"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "progressBar" },
                          [
                            _c("el-progress", {
                              staticClass: "progress",
                              attrs: {
                                percentage: _vm.historyNotPush,
                                color: _vm._f("taskProgressColor")(
                                  _vm.historyNotPush
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "botImg" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "footBtn" },
                      [
                        _c(
                          "el-dropdown",
                          {
                            staticClass: "newTask",
                            attrs: { trigger: "click", size: "medium" },
                            on: { command: _vm.feimaTask },
                          },
                          [
                            _c(
                              "el-button",
                              { attrs: { type: "primary", round: "" } },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c("div", { staticClass: "but-left" }, [
                                      _vm._v("新建任务"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "but-right" }, [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right",
                                      }),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  { attrs: { command: "feima" } },
                                  [_vm._v("新建航线任务")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-dropdown-item",
                                  { attrs: { command: "currency" } },
                                  [_vm._v("新建通用任务")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "footTit",
                            on: { click: _vm.publicSentiment },
                          },
                          [_vm._v("舆情发布")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "imgBtnOne", on: { click: _vm.leftTop } },
                  [
                    _c("img", {
                      staticClass: "iconImg",
                      attrs: { src: _vm.leftFoldTop, alt: "" },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "twoBar" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.leftFootStatus,
                        expression: "leftFootStatus",
                      },
                    ],
                    staticClass: "echartsTwo",
                  },
                  [
                    _c("div", { staticClass: "titleWBar second" }, [
                      _vm.pointIndex === 1
                        ? _c("span", { staticClass: "title" }, [
                            _vm._v("飞行面积"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pointIndex === 2
                        ? _c("span", { staticClass: "title" }, [
                            _vm._v("飞行里程"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pointIndex === 3
                        ? _c("span", { staticClass: "title" }, [
                            _vm._v("飞行架次"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pointIndex === 4
                        ? _c("span", { staticClass: "title" }, [
                            _vm._v("飞行时长"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "title",
                          staticStyle: { color: "#dde2ee" },
                        },
                        [_vm._v("全部无人机")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "point" }, [
                        _c("div", {
                          style: { opacity: _vm.pointIndex === 1 ? 1 : 0.4 },
                          attrs: { title: "飞行面积" },
                          on: {
                            click: function ($event) {
                              return _vm.repeatReport(1)
                            },
                            mouseenter: function ($event) {
                              return _vm.repeatReport(1)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", {
                          style: { opacity: _vm.pointIndex === 2 ? 1 : 0.4 },
                          attrs: { title: "飞行里程" },
                          on: {
                            click: function ($event) {
                              return _vm.repeatReport(2)
                            },
                            mouseenter: function ($event) {
                              return _vm.repeatReport(2)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", {
                          style: { opacity: _vm.pointIndex === 3 ? 1 : 0.4 },
                          attrs: { title: "飞行架次" },
                          on: {
                            click: function ($event) {
                              return _vm.repeatReport(3)
                            },
                            mouseenter: function ($event) {
                              return _vm.repeatReport(3)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", {
                          style: { opacity: _vm.pointIndex === 4 ? 1 : 0.4 },
                          attrs: { title: "飞行时长" },
                          on: {
                            click: function ($event) {
                              return _vm.repeatReport(4)
                            },
                            mouseenter: function ($event) {
                              return _vm.repeatReport(4)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { attrs: { id: "monthTaskPushChart" } }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "imgBtnTwo", on: { click: _vm.leftFoot } },
                  [
                    _c("img", {
                      staticClass: "iconImg",
                      attrs: { src: _vm.leftFoldFoot, alt: "" },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "thrBar" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.rightTopStatus,
                        expression: "rightTopStatus",
                      },
                    ],
                    staticClass: "echartsThr",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "titleWBar" },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("用户管理"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "details",
                            attrs: { type: "text" },
                            on: { click: _vm.jumpTerminalUser },
                          },
                          [_vm._v("详情")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { attrs: { id: "userManagement" } }),
                    _vm._v(" "),
                    _c("div", { staticClass: "leftNav" }, [
                      _c("img", {
                        staticClass: "userImg",
                        attrs: {
                          src: require("../../assets/img/home／union.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "statistics" }, [
                        _vm._v(_vm._s(_vm.homeDataList.report_home_unit_qty)),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "individual" }, [_vm._v("个")]),
                      _vm._v(" "),
                      _c("p", { staticClass: "already" }, [
                        _vm._v("授权巡护单位"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "rightNav" }, [
                      _c("img", {
                        staticClass: "userImg",
                        attrs: {
                          src: require("../../assets/img/home／terminal.png"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "statistics" }, [
                        _vm._v(_vm._s(_vm.terminalOnlineCount)),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "individual" }, [_vm._v("个")]),
                      _vm._v(" "),
                      _c("p", { staticClass: "already" }, [
                        _vm._v("当前在线用户"),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "imgBtnThr", on: { click: _vm.rightTop } },
                  [
                    _c("img", {
                      staticClass: "iconImg",
                      attrs: { src: _vm.rightFoldtop, alt: "" },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "fourBar" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.rightFootStatus,
                        expression: "rightFootStatus",
                      },
                    ],
                    staticClass: "echartsFour",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "titleWBar" },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("应急装备"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "details",
                            attrs: { type: "text" },
                            on: { click: _vm.jumpUav },
                          },
                          [_vm._v("详情")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "leftbar" }, [
                      _c("div", { staticClass: "topNav every" }, [
                        _c("img", {
                          staticClass: "userImg",
                          attrs: {
                            src: require("../../assets/img/home／equipment／feima.png"),
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "statistics" }, [
                          _vm._v(
                            _vm._s(_vm.homeDataList.report_home_feima_plane)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "already" }, [
                          _vm._v("注册飞马无人机"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "midNav every" }, [
                        _c("img", {
                          staticClass: "userImg",
                          attrs: {
                            src: require("../../assets/img/home／equipment／general.png"),
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "statistics" }, [
                          _vm._v(
                            _vm._s(_vm.homeDataList.report_home_other_plane)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "already" }, [
                          _vm._v("注册其他无人机"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "footNav every" }, [
                        _c("img", {
                          staticClass: "userImg",
                          attrs: {
                            src: require("../../assets/img/home／equipment／terminal.png"),
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "statistics" }, [
                          _vm._v(_vm._s(_vm.homeDataList.report_home_terminal)),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "already" }, [
                          _vm._v("注册林业巡护"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._m(0),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "imgBtnFour", on: { click: _vm.rightFoot } },
                  [
                    _c("img", {
                      staticClass: "iconImg",
                      attrs: { src: _vm.rightFoldFoot, alt: "" },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "five" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.rightStatus,
                        expression: "rightStatus",
                      },
                    ],
                    staticClass: "echartsFive",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "titleWBar" },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("最新任务条目"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "details",
                            attrs: { type: "text" },
                            on: { click: _vm.jumpNewTaskline },
                          },
                          [_vm._v("详情")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "bodyBar" },
                      _vm._l(_vm.taskList, function (item, index) {
                        return _c(
                          "el-tooltip",
                          {
                            key: index,
                            attrs: {
                              content:
                                item.project_name +
                                "-" +
                                item.block_name +
                                "-" +
                                item.task_name,
                              placement: "bottom",
                              effect: "light",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "taskLine",
                                on: {
                                  click: function ($event) {
                                    return _vm.jumpTask(item)
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "taskName" }, [
                                  _vm._v(_vm._s(item.task_name)),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "taskTime" }, [
                                  _vm._v(_vm._s(item.created_at)),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "imgBtnFive", on: { click: _vm.rightMid } },
                  [
                    _c("img", {
                      staticClass: "iconImg",
                      attrs: { src: _vm.rightFoldMid, alt: "" },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "monitor-type-list",
                attrs: { title: "飞行脚印" },
                on: {
                  click: function ($event) {
                    return _vm.changeMonitorType("list")
                  },
                },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "monitor-type-point",
                attrs: { title: "设备脚印" },
                on: {
                  click: function ($event) {
                    return _vm.changeMonitorType("point")
                  },
                },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "task",
                attrs: { title: "数据回传" },
                on: { click: _vm.dataReturn },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "video", on: { click: _vm.chat } }, [
                _vm.chatDialog.unread_num > 99
                  ? _c("div", { staticClass: "unread" }, [_vm._v("···")])
                  : _vm.chatDialog.unread_num > 0
                  ? _c("div", { staticClass: "unread" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.chatDialog.unread_num) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("publicSen", { ref: "public" }),
            _vm._v(" "),
            _c("newFmTask", { ref: "newFmTask" }),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rightBar" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/img/home／equipment／pic1.png"),
          alt: "",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "ritImg",
        attrs: {
          src: require("../../assets/img/home／equipment／pic2.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }