<template>
  <div class="dioContent">
    <div class="information1">
      <div class="number">
        <div class="setSup" v-if="statsTexts && statsTexts.sup">
          {{statsTexts.sup}}
        </div>
        <div class="setTitle">
          {{statsTexts.title}}
        </div>
        <div class="setData">
          {{statsTexts.data}}
        </div>
        <div class="setUnit">
          {{statsTexts.unit}}
        </div>
      </div>
      <div id="chart" class="setChart"></div>
    </div>
    <div class="moreDatas">
      <div v-for="(val, key, index) in data" :key="key" class="datas">
        <div class="title"><span class="sign" :style="{backgroundColor:color[index]}"></span>{{ key }}</div>
        <div class="data" :title="val">{{ val }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import echarts from "echarts";
export default {
  props: {
    datas: {
      type: Object,
      default: ()=> {
        return  {
          statsTexts:{
            title: '总飞行面积',
            data: '0',
            unit: '平方公里'
          },
          data: {
          },
        }
      }
    }
  },
  data() {
    return {
      statsTexts: {
        title: '总飞行面积',
        data: '0',
        unit: '平方公里'
      },
      data: {
        彩虹: 0,
        南宁慧视: 0,
        飞马机器人: 0,
        成都纵横: 0,
        思洛普: 0,
        南方测绘: 0,
        大疆: 0,
        华测导航: 0,
        千寻位置: 0,
      },
      highLightIndex: 0,
      color: [
        "#BE20F4",
        "#82FF25",
        "#CD2727",
        "#24C7D3",
        "#FF6500",
        "#007BFF",
        "#F9CB1F",
        "#6618FF",
        "#4B00FF",
        "#BE20F4",
        "#82FF25",
        "#CD2727",
        "#24C7D3",
        "#FF6500",
        "#007BFF",
        "#F9CB1F",
        "#6618FF",
        "#4B00FF",],
    };
  },
  watch: {
    datas: {
      handler: 'initDatas',
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.initDatas();
  },
  methods: {
    initDatas(val) {
      if(!val) return
      this.data = this.datas.data;
      this.statsTexts = this.datas.statsTexts;
      if (document.getElementById("chart")){
        this.setEcahart()
      }else {
        setTimeout(()=>{
          this.initDatas(val)
        },500)
      }
      // this.setEcahart();
    },
    setEcahart() {
      let chartDom = document.getElementById("chart");
      let myChart = echarts.init(chartDom);
      let option;
      // echarts需要绑定的data数据
      let list = []
      for(let key in this.data) {
        list.push({ value: this.data[key], name: key})
      }

      option = {
        tooltip: {
          alwaysShowContent: true,
          backgroundColor: "#0C2039",
          formatter: function (params) {
            // console.log(params);
            let data = params.data;
            let str =
              '<div class="echartDatas" style="width: 55px;text-align: center;"><div class="per" style="color: #5bfa59;font-size: 12px;">' +
              params.percent.toFixed(0) + '%' +
              '</div><div class="total" style="font-size: 28px;font-family: "DIN";font-weight: bold;color: #ffffff;">' +
              data.value +
              "</div></div>";
            return str;
          },
          position: ["32%", "35%"],
        },
        color: this.color,
        legend: {
          top: "5%",
          left: "center",
          show: false,
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["45%", "68.4%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: list,
          },
        ],
      };

      option && myChart.setOption(option);

      myChart.dispatchAction({
        type: "highlight",
        seriesIndex: this.highLightIndex,
        dataIndex: this.highLightIndex
      });
      myChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: 0,
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.dioContent {
  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 2px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #3f597e;
  }

  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: transparent;
  }
  * {
    scrollbar-color: #000 #6d6d83; /* 滑块颜色  滚动条背景颜色 */
    scrollbar-width: thin; /* 滚动条宽度有三种：thin、auto、none */
  }
  .information1 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .number {
      height: 131px;
      width: 102px;
      background: url("~@/assets/img/centerHome/border.png") no-repeat center;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      font-size: 12px;
      font-family: "DIN";
        color: #7893B9;
      .setSup {
        margin-bottom: -15px;
        margin-top: 15px;
      }
      .setTitle {
        margin-top: 15px;
      }
      .setData {
        font-size: 28px;
        color: #FFCB51;
        font-weight: bold;
        font-family: "DIN";
      }
      .setUnit {
        margin-bottom: 15px;
      }
    }
    .setChart {
      width: 190px;
      height: 190px;
      background: url("~@/assets/img/centerHome/1234.png") no-repeat center;
      background-size: 100% 100%;
    }
  }
  .moreDatas {
    display: flex;
    flex-wrap: wrap;
    max-height: 180px;
    overflow: auto;
    margin-top: 55px;
  }
  .datas {
    display: flex;
    font-size: 14px;
    line-height: 31px;
    .title {
      width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #7893b9;
      .sign {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 4px;
      }
    }
    .data {
      width: 50px;
      margin-right: 13px;
      font-family: "DIN";
      font-weight: bold;
      color: #ffffff;
    }
  }
}
.echartDatas {
  width: 55px;
  text-align: center;
  .per {
    color: #5bfa59;
    font-size: 12px;
  }
  .total {
    font-size: 28px;
    font-family: "DIN";
    font-weight: bold;
    color: #ffffff;
  }
}
</style>
