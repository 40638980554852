<template>
  <!-- 权限管理 -->
  <div class="privile">
    <!-- 具体内容 -->
    <div class="privileBody">
      <div class="cen">
        <div class="cen-left">
          <navTop />
          <div class="taxon">
            <div
              v-for="(item, index) in userList"
              :key="item.name"
              :class="{ super: userListIndex == userList.length - index }"
              @click="roleList(item.id)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="cen-right">
          <div class="cen-right-elMenu">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo configuration-navMenu"
              mode="horizontal"
              @select="handleSelect"
            >
              <el-menu-item index="1">权限设置</el-menu-item>
              <el-menu-item index="2">角色设置</el-menu-item>
            </el-menu>
          </div>
          <div v-if="activeIndex === '2'" class="search">
            <div class="search-input">
              <span>角色ID</span>
              <el-input
                v-model="roleSeekId"
                class="import scale"
                placeholder="请输入"
                clearable
              />
            </div>
            <div class="search-input">
              <span>角色名称</span>
              <el-input
                v-model="roleSeekName"
                class="import scale"
                placeholder="请输入"
                clearable
              />
            </div>

            <button class="newRole inquire btn" type="default" @click="newRole">
              新建角色
            </button>

            <button class="inquire btn" type="default" @click="inquire">
              查询
            </button>
            <button class="reset btn" type="default" @click="reset">
              重置
            </button>
          </div>

          <div v-if="activeIndex === '1'" class="cen-right-top">
            <el-checkbox v-model="checkAll" @change="allChange"
              >全部选择</el-checkbox
            >
          </div>
          <div
            v-if="checkedCitiesArr != [] && activeIndex === '1'"
            class="cen-right-body"
          >
            <div
              v-for="(item, index) in checkedCitiesArr"
              :key="item.name"
              class="option"
            >
              <el-checkbox
                v-model="checkAllArr[index]"
                @change="allListChange(item.name, index)"
                >{{ item.name }}</el-checkbox
              >
              <div class="division" />
              <el-checkbox-group v-model="permissionsArr[index]">
                <el-checkbox
                  v-for="(city, num) in item.list"
                  :key="num"
                  :label="city"
                  style="margin: 10px  4%; 0 0"
                  @change="GroupChange(checkedCitiesArr[index], index, num)"
                  >{{ city.name }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="btn" @click="preserve">保存</div>
          </div>
        </div>
        <div
          style="margin-top: 15px; width: 76%"
          class="cen-right"
          v-if="activeIndex === '2'"
        >
          <el-table
            :data="roleDataArr"
            style="width: 100%"
            :height="userListIndex === 2 ? ' 500px' : ' 600px'"
          >
            <el-table-column
              prop="id"
              label="角色ID"
              width="200px"
              align="center"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.id !== ''">
                  {{ scope.row.id }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="角色名称" align="center">
              <!-- v-model="editBoxData" -->
              <template slot-scope="scope">
                <input
                  class="nameState"
                  type="text"
                  :placeholder="scope.row.name"
                  :id="'id' + scope.row.id"
                  :ref="'ref' + scope.row.id"
                  @input="inputJudge(scope)"
                  @blur="cursorDisappears(scope)"
                />
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200px">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.id !== ''"
                  size="mini"
                  @click="configurationAccess(scope)"
                  >权限配置</el-button
                >
                <el-button
                  v-if="scope.row.id !== ''"
                  size="mini"
                  type="danger"
                  @click="rolechildDel(scope.row.id)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <subcharacters
      :obj="subcharactersObj"
      @shut="shut"
      v-if="subcharactersShow"
    >
    </subcharacters>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
let editBoxData = ""; //  input 输入框的内容
export default {
  data() {
    return {
      checkAll: false, //  全部按钮全选绑定
      checkedCitiesArr: [], //  服务区返回的权限数组
      permissionsArr: [], //  用户点击选择的权限数组
      checkAllArr: [], //  全选按钮数组
      userList: "", //  用户列表
      userListIndex: 1, //  用户点击的那个
      activeIndex: "1",
      roleId: "", //   角色id
      roleName: "", //  角色名称
      roleDataArr: [],
      roleSeekId: "", //  搜索的角色id
      roleSeekName: "", //  搜索的角色id
      editBoxKey: "", //  当前编辑的角色
      subcharactersShow: false,
      subcharactersObj: {},
    };
  },

  computed: {
    ...mapState(["navMessage", "currentUrl", "user", "token"]),
  },
  created() {
    this.currentUrlStorage(["首页", "权限配置", "权限管理"]);
    this.navMessageStorage(8);
  },
  mounted() {
    this.roleList(3);
  },
  methods: {
    ...mapMutations(["currentUrlStorage", "navMessageStorage"]),
    /**
     * 顶部默认权限设置，角色设置切换
     */
    handleSelect(index) {
      this.activeIndex = index;
      this.roleList(this.userListIndex);
    },
    /**
     * input正则判断
     */
    inputJudge(scope) {
      this.editBoxKey = scope.row.id;
      let reg = /^[\u4E00-\u9FA5A-Za-z0-9]+$/;
      let inputStr = this.$refs["ref" + scope.row.id].value;
      if (!reg.test(inputStr[inputStr.length - 1])) {
        this.$message.error("仅支持输入中英文及数子!");
        this.$refs["ref" + scope.row.id].value = "";
      }
    },
    /**
     * input 光标的消失
     */
    cursorDisappears(scope) {
      let message = "确认添加";
      editBoxData = this.$refs["ref" + scope.row.id].value;
      if (editBoxData === "" || editBoxData === scope.row.name) {
        return;
      }
      let obj = {
        action: "insert",
        name: editBoxData,
      };
      if (scope.row.id !== "") {
        message = "确认修改?";
        obj.action = "update";
        obj.id = scope.row.id;
      }
      this.$confirm(message, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.editBoxKey = "";
          this.rolechildSave(obj);
        })
        .catch(() => {
          this.$refs["ref" + scope.row.id].value = "";
          editBoxData = "";
          this.editBoxKey = "";
          if (scope.row.id === "") {
            this.roleDataArr.pop();
          }
        });
    },
    /**
     * 新建角色
     */
    newRole() {
      let scope = {
        row: {
          id: "",
          name: "",
        },
      };
      this.roleDataArr.push(scope.row);
      setTimeout(() => {
        this.handleEdit(scope);
      }, 0);
    },
    /**
     * 查询
     */
    inquire() {
      this.roleList(this.userListIndex);
    },
    /**
     * 重置
     */
    reset() {
      this.roleSeekId = "";
      this.roleSeekName = "";
    },
    /**
     * 获取用户列表
     */
    roleList(index) {
      this.userListIndex = index;
      if (this.activeIndex === "1") {
        this.$apis
          .roleList({}, this.token)
          .then((data) => {
            this.userList = data.data.data;
            // this.userList.reverse();
            this.checkedCitiesArr = [];
            this.checkAllArr = [];
            this.permissionsArr = [];
            this.checkAll = false;
            this.permissionsGet(index);
          })
          .catch((err) => {
            console.error(err);
          });
      } else if (this.activeIndex === "2") {
        /**
         * 角色子列表
         */
        this.roleDataArr = [];
        this.$apis
          .rolechildList(
            {
              role_id: this.userListIndex,
              id: this.roleSeekId,
              name: this.roleSeekName,
            },
            this.token
          )
          .then((data) => {
            if (data.data.status === 0) {
              this.roleDataArr = data.data.data;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    /**
     * 保存子角色
     */
    rolechildSave(obj) {
      this.$apis
        .rolechildSave(
          {
            action: obj.action,
            role_id: this.userListIndex,
            name: obj.name,
            id: obj.id,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status === 0) {
            this.roleList(this.userListIndex);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 子角色权限配置
     */
    configurationAccess(scope) {
      this.subcharactersShow = true;
      this.subcharactersObj = scope.row;
    },
    /**
     * 关闭子角色权限配置
     */
    shut(e) {
      this.subcharactersShow = false;
    },
    /**
     * 删除子角色
     */
    rolechildDel(id) {
      this.$apis
        .rolechildDel(
          {
            id: id,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status === 0) {
            this.roleList(this.userListIndex);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 表格的编辑
     */
    handleEdit(scope) {
      this.editBoxKey = scope.row.id;
      editBoxData = scope.row.data;
      document.querySelector("#id" + scope.row.id).focus();
      setTimeout(() => {
        document.querySelector("#id" + scope.row.id).focus();
      }, 0);
      return;
    },
    /**
     * 获取权限数据
     */
    permissionsGet(index) {
      this.$apis
        .permissionsGet(
          { action: "parent", type: "api", role_id: index },
          this.token
        )
        .then((data) => {
          setTimeout(() => {
            this.checkedCitiesArr = data.data.data.system_permissions;
            const arr = JSON.parse(
              data.data.data.role_permissions_data.permissions
            );
            let lengthSum = this.checkedCitiesArr.length;
            for (let i = 0; i < this.checkedCitiesArr.length; i++) {
              this.permissionsArr.push([]);
              this.checkAllArr.push(false);
              const lengthNum = this.checkedCitiesArr[i].list.length;
              for (let j = 0; j < lengthNum; j++) {
                if (arr.indexOf(this.checkedCitiesArr[i].list[j].code) != -1) {
                  this.permissionsArr[i].push(this.checkedCitiesArr[i].list[j]);
                  if (this.permissionsArr[i].length == lengthNum) {
                    this.checkAllArr[i] = true;
                    if (i === lengthSum - 1) {
                      this.allJudge();
                    }
                  }
                }
              }
            }
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 总全选按钮状态判断
     */
    allJudge() {
      const lengthNum = this.checkAllArr.length;
      for (let i = 0; i < lengthNum; i++) {
        if (this.checkAllArr[i] == false) {
          this.checkAll = false;
          return false;
        }
      }
      this.checkAll = true;
    },
    /**
     * 全部按钮全选
     * @param {Object} val
     */
    allChange(val) {
      if (val) {
        const lengthNum = this.checkAllArr.length;
        for (let i = 0; i < lengthNum; i++) {
          this.checkAllArr[i] = true;
          this.permissionsArr[i] = this.checkedCitiesArr[i].list;
        }
      } else {
        const lengthNum = this.checkAllArr.length;
        for (let i = 0; i < lengthNum; i++) {
          this.checkAllArr[i] = false;
          this.permissionsArr[i] = [];
          this.checkAll = false;
        }
      }
    },
    /**
     * list全选
     * @param {Object} val
     */
    allListChange(val, index, num) {
      if (this.checkAllArr[index] != false) {
        this.checkAllArr[index] = true;
        this.permissionsArr[index] = this.checkedCitiesArr[index].list;
        this.allJudge();
      } else {
        this.checkAllArr[index] = false;
        this.permissionsArr[index] = [];
        this.checkAll = false;
      }
    },
    /**
     * 按钮状态管理
     * @param {Object} val
     */
    GroupChange(val, index, num) {
      if (val.list.length == this.permissionsArr[index].length) {
        this.checkAllArr[index] = true;
        this.allJudge();
      } else {
        this.checkAllArr[index] = false;
        this.checkAll = false;
      }
    },
    /**
     * 保存按钮
     */
    preserve() {
      const arr = [];
      const lengthNum = this.permissionsArr.length;
      for (let i = 0; i < lengthNum; i++) {
        const arrData = this.permissionsArr[i];
        for (let j = 0; j < arrData.length; j++) {
          arr.push(arrData[j].code);
        }
      }
      this.$apis
        .permissionsSave(
          {
            action: "parent",
            role_id: this.userListIndex,
            permissions: arr,
          },
          this.token
        )
        .then((data) => {
          if (data.data.message == "权限保存成功！") {
            this.utils.$message("权限保存成功！");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.privileBody {
  width: 100%;
  margin-top: -2.54%;
  padding-bottom: 37px;
  background-color: #f0f0f0;

  .cen {
    position: relative;
  }

  .cen-left {
    position: absolute;
    top: 0;
    left: 1.61%;
    width: 18.75%;
    height: 89.17%;
    .cen-left-top {
      height: 56px;
      line-height: 56px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);

      .topName {
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .taxon {
      width: 100%;
      height: 105%;
      min-height: 88vh;
      background: rgba(235, 236, 239, 1);
      margin-left: 9px;
      box-sizing: border-box;
      div {
        line-height: 64px;
        border-bottom: 1px solid #a4aab9;
        padding-left: 8.889%;
        font-size: 18px;
        cursor: pointer;
      }

      .super {
        background-color: #dde2ee;
      }
    }
  }

  .cen-right {
    width: 76%;
    height: 89.17%;
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    margin-left: 21.93%;
    padding: 0 4.8% 40px 2.73%;
    background-color: #fff;

    .cen-right-elMenu {
      width: 100%;
      padding-top: 42px;
      margin-top: 42px;
    }

    .search {
      display: flex;
      position: relative;
      .search-input {
        display: inline-block;
        width: 26%; // 332
        text-align: right;
        height: 146px;
        line-height: 146px;
        span {
          padding-right: 2%;
          color: #838a9d;
          font-size: 14px;
        }
        .import {
          display: inline-block;
          width: 55%;
          height: 40px;
        }
      }

      .btn {
        position: absolute;
        bottom: 0;
        width: 112px;
        height: 36px;
        line-height: 36px;
        border-radius: 2px;
        border: 0;
        font-size: 14px;
      }

      .inquire {
        color: #fff;
        background-color: #1182fb;
        right: 8vw;
      }
      .reset {
        right: 0;
        color: #1182fb;
        background-color: #fff;
        border: 1px solid #1182fb;
      }

      .newRole {
        top: -160px;
        right: 0;
      }
    }

    .cen-right-top {
      padding-bottom: 17px;
      border-bottom: 2px solid #dde2ef;
      margin-top: 40px;
    }

    .cen-right-body {
      margin-left: 2.34%;
    }

    .option {
      margin-top: 43px;
    }

    .division {
      margin: 22px 0;
      width: 100%;
      border-bottom: 2px solid #dde2ef;
    }

    .btn {
      width: 12.57%;
      height: 48px;
      line-height: 48px;
      background: #1182fb;
      border-radius: 2px;
      text-align: center;
      margin-top: 93px;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }
  }
}

.nameState {
  border: 0;
  text-align: center;
  line-height: 32px;
  height: 32px;
  color: #9e9e9e;
  box-sizing: border-box;
}
.nameState:focus {
  outline: none;
  border: 1px solid #1782fb !important;
}
</style>
