// fly的默认配置项

// import Fly from 'flyio/dist/npm/wx'
import Fly from 'flyio/dist/npm/fly'
import ElementUI from 'element-ui'
import router from '../router'
import store from '../store'
import utils from '../utils/public' // 任务公用方法
export const fly = new Fly()
export const baseURL = process.env.VUE_APP_API_HOST + '/api'
export const baseAirURL = process.env.VUE_APP_AIR
export const baseWeatherURL = process.env.VUE_APP_FEIMA_WEATHER_API + '/api'


export const whiteList = ['/task/postDataBack']  //  需要监控请求进度的白名单

export const start = (request) => {
  // 给所有请求添加自定义header
  request.headers['X-Tag'] = 'flyio'
  // 打印出请求体
  // str.slice(str.indexOf('/api/') + 4)
  // let httpUrl = request.url.slice(request.url.indexOf('/api/') + 4)
  // if (whiteList.indexOf(httpUrl) !== -1) {
  //   setTimeout(() => {
  //     fly.engine.upload.onprogress = (event) => {
  //       if (event.lengthComputable) {
  //         let percent = Math.floor(event.loaded / event.total * 100);
  //       }
  //     };
  //   }, 100);
  // }
}

export const deploy = (timeout, token) => {
  return {
    timeout: timeout,
    headers: {
      'Access-Control-Max-Age': '86400',
      'Content-Type': 'application/json',
      token: token
    },
    parseJson: true
  }
}

// 添加响应拦截器，响应拦截器会在then/catch处理之前执行
export const finishRes = (response) => {
  // 只将请求结果的data字段返回
  let fn = () => { }
  switch (response.data.status) {
    case 0:
      break
    case 1:
      let message = '请求失败'
      if (response.data.message) {
        message = response.data.message
      }
      utils.$message(message, 'error')
      break
    case 2:
      utils.$message('权限不足', 'error')
      break
    case 3:
      utils.$message('服务器组件运行异常', 'warning')
      break
    case 4:

      utils.$message('非法操作', 'warning')
      break
    case 999:

      fn = () => {
        ElementUI.MessageBox('登录信息已过期，请重新登录', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          showClose: false,
          type: 'warning'
        }).then(() => {
          router.push('/login')
        }).catch(() => {
          router.push('/login')
        })
      }
      store.commit('logOut')
      break
      // 导出时直接返回了流文件，无法判断状态，使用undefind判断是否为流文件格式
    case undefined:
      break
    default:
      fn = () => {
        ElementUI.Message('未知代码:' + response.data.data.status)
      }
  }
  if (document.getElementsByClassName('el-message').length < 1 && document.getElementsByClassName('el-message-box').length <
    1) {
    fn()
  }
}
export const finishErr = (err) => {
  console.error(err)
}
