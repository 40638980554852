<template>
  <div class="taskList" v-loading="vloading">

    <publish-task :isComponents="isComponents" @getMap="getMap" @showTable="showTable" @changeMap="changeMap" />
    <div class="switchType">
      <div class="switchActive" ref="switchActiveRef">

      </div>
        <div class="drone" :style="{color:task_type === '无人机' ? '#fff' : '#000'}" @click="changeSwitchType('drone')">
          无人机
        </div>
        <div class="drone" :style="{color:task_type === '非无人机' ? '#fff' : '#000'}" @click="changeSwitchType('onDrone')">
          非无人机
        </div>
        <div class="drone" :style="{color:task_type === '火情上报' ? '#fff' : '#000'}" @click="changeSwitchType('fire')">
          火情上报
        </div>
    </div>
    <div v-show="showOrHideTable" class="search" ref="search">
      <el-card shadow="never">
        <div class="searchTable" ref="searchTable">
          <div class="searchTableBox">
            <span class="searchTitle"> 发布ID </span>
            <span class="searchData">
              <el-input
                placeholder="请输入"
                v-model="searchForm.demand_order_no"
                size="small"
              ></el-input>
            </span>
          </div>
          <div class="searchTableBox">
            <span class="searchTitle"> 任务名称 </span>
            <span class="searchData">
              <el-input
                placeholder="请输入"
                v-model="searchForm.task_name"
                size="small"
              ></el-input>
            </span>
          </div>
          <div class="searchTableBox">
            <span class="searchTitle"> 任务来源 </span>
            <span class="searchData">
              <el-select v-model="searchForm.order_source" size="small">
                <el-option
                  v-for="item in order_sourceList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </span>
          </div>
          <div v-show="pathQuery !== 'draft'" class="searchTableBox">
            <span class="searchTitle"> 发布人 </span>
            <span class="searchData">
              <el-input
                placeholder="请输入"
                size="small"
                v-model="searchForm.created_user_mobile"
              ></el-input>
            </span>
          </div>
          <div class="searchTableBox">
            <span class="searchTitle"> 发布方式 </span>
            <span class="searchData">
              <el-select v-model="searchForm.created_type" size="small">
                <el-option
                  v-for="item in created_typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </span>
          </div>
          <div
            v-show="pathQuery !== 'toAudit' && pathQuery !== 'draft'"
            class="searchTableBox"
          >
            <span class="searchTitle"> 核发人 </span>
            <span class="searchData">
              <el-input
                placeholder="请输入"
                size="small"
                v-model="searchForm.order_audit_user_mobile"
              ></el-input>
            </span>
          </div>
          <div
            v-show="
              pathQuery !== 'toAudit' &&
              pathQuery !== 'back' &&
              pathQuery !== 'draft'
            "
            class="searchTableBox"
          >
            <span class="searchTitle"> 完善人 </span>
            <span class="searchData">
              <el-input
                placeholder="请输入"
                size="small"
                v-model="searchForm.order_complete_user_mobile"
              ></el-input>
            </span>
          </div>
          <div
            v-show="
              pathQuery !== 'toAudit' &&
              pathQuery !== 'back' &&
              pathQuery !== 'draft'
            "
            class="searchTableBox"
          >
            <span class="searchTitle"> 派单人 </span>
            <span class="searchData">
              <el-input
                placeholder="请输入"
                size="small"
                v-model="searchForm.order_send_user_mobile"
              ></el-input>
            </span>
          </div>
          <div class="searchTableBox">
            <span class="searchTitle"> 截止工期 </span>
            <span class="searchData">
              <el-date-picker
                v-model="searchForm.end_date"
                type="date"
                size="small"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                style="width: 100%"
              >
              </el-date-picker>
            </span>
          </div>
          <div
            v-show="
              pathQuery !== 'toAudit' &&
              pathQuery !== 'back' &&
              pathQuery !== 'draft'
            "
            class="searchTableBox"

          >
            <span class="searchTitle" > 任务状态 </span>
            <span class="searchData">
              <el-select v-model="searchForm.demand_status" size="small" >
                <el-option
                  v-for="item in taskStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </span>
          </div>
          <!-- <tr>

            <td v-show="pathQuery!=='toAudit'" class="searchTitle">任务状态</td>
            <td v-show="pathQuery!=='toAudit'" class="searchData">
              <el-select v-model="searchForm.status" size="small">
                <el-option
                  v-for="item in taskStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </td>
          </tr> -->
        </div>
        <div class="searchBtn" ref="searchBtn">
          <el-button @click="resetSearch" size="small">重置</el-button>
          <el-button type="primary" @click="searchTask" size="small"
            >查询</el-button
          >
          <el-button v-show="pathQuery !== 'draft'" type="text" size="small" @click="hideSearch"
            >{{searchText}}
            <i  v-show="searchText==='展开'" class="el-icon-arrow-down"></i>
            <i  v-show="searchText==='收起'" class="el-icon-arrow-up"></i>

            </el-button>
        </div>
      </el-card>
    </div>
    <div v-show="showOrHideTable" class="dataList" id="datalist">
      <el-card shadow="never" style="height: 347px" class="datalistCard" >
        <div style="height: 244px; overflow: auto; width: 97%;margin-left: 20px;">
          <el-table
            :data="taskListData"
            style="width: 100%;font-size: 13px;"
            height="244"
            highlight-current-row
            @current-change="handleCurrentChange"
            v-loading="isLoading"
            size="small"
          >
            <el-table-column prop="demand_order_no" label="发布ID" :width="tableTdWidth.demand_order_no">
              <template slot-scope="scope">
                <div>
                  <span v-show="scope.row.is_read === 0" class="isNotRead"></span>
                <span>
                  {{scope.row.demand_order_no}}
                </span>
                </div>

              </template>
            </el-table-column>
            <el-table-column prop="task_name" label="任务名称" :width="tableTdWidth.task_name" :show-overflow-tooltip='true' min-width="200">
              <template slot-scope="scope">
                <span v-if="scope.row.task_name">{{
                  scope.row.task_name
                }}</span>
                <span v-else>
                  <div class="noData"></div>
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="order_source" label="任务来源" :width="tableTdWidth.order_source" :show-overflow-tooltip='true'>
            </el-table-column>
            <el-table-column
              prop="created_user_mobile"
              label="发布人"
              :width="tableTdWidth.created_user_mobile"
              :show-overflow-tooltip='true'
            >
             <template slot-scope="scope">
        <el-popover trigger="click" placement="right" title="发布人" width="263" popper-class="information123" effect="light" :popper-options="popperOp">
          <div style="border-top: 1px solid rgba(0, 0, 0, 0.06);width:100%;padding-top: 8px;line-height: 20px;" class="">
            <span class="creatUnitName">账户: {{ scope.row.created_user_mobile }}</span><br>
            <span class="creatUnitName">姓名: {{ orderData.created_user_name }}</span><br>
            <span class="creatUnitName">账户类型: {{ orderData.created_user_role_name }}</span><br>
            <span :title="orderData.created_user_unit_name" class="creatUnitName">单位: {{ orderData.created_user_unit_name }}</span>
            <span class="creatUnitName">发布时间: {{ orderData.created_at }}</span>
          </div>

          <div slot="reference" class="name-wrapper">
            <el-button type="text" style="color:rgb(96, 98, 102)" class="orderDataName">{{ scope.row.created_user_name }}</el-button>
          </div>
        </el-popover>
      </template>
            </el-table-column>
            <el-table-column prop="created_type" label="发布方式" :width="tableTdWidth.releaseType">
            </el-table-column>
            <el-table-column
              v-if="pathQuery !== 'toAudit' && pathQuery !== 'draft'"
              prop="audit_user_mobile"
              label="核发人"
              :show-overflow-tooltip='true'
              :width="tableTdWidth.audit_user_mobile"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.audit_user_mobile">
                  <el-popover trigger="click" placement="right" title="核发人" width="263" popper-class="information123" effect="light" :popper-options="popperOp">
                    <div style="border-top: 1px solid rgba(0, 0, 0, 0.06);width:100%;padding-top: 8px;line-height: 20px;" class="">
                      <span class="creatUnitName">账户: {{ scope.row.audit_user_mobile }}</span><br>
                      <span class="creatUnitName">账户类型: 超级管理员</span><br>
                      <span v-if="auditData" class="creatUnitName">单位: {{ auditData.unit_name }}</span><br>
                      <span v-if="auditData" class="creatUnitName">审核时间: {{ auditData.created_at }}</span>
                    </div>

                    <div slot="reference" class="name-wrapper">
                      <!-- <el-button type="text" style="color:rgb(96, 98, 102)">{{ scope.row.audit_user_mobile }}</el-button> -->
                      <el-button type="text" style="color:rgb(96, 98, 102)" class="orderDataName">{{ scope.row.audit.audit_user_name }}</el-button>

                    </div>
                  </el-popover>
                </span>
                <span v-else>
                  <div class="noData"></div>
                </span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="pathQuery !== 'toAudit' && pathQuery !== 'draft' && pathQuery !== 'back'"
              prop="order_complete_user_mobile"
              label="完善人"
              :show-overflow-tooltip='true'
              :width="tableTdWidth.order_complete_user_mobile"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.order_complete_user_mobile">
                   <el-popover trigger="click" placement="right" title="完善人" width="263" popper-class="information123" effect="light" :popper-options="popperOp">
                    <div style="border-top: 1px solid rgba(0, 0, 0, 0.06);width:100%;padding-top: 8px;line-height: 20px;" class="">
                      <span class="creatUnitName">账户: {{ scope.row.order_complete_user_mobile }}</span><br>
                      <span class="creatUnitName">账户类型: 超级管理员</span><br>
                      <span class="creatUnitName" :title="orderData.order_complete_user_unit_name">单位: {{ orderData.order_complete_user_unit_name }}</span><br>
                      <span class="creatUnitName">完善时间: {{ orderData.order_complete_datetime }}</span>
                    </div>

                    <div slot="reference" class="name-wrapper">
                      <el-button type="text" style="color:rgb(96, 98, 102)" class="orderDataName">{{ scope.row.order_complete_user_name }}</el-button>
                    </div>
                  </el-popover>
                </span>
                <span v-else>
                  <div class="noData"></div>
                </span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="pathQuery !== 'toAudit' && pathQuery !== 'draft' && pathQuery !== 'back'"
              prop="order_send_user_mobile"
              label="派单人"
              :width="tableTdWidth.order_send_user_mobile"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.order_send_user_name">
                 <el-popover trigger="click" placement="right" title="派单人" width="263" popper-class="information123" effect="light" :popper-options="popperOp">
                    <div style="border-top: 1px solid rgba(0, 0, 0, 0.06);width:100%;padding-top: 8px;line-height: 20px;">
                      <span class="creatUnitName">账户: {{ scope.row.order_send_user_mobile }}</span><br>
                      <span class="creatUnitName">账户类型: 超级管理员</span><br>
                      <span class="creatUnitName" :title="orderData.order_send_user_unit_name">单位: {{ orderData.order_send_user_unit_name }}</span><br>
                      <span class="creatUnitName">派单时间: {{ orderData.order_send_datetime }}</span>
                    </div>

                    <div slot="reference" class="name-wrapper">
                      <el-button type="text" style="color:rgb(96, 98, 102)" class="orderDataName">{{ scope.row.order_send_user_name }}</el-button>
                    </div>
                  </el-popover>
                </span>
                <span v-else>
                  <div class="noData"></div>
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="end_date" label="截止工期" :width="tableTdWidth.end_date" :show-overflow-tooltip='true'>
              <template slot-scope="scope">
                <span v-if="scope.row.end_date">{{ scope.row.end_date }}</span>
                <span v-else>
                  <div class="noData"></div>
                </span>
              </template>
            </el-table-column>
            <el-table-column label="任务详情" :width="tableTdWidth.taskInformation">
              <template slot-scope="scope">
                <el-button
                  @click="
                    lookTaskDetails(
                      scope.row.id,
                      scope.row.order_complete_user_mobile,
                      scope.row
                    )
                  "
                  type="text"
                  >查看</el-button
                >
              </template>
            </el-table-column>
            <el-table-column label="任务状态" min-width="100" key="taskStatus" :show-overflow-tooltip='true'>
              <template slot-scope="scope">
                <span v-if="scope.row.status !== 3">
                    <span v-if="scope.row.status === 2"
                  ><span class="point" style="background-color: #fac419"></span>
                  待核发</span
                >
                <span v-else-if="scope.row.status === 3"
                  ><span class="point" style="background-color: #fac419"></span
                  >待处理</span
                >
                <span v-else-if="scope.row.status === 4">
                  <el-popover trigger="hover" placement="right" width="263" popper-class="information123" effect="light" :popper-options="popperOp">
                    <div style="width:100%;padding-top: 8px;line-height: 20px;display: flex;align-items: center;" class="">
                      <i class="el-icon-warning" style="color: #faad14; font-size: 21px; margin-right: 10px"></i>
                      <span style="display:inline-block;width:280px">您提交的任务，因"{{scope.row.audit.reason}}"的原因无法核发，予以退回。</span>
                    </div>

                    <div slot="reference" class="name-wrapper">
                      <span class="point" style="background-color:rgb(255, 42, 0)"></span
                    >
                      <el-button type="text" style="color:rgb(96, 98, 102)">已退回</el-button>
                    </div>
                  </el-popover>
                </span>
                <span v-else>
                  草拟
                </span>
                </span>
                <span v-else>
                <span v-if="scope.row.status_cancel === 1">
                  <el-popover trigger="hover" placement="right" width="263" popper-class="information123" effect="light" :popper-options="popperOp">
                    <div style="width:100%;padding-top: 8px;line-height: 20px;display: flex;align-items: center;" class="">
                      <i class="el-icon-warning" style="color: #faad14; font-size: 21px; margin-right: 10px"></i>
                      <span style="display:inline-block;width:280px">您提交的任务，因"{{scope.row.audit.reason}}"的原因无法执行，被迫中止</span>
                    </div>

                    <div slot="reference" class="name-wrapper">
                    <span class="point" style="background-color: #FF2A00"></span>
                      <el-button type="text" style="color:rgb(96, 98, 102)">已中止</el-button>
                    </div>
                  </el-popover>
                    <!-- <span class="point" style="background-color: #FF2A00"></span>已中止 -->
                </span>
                  <span v-else>
                    <span v-if="scope.row.status_task === 0">
                      <span class="point" style="background-color: #FAC419"></span>待处理
                    </span>
                    <span v-else-if="scope.row.status_task === 1">
                      <span class="point" style="background-color: #02BE00"></span>已外部协作
                    </span>
                    <span v-else @click="jumpPath(scope.row)">
                      <span class="point" style="background-color: #02BE00;" ></span>已内部派单
                    </span>
                  </span>
                  </span>
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="任务处理"
              :width="tableTdWidth.taskBtn"
              min-width="200"
              v-if="userInfo.is_admin === 1 && (pathQuery !== 'all' || pathQuery !== 'toAudit' ||  pathQuery !== 'published' || pathQuery !== 'back' || pathQuery !== 'draft' )"
              key="status"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.status === 2">
                  <el-button @click="audit(scope.row)" type="text"
                    >核发</el-button
                  >
                  <span class="line"></span>
                  <el-button
                    @click="rebut(scope.row)"
                    type="text"
                    style="color: red"
                    >退回</el-button
                  >
                </span>
                <span v-else-if="scope.row.status === 3">
                  <el-button
                    @click="audit(scope.row)"
                    type="text"
                    disabled
                    >外部协作</el-button
                  >
                  <el-button
                    @click="setDemandInternalOrder(scope.row)"
                    type="text"
                    :disabled="scope.row.status_cancel===1 || scope.row.status_task!==0"
                    >内部派单</el-button
                  >
                  <el-button
                    @click="demandCancelOrder(scope.row)"
                    type="text"
                    :disabled="scope.row.status_cancel===1"
                     :style="{
                        color: scope.row.status_cancel===1
                          ? ''
                          : 'red',
                      }"
                    >中止</el-button
                  >
                </span>
                <span v-else-if="scope.row.status === 4">
                  <div class="noData"></div>
                </span>
                <span v-else>
                  <el-button @click="public(scope.row)" type="text"
                    >发布</el-button
                  >
                  <el-button
                    @click="del(scope.row)"
                    type="text"
                    style="color: red"
                    >删除</el-button
                  >
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-show="status !==''" class="allHaveBeenRead" @click="allRead">
          <img src="@/assets/img/planTask/brush.png" alt="">全部已读
        </div>
        <div class="pagination">
          <span style="margin-right: 20px">共 {{ totalPage }}条</span>
          <span> 每页{{ per_page }}条 </span>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="setPage"
            :current-page="nowPage"
            :page-size="per_page"
            layout="prev, pager, next, jumper"
            :total="totalPage"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
    <div class="taskDetail">
      <el-dialog
      title="任务详情"
      :visible.sync="taskDetailShow"
      v-if="taskDetailShow"
      width="382px"
      v-dialogDrag
      :modal="false"
      @closeForm="handleClose"
      :append-to-body="false"
      :modal-append-to-body="false"
      :lock-scroll="false"

    >
      <taskDetail
        ref="taskDetail"
        :id="id"
        :releaseShow="taskDetailShow"
        @closeForm="closeForm"
        @showCompleteOrder="showCompleteOrder"
        :pathQuery="pathQuery"
        :showAllData="showAllData"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeForm">关闭</el-button>
      </span>
    </el-dialog>
    </div>
     <el-dialog title="提示" :visible.sync="afterComplete" width="382px">
      <i
        class="el-icon-warning"
        style="color: #faad14; font-size: 21px; margin-left: 15px"
      ></i>
      <span style="display:inline-block;width:280px">请对该任务进行派单。</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" disabled
          >外部协作</el-button>
        <el-button type="primary" @click="demandInternalOrder"
          >内部派单</el-button>
        <el-button  @click="demandCancelOrder"
          >中止</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="promptDia" width="382px">
      <i
        class="el-icon-warning"
        style="color: #faad14; font-size: 21px; margin-left: 15px"
      ></i>
      <span style="display:inline-block;width:280px">该任务发布方式为一键发布，请优先进行任务完善。</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="promptDia = false">取消</el-button>

        <el-button @click="demandInternalOrder()">跳过完善</el-button>
        <el-button type="primary" @click="setDetailData"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="isShowReason" width="382px" :modal="false" v-dialogDrag :close-on-click-modal="false">
      <i
        class="el-icon-warning"
        style="color: #faad14; font-size: 21px; margin-left: 15px"
      ></i>
      <span style="display:inline-block;width:280px">您提交的任务，因"{{reason}}"的原因无法{{backOrStop}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowReason = false">取消</el-button>
        <el-button type="primary" @click="isShowReason = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <div v-if="completeInformationShow">
      <releaseForm
        :listDrawShapeData="orderData.survey_area"
        :completeData="completeData"
        :releaseShow="completeInformationShow"
        @closeForm="handleClose"
        title="完善任务"
        :userInfo="orderUserInfo"
        @completeAkeyOrder="completeAkeyOrder"
      />
    </div>
  </div>
</template>

<script>
import publishTask from "./publishTask.vue";
import { mapState, mapMutations } from "vuex";
import taskDetail from "./taskDetail.vue";
import MapTool from "./mapTool";
import releaseForm from "./releaseForm.vue";
export default {
  components: {
    publishTask,
    taskDetail,
    releaseForm,
  },
  data() {
    return {
      taskListData: [],
      nowPage: 1, //当前所在页数
      totalPage: 1,
      per_page: 10, //每页数量
      status: "", //任务状态，用于侧边栏切换
      order_sourceList: [
        {
          value: "调度中心端",
          label: "调度中心端",
        },
        {
          value: "林业巡护",
          label: "林业巡护",
        },
      ],

      created_typeList: [
        {
          value: "一键发布",
          label: "一键发布",
        },
        {
          value: "详细发布",
          label: "详细发布",
        },
      ],
      searchForm: {
        demand_order_no: "",
        order_source: "", //订单来源
        task_name: "", //任务名称
        created_user_mobile: "", //发布人
        created_type: "",
        order_audit_user_mobile: "",
        order_complete_user_mobile: "",
        end_date: "",
        demand_status: "",
      },
      taskStatusList: [],
      allTaskStatusList: [
        //1.草拟任务 2.待核任务 3.核发任务 4.退回任务
        { label: "待核发", value: 1 },
        { label: "已退回", value: 2 },
        { label: "待处理", value: 3 },
        { label: "已外部协作", value: 4 },
        { label: "已内部派单", value: 5 },
        { label: "已中止", value: 6 },
      ],
      publishedTaskStatusList: [
        { label: "待处理", value: 3 },
        { label: "已外部协作", value: 4 },
        { label: "已内部派单", value: 5 },
        { label: "已中止", value: 6 },
      ],
      userInfo: {}, //用户信息
      taskDetailShow: false, //显示详情弹窗
      id: -1, //任务编号
      showAllData: true, //查看是否要显示全部数据，一键发布的如果已经完善了，可以看到所有的数据
      mapToolManage: "", //鼠标工具，用于绘制图形
      map: "", //地图实例
      isComponents: true, //地图是否为组件
      isLoading: true, //地图加载
      pathQuery: "", //路由地址参数
      promptDia: false, //一键派单弹出窗口
      reason: "", //原因
      isShowReason: false, //退回原因弹窗
      showReasonId: -1, //显示原因的Id原因激活下划线
      backOrStop: "", //退回或中止的文字说明
      orderData: "", //订单表单数据，用于完善数据
      orderUserInfo: {}, //订单表单用户信息
      completeInformationShow: false, //完善信息弹窗
      popperOp: {}, //popper设置属性
      auditData: {}, //审核人数据
      searchText: "展开", //收起按钮文字
      showOrHideTable: true, //显示或隐藏表格
      afterComplete: false, //完善后弹窗
      completeData: {}, //完善弹窗的文件和补充说明
      vloading: false, //加载中
      allTableTdWidth: {
        demand_order_no: "125",
        task_name: "",
        order_source: "",
        created_user_mobile: "",
        audit_user_mobile: "",
        order_complete_user_mobile: "",
        order_send_user_mobile: "",
        end_date: "",
        status: "",
        taskBtn: "230",
        releaseType: "",
        taskInformation: "",
      },
      partTableTdWidth: {
        demand_order_no: "150",
        task_name: "",
        order_source: "",
        created_user_mobile: "",
        audit_user_mobile: "",
        order_complete_user_mobile: "",
        order_send_user_mobile: "",
        end_date: "",
        status: "",
        taskBtn: "120",
        releaseType: "",
        taskInformation: "",
      },
      tableTdWidth: {},
      survey_area: [],
      switchActive: true, //是否激活的是无人机
      task_type: "无人机",
    };
  },
  watch: {
    $route: "setSideNav",
  },
  mounted() {
    //
    this.setSideNav();
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.popperOp = { boundariesElement: document.getElementById("datalist") };
  },
  computed: {
    ...mapState(["token", "orderQty", "role"]),
  },
  methods: {
    ...mapMutations([
      "currentUrlStorage",
      "navMessageStorage",
      "newTaskNumStorage",
      "setOrderQty",
    ]),
    allRead() {
      // to do
      this.$apis
        .setOrderIsRead({ status: this.status }, this.token)
        .then((data) => {
          this.getDemandListOrder();
          let orderQty = JSON.parse(JSON.stringify(this.orderQty));
          orderQty["status_" + this.status] = 0;
          this.setOrderQty(orderQty);
        });
    },
    setSideNav() {
      let query = this.$route.query.type;
      this.pathQuery = query;
      let str = "全部任务";
      let status = "";
      let orderQty = JSON.parse(JSON.stringify(this.orderQty));
      switch (query) {
        case "all":
          str = "全部任务";
          this.taskStatusList = JSON.parse(
            JSON.stringify(this.allTaskStatusList)
          );
          break;
        case "toAudit":
          str = "待核任务";
          status = 2;
          orderQty.status_2 = 0;
          break;
        case "back":
          str = "退回任务";
          orderQty.status_4 = 0;
          status = 4;
          break;
        case "draft":
          str = "草拟任务";
          orderQty.status_1 = 0;
          status = 1;
          break;
        default:
          str = "核发任务";
          orderQty.status_3 = 0;
          status = 3;
          this.taskStatusList = JSON.parse(
            JSON.stringify(this.publishedTaskStatusList)
          );

          break;
      }
      // this.setOrderQty(orderQty);
      this.status = status;
      this.currentUrlStorage(["首页", "任务管理", str]);
      this.getDemandListOrder();
      for (let key in this.searchForm) {
        this.searchForm[key] = "";
      }
      this.nowPage = 1;
      if (query === "all" || query === "published") {
        this.tableTdWidth = JSON.parse(JSON.stringify(this.allTableTdWidth));
      } else {
        this.tableTdWidth = JSON.parse(JSON.stringify(this.partTableTdWidth));
      }
    },
    getDemandListOrder(
      form = {
        task_type: this.task_type,
        per_page: 10,
        page: this.nowPage,
        status: this.status,
      }
    ) {
      this.isLoading = true;
      this.taskListData = [];
      this.$apis.demandListOrder(form, this.token).then((data) => {
        const allData = JSON.parse(
          JSON.stringify(data.data.data.order_data.data)
        );
        this.taskListData = allData;
        this.totalPage = data.data.data.order_data.total;
        this.isLoading = false;
        this.setOrderQty(data.data.data.order_qty);
        this.$forceUpdate();
      });
    },
    // 查看任务详情
    lookTaskDetails(id, order_complete_user_mobile, data) {
      this.id = id;
      this.taskDetailShow = true;
    },
    // 关闭弹窗
    closeForm() {
      this.taskDetailShow = false;
      // this.getDemandListOrder()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    setPage(val) {
      this.nowPage = val;
      let form = {
        task_type: this.task_type,
        per_page: 10,
        page: this.nowPage,
        status: this.status,
      };
      for (let key in this.searchForm) {
        if (this.searchForm[key] !== "") {
          form[key] = this.searchForm[key];
        }
      }
      this.getDemandListOrder(form);
    },
    // 重置搜索
    resetSearch() {
      for (let key in this.searchForm) {
        this.searchForm[key] = "";
      }
      this.nowPage = 1;
      this.getDemandListOrder();
    },
    searchTask() {
      this.nowPage = 1;
      let form = {
        task_type: this.task_type,
        per_page: 10,
        page: this.nowPage,
        status: this.status,
      };
      for (let key in this.searchForm) {
        if (this.searchForm[key] !== "") {
          form[key] = this.searchForm[key];
        }
      }
      this.getDemandListOrder(form);
    },
    // 收起
    hideSearch() {
      let height = "130px";
      let paddingTop = "64px";
      let searchTableHeight = "104px";
      if (this.searchText === "展开") {
        this.searchText = "收起";
      } else {
        this.searchText = "展开";
        height = "78px";
        paddingTop = "10px";
        searchTableHeight = "100px";
      }
      this.$refs.search.style.height = height;
      this.$refs.searchBtn.style.paddingTop = paddingTop;
      this.$refs.searchTable.style.height = searchTableHeight;
    },
    changeMap(isWgs) {
      this.map.clearMap();
      this.mapToolManage.drawShape(JSON.parse(this.survey_area), isWgs);

      this.map.setFitView();
    },
    // 选中表格某行
    handleCurrentChange(val) {
      // this.taskDetailShow = false
      this.map.clearMap();
      if (val && val.id) {
        this.$apis.demandShowOrder({ id: val.id }, this.token).then((res) => {
          let survey_area = res.data.data.order.survey_area;
          this.survey_area = survey_area;
          // console.log(JSON.parse(survey_area));
          this.mapToolManage.drawShape(JSON.parse(survey_area));
          this.orderData = res.data.data.order;
          this.completeData["fileList"] = res.data.data.file;
          this.completeData["additional_remarks"] =
            this.orderData.additional_remarks;
          this.orderData.description = JSON.parse(this.orderData.description);
          this.orderData.survey_area = JSON.parse(this.orderData.survey_area);
          this.orderUserInfo["mobile"] = this.orderData.created_user_mobile;
          this.orderUserInfo["role_name"] =
            this.orderData.created_user_role_name;
          this.orderUserInfo["unit_name"] =
            this.orderData.created_user_unit_name;
          this.orderUserInfo["name"] = this.orderData.created_user_name;
          this.orderUserInfo["id"] = val.id;
          this.auditData = res.data.data.audit;
          if (val.is_read === 0) {
            let dataIndex = this.taskListData.indexOf(val);
            this.taskListData[dataIndex].is_read = 1;
            if (this.status !== "") {
              let orderQty = JSON.parse(JSON.stringify(this.orderQty));
              orderQty["status_" + this.status]--;
              this.setOrderQty(orderQty);
            } else {
              this.$apis
                .demandListOrder({ task_type: this.task_type }, this.token)
                .then((data) => {
                  this.setOrderQty(data.data.data.order_qty);
                  this.$forceUpdate();
                });
            }
          }
        });
      }
    },
    // 获取地图实例。地图组件获取到地图实例后返回到父组件
    getMap(map) {
      this.mapToolManage = new MapTool(map);
      this.map = map;
    },
    audit(data) {
      this.$confirm("确定要核发该条任务吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.auditTask(data.id, "pass");
        })
        .catch(() => {});
    },
    clickText() {
      // console.log(143423423423432);
    },
    rebut(data) {
      this.$prompt("您提交的任务，因以下原因无法核发，予以退回。 ", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPlaceholder: "请输入退回原因…",
      })
        .then(({ value }) => {
          this.auditTask(data.id, "rebut", value);
        })
        .catch(() => {});
    },
    auditTask(id, audit, reason = "") {
      let form = { id, audit };
      if (reason !== "") {
        form["reason"] = reason;
      }
      this.$apis.demandAuditOrder(form, this.token).then((res) => {
        if (audit === "pass") {
          this.$message({
            dangerouslyUseHTMLString: true,
            type: "success",
            duration: 6000,
            message:
              '<span>已提交核发，任务已流转至“核发任务”中。<a id="success" style="color: #2650ff">查看</a> </span',
          });
          // 监听message实例的点击实例
          let that = this;
          document
            .getElementById("success")
            .addEventListener("click", function () {
              // self.errorMsg.close()
              // console.log(12345);
              that.$router.push({
                path: "taskList?type=published",
              });
            });
        } else {
          this.$message({
            message: res.data.message,
            type: "success",
          });
        }
        this.getDemandListOrder();
      });
    },
    setDemandInternalOrder(data) {
      this.id = data.id;
      if (data.created_type === "详细发布") {
        this.$confirm("确定要内部派单该条任务吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.vloading = true;
            this.demandInternalOrder();
          })
          .catch(() => {});
      } else {
        if (data.order_complete_user_mobile !== "" || this.task_type === '非无人机') {
          this.$confirm("确定要内部派单该条任务吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          })
            .then(() => {
              this.demandInternalOrder();
            })
            .catch(() => {});
        } else {
          this.promptDia = true;
        }
      }
    },
    // 内部派单
    demandInternalOrder() {
      this.afterComplete = false;
      this.$apis
        .demandInternalOrder({ id: this.id }, this.token)
        .then((res) => {
          if (res.data.status === 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.vloading = false;
            this.promptDia = false;
          }
          this.getDemandListOrder();
        });
    },
    // 草拟订单发布
    public(data) {
      this.$confirm("确定要发布该条任务吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$apis
            .demandSaveOrder({ id: data.id, action: "public" }, this.token)
            .then((res) => {
              if (res.data.status === 0) {
                this.$message({
                  message: res.data.message,
                  type: "success",
                });
                this.getDemandListOrder();
              }
            });
        })
        .catch(() => {});
    },
    // 草拟订单删除
    del(data) {
      this.$confirm("确定要删除该条任务吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$apis
            .demandDeleteOrder({ id: data.id }, this.token)
            .then((res) => {
              if (res.data.status === 0) {
                this.$message({
                  message: res.data.message,
                  type: "success",
                });
                this.getDemandListOrder();
              }
            });
        })
        .catch(() => {});
    },
    // 中止订单
    demandCancelOrder(data = "") {
      this.afterComplete = false;
      if (data !== "") {
        this.id = data.id;
      }

      this.$prompt("您提交的任务，因以下原因无法执行，被迫中止。 ", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPlaceholder: "请输入中止原因…",
      })
        .then(({ value }) => {
          this.$apis
            .demandCancelOrder(
              { id: this.id, audit: "cancel", reason: value },
              this.token
            )
            .then((res) => {
              if (res.data.status === 0) {
                this.$message({
                  message: res.data.message,
                  type: "success",
                });
              }
              this.getDemandListOrder();
            });
        })
        .catch(() => {});
    },
    // 一键发布的派单点击确定
    setDetailData() {
      this.completeInformationShow = true;
      this.promptDia = false;
    },
    // 显示原因
    showReason(id, event) {
      this.showReasonId = id;
      if (event === "back") {
        this.backOrStop = "核发，予以退回。";
      } else {
        this.backOrStop = "执行，被迫中止。";
      }
      this.$apis.demandShowOrder({ id: id }, this.token).then((res) => {
        this.reason = res.data.data.audit.reason;
        // this.isShowReason = true;
      });
    },

    // 完善信息弹窗关闭
    handleClose() {
      this.completeInformationShow = false;
    },
    // 完善信息订单赋值
    showCompleteOrder(data) {
      this.completeData["fileList"] = data.fileList;
      this.completeData["additional_remarks"] = data.order.additional_remarks;
      this.orderData = data.order;
      this.orderUserInfo = data.userInfo;
      this.taskDetailShow = false;
      this.completeInformationShow = true;
    },
    // 显示或隐藏表格
    showTable(data) {
      // console.log(data);
      this.showOrHideTable = data;
    },
    // 完善任务点击保存后显示提示
    completeAkeyOrder() {
      this.afterComplete = true;
      this.getDemandListOrder();
    },
    // 跳转监控
    jumpPath(data) {
      this.$router.push({
        path: "/blockTaskPush",
        query: {
          id: data.relation_order_no,
          nav: 3,
          name: data.task_name,
          mobile: data.order_send_user_mobile,
          push_flag: "admin",
          redo: 0,
        },
      });
    },
    // 改变选择的列表类型
    changeSwitchType(listType) {
      let switchActiveRef = this.$refs.switchActiveRef;
      this.nowPage = 1
      if (listType === "drone") {
        switchActiveRef.style.left = "0px";
        this.switchActive = true;
        this.task_type = "无人机"
      }else if(listType === "fire") {
        switchActiveRef.style.left = "169px";
        this.switchActive = false;
        this.task_type = "火情上报"
      }
      else {
        switchActiveRef.style.left = "87px";
        this.switchActive = false;
        this.task_type = "非无人机"

        // switchActiveRef.style.transform = ' translate(0,121px)'
        // switchActiveRef.style.right = '0px'
      }
      this.getDemandListOrder()

    },
  },
};
</script>

<style lang="scss" scoped>
.information123 {
  background-color: #fff;
}
.creatUnitName {
  display: inline-block;
  width: 237px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.orderDataName {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.taskList {
  width: 100%;
  height: 100%;
  position: relative;
  .switchType {
    position: absolute;
    top: 17px;
    left: 19px;
    width: 255px;
    height: 48px;
    z-index: 9999;
    background: #ffffff;
    box-shadow: 0px 2px 30px 0px rgba(184, 183, 183, 0.5);
    border-radius: 30px;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    .switchActive {
      position: absolute;
      top: 0;
      left: 0;
      width: 85px;
      height: 48px;
      background: #1182fb;
      border-radius: 30px;
    }
    .drone {
      width: 134px;
      height: 48px;
      z-index: 99999;
      line-height: 48px;
      text-align: center;
      cursor: pointer;
      user-select: none;
    }
  }
  .datalistCard {
    .isNotRead {
      display: inline-block;
      width: 6px;
      height: 6px;
      background: #ff2a00;
      border-radius: 3px;
      margin-left: -9px;
    }
  }
  /deep/ .el-input {
    // width: 95.6%;
    // height: 32px;
    // line-height: 32px;
  }
  /deep/.el-card__body {
    padding: 20px 8px;
    display: flex;
  }

  /deep/.el-dialog__wrapper {
    right: -65%;
  }
  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 2px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 2px;
  }

  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    // border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #c2c6cc;
  }

  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    background: transparent;
  }
  * {
    scrollbar-color: #000 #6d6d83; /* 滑块颜色  滚动条背景颜色 */
    scrollbar-width: thin; /* 滚动条宽度有三种：thin、auto、none */
  }
  .search {
    position: absolute;
    width: 96%;
    bottom: 380px;
    overflow-x: auto;
    overflow-y: hidden;
    transition: 1s height;
    height: 78px;
    /deep/.el-card {
      min-width: 1596px;
    }
    left: 29px;
    .searchTable {
      font-size: 14px;
      color: #5a5a5a;
      display: flex;
      flex-wrap: wrap;
      width: 1308px;
      // align-items: center;
      margin-top: 7px;
      height: 100px;
      .searchTableBox {
        display: flex;
        align-items: center;
        height: 32px;
      }
      tr {
        line-height: 38px;
      }
      .searchTitle {
        width: 56px;
        margin-right: 14px;
        margin-left: 15px;
        text-align: right;
        display: inline-block;
      }
      .searchData {
        width: 172px;
      }
    }
    .searchBtn {
      padding-top: 10px;
      // margin-top: 5px;
      margin-left: 37px;
    }
  }
  /deep/.el-dialog__body {
    padding: 0;
  }
  .dataList {
    position: absolute;
    bottom: 24px;
    width: 96%;
    left: 29px;
    height: 347px;
    overflow: auto;

    /deep/.el-table .cell {
      // text-align: center !important;
    }
    /deep/.el-card__body {
      display: flex;
      flex-direction: column;
    }

    .noData {
      width: 51px;
      height: 2px;
      background: #d8d8d8;
    }
    .point {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-bottom: 2px;
      margin-right: 5px;
    }
    .line {
      display: inline-block;
      height: 12px;
      // width: 1px;

      // margin:3px 0;
      margin-top: -2px;
      // background-color: #D8D8D8;
      border-left: 1px solid #d8d8d8;
    }
    .pagination {
      display: flex;
      justify-content: flex-end;
      font-size: 14px;
      width: 97%;
      margin-top: 20px;
      color: #606266;
      span {
        // display: inline-block;
        line-height: 30px;
      }
    }
    .allHaveBeenRead {
      // width: 52px;
      height: 16px;
      font-size: 13px;
      color: #333333;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 48px;
      left: 47px;
      cursor: pointer;
      user-select: none;
      img {
        margin-right: 8px;
      }
    }
  }
  /deep/.blockInformation {
    width: 382px;
    height: 687px;
    left: calc(97% - 415px);
    top: calc(50% - 343px);
    > .el-dialog:first-child {
      width: 100%;
      margin-top: 0 !important;
      .el-dialog__header {
        // position: absolute;
        // right: 0;
        z-index: 998;
        width: 100%;
        // height: 70px;
        .chat .el-dialog__headerbtn {
          top: 10px;
        }
      }
      .el-dialog__body {
        padding: 0;
      }
    }
  }
}
</style>
