<template>
  <div class="allianceNav">
    <navTop />
    <div class="allianceBody">
      <div class="navBody">
        <div class="topNav">
          <div class="leftBtn">
            <el-button type="primary" plain @click="addalliance"
              >新建巡护单位</el-button
            >
          </div>
          <div class="rightInp">
            <!-- 搜索框 -->
            <el-input
              class="alliInput"
              placeholder="请输入巡护单位名称/巡护代码进行搜索"
              v-model="ruleForm.keyword"
              @click="search"
              @keyup.enter.native="search"
              @input="fucus(ruleForm.keyword)"
            >
              <i slot="suffix" @click="search" class="iconfont searchAll"
                >&#xe61d;</i
              >
            </el-input>
          </div>
        </div>
        <div class="midlleNav">
          <el-table
            height="100%"
            :data="unitList"
            style="width: 100%"
            :stripe="true"
            :header-cell-style="{ background: '#DDE6EF' }"
          >
            <el-table-column
              prop="unit_name"
              label="巡护单位"
              width="180"
              class="number"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="unit_code"
              label="巡护代码"
              width="180"
              align="center"
            ></el-table-column>
            <el-table-column label="超级管理员" align="center">
              <template slot-scope="scope">{{
                scope.row.count.admin_count
              }}</template>
            </el-table-column>
            <el-table-column label="巡护管理员" align="center">
              <template slot-scope="scope">{{
                scope.row.count.unit_count
              }}</template>
            </el-table-column>
            <el-table-column label="巡护终端用户" align="center">
              <template slot-scope="scope">{{
                scope.row.count.user_count
              }}</template>
            </el-table-column>
            <el-table-column label="任务群组" align="center">
              <template slot-scope="scope">{{
                scope.row.count.group_count
              }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="editBtn(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  @click="delBtn(scope.row)"
                  class="deletBtn"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="block">
          <el-pagination
            @current-change="currentchange"
            @next-click="handleNext"
            @prev-click="handlePrev"
            :current-page="current_page"
            :page-size="this.per_page"
            layout="total,  prev, pager, next, jumper"
            :total="this.total"
          ></el-pagination>
        </div>
        <div class="footNav">
          <p class="numalliance">
            巡护单位：共{{ this.total }}个
            <el-button
              v-if="role !== 2"
              @click="exportFile"
              type="primary"
              plain
              class="exportFileStyle"
              size="mini"
              >导出</el-button
            >
          </p>
        </div>
      </div>
    </div>
    <!-- 新建巡护 -->
    <el-dialog
      title="新建巡护单位"
      :visible.sync="dialogVisible"
      class="taskBox"
      :close-on-click-modal="false"
    >
      <el-divider></el-divider>
      <div class="detailBar">
        <div class="formBox">
          <el-form
            class="formbox"
            ref="ruleForm"
            :rules="rules"
            :model="ruleForm"
            label-width="80px"
          >
            <el-form-item class="userForm" prop="unit_name" label="巡护单位">
              <el-input
                placeholder="请输入"
                v-model="ruleForm.unit_name"
              ></el-input>
              <p class="sign">
                请输入正确的公司名称，公司名称应与营业执照上的名称保持一致。仅支持中文
              </p>
            </el-form-item>
            <el-form-item class="userForm" prop="unit_code" label="巡护代码">
              <el-input
                placeholder="请输入"
                v-model="ruleForm.unit_code"
              ></el-input>
              <p class="sign">
                请按照XXXXXXXXX规则输入巡护代码，巡护代码初始密码同巡护代码。
              </p>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footBtn" @click="newAlliance" type="primary"
          >确 认</el-button
        >
        <el-button class="footBtn" @click="dialogVisible = false"
          >关 闭</el-button
        >
      </span>

      <el-divider></el-divider>
    </el-dialog>
    <Edit :currentParam="currentParam" ref="edit" />
    <DelEdit :currendelParam="currendelParam" ref="deledit" />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Edit from "@/components/messageBox/edit";
import DelEdit from "@/components/messageBox/delEdit";
import { chinese } from "../../utils/validate.js";
import { exportFile } from "@/utils/exportFile";
export default {
  inject: ["reload"],
  components: {
    Edit,
    DelEdit,
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        unit_name: "",
        unit_password: "",
        keyword: "",
        unit_code: "",
      },
      rules: {
        unit_name: [],
        unit_code: [
          { required: true, message: "请输入巡护代码", trigger: "blur" },
          { min: 6, message: "长度必须大于6个字符", trigger: "blur" },
        ],
      },
      unitList: [],
      current_page: 1, //当前页
      total: 0, //总条目数
      per_page: 10, //每页多少条
      page: 1,
      currentParam: {},
      currendelParam: {},
    };
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    fucus(keyword) {
      if (keyword == "") {
        this.page = 1;
        this.search();
      }
    },
    search() {
      this.page = 1;
      this.unitListFunc();
    },
    addalliance() {
      this.dialogVisible = true;
    },
    newAlliance() {
      if (!chinese(this.ruleForm.unit_name)) {
        this.$message.error("巡护名称仅支持中文！");
        return;
      }
      this.$apis
        .add(
          {
            unit_name: this.ruleForm.unit_name,
            unit_code: this.ruleForm.unit_code,
            unit_password: this.ruleForm.unit_code,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status == 0) {
            this.$message({
              message: data.data.message,
              type: "success",
            });
            this.dialogVisible = false;
            this.ruleForm.unit_name = "";
            this.ruleForm.unit_password = "";
            this.unitListFunc();
          }
        })
        .catch((err) => {
          console.error(err);
          this.$message({
            message: data.data.message,
            type: "error",
          });
        });
    },
    unitListFunc() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$apis
        .unitList(
          {
            page: this.page,
            per_page: this.per_page,
            keyword: this.ruleForm.keyword,
          },
          this.token,
          ""
        )
        .then((data) => {
          this.unitList = data.data.data.data;
          this.total = data.data.data.total;
          this.current_page = data.data.data.current_page;
          this.per_page = data.data.data.per_page;
          loading.close();
        })
        .catch((err) => {
          loading.close();
          console.error(err);
        });
    },
    handleNext() {
      this.page++;
      this.unitListFunc(this.page, this.per_page);
    },
    handlePrev() {
      this.page--;
      this.unitListFunc(this.page, this.per_page);
    },
    currentchange(page) {
      this.page = page;
      this.unitListFunc(this.page, this.per_page);
    },
    editBtn(item) {
      this.currentParam = item;
      setTimeout(() => {
        this.$refs.edit.openEdit();
      }, 0);
    },
    delBtn(item) {
      this.currendelParam = item;
      setTimeout(() => {
        this.$refs.deledit.openDelEdit();
      }, 0);
    },
    exportFile() {
      this.$apis
        .exportUnit({}, this.token)
        .then((data) => {
          exportFile(data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  computed: {
    ...mapState(["user", "token", "role"]),
  },
  created() {
    this.unitListFunc();
  },
  mounted() {
    if (this.$route.query.nav == "danwei") {
      this.currentUrlStorage(["首页", "用户管理", "巡护单位"]);
    }
  },
};
</script>

<style lang="less" scoped>
.sign {
  width: 277px;
  font-size: 12px;
  color: #9b9da3;
  margin: 5px 0 0 80px;
}
.searchAll {
  cursor: pointer;
  display: block;
  margin-top: 10px;
}
/deep/.el-form-item__content {
  line-height: 16px;
}
@import url("../../assets/iconfont/iconfont.css");
/deep/ .el-table .cell {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
}
/deep/ .el-table .cell:hover {
  text-overflow: inherit;
  overflow: visible;
  white-space: pre-line;
}
.deletBtn {
  color: #ff5c5e;
}
.block {
  padding: 30px 0 0 50px;
}
.footBtn {
  width: 140px;
  height: 48px;
  margin-left: 20px;
}
/deep/.el-dialog__footer {
  text-align: center;
  line-height: 70px;
}
.formBox {
  margin-top: 50px !important;
  margin-left: 30px;
}
/deep/.el-dialog {
  width: 460px;
  height: 380px;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-divider--horizontal {
  margin: 0;
}
.el-input {
  width: 277px;
}
th,
tr {
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-size: 13px;
}
.alliInput {
  width: 310px;
  font-size: 13px;
}
.tableData {
  // height: calc(100vh - 500px);
  border-collapse: collapse;
  .tabTitle {
    height: 56px;
  }
}
.tableFile {
  height: 30px;
  color: #000000;
  font-size: 14px;
  font-family: MicrosoftYaHei;
}
.tableTitle {
  height: 30px;
  background-color: #dde6ef;
  font-size: 16px;
  color: #3c4353;
  font-weight: bold;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
}

table tr:nth-child(odd) {
  background-color: #e5eaf1;
}

table tr:nth-child(even) {
  background: #fff;
}
.footNav {
  position: relative;
  bottom: 40px;
  .numalliance {
    float: right;
    color: #606266;
    font-size: 14px;
  }
}
.allianceNav {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  padding: 0px 30px 75px 30px;
  .allianceBody {
    width: 100%;
    height: calc(100vh - 150px);
    background-color: #ffffff;
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    .navBody {
      width: 100%;
      height: 100%;
      padding: 0 2%;
      .topNav {
        height: 50px;
        display: flex;
        justify-content: space-between;
        .leftBtn {
          margin-top: 50px;
        }
        .rightInp {
          margin-top: 50px;
        }
      }
      .midlleNav {
        margin-top: 65px;
        height: 75%;
      }
    }
  }
}
@media screen and (min-width: 1366px) and (max-width: 1650px) {
  .block {
    padding: 30px 0 0 50px;
  }
  .tableTitle {
    height: 30px;
    background-color: #dde6ef;
    font-size: 16px;
    color: #3c4353;
    font-weight: bold;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  }
  .allianceNav {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    padding: 0px 30px 75px 30px;
    .allianceBody {
      width: 100%;
      height: calc(100vh - 150px);
      background-color: #ffffff;
      box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      overflow-y: auto;
      .navBody {
        width: 100%;
        height: 100%;
        padding: 0 30px;
        .topNav {
          height: 10px;
          display: flex;
          justify-content: space-between;
          .leftBtn {
            margin-top: 20px;
          }
          .rightInp {
            margin-top: 20px;
          }
        }
        .midlleNav {
          margin-top: 70px;
        }
        .footNav {
          position: relative;
          bottom: 40px;
        }
      }
    }
  }
  .tableData {
    border-collapse: collapse;
    .tabTitle {
      height: 30px;
    }
  }
  .tabletr {
    height: 25px !important;
  }
}
@media only screen and (max-width: 1366px) {
  .tableTitle {
    height: 30px;
    background-color: #dde6ef;
    font-size: 16px;
    color: #3c4353;
    font-weight: bold;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  }
  .allianceNav {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    padding: 0px 30px 75px 30px;
    .allianceBody {
      width: 100%;
      height: calc(100vh - 150px);
      background-color: #ffffff;
      box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      overflow-y: auto;
      .navBody {
        width: 100%;
        height: 100%;
        padding: 0 10px;
        .topNav {
          height: 10px;
          display: flex;
          justify-content: space-between;
          .leftBtn {
            margin-top: 20px;
          }
          .rightInp {
            margin-top: 20px;
          }
        }
        .midlleNav {
          margin-top: 70px;
        }
        .footNav {
          position: relative;
          bottom: 40px;
        }
      }
    }
  }
}
</style>
