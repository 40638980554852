<template>
  <div>
    <div id="appBar" class="appBar">
      <div class="amap-box">
        <el-amap
          :events="events"
          :zoom="zoom"
          :center="center"
          :plugin="plugin"
          class="amap-box"
          :vid="'amap-vue'"
        >
          <el-amap-marker
            v-for="(marker, index) in markers"
            :key="index"
            :position="marker.position"
            :events="marker.events"
            :visible="marker.visible"
            :icon="marker.icon"
            :content="marker.template"
            :draggable="marker.draggable"
          />
          <el-amap-polygon
            id="amapPolygon"
            v-for="(polygon, index) in polygons"
            v-if="polygon != []"
            :key="index + '0'"
            :ref="`polygon_${index}`"
            bubble="true"
            :path="polygon.path"
            :stroke-color="polygon.strokeColor"
            :fill-color="polygon.fillColor"
            :fill-opacity="polygon.fillOpacity"
            :draggable="polygon.draggable"
            :events="polygon.events"
          />
        </el-amap>
      </div>
    </div>
    <Warning
      @eliminate="empty"
      :latitudeAndLongitudeStr="latitudeAndLongitudeStr"
      :centerStr="centerStr"
      :dataURL="dataURL"
    />
    <!-- <img
      style="position: fixed; top: 200px; left: 200px"
      :src="dataURL"
      alt=""
    /> -->
  </div>
</template>

<script>
let len = 0; // 缓存的方块个数
let indexes = 0; //  创建的是第几个任务
let area = 0; //  所绘画的多边形面积
let polygon = [];
let center = "";
const turf = require("@turf/turf");
import { mapState, mapMutations } from "vuex";
import Warning from "@/components/warning.vue";
import defaultConstants from "@/utils/default-constants";
import html2canvas from "html2canvas";

export default {
  components: {
    Warning,
  },
  data() {
    const self = this;
    return {
      dataURL: "@/assets/img/chat/select.png",
      latitudeAndLongitudeStr: "", //  经纬度数组集合字符串
      centerStr: "", //  中心点
      legal: false, //  绘制是否合法
      lng: "",
      lat: "",
      markers: [], //  点击地图标点的展示
      zoom: 14,
      center: defaultConstants.mapDefaultLocation,
      polygons: [
        {
          draggable: false,
          strokeColor: "#49B2FF",
          fillColor: "#47ABF5",
          fillOpacity: 0.5,
          path: [],
        },
      ], //  多边形的绑定数据
      events: {
        init(e, mapStyle) {
          e.setMapStyle("amap://styles//macaron"); // 自定义的高德地图的样式，我选的是马卡龙
          self.center = [];
        },
        click(e) {
          const { lng, lat } = e.lnglat;
          self.lng = lng;
          self.lat = lat;
        },
      },
      /**
       * 高德插件
       */
      plugin: [
        {
          pName: "Geolocation",
          events: {
            init(o) {
              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  self.center = [result.position.lng, result.position.lat];
                }
              });
            },
          },
        },
      ],
      airPlay: [], //  多边形的绑定数据
    };
  },
  watch: {
    /**
     * 当lng的值发生变化是，页面新增点位
     */
    lng() {
      this.airPlay = [];
      const obj = {
        position: [this.lng, this.lat],
        events: {},
        visible: true,
        draggable: false,
        template:
          '<div style="width: 8px;height: 8px;background: #000;border: 2px solid #49B2FF;border-radius: 50%;margin: 28px 0 0 6px"></div>',
      };
      this.markers.push(obj);
      this.latitudeAndLongitudeStr =
        this.latitudeAndLongitudeStr === ""
          ? `${this.lng},${this.lat},0`
          : `${this.latitudeAndLongitudeStr};${this.lng},${this.lat},0`;
      if (this.markers.length > 3) {
        this.polygons[indexes] = {
          draggable: false,
          strokeColor: "#49B2FF",
          fillColor: "#47ABF5",
          fillOpacity: 0.5,
          path: [],
        };
        const arr = []; //  用来存储画的多边形的坐标数组
        const lengthNum = this.markers.length;
        for (let i = 0; i < lengthNum; i++) {
          this.polygons[indexes].path[i] = this.markers[i].position;
          arr[i] = this.markers[i].position;
        }
        arr.push(this.markers[0].position);
        polygon = turf.polygon([arr], {
          name: "poly1",
        });
        this.isIntersect(polygon);
      }
    },
  },
  methods: {
    /**
     * 截图
     */
    screenshot() {
      html2canvas(document.getElementById("appBar")).then((canvas) => {
        let imgUrl = canvas.toDataURL("image/png");
        this.dataURL = imgUrl;
      });
    },
    /**
     * 绘制完多边形判断Polygon是否为自相交
     * @param {Object} polygon turfpolygon类型
     */
    isIntersect(polygon) {
      let kinks = turf.kinks(polygon);
      center = turf.centerOfMass(polygon).geometry.coordinates;
      if (kinks.features.length > 0) {
        // 存在自相交情况
        this.$message({
          showClose: true,
          message: "错误，多边形不合法，请顺时针或逆时针绘制坐标点",
          type: "error",
        });
        this.legal = false;
        return;
      }
      // 不存在自相交情况
      this.legal = true;
      this.centerStr = `${center[0]},${center[1]}`;
      setTimeout(() => {
        this.screenshot();
      }, 300);
    },
    /**
     * 清空所绘画的区域
     */
    empty() {
      this.legal = false;
      this.markers = [];
      this.polygons = [];
      this.latitudeAndLongitudeStr = "";
      this.centerStr = "";
    },
  },
  created() {},
  computed: {
    ...mapState(["user", "token"]),
  },
};
</script>

<style lang="less" scoped>
.appBar {
  width: 100%;
  height: calc(100vh - 50px);
  float: left;
  background-color: #f0f0f0;
  .amap-box {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
</style>
