<template>
  <div class="task-push">
    <navTop class="navTop" />
    <div class="left-alert">
      <div class="left-alert-ov iconfont">
        <div class="left-alert-top">
          <el-checkbox
            v-model="checked"
            :title="projectName"
            class="project_name"
            @change="changelog"
            >{{ projectName }}</el-checkbox
          >
          <div v-if="authority" class="cursor alert-top-btn" @click="designate">
            <span>&#xe65b;</span>
            指派
          </div>
          <div
            v-if="authority"
            class="cursor alert-top-btn mass"
            @click="flockSend(true)"
          >
            <span>&#xe65d;</span>
            群发
          </div>
        </div>

        <div v-for="(item, index) in projectData.arr" :key="'0' + index">
          <div style="margin: 19px 0 0 49px">{{ item.block_name }}</div>
          <taskItem
            v-for="(item2, index2) in item.task"
            :key="index + '0' + index2"
            :checkeds="item2.checked"
            :index="index2"
            :index-i="index"
            :block-id="item.id"
            :project-obj="item2"
            :block="item.block_name"
            :authority="authority"
            :nav="nav"
            class="taskItem"
            @taskRedoTaskUpdate="taskRedoTaskUpdate"
            @sonChecked="sonChecked"
            @instructions="instructions"
            @taskListTask="taskListTask"
            @clickConversation="clickConversation"
          />
        </div>
      </div>
    </div>
    <!-- <div class="amap-page-container"> -->
    <el-amap
      ref="map"
      :plugin="plugin"
      :events="events"
      :center="center"
      :zoom="zoom"
      class="amap-demo"
      vid="amap-vue"
      :amap-manager="amapManager"
    >
      <el-amap-polygon
        v-for="(polygon, index) in polygons"
        :key="index"
        :stroke-opacity="0"
        :fill-opacity="0.6"
        fill-color="#367cf3"
        :events="polygonsEvent"
        :vid="index"
        :path="polygon"
      />
      <el-amap-polyline
        stroke-color="#ffff00"
        :editable="false"
        :stroke-weight="2"
        :path="polyline"
      />
      <el-amap-polyline
        stroke-color="#ffff00"
        :editable="false"
        :stroke-weight="1"
        stroke-style="dashed"
        :path="polylines"
      />
      <div class="video" @click="chat">
        <div v-if="chatDialog.unread_num_arr[projectId] > 99" class="unread">
          ···
        </div>
        <div
          v-else-if="chatDialog.unread_num_arr[projectId] > 0"
          class="unread"
        >
          {{ chatDialog.unread_num_arr[projectId] }}
        </div>
      </div>
    </el-amap>
    <el-dialog
      :title="designateVisible == false ? '群发' : '指派'"
      :visible.sync="centerDialogVisible"
      class="el-dialog-wi"
      @close="abolish"
      v-dialogDrag
    >
      <div class="el-dialong-body">
        <div class="el-dialong-div el-dialong-left">
          <div class="el-left-checkbox">
            <div
              v-for="(item, index) in checkedCities"
              :key="item.id"
              class="iconfont cursor"
              @click="clearAway(index)"
            >
              {{ item.unit_name ? item.unit_name : item.name }}
              <span>&#xe621;</span>
            </div>
            <div
              v-if="designateValue"
              class="iconfont cursor"
              @click="clearAway()"
            >
              {{
                designateValue.unit_name
                  ? designateValue.unit_name
                  : designateValue.name
              }}
              <span>&#xe621;</span>
            </div>
          </div>
          <div class="time-text">设置截至时间</div>
          <div class="el-dialong-time">
            <el-date-picker
              v-model="valueDate"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
              class="time"
            />
            <el-time-select
              v-model="valueTime"
              class="time"
              :picker-options="{
                step: '00:15',
                end: '23:45',
              }"
              placeholder="选择时间"
            />
          </div>
          <div class="cursor el-dialong-btn">
            <div @click="selectConfirm">
              确定({{ designateValue != "" ? 1 : checkedCities.length }})
            </div>
            <div class="cancel" @click="abolish">取消</div>
          </div>
        </div>
        <div
          v-if="underling == false && designateVisible == false"
          class="el-dialong-div el-dialong-right"
        >
          <el-input
            v-model="inputData"
            class="el-dialong-input"
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
            @change="flockSend(false)"
            :disabled="underling !== pitchOn"
          />
          <el-checkbox
            v-model="checkAll"
            class="cheak-all"
            :disabled="underling !== pitchOn"
            @change="handleCheckAllChange(cities)"
            >全选</el-checkbox
          >
          <div style="margin: 15px 0" />
          <el-checkbox-group
            v-model="checkedCities"
            class="el-dialong-group"
            @change="handleCheckedCitiesChange(false)"
          >
            <div v-if="cities.length > 0" class="dialog-one-div">
              <el-checkbox
                :label="cities[0]"
                :disabled="underling !== pitchOn"
                class="el-dialong-checkbox"
                >{{ cities[0].unit_name }}</el-checkbox
              >
              <div
                v-if="cities[0].unit_code === user.user_unit.unit_code"
                class="el-dialong-chdiv iconfont cursor"
                @click="subordinate(0)"
              >
                &#xe62f;
              </div>
            </div>
            <div
              v-for="(city, index) in cities.slice(1)"
              :key="city.id"
              class="dialog-one-div"
            >
              <el-checkbox
                :label="city"
                :disabled="underling !== pitchOn"
                class="el-dialong-checkbox"
                >{{ city.unit_name }}</el-checkbox
              >
              <div
                v-if="role === 0 && nav === 2"
                class="el-dialong-chdiv iconfont cursor"
                @click="subordinate(index + 1)"
              >
                &#xe62f;
              </div>
            </div>
          </el-checkbox-group>
        </div>
        <div
          v-if="underling != false && designateVisible == false"
          class="el-dialong-div el-dialong-right"
        >
          <el-input
            v-model="inputDatas"
            class="el-dialong-input"
            placeholder="请输入所需搜索用户"
            suffix-icon="el-icon-search"
            @change="subordinate(selectNum)"
            :disabled="underling !== pitchOn"
          />
          <div
            class="cursor el-dialong-company"
            @click="getBackLeague(), estimateAllChange()"
          >
            <span v-if="role === 0" class="iconfont">&#xe877;</span>
            巡护下用户
          </div>
          <el-checkbox
            v-model="checkAll"
            class="cheak-all"
            :disabled="underling !== pitchOn"
            @change="handleCheckAllChange(underlingArr)"
            >全部</el-checkbox
          >
          <div style="margin: 15px 0" />
          <el-checkbox-group
            v-model="checkedCities"
            class="el-dialong-group"
            @change="handleCheckedCitiesChange(true)"
          >
            <el-checkbox
              v-for="(city, index) in underlingArr"
              :key="index"
              :disabled="underling !== pitchOn"
              class="el-dialong-checkbox"
              :label="city"
              >{{ city.name }}</el-checkbox
            >
          </el-checkbox-group>
        </div>

        <!-- 指定推送 -->
        <div
          v-if="underling == false && designateVisible"
          class="el-dialong-div el-dialong-right"
        >
          <el-input
            v-model="inputData"
            class="el-dialong-input"
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
            @change="flockSend(false)"
          />
          <div class="cheak-all">指派</div>
          <div style="margin: 15px 0" />
          <div class="dialog-one-div">
            <el-radio
              v-model="designateValue"
              class="el-dialong-checkbox"
              :label="cities[0]"
              >{{ cities[0].unit_name }}</el-radio
            >
            <div
              v-if="cities[0].unit_code === user.user_unit.unit_code"
              class="el-dialong-chdiv iconfont cursor"
              @click="subordinate(0)"
            >
              &#xe62f;
            </div>
          </div>
          <div
            v-for="(city, index) in cities.slice(1)"
            :key="city.id"
            class="dialog-one-div"
          >
            <el-radio
              v-model="designateValue"
              class="el-dialong-checkbox"
              :label="city"
              >{{ city.unit_name }}</el-radio
            >
            <div
              v-if="role === 0 && nav === 2"
              class="el-dialong-chdiv iconfont cursor"
              @click="subordinate(index + 1)"
            >
              &#xe62f;
            </div>
          </div>
        </div>
        <div
          v-if="underling != false && designateVisible"
          class="el-dialong-div el-dialong-right"
        >
          <el-input
            v-model="inputDatas"
            class="el-dialong-input"
            placeholder="请输入所需搜索用户"
            suffix-icon="el-icon-search"
            @change="subordinate(selectNum)"
          />
          <div class="cursor el-dialong-company" @click="getBackLeague">
            <span v-if="role === 0" class="iconfont">&#xe877;</span>
            巡护下用户
          </div>
          <div style="margin: 15px 0" />
          <el-radio
            v-for="(city, index) in underlingArr"
            :key="index"
            v-model="designateValue"
            class="el-dialong-checkbox"
            :label="city"
            >{{ city.name }}</el-radio
          >
        </div>
      </div>
    </el-dialog>
    <div v-if="directiveReveal" class="directive">
      <div
        v-for="(item, index) in directiveArr"
        :key="index"
        @click.stop="directivePush(item)"
      >
        <img :src="item.url" alt />
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div
      v-if="Object.keys(parameter).length > 0 && parameterIndex > 0"
      class="task-particulars"
    >
      <div class="particulars-top">
        <div :class="{ bor: parameterIndex === 1 }" @click="meaagseCut(1)">
          任务信息
        </div>
        <div
          :class="{ bor: parameterIndex === 2 }"
          class="cen"
          @click="meaagseCut(2)"
        >
          无人机信息
        </div>
        <div :class="{ bor: parameterIndex === 3 }" @click="meaagseCut(3)">
          终端信息
        </div>
      </div>
      <div class="particulars-body">
        <div v-if="parameterIndex === 1 && nav === 1" class="body-top">
          <div class="div name">
            <div class="task-name" />
            <div class="model">
              <div>
                设备型号:
                <span>{{ parameter.content.plane_type }}</span>
              </div>
              <div style="margin-left: 52px">
                载荷型号:
                <span>{{ parameter.content.camera_type }}</span>
              </div>
            </div>
          </div>
          <div class="div parameter">
            <div style="color: #fff; font-size: 14px">参数</div>
            <div style="color: #fff; font-size: 14px">最高点</div>
            <div style="color: #fff; font-size: 14px">最低点</div>
            <div>
              GSD
              <span>{{ parameter.content.average_ground_resolution }} cm</span>
            </div>
            <div>
              GSD
              <span>{{ parameter.content.high_gsd }}cm</span>
            </div>
            <div>
              GSD
              <span>{{ parameter.content.low_gsd }}cm</span>
            </div>
            <div>
              航向重叠
              <span>{{ parameter.content.longitudinal_overlap }}%</span>
            </div>
            <div>
              航向重叠
              <span>{{ parameter.content.high_longitudinal_overlap }}%</span>
            </div>
            <div>
              航向重叠
              <span>{{ parameter.content.low_longitudinal_overlap }}%</span>
            </div>
            <div>
              旁向重叠
              <span>{{ parameter.content.lateral_overlap }}%</span>
            </div>
            <div>
              旁向重叠
              <span>{{ parameter.content.high_lateral_overlap }}%</span>
            </div>
            <div>
              旁向重叠
              <span>{{ parameter.content.low_lateral_overlap }}%</span>
            </div>
            <div>
              海拔高度
              <span>{{ parameter.content.survey_average_altitude }}m</span>
            </div>
            <div>
              海拔高度
              <span>{{ parameter.content.survey_highest_altitude }}m</span>
            </div>
            <div>
              海拔高度
              <span>{{ parameter.content.survey_lowest_altitude }}m</span>
            </div>
            <div>
              飞行高度
              <span>{{ parameter.content.flight_relative_altitude }}m</span>
            </div>
            <div />
            <div />
            <div>
              比例尺
              <span>{{ parameter.content.work_scale }}</span>
            </div>
          </div>
          <div class="div message">
            <div style="font-size: 14px">信息</div>
            <div class="message-div">
              <div>
                航线间距:
                <span>{{ parameter.content.flight_interval }}m</span>
              </div>
              <div>
                预计时间:
                <span>{{ parameter.content.work_time }}min</span>
              </div>
            </div>
            <div class="message-div">
              <div>
                拍照间距:
                <span>{{ parameter.content.take_pic_interval }}m</span>
              </div>
              <div v-if="parameter.content.work_area">
                预计面积:
                <span>{{ parameter.content.work_area.slice(0, 3) }}km²</span>
              </div>
            </div>
            <div class="message-div">
              <div>
                默认空速:
                <span>{{ parameter.content.default_air_speed }}m/s</span>
              </div>
              <div>
                预计里程:
                <span v-if="parameter.content.total_mileage"
                  >{{ parameter.content.total_mileage.slice(0, 5) }}km</span
                >
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="parameterIndex === 1 && nav === 2" class="div uav">
          <div class="uav-div">
            <div>传感器</div>
            <div>
              <span>{{ parameter.project_content.project.sensor_type }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>授权空域</div>
            <div>
              <span>{{ parameter.project_content.project.auth_area }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>任务性质</div>
            <div>
              <span>{{
                parameter.project_content.project.charge_property
              }}</span>
            </div>
          </div>
          <div
            v-if="parameter.project_content.project.point_cloud_density === ''"
            class="uav-div"
          >
            <div>影像分辨率</div>
            <div>
              <span
                >{{
                  parameter.project_content.project.average_ground_resolution
                }}cm</span
              >
            </div>
          </div>
          <div
            v-if="parameter.project_content.project.point_cloud_density === ''"
            class="uav-div"
          >
            <div>航向重叠度</div>
            <div>
              <span
                >{{
                  parameter.project_content.project.longitudinal_overlap
                }}%</span
              >
            </div>
          </div>
          <div
            v-if="parameter.project_content.project.point_cloud_density === ''"
            class="uav-div"
          >
            <div>旁向重叠度</div>
            <div>
              <span
                >{{ parameter.project_content.project.lateral_overlap }}%</span
              >
            </div>
          </div>
          <div
            v-if="parameter.project_content.project.point_cloud_density !== ''"
            class="uav-div"
          >
            <div>激光点密度</div>
            <div>
              <span
                >{{
                  parameter.project_content.project.point_cloud_density
                }}p/m²</span
              >
            </div>
          </div>
          <div
            v-if="parameter.project_content.project.point_cloud_density !== ''"
            class="uav-div"
          >
            <div>航带重叠度</div>
            <div>
              <span
                >{{ parameter.project_content.project.lateral_overlap }}%</span
              >
            </div>
          </div>
        </div>

        <div
          v-else-if="parameterIndex === 2 && parameter.uav != null"
          class="uav div"
        >
          <div class="uav-div">
            <div>设备ID</div>
            <div>
              <span>{{ parameter.uav.software_code }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>设备型号</div>
            <div>
              <span>{{ parameter.uav.name }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>巡护单位</div>
            <div>
              <span>{{ parameter.uav.unit_name }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>设备状态</div>
            <div>
              <span>{{ statusArr[parameter.uav.status] }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>归属部门</div>
            <div>
              <span>{{ parameter.uav.holder_dept }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>持有人</div>
            <div>
              <span>{{ parameter.uav.holder_name }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>持有人手机号</div>
            <div>
              <span>{{ parameter.uav.holder_phone }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>创建人手机号</div>
            <div>
              <span>{{ parameter.uav.creator_phone }}</span>
            </div>
          </div>
        </div>
        <div
          v-else-if="parameterIndex === 3 && parameter.terminal != null"
          class="div uav"
        >
          <div class="uav-div">
            <div>设备ID</div>
            <div>
              <span>{{ parameter.terminal.device_id }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>巡护单位</div>
            <div>
              <span>{{ parameter.terminal.unit_name }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>设备状态</div>
            <div>
              <span>{{ statusArr[parameter.terminal.status] }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>归属部门</div>
            <div>
              <span>{{ parameter.terminal.holder_dept }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>持有人</div>
            <div>
              <span>{{ parameter.terminal.holder_name }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>持有人手机号</div>
            <div>
              <span>{{ parameter.terminal.holder_phone }}</span>
            </div>
          </div>
          <div class="uav-div">
            <div>使用人手机号</div>
            <div>
              <span>{{ parameter.terminal.use_mobile }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="parameter.iframe_url !== ''"
        class="iconfont play"
        @click="play(parameter.iframe_url)"
      >
        &#xe66c;
      </div>
    </div>
    <newFmTask ref="newFmTask" />
    <!-- <dtuPop v-if="Object.keys(parameter).length > 0" :terminal="parameter.terminal"></dtuPop> -->
    <newTaskPop v-if="newTaskNum" @newFmPop="newFmPop" />
    <!-- 创建音视频组件 -->
    <el-dialog
      class="chat-add-dialog"
      v-dialogDrag
      :modal="false"
      :lock-scroll="false"
      :visible.sync="chatAddDialogVisible"
    >
      <chatAdd
        ref="chatAdd"
        :key="chatAddDialogKey"
        :type="roomActionType"
        :project="chatDialog.project"
      />
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { AMapManager } from "vue-amap";
import chatAdd from "@/components/taskChat/add";
const amapManager = new AMapManager();
let authorityObj = {}; //   点击选中任务推送，判断是否能推送时储存所选任务的数据
const turf = require("@turf/turf");
export default {
  components: {
    chatAdd,
  },
  data() {
    const self = this;
    return {
      selectNum: 0, //  所选巡护
      projectId: "", // 任务id
      projectName: "", //  任务标题
      polygons: [], //  多边形
      polygonsEvent: {
        click() {
          self.parameterIndex = 1;
        },
      }, //  多边形绑定方法
      polyline: [], //  折线
      polylines: [], //  折线
      directiveArr: [
        // { name: '请接单任务', status: 4,url: '../../src/assets/images/指令/1.png' },
        {
          name: "请开始任务",
          status: 6,
          url: require("../../assets/img/instructions/2.png"),
        },
        {
          name: "请尽快放飞",
          status: 8,
          url: require("../../assets/img/instructions/3.png"),
        },
        {
          name: "请立即返航",
          status: 10,
          url: require("../../assets/img/instructions/4.png"),
        },
        {
          name: "请尽快降落",
          status: 12,
          url: require("../../assets/img/instructions/5.png"),
        },
        {
          name: "请结束任务",
          status: 14,
          url: require("../../assets/img/instructions/9.png"),
        },
        {
          name: "请上报简报",
          status: 16,
          url: require("../../assets/img/instructions/7.png"),
        },
        {
          name: "请上报进度",
          status: 18,
          url: require("../../assets/img/instructions/8.png"),
        },
        {
          name: "请回传原始数据",
          status: 20,
          url: require("../../assets/img/instructions/9.png"),
        },
        {
          name: "请回传成果数据",
          status: 22,
          url: require("../../assets/img/instructions/9.png"),
        },
      ], //  指令数组
      statusArr: ["正常", "故障", "设备维修", "设备遗失", "设备报废"], //  设备状态数组
      directiveTaskId: "", //  发送指令id
      directiveStatus: 4, //  当前指令的
      directiveReveal: false, //  指令发送是否显示
      taskIdArr: [], //  选择的任务id列表
      pitchOn: false, //  是否已群发选择下属终端用户
      underling: false, //  判断是否打开巡护下属终端用户
      underlingArr: [1], //  巡护的下属用户
      inputData: "", //  输入搜索的内容
      inputDatas: "", //  巡护用户输入搜索的内容
      checkAll: false, //  全选
      checkedCities: [], //  所选的巡护或终端用户
      cities: ["上海", "北京"],
      valueDate: "", //  截止日期
      valueTime: "", //  截止时间
      amapManager: amapManager,
      zoom: 13,
      center: [121.59996, 31.197646],
      elMap: {},
      googleLayer: {},
      events: {
        init(e, mapStyle) {
          self.googleLayer = new AMap.TileLayer({
            getTileUrl:
              "http://wprd0{1,2,3,4}.is.autonavi.com/appmaptile?&x=[x]&y=[y]&z=[z]&lang=zh_cn&size=1&scl=1&style=6",
            zIndex: 2,
          });
          self.googleLayer.setMap(e);
          e.setMapStyle("amap://styles/dark");
        },
        click() {
          self.parameterIndex = self.parameterIndex === 0 ? 1 : 0;
        },
      },
      /**
       * 高德插件
       */
      plugin: [
        {
          pName: "Geolocation",
          events: {
            init(o) {
              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  self.lng = result.position.lng;
                  self.lat = result.position.lat;
                  self.center = [self.lng, self.lat];
                }
              });
            },
          },
        },
      ],
      centerDialogVisible: false, //  是否显示推送弹框
      designateVisible: false, //  是否为指派类型
      designateValue: "", //  是否为指派类型
      checked: false, //  任务列表全选
      projectData: {}, // 路由传过来的项目数据
      nav: 1, //  飞马还是通用任务
      parameter: {}, //  飞马子任务的详细信息
      parameterIndex: 1, //  飞马子任务详情选选项卡
      identity: false, //  当前选择的推送巡护还是推送用户
      conversationDialogVisible: false,
      chatAddDialogVisible: false,
      chatAddDialogKey: "",
      roomActionType: "",
      authority: 0, //  是否拥有推送群发等权限
      taskPush: {}, // 当前页面
    };
  },
  computed: {
    ...mapState([
      "user",
      "token",
      "role",
      "currentUrl",
      "chatAddDialog",
      "chatDialog",
      "taskWslisten",
      "msgCenter",
      "newTaskNum",
    ]),
  },
  watch: {
    $route: "fetchData",
    // 任务指令监听
    taskWslisten: {
      handler(newVal, oldVal) {
        if (
          newVal.new_msg.wait_handle == 1 &&
          this.projectId == newVal.new_msg.data.project_id
        ) {
          this.taskListTask();
        }
      },
      deep: true,
    },
    // 消息中心监听
    msgCenter: {
      handler(newVal, oldVal) {
        if (
          newVal.new_msg.wait_handle == 1 &&
          this.projectId == newVal.new_msg.data.project.id
        ) {
          this.taskListTask();
        }
      },
      deep: true,
    },
    designateValue() {
      this.identity = this.pitchOn;
    },
    checkedCities() {
      this.identity = this.pitchOn;
    },
  },
  created(data) {
    this.taskRedoTaskUpdate();
  },
  mounted() {
    this.addDirectiveCloseListener(); // 动作指令列表 关闭监听器
  },
  methods: {
    ...mapMutations(["currentUrlStorage", "chatDialogStorage"]),
    /**
     * 路由更新
     */
    fetchData(data) {
      this.taskRedoTaskUpdate();
    },
    /**
     * 弹出新建飞马任务
     */
    newFmPop() {
      this.$refs.newFmTask.reveal();
    },
    /**
     * 刷新当前页面
     */
    taskRedoTaskUpdate() {
      this.nav = parseInt(this.$route.query.nav);
      this.projectId = this.$route.query.id;
      this.projectName = this.$route.query.name;
      if (this.role === 0) {
        this.authority = this.$route.query.push_flag === "admin";
      } else if (this.role === 1) {
        this.authority = this.$route.query.push_flag === "self";
      } else {
        this.authority = false;
      }
      this.taskListTask();
    },
    /**
     * 播放
     */
    play(url) {
      window.open(url);
    },
    /**
     * 信息列表切换
     * @param {NUmber} index
     */
    meaagseCut(index) {
      this.parameterIndex = index;
    },
    /**
     * 聊天未读数
     */
    chatUnreadNum() {
      this.$apis
        .chatUnreadNum({ project_id: this.projectId }, this.token)
        .then((res) => {
          this.chatDialog.unread_num_arr[this.projectId] = res.data.data;
        });
    },
    /**
     * 聊天组件逻辑
     */
    chat() {
      if (this.chatDialog.show) {
        this.$confirm(
          "您当前已打开了一个聊天窗口，是否要关闭当前的聊天窗口？",
          "提示"
        ).then(() => {
          this.chatDialog.init = false;
          this.chatDialog.show = false;

          this.openChatDialog();
        });
      } else {
        this.openChatDialog();
      }
    },
    /**
     * 打开聊天组件 (右下角)
     */
    openChatDialog() {
      this.chatDialog.window = "task";
      this.chatDialog.project.id = this.projectId;
      this.chatDialog.project.name = this.projectName;
      this.chatDialog.task.id = "";
      this.chatDialog.key = new Date().getTime();
      this.chatDialog.show = true;
    },
    /**
     * 接收子组件的音视频/聊天发起请求
     */
    clickConversation(data) {
      this.chatDialog.window = "task";
      this.chatDialog.project.id = this.projectId;
      this.chatDialog.project.name = this.projectName;
      this.chatDialog.task.id = data.task.id;
      this.chatDialog.key = new Date().getTime();
      this.chatDialog.show = true;
    },
    /**
     * 选取任务是任务全选值改变的时候
     * @param {Object} data
     */
    changelog(data) {
      this.getSelection("all", data);
    },
    /**
     * 判断所选中的任务是否可推送
     */
    authorityJudgment(data) {
      if (Number(data.confirm_unit_id) === Number(this.user.unit_id)) {
        authorityObj[data.task_id] = true;
      }
      for (const i in authorityObj) {
        if (!authorityObj[i]) {
          this.authority = false;
          return;
        } else {
          this.authority = true;
        }
      }
    },
    /**
     * 子组件的值发生变化的时候
     */
    sonChecked(data) {
      console.log(data);
      if (this.role === 1 && this.$route.query.push_flag !== "self") {
        authorityObj[data.task_id] = !data.checked;
        this.authorityJudgment(data);
      }
      this.projectData.arr[data.indexI].task[data.index].checked = data.checked;
      if (data.checked) {
        this.taskIdArr.push(data);
        this.checked = true;
        this.getSelection("son");
        this.polygons[0] = data.taskCoordinatePoints;
        let cenArr = [];
        let lengthNum = data.taskCoordinatePoints.length;
        if (data.taskCoordinatePoints[0].lat === undefined) {
          for (let i = 0; i < lengthNum; i++) {
            cenArr[i] = turf.point(data.taskCoordinatePoints[i]);
          }
        } else {
          for (let i = 0; i < lengthNum; i++) {
            cenArr[i] = turf.point([
              data.taskCoordinatePoints[i].lng,
              data.taskCoordinatePoints[i].lat,
            ]);
          }
        }
        cenArr = turf.featureCollection(cenArr);
        this.center = turf.center(cenArr).geometry.coordinates;
        if (Number(this.$route.query.nav) === 1) {
          this.polyline = data.taskCoordinateWaypoints;
          this.polylines = [
            data.taskCoordinateWaypoints[0],
            data.taskCoordinateWaypoints[
              data.taskCoordinateWaypoints.length - 1
            ],
          ];
        }
        this.taskGetTaskDetail(data.task_id);
        // let polygonArea = AMap.GeometryUtil.ringArea(this.polygons[0]);
        this.zoom = 7;
        setTimeout(() => {
          this.zoom = 14;
          // switch (true) {
          //   case polygonArea <= 400000:
          //     this.zoom = 16;
          //     break;
          //   case polygonArea <= 1800000:
          //     this.zoom = 15;
          //     break;
          //   case polygonArea <= 2400000:
          //     this.zoom = 14;
          //     break;
          //   case polygonArea <= 3600000:
          //     this.zoom = 13;
          //     break;
          //   case polygonArea <= 5000000:
          //     this.zoom = 12;
          //     break;
          //   default:
          //     this.zoom = 12;
          //     break;
          // }
        }, 100);
      } else {
        this.checked = false;
        const lengthNum = this.taskIdArr.length;
        const taskArr = this.taskIdArr;
        for (let i = 0; i < lengthNum; i++) {
          if (taskArr[i].task_id === data.task_id) {
            this.taskIdArr.splice(i, 1);
            return;
          }
        }
      }
    },
    /**
     * 飞马任务任务详情
     */
    taskGetTaskDetail(task_id) {
      this.$apis
        .taskGetTaskDetail(
          {
            task_id: task_id,
          },
          this.token
        )
        .then((data) => {
          this.parameterIndex = 1;
          this.parameter = data.data.data;
          if (this.nav === 2) {
            this.parameter.project_content = JSON.parse(
              this.parameter.project_content
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 全选子模块的处理
     */
    getSelection(data, ofBoolean) {
      const lengthNum = this.projectData.arr.length;
      let fn = null;
      if (data === "all") {
        // 当点击顶部任务全选的状态判断
        fn = (i, j) => {
          this.projectData.arr[i].task[j].checked = ofBoolean;
          if (ofBoolean) {
            // const lengthNum = this.taskIdArr.length;
            // this.taskIdArr[lengthNum] = {
            //   task_id: this.projectData.arr[i].task[j].id,
            //   block_id: this.projectData.arr[i].id,
            //   checked: true,
            //   index: j,
            //   status: this.projectData.arr[i].task[j].status
            // };
          } else {
            this.taskIdArr = [];
          }
          // ofBoolean ? this.taskIdArr.push(this.projectData.arr[i].task[j].id) : (this.taskIdArr = []);
        };
      } else {
        // 当子任务是否选中状态判断
        fn = (i, j) => {
          if (!this.projectData.arr[i].task[j].checked) {
            this.checked = false;
            return;
          }
        };
      }
      for (let i = 0; i < lengthNum; i++) {
        for (let j = 0; j < this.projectData.arr[i].task.length; j++) {
          fn(i, j);
        }
      }
    },
    /**
     * 子组件值发生变化时
     * @param {Object} data
     */
    instructions(data) {
      this.directiveTaskId = data.task_id;
      this.directiveStatus = data.status;
      if (this.directiveStatus > 4) {
        this.directiveReveal = !this.directiveReveal;
      } else if (this.directiveStatus === 2) {
        //通知巡护接单通知
        this.$confirm("是否发送请接单推送通知?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        })
          .then(() => {
            this.$apis
              .taskSendCommand(
                {
                  task_id: this.directiveTaskId,
                  status: "已推送联盟",
                },
                this.token
              )
              .then((data) => {
                if (data.data.message === "发送指令错误") {
                  this.utils.$message("推送失败", "error");
                } else {
                  this.utils.$message("推送成功");
                }
              })
              .catch((err) => {
                console.error(err);
              });
          })
          .catch(() => {
            this.utils.$message("取消推送", "info");
          });
      } else if (this.directiveStatus === 4) {
        //通知终端通知
        this.$confirm("是否发送请接单推送通知?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        })
          .then(() => {
            this.$apis
              .taskSendCommand(
                {
                  task_id: this.directiveTaskId,
                  status: "请接单任务",
                },
                this.token
              )
              .then((data) => {
                if (data.data.message === "发送指令错误") {
                  this.utils.$message("推送失败", "error");
                } else {
                  this.utils.$message("推送成功");
                }
              })
              .catch((err) => {
                console.error(err);
              });
          })
          .catch(() => {
            this.utils.$message("取消推送", "info");
          });
      }
    },
    /**
     * 点击巡护的时候选择下属终端用户
     */
    async subordinate(num) {
      // this.inputData = "";
      if (this.checkedCities.length === 0) {
        this.pitchOn = true;
      }
      this.selectNum = num;
      this.underling = true;
      // this.checkedCities = [];
      this.checkAll = false;
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      try {
        const data = await this.$apis.unituserList(
          {
            per_page: 999,
            unit_id: this.cities[num].id,
            keyword: this.inputDatas,
          },
          this.token
        );
        this.underlingArr = data.data.data.data;
        if (!this.designateVisible && this.inputDatas === "") {
          const res = await this.$apis.grouplist(
            {
              page: 1,
              per_page: 999,
              unit_id: this.cities[num].id,
              channel: "web",
            },
            this.token
          );
          if (res.data.status === 0 && res.data.data.data.length > 0) {
            const lengthNum = res.data.data.data.length;
            for (let i = 0; i < lengthNum; i++) {
              this.underlingArr.unshift(res.data.data.data[i]);
            }
          }
        }
        loading.close();
      } catch (err) {
        console.error(err);
        loading.close();
      }
    },
    /**
     * 返回推送巡护
     */
    getBackLeague() {
      if (this.role === 0) {
        this.underling = !this.underling;
        if (this.checkedCities.length === 0) {
          this.pitchOn = false;
        }
      }
    },
    /**
     * 取消当前选中的
     */
    abolish() {
      // this.flockCall = []
      this.checkedCities = [];
      this.centerDialogVisible = false;
      this.checkAll = false;
      this.underling = false;
      this.underlingArr = [];
      this.designateVisible = false;
      this.designateValue = "";
      this.pitchOn = false;
      this.inputData = "";
    },
    /**
     * 删除当前列表选中的
     * @param {Object} index
     */
    clearAway(index) {
      if (index !== undefined) {
        this.checkedCities.splice(index, 1);
        this.estimateAllChange();
      } else {
        this.designateValue = "";
      }
    },
    /**
     * 选择后确定
     */
    selectConfirm() {
      if (this.taskIdArr.length === 0) {
        this.utils.$message("请选择所要推送的任务", "error");
        return;
      }

      if (
        this.valueDate !== "" &&
        this.valueTime !== "" &&
        (this.checkedCities.length > 0 || this.designateValue !== "")
      ) {
        const lengthNum = this.taskIdArr.length;
        for (let i = 0; i < lengthNum; i++) {
          const pushType = this.designateVisible ? 1 : 2;
          const lengthNums = this.checkedCities.length;
          const arrCitie = [];
          for (let j = 0; j < lengthNums; j++) {
            if (
              this.checkedCities[j].mobile === undefined &&
              this.checkedCities[j].unit_pinyin === undefined
            ) {
              arrCitie[arrCitie.length] = this.checkedCities[j];
            }
          }

          if (arrCitie.length > 0) {
            const arr = [...this.checkedCities];
            arr.splice(0, arrCitie.length);
            this.massGroups(pushType, arrCitie, this.taskIdArr[i], arr);
          } else {
            this.identity
              ? this.taskPushToUser(pushType, this.taskIdArr[i])
              : this.taskPushToUnit(pushType, this.taskIdArr[i]);
          }
        }
      } else {
        this.utils.$message("时间必须,所选推送数量不能为0", "error");
      }
    },
    /**
     * 群组下群发
     * @param {Object} pushType  群发指派
     * @param {Object} arr
     * @param {Object} obj
     * @param {Object} arrCitie
     */
    async massGroups(pushType, arrCitie, obj, arr) {
      const dataArr = [...arr];
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      for (let i = 0; i < arr.length; i++) {
        dataArr[i].user_id = dataArr[i].id;
      }
      for (let i = 0; i < arrCitie.length; i++) {
        try {
          const data = await this.$apis.groupuserList(
            {
              group_id: arrCitie[i].id,
            },
            this.token
          );
          if (data.data.status === 0) {
            dataArr.push(...data.data.data);
          }
        } catch (err) {
          console.error(err);
        }
      }
      if (arr.length > 0 || arrCitie.length > 0) {
        const result = [];
        const objs = {};
        for (let i = 0; i < dataArr.length; i++) {
          if (!objs[dataArr[i].user_id]) {
            result.push(dataArr[i]);
            objs[dataArr[i].user_id] = true;
          }
        }
        this.taskPushToUser(pushType, obj, false, result);
      } else {
        this.taskPushToUser(pushType, obj, arrCitie[0].id, dataArr);
      }
      loading.close();
    },
    /**
     * 指派
     */
    designate() {
      this.flockSend(true);
      this.designateVisible = true;
    },
    /**
     * 群发
     */
    flockSend(e) {
      if (this.taskIdArr.length === 0) {
        this.utils.$message("请选择所要推送的任务", "error");
        return;
      }
      if (this.role === 1) {
        for (let i = 0; i < this.taskIdArr.length; i++) {
          if (this.taskIdArr[i].status < 3) {
            if (this.$route.query.push_flag === "admin") {
              this.utils.$message("所要推送的任务中存在未接单任务", "error");
              return;
            }
          }
        }
      }
      if (e) {
        this.$confirm(`确认推送${this.taskIdArr.length}个子任务吗？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.unitLists();
          })
          .catch(() => {
            this.utils.$message("已取消推送", "info");
            this.designateVisible = false;
          });
      } else {
        this.unitLists();
      }
    },
    /**
     * 巡护列表
     */
    unitLists() {
      this.inputDatas = "";
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$apis
        .unitLists(
          {
            channel: "",
            keyword: this.inputData,
            per_page: 999,
          },
          this.token
        )
        .then((data) => {
          loading.close();
          this.centerDialogVisible = true;
          if (data.data.data.data.length === 0) {
            this.utils.$message("未搜索到数据", "info");
            return;
          }
          this.cities = data.data.data.data;
          const lengthNum = this.cities.length;
          for (let i = 0; i < lengthNum; i++) {
            if (this.user.user_unit.unit_name === this.cities[i].unit_name) {
              const obj = this.cities[0];
              this.cities[0] = this.cities[i];
              this.cities[i] = obj;
            }
          }
          if (this.role !== 0) {
            this.subordinate(0);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 群发
     * 全选
     */
    handleCheckAllChange(arr) {
      if (this.checkAll) {
        const lengthNum = arr.length;
        for (let i = 0; i < lengthNum; i++) {
          this.checkedCities.push(arr[i]);
        }
        this.checkedCitiesDeWeight();
      } else {
        this.checkedCities = [];
      }
    },
    /**
     * checkedCities 去重
     */
    checkedCitiesDeWeight() {
      const result = [];
      const objs = {};
      for (let i = 0; i < this.checkedCities.length; i++) {
        if (!objs[this.checkedCities[i].id]) {
          result.push(this.checkedCities[i]);
          objs[this.checkedCities[i].id] = true;
        }
      }
      this.checkedCities = [...result];
    },
    /**
     * 全选状态判断
     */
    estimateAllChange() {
      if (
        (this.checkedCities.length === this.underlingArr.length &&
          this.underling) ||
        (this.checkedCities.length === this.cities.length && !this.underling)
      ) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    },
    /**
     * 选中1
     */
    handleCheckedCitiesChange(verdict) {
      this.estimateAllChange();
    },
    /**
     * 推送巡护api接口
     */
    taskPushToUnit(push_type, obj) {
      const time = this.valueDate + " " + this.valueTime;
      const unit_id = [];
      if (push_type === 2) {
        const lengthNum = this.checkedCities.length;
        for (let i = 0; i < lengthNum; i++) {
          unit_id[i] = this.checkedCities[i].id;
        }
      } else {
        unit_id[0] = this.designateValue.id;
      }
      this.$apis
        .taskPushToUnit(
          {
            project_main_id: this.$route.query.id,
            block_id: obj.block_id,
            task_id: obj.task_id,
            unit_id: unit_id,
            push_type: push_type,
            end_date: time,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status === 0) {
            this.utils.$notifySuccess(
              "提示",
              "推送" + obj.task_id + "任务成功"
            );
            this.centerDialogVisible = false;
            this.taskListTask();
          } else {
            this.utils.$notifyError("提示", data.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 推送用户api接口
     */
    taskPushToUser(push_type, obj, group_id, groupArr) {
      const time = this.valueDate + " " + this.valueTime;
      const user_id = [];

      if (push_type === 2) {
        if (groupArr === undefined) {
          const lengthNum = this.checkedCities.length;
          for (let i = 0; i < lengthNum; i++) {
            user_id[i] = this.checkedCities[i].id;
          }
        } else {
          const lengthNum = groupArr.length;
          for (let i = 0; i < lengthNum; i++) {
            user_id[i] = groupArr[i].user_id;
          }
        }
      } else {
        user_id[0] = this.designateValue.id;
      }
      if (user_id.length === 0) {
        return;
      }

      const dataObj = {
        project_main_id: this.$route.query.id,
        block_id: obj.block_id,
        task_id: obj.task_id,
        user_id: user_id,
        unit_id: this.cities[this.selectNum].id,
        push_type: push_type,
        end_date: time,
      };
      if (group_id !== undefined && group_id !== false) {
        dataObj.group_id = group_id;
      }
      this.$apis
        .taskPushToUser(dataObj, this.token)
        .then((data) => {
          if (data.data.status === 0) {
            this.utils.$notifySuccess(
              "提示",
              "推送" + obj.task_id + "任务成功"
            );
            this.centerDialogVisible = false;
            if (this.$route.query.id !== undefined) {
              this.taskListTask();
            } else {
              window.history.go(-1);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 获取任务下的子任务
     */
    taskListTask() {
      let way = "listFeiMaTask";
      way = this.nav === 1 ? "listFeiMaTask" : "listCommonTask";
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$apis
        .taskListTask(
          {
            project_id: this.$route.query.id,
            sort: "asc",
          },
          this.token,
          way
        )
        .then((data) => {
          const arrObj = this.projectData;
          this.projectData = [];
          loading.close();
          setTimeout(() => {
            arrObj.arr = data.data.data;
            this.projectData = arrObj;
            this.taskIdArr = [];
            this.checked = false;
            this.taskSort();
          }, 100);
        })
        .catch((err) => {
          console.error(err);
          loading.close();
        });
    },
    /**
     * 指令推送
     */
    directivePush(data) {
      this.$apis
        .taskSendCommand(
          {
            task_id: this.directiveTaskId,
            status: data.name,
          },
          this.token
        )
        .then((data) => {
          if (data.data.message === "发送指令错误") {
            this.utils.$message("发送指令错误", "error");
          } else {
            this.utils.$message("发送指令成功");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 子任务排序
     */
    taskSort() {
      if (this.projectData.arr) {
        for (let i = 0; i < this.projectData.arr.length; i++) {
          this.projectData.arr[i].task.sort((a, b) => {
            return a.id - b.id;
          });
        }
      }
    },
    /**
     * 动作指令列表 关闭监听器
     */
    addDirectiveCloseListener() {
      const taskPush = document.querySelector('.task-push')
      taskPush.addEventListener('click', (e)=> {
        this.directiveReveal = false // 关闭动作指令列表
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// /deep/.el-dialog__body {
// 	// display: none;
// 	padding: 0;
// }
.task-push {
  background-color: #f0f2f5;
  height: calc(100% - 35px);
  position: relative;
  .conversationOrderUser {
    position: absolute;
    top: 0;
  }
}

.left-alert-ov::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}

.left-alert {
  width: 30%;
  min-width: 350px;
  height: calc(100% - 60px);
  top: 2.99vw;
  position: absolute;
  left: 29px;
  bottom: 25px;
  background-color: #ebecef;
  box-sizing: border-box;
  overflow: hidden;
  padding-bottom: 40px;
  z-index: 999;

  .left-alert-ov {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-top: 72px;
  }

  .left-alert-top {
    width: 100%;
    height: 72px;
    line-height: 72px;
    border-bottom: 1px solid #dde2ee;
    margin-bottom: 40px;
    box-sizing: border-box;
    padding-left: 15px;
    position: absolute;
    top: 0;
    z-index: 999;
    background-color: #ebecef;

    .project_name {
      width: 50%;
      overflow: hidden;
      // text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .alert-top-btn {
    position: absolute;
    top: 0px;
    right: 90px;
    color: #1182fb;
    span {
      margin-right: -20px;
    }
  }

  .mass {
    right: 20px;
  }

  .taskItem {
    width: 95.155%;
    margin: 14px auto 0;
  }
}

.video {
  position: absolute;
  z-index: 999;
  bottom: 0.5%;
  right: 30px;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background: url("../../assets/img/home／audio／nor.png") no-repeat center;
  .unread {
    background: rgb(255, 92, 94);
    color: #ffffff;
    border-radius: 30px;
    padding: 2px 11px 0px 9px;
    font-weight: bold;
    position: absolute;
    right: 0;
  }
}

.amap-demo {
  position: absolute;
  left: 30px;
  right: 30px;
  top: 3vw;
  height: calc(100% - 95px);
}

.el-dialong-body {
  width: 100%;
  height: 495px;
  border-top: 1px solid #dde2ee;
  display: flex;
}

.el-dialog-wi {
  width: 100%;
  min-width: 600px;
}

.el-dialong-div {
  display: inline-block;
  width: 49%;
  height: 100%;
  flex-direction: column;
  overflow: auto;

  .el-dialong-transfer {
    button {
      position: absolute !important;
      bottom: 0 !important;
    }
    .el-icon-arrow-left {
      display: none !important;
    }
  }

  .cheak-all {
    margin: 40px 49px 0 40px;
  }

  .el-dialong-group {
    padding-bottom: 15px;
  }

  .dialog-one-div {
    position: relative;
  }

  .el-dialong-chdiv {
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #a6aab8;
    font-size: 19px;
    position: absolute;
    bottom: -10px;
    right: 80px;
    text-align: center;
  }

  .el-dialong-checkbox {
    width: 60%;
    display: block;
    margin: 30px 49px 0 40px;
    font-size: 16px;
  }

  .el-dialong-input {
    display: block;
    width: 83.4%;
    margin: 25px auto 0;
  }

  .el-left-checkbox {
    padding-left: 34px;
    padding-top: 10px;
    width: 83.4%;
    height: 310px;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    div {
      padding: 10px;

      span {
        margin-left: 12px;
      }
    }
  }

  .time-text {
    margin-left: 48px;
    margin-bottom: 19px;
  }

  .el-dialong-time {
    display: flex;
    padding: 0 4.67% 0 9.11%;
    .time {
      flex-direction: column;
      margin: 0 1.35%;
    }
  }

  .el-dialong-btn {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    div {
      width: 7.9vw;
      height: 2.4vw;
      line-height: 2.4vw;
      text-align: center;
      background: rgba(17, 130, 251, 1);
      border-radius: 2px;
      // margin-left: 48px;
      color: #fff;
      box-sizing: border-box;
    }
    div:nth-child(2) {
      margin-left: 1.56vw;
      width: 6.7vw;
    }
    .cancel {
      background-color: #fff;
      color: #1182fb;
      border: 1px solid #1182fb;
    }
  }

  .el-dialong-company {
    margin-top: 38px;
    margin-left: 40px;
    span {
      margin-right: 43px;
    }
  }
}
.el-dialong-div::-webkit-scrollbar {
  display: none;
}

.el-dialong-right {
  border-left: 1px solid #dde2ee;
}

.directive {
  position: fixed;
  left: 50.8vw;
  top: 15vh;
  width: 11vw;
  height: 38vh;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  padding-top: 2.2vh;
  padding-bottom: 2.4vh;
  div {
    font-size: 14px;
    width: 100%;
    height: 3.7vh;
    line-height: 3.7vh;
    text-align: left;
    display: flex;
    align-items: center;
    img {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-left: 11.32%;
      margin-right: 11.32%;
    }
  }
  div:hover {
    color: #1182fb;
  }
}

.task-particulars {
  width: 412px;
  height: 68.7vh;
  background: rgba(0, 6, 32, 1);
  position: fixed;
  top: 15vh;
  right: 40px;
  color: #fff;
  font-size: 14px;

  .play {
    font-size: 30px;
    margin-left: 15px;
    margin-top: 6px;
    cursor: pointer;
  }

  .particulars-top {
    display: flex;
    height: 56px;
    line-height: 56px;
    background: rgba(0, 6, 32, 1);
    div {
      width: 24.6%;
      text-align: center;
    }
    .cen {
      margin: 0 auto;
    }
    .bor {
      border-bottom: 2px solid #1782fb;
    }
  }

  .particulars-body {
    height: 57vh;
    background-color: #242f5d;

    .div {
      width: 100%;
      background: rgba(0, 6, 32, 0.63);
      padding-left: 41px;
      box-sizing: border-box;
    }
    .body-top {
      height: 100%;
      .name {
        height: 18.34%;

        .task-name {
          padding-top: 33px;
        }

        .model {
          margin-top: 16px;
          display: flex;
          color: #a6acb9;
          span {
            color: #fff;
          }
        }
      }

      .parameter {
        height: 45.61%;
        margin-top: 10px;
        padding-top: 26px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        div {
          width: 33%;
          color: #a6acb9;
          font-size: 13px;
          span {
            color: #ffffff;
          }
        }
      }

      .message {
        height: 28.57%;
        margin-top: 10px;
        padding-top: 10px;
        box-sizing: border-box;
        font-size: 13px;

        .message-div {
          display: flex;
          margin-top: 21px;
          div {
            width: 45%;
            color: #a6acb9;
            span {
              color: #fff;
            }
          }
        }
      }
    }

    .uav {
      height: 94.3%;
      background: rgba(0, 6, 32, 0.63);
      padding-top: 27px;
      font-size: 13px;
      .uav-div {
        display: flex;
        margin-top: 16px;
        div:nth-child(1) {
          color: #a6acb9;
          width: 96px;
        }
        div:nth-child(2) {
          color: #fff;
        }
      }
    }
  }
}
</style>
