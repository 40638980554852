<template>
  <div class="el-dialong-body">
    <div class="el-dialong-div el-dialong-left">
      <div class="el-left-checkbox">
        <!-- 巡护列表 -->
        <div
          v-for="(item, index) in checkedUnits"
          @click="clearAway(index, 'unit')"
          class="iconfont cursor"
        >
          {{ item.unit_name }}
          <span>&#xe621;</span>
        </div>
        <!-- 群组列表 -->
        <div
          v-for="(item, index) in checkedGroups"
          @click="clearAway(index, 'group')"
          class="iconfont cursor"
        >
          {{ item.name }}
          <span>&#xe621;</span>
        </div>
        <!-- 用户列表 -->
        <div
          v-for="(item, index) in checkedUsers"
          @click="clearAway(index, 'user')"
          class="iconfont cursor"
        >
          {{ item.name }}
          <span>&#xe621;</span>
        </div>
      </div>
      <div class="cursor el-dialong-btn">
        <div @click="selectConfirm">确定({{ checkedUnits.length + checkedGroups.length + checkedUsers.length }})</div>
        <div @click="cancelSelect" class="cancel">取消</div>
      </div>
    </div>
    <div class="el-dialong-div el-dialong-right">
      <div v-if="!isHint">
        <el-input
          class="el-dialong-input"
          placeholder="请输入内容"
          suffix-icon="el-icon-search"
          v-show="currentUnitId == 0"
          v-model="unitSearchInput"
          @input="unitSearchEvent"
        ></el-input>
        <el-input
          class="el-dialong-input"
          placeholder="请输入内容"
          suffix-icon="el-icon-search"
          v-show="currentUnitId != 0"
          v-model="groupUserSearchInput"
          @input="groupUserSearchEvent"
        ></el-input>
        <div v-show="currentUnitId == 0">
          <el-checkbox class="cheak-all" v-model="checkAllUnit">全选</el-checkbox>
          <div style="margin: 15px 0"></div>
          <!-- 巡护选择列表 -->
          <el-checkbox-group class="el-dialong-group" v-model="checkedUnits">
            <div class="dialog-one-div" v-for="(unit, index) in unitList">
              <div v-show="unit.data.show" class="selectUnit" :title="unit.data.unit_name">
                <el-checkbox
                  class="el-dialong-checkbox"
                  :label="unit.data"
                  >{{ unit.data.unit_name }}</el-checkbox
                >
                <div
                  class="el-dialong-chdiv iconfont cursor"
                  @click="subordinate(index)"
                >
                  &#xe62f;
                </div>
              </div>
            </div>
          </el-checkbox-group>
        </div>
      </div>
      <div v-else style="margin:15px;font-size:17px;">当前无活跃的指派或群发人员</div>
      <div v-for="(unit, index) in unitList">
        <div v-show="currentUnitId == unit.data.id">
          <div @click="backUnitList" class="iconfont cursor back-btn">
            &#xe877;
            <span>{{ currentUnitName }}</span>
          </div>
          <el-checkbox class="cheak-all-user" v-model="checkAllGroupAndUser"
            >全选</el-checkbox
          >
          <!-- 群组列表 -->
          <el-checkbox-group class="el-dialong-group" v-model="checkedGroups">
            <div class="dialog-one-div" v-for="group in unit.groupList">
              <el-checkbox
                class="el-dialong-checkbox"
                :label="group"
                v-show="group.show"
              >
                {{ group.name }}
              </el-checkbox>
            </div>
          </el-checkbox-group>
          <!-- 用户列表 -->
          <el-checkbox-group class="el-dialong-group" v-model="checkedUsers">
            <div class="dialog-one-div" v-for="user in unit.userList">
              <el-checkbox
                class="el-dialong-checkbox"
                :label="user"
                v-show="user.show"
              >
                {{ user.name }}
                <i
                  v-if="user.is_admin == 0 && user.is_unit == 1"
                  class="iconfont icon-pre"
                  >&#xe605;</i
                >
              </el-checkbox>
            </div>
          </el-checkbox-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import publicJs from "@/utils/public"

export default {
  data() {
    return {
      isHint: false, //是否提示

      unitSearchInput: "", //巡护搜索框
      groupUserSearchInput: "", //群组/用户搜索框

      checkedUnits: [], //所选的巡护
      checkedGroups: [], //所选的群组
      checkedUsers: [], //所选的用户

      unitList: [
        //巡护列表
        {
          data: {}, //巡护数据
          manager: [], //巡护管理员、超管
          groupList: [], //群组列表
          userList: [], //用户列表
        },
      ],
      currentUnitId: "", //当前选择巡护id 0显示巡护列表 不为0则显示用户列表
      currentUnitName: "",
    };
  },
  created() {
    this.getUnitData();
  },
  methods: {
    /**
     * loading
     */
    showLoading() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      return loading;
    },
    /**
     * 选择用户dialog
     */
    getUnitData() {
      return new Promise((resolve,reject) => {
        this.unitList = [];
        this.currentUnitId = 0;
        //若没数据则获取
        let loading = this.showLoading();
        this.$apis
          .chatListUnit(
            {
              keywords: this.unitSearchInput,
              project_id: this.chatDialog.project.id,
              task_id: this.chatDialog.task.id ? this.chatDialog.task.id : 0
            },
            this.token
          )
          .then((res) => {
            loading.close();

            //组装数据添加
            res.data.data.forEach((item, index) => {
              item.show = true
              //获取巡护下的巡护管理员
              let obj = {
                data: item, //巡护数据
                manager: item.manager, //巡护管理员、超管
                groupList: [], //群组列表
                userList: [], //用户列表
              };
              this.unitList.push(obj);
            });

            if(res.data.data.length == 0) {
              this.isHint = true
            }
            resolve();
          });
      })
    },
    /**
     * 展开巡护下级用户
     */
    async subordinate(index) {
      this.currentUnitId = this.unitList[index].data.id;

      if (this.unitList[index].userList.length == 0) {
        let loading = this.showLoading();
        this.unitList[index].groupList = await this.getGroupData(this.currentUnitId);
        this.unitList[index].userList = await this.getUserData(this.currentUnitId);
        loading.close();
      }

      this.currentUnitName = this.unitList[index].data.unit_name;
    },
    /**
     * 获取群组
     */
    getGroupData(unitId) {
      return new Promise((resolve, reject) => {
        this.$apis
          .chatListGroup(
            {
              keywords: this.groupUserSearchInput,
              channel: "web",
              project_id: this.chatDialog.project.id,
              task_id: this.chatDialog.task.id ? this.chatDialog.task.id : 0,
              unit_id: unitId
            },
            this.token
          )
          .then((res) => {
            let data = res.data.data
            data.forEach(item => {
              item.show = true
            })
            resolve(data);
          })
          .catch((err) => {
            reject();
          });
      });
    },
    /**
     * 获取用户
     */
    getUserData(unitId) {
      return new Promise((resolve, reject) => {
        this.$apis
          .chatListUser(
            {
              keywords: this.groupUserSearchInput,
              channel: "web",
              project_id: this.chatDialog.project.id,
              task_id: this.chatDialog.task.id ? this.chatDialog.task.id : 0,
              unit_id: unitId
            },
            this.token
          )
          .then((res) => {
            let data = res.data.data
            data.forEach(item => {
              item.show = true
            })
            resolve(res.data.data);
          })
          .catch((err) => {
            reject();
          });
      });
    },
    /**
     * 巡护搜索框事件
     */
    unitSearchEvent(search) {
      this.unitList.forEach(item => {
        if(item.data.unit_name.match(search)) {
          item.data.show = true
        }else {
          item.data.show = false
        }
      })
    },
    /**
     * 群组/用户搜索框事件
     */
    groupUserSearchEvent(search) {
      this.currentOpenUnit.groupList.forEach(item => {
        if(item.name.match(search)) {
          item.show = true
        }else {
          item.show = false
        }
      })
      this.currentOpenUnit.userList.forEach(item => {
        if(item.name.match(search)) {
          item.show = true
        }else {
          item.show = false
        }
      })
    },
    /**
     * 返回
     */
    backUnitList() {
      this.groupUserSearchInput = ''
      //还原所有的子项
      this.currentOpenUnit.groupList.forEach(item => {
        item.show = true
      })
      this.currentOpenUnit.userList.forEach(item => {
        item.show = true
      })
      this.currentUnitId = 0
    },
    /**
     * 删除已选的用户选项
     */
    clearAway(index, type) {
      switch (type) {
        case "unit":
          this.checkedUnits.splice(index, 1);
          break;
        case "group":
          this.checkedGroups.splice(index, 1);
          break;
        case "user":
          this.checkedUsers.splice(index, 1);
          break;
      }
    },
    /**
     * 确定
     */
    selectConfirm() {
      let user = []

      let emptyManagerUnit = [];
      // 组装巡护管理员数据
      this.checkedUnits.forEach(unit => {
        if(unit.manager.length == 0) {
          emptyManagerUnit.push(unit)
        }else {
          user = user.concat(unit.manager)
        }
      })

      // 组装群组数据
      this.checkedGroups.forEach(group => {
        user = user.concat(group.user)
      })
      // 组装单选用户数据
      user = user.concat(this.checkedUsers)

      //去重
      let removeRepeatUser = []
      user.forEach((item,index) => {
        let find = removeRepeatUser.find(v => {
          return v.id == item.id
        })
        if(!find) {
          removeRepeatUser.push(item)
        }
      })

      if(emptyManagerUnit.length != 0) {
        let hintUnit = ''
        emptyManagerUnit.forEach(item => {
          hintUnit += item.unit_name + '、'
        })
        let hint = '以下巡护因无管理员，无法邀请:' + hintUnit.substr(0,hintUnit.length-1)

        if(user.length == 0) {
          let unit = emptyManagerUnit[0]
          hint = unit.unit_name + '未绑定或分配巡护管理员或超级管理员，无法添加。'
          publicJs.$message(hint,'error')
          return false
        }

        publicJs.$notifyError('提示',hint,3000)
      }


      this.$emit("confirmSelect",removeRepeatUser);
    },
    /**
     * 取消
     */
    cancelSelect() {
      this.$emit("cancelSelect");
    },
  },
  computed: {
    ...mapState(["user", "token", "chatDialog"]),
    checkAllUnit: {
      get() {
        let showLen = 0
        this.unitList.forEach(item => {
          if(item.data.show) {
            showLen ++
          }
        })
        return this.checkedUnits.length == showLen ? true : false;
      },
      set(bool) {
        this.checkedUnits = [];
        if (bool) {
          this.unitList.forEach((item) => {
            if(item.data.show) {
              this.checkedUnits.push(item.data);
            }
          });
        }
      },
    },
    // 当前展开巡护
    currentOpenUnit() {
      let currentUnit = this.unitList.find((v) => {
        return v.data.id == this.currentUnitId;
      });
      if (!currentUnit) {
        return false;
      }
      return currentUnit;
    },
    checkAllGroupAndUser: {
      get() {
        if (!this.currentOpenUnit) {
          return false;
        }
        // 判断当前展开巡护中所有的群组是否被选中
        let hasGroup = 0;
        let showGroupLen = 0;
        this.currentOpenUnit.groupList.forEach((item) => {
          if(item.show) {
            showGroupLen ++;
            let findIndex = this.checkedGroups.findIndex((v) => {
              return v.id == item.id;
            });
            if (findIndex > -1) {
              hasGroup++;
            }
          }
        });
        // 判断当前展开巡护中所有的用户是否被选中
        let hasUser = 0;
        let showUserLen = 0;
        this.currentOpenUnit.userList.forEach((item) => {
          if(item.show) {
            showUserLen ++;
            let findIndex = this.checkedUsers.findIndex((v) => {
              return v.id == item.id;
            });
            if (findIndex > -1) {
              hasUser++;
            }
          }
        });
        if (hasGroup == showGroupLen && hasUser == showUserLen) {
          return true;
        }
        return false;
      },
      set(bool) {
        this.currentOpenUnit.groupList.forEach(item => {
          if(item.show) {
            let findIndex = this.checkedGroups.findIndex(v => {
              return v.id == item.id
            })
            //全选
            if(bool) {
              //如果没有则加入
              if (findIndex < 0) {
                this.checkedGroups.push(item)
              }
            }else { //取消全选
              //如果有则剔除
              if (findIndex > -1) {
                this.checkedGroups.splice(findIndex,1)
              }
            }
          }
        })
        this.currentOpenUnit.userList.forEach(item => {
          if(item.show) {
            let findIndex = this.checkedUsers.findIndex(v => {
              return v.id == item.id
            })
            //全选
            if(bool) {
              //如果没有则加入
              if (findIndex < 0) {
                this.checkedUsers.push(item)
              }
            }else { //取消全选
              //如果有则剔除
              if (findIndex > -1) {
                this.checkedUsers.splice(findIndex,1)
              }
            }
          }
        })
      },
    },
  },
};
</script>

<style lang="less" scoped>
.el-dialong-body {
  width: 100%;
  height: 495px;
  border-top: 1px solid #dde2ee;
  display: flex;
}

.el-dialog-wi {
  width: 100%;
  min-width: 600px;
}

.el-dialong-div {
  display: inline-block;
  width: 49%;
  height: 100%;
  flex-direction: column;
  overflow: auto;

  .el-dialong-transfer {
    button {
      position: absolute !important;
      bottom: 0 !important;
    }
    .el-icon-arrow-left {
      display: none !important;
    }
  }

  .back-btn {
    margin: 40px;
    font-size: 16px;
  }

  .cheak-all {
    margin: 40px 49px 0 40px;
  }

  .cheak-all-user {
    margin: 0px 49px 0 40px;
  }

  .el-dialong-group {
  }

  .dialog-one-div {
    position: relative;
    .selectUnit {
      display: flex;
      justify-content: space-around;
      // align-items: center;
    }
    /deep/.el-checkbox__inner{
      margin-bottom: 7px;
    }
  }
  /deep/.el-checkbox__label{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .el-dialong-chdiv {
    // width: 40px;
    // height: 40px;
    // line-height: 40px;
    color: #a6aab8;
    font-size: 19px;
    // position: absolute;
    // bottom: -10px;
    // right: 80px;
    text-align: center;
  }

  .el-dialong-checkbox {
    width: 74%;
    display: block;
    // margin: 30px 0px 0 40px;
    margin-left: 40px;
    margin-top: 2px;
    height: 38px;
    font-size: 16px;
    .icon-pre {
      color: #faad14;
      width: 19px;
      height: 17px;
      margin-left: 14px;
    }
  }

  .el-dialong-input {
    display: block;
    width: 83.4%;
    margin: 25px auto 0;
  }

  .el-left-checkbox {
    padding-left: 34px;
    padding-top: 10px;
    width: 83.4%;
    height: 87%;
    overflow: auto;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    div {
      padding: 10px;

      span {
        margin-left: 12px;
      }
    }
  }

  .time-text {
    margin-left: 48px;
    margin-bottom: 19px;
  }

  .el-dialong-time {
    display: flex;
    padding: 0 4.67% 0 9.11%;
    .time {
      flex-direction: column;
      margin: 0 1.35%;
    }
  }

  .el-dialong-btn {
    // margin-top: 167px;
    display: flex;
    justify-content: center;
    div {
      width: 7.9vw;
      height: 2.4vw;
      line-height: 2.4vw;
      text-align: center;
      background: rgba(17, 130, 251, 1);
      border-radius: 2px;
      // margin-left: 48px;
      color: #fff;
      box-sizing: border-box;
    }
    div:nth-child(2) {
      margin-left: 1.56vw;
      width: 6.7vw;
    }
    .cancel {
      background-color: #fff;
      color: #1182fb;
      border: 1px solid #1182fb;
    }
  }

  .el-dialong-company {
    margin-top: 38px;
    margin-left: 40px;
    span {
      margin-right: 43px;
    }
  }
}
.el-dialong-div::-webkit-scrollbar {
  display: none;
}

.el-dialong-right {
  border-left: 1px solid #dde2ee;
}
</style>
