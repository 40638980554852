<template>
  <div id="app">
    <el-menu
      background-color="#112D6D"
      text-color="#fff"
      active-text-color="#fff"
      class="feimarMenu"
      default-active="activeIndex"
      @open="menuOpen"
      @close="menuClose"
      @select="handleSelect"
    >
      <template v-for="side in sidebarConfig">
        <template v-if="side.sideIndex == navMessage">
          <!-- 分组菜单 -->
          <el-menu-item-group v-for="group in side.group" :key="group">
            <template v-if="verifyAuth(group)">
              <template slot="title" v-if="group.name">{{ group.name }}</template>
              <template v-for="menu in group.menu">
                <el-menu-item
                  v-if="verifyAuth(menu)"
                  :key="menu.name"
                  :class="['fmmenuson', { active: currentUrl[2] == menu.name }]"
                  :index="menu.index"
                >
                  <i
                    style="margin-right: 18px"
                    class="iconfont"
                    v-html="menu.icon"
                  ></i>
                  <span slot="title" class="siderTit">{{ menu.name }}</span>
                </el-menu-item>
              </template>
            </template>
          </el-menu-item-group>

          <!-- 普通菜单 -->
          <template v-for="menu in side.menu">
            <el-menu-item
              :key="menu.name"
              :class="['fmmenuson', { active: currentUrl[2] == menu.name }]"
              :index="menu.index"
              @click="menu.callback != null && menu.callback()"
            >
              <div v-if="menu.template" v-html="menu.template()">
              </div>
              <div v-else>
                <i
                  v-if="menu.icon"
                  v-html="menu.icon"
                  style="margin-right: 18px"
                  class="iconfont"
                ></i>
                <img
                  v-if="menu.img"
                  :src="menu.img"
                  style="margin-right: 18px"
                  width="36"
                />
                <span slot="title" class="siderTit">{{ menu.name }}</span>
              </div>
            </el-menu-item>
          </template>

          <!-- 子菜单 -->
          <template v-for="submenu in side.submenu">
            <el-submenu
              class="sonMenu fmmenuson"
              :key="submenu.name"
              :index="submenu.name"
            >
              <template slot="title">
                <i class="iconfont" v-if="submenu.icon" v-html="submenu.icon"></i>
                <img
                  v-if="submenu.img"
                  :src="submenu.img"
                  style="margin-right: 18px"
                  width="36"
                />
                <span class="siderTit">{{ submenu.name }}</span>
              </template>
              <el-menu-item
                v-for="menu in submenu.menu"
                :index="menu.index"
                :class="{ active: currentUrl[2] == menu.name || currentUrl[3] == menu.name }"
                :key="menu.name"
                class="fmmenuson"
                >{{ menu.name }}</el-menu-item
              >
            </el-submenu>
          </template>
        </template>
      </template>

    </el-menu>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import config from "./config.js";
export default {
  data() {
    return {
      activeIndex: "/",
      sidebarConfig: config.sidebarConfig,
    };
  },
  // 检测路由变化
  watch: {
    $route() {
      this.setCurrentRoute();
    },
    newTaskNum() {},
  },
  computed: {
    ...mapState([
      "navMessage",
      "currentUrl",
      "user",
      "token",
      "newTaskNum",
      "orderQty",
    ]),
  },
  created() {
    this.setCurrentRoute();
    config.sendThis(this);
  },
  mounted() {
    //将组件实例this传给config.js
    //config.sendThis(this);
  },
  methods: {
    ...mapMutations(["achivevStorage", "jurisidStorage", "newTaskNumStorage"]),
    /**
     *
     */
    handleSelect(key, keyPath) {
      if (key === "newgeneric") {
        this.newTaskNumStorage(true);
        return;
      }
      this.$router.push({
        path: key,
        params: { data: keyPath },
      });
    },
    setCurrentRoute() {
      this.activeIndex = this.$route.path; // 通过他就可以监听到当前路由状态并激活当前菜单
    },
    verifyAuth(menu) {
      if(!menu.isAdmin && !menu.isUnit) {
        return true
      }
      let bool = false
      //验证超管
      if(menu.isAdmin && this.user.is_admin == 1) {
        bool = true
      }
      //验证联管
      if(menu.isUnit && this.user.is_unit == 1) {
        bool = true
      }
      return bool
    },
    watchachve() {
      this.$emit("watchachve");
    },
    intrTags() {
      this.$emit("intrTags");
    },
    released() {
      this.$emit("released");
    },
    menuOpen(e) {
      return;
    },
    menuClose(e) {
      return;
    },
  },
};
</script>
<style lang="less" scoped>
@import url("~@/assets/iconfont/iconfont.css");
/deep/.el-menu-item-group__title {
  color: #005dc4;
  margin: 20px 0 0 5px;
  font-size: 20px;
}
.active {
  background-color: #0052f7 !important;
}
/deep/.fmmenuson {
  position: relative;
  .number {
    display: inline-block;
    position: absolute;
    right: 0px;
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 20px;
    transform: scale(0.5);
    background: #ff5151;
    border-radius: 50%;
    right: 140px;
    top: 10px;
    text-align: center;
  }
}
/deep/.el-icon-arrow-down {
  right: 100px;
}
/deep/.el-menu-item {
  float: left;
  height: 48px;
  line-height: 48px;
  margin-top: 10px;
}
/deep/.el-menu-item:nth-child(1) {
  margin-top: 2vh;
}
.sonMenu {
  margin-left: 20px;
  border-radius: 28px;
  width: 300px;
  float: left;
}
.feimarMenu {
  width: 260px;
}
.mark {
  margin-left: 10px;
}
.icontitle {
  margin-left: 20px;
}
.el-menu-item {
  margin-left: 20px;
  border-radius: 40px;
  width: 300px;
}
.iconfont {
  color: #fff;
  font-size: 18px;
}
/deep/.el-submenu__title {
  border-radius: 28px;
  height: 48px;
  line-height: 48px;
  margin-top: 10px;
}
.iconfont {
  margin-right: 10px;
}
.zhongduan {
  margin-right: 15px;
  margin-left: 5px;
  font-size: 18px;
}
/deep/.siderTit {
  font-size: 16px;
}
@media screen and (min-width: 1366px) and (max-width: 1920px) {
  .iconfont {
    color: #fff;
    font-size: 17px;
  }
}
@media only screen and (max-width: 1366px) {
  .iconfont {
    color: #fff;
    font-size: 16px;
  }
}
</style>
