// 该文件用于配置一些常量配置，不用于修改
// 操作建议：请尽量不要将此赋值到window对象中，减少DOM层级

export default {
  // 默认位置，该位置为监测院中心
  mapDefaultLocation: [108.344218, 22.824026],
  // 请求API的超时时间-正常
  requestApiTimeoutNormal: 30000,
  // 中国中心点坐标
  chinaCenterPoint: [108.5525, 34.3227]
}

