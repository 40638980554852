<template>
  <!-- 通用飞马任务 -->
  <div class="flying">
    <navTop />

    <div class="cen">
      <div class="nape nape-one nape-top-one">
        <span>任务名称</span>
        <el-input
          v-model="taskName"
          class="import scale"
          placeholder="请输入"
          clearable
        />
      </div>
      <div class="nape nape-two nape-top-one">
        <span>创建时间</span>
        <el-date-picker
          v-model="creationTime"
          class="import scale"
          type="daterange"
          align="right"
          unlink-panels
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </div>
      <button class="inquire btn" type="default" @click="inquire">查询</button>
      <button class="reset btn" type="default" @click="reset">重置</button>
    </div>
    <div class="bottom">
      <div class="all">
        <span style="opacity: 0" />
      </div>
      <div v-for="(item, index) in list" :key="index" class="bottom-content">
        <div
          v-if="item.created_at"
          class="bottom-content-top flex-view"
          @click="ubfoldAlter(index, item.id)"
        >
          <div class="unfold-btn iconfont">
            <!-- {{ unfoldArr[index] ? "-" : "+" }} -->
            <span v-if="unfoldArr[index]">&#xe672;</span>
            <span v-if="!unfoldArr[index]">&#xe671;</span>
          </div>
          <div class="content-top-name" style="color: #1782fb">
            {{ item.project_name }}
          </div>
          <div class="content-top-name">创建人：{{ item.mobile }}</div>
          <div class="content-top-time">
            创建时间：{{ item.created_at.slice(0, 19) }}
          </div>
        </div>

        <div v-if="unfoldArr[index]" class="bottom-content-bot">
          <div v-for="(item2, index2) in item.arr" :key="index2 + '0'">
            <div class="content-list-title">{{ item2.block_name }}</div>
            <el-table ref="filterTable" :data="item2.task" style="width: 100%">
              <el-table-column
                align="center"
                prop="task_name"
                label="任务名称"
                sortable
              />
              <el-table-column
                align="center"
                prop="id"
                label="任务ID"
                sortable
              />
              <el-table-column align="center" label="终端进度">
                <template slot-scope="scope">
                  <div class="state">
                    <span v-if="scope.row.status_name == '已推送联盟'"
                      >巡护待接单</span
                    >
                    <span v-if="scope.row.status_name == '请接单任务'"
                      >终端待接单</span
                    >
                    <span
                      v-if="
                        scope.row.status_name != '已推送联盟' &&
                        scope.row.status_name != '请接单任务'
                      "
                      :class="{ redness: scope.row.status_name == '已拒绝' }"
                      >{{ scope.row.task_push_data.task_last_log_name }}</span
                    >
                  </div>
                </template>
              </el-table-column>

              <el-table-column align="center" label="终端回传">
                <template slot-scope="scope">
                  <div class="instruct-btn iconfont">
                    <span
                      v-if="scope.row.task_data_shp_url !== ''"
                      title="SHP文件"
                      class="shp"
                      @click="getShp(scope.row.task_data_shp_url)"
                    />
                    <span
                      v-if="scope.row.task_brief_count !== 0"
                      title="任务简报"
                      class="task"
                      @click="bulletin(scope.row)"
                    />
                    <span
                      v-if="scope.row.task_data_result_count !== 0"
                      title="成果数据回传"
                      class="original"
                      @click="bacKikc('成果', scope.row.id)"
                    />
                    <span
                      v-if="scope.row.task_data_original_count !== 0"
                      title="原始数据回传"
                      class="achievement"
                      @click="bacKikc('原始', scope.row.id)"
                    />
                  </div>
                </template>
              </el-table-column>

              <el-table-column align="center" prop="tag" label="任务操作">
                <template slot-scope="scope">
                  <div class="postback-btn">
                    <div
                      @click="toPush(item, index, item.id, index2, scope.row)"
                    >
                      详情
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!-- 简报列表 -->
      <el-dialog
        title="简报列表"
        :visible.sync="bulletinVisible"
        width="40.1%"
        center
      >
        <el-table :data="bulletinListArr" @row-click="listRow">
          <el-table-column property="brief_title" label="简报标题" />
          <el-table-column property="project_type" label="任务类型" />
          <el-table-column property="execute_progress" label="进度" />
          <el-table-column property="disaster_level" label="灾情态势" />
          <el-table-column label="操作">
            <el-button size="mini">前往</el-button>
          </el-table-column>
        </el-table>
      </el-dialog>

      <dataBackhaul ref="dataBackhaul" />

      <el-pagination
        class="bottom-el-pagination"
        background
        layout="prev, pager, next"
        :current-page="pageIndex"
        :total="sumPage"
        @current-change="currentChange"
      />
    </div>

    <el-dialog
      title="简报详情"
      :visible.sync="bulletinDetailsVisible"
      width="35.5vw"
      center
    >
      <div class="bulletin-details">
        <div>
          <span>简报标题:</span>
          {{ bulletinDetailsData.brief_title }}
        </div>
        <div>
          <span>任务ID:</span>
          {{ bulletinDetailsData.id }}
        </div>
        <div>
          <span>任务下达类型:</span>
          {{ bulletinDetailsData.project_type }}
        </div>
        <div>
          <span>任务执行进度:</span>
          {{ bulletinDetailsData.execute_progress }}%
        </div>
        <div>
          <span>灾情态势:</span>
          {{ bulletinDetailsData.disaster_level }}
        </div>
        <div></div>
        <div style="width: 100%">
          <span>灾情描述:</span>
          {{ bulletinDetailsData.disaster_description }}
        </div>
        <div style="width: 100%" class="img">
          <span>灾情测区快照</span>
          <img :src="bulletinDetailsData.file_1" alt />
        </div>
        <div style="width: 100%" class="img">
          <span>灾情现场快照</span>
          <img :src="bulletinDetailsData.file_2" alt />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      taskQueryValue: "全部的", //  下拉框选择的
      sumPage: 10, // 总页数
      pageIndex: 1,
      nav: 0, //  页面飞马还是通用
      taskName: "", //  任务名称
      creationTime: "", //  创建时间
      allState: false, //  全部展开
      unfoldArr: [], //  判断是否展开
      list: [], //  任务列表数据
      bulletinVisible: false, //  简报列表弹框
      bulletinListArr: [], // 简报列表数租组
      bulletinDetailsVisible: false, // 简报详情弹框
      bulletinDetailsData: {}, //  简报详情
    };
  },
  mounted() {
    if (JSON.stringify(this.$route.query) !== "{}") {
      this.fetchData(this.$route.query);
    } else {
      const obj = {
        nav: "feima",
      };
      this.fetchData(obj);
    }
  },
  computed: {
    ...mapState(["user", "token", "role", "currentUrl", "ifPortal"]),
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    /**
     * 查看回传数据
     */
    bacKikc(str, id) {
      this.$message({
        showClose: true,
        message: "对不起，您没有下载该数据的权限",
        type: "warning",
      });
    },
    /**
     * 获取shp文件
     */
    getShp(url) {
      window.open(url);
    },
    /**
     * 查看任务简报列表
     */
    bulletin(item) {
      this.$apis
        .taskGetTaskBriefList(
          {
            task_id: item.id,
          },
          this.token
        )
        .then((data) => {
          this.bulletinListArr = data.data.data;
          this.bulletinVisible = true;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 简报列表某一行被点击时会触发事件
     */
    listRow(e) {
      this.$apis
        .taskGetTaskBriefDetail(
          {
            task_brief_id: e.id,
          },
          this.token
        )
        .then((data) => {
          this.bulletinVisible = false;
          this.bulletinDetailsVisible = true;
          this.bulletinDetailsData = data.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 新建任务
     */
    newTask() {
      this.$router.push({
        path: "/newgeneric",
      });
    },
    /**
     * 下拉框出现的时候
     */
    dropDown(data) {
      if (data && this.TypeArr.length === 0) {
        this.$apis
          .taskPushType({}, this.token)
          .then((data) => {
            this.TypeArr = data.data.data;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    /**
     * 点击重置
     */
    reset() {
      this.taskName = "";
      this.creationTime = ["", ""];
    },
    /**
     * 点击查询
     */
    inquire() {
      this.pageIndex = 1;
      this.antiShake(this.taskList, 0);
    },
    /**
     * 概述，前往推送页面
     * @param {Object} item
     */
    toPush(item, index, id, index2, obj) {
      if (item.arr) {
        this.$router.push({
          path: "/taskDetails",
          query: {
            id: id,
            nav: this.nav,
            name: item.project_name,
            mobile: item.mobile,
            push_flag: item.push_flag,
          },
        });
      } else {
        this.taskListTask(index, id, "/blockTaskPush");
      }
    },
    /**
     * 获取项目列表
     */
    taskList(num = 0) {
      let way = "listFeiMa";
      way = this.nav === 1 ? "listFeiMa" : "listCommon";
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const parameter = {
        page: this.pageIndex,
        type: this.nav,
        status: 0,
        project_name: this.taskName,
        created_at_from: this.creationTime === null ? "" : this.creationTime[0],
        created_at_to: this.creationTime === null ? "" : this.creationTime[1],
        user_level: num,
      };
      this.$apis
        .portalListProject(parameter, this.token, way)
        .then((data) => {
          this.sumPage = data.data.data.last_page * 10;
          this.pageIndex = data.data.data.current_page;
          this.list = [];
          this.list = data.data.data.data;
          const lengthNum = this.list.length;
          for (let i = 0; i < lengthNum; i++) {
            this.unfoldArr.push(false);
          }
          loading.close();
        })
        .catch((err) => {
          console.error(err);
          loading.close();
        });
    },
    /**
     * 当前页码发生变化时
     */
    currentChange(e) {
      this.pageIndex = e;
      this.antiShake(this.taskList, 0);
    },
    /**
     * 全选状态判断
     */
    allUnfold() {
      for (let i = 0; i < this.unfoldArr.length; i++) {
        if (!this.unfoldArr[i]) {
          this.allState = false;
          return false;
        }
        this.allState = true;
      }
    },
    /**
     * 查看全部任务，待接单任务选择
     * @param {number} index\
     */
    taskSelect(index) {
      this.choice = index;
      this.antiShake(this.taskList, 0);
    },
    /**
     * 总全部展开
     */
    stateAmend() {
      this.allState = !this.allState;
      this.unfoldArr = [];
      setTimeout(() => {
        for (let i = 0; i < this.list.length; i++) {
          this.unfoldArr.push(this.allState);
        }
      }, 100);
    },
    /**
     * 列表每一项的全部展开
     * @param {number} index
     */
    ubfoldAlter(index, id) {
      if (!this.list[index].arr) {
        this.taskListTask(index, id);
      } else {
        this.unfoldArr.splice(index, 1, !this.unfoldArr[index]);
        this.allUnfold();
      }
    },
    /**
     * 获取子任务
     * @param {number} index
     * @param {number} id
     */
    taskListTask(index, id, pushUrl) {
      let way = "listFeiMaTask";
      way = this.nav === 1 ? "listFeiMaTask" : "listCommonTask";
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$apis
        .portalListTask(
          {
            project_id: id,
            sort: "asc",
          },
          this.token,
          way
        )
        .then((data) => {
          this.list[index].arr = data.data.data;
          loading.close();
          if (pushUrl) {
            this.$router.push({
              path: "/blockTaskPush",
              query: {
                id: id,
                nav: this.nav,
                name: this.list[index].project_name,
                mobile: this.list[index].mobile,
                push_flag: this.list[index].push_flag,
              },
            });
          } else {
            this.unfoldArr.splice(index, 1, !this.unfoldArr[index]);
            setTimeout(() => {
              this.allUnfold();
            }, 100);
          }
        })
        .catch((err) => {
          console.error(err);
          loading.close();
        });
    },
    /**
     * 页面防抖函数
     * @param {fundtion} fn
     * @param {object} parameter
     */
    antiShake(fn, parameter) {
      fn(parameter);
    },
    /**
     * @param {Object} data
     * 判断路由的路径
     */
    fetchData(data) {
      let dataNav = "";
      if (data.nav) {
        dataNav = data.nav;
      } else {
        dataNav = data.query.nav;
      }
      if (dataNav !== "feima") {
        this.nav = 2;
        this.currentUrlStorage(["首页", "应急任务", "通用任务"]);
      } else {
        this.nav = 1;
        this.currentUrlStorage(["首页", "应急任务", "航线任务"]);
      }
    },
  },
  watch: {
    $route: "fetchData",
    nav() {
      this.antiShake(this.taskList, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/iconfont/iconfont.css";
.flying {
  padding: 0px 40px 40px 40px;
  background-color: #f0f0f0;
}
.top {
  margin-left: 30px;
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  span {
    color: rgba(0, 0, 0, 0.65);
  }
}

.nav {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 2vh;
  div {
    width: 18.4vw;
    max-width: 304px;
    max-height: 138px;
    height: 8.3vw;
    line-height: 8.3vw;
    background: rgba(255, 255, 255, 1);
    border-radius: 6.4px;
    margin-right: 1.5vw;
    box-sizing: border-box;
    text-align: center;
    font-size: 19.2px;
    position: relative;
  }

  div:nth-child(1) {
    border-bottom: 0px solid #7c6dea;
    box-shadow: 0px 4px 12px 0px rgba(194, 156, 81, 0.23);
  }
  div:nth-child(2) {
    border-bottom: 0px solid #ff4444;
    box-shadow: 0px 4px 12px 0px rgba(216, 115, 115, 0.16);
  }
  div:nth-child(3) {
    border-bottom: 0px solid #9bceb9;
    box-shadow: 0px 4px 12px 0px rgba(155, 206, 185, 0.28);
  }
  div:nth-child(4) {
    border-bottom: 0px solid #36c98d;
    box-shadow: 0px 4px 12px 0px rgba(147, 147, 190, 0.29);
  }

  .image {
    width: 3.96vw;
    height: 3.96vw;
    max-width: 65.6px;
    max-height: 65.6px;
    left: 2.216vw;
  }
}

@mixin scale($scale, $navSize) {
  .scale {
    transform: scale($scale, $scale);
  }

  .posi {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: $navSize;
  }
}

@include scale($scale: 1, $navSize: 26px);

@media screen and(max-width: 1680px) {
  @include scale($scale: 0.95, $navSize: 26px);
}
@media screen and(max-width: 1550px) {
  @include scale($scale: 0.9, $navSize: 22px);
}
@media screen and(max-width: 1450px) {
  @include scale($scale: 0.85, $navSize: 20px);
}
@media screen and(max-width: 1300px) {
  @include scale($scale: 0.8, $navSize: 18px);
}

.cen {
  width: 100%;
  height: 160px;
  position: relative;
  margin: 0 auto;
  box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
  background-color: #fff;
  margin-bottom: 2vh;
  .nape {
    position: absolute;
    display: inline-block;
    width: 26%; // 332
    text-align: right;
    span {
      padding-right: 2%;
      color: #838a9d;
      font-size: 14px;
    }
    .import {
      display: inline-block;
      width: 66%;
      height: 40px;
    }
  }
  .nape-one {
    right: 72.3125%;
  }
  .nape-two {
    right: 46.9375%;
  }
  .nape-top-one {
    top: 35.2px;
  }

  .btn {
    position: absolute;
    bottom: 2.5vw;
    width: 112px;
    height: 40px;
    border-radius: 2px;
    border: 0;
    font-size: 16px;
  }

  .inquire {
    color: #fff;
    background-color: #1182fb;
    right: 11vw;
  }
  .reset {
    right: 2.95vw;
    color: #1182fb;
    background-color: #fff;
    border: 1px solid #1182fb;
  }
}

.bottom {
  padding: 0 3.8vw 168px;
  margin-bottom: 30px;
  position: relative;
  background-color: #fff;
  min-height: 750px;

  .all {
    cursor: pointer;
    user-select: none;
    margin: 24px 22px;
    font-size: 0.843vw;
    width: 120px;

    span {
      display: inline-block;
      width: 17px;
      height: 17px;
      line-height: 17px;
      border-radius: 2px;
      border: 1px solid rgba(131, 138, 157, 1);
      text-align: center;
    }
  }

  .bottom-content {
    line-height: 44.8px;
    border-radius: 2px;
    border: 1px solid rgba(221, 226, 238, 1);
    margin-bottom: 1.5vw;

    .flex-view {
      display: flex;
      div {
        flex-direction: column;
        width: 16.66667%;
        text-align: center;
      }
    }

    .unfold-btn {
      width: 13.6px !important;
      // height: 13.6px !important;
      line-height: 13.6px;
      border-radius: 2px;
      // border: 1px solid rgba(131, 138, 157, 1);
      text-align: center;
      margin: 15.2px 0 15.2px 17.6px;
      cursor: pointer;
      user-select: none;
      box-sizing: border-box;
      display: flex;
      span {
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
      }
    }

    .bottom-content-top {
      background: rgba(221, 230, 239, 1);
      color: #3c4353;
      font-size: 0.84vw;
      height: 44.8px;
      .content-top-name {
        width: 20%;
        font-size: 14px;
      }
      .content-top-time {
        width: 28%;
        font-size: 14px;
      }
    }

    .bottom-content-bot {
      .content-list {
        padding-left: 2.6vw;
        height: 48px;
        line-height: 48px;
        div {
          font-size: 14px;
        }
      }
      .content-list-title {
        height: 48px;
        line-height: 48px;
        color: rgba(0, 0, 0, 0.65);
        padding-left: 52px;
        border-top: 1px solid rgba(221, 226, 238, 1);
        border-bottom: 1px solid rgba(221, 226, 238, 1);
        font-size: 16px;
        font-weight: 700;
      }
      .state {
        span {
          display: inline-block;
          height: 28px;
          line-height: 28px;
          background: rgba(246, 255, 237, 1);
          border-radius: 2px;
          border: 1px solid #b7eb8f;
          padding: 0 12px;
          color: #52c41a;
          font-size: 12px;
        }
      }

      .instruct-btn {
        width: 100%;
        span {
          width: 21.66%;
          display: inline-block;
          height: 24px;
          line-height: 60%;
          margin-top: 10px;
        }
        .shp {
          border-radius: 2px;
          background: url("../../../assets/img/SHP.png") no-repeat center
            center;
          background-size: 75% 100%;
        }
        .task {
          width: 33.3%;
          border-radius: 2px;
          background: url("../../../assets/img/mission_brief.png") no-repeat center
            center;
          background-size: 75% 100%;
        }
        .original {
          border-radius: 2px;
          background: url("../../../assets/img/achievements.png") no-repeat center
            center;
          background-size: 75% 100%;
        }

        .achievement {
          border-radius: 2px;
          background: url("../../../assets/img/raw_data.png") no-repeat center
            center;
          background-size: 75% 100%;
        }
      }

      .redness {
        border-color: #ffa39e !important;
        color: #ffa39e;
      }

      .postback-btn {
        color: #1182fb;
        cursor: pointer;
        user-select: none;
        div {
          display: inline-block;
          width: 40px;
        }
      }
    }
  }
  .bottom-el-pagination {
    position: absolute;
    right: 18%;
    bottom: 56px;
  }
}

.bulletin-details {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  span {
    opacity: 0.8;
    margin-right: 15px;
    // display: block;
    color: #3c4353;
    position: absolute;
    left: 0;
    text-align: right;
    width: 4.9vw;
  }
  div {
    color: #838a9d;
    margin: 20px 0;
    width: 49%;
    position: relative;
    padding-left: 6vw;
    font-size: 13px;
  }
  .img {
    width: 280px;
    height: 280px;
    img {
      display: inline-block;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.refresh-btn {
  transition: All 0.4s ease-in-out;
  -webkit-transition: All 0.4s ease-in-out;
  -moz-transition: All 0.4s ease-in-out;
  -o-transition: All 0.4s ease-in-out;
  .iconfont {
    cursor: pointer;
    font-size: 30px;
  }
}
.refresh-btn:hover {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}
</style>
