<template>
  <div class="disclaimer">

      <div>
        <div class="statement">
          通过无人机巡护指挥调度管理平台接受任务的测绘资质单位，请自行做好飞行计划安排并确保安全飞行，平台不提供空域保障和飞行计划报批等相关服务。本网站对无人机用户单位（公司）出现的任何损害情况不承担任何法律责任。
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      disStatemenetData: false, //免责声明弹窗
    }
  },
  mounted(){
  }
}
</script>

<style lang="scss" scoped>
.disclaimer{
  /deep/.el-dialog__header{
  padding-bottom: 0px;
}
.statement {
  line-height: 31px;
  text-indent:24px;
  text-align:justify;
  text-justify:inter-ideograph;
  margin-top: -13px;
}
}

</style>
