<template>
  <div>
    <el-dialog
      :modal="false"
      :show-close="true"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="372px"
      v-dialogDrag
    >
      <div class="topBar">
        <div class="headerImg">
          <img class="headImg" :src="avatarUrl" alt />
          <div class="userInfo">
            <span class="username">{{ this.detailObj.name }}</span>
            <i v-if="this.detailObj.is_admin == 1" class="iconfont icon-VIP"
              >&#xe632;</i
            >
            <i
              v-if="this.detailObj.is_admin == 0 && this.detailObj.is_unit == 1"
              class="iconfont icon-pre"
              >&#xe605;</i
            >
          </div>
          <div v-if="this.detailObj.is_admin == 1" class="userBar">
            {{ overpipe }}
          </div>
          <div
            v-if="this.detailObj.is_admin == 0 && this.detailObj.is_unit == 1"
            class="userBarOne"
          >
            {{ unionpipe }}
          </div>
          <div
            v-if="this.detailObj.is_admin == 0 && this.detailObj.is_unit == 0"
            class="userBarTwo"
          >
            {{ terminaluser }}
          </div>
          <div class="user">
            <span class="userTit">账号</span>
            <span class="userNum">{{ this.detailObj.mobile }}</span>
          </div>
          <div class="user">
            <span class="userTit">账户类型</span>
            <span class="userNum">{{ this.detailObj.register_channel }}</span>
          </div>
          <div class="user">
            <span class="userTit">巡护单位</span>
            <span class="userLocal userNum">
              {{ this.detailList.unit_name }}&nbsp;({{
                this.detailList.unit_code
              }})
            </span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState,mapMutations } from "vuex";
export default {
  props: {
    detailObj: {
      type: Object,
      default: {}
    },
    detailList: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      dialogVisible: false,
      overpipe: "超级管理员",
      unionpipe: "巡护管理员",
      terminaluser: "巡护终端用户",
      taskgroup: "任务群组管理员",
      avatarUrl: require("@/assets/img/user-default-head-big.png")
    };
  },
  methods: {
    open () {
      this.dialogVisible=true;
    }
  },
  computed: {
    ...mapState(["user","token"])
  }
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  height: 278px;
  margin-top: 28vh !important;
}
.topBar {
  width: 100%;
  height: 102px;
  background: url("/assets/img/nor.png");
}
/deep/.el-dialog__header .el-dialog__body {
  padding: 0px;
}
/deep/.el-dialog__body {
  padding: 0px;
}
/deep/.el-dialog__header {
  padding: 0px;
}
.headerImg {
  .headImg {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin-top: 11px;
    margin-left: 40px;
    float: left;
  }
}
.userInfo {
  width: 200px;
  float: left;
  height: 102px;
  line-height: 80px;
  margin-left: 45px;
  font-size: 20px;
}
.icon-VIP {
  font-size: 14px;
  color: #1182fb;
  line-height: 80px;
  margin-left: 5px;
  cursor: pointer;
}
.icon-pre {
  font-size: 14px;
  color: #faad14;
  line-height: 80px;
  margin-left: 5px;
  cursor: pointer;
}
.userBar {
  color: #fff;
  position: absolute;
  left: 85px;
  top: 70px;
  width: 90px;
  height: 20px;
  border-radius: 3px;
  background-color: #1862ff;
  display: flex;
  justify-content: center;
  line-height: 20px;
  font-size: 12px;
  margin-left: 75px;
}
.userBarOne {
  color: #3c4353;
  position: absolute;
  left: 85px;
  top: 70px;
  width: 90px;
  height: 20px;
  border-radius: 3px;
  background-color: #ffc000;
  display: flex;
  justify-content: center;
  line-height: 20px;
  font-size: 12px;
  margin-left: 75px;
}
.userBarTwo {
  color: #3c4353;
  position: absolute;
  left: 85px;
  top: 70px;
  width: 90px;
  height: 20px;
  border-radius: 3px;
  background-color: #59fa9c;
  display: flex;
  justify-content: center;
  line-height: 20px;
  font-size: 12px;
  margin-left: 75px;
}
.user {
  width: 100%;
  margin-top: 25px;
  float: left;
  margin-left: 50px;
  .userTit {
    font-size: 14px;
    color: #a6aab8;
    float: left;
  }
  .userNum {
    width: 260px;
    margin-left: 50px;
    float: right;
  }
  .userLocal {
    margin-bottom: 5px;
  }
}
</style>