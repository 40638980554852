<template>
  <el-dialog
    title="数据回传"
    :visible.sync="dialogVisible"
    v-dialogDrag
    width="34.33%"
    :before-close="shut"
  >
    <div class="uploadInput">
      <input
        type="file"
        multiple="multiple"
        webkitRelativePath
        ref="file"
        @change="aetherupload"
        :disabled="num !== uploadingNum"
      />
      <span>点击上传单个/多个文件，或将单个/多个文件拖拽到这里</span>
    </div>
    <div class="statistics">
      <div>
        上传总文件：<span>{{ sum }}</span>
      </div>
      <div class="be-uploading">
        <div>
          正在上传中：<span>{{ num }}</span
          >/{{ uploadingNum }}
        </div>
        <div></div>
      </div>
    </div>
    <div class="postback-table">
      <el-table
        :data="tableData"
        height="362"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          background: '#DDE6EF',
          color: 'rgba(0, 0, 0, 0.65)',
        }"
      >
        <el-table-column prop="name" label="文件名" width="240">
        </el-table-column>
        <el-table-column prop="size" label="大小"> </el-table-column>
        <el-table-column prop="address" label="状态" width="100">
        </el-table-column>
      </el-table>
    </div>

    <div class="btn">
      <el-button
        type="primary"
        @click="cancel"
        :disabled="num !== uploadingNum"
        style="margin-right: 24px"
        >确定</el-button
      >
      <el-button
        :disabled="num !== uploadingNum"
        @click="cancel"
        style="margin-right: 36px"
        >取消</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
let files = {};
import { mapState, mapMutations } from "vuex";
export default {
  name: "subcharacters",
  data() {
    return {
      dialogVisible: true,
      sum: 0, //   上传总数量
      num: 0, //   进行中的数量
      uploadingNum: 0, //   进行中的总数量
      tableData: [],
    };
  },
  computed: {
    ...mapState(["currentUrl", "token", "role"]),
  },
  created() {},
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    /**
     * 弹框点击关闭触发
     */
    shut() {
      if (this.num !== this.uploadingNum) {
        this.$confirm("回传任务暂未完成,确认关闭?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.cancel();
          })
          .catch(() => {});
      } else {
        this.cancel();
      }
    },
    /**
     * 选取上传文件
     */
    aetherupload(e) {
      files = e.target.files;
      this.uploadingNum = files.length;
      this.num = 0;
      for (let key in files) {
        let size = files[key].size;
        if (size !== undefined) {
          size = this.sizeTransition(size);
          this.tableData.push({
            name: files[key].name,
            size: size,
            address: "上传中",
          });
          this.sum = this.sum + 1;
          // this.num = this.num - 1;
          this.fileTransfer(files[key], this.tableData.length - 1);
        }
      }
    },
    /**
     * 请求前转换
     */
    fileTransfer(file, index) {
      let obj = {
        action: "upload",
        task_id: this.obj.id,
        type: this.obj.name,
        size: file.size,
      };
      let formData = new FormData();
      formData.append("file", file, file.name);
      for (let keys in obj) {
        formData.append(keys, obj[keys]);
      }
      this.taskPostDataBack(formData, index);
    },
    /**
     * 转换文件大小
     */
    sizeTransition(size) {
      if (size > 1024 * 1024 * 1024) {
        size = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
      } else if (size > 1024 * 1024) {
        size = (size / (1024 * 1024)).toFixed(2) + "MB";
      } else if (size > 1024) {
        size = (size / 1024).toFixed(2) + "KB";
      } else {
        size = size + "B";
      }
      return size;
    },
    /**
     * 回传文件
     */
    taskPostDataBack(formData, index) {
      this.$apis
        .taskPostDataBack(formData, this.token)
        .then((data) => {
         
          this.num = this.num + 1;
          this.tableData[index].address =
            data.data.status === 0 ? "已完成" : "失败";
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 取消按钮，关闭回传弹框
     */
    cancel() {
      this.$emit("postbackCancel");
    },
  },
  props: {
    obj: {
      type: Object,
      data: "",
    },
  },
};
</script>

<style lang="scss" scoped>
// .el-table__body-wrapper::-webkit-scrollbar {
//   /*width: 0;宽度为0隐藏*/
//   width: 2px;
// }
// .el-table__body-wrapper::-webkit-scrollbar-thumb {
//   border-radius: 2px;
//   height: 50px;
//   background: #eee;
// }
// .el-table__body-wrapper::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//   border-radius: 2px;
//   background: rgba(0, 0, 0, 0.4);
// }

.top-segmentation {
  width: 100%;
  height: 1px;
  background-color: #dde2ee;
}
.uploadInput {
  width: 90%;
  height: 130px;
  background: url(../assets/img/file.png) no-repeat center 25px;
  background-size: 40px 40px;
  border-radius: 1px;
  border: 1px dashed rgba(0, 0, 0, 0.15);
  position: relative;
  margin: 0 auto;
  span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 28px;
    font-size: 14px;
    color: #838a9d;
    width: 100%;
    text-align: center;
    font-size: 10px;
  }
  input {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.statistics {
  width: 90%;
  margin: 25px auto 14px;
  border-top: 1px dashed rgba(166, 170, 184, 0.6);
  padding-top: 25px;
  color: rgba(0, 0, 0, 0.65);
  span {
    color: rgba(23, 130, 251, 1);
  }
}

.postback-table {
  width: 90%;
  margin-left: 5%;
  margin-bottom: 40px;
}

.btn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
</style>
