<template>
  <el-dialog
    :title="obj.name"
    :visible.sync="dialogVisible"
    v-dialogDrag
    width="46.875%"
    :before-close="shut"
  >
    <div class="top-segmentation"></div>
    <div class="cen-right">
      <div class="cen-right-top">
        <el-checkbox v-model="checkAll" @change="allChange"
          >全部选择</el-checkbox
        >
      </div>
      <div v-if="checkedCitiesArr.length > 0" class="cen-right-body">
        <div
          v-for="(item, index) in checkedCitiesArr"
          :key="item.name"
          class="option"
        >
          <el-checkbox
            v-model="checkAllArr[index]"
            @change="allListChange(item.name, index)"
            >{{ item.name }}</el-checkbox
          >
          <div class="division" />
          <el-checkbox-group v-model="permissionsArr[index]">
            <el-checkbox
              v-for="(city, num) in checkedCitiesArr[index].list"
              :key="city.code"
              :label="city"
              style="margin: 10px  4%; 0 0"
              @change="GroupChange(checkedCitiesArr[index], index, num)"
              >{{ city.name }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer subcharacters-ben">
      <el-button class="btn" type="primary" @click="preserve">保存</el-button>
      <el-button
        class="btn"
        style="color: #1182fb; border: 1px solid #1182fb"
        @click="shut"
        >取 消</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "subcharacters",
  data() {
    return {
      checkAll: false, //  全部按钮全选绑定
      checkedCitiesArr: [], //  服务区返回的权限数组
      permissionsArr: [], //  用户点击选择的权限数组
      checkAllArr: [], //  全选按钮数组
      dialogVisible: true,
    };
  },
  computed: {
    ...mapState(["currentUrl", "token", "role"]),
  },
  created() {
    this.permissionsGet();
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    /**
     * 取消
     */
    shut() {
      this.$emit("shut");
    },

    /**
     * 获取权限数据
     */
    permissionsGet(index) {
      this.$apis
        .permissionsGet(
          { action: "child", type: "api", role_child_id: this.obj.id },
          this.token
        )
        .then((data) => {
          setTimeout(() => {
            this.checkedCitiesArr = data.data.data.system_permissions;
            const arr = JSON.parse(
              data.data.data.role_permissions_data.permissions
            );
            let lengthSum = this.checkedCitiesArr.length;
            for (let i = 0; i < lengthSum; i++) {
              this.permissionsArr.push([]);
              this.checkAllArr.push(false);
              const lengthNum = this.checkedCitiesArr[i].list.length;
              if (arr !== null) {
                for (let j = 0; j < lengthNum; j++) {
                  if (
                    arr.indexOf(this.checkedCitiesArr[i].list[j].code) != -1
                  ) {
                    this.permissionsArr[i].push(
                      this.checkedCitiesArr[i].list[j]
                    );
                    if (this.permissionsArr[i].length === lengthNum) {
                      this.checkAllArr[i] = true;
                      if (i === lengthSum - 1) {
                        this.allJudge();
                      }
                    }
                  }
                }
              } else {
                this.checkAll = false;
              }
            }
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 总全选按钮状态判断
     */
    allJudge() {
      const lengthNum = this.checkAllArr.length;
      for (let i = 0; i < lengthNum; i++) {
        if (this.checkAllArr[i] == false) {
          this.checkAll = false;
          return false;
        }
      }
      this.checkAll = true;
    },
    /**
     * 全部按钮全选
     * @param {Object} val
     */
    allChange(val) {
      if (val) {
        const lengthNum = this.checkAllArr.length;
        for (let i = 0; i < lengthNum; i++) {
          this.checkAllArr[i] = true;
          this.permissionsArr[i] = this.checkedCitiesArr[i].list;
        }
      } else {
        const lengthNum = this.checkAllArr.length;
        for (let i = 0; i < lengthNum; i++) {
          this.checkAllArr[i] = false;
          this.permissionsArr[i] = [];
          this.checkAll = false;
        }
      }
    },
    /**
     * list全选
     * @param {Object} val
     */
    allListChange(val, index, num) {
      if (this.checkAllArr[index] != false) {
        this.checkAllArr[index] = true;
        this.permissionsArr[index] = this.checkedCitiesArr[index].list;
        this.allJudge();
      } else {
        this.checkAllArr[index] = false;
        this.permissionsArr[index] = [];
        this.checkAll = false;
      }
    },
    /**
     * 按钮状态管理
     * @param {Object} val
     */
    GroupChange(val, index, num) {
      if (val.list.length == this.permissionsArr[index].length) {
        this.checkAllArr[index] = true;
        this.allJudge();
      } else {
        this.checkAllArr[index] = false;
        this.checkAll = false;
      }
    },
    /**
     * 保存按钮
     */
    preserve() {
      const arr = [];
      const lengthNum = this.permissionsArr.length;
      for (let i = 0; i < lengthNum; i++) {
        const arrData = this.permissionsArr[i];
        for (let j = 0; j < arrData.length; j++) {
          arr.push(arrData[j].code);
        }
      }
      this.$apis
        .permissionsSave(
          {
            action: "child",
            role_child_id: this.obj.id,
            permissions: arr,
          },
          this.token
        )
        .then((data) => {
          if (data.data.message == "权限保存成功！") {
            this.utils.$message("权限保存成功！");
            this.shut();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  props: {
    obj: {
      type: Object,
      data: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.top-segmentation {
  width: 100%;
  height: 1px;
  background-color: #dde2ee;
}

.cen-right {
  width: 88.4%;
  box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
  background-color: #fff;
  margin-left: 36px;
  min-height: 95vh;

  .cen-right-top {
    padding-bottom: 17px;
    border-bottom: 2px solid #dde2ef;
    margin-top: 40px;
  }

  .cen-right-body {
    margin-left: 2.34%;
  }

  .option {
    margin-top: 43px;
  }

  .division {
    margin: 22px 0;
    width: 100%;
    border-bottom: 2px solid #dde2ef;
  }
}

.subcharacters-ben {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-51%);
  width: 40%;
  height: 72px;
  line-height: 72px;
  background-color: #fff;
  z-index: 999;

  .btn {
    margin-right: 24px;
  }
}
</style>
