var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "centerWindow" },
    [
      _c("div", { staticClass: "loginBgc" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "fmLoginNav" }, [
          _c("div", { staticClass: "loginNav" }, [
            _c(
              "div",
              { staticClass: "loginBox" },
              [
                _c("p", { staticClass: "loginTitle" }, [
                  _vm._v("巡护代码登录"),
                ]),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    attrs: {
                      model: _vm.ruleForm,
                      rules: _vm.rules,
                      "label-width": "100px",
                    },
                  },
                  [
                    _vm.showWarning
                      ? _c("el-alert", {
                          attrs: {
                            title: "如忘记密码，请联系超级管理员。",
                            type: "warning",
                            "show-icon": "",
                            closable: false,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "demo-ruleForm",
                        attrs: { prop: "unit_code" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入巡护代码" },
                          model: {
                            value: _vm.ruleForm.unit_code,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "unit_code", $$v)
                            },
                            expression: "ruleForm.unit_code",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "demo-ruleForm",
                        attrs: { prop: "unit_password" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入密码",
                            type: "password",
                          },
                          model: {
                            value: _vm.ruleForm.unit_password,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "unit_password", $$v)
                            },
                            expression: "ruleForm.unit_password",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { staticClass: "SignInBar" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "loginBar",
                            attrs: { type: "primary", loading: _vm.loading },
                            on: { click: _vm.userLogin },
                          },
                          [_vm._v("登  录")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-form-item", { staticClass: "demo-ruleForm" }, [
                      _c("div", { staticClass: "otherLogin" }, [
                        _c("div", { staticClass: "leftBoder" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "FMword",
                            on: { click: _vm.phoneLogin },
                          },
                          [_vm._v("手机号登录")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "rightBoder" }),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "footerBar" },
                  [
                    _c(
                      "el-link",
                      {
                        staticClass: "register",
                        attrs: { underline: false },
                        on: { click: _vm.registerNum },
                      },
                      [_vm._v("注册账号")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "dividing" }),
                    _vm._v(" "),
                    _c(
                      "el-link",
                      {
                        staticClass: "forgetPsw",
                        attrs: { underline: false },
                        on: { click: _vm.changeReg },
                      },
                      [_vm._v("忘记巡护代码密码")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "免责声明",
            visible: _vm.disStatemenetData,
            width: "302px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.disStatemenetData = $event
            },
          },
        },
        [
          _c("disclaimer", { attrs: { show: _vm.disStatemenetData } }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.disStatemenetData = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fmWelcome" }, [
      _c("div", { staticClass: "fmWelLogin" }, [
        _c("p", { staticClass: "sayHello" }, [_vm._v("您好,")]),
        _vm._v(" "),
        _c("p", { staticClass: "welLogin" }, [_vm._v("欢迎登录")]),
        _vm._v(" "),
        _c("p", { staticClass: "fmsider" }),
        _vm._v(" "),
        _c("p", { staticClass: "fmTitle" }, [
          _vm._v("无人机巡护指挥调度管理平台"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }