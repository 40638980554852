
let vm = null;

const sendThis = (_this) => {
    vm = _this;
};

export default {
    sendThis, //暴露函数
    sidebarConfig: [
        {
            sideIndex: 0,
            menu: [
                {
                    name: '新建任务',
                    index: 'newgeneric',
                    img: require('@/assets/img/newTask.png'),
                },
                {
                    name: '航线任务',
                    index: '/flying?nav=feima',
                    img: require('@/assets/img/routesTask.png')
                },
                {
                    name: '通用任务',
                    index: '/flying?nav=gm',
                    img: require('@/assets/img/commonTask.png')
                },
                {
                    name: '区块任务',
                    index: '/flying?nav=block',
                    img: require('@/assets/img/planTask/blockTask.png')
                },
            ]
        },
        {
            sideIndex: 1,
            menu: [
                {
                    name: '无人机',
                    index: '/deviceManagement?type=uav',
                    img: require('@/assets/img/sideNav/uav.png'),
                },
                {
                    name: '测绘装备',
                    index: '/deviceManagement?type=mapping',
                    img: require('@/assets/img/sideNav/mapping.png'),
                }
            ],
            submenu: [
                {
                    name: '终端设备',
                    img: require('@/assets/img/sideNav/terminal.png'),
                    menu: [
                        {
                            name: '林业巡护',
                            index: '/deviceManagement?type=terminal',
                        },
                    ]
                },
            ]
        },
        {
            sideIndex: 2,
            menu: [
                {
                    name: '巡护单位',
                    index: '/alliance?nav=danwei',
                    icon: '&#xe627;'
                },
                {
                    name: '巡护终端用户',
                    index: '/terminal?nav=zhongduanuser',
                    icon: '&#xe629;'
                },
                {
                    name: '门户网站用户',
                    index: '/portal?nav=portal',
                    icon: '&#xe66b;'
                },
                {
                    name: '排行榜',
                    index: '/ranking?nav=paihang',
                    icon: '&#xe626;'
                }
            ]
        },

        {
            sideIndex: 3,
            menu: [
                {
                    name: '火情管理',
                    index: '/achievement',
                    icon: '&#xe61c;',
                    callback: function () {
                        vm.watchachve()
                    }
                },
                {
                    name: '兴趣标注',
                    index: '/achievement',
                    icon: '&#xe61b;',
                    callback: function () {
                        vm.intrTags()
                    }
                },
            ],
            submenu: [
                {
                    name: '数据发布',
                    icon: '&#xe617;',
                    menu: [
                        {
                            name: '待发布',
                            index: '/released?nav=stay',
                        },
                        {
                            name: '已发布',
                            index: '/publish?nav=released',
                        },
                    ]
                },
            ]
        },

        {
            sideIndex: 4,
            group: [
                {
                    name: '空域查询',
                    menu: [
                        {
                            name: '空域查询',
                            index: '/airspaceQuery?nav=query',
                            icon: '&#xe63f;'
                        },
                        {
                            name: '已申请空域',
                            index: '/airspaceApplied?nav=applied',
                            icon: '&#xe641;',
                            isAdmin: true,
                            isUnit: true,
                        },
                    ]
                },
                {
                    name: '审批空域',
                    isAdmin: true,
                    menu: [
                        {
                            name: '待审批空域',
                            index: '/pendingApprova?nav=approva',
                            icon: '&#xe640;'
                        },
                        {
                            name: '已审批空域',
                            index: '/approved?nav=approved',
                            icon: '&#xe640;'
                        },
                    ]
                },
                {
                    name: '空域档案',
                    isAdmin: true,
                    isUnit: true,
                    menu: [
                        {
                            name: '加载授权空域',
                            index: '/authorizedAirspace?nav=authorized',
                            icon: '&#xe644;'
                        },
                        {
                            name: '授权空域编目',
                            index: '/airspaceCataloging?nav=cataloging',
                            icon: '&#xe631;'
                        },
                    ]
                }
            ]
        },

        {
            sideIndex: 5,
            menu: [
                // {
                //     name: '任务统计',
                //     index: '/taskstat?nav=renwusta',
                //     icon: '&#xe622;'
                // },
                // {
                //     name: '用户统计',
                //     index: '/userstat?nav=usersta',
                //     icon: '&#xe624;'
                // },
                {
                    name: '飞行统计',
                    index: '/flystat?nav=flysta',
                    icon: '&#xe61f;'
                },
            ]
        },

        {
            sideIndex: 6,
            menu: [
                {
                    name: '个人信息',
                    index: '/information?nav=infor',
                    icon: '&#xe61f;'
                },
                {
                    name: '账户设置',
                    index: '/setting?nav=set',
                    icon: '&#xe61f;'
                },
            ]
        },

        {
            sideIndex: 7,
            menu: [
                {
                    name: '待办任务',
                    index: '/taskpage?nav=taskp',
                    icon: '&#xe619;'
                },
                {
                    name: '标准动作指令',
                    index: '/actionpage?nav=action',
                    icon: '&#xe61a;'
                },
                {
                    name: 'SHP文件',
                    index: '/shpage?nav=shp',
                    icon: '&#xe620;'
                }
            ]
        },

        {
            sideIndex: 8,
            menu: [
                {
                    name: '权限管理',
                    index: '/permissionManagement',
                    icon: '&#xe66a;'
                },
                {
                    name: '配置管理',
                    index: '/configuration',
                    icon: '&#xe613;'
                },
            ]
        },

        {
            sideIndex: 9,
            menu: [
                {
                    name: '发布任务',
                    index: '/taskCoordination',
                    template: function () {
                        let img = require('@/assets/img/sideNav/publish.png')
                        return `<img src="` + img + `" alt="" width="24" />
                        <span slot="title" class="siderTit">发布任务</span>`;
                    },
                },
                {
                    name: '火情上报',
                    index: '/fireReporting',
                    template: function () {
                        let img = require('@/assets/img/newTask.png')
                        return `<img src="` + img + `" alt="" width="24" />
                        <span slot="title" class="siderTit">火情上报</span>`;
                    },
                },
                {
                    name: '全部任务',
                    index: '/taskList?type=all',
                    template: function () {
                        let img = require('@/assets/img/sideNav/allTask.png')
                        return `<img src="` + img + `" alt="" width="24" />
                        <span slot="title" class="siderTit">全部任务</span>`;
                    },
                },
                {
                    name: '待核任务',
                    index: '/taskList?type=toAudit',
                    template: function () {
                        let img = require('@/assets/img/sideNav/toAuditTask.png')
                        let tem = `<img src="` + img + `" alt="" width="24" />
                        <span slot="title" class="siderTit">待核任务</span>`;
                        if (vm.orderQty.status_2 !== 0) {
                            tem += `<span class="number">` + vm.orderQty.status_2 + `</span>`
                        }
                        return tem
                    },
                },
                {
                    name: '核发任务',
                    index: '/taskList?type=published',
                    template: function () {
                        let img = require('@/assets/img/sideNav/publishedTask.png')
                        let tem = `<img src="` + img + `" alt="" width="24" />
                        <span slot="title" class="siderTit">核发任务</span>`;
                        if (vm.orderQty.status_3 !== 0) {
                            tem += `<span class="number">` + vm.orderQty.status_3 + `</span>`
                        }
                        return tem
                    },
                },
                {
                    name: '退回任务',
                    index: '/taskList?type=back',
                    template: function () {
                        let img = require('@/assets/img/sideNav/backTask.png')
                        let tem = `<img src="` + img + `" alt="" width="24" />
                        <span slot="title" class="siderTit">退回任务</span>`;
                        if (vm.orderQty.status_4 !== 0) {
                            tem += `<span class="number">` + vm.orderQty.status_4 + `</span>`
                        }
                        return tem
                    },
                },
                {
                    name: '草拟任务',
                    index: '/taskList?type=draft',
                    template: function () {
                        let img = require('@/assets/img/sideNav/draftTask.png')
                        let tem = `<img src="` + img + `" alt="" width="24" />
                        <span slot="title" class="siderTit">草拟任务</span>`;
                        if (vm.orderQty.status_1 !== 0) {
                            tem += `<span class="number">` + vm.orderQty.status_1 + `</span>`
                        }
                        return tem
                    },
                },
                {
                    name: '任务派单',
                    index: '/taskManager?nav=block',
                    template: function () {
                        let img = require('@/assets/img/planTask/blockTask.png')
                        return `<img src="` + img + `" alt="" width="24" />
                        <span slot="title" class="siderTit">任务派单</span>`;
                    },
                },
            ]

        }

    ]
}
