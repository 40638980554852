<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      title="删除"
      v-dialogDrag
    >
      <span class="titleBar"
        >确定要将&nbsp;&nbsp;&nbsp;&nbsp;{{
          this.groupUser.name
        }}&nbsp;&nbsp;&nbsp;&nbsp;从任务群组中删除吗？</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button class="footBtn" type="primary" @click="delBtn"
          >确 定</el-button
        >
        <el-button class="footBtn" @click="dialogVisible = false"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  props: {
    groupUser: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    openDelEdit() {
      this.dialogVisible = true;
    },
    // 确定删除
    delBtn() {
      const userArr = [this.groupUser.user_id];
      this.$apis
        .delgroupuser(
          {
            user_id: userArr,
            group_id: this.groupUser.group_id,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status == 0) {
            this.$emit("refresh");
            this.$message({
              message: data.data.message,
              type: "success",
            });
          } else {
            this.$message({
              message: data.data.message,
              type: "error",
            });
          }
          this.dialogVisible = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  computed: {
    ...mapState(["user", "token"]),
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  width: 308px !important;
  height: 200px !important;
  margin-top: 38vh !important;
}
/deep/ .el-dialog__footer {
  margin-top: 20px !important;
  padding-right: 50px;
}
.footBtn {
  width: 96px;
  height: 36px;
  margin-left: 10px;
}
.titleBar {
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 20px;
  line-height: 25px;
}
/deep/.el-dialog__body {
  padding: 0px 20px;
}
</style>