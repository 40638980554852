<template>
  <div>
    <el-dialog
      :show-close="false"
      :visible.sync="this.loginVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="bodyBar">
        <div class="loginLogo">
          <img src="@/assets/img/new-logo.png" alt width="60px"/>
        </div>
        <div class="loginTit">
          <span class="loginword">无人机巡护指挥调度管理平台</span>
        </div>
        <div class="getawaytit">
          <span class="getaword">【公众门户网站】</span>
        </div>
        <el-form
          ref="ruleForm"
          class="loginInput"
          :model="ruleForm"
          :rules="rules"
          label-width="100px"
        >
          <el-form-item prop="mobile" class="demo-ruleForm">
            <el-input v-model="ruleForm.mobile" placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item prop="password" class="demo-ruleForm">
            <el-input
              v-model="ruleForm.password"
              placeholder="请输入密码"
              type="password"
            />
          </el-form-item>
          <el-form-item class="SignInBar">
            <el-checkbox v-model="agreeStatement"
                  ><span style="color:rgb(96, 98, 102)">我已同意并认真阅读</span><a href="#" @click="lookStatement" style="color:#5185ff">《免责声明》</a></el-checkbox
                >
            <el-button
              :loading="loading"
              @click="loginBtn"
              class="loginBar"
              type="primary"
              :disabled="!agreeStatement"
              >登&nbsp;&nbsp;录</el-button
            >
          </el-form-item>
        </el-form>
        <div class="footerBar">
          <div class="option">
            <el-link class="register" :underline="false" @click="registerNum"
              >注册账号</el-link
            >
            <div class="dividing" />
            <el-link class="forgetPsw" :underline="false" @click="forgetPsw"
              >忘记密码</el-link
            >
          </div>
          <div class="versions">{{ versions }}</div>
        </div>
      </div>
    </el-dialog>
    <Register ref="register" />
    <div class="disclaimer">
      <el-dialog title="免责声明" :visible.sync="disStatemenetData" width="300px" top="25vh" center>
      <disclaimer :show="disStatemenetData" />
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="disStatemenetData = false;agreeStatement = false">取 消</el-button> -->
        <el-button type="primary" @click="disStatemenetData = false;">确 定</el-button>
      </span>
    </el-dialog>
    </div>

  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Register from "./registerDialog";
import disclaimer from "@/components/statemenet/disclaimer"
export default {
  components: { Register,disclaimer },
  data() {
    return {
      versions: process.env.VUE_APP_VERSION,
      loading: false,
      ruleForm: {
        mobile: "",
        password: "",
      },
      rules: {
        mobile: [
          { required: true, message: "手机号不存在", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确手机号", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, message: "最小六位", trigger: "blur" },
        ],
      },
      agreeStatement: false, //同意免责声明
      disStatemenetData: false, //免责声明弹窗
    };
  },
  methods: {
    ...mapMutations([
      "userStorage",
      "tokenStorage",
      "loginStorage",
      "registerStorage",
      "forgetStorage",
    ]),
    /**
     * 查看免责声明
     */
    lookStatement() {
      console.log(1234);
      this.disStatemenetData = true
    },
    registerNum() {
      this.loginStorage(false);
      this.registerStorage(true);
    },
    forgetPsw() {
      this.loginStorage(false);
      this.forgetStorage(true);
    },
    // 登陆按钮
    loginBtn() {
      // if (this.agreeStatement){
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            this.loading = true;
            this.submitData();
            this.$router.push("/login");
            setTimeout(() => {
              this.$emit("getHome");
            }, 3000);
          } else {
            this.loading = false;
          }
        });
      // }

    },
    submitData() {
      this.$apis
        .login({
          mobile: this.ruleForm.mobile,
          password: this.ruleForm.password,
          register_channel: "门户网站用户",
        })
        .then((data) => {
          if (data.data.status == 0) {
            this.$message({
              message: data.data.message,
              type: "success",
              offset: 80,
            });
            this.loading = false;
            this.loginStorage(false);
          } else {
            this.loading = false;
          }
          this.tokenStorage(data.data.data.token);
          localStorage.setItem('TokenKeyToUser',data.data.data.token);
          this.$apis.info({}, this.token).then((userData) => {
            localStorage.setItem('userInfo',JSON.stringify(userData.data.data));
            this.userStorage(userData.data.data);
            setTimeout(() => {
              this.$router.go(0);
            }, 100);
          });
          this.$emit("loginSuccess");
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  created() {
    // enter事件
    const that = this;
    document.onkeydown = function (e) {
      e = window.event || e;
      // 验证在登录界面和按得键是回车键enter
      if (that.loginVisible == true && e.keyCode == 13) {
        that.loginBtn(); // 登录函数
      }
    };
  },
  computed: {
    ...mapState(["user", "token", "loginVisible"]),
  },
};
</script>

<style lang="less" scoped>
.bodyBar {
  width: 358px;
  height: 450px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  .loginLogo {
    float: left;
    width: 100%;
  }
  .loginTit {
    float: left;
    width: 100%;
    margin-top: 10px;
    .loginword {
      font-size: 16px;
      color: #3c4353;
    }
  }
  .getawaytit {
    float: left;
    width: 100%;
    margin-top: 12px;
    .getaword {
      font-size: 14px;
      color: #006efe;
    }
  }
}
.loginInput {
  float: left;
  width: 100%;
  margin-top: 46px;
}
.demo-ruleForm {
  width: 316px;
  margin: 0px auto 37px auto;
}
.SignInBar {
  .loginBar {
    font-size: 16px;
    width: 316px;
    height: 40px;
    border-radius: 4px;
    // background: linear-gradient(
    //   225deg,
    //   rgba(0, 110, 254, 1) 0%,
    //   rgba(73, 74, 255, 1) 70%
    // );
  }
}
.footerBar {
  width: 370px;
  height: 100%;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .option {
    display: flex;
    justify-content: center;
  }
}
.register {
  cursor: pointer;
  color: #006efe;
}
.dividing {
  height: 16px;
  width: 2px;
  background-color: #8099bb;
  margin: 0 20px 0 20px;
}
.forgetPsw {
  cursor: pointer;
  color: #006efe;
}
/deep/.el-dialog {
  width: 400px;
  height: 508px;
  border-radius: 8px;
  border: 1px solid #a4aab9;
}
/deep/.el-dialog__body {
  padding: 10px 10px;
}

.disclaimer {
  /deep/.el-dialog{
    height: 285px;
  }
  /deep/.el-dialog__header{
    padding-bottom: 0px;
  }
  /deep/.el-dialog__footer {
    padding-top: 0;
  }
}
</style>
