import { render, staticRenderFns } from "./drawer.vue?vue&type=template&id=1f743988&scoped=true&"
import script from "./drawer.vue?vue&type=script&lang=js&"
export * from "./drawer.vue?vue&type=script&lang=js&"
import style0 from "./drawer.vue?vue&type=style&index=0&id=1f743988&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f743988",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/www/wwwroot/xhddgl.afuav.com/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f743988')) {
      api.createRecord('1f743988', component.options)
    } else {
      api.reload('1f743988', component.options)
    }
    module.hot.accept("./drawer.vue?vue&type=template&id=1f743988&scoped=true&", function () {
      api.rerender('1f743988', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/messageBox/drawer.vue"
export default component.exports