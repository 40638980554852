<template>
  <div>
    <el-dialog
      title="精确筛选"
      :visible.sync="dialogVisible"
      @click="turnon"
      class="taskBox"
      :close-on-click-modal="false"
      :modal="false"
      v-dialogDrag
    >
      <el-divider></el-divider>
      <div class="detailBar">
        <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
          <el-form-item label="巡护单位">
            <el-select
              v-model="ruleForm.unit_id"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="(item, index) in unitList"
                :key="index"
                :label="item.unit_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!--  -->
          <el-form-item label="任务类型">
            <el-select v-model="ruleForm.task_type" placeholder="请选择">
              <el-option label="航线任务" value="1"></el-option>
              <el-option label="通用任务" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任务名称">
            <el-input
              v-model="ruleForm.task_name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="数据名称">
            <el-input
              v-model="ruleForm.result_name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="数据格式">
            <el-select v-model="ruleForm.result_type" placeholder="请选择">
              <el-option label="Tileset" value="TILESET"></el-option>
              <el-option label="Imagery" value="IMAGERY"></el-option>
              <el-option label="Terrain" value="TERRAIN"></el-option>
              <el-option label="Poi" value="POI"></el-option>
              <el-option label="Model" value="MODEL"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="type == 'poi'" label="兴趣点名称">
            <el-input
              v-model="ruleForm.poi_name"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="type == 'poi'" label="兴趣点类型">
            <el-select
              v-model="ruleForm.poi_type"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="(item, index) in interestTypeList"
                :key="index"
                :label="item.data_interest_type"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <el-divider></el-divider>
      <span slot="footer" class="dialog-footer">
        <el-button @click="searchBtn" type="primary">查 询</el-button>
        <el-button @click="resetForm('ruleForm')" type="primary" plain
          >重 置</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  props: ["type"],
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        unit_id: "", //单位
        result_name: "",
        result_type: "",
        task_name: "",
        task_type: "",
        poi_name: "",
        poi_type: "",
      },
      unitList: [], // 巡护列表
      interestTypeList: [], //兴趣点类型列表
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    turnon() {
      this.dialogVisible = true;
    },
    searchBtn() {
      this.dialogVisible = false;
      this.$emit("searchDetail", this.ruleForm);
    },
    resetForm(ruleForm) {
      Object.assign(this.$data.ruleForm, this.$options.data().ruleForm);
    },
    // 巡护列表
    getUnitsList() {
      this.$apis
        .unitList(
          { per_page: 100000000000, get_all: "yes" },
          this.token,
          "?page=1"
        )
        .then((res) => {
          if (res.data.status == 0) {
            this.unitList = res.data.data.data;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getInterestTypeList() {
      this.$apis.getInterestType({}, this.token).then((res) => {
        if (res.data.status == 0) {
          this.interestTypeList = res.data.data;
        }
      });
    },
  },
  computed: {
    ...mapState(["navMessage", "token", "user"]),
  },
  created() {
    this.getUnitsList();
    this.getInterestTypeList();
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  width: 432px;
  left: 10%;
}
/deep/.el-dialog__title {
  color: #000000;
  font-size: 20px;
  margin-left: 20px;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-dialog__header {
  padding-bottom: 0px;
}
/deep/.el-form-item__label {
  font-size: 14px;
  color: #838a9d;
  margin-right: 20px;
}
/deep/.el-form-item {
  margin-left: 20px;
  margin-bottom: 30px;
}

/deep/.el-input {
  width: 240px;
  height: 40px;
}
/deep/.el-dialog__headerbtn {
  position: absolute;
  top: 10px;
  right: 20px;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 36px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
/deep/.el-button--medium {
  padding: 15px 60px;
}
/deep/.el-form-item__error {
  color: #ff4949;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 29%;
}
</style>
