<template>
  <div>
    <div class="footBar">
      <div class="midlleNav">
        <el-table
          height="100%"
          :data="data"
          style="width: 100%"
          :stripe="true"
          :header-cell-style="{ background: '#DDE6EF' }"
        >
          <el-table-column
            v-for="item in tableConfig"
            :key="item.field"
            :prop="item.field"
            :label="item.label"
            :width="item.width ? item.width : ''"
            align="center"
          ></el-table-column>
          <el-table-column label="登记位置" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.address !== ''">
                <el-popover
                  trigger="click"
                  placement="right"
                  title="登记位置"
                  width="263"
                  popper-class="information123"
                  effect="light"
                  :popper-options="popperOp"
                >
                  <div
                    style="
                      border-top: 1px solid rgba(0, 0, 0, 0.06);
                      width: 100%;
                      padding-top: 8px;
                      line-height: 20px;
                    "
                  >
                    <div>{{scope.row.address}}</div>
                    <div>经度：{{scope.row.longitude}}</div>
                    <div>纬度：{{scope.row.latitude}}</div>

                  </div>

                  <div slot="reference" class="name-wrapper">
                    <i class="el-icon-map-location"></i>
                    <el-button
                      type="text"
                      >查看</el-button
                    >
                  </div>
                </el-popover>
              </span>
              <span v-else>
               <i class="el-icon-map-location"></i>
                    <el-button
                      type="text"
                      @click="registerAddress(scope.row)"
                      >登记</el-button
                    >
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100" align="center">
            <template slot-scope="scope">
              <el-button type="text" v-if="editAble" @click="edit(scope.row)"
                >编辑</el-button
              >
              <el-button
                v-if="delAble"
                type="text"
                @click="del(scope.row)"
                class="deletBtn"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @current-change="currentChange"
          @next-click="handleNext"
          @prev-click="handlePrev"
          :current-page="page"
          :page-size="perPage"
          layout="total, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
        <el-button
          v-if="exportAble"
          @click="exportFile"
          type="primary"
          plain
          class="exportFileStyle"
          size="mini"
          >导出</el-button
        >
      </div>
    </div>
    <span v-if="selectMapPointDialog.visible">
      <selectMapPoint
      :lonlatForm="ruleForm"
      :selectMapPointDialog="selectMapPointDialog"
      :vid="ruleForm.id"
      :isRedegisterAddress="isRedegisterAddress"
      @updateLocation="updateLocation"
    />
    </span>

  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import selectMapPoint from "@/components/messageBox/selectMapPoint";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    deviveType:{
      type: String,
      default: ""
    },
    tableConfig: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 8,
    },
    total: {
      type: Number,
      default: 0,
    },
    editAble: {
      type: Boolean,
      default: false,
    },
    delAble: {
      type: Boolean,
      default: false,
    },
    exportAble: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popperOp: {}, //popper设置属性
      ruleForm: {
        id: "",
        name: "",
        software_code: "", //设备ID
        category_id: "",
        brand_id: "", //品牌
        model_id: "", //型号
        unit_id: "", //单位
        status: "", //状态
        holder_name: "", //持有人
        holder_phone: "", //手机号
        holder_dept: "",
        longitude: "",
        latitude: "",
        associated_device_type_id: 0,
        associated_device_software_code: ""
      },
      selectMapPointDialog: {
        visible: false,
      },
      isRedegisterAddress: true,
      params: {}
    };
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    handleNext() {
      this.$emit("pageChange", { page: this.page + 1 });
    },
    handlePrev() {
      this.$emit("pageChange", { page: this.page - 1 });
    },
    currentChange(page) {
      this.$emit("pageChange", { page: page });
    },
    edit(row) {
      this.$emit("edit", row);
    },
    del(row) {
      this.$emit("del", row);
    },
    exportFile() {
      this.$emit("exportFile");
    },
    registerAddress(data){
      this.params.id = data.id
      this.params.type = "registerPoint"
      // for (var key in this.ruleForm) {
      //   this.ruleForm[key] = data[key];
      // }
      this.selectMapPointDialog.visible = true
    },
    updateLocation(lnglat){

      this.params.latitude = lnglat.latitude
      this.params.longitude = lnglat.longitude

      this.$apis.UpdateDevice(this.params, this.token).then((res) => {
        this.$message({
          message: res.data.message
        })
        if (res.data.status === 0){
          this.$emit("searchData")
        }
      });
    }
  },
  components:{
    selectMapPoint
  },
  computed: {
    ...mapState(["navMessage", "token", "user", "role"]),
  },
  mounted() {
    this.currentUrlStorage(["首页", "应急装备", "无人机"]);

  },
  created() {},
};
</script>

<style lang="less" scoped>
.information123 {
  background-color: #fff;
}
/deep/.el-pagination__editor.el-input .el-input__inner {
  width: 40px;
}
.exportFileStyle {
  position: absolute;
  right: 0;
  bottom: 0px;
}
.bodyNav {
  height: calc(100vh - 150px);
  width: 100%;
}
.deletBtn {
  color: #ff5c5e;
}
/deep/ .el-table .cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/deep/ .el-table .cell:hover {
  text-overflow: inherit;
  overflow: visible;
  white-space: pre-line;
}
.tabWid {
  display: table;
  width: 100%;
  table-layout: fixed;
  text-align: center;
}
.header {
  width: 100%; /*表头与表格垂直对齐*/
}
th,
tr {
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
}
.block {
  padding: 6px 0 0 0;
  width: 100%;
  position: relative;
}
.footBar {
  padding: 10px 10px 20px 10px;
  height: 100%;
}
.midlleNav {
  width: 100%;
  border-radius: 4px;
  height: 48vh;
}
.tableData {
  border-collapse: collapse;
  font-size: 14px;
  font-weight: normal;
  .tabTitle {
    height: 56px;
    color: #000000;
    font-size: 16px;
  }
}
.tableFile {
  height: 30px;
  color: #000000;
  font-size: 16px;
  width: 30px;
}
.tableTitle {
  height: 30px;
  background-color: #dde6ef;
  width: 30px;
}

table tr:nth-child(odd) {
  background-color: #fafbfc;
}

table tr:nth-child(even) {
  background: #fff;
}
</style>
