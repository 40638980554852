<template>
  <div class="container">
    <div class="chat">
      <!-- 左侧聊天列表 -->
      <div class="left">
        <div class="room-list">
          <div class="search">
            <el-input
              v-model="roomParam.keywords"
              @input="searchInput"
              class="search-input"
              clearable
              placeholder="搜索"
              suffix-icon="el-icon-search"
            />
          </div>
          <div v-if="roomLoading" class="hint">
            <div>
              <i class="el-icon-loading" style="margin-right: 5px"></i>加载中
            </div>
          </div>
          <div v-else-if="roomList.length == 0" class="hint">
            <div>暂无消息</div>
          </div>
          <div ref="room" class="room">
            <div
              v-for="(room, index) in roomList"
              class="room-box"
              :class="{ 'current-room': currentRoomId == room.id }"
              @click="switchRoom(index)"
            >
              <div class="room-left">
                <div v-show="room.unread_num > 0" class="new-msg-icon" />
                <!-- 单人聊天显示，显示对方头像 -->
                <chat-avatar
                  v-if="room.user_num == 2 && room.create_user_id != user.id"
                  class="avatar"
                  :avatar="room.user[0].photo"
                />
                <!-- 单人聊天显示，显示对方头像 -->
                <chat-avatar
                  v-else-if="
                    room.user_num == 2 && room.create_user_id == user.id
                  "
                  class="avatar"
                  :avatar="room.user[1].photo"
                />
                <!-- 多人聊天，显示多人头像 -->
                <chat-avatar v-else class="avatar" :user="room.user" />
              </div>
              <div class="room-center">
                <div class="room-name">
                  <span v-if="room.user_num == 2">
                    <span v-if="room.create_user_id != user.id">{{
                      room.user[0].name
                    }}</span>
                    <span v-else>{{ room.user[1].name }}</span>
                  </span>
                  <span v-else>{{ room.room_name }}</span>
                </div>
                <div class="last-msg">
                  <span v-if="currentRoomId != room.id && chatInput[index]">
                    <span style="color: red">[草稿]</span>
                    {{ chatInput[index] }}
                  </span>
                  <span v-else>{{ room.record.data[0] | chatLastMsg }}</span>
                </div>
              </div>
              <div class="room-right">
                <div class="last-time">
                  <span v-if="room.record.data.length != 0">{{
                    room.record.data[0].created_at.substr(-8, 5)
                  }}</span>
                  <span v-else>{{ room.created_at.substr(-8, 5) }}</span>
                </div>
                <div v-if="room.user_num > 2" class="room-user-num">
                  {{ room.user_num }}人
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="option-btn">
          <div class="btn" @click="toAdd(2)">
            <i class="iconfont">&#xe60f;</i>
          </div>
          <div style="width: 2px; height: 40px; background: #d8d8d8" />
          <div class="btn" @click="toAdd(1)">
            <i class="iconfont">&#xe610;</i>
          </div>
        </div>
      </div>
      <!-- 右侧聊天主体 -->
      <div class="right">
        <div
          v-for="(room, index) in roomList"
          v-show="currentRoomId == room.id"
          class="room-chat-box"
        >
          <div class="room-chat-header">
            <chat-avatar
              v-if="room.user_num == 2 && room.create_user_id != user.id"
              class="avatar"
              :avatar="room.user[0].photo"
            />
            <chat-avatar
              v-else-if="room.user_num == 2 && room.create_user_id == user.id"
              class="avatar"
              :avatar="room.user[1].photo"
            />
            <chat-avatar v-else class="avatar" :user="room.user" />
            <div class="room-task-name">{{ room.room_task_name }}</div>
            <div class="head-option">
              <i class="iconfont show-option-box" @click="optionBoxShow = true"
                >&#xe602;</i
              >
              <div v-show="optionBoxShow" class="head-option-box">
                <div
                  class="head-option-btn"
                  style="margin-bottom: 7px"
                  @click="showUserList(index)"
                >
                  <i class="iconfont">&#xe62c;</i>
                  成员
                </div>
                <div class="head-option-btn" @click="showFileList(index)">
                  <i class="iconfont">&#xe60b;</i>
                  文件
                </div>
              </div>
            </div>
          </div>
          <div class="room-is-media" v-show="room.media_status == 1">
            正在进行音视频通讯，
            <span
              style="color: #07c160; cursor: pointer"
              @click="enterMedia(index)"
              >点击进入</span
            >
          </div>
          <div ref="record" class="chat-record">
            <div v-show="loadingRecord" class="record-loading">
              <i class="el-icon-loading" />
            </div>
            <div
              v-for="(msg, msgIndex) in room.record.data.slice().reverse()"
              ref="recordBox"
              class="record-box"
            >
              <div v-if="msg.type == 6" class="record-box-center">
                {{ msg.content }}
              </div>
              <div
                v-else-if="msg.user.user_id != user.id"
                class="record-box-left"
              >
                <div class="content-msg">
                  <chat-avatar class="avatar" :avatar="msg.user.photo" />
                  <div class="content-frame">
                    <div class="frame-triangle" />
                    <div class="frame-rectangle">
                      <div v-if="msg.type == 1">{{ msg.content }}</div>
                      <div v-else-if="msg.type == 2">
                        <el-image
                          :ref="'imageBox-' + index"
                          lazy
                          :src="msg.file_path"
                          class="image"
                          :preview-src-list="[msg.file_path]"
                          @load="imgLoadEvent(index, msgIndex)"
                          @error="imgLoadEvent(index, msgIndex)"
                        >
                          <div slot="placeholder" class="image-slot">
                            加载中
                            <span class="dot">...</span>
                          </div>
                        </el-image>
                      </div>
                      <div v-else-if="msg.type == 3" class="video-box">
                        <video
                          :src="msg.file_path"
                          controls
                          style="width: 300px"
                        ></video>
                      </div>
                      <div
                        v-else-if="msg.type == 4"
                        class="location-box"
                        @click="clickMap(msg.location)"
                      >
                        <el-amap
                          :vid="'map_' + index + '_' + msgIndex"
                          defaultCursor="pointer"
                          :dragEnable="false"
                          :doubleClickZoom="false"
                          :keyboardEnable="false"
                          :scrollWheel="false"
                          :zoom="zoom"
                          :center="msg | locationMsg"
                          class="amap-box"
                        >
                          <el-amap-marker
                            :vid="'marker-' + msg.msg_id"
                            :position="msg | locationMsg"
                          ></el-amap-marker>
                        </el-amap>
                      </div>
                      <div
                        v-else-if="msg.type == 5"
                        class="file-box"
                        @click="downloadFile(msg.file_path)"
                      >
                        <div class="file-box-left">
                          <div>{{ JSON.parse(msg.file_msg)["name"] }}</div>
                          <div>
                            {{ JSON.parse(msg.file_msg)["size"] | fileSize }}
                          </div>
                        </div>
                        <i class="el-icon-document"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content-time">{{ msg.created_at }}</div>
              </div>
              <div v-else class="record-box-right">
                <div class="content-msg">
                  <div class="content-frame right">
                    <div class="frame-triangle-right" />
                    <div style="margin-right: 10px">
                      <i v-show="msg.send_loading" class="el-icon-loading" />
                      <i
                        v-show="msg.send_err"
                        class="el-icon-warning-outline"
                        style="color: red"
                      />
                    </div>
                    <div class="frame-rectangle right">
                      <div v-if="msg.type == 1">{{ msg.content }}</div>
                      <div v-else-if="msg.type == 2">
                        <el-image
                          :ref="'imageBox-' + index"
                          lazy
                          :src="msg.file_path"
                          class="image"
                          :preview-src-list="[msg.file_path]"
                          @load="imgLoadEvent(index, msgIndex)"
                          @error="imgLoadEvent(index, msgIndex)"
                        >
                          <div slot="placeholder" class="image-slot">
                            加载中
                            <span class="dot">...</span>
                          </div>
                        </el-image>
                      </div>
                      <div v-else-if="msg.type == 3" class="video-box">
                        <video
                          :src="msg.file_path"
                          controls
                          style="width: 300px"
                        ></video>
                      </div>
                      <div
                        v-else-if="msg.type == 4"
                        class="location-box"
                        @click="clickMap(msg.location)"
                      >
                        <el-amap
                          :vid="'map_' + index + '_' + msgIndex"
                          defaultCursor="pointer"
                          :dragEnable="false"
                          :doubleClickZoom="false"
                          :keyboardEnable="false"
                          :scrollWheel="false"
                          :zoom="zoom"
                          :center="msg | locationMsg"
                          class="amap-box"
                        >
                          <el-amap-marker
                            :vid="'marker-' + msg.msg_id"
                            :position="msg | locationMsg"
                          ></el-amap-marker>
                        </el-amap>
                      </div>
                      <div
                        v-else-if="msg.type == 5"
                        class="file-box"
                        @click="downloadFile(msg.file_path)"
                      >
                        <div class="file-box-left">
                          <div>{{ JSON.parse(msg.file_msg)["name"] }}</div>
                          <div>
                            {{ JSON.parse(msg.file_msg)["size"] | fileSize }}
                          </div>
                        </div>
                        <i class="el-icon-document"></i>
                      </div>
                    </div>
                  </div>
                  <chat-avatar class="avatar" :avatar="msg.user.photo" />
                </div>
                <div class="content-time right">{{ msg.created_at }}</div>
              </div>
            </div>
          </div>
          <div class="chat-input-box">
            <div class="right-btn">
              <div class="upload">
                <i
                  class="iconfont"
                  style="cursor: pointer"
                  @click="$refs.fileInput[index].click()"
                  >&#xe60b;</i
                >
              </div>
              <input
                ref="fileInput"
                type="file"
                style="display: none"
                @change="uploadFile($event, index)"
              />
              <div class="video">
                <i
                  class="iconfont"
                  style="cursor: pointer"
                  @click="openMedia(index)"
                  >&#xe60f;</i
                >
              </div>
            </div>
            <textarea
              v-model="chatInput[index]"
              class="chat-input"
              resize="none"
              placeholder="请输入消息.."
              cols="30"
              rows="10"
              @keyup.enter="sendMsg(index)"
            />
          </div>
          <div v-show="userListShow" class="user-list">
            <div class="add-member-box" @click="toAddUser(index)">
              <i class="iconfont">&#xe650;</i>
              添加成员
            </div>
            <div class="user-list-box">
              <div v-for="user in room.user" class="user-box">
                <chat-avatar class="avatar" :avatar="user.photo" />
                <div class="user-box-name">{{ user.name }}</div>
              </div>
            </div>
          </div>
          <div v-show="fileListShow" ref="fileRecord" class="file-list">
            <div
              v-for="dateArr in roomList[index].file_record.data"
              class="file-time-box"
            >
              <div class="file-time">{{ dateArr.created_at }}</div>
              <div class="file-column-list">
                <div v-for="msg in dateArr.content" class="file-box">
                  <div v-if="msg.type == 2">
                    <el-image
                      class="file-content"
                      fit="cover"
                      :src="msg.file_path"
                      :preview-src-list="[msg.file_path]"
                      lazy
                    >
                      <div slot="placeholder" class="image-slot">
                        加载中
                        <span class="dot">...</span>
                      </div>
                    </el-image>
                  </div>
                  <div v-else-if="msg.type == 3">
                    <video
                      :src="msg.file_path"
                      controls
                      style="width: 100%; height: 120px"
                    ></video>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="loadingFileRecord" class="record-loading">
              <i class="el-icon-loading" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      class="chat-content-map-dialog"
      :modal="false"
      :top="'20vh'"
      :lock-scroll="false"
      :visible.sync="mapDialog.show"
    >
      <div style="width: 100%; height: 500px">
        <el-amap vid="map-dialog" :zoom="zoom" :center="mapDialog.position">
          <el-amap-marker
            vid="marker-dialog"
            :position="mapDialog.position"
          ></el-amap-marker>
        </el-amap>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { parseTime } from "@/utils/index";
import chatAvatar from "@/components/avatar/chatAvatar";
import { AMapManager, elAmapMarker, elAmapPolygon } from "vue-amap";

export default {
  components: {
    parseTime,
    chatAvatar,
  },
  data() {
    return {
      roomLoading: false, //加载标识
      
      roomList: [], // 房间列表
      roomParam: {
        keywords: "", //搜索框
        project_id: "",
        task_id: "",
        page: 1, // 房间当前页数
      },
      roomListLastPage: 0, // 房间总页数
      currentRoomId: "", // 当前房间id

      chatInput: [], // 聊天输入框
      optionBoxShow: false,
      userListShow: false,
      fileListShow: false,

      loadingRecord: false,
      loadingFileRecord: false,

      playerOptions: {
        //视频组件的参数
        // 视频组件的参数
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },

      zoom: 12, //地图组件的参数
      mapDialog: {
        show: false,
        position: [],
      },
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    /**
     * 初始化
     */
    init() {
      this.roomLoading = true;
      this.roomList = [];
      // 初始化房间列表
      this.getRoomList();
      // 添加全局点击事件
      document.addEventListener("click", (e) => {
        // 隐藏右上角的选项div
        if (e.target.className.indexOf("show-option-box") < 0) {
          this.optionBoxShow = false;
        }
        // 适配Firefox和Safari浏览器
        let epath = e.path || (e.composedPath && e.composedPath());
        // 隐藏成员列表的div
        const isUserListBox = epath.findIndex((v) => {
          return v.className == "user-list";
        });
        if (isUserListBox < 0 && e.target.className != "head-option-btn") {
          this.userListShow = false;
        }
        // 隐藏文件列表的div
        const isFileListBox = epath.findIndex((v) => {
          return v.className == "file-list";
        });
        if (isFileListBox < 0 && e.target.className != "head-option-btn") {
          this.fileListShow = false;
        }
      });
      this.chatDialog.init = true
    },
    /**
     * 添加聊天
     */
    toAdd(type) {
      this.chatAddDialog.type = type;
      this.chatAddDialog.key = new Date().getTime();
      this.chatAddDialog.show = true
    },
    /**
     * 添加用户
     */
    toAddUser(index) {
      const room = JSON.parse(JSON.stringify(this.roomList[index]));
      this.chatAddDialog.add_user_data.project_id = room.project_id;
      this.chatAddDialog.add_user_data.project_name = room.project_name;
      this.chatAddDialog.add_user_data.type = room.type;
      this.chatAddDialog.add_user_data.users = room.user;
      this.chatAddDialog.add_user_data.room_id = room.id;

      this.chatAddDialog.type = 3;
      this.chatAddDialog.key = new Date().getTime();
      this.chatAddDialog.show = true
    },
    /**
     * 聊天房间创建/添加成员动作完成回调
     */
    async chatAddComplete(result) {
      this.roomParam.page = 1;
      this.$refs.room.scrollTop = 0;
      this.roomList = [];
      Object.assign(this.$data.roomParam, this.$options.data().roomParam);

      await this.getRoomList();
      this.switchRoom(0);

      if (this.chatAddDialog.type == 2) {
        this.startMedia(result.data.media_url)
      }
    },
    /**
     * 搜索
     */
    async searchInput(e) {
      this.roomParam.page = 1;
      this.$refs.room.scrollTop = 0;
      this.roomList = [];
      
      await this.getRoomList();
    },
    /**
     * 获取聊天列表
     */
    getRoomList() {
      return new Promise((resolve, reject) => {
        if(this.chatDialog.window != 'home') {
          this.roomParam.project_id = this.chatDialog.project.id ? this.chatDialog.project.id : ""
          this.roomParam.task_id = this.chatDialog.task.id ? this.chatDialog.task.id : ""
        }
        this.$apis
          .chatRoomList(this.roomParam,this.token)
          .then((res) => {
            this.roomLoading = false;
            this.roomListLastPage = res.data.data.last_page;
            const roomArr = [];
            res.data.data.data.forEach((item) => {
              item = this.roomInit(item);
              // 格式化视频信息的参数
              item.record.data.forEach((msg) => {
                if (msg.type == 3) {
                  msg.video_option = this.getMsgVideoOptions(msg.file_path);
                }
              });
              roomArr.push(item);
            });
            this.roomList = this.roomList.concat(roomArr);
            
            // 给左侧房间列表添加滚动事件(获取历史聊天房间)
            if(this.roomParam.page == 1) {
              this.$nextTick(() => {
                this.$refs.room.addEventListener("scroll", (e) => {
                  if (
                    e.target.scrollTop + e.target.clientHeight >=
                    e.target.scrollHeight
                  ) {
                    if (this.roomParam.page < this.roomListLastPage && this.roomList.length > 0) {
                      this.roomParam.page++;
                      this.getRoomList();
                    }
                  }
                });
              });
            }
            
            resolve();
          });
      });
    },
    /**
     * 房间信息初始化
     */
    roomInit(room) {
      room.file_record = {};
      room.room_status = "init"; // 房间状态初始化
      room.scroll_history = -1; // 房间滚动位置初始化

      return room;
    },
    /**
     * 选择聊天
     */
    switchRoom(index) {
      const room = this.roomList[index];
      this.currentRoomId = room.id;

      // 更新后台阅读记录
      this.$apis
        .chatRecord({ room_id: room.id, page: 1 }, this.token)
        .then((res) => {
          this.chatDialog.unread_num -= this.roomList[index].unread_num;
          this.chatDialog.unread_num_arr[room.project_id] = 0;
          room.unread_num = 0;
        });

      // 判断历史滚动位置
      if (room.scroll_history == -1) {
        // 滚动到底部 ，此时图片还没加载（用了懒加载），因此图片加载完后需要再滚动到底部一次
        this.recordScroll(index, "bottom");
      } else {
        this.recordScroll(index, room.scroll_history);
      }

      // 监听聊天记录的滚动，
      this.$refs.record[index].addEventListener("scroll", (e) => {
        room.scroll_history = e.target.scrollTop; // 记录历史滚动
        const old = this.$refs.recordBox.length;
        if (
          e.target.scrollTop == 0 &&
          room.record.current_page < room.record.last_page
        ) {
          room.record.current_page++;
          this.loadingRecord = true;
          // 获取上一页的记录
          this.$apis
            .chatRecord(
              { room_id: room.id, page: room.record.current_page },
              this.token
            )
            .then((res) => {
              this.loadingRecord = false;
              room.record.last_page = res.data.data.last_page;
              // 添加记录到记录框中
              res.data.data.data.forEach((item) => {
                if (item.type == 3) {
                  item.video_option = this.getMsgVideoOptions(item.file_path);
                }
                room.record.data.push(item);
              });
              // 滚动的到上一页历史记录的最后一条的顶部的高度
              this.$nextTick(() => {
                const oldIndex = this.$refs.recordBox.length - old;
                const oldObj = this.$refs.recordBox[oldIndex];
                // 滚动的高度 = 增加记录前，第一天的距离父元素顶部的高度 - 自身的高度 - 新的记录的最后一条信息的高度 - 记录之间的分隔的高度
                const scroll =
                  oldObj.offsetTop -
                  oldObj.clientHeight -
                  this.$refs.recordBox[oldIndex - 1].clientHeight -
                  38;
                this.$refs.record[index].scrollTop = scroll;
              });
            });
        }
      });
    },
    /**
     * 聊天记录滚动
     */
    recordScroll(index, scroll) {
      this.$nextTick(() => {
        const msg = this.$refs.record[index]; // 获取对象
        msg.scrollTop = msg.scrollHeight;
        switch (scroll) {
          case "top":
            msg.scrollTop = 0;
            break;
          case "bottom":
            msg.scrollTop = msg.scrollHeight;
            break;
          default:
            msg.scrollTop = scroll;
            break;
        }
      });
    },
    /**
     * 发送消息
     */
    sendMsg(index, msg, formData) {
      if (!msg) {
        msg = {
          type: 1,
          content: this.chatInput[index],
        };
      }

      let room = this.roomList[index];
      msg.room_id = room.id;
      msg.created_at = parseTime(new Date());
      msg.user = {
        user_id: this.user.id,
        photo: this.user.photo_url,
      };
      msg.send_loading = true; // 发送loading的icon
      msg.send_err = false; // 发送失败的icon

      this.chatInput[index] = "";

      //存储文件记录
      if (msg.type == 2 || msg.type == 3) {
        if (room.file_record.data) {
          let fileRecordArr = room.file_record.data
            ? room.file_record.data
            : [];
          let findIndex = fileRecordArr.findIndex((v) => {
            return v.created_at == msg.created_at.substr(0, 10);
          });
          if (findIndex < 0) {
            fileRecordArr.push({
              created_at: msg.created_at.substr(0, 10),
              content: [msg],
            });
          } else {
            fileRecordArr[findIndex].content.unshift(msg);
          }
          room.file_record.data = fileRecordArr;
        }
      }
      // 视频信息需要加载组件选项
      if (msg.type == 3) {
        msg.video_option = this.getMsgVideoOptions(msg.file_path);
      }

      // 添加聊天记录
      this.roomList[index].record.data.unshift(msg);

      this.recordScroll(index, "bottom");

      // 发送消息
      this.$apis.chatSendMsg(formData || msg, this.token).then((res) => {
        const newMsg = this.roomList[index].record.data[0];
        newMsg.send_loading = false;
        if (res.data.status == 0) {
          newMsg.msg_id = res.data.data.msg_id;
        } else {
          // 发送失败
          newMsg.send_err = true;
        }

        this.$forceUpdate();
      });
    },
    /**
     * 开始音视频
     */
    startMedia(url) {
      if (this.mediaDialog.show) {
        this.$message({
          message: "正在进行音视频通讯，无法重复开启",
          type: "error",
        });
        return false;
      }
      this.mediaDialog.url = url + "&token=" + this.token;
      this.mediaDialog.show = true;
    },
    /**
     * 重新进入音视频
     */
    enterMedia(index) {
      let room = this.roomList[index];
      this.startMedia(room.media_url)
    },
    /**
     * 发起音视频通讯
     */
    openMedia(index) {
      let room = this.roomList[index];
      this.$apis.chatOpenMedia({ room_id: room.id }, this.token).then((res) => {
        if (res.data.status == 0) {
          this.startMedia(res.data.data.media_url)
        } else {
          this.$message({ message: res.data.message, type: "error" });
        }
      });
    },
    /**
     * 上传
     */
    uploadFile(e, index) {
      const file = e.target.files[0];
      e.target.value = null;
      if (file.size > 10 * 1024 * 1024) {
        this.$message({
          message: "请上传10M以内的文件",
          type: "error",
        });
        return false;
      }

      // 文件后缀
      const extIndex = file.name.lastIndexOf(".") + 1;
      const ext = file.name.substr(extIndex);
      let fileType = "";
      if (["jpg", "jpeg", "tiff", "png", "gif", "bmp"].includes(ext)) {
        fileType = 2;
      } else if (["mp4"].includes(ext)) {
        fileType = 3;
      } else {
        fileType = 5;
      }

      const msg = {
        type: fileType,
        file: file,
      };

      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", fileType);
      formData.append("room_id", this.roomList[index].id);
      formData.append("created_at", parseTime(new Date()));
      if (msg.type == 2) {
        const that = this;
        const reader = new FileReader();
        reader.onload = function () {
          // 本地消息的图片的用base64
          msg.file_path = this.result;
          that.sendMsg(index, msg, formData);
        };
        reader.readAsDataURL(file);
      } else if (msg.type == 3 || msg.type == 5) {
        msg.file_path = URL.createObjectURL(file);
        msg.file_msg = JSON.stringify({ name: file.name, size: file.size });
        this.sendMsg(index, msg, formData);
      }
    },
    /**
     * 下载
     */
    downloadFile(url) {
      window.open(url);
    },
    /**
     * 图片加载完成的事件
     */
    imgLoadEvent(index, msgIndex) {
      let room = this.roomList[index];
      // // 这个房间处于初始化状态
      if (room.room_status == "init") {
        let len = this.$refs["imageBox-" + index].length;
        // 可视区域内最后一张图片加载完成
        if (this.$refs["imageBox-" + index][len - 1].loading == false) {
          this.$refs.record[index].scrollTop += this.$refs["imageBox-" + index][
            len - 1
          ].$el.offsetHeight;
          room.room_status = "complete";
        }
      } else {
        // 图片加载完成是聊天的新消息
        if (msgIndex == room.record.data.length - 1) {
          this.recordScroll(index, "bottom");
        }
      }
    },
    /**
     * 视频信息的选项封装
     */
    getMsgVideoOptions(filePath) {
      let options = JSON.parse(JSON.stringify(this.playerOptions));
      options.sources[0].src = filePath;
      return options;
    },
    /**
     * 地图点击
     */
    clickMap(location) {
      let obj = JSON.parse(location);
      this.mapDialog.show = true;
      this.mapDialog.position = [obj.longitude, obj.latitude];
    },
    /**
     * 显示用户列表记录
     */
    showUserList(index) {
      let room = this.roomList[index];
      this.$apis.chatRoomUser({ room_id: room.id }, this.token).then((res) => {
        room.user = res.data.data.user;
        this.userListShow = true;
      });
    },
    /**
     * 显示文件历史记录
     */
    showFileList(index) {
      this.fileListShow = true;
      this.addFileRecord(index, 1);
      this.$refs.fileRecord[index].addEventListener("scroll", (e) => {
        const roomFileRecord = this.roomList[index].file_record;
        // 滚动到底部
        if (
          e.target.scrollTop + e.target.clientHeight + 5 >=
          e.target.scrollHeight
        ) {
          if (roomFileRecord.current_page < roomFileRecord.last_page) {
            roomFileRecord.current_page++;
            this.addFileRecord(index, roomFileRecord.current_page);
          }
        }
      });
    },
    /**
     * 加载文件历史记录
     */
    addFileRecord(index, page) {
      this.loadingFileRecord = true;
      const room = this.roomList[index];
      if (page == 1) {
        room.file_record = [];
      }
      // 加载文件的历史记录
      this.$apis
        .chatRecord(
          {
            room_id: room.id,
            type: "file",
            page: page,
          },
          this.token
        )
        .then((res) => {
          this.loadingFileRecord = false;
          const fileRecordArr = room.file_record.data
            ? room.file_record.data
            : [];
          // 数据格式的组装
          res.data.data.data.forEach((item) => {
            if (item.type == 3) {
              item.video_option = this.getMsgVideoOptions(item.file_path);
            }

            const findIndex = fileRecordArr.findIndex((v) => {
              return v.created_at == item.created_at.substr(0, 10);
            });
            if (findIndex < 0) {
              fileRecordArr.push({
                created_at: item.created_at.substr(0, 10),
                content: [item],
              });
            } else {
              fileRecordArr[findIndex].content.push(item);
            }
          });

          room.file_record = res.data.data;
          room.file_record.data = fileRecordArr;
        });
    },
    /**
     * 接收到新消息
     */
    newMsgRecv(message) {
      this.roomList.forEach((item, index) => {
        if (item.id == message.room_id) {
          const messageUser = JSON.parse(message.user);
          // 并且新消息的消息id不一致
          if (
            item.record.data.length == 0 ||
            item.record.data[0].msg_id != message.msg_id
          ) {
            const msg = {
              type: message.type,
              room_id: message.room_id,
              msg_id: message.msg_id,
              content: message.content,
              created_at: message.created_at,
              file_path: message.file_path,
              file_msg: message.file_msg,
              location: message.location,
              user: {
                user_id: messageUser.id,
                photo: messageUser.photo,
              },
            };
            item.unread_num++;
            item.record.data.unshift(msg);
            // 判断音视频结束通知
            if(msg.content == '音视频通讯已结束') {
              item.media_status = 0
            }
            // 如果新消息的id是当前打开的房间id则滚动到底部
            if (message.room_id == this.currentRoomId) {
              // 如果是视频信息需要加载视频组件
              if (msg.type == 3) {
                msg.video_option = this.getMsgVideoOptions(msg.file_path);
              }
              this.recordScroll(index, "bottom");
            }
          }
        }
      });
    },
    /**
     * 接收到新聊天
     */
    newRoomRecv(message) {
      let room = message;
      room = this.roomInit(room);
      room.user = JSON.parse(room.user);
      if (room.user[0].user_id != this.user.id) {
        //对于创建者来说不需要处理
        room.record = {
          data: [],
          current_page: 1,
          last_page: 1,
        };
        this.roomList.unshift(room);
      }
    },
  },
  computed: {
    ...mapState([
      "user",
      "token",
      "chatAddDialog",
      "chatDialog",
      "mediaDialog",
    ]),
  },
  filters: {
    // 左边预览信息的显示
    chatLastMsg(val) {
      if (!val) {
        return "";
      }
      let content = "";
      switch (val.type + "") {
        case "1":
        case "6":
          content = val.content;
          break;
        case "2":
          content = "【现场图片】";
          break;
        case "3":
          content = "【现场视频】";
          break;
        case "4":
          content = "【定位】";
          break;
        case "5":
          content = "【文件】";
          break;
      }
      return content;
    },
    // 定位消息
    locationMsg(msg) {
      let obj = JSON.parse(msg.location);
      return [obj.longitude, obj.latitude];
    },
    // 文件的大小
    fileSize(val) {
      let text = "";
      if (val / 1024 / 1024 > 1) {
        text = (val / 1024 / 1024).toFixed(2) + "M";
      } else if (val / 1024 > 1) {
        text = (val / 1024).toFixed(2) + "KB";
      } else {
        text = val + "B";
      }
      return text;
    },
  },
  watch: {
    chatAddDialog: {
      handler(newVal, oldVal) {
        if(Object.keys(newVal.result).length != 0) {
          this.chatAddComplete(newVal.result)
          this.chatAddDialog.result = {}
        }
      },
      deep: true,
    },
    chatDialog: {
      handler(newVal, oldVal) {
        if (newVal.new_msg.wait_handle == 1) {
          switch (newVal.new_msg.cmd) {
            case "new_msg":
              this.newMsgRecv(newVal.new_msg.data);
              break;
            case "new_room":
              this.newRoomRecv(newVal.new_msg.data);
              break;
            case "media_close":
              let findIndex = this.roomList.findIndex((v) => {
                return v.id == newVal.new_msg.data.room_id;
              });
              this.roomList[findIndex].media_status = 0;
              break;
          }
        }
        this.chatDialog.new_msg.wait_handle = 0;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("~@/assets/iconfont/iconfont.css");

.container {
  height: 687px;
  overflow-y: auto;
}

.chat {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #a6aab8;
  box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
  display: flex;
  .left {
    width: 25.8%;
    display: flex;
    flex-direction: column;
    .option-btn {
      height: 51px;
    }
    .room-list {
      flex: 1;
      padding-left: 11px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      .search {
        min-height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #d8d8d8;
        padding-right: 22px;
        padding-top: 20px;
        .search-input {
          width: 257px;
          z-index: 999;
        }
      }
      .hint {
        font-size: 14px;
        color: #1182fb;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .room {
        overflow-y: auto;
        .room-box {
          display: flex;
          flex-direction: row;
          padding: 14px 21px 14px 7px;
          border-bottom: 1px solid #e6e9ef;
          cursor: pointer;
          .room-left {
            margin-right: 9px;
            position: relative;
          }
          .room-center {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .room-name {
              color: #3c4353;
              font-size: 14px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .last-msg {
              color: #a6aab8;
              font-size: 12px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .room-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            .last-time {
              color: #838a9d;
              font-size: 14px;
            }
            .room-user-num {
              color: #a6aab8;
              font-size: 12px;
            }
          }
        }
        .current-room {
          background: #e6e9ef;
        }
      }
      .room::-webkit-scrollbar-track-piece {
        //滚动条凹槽的颜色，还可以设置边框属性
        background-color: #ffffff;
      }
    }

    .option-btn {
      display: flex;
      align-items: center;
      border-top: 1px solid #c3c3c3;
      padding: 6px 0;
      cursor: pointer;
      box-shadow: 0px -1px 1px 0px rgba(195, 195, 195, 0.5);
      box-sizing: border-box;
      .btn {
        color: rgb(17, 130, 251);
        width: 49%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .right {
    flex: 1;
    background: #f0f1f2;
    position: relative;
    .room-chat-box {
      display: flex;
      flex-direction: column;
      height: 100%;
      .room-chat-header {
        width: 100%;
        height: 80px;
        background: #f6f7f9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 30px;
        border-bottom: 1px solid #d8d8d8;
        padding-top: 30px;
        .head-option {
          width: 56px;
          position: relative;
          z-index: 999;
          .show-option-box {
            cursor: pointer;
            font-size: 30px;
          }
          .head-option-box {
            position: absolute;
            background: #ffffff;
            padding: 14px 7px;
            width: 155px;
            right: 0;
            box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
              0px 6px 16px 0px rgba(0, 0, 0, 0.08),
              0px 3px 6px -4px rgba(0, 0, 0, 0.12);
            border-radius: 4px;
            z-index: 10;
            .head-option-btn {
              width: 100%;
              font-size: 14px;
              height: 28px;
              color: #3c4353;
              position: relative;
              text-align: center;
              line-height: 28px;
              cursor: pointer;
              i {
                position: absolute;
                left: 30px;
              }
            }
            .head-option-btn:hover {
              background: #dde2ef;
            }
          }
        }
      }
      .room-is-media {
        padding: 15px;
        background: #fff;
      }
      .chat-record {
        flex: 1;
        padding: 14px 29px 14px 29px;
        overflow-y: auto;
        .record-box {
          margin-bottom: 38px;
          .record-box-center {
            text-align: center;
            margin: 10px;
          }
          .record-box-left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
          .record-box-right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }
        }

        .content-msg {
          display: flex;
          align-items: center;
        }
        .content-time {
          color: #000000;
          font-size: 12px;
          margin-top: 9px;
          margin-left: 60px;
        }
        .content-time.right {
          margin-right: 60px;
        }
        .content-frame {
          position: relative;
          margin-left: 20px;
          .frame-triangle {
            width: 0px;
            height: 0px;
            border: 10px transparent solid;
            border-right-color: rgba(221, 226, 238, 1);
            position: absolute;
            top: 50%;
            left: -20px;
            transform: translateY(-50%);
            content: "";
          }
          .frame-triangle::before {
            width: 0px;
            height: 0px;
            border: 10px transparent solid;
            border-right-color: #ffffff;
            position: absolute;
            top: 50%;
            left: -9px;
            transform: translateY(-50%);
            content: "";
          }
          .frame-triangle-right {
            width: 0px;
            height: 0px;
            border: 10px transparent solid;
            border-left-color: rgba(221, 226, 238, 1);
            position: absolute;
            top: 50%;
            right: -20px;
            transform: translateY(-50%);
            content: "";
          }
          .frame-triangle-right::before {
            width: 0px;
            height: 0px;
            border: 10px transparent solid;
            position: absolute;
            top: 50%;
            right: -9px;
            transform: translateY(-50%);
            content: "";
            border-left-color: #1cd0bd;
          }
          .frame-rectangle {
            position: relative;
            background: #fff;
            color: #000000;
            font-size: 14px;
            padding: 15px 22px;
            .image {
              max-width: 185px;
              color: white;
            }
          }
          .frame-rectangle.right {
            background: #1cd0bd;
            color: #ffffff;
          }
          .video-box {
            min-width: 300px;
            video {
              width: 100px;
            }
          }
          .location-box {
            width: 220px;
            height: 140px;
          }
          .file-box {
            display: flex;
            cursor: pointer;
            .file-box-left {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            }
            i {
              font-size: 40px;
              margin-left: 30px;
            }
          }
        }
        .content-frame.right {
          margin-right: 20px;
          display: flex;
          align-items: center;
        }
      }
      .chat-record::-webkit-scrollbar-track-piece {
        //滚动条凹槽的颜色，还可以设置边框属性
        background-color: #f0f1f2;
      }
      .chat-input-box {
        border-top: 1px solid #d8d8d8;
        height: 194px;
        background: #f6f6f6;
        padding: 29px;
        display: flex;
        flex-direction: column;
        .right-btn {
          display: flex;
          color: #a6aab8;
          justify-content: flex-end;
          margin-bottom: 5px;
        }
        .upload {
          display: flex;
          justify-content: flex-end;
          margin-right: 15px;
        }
        .chat-input {
          width: 100%;
          resize: none;
          border: none;
          background: #f6f6f6;
          outline: none;
          flex: 1;
        }
      }
      .user-list {
        position: absolute;
        width: 200px;
        height: 100%;
        background: #ffffff;
        right: 0;
        z-index: 999;
        box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
          0px 6px 16px 0px rgba(0, 0, 0, 0.08),
          0px 3px 6px -4px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        color: #3c4353;
        display: flex;
        flex-direction: column;
        .add-member-box {
          width: 100%;
          height: 125px;
          border-bottom: 1px solid #d8d8d8;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 29px;
          cursor: pointer;
          i {
            width: 21px;
            height: 21px;
            margin-bottom: 15px;
          }
        }
        .user-list-box {
          flex: 1;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 14px;
          .user-box {
            margin-bottom: 15px;
            padding-left: 55px;
            width: 100%;
            display: flex;
            align-items: center;
          }
          .user-box-name {
            margin-left: 9px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .file-list {
        position: absolute;
        width: 85%;
        height: 100%;
        background: #ffffff;
        right: 0;
        box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
          0px 6px 16px 0px rgba(0, 0, 0, 0.08),
          0px 3px 6px -4px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        color: #3c4353;
        overflow-y: auto;
        padding: 56px 29px;
        display: flex;
        flex-direction: column;
        z-index: 999;
        .file-time-box {
          .file-time {
            color: #3c4353;
            font-size: 14px;
            margin-bottom: 2px;
          }
          .file-column-list {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;
            .file-box {
              width: 32%;
              height: 120px;
              margin-top: 12px;
              margin-right: 2%;
              .file-content {
                width: 100%;
                height: 120px;
                color: white;
              }
            }
            .file-box:nth-child(3n + 3) {
              margin-right: 0;
            }
          }
        }
      }
      .record-loading {
        display: flex;
        justify-content: center;
        margin: 10px 0;
      }
    }
  }
}

::-webkit-scrollbar {
  //滚动条的宽度
  width: 9px;
  height: 9px;
}
::-webkit-scrollbar-thumb {
  //滚动条的设置
  background-color: #dddddd;
  background-clip: padding-box;
  min-height: 28px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}



/deep/.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 1.5em;
}

.new-msg-icon {
  width: 12px;
  height: 12px;
  background: #ff5c5e;
  border-radius: 50%;
  position: absolute;
  left: -5px;
  z-index: 3;
}

.avatar {
  width: 40px;
  height: 40px;
}
</style>
