// 任务模块公用方法
import Element from "element-ui";
import coordtransform from "./transform.js";
const link =
  document.querySelector("link[rel*='icon']") || document.createElement("link");

export default {
  /**
   * 错误提示
   * title: 错误标题,
   * message: 提示内容
   * duration: 生存时间
   */
  $notifyError: (title, message, duration = 1500) => {
    Element.Notification.error({
      title: title,
      message: message,
      duration: duration
    });
  },
  /**
   * 正确提示
   * title: 错误标题,
   * message: 提示内容
   * duration: 生存时间
   */
  $notifySuccess: (title, message, duration = 1500) => {
    Element.Notification.success({
      title: title,
      message: message,
      duration: duration
    });
  },
  /**
   * 居中提示
   * type: 提示类型 success 成功/warning 警告/info 消息/error 失败 默认success
   * message: 提示内容
   * duration: 生存时间
   */
  $message: (message, type = "success", duration = 1500) => {
    Element.Message({
      type: type,
      message: message,
      showClose: true,
      duration: duration
    });
  },
  /**
   * 动态修改favicon
   * faviconUrl: string  favicon的路径
   */
  changeFavicon: (faviconUrl = "./favicon_tip.ico") => {
    link.href = faviconUrl;
    document.getElementsByTagName("head")[0].appendChild(link);
  },
  /**
   * 获取当前时间
   * 返回当前时间字符串
   */
  getCurrentTime: () => {
    const date = new Date();
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    let hour = date.getHours() + 1;
    let minute = date.getMinutes() + 1;
    let second = date.getSeconds();

    // 这样写显示时间在1~9会挤占空间；所以要在1~9的数字前补零;

    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    if (hour < 10) {
      hour = "0" + hour;
    }
    if (minute < 10) {
      minute = "0" + minute;
    }
    if (second < 10) {
      second = "0" + second;
    }
    const time =
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
    return time;
  },
  /**
   * 坐标拆分
   * @param {string} str
   * @decide {Boller} str
   * @nav {Number} str
   */
  $funcResolution(str, decide, nav, software_version) {
    const arrStr = str.split(";");
    if (decide) {
      arrStr[arrStr.length - 1] = arrStr[0];
    } else {
      arrStr.pop();
    }
    const arr = [];
    for (let i = 0; i < arrStr.length; i++) {
      const lonArr = arrStr[i].split(",");
      if (nav === 1) {
        if (
          software_version !== null &&
          typeof software_version === "string" &&
          software_version.toLowerCase().search("ios") !== -1
        ) {
          arr[i] = [Number(lonArr[1]), Number(lonArr[0])];
        } else {
          const lan = coordtransform.wgs84togcj02(
            Number(lonArr[1]),
            Number(lonArr[0])
          );
          arr[i] = [lan[0], lan[1]];
        }
      } else if (nav === 2) {
        arr[i] = [Number(lonArr[0]), Number(lonArr[1])];
      }
    }
    return arr;
  },
  /**
   * 获取空域查询中kml文件中区块的坐标点集合（“经度,纬度,高度”的用;号分割的字符串），因为之前通用任务也使用的是getKmlPolygonStr函数，此处只加载出一个区块进行查询
   * @param {file} file file对象
   */
  getAirKmlPolygonStr(file) {
    return new Promise((resolve, reject) => {
      let parseString = require("xml2js").parseString;
      let reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = function(e) {
        let xml = e.target.result;
        parseString(xml, function(err, result) {
          let coordinates;
          let data = result.kml.Document[0].Placemark[0];
          if (data.LineString) {
            coordinates = data.LineString[0].coordinates[0];
          } else if (data.MultiGeometry) {
            coordinates =
              data.MultiGeometry[0].Polygon[0].outerBoundaryIs[0].LinearRing[0]
                .coordinates[0];
          } else {
            coordinates =
              data.Polygon[0].outerBoundaryIs[0].LinearRing[0].coordinates[0];
          }
          coordinates = coordinates.split(" ");
          let coordinatesText = "";
          coordinates.forEach(item => {
            let point = item.split(",");

            if (point.length == 2) {
              point.push(0);
            }
            if (point.length > 2) {
              coordinatesText += point.join(",") + ";";
            }
          });
          // 坐标组合成 “经度,纬度,高度”的用;号分割的字符串
          coordinatesText = coordinatesText.substr(
            0,
            coordinatesText.length - 1
          );
          resolve(coordinatesText);
        });
      };
    });
  },
  getKmlPolygonStr(e) {
    return new Promise((resolve, reject) => {
      let file = e.target.files[0];
      // 使用 FileReader 来读取文件
      let reader = new FileReader();

      // 读取纯文本文件,且编码格式为 utf-8
      reader.readAsText(file, "UTF-8");

      // 读取文件,会触发 onload 异步事件,可使用回调函数 来获取最终的值.
      reader.onload = function(e) {
        let fileContent = e.target.result;
        let data = fileContent.split("<coordinates>");
        let lnglatData = [];
        data.forEach(item => {
          if (item.indexOf("</coordinates>") !== -1) {
            let path = "";
            let strLnglat = item.split("</coordinates>")[0];
            strLnglat.split(" ").forEach(lnglats => {
              let lnglat = lnglats.split(",");
              if (lnglat[0] !== "" && lnglat[0])
                path = path === '' ? lnglat[0]+","+ lnglat[1]+","+"0" :  path + ";"+lnglat[0]+","+ lnglat[1]+","+"0";
            });
            let arrPath = path.split(";")
            if(arrPath[0] !== arrPath[arrPath.length -1]){
              Element.Message({
                type: "warning",
                message: "导入的kml区块中含有非多边形，请检查后再导入。",
                showClose: true,
                duration: 4000
              });
              return
            }
            lnglatData.push(path);
          }
        });
        resolve(lnglatData);
      };
    });
  },
  /**
   * 获取kml文件中区块的坐标点集合（“经度,纬度,高度”的用;号分割的字符串）
   * @param {file} file file对象
   */
  getKmlPolygonStr1(file) {
    return new Promise((resolve, reject) => {
      let parseString = require("xml2js").parseString;
      let reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = function(e) {
        let xml = e.target.result;
        parseString(xml, function(err, result) {
          let coordinates;
          let coordinatesTexts = [];
          // let data = result.kml.Document[0].Placemark[0];
          let datas = result.kml.Document[0].Placemark;
          datas.forEach(PlacemarkData => {
            let data = PlacemarkData;
            if (data.LineString) {
              coordinates = data.LineString[0].coordinates[0];
            } else if (data.MultiGeometry) {
              coordinates =
                data.MultiGeometry[0].Polygon[0].outerBoundaryIs[0]
                  .LinearRing[0].coordinates[0];
            } else {
              coordinates =
                data.Polygon[0].outerBoundaryIs[0].LinearRing[0].coordinates[0];
            }
            coordinates = coordinates.split(" ");
            let coordinatesText = "";
            coordinates.forEach(item => {
              let point = item.split(",");

              if (point.length == 2) {
                point.push(0);
              }
              if (point.length > 2) {
                coordinatesText += point.join(",") + ";";
              }
            });
            // 坐标组合成 “经度,纬度,高度”的用;号分割的字符串
            coordinatesText = coordinatesText.substr(
              0,
              coordinatesText.length - 1
            );
            coordinatesTexts.push(coordinatesText);
          });
          console.log(coordinatesTexts);
          resolve(coordinatesTexts);
        });
      };
    });
  },
  /**
   * 坐标字符串拆分成坐标集合
   * @param {string} str 坐标字符串 "110.34052499,25.30728471,0;110.34052499,25.30728471,0;110.34052499,25.30728471,0..."
   * @return {Array} arr [[110.34052499,25.30728471,0],[110.34052499,25.30728471,0]...]
   */
  coordinateStr2coordinateList(coordinateStr) {
    let pointList = coordinateStr.split(";");
    let path = [];
    //将每个点存入到path中
    pointList.forEach((point, pointIndex) => {
      if (typeof point === "string") {
        let pointSplit = point.split(",");
        let lng = Number(pointSplit[0]);
        let lag = Number(pointSplit[1]);
        let height = Number(pointSplit[2]);
        point = [lng, lag, height];
        path.push(point);

        //如果最后一个点不相等，则头尾相连
        if (pointIndex == pointList.length - 1) {
          if (lng != path[0][0] && lag != path[0][1]) {
            path.push(path[0]);
          }
        }
      }
    });
    return path;
  },
  /**
   * 转二进制文件下载
   * @param {string} url
   * @param {string} filename
   */
  $funcDownloadImgZP(url, filename) {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(res =>
          res
            .blob()
            .then(blob => {
              let a = document.createElement("a");
              let url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = filename;
              a.click();
              window.URL.revokeObjectURL(url);
              resolve("成功");
            })
            .catch(err => {
              console.error(err);
              reject("失败");
            })
        )
        .catch(err => {
          console.error(err);
          reject("失败");
        });
    });
  },
  /**
   * web 存储
   * @param {Object} value
   * @param {String} key
   */
  $funcSave(value, key) {
    let obj = new Object();
    obj.key = key;
    obj.value = value;
    let str = JSON.stringify(obj); // 将对象转换为字符串
    localStorage.setItem(obj.key, str);
  },
  /**
   * 读取本地存储
   * @param {string} key
   */
  $funcFind(key) {
    let str = localStorage.getItem(key);
    let obj = JSON.parse(str);
    return {
      key: key,
      data: obj.value
    };
  },
  /**
   * 文件数据回传
   * @param {file} formData
   * @param {string} url
   * @param {Object} header
   * @param {number} timeout
   */
  $funcFileRequest(fileData, url, header = {}, timeout = 0, objkey) {
    if (!url || !fileData) {
      return;
    }
    let xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);

    for (let keys in header) {
      xhr.setRequestHeader(keys, header[keys]);
    }
    let formData = new FormData();
    for (let keys in fileData) {
      if (keys === "file") {
        formData.append(keys, fileData[keys], fileData[keys].name);
      } else {
        formData.append(keys, fileData[keys]);
      }
    }
    xhr.timeout = timeout;
    xhr.ontimeout = function() {
      // xhr请求超时事件处理
      setTimeout(() => {
        window.progress[objkey] = "失败";
      }, 1000);
    };
    xhr.upload.addEventListener(
      "progress",
      event => {
        if (event.lengthComputable) {
          let percent = Math.ceil((event.loaded * 100) / event.total - 1) + "%";
          window.progress[objkey] = percent;
        }
      },
      false
    );
    xhr.onreadystatechange = ev => {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          let data = JSON.parse(xhr.responseText);
          if (data.status === 0) {
            window.progress[objkey] = "已完成";
          } else {
            window.progress[objkey] = "失败";
            Element.Notification.error({
              title: "错误",
              message: data.message,
              position: "bottom-right",
              duration: 1500
            });
          }
        } else {
          window.progress[objkey] = "失败";
        }
      }
    };
    xhr.send(formData);
  },
  /**
   *
   * 数组根据数组对象中的某个属性值进行排序的方法
   * 使用例子：newArray.sort(sortBy('number',false))
   * 表示根据number属性降序排列;若第二个参数不传递，默认表示升序排序
   * @param {string} attr
   * @param {boolean} rev
   */
  $funcArrSort: (attr, rev) => {
    //第二个参数没有传递 默认升序排列
    if (rev == undefined) {
      rev = 1;
    } else {
      rev = rev ? 1 : -1;
    }

    return (a, b) => {
      a = a[attr];
      b = b[attr];
      if (a < b) {
        return rev * -1;
      }
      if (a > b) {
        return rev * 1;
      }
      return 0;
    };
  }
};
