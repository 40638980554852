<template>
  <div class="rightBar">
    <div class="bodybar">
      <div class="title">{{ title }}</div>
      <div class="fmNav">
        <el-button :style="ImportSty" type="text" @click="Import((flag = 0))"
          >导入空域</el-button
        >
        <el-button :style="drawSty" type="text" @click="draw((flag = 1))"
          >绘制空域</el-button
        >
      </div>
      <div class="uploadBar" v-if="flag == 0">
        <p class="upWord">请加载您计划的航飞空域</p>
        <el-button type="primary" plain @click="$refs.kmlInputEl.click()"
          >加载空域</el-button
        >
        <input
          type="file"
          ref="kmlInputEl"
          style="display: none"
          accept=".kml, .KML"
          @change="uploadKmlFile"
        />
        <p class="careful">*仅支持KML文件</p>
      </div>
      <div class="drawair" v-if="flag == 1">
        <img src="@/assets/img/绘制示意.png" alt />
        <p class="careful">
          请在地图中绘制您的计划航飞空域 （注：请至少选择四个点进行绘制）
        </p>
        <el-button @click="empty" type="primary" plain>清除重绘</el-button>
      </div>
      <div class="divider"></div>
      <div class="results">
        <span class="resultsTitle">查询结果:</span>
        <div class="exhibition">
          <span class="guide">针对您提交的航飞空域，查询到以下有效信息</span>
          <div class="warzone">
            <span class="titleword">任务审批归属战区：</span>
            <span
              class="flyInformation"
              v-for="(item, index) in warZone"
              :key="index"
              >{{ item }}</span
            >
          </div>
          <div class="partition">
            <span class="titleword">任务审批归属分区：</span>
            <span class="flyInformation" v-for="litem in partition" :key="litem">
              {{ litem }}
            </span>
          </div>
          <div class="partition">
            <span class="flyInformation" v-if="limitData.length > 0"
              >空域内包含限制区域。</span
            >
            <span class="flyInformation" v-if="limitData.length == 0"
              >空域内不包含限制区域。</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import publicJs from "@/utils/public";
export default {
  props: {
    legal: {
      type: Boolean,
    },
    latitudeAndLongitudeStr: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    warZone: {
      type: Array,
      default: () => [],
    },
    partition: {
      type: Array,
      default: () => [],
    },
    limitData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ImportSty: "",
      drawSty: "",
      flag: 0,
    };
  },
  methods: {
    // 上传kml
    async uploadKmlFile(e) {
      let file = e.target.files[0];
      this.$parent.kmlLoadAir(await publicJs.getAirKmlPolygonStr(file));
    },
    Import(param) {
      this.$emit("Import", param);
      if (param == 0) {
        this.ImportSty = "border-bottom:4px solid #0094ff;color:#0094ff";
        this.drawSty = "";
      }
    },
    draw(param) {
      this.$emit("Import", param);
      if (param == 1) {
        this.drawSty = "border-bottom:4px solid #0094ff;color:#0094ff";
        this.ImportSty = "";
      }
    },
    // 清除重绘
    empty() {
      this.$emit("empty");
    },
    // 打开加载空域的方法
    open() {
      this.dialogVisible = true;
    },
  },
  computed: {
    ...mapState(["navMessage", "token", "user"]),
  },
  created() {
    if (this.flag == 0) {
      this.ImportSty = "border-bottom:4px solid #0094ff;color:#0094ff";
      this.drawSty = "";
    }
  },
};
</script>

<style lang="less" scoped>
.warzone {
  margin-top: 15px;
  float: left;
}
.partition {
  margin-top: 15px;
  float: left;
}
/deep/.el-form-item__error {
  left: 150px;
}
/deep/.el-form-item__label {
  width: 150px !important;
}
/deep/.el-input {
  width: 220px;
}
/deep/.el-dialog {
  height: 750px !important;
  overflow-y: auto;
  margin-top: 10vh;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-divider--horizontal {
  margin: 12px 0;
}
/deep/.el-range-editor--medium.el-input__inner {
  width: 220px;
}
.hovers {
  float: left;
  margin-top: 70px;
  margin-left: 20px;
  .hoverBar {
    .yellow {
      color: #faad14;
      float: left;
      text-align: center;
      margin-top: 10px;
      margin-left: 8px;
    }
    .sign {
      color: #a6aab8;
      font-size: 12px;
      line-height: 20px;
      margin-left: 30px;
      margin-right: 5px;
    }
    /deep/ .el-popper {
      top: 680px;
      left: 68%;
      padding: 0;
    }
  }
}
.snapshotbarOne {
  float: left;
  line-height: 45px;
  margin-top: 13px;
  margin-left: 150px;
}
.snapshotbar {
  float: left;
  line-height: 45px;
  margin-top: 13px;
}
.imgupload {
  font-size: 0;
  width: 224px;
  height: 130px;
  opacity: 0;
  cursor: pointer;
  position: absolute;
}
.snapshotBar {
  width: 224px;
  height: 130px;
  background-color: #f6f7f9;
  border: 1px dashed rgba(0, 0, 0, 0.15);
  .uploadImg {
    margin-left: 89px;
    margin-top: 20px;
  }
  .describe {
    color: #838a9d;
    font-size: 13px;
    text-align: center;
    line-height: 14px;
  }
}
.apply {
  width: 150px;
  height: 200px;
  background-color: #fff;
  position: absolute;
  top: 2%;
}
.palyBar {
  text-align: center;
}
.imgBar {
  cursor: pointer;
  margin-top: 15px;
}
.wordBar {
  font-size: 14px;
  color: #b4b4b4;
  line-height: 36px;
  margin-bottom: 10px;
}
.bodyBar {
  width: 80%;
  height: 100%;
  margin-left: 5%;
  margin-top: 20px;
}
.el-button--text {
  color: #3c4353;
  font-size: 18px;
}
.el-button--text:hover {
  color: #0094ff;
  border-bottom: 4px solid #0094ff;
}
.rightBar {
  width: 350px;
  height: 93%;
  position: absolute;
  right: 1%;
  top: 2%;
  background-color: #fff;
  .bodybar {
    width: 80%;
    margin-top: 5%;
    margin-left: 10%;
    display: grid;
    .title {
      font-size: 20px;
      color: #a3aaba;
      float: left;
      margin-bottom: 20px;
    }
    .fmNav {
      display: flex;
      justify-content: space-around;
      margin-bottom: 30px;
    }
    .uploadBar {
      display: contents;
    }
    .drawair {
      display: contents;
    }
    .careful {
      color: #a6a6a6;
      font-size: 12px;
      text-align: center;
      margin: 12px auto;
      width: 192px;
    }
    .upWord {
      font-size: 14px;
      color: #a6a6a6;
    }
    .divider {
      height: 1px;
      border: 1px dashed #e3e3e3;
      width: 100%;
      margin-top: 60px;
    }
    .results {
      width: 100%;
      margin-top: 80px;
      .resultsTitle {
        color: #b1b1b1;
        font-size: 14px;
      }
      .exhibition {
        width: 100%;
        height: 220px;
        border: 1px solid #dedede;
        margin-top: 10px;
        border-radius: 2px;
        text-align: center;
        padding: 0 18px 0 18px;
        overflow-y: auto;
        .guide {
          font-size: 12px;
          line-height: 36px;
        }
        .flyInformation {
          font-size: 12px;
          color: #0094ff;
          text-align: center;
          float: left;
          line-height: 20px;
        }
        .titleword {
          font-size: 12px;
          color: #0094ff;
          text-align: center;
          float: left;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
