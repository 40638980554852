<template>
  <div
    class="releaseTask">
    <el-dialog
      :title="title"
      :visible.sync="releaseShow"
      width="382px"
      :before-close="handleClose"
      :destroy-on-close="true"
      :modal="false"
      v-dialogDrag
      v-loading="loading"
      element-loading-text="上传中"
    >
      <div class="releaseBody">
        <el-radio-group v-model="labelSelect" size="small" @change="changeTest">
          <el-radio-button label="releasePeople">发布人</el-radio-button>
          <el-radio-button label="taskDescription">任务描述</el-radio-button>
          <el-radio-button label="taskTest">任务面积</el-radio-button>
          <el-radio-button label="supplement">补充说明</el-radio-button>
        </el-radio-group>
        <div style="margin: 15px"></div>
        <div class="formBody">
          <table class="fixedVal" id="releasePeople">
            <tr>
              <td>账户</td>
              <td :title="userInfo.mobile">{{ userInfo.mobile }}</td>
            </tr>
            <tr>
              <td>账户类型</td>
              <td :title="userInfo.role_name">{{ userInfo.role_name }}</td>
            </tr>
            <tr>
              <td>单位</td>
              <td :title="unit_name">
                {{ userInfo.unit_name }}
              </td>
            </tr>
            <tr>
              <td>账户名称</td>
              <td :title="userInfo.name">{{ userInfo.name }}</td>
            </tr>
            <tr v-if="title === '发布任务'">
              <td>*发布方式</td>
              <td>
                <el-radio
                  v-model="publishType"
                  label="一键发布"
                  @change="selectPublishType"
                  >一键发布</el-radio
                >
                <el-radio
                  v-model="publishType"
                  label="详细发布"
                  @change="selectPublishType"
                  >详细发布</el-radio
                >
              </td>
            </tr>
          </table>
          <el-divider></el-divider>
          <el-form
            ref="ruleForm"
            :model="detailDescription"
            :rules="rules"
            label-width="98px"
            class="demo-ruleForm"
            label-position="left"
            :id="taskDescription"
          >
            <span v-if="publishType === '详细发布'">
              <el-form-item label="任务类型" prop="task_type">
                <el-radio v-model="taskType" label="无人机">无人机</el-radio>
                <el-radio v-show="publishType==='一键发布'" v-model="taskType" label="非无人机"
                  >非无人机</el-radio
                >
              </el-form-item>
              <el-form-item label="任务名称" prop="task_name">
                <span style="width: 62%; display: inline-block">
                  <el-input v-model="detailDescription.task_name"></el-input>
                </span>
              </el-form-item>
              <el-form-item label="授权空域" prop="auth_area">
                <el-radio v-model="detailDescription.auth_area" label="有"
                  >有</el-radio
                >
                <el-radio v-model="detailDescription.auth_area" label="无"
                  >无</el-radio
                >
                <el-radio v-model="detailDescription.auth_area" label="申请中"
                  >申请中</el-radio
                >
              </el-form-item>
              <el-form-item label="传感器" prop="sensor_type">
                <el-select
                  v-model="detailDescription.sensor_type"
                  placeholder="请选择"
                  @change="selectSensorType"
                >
                  <el-option
                    v-for="item in sensor_type"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="影像GSD"
                prop="data_requirements"
                v-show="detailDescription.sensor_type.indexOf('LIDAR') === -1"
              >
                <span
                  v-for="(item, index) in sensors[
                    detailDescription.sensor_type
                  ]['gsdTypes']"
                >
                  <el-radio
                    v-model="detailDescription.data_requirements"
                    :label="item.value"
                    :value="item.checked"
                    @change="radioSelectDataRequirements"
                    >{{ item.value }}</el-radio
                  >
                </span>
                <div class="otherVal">
                  <el-input
                    v-model="data_requirements"
                    placeholder="其他"
                    class="otherInput"
                    @change="changeData_requirements"
                  >
                    <span slot="suffix" style="margin-right: 10px">cm</span>
                  </el-input>
                </div>
              </el-form-item>

              <el-form-item
                label="航向重叠度"
                prop="longitudinal_overlap"
                v-show="detailDescription.sensor_type.indexOf('LIDAR') === -1"
              >
                <span style="width: 62%; display: inline-block">
                  <el-input v-model="detailDescription.longitudinal_overlap">
                    <div slot="suffix" class="">
                      %
                      <!-- <div
                        class="el-icon-arrow-up"
                        @click="addPercent('longitudinal_overlap')"
                      ></div>
                      <div
                        class="el-icon-arrow-down"
                        @click="subPercent('longitudinal_overlap')"
                      ></div> -->
                    </div>
                  </el-input>
                </span>
              </el-form-item>
              <el-form-item
                label="点密度"
                v-show="detailDescription.sensor_type.indexOf('LIDAR') !== -1"
                prop="pts"
              >
                <span style="width: 62%; display: inline-block">
                  <el-input
                    v-model="detailDescription.pts"
                    placeholder="请输入"
                  >
                    <div slot="suffix">pts/m²</div>
                  </el-input>
                </span>
              </el-form-item>
              <el-form-item
                label="影像GSD"
                v-show="detailDescription.sensor_type === 'LIDAR+正射'"
              >
                <span style="width: 62%; display: inline-block">
                  <el-input
                    v-model="detailDescription.data_requirements"
                    placeholder="请输入"
                    type="number"
                  >
                    <div slot="suffix">cm</div>
                  </el-input>
                </span>
              </el-form-item>
              <el-form-item label="旁向重叠度" prop="lateral_overlap">
                <span style="width: 62%; display: inline-block">
                  <el-input v-model="detailDescription.lateral_overlap">
                    <div slot="suffix" class="arrowUpAndDownAll">
                      <div>%</div>
                      <!-- <div class="arrowUpAndDown">
                        <div
                        class="el-icon-arrow-up"
                        @click="addPercent('lateral_overlap')"
                      ></div>
                      <div
                        class="el-icon-arrow-down"
                        @click="subPercent('lateral_overlap')"
                      ></div>
                      </div> -->
                    </div>
                  </el-input>
                </span>
              </el-form-item>
              <el-form-item
                label="航向重叠度"
                v-show="detailDescription.sensor_type === 'LIDAR+正射'"
              >
                <span style="width: 62%; display: inline-block">
                  <el-input
                    v-model="detailDescription.longitudinal_overlap"
                    placeholder="请输入"
                    type="number"
                  >
                    <div slot="suffix">%</div>
                  </el-input>
                </span>
              </el-form-item>
              <el-form-item label="成果类型" prop="result_type">
                <el-select
                  v-model="detailDescription.result_type"
                  placeholder="请选择"
                  multiple
                  collapse-tags
                >
                  <el-option
                    v-for="item in sensors[detailDescription.sensor_type][
                      'resultTypes'
                    ]"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="成果精度" prop="result_precision">
                <el-radio
                  v-model="detailDescription.result_precision"
                  label="1:500"
                  @change="oneSelectPrecision"
                  >1:500</el-radio
                >
                <el-radio
                  v-model="detailDescription.result_precision"
                  label="1:1000"
                  @change="oneSelectPrecision"
                  >1:1000</el-radio
                >
                <el-radio
                  v-model="detailDescription.result_precision"
                  label="1:2000"
                  @change="oneSelectPrecision"
                  >1:2000</el-radio
                >
                <div class="otherVal">
                  <el-input
                    v-model="result_precision"
                    placeholder="其他"
                    @change="changeResultPrecision"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item label="截止工期" prop="end_date">
                <span class="otherVal" style="display: inline-block; margin: 0">
                  <el-date-picker
                    v-model="detailDescription.end_date"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="截止日期"
                  >
                  </el-date-picker>
                </span>
              </el-form-item>
            </span>
            <span v-else id="aKey">
              <el-form-item label="任务类型" prop="task_type">
                <el-radio v-model="aKeyDescription.task_type" label="无人机"
                  >无人机</el-radio
                >
                <el-radio v-model="aKeyDescription.task_type" label="非无人机"
                  >非无人机</el-radio
                >
              </el-form-item>
              <el-form-item label="任务描述" prop="description">
                <span style="width: 62%; display: inline-block">
                  <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="文本描述"
                    v-model="aKeyDescription.description"
                  >
                  </el-input>
                </span>
              </el-form-item>
              <el-divider style="margin-top: 14px"></el-divider>
            </span>
          </el-form>

          <div id="taskTest">
            <table class="fixedVal" v-if="title === '发布任务'">
              <tr>
                <td>任务面积</td>
                <td>
                  共<span class="measurementArea">{{
                    listDrawShapeData[2].length + kmlShapeCount
                  }}</span
                  >个区块，累计面积<span
                    class="measurementArea"
                    :title="totalArea + `Km²`"
                    >{{ totalArea }}</span
                  >km²
                </td>
              </tr>
            </table>
            <span v-for="(item, key, index) in selectShape" :key="key">
              <div class="testArea">
                <div>任务{{ index + 1 }}</div>
                <div>
                  <img
                    src="@/assets/img/homeDialog/taskTestArea.png"
                    alt=""
                    width="12px"
                  />
                  <span class="area">{{ item.area }}Km²</span>
                </div>
              </div>
              <div class="location">
                <img
                  src="@/assets/img/centerHome/onlinetask.png"
                  alt=""
                  width="14px"
                />
                <span>{{ item.location }}</span>
              </div>
            </span>
          </div>
          <div>
            <table class="fixedVal">
              <tr>
                <td style="position: relative; top: -29px">补充说明</td>
                <td>
                  <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="文本描述"
                    v-model="applyForm.additional_remarks"
                  >
                  </el-input>
                </td>
              </tr>
            </table>
            <table class="fixedVal" id="supplement">
              <tr>
                <td>附件</td>
                <td>
                  <div class="files">
                    <div
                      class="file"
                      v-for="item in fileList"
                      :class="{ canLoad: title === '任务详情' }"
                    >
                      <span v-if="item.name">
                        {{ item.name }}
                      </span>
                      <span v-else>
                        {{ item.source_name }}
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <el-upload
                    action="aaaa"
                    accept="All"
                    :show-file-list="false"
                    :file-list="fileList"
                    :on-change="FileUpload"
                    :auto-upload="false"
                    :on-remove="handleRemove"
                  >
                    <span class="uploadBtn" slot="trigger">
                      <img src="@/assets/img/upload.png" alt="" width="20" />
                      上传
                    </span>
                    <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
                  </el-upload>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <span v-if="title === '发布任务'" slot="footer" class="dialog-footer">
        <el-button :disabled="canPost" @click="saveDraft">保存草稿</el-button>
        <el-button :disabled="canPost" type="primary" @click="saveOrder"
          >确定</el-button
        >
      </span>
      <span v-else slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveAkeyTask" :disabled="canPost">保存</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="promptDia" width="382px">
      <i
        class="el-icon-warning"
        style="color: #faad14; font-size: 21px; margin-left: 15px"
      ></i>
      <span>请对该任务进行派单。</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >外部协作</el-button
        >

        <el-button type="primary" @click="dialogVisible = false"
          >内部派单</el-button
        >
        <el-button @click="dialogVisible = false">中止</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import sensors from "./sensors";
import coordtransform from "@/utils/transform.js";
import { v4 as uuidv4 } from "uuid";
import getDate from "@/utils/time.js";
const circleToPolygon = require("circle-to-polygon");
export default {
  props: {
    listDrawShapeData: {
      type: Array,
    },
    releaseShow: {
      type: Boolean,
    },
    title: {
      type: String,
      default: "发布任务",
    },
    userInfo: {
      type: Object,
    },
    isSelfDraw: {
      type: Boolean,
      default: true,
    },
    kmlData: {
      type: Object,
      default: () => {},
    },
    isWgs: {
      type: Boolean,
      default: false,
    },
    completeData: {
      //完善信息时的文件和补充说明
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      labelSelect: "releasePeople",
      publishType: "一键发布",
      taskType: "无人机",
      gsd: "four", //影像
      stopDate: "",
      // userInfo: {}, //发布人信息，即当前登录用户信息
      unit_name: "", //单位
      selectShape: {},
      ruleForm: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      data_requirements: "", //影像GSD，其他输入
      result_precision: "", //成果精度，其他输入
      detailDescription: {
        task_type: "无人机",
        task_name: "",
        auth_area: "有",
        sensor_type: "正射",
        data_requirements: "8cm",
        longitudinal_overlap: "80",
        lateral_overlap: "50",
        result_type: [],
        result_precision: "1:500",
        end_date: "",
        // pts: "",
        description: "-1",
        local_id: uuidv4().replace(/[-]/g, ""),
      },
      aKeyDescription: {
        task_type: "无人机",
        description: "",
        local_id: uuidv4().replace(/[-]/g, ""),
      },
      applyForm: {
        action: "public",
        created_type: "一键发布",
        survey_area: {},
        survey_area_count: 0,
        survey_area_area: 0,
        order_source: "调度中心端",
        description: {},
        additional_remarks: "",
        // task_name: "",
      },
      survey_area: [],
      aKeyForm: {
        task_type: "无人机",
        description: "",
      },
      sensor_type: [
        {
          value: "正射",
          label: "正射",
        },
        {
          value: "倾斜",
          label: "倾斜",
        },
        {
          value: "LIDAR",
          label: "LIDAR",
        },
        {
          value: "LIDAR+正射",
          label: "LIDAR+正射",
        },
      ],
      value: "",
      rules: {
        task_name: [{ required: true, message: " ", trigger: "blur" }],
        task_type: [{ required: true, message: " ", trigger: "blur" }],
        auth_area: [{ required: true, message: " ", trigger: "blur" }],
        sensor_type: [{ required: true, message: " ", trigger: "blur" }],
        data_requirements: [{ required: true, message: " ", trigger: "blur" }],
        longitudinal_overlap: [
          { required: true, message: " ", trigger: "blur" },
        ],
        lateral_overlap: [{ required: true, message: " ", trigger: "blur" }],
        result_type: [
          { type: "array", required: true, message: " ", trigger: "change" },
        ],
        result_precision: [{ required: true, message: " ", trigger: "blur" }],
        end_date: [{ required: true, message: " ", trigger: "blur" }],
        // pts: [{ required: true, message: " ", trigger: "blur" }],
        description: [{ required: true, message: " ", trigger: "blur" }],
      },
      sensors: [],
      fileList: [], //文件列表
      fileData: [], //文件
      canPost: true,
      promptDia: false, //完善信息后的弹窗
      kmlShapeCount: 0,
      taskDescription: "aKey", //任务描述动态ID
      loading: false
    };
  },
  mounted() {
    this.init();
    window.addEventListener("scroll", this.onScroll, true);
  },
  methods: {
    ...mapMutations(["setOrderQty"]),
    init() {
      if (this.title === "发布任务") {
        // this.setUserInfo();
        this.dialogVisible = this.releaseShow;
        // this.selectShape = this.listDrawShapeData[0]
        if (this.isSelfDraw) {
          this.selfDrawShape();
        } else {
          this.kmlDrawShape();
        }
      } else {
        this.publishType = "详细发布";
      }
      if (this.title === "完善任务") {
        this.fileList = this.completeData.fileList;
        this.applyForm.additional_remarks =
          this.completeData.additional_remarks;
      }
      this.sensors = sensors;
      // this.getOrderQty()
    },
    getPathByBoundPoints(boundPoints) {
      let lat1, lng1, lat2, lng2;
      lat1 = boundPoints[0].lat;
      lng1 = boundPoints[0].lng;
      lat2 = boundPoints[2].lat;
      lng2 = boundPoints[2].lng;
      const maxLat = lat1 > lat2 ? lat1 : lat2;
      const minLat = lat1 < lat2 ? lat1 : lat2;
      const maxLng = lng1 > lng2 ? lng1 : lng2;
      const minLng = lng1 < lng2 ? lng1 : lng2;
      return [
        new AMap.LngLat(minLng, maxLat),
        new AMap.LngLat(maxLng, maxLat),
        new AMap.LngLat(maxLng, minLat),
        new AMap.LngLat(minLng, minLat),
      ];
    },
    // 自己绘制的
    selfDrawShape() {
    const newListDrawShapeId = this.listDrawShapeData[2].sort(function(a, b){ return a - b });
      newListDrawShapeId.forEach((item, index) => {
        this.selectShape[item] = {};
        let temData = {};
        let c = this.listDrawShapeData[0][item];
        let str = "";
        let task_coordinate_points = "";
        temData["survey_area_name"] = "区块" + (index + 1);
        temData["isKml"] = !this.isSelfDraw;
        let local_id = uuidv4().replace(/[-]/g, "");
        temData["survey_area_guid"] = local_id;
        temData["local_id"] = local_id;

        temData["survey_area_area"] = c.area;
        temData["survey_street_name"] = c.location;
        temData["tasks"] = [{}];
        temData["tasks"][0]["flight_task_name"] = "任务" + (index + 1);
        temData["tasks"][0]["flight_task_area"] = c.area;
        if (c.shapeType !== "circle") {
          let path = c.getPath();
          if (c.shapeType === "polygon") {
            temData["block_type"] = 0;
          } else {
            temData["block_type"] = 1;
            path = this.getPathByBoundPoints(path);
          }
          path.forEach((data) => {
            if (this.isWgs) {
              let wgsLnglat = coordtransform.wgs84togcj02(data.R, data.Q);
              data.lng = wgsLnglat[0];
              data.lat = wgsLnglat[1];
            }
            str = str + data.lng + "," + data.lat + ";";
            task_coordinate_points =
              task_coordinate_points + data.lat + "," + data.lng + ";";
          });
          temData["survey_block_type"] = "polygon";

        } else {
          temData["block_type"] = 0;
          let center = c.getCenter();
          let radius = c.getRadius();
          // str = center.lng + "," + center.lat + "," + radius;
          if (this.isWgs) {
            let wgsLnglat = coordtransform.wgs84togcj02(center.R, center.Q);
            center.lng = wgsLnglat[0];
            center.lat = wgsLnglat[1];
          }
          const coordinates = [center.lng, center.lat]; //[lon, lat]
          const options = { numberOfEdges: 32 }; //optional, that defaults to { numberOfEdges: 32 }

          let polygon = circleToPolygon(coordinates, radius, options);
          polygon.coordinates[0].forEach((item) => {
            str = str + item[0] + "," + item[1] + ";";
          });
          temData["survey_block_type"] = "polygon";
        }
        temData["survey_area_coordinate_points"] = str;
        temData["tasks"][0]["task_coordinate_points"] = str;
        this.survey_area.push(temData);
      });
      for (let key in this.listDrawShapeData[0]) {
        this.$set(
          this.selectShape[key],
          "area",
          this.listDrawShapeData[0][key]["area"].toFixed(2)
        );
        this.$set(
          this.selectShape[key],
          "location",
          this.listDrawShapeData[0][key]["location"]
        );
        this.aKeyDescription["task_name"] = this.listDrawShapeData[0][key]["location"].replace(/ /g, "");
        this.$forceUpdate();
      }
      this.applyForm.survey_area_count = this.listDrawShapeData[2].length;
      this.applyForm.survey_area_area = this.totalArea;
      this.applyForm.survey_area = this.survey_area;
    },
    // kml导入的
    kmlDrawShape() {
      const kmlDataLength = Object.keys(this.kmlData).length;
      this.kmlShapeCount = kmlDataLength;
      if (kmlDataLength > 0) {
        this.applyForm.survey_area_count =
          this.applyForm.survey_area_count + kmlDataLength;
        for (let key in this.kmlData) {
          if (!this.aKeyDescription["task_name"] || this.aKeyDescription["task_name"] === "")
            {this.aKeyDescription["task_name"] = this.kmlData[key]["location"].replace(/ /g, "");}

          this.selectShape["kml" + key] = {};
          this.$set(
            this.selectShape["kml" + key],
            "area",
            this.kmlData[key].area.toFixed(2)
          );
          this.$set(
            this.selectShape["kml" + key],
            "location",
            this.kmlData[key].location
          );
          this.$forceUpdate();
          let temData = {};
          let str = "";
          temData["survey_area_name"] = "区块" + (Number(key) + 1);
          temData["isKml"] = !this.isSelfDraw;
          let local_id = uuidv4().replace(/[-]/g, "");
          temData["survey_area_guid"] = local_id;
          temData["local_id"] = local_id;
          temData["survey_area_area"] = this.kmlData[key].area;
          temData["survey_street_name"] = this.kmlData[key].location;
          temData["tasks"] = [{}];
          temData["tasks"][0]["flight_task_name"] = "任务" + (Number(key) + 1);
          temData["tasks"][0]["flight_task_area"] = this.kmlData[key].area;
          let path = this.kmlData[key].path;
          path.forEach((data) => {
            str = str + data.lng + "," + data.lat + ";";
          });
          temData["survey_block_type"] = "polygon";
          temData["block_type"] = 0;
          temData["survey_area_coordinate_points"] = str;
          temData["tasks"][0]["task_coordinate_points"] = str;
          this.survey_area.push(temData);
        }
      }

      this.applyForm.survey_area_area = this.totalArea;
      this.applyForm.survey_area = this.survey_area;
      this.applyForm.survey_area_count = kmlDataLength;
    },
    changeTest(name) {
      if (name === "taskDescription") {
        if (this.publishType === "一键发布") {
          name = "aKey";
        } else {
          name = "detailed";
        }
      }
      this.jump(name);
    },
    selectPublishType() {
      this.applyForm.created_type = this.publishType;
      if (this.applyForm.created_type === "一键发布") {
        this.taskDescription = "akey"; //任务描述动态ID
        // this.canPost = true;
      } else {
        this.taskDescription = "detailed"; //任务描述动态ID
        // this.canPost = true;
      }
    },
    jump(id) {
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
    handleClose() {
      this.dialogVisible = false;
      this.$emit("closeForm");
    },
    // 保存草稿
    saveDraft() {
      this.applyForm.action = "new_draft";
      this.saveOrder();
    },
    // 完善信息时的保存按钮
    saveAkeyTask() {
      let detailDescription = JSON.parse(
        JSON.stringify(this.detailDescription)
      );
      if (detailDescription.description === "-1") {
        delete detailDescription.description;
      }
      // detailDescription.additional_remarks = this.applyForm.additional_remarks
      if (
        detailDescription.data_requirements &&
        this.data_requirements !== ""
      ) {
        detailDescription.data_requirements = this.data_requirements + "cm";
      }
      if (detailDescription.result_precision && this.result_precision !== "") {
        detailDescription.result_precision = this.result_precision;
      }
      const form = {
        id: this.userInfo.id,
        description: JSON.stringify(detailDescription),
        additional_remarks: this.applyForm.additional_remarks,
      };
      console.log(form);
      this.$apis
        .demandCompleteOrder(form, this.token)
        .then((data) => {
          if (data.data.status === 0) {
            this.$message({
              type: "success",
              message: data.data.message,
            });
            let id = this.userInfo.id;
            if (this.fileList.length > 0) {
              this.fileData.forEach((file) => {
                file.append("action", "upload");
                file.append("id", id);
                this.$apis.demandSaveOrder(file, this.token).then((data) => {});
              });
            }
            this.handleClose();
            this.$emit("completeAkeyOrder");
            this.promptDia = true;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 发布任务
    saveOrder() {
      this.loading = true
      let detailDescription = JSON.parse(
        JSON.stringify(this.detailDescription)
      );
      if (detailDescription.sensor_type === "LIDAR") {
        delete detailDescription["data_requirements"];
        delete detailDescription["longitudinal_overlap"];
      }
      if (
        detailDescription.data_requirements &&
        this.data_requirements !== ""
      ) {
        detailDescription.data_requirements = this.data_requirements + "cm";
      }
      if (detailDescription.result_precision && this.result_precision !== "") {
        detailDescription.result_precision = this.result_precision;
      }
      if (this.publishType === "一键发布") {
        this.applyForm.description = JSON.stringify(this.aKeyDescription);
      } else {
        delete detailDescription.description;
        if (detailDescription.longitudinal_overlap) {
          if (detailDescription.longitudinal_overlap.indexOf("%") === -1) {
            detailDescription.longitudinal_overlap =
              detailDescription.longitudinal_overlap + "%";
          }
        }
        if (detailDescription.pts) {
          detailDescription.pts = detailDescription.pts + "pts/m²";
        }
        if (detailDescription.lateral_overlap.indexOf("%") === -1) {
          detailDescription.lateral_overlap =
            detailDescription.lateral_overlap + "%";
        }
        this.applyForm.description = JSON.stringify(detailDescription);
      }
      this.applyForm.survey_area = JSON.stringify(this.applyForm.survey_area);
      this.$apis
        .demandSaveOrder(this.applyForm, this.token)
        .then((data) => {
          if (data.data.status === 0) {
            this.$message({
              type: "success",
              message: data.data.message,
            });
            let id = data.data.data;
            if (this.fileList.length > 0) {
              this.fileData.forEach((file) => {
                file.append("action", "upload");
                file.append("id", id);
                this.$apis.demandSaveOrder(file, this.token).then((data) => {});
              });
            }
            this.handleClose();
            this.getOrderQty();
            this.loading = false

          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取列表未查看的数量
    getOrderQty() {
      this.$apis
        .demandListOrder({ task_type: "无人机" }, this.token)
        .then((res) => {
          this.setOrderQty(res.data.data.order_qty);
        });
    },
    // 传感器选择
    selectSensorType(data) {
      if (data.indexOf("LIDAR") !== -1) {
        if (!this.detailDescription["pts"]) {
          this.$set(this.detailDescription, "pts", "");
          this.$set(this.rules, "pts", [
            { required: true, message: " ", trigger: "blur" },
          ]);
        }
      } else {
        if (this.detailDescription["pts"]) {
          delete this.detailDescription["pts"];
          delete this.rules["pts"];
        }
      }
      console.log(this.detailDescription.sensor_type);
      if (this.detailDescription.sensor_type === "LIDAR+正射") {
        this.detailDescription.longitudinal_overlap = "";
        this.detailDescription.data_requirements = "";
        this.rules.longitudinal_overlap[0].required = false;
        this.rules.data_requirements[0].required = false;
      } else if (this.detailDescription.sensor_type === "LIDAR") {
        this.rules.longitudinal_overlap[0].required = false;
      } else {
        this.detailDescription.longitudinal_overlap = "";
        this.detailDescription.data_requirements = "";
        this.rules.longitudinal_overlap[0].required = true;
        this.rules.data_requirements[0].required = true;
      }

      for (let key in this.sensors[data]["gsdTypes"]) {
        if (this.sensors[data]["gsdTypes"][key].checked) {
          this.detailDescription.data_requirements =
            this.sensors[data]["gsdTypes"][key].value;
        }
      }
      this.detailDescription.lateral_overlap =
        this.sensors[data].lateral_overlap;
      this.detailDescription.result_type = [];
    },
    // 百分比加减
    addPercent(name) {
      let number = this.detailDescription[name].split("%");
      let totalNumber = Number(number[0]);
      let data = totalNumber + 1;
      this.detailDescription[name] = data + "%";
    },
    subPercent(name) {
      let number = this.detailDescription[name].split("%");
      let totalNumber = Number(number[0]);
      let data = totalNumber - 1;
      this.detailDescription[name] = data + "%";
    },
    // 监听滑动
    onScroll() {
      let formBody = document.getElementsByClassName("formBody")[0];
      let scrolled = formBody.scrollTop;
      let windowHeight = formBody.clientHeight;
      let scrollHeight = formBody.scrollHeight;
      let releasePeople = document.getElementById("releasePeople").scrollHeight;
      let detailed = document.getElementById(this.taskDescription).clientHeight;
      if (scrolled === 0) {
        this.labelSelect = "releasePeople";
      }
      if (releasePeople <= scrolled && scrolled < detailed + releasePeople) {
        this.labelSelect = "taskDescription";
      }
      if (detailed + releasePeople <= scrolled) {
        this.labelSelect = "taskTest";
      }
      if (scrolled + windowHeight === scrollHeight) {
        this.labelSelect = "supplement";
      }
    },
    FileUpload(file, fileList) {
      this.fileList = fileList;
      // if (fileList.length > 0) {
      //   this.fileList = [fileList[fileList.length - 1]]; // 这一步，是 展示最后一次选择的文件
      // }

      const formData = new FormData();
      formData.append("file", file.raw);
      this.fileData.push(formData);
      // console.log(this.fileData.get("file"),"FileUpload", file.raw);
    },
    handleRemove(file, fileList) {
      this.fileData = this.formData.delete("file");
    },
    // 修改影像gsd
    changeData_requirements(val) {
      this.detailDescription.data_requirements = -1;
    },
    // 单选影像gsd
    radioSelectDataRequirements(val) {
      this.data_requirements = "";
    },
    // 单选成果精度
    oneSelectPrecision(val) {
      this.result_precision = "";
    },
    //修改成果精度
    changeResultPrecision(val) {
      this.detailDescription.result_precision = -1;
    },
  },
  computed: {
    totalArea() {
      let area = 0;
      if (this.title === "发布任务") {
        let selectShape = this.listDrawShapeData[0];
        for (let key in selectShape) {
          area = area + selectShape[key].area;
        }
        for (let key in this.kmlData) {
          area = area + this.kmlData[key].area;
        }
      }

      return area.toFixed(2);
    },
    ...mapState(["token"]),
  },
  watch: {
    detailDescription: {
      handler(newName, oldName) {
        if (this.$refs.ruleForm)
          this.$refs.ruleForm.validate((valid) => {
            if (valid) {
              console.log("填写完毕");
              this.canPost = false;
            } else {
              this.canPost = true;
              return false;
            }
          });
      },
      deep: true,
    },
    aKeyDescription: {
      handler(newName, oldName) {
        if (this.publishType === "一键发布") {
          if (newName.description !== "") {
            this.canPost = false;
          } else {
            this.canPost = true;
          }
        }
      },
      deep: true,
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
};
</script>

<style lang="scss" scoped>
.releaseTask {
  /deep/.el-dialog__body {
    padding: 0;
  }
  /deep/.el-dialog__header {
    text-align: left;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
  /deep/.el-form-item__label {
    font-size: 13px;
    color: #666666;
    font-weight: 400;
  }
  /deep/.el-form-item {
    margin-bottom: 5px;
  }
  /deep/.el-divider--horizontal {
    margin-top: 0;
    margin-bottom: 16px;
  }
  /deep/.el-input {
    width: 106%;
    height: 32px;
    line-height: 32px;
  }
  /deep/.el-radio {
    margin-right: 10px;
    color: #c0c4cc;
  }
  /deep/.el-radio__label {
    font-size: 13px;
    padding-left: 5px;
  }
  /deep/.el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  .releaseBody {
    width: 382px;
    height: 533px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 24px 14px 27px 27px;
    display: flex;
    flex-direction: column;
    ::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      // border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #c2c6cc;
    }

    ::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      -webkit-box-shadow: inset 0 0 5px #fff;
      border-radius: 10px;
      background: transparent;
    }
    * {
      scrollbar-color: #000 #6d6d83; /* 滑块颜色  滚动条背景颜色 */
      scrollbar-width: thin; /* 滚动条宽度有三种：thin、auto、none */
    }
    .formBody {
      overflow: auto;
      padding-right: 18px;
      .uploadBtn {
        width: 83px;
        height: 32px;
        border-radius: 2px;
        border: 1px solid #838a9d;
        font-size: 14px;
        color: #838a9d;
        display: flex;
        align-items: center;
        // margin-left: 1.45vw;
        margin-top: 10px;
        // position: absolute;
        // right: 29%;
        // bottom: -29px;
        img {
          margin: 0 8px;
        }
      }
      .fixedVal {
        tr {
          line-height: 24px;
          > td:nth-child(1) {
            width: 98px;
            padding-left: 7px;
          }
          > td:nth-child(2) {
            color: #333333;
            display: inline-block;
            width: 223px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        td {
          text-align: left;
          font-size: 13px;
          font-family: MicrosoftYaHei;
          color: #666666;
        }
        .measurementArea {
          font-weight: 600;
          font-size: 21px;
        }
        .files {
          display: flex;
          flex-wrap: wrap;
        }
        .file {
          width: 107px;
          height: 32px;
          color: #1890ff;
          border-radius: 2px;
          border: 1px solid #1890ff;
          line-height: 32px;
          text-align: center;
          overflow: hidden; /*超出部分隐藏*/
          text-overflow: ellipsis; /* 超出部分显示省略号 */
          white-space: nowrap; /*规定段落中的文本不进行换行 */
          margin-right: 3px;
          margin-top: 5px;
        }
        .canLoad {
          cursor: pointer;
        }
      }
      .testArea {
        display: flex;
        font-size: 13px;
        line-height: 30px;
        color: #666666;
        > div:nth-child(1) {
          width: 84px;
          margin-left: 10px;
        }
        .area {
          color: #edad00;
          font-weight: 500;
        }
      }
      .location {
        color: #1989fa;
        margin-left: 94px;
        line-height: 30px;
      }
      .otherVal {
        margin-left: 97px;
        width: 62.2%;
        margin-bottom: 3px;
      }
      /deep/.el-input-group__append {
        height: 32px;
        line-height: 28px;
        background-color: transparent;
        padding: 0;
      }
      .otherInput {
        /deep/.el-input__inner {
          // border-right: none;
        }
      }
      .arrowUpAndDownAll {
        display: flex;
      }
      .arrowUpAndDown {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-right: -4px;
        cursor: pointer;
        height: 100%;
        border-left: 1px solid #d9d9d9;
        .el-icon-arrow-down {
          border-top: 1px solid #d9d9d9;
          width: 21px;
        }
      }
    }
  }
}
</style>
