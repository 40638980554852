import data from "@/assets/corsList.json";

const province = "static/img/cors/cors-province.svg";
const nation = "static/img/cors/cors-country.svg";
const cors927 = "static/img/cors/cors-927.svg";
const ground = "static/img/cors/cors-ground.svg";
const city = "static/img/cors/cors-city.svg";
const clusterIcon = "static/img/cors/cors-clusterer.svg";

export const corsIconList = {
  省站: {
    brand_name: "省站",
    category_name: "cors站",
    clusterer_color: "#96BC00",
    history_sorties_icon: "",
    icon: province
  },
  国家站: {
    brand_name: "国家站",
    category_name: "cors站",
    clusterer_color: "#96BC00",
    icon: nation
  },
  "927": {
    brand_name: "927",
    category_name: "cors站",
    clusterer_color: "#96BC00",
    icon: cors927
  },
  陆态站: {
    brand_name: "陆态站",
    category_name: "cors站",
    clusterer_color: "#96BC00",
    icon: ground
  },
  市站: {
    brand_name: "市站",
    category_name: "cors站",
    clusterer_color: "#96BC00",
    icon: city
  }
};

export function isCorsMarker(config) {
  this.clusterers[clusterType] = new AMap.MarkerClusterer(this.map, [], {
    styles: [
      {
        url: clusterIcon,
        size: [width, height],
        offset: new AMap.Pixel(-width / 2, -height / 2),
        imageOffset: new AMap.Pixel(-width, -height),
        textSize: 18
      }
    ],
    maxZoom: this.isClustering ? 18 : 1, // 根据是否聚合调整默认值
    renderClusterMarker: context => {
      var div = document.createElement("div");
      div.style.width = width + "px";
      div.style.height = height + "px";
      div.innerHTML =
        "<div style='border: 1px solid " +
        clusterColor +
        ";height: 11px; line-height: 13px; min-width: 13px; justify-content: center; border-radius: 3px;background: #fff;font-size: 10px;color: " +
        clusterColor +
        ";font-weight: 500;    position: absolute; " +
        `bottom: ${height -
          width}px;right: 0px;display: flex;align-items: center;'>${
          context.count
        }</div>`;
      div.style.lineHeight = height + "px";
      div.style.color = "#000";
      div.style.textAlign = "center";
      div.style.position = "relative";
      div.style.lineHeight = height + "px";
      div.style.color = clusterColor;
      div.style.fontSize = "10px";
      div.style.textAlign = "center";
      div.style.fontWeight = "500";
      div.style.background = `url(${clusterIcon}) no-repeat center`;
      div.style.backgroundSize = "contain";
      context.marker.setOffset(new AMap.Pixel(-width / 2, -height / 2));
      context.marker.setContent(div);
    }
  });

  return config.category_name.match("cors");
}

export const iconData = getCorsIconData();

function getCorsIconData() {
  const corsList = data.map(item => {
    const { category_name, brand_name, name, lng, lat } = item;
    const { clusterer_color, icon } = corsIconList[brand_name];
    const content = `<div style="color:${clusterer_color};font-size:10px; text-align:center;width: 32px;">${name}</div><style>
    .amap-marker-label{
        border: 0;
        background-color: transparent;
    }
    </style>`;

    return {
      anchor: { x: 0.5, y: 0.5 },
      brand_name,
      category_name,
      clusterColor: clusterer_color,
      clusterIcon,
      clusterType: category_name,
      height: 50,
      iconPath: icon,
      id: name,
      latitude: lat,
      longitude: lng,
      label: {
        offset: [-2, 30],
        content
      },
      type: "online",
      width: 32
    };
  });

  return corsList;
}
