<!-- 发布成果 -->
<template>
  <div class="subject">
    <div class="top">
      <el-form
        class="formbox"
        ref="ruleForm"
        :model="ruleForm"
        label-width="80px"
      >
        <el-form-item class="userForm" prop="catalog" label="数据编号">
          <el-input placeholder="请输入" v-model="ruleForm.catalog"></el-input>
        </el-form-item>
        <el-form-item class="userForm" prop="name" label="数据名称">
          <el-input placeholder="请输入" v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item class="userForm" prop="type" label="数据类型">
          <el-select
            v-model="ruleForm.type"
            @change="getcgType"
            placeholder="请选择"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in serialFormat"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="userForm" prop="publish_way" label="上传媒介">
          <el-select
            v-model="ruleForm.publish_way"
            @change="getUploadType"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in uploadMedia"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="userForm" prop="user_mobile" label="用户账号">
          <el-input
            placeholder="请输入"
            v-model="ruleForm.user_mobile"
          ></el-input>
        </el-form-item>
        <el-form-item class="userForm" prop="unit_id" label="巡护单位">
          <el-select
            v-model="ruleForm.unit_id"
            @change="getUnitId(ruleForm.unit_id)"
            placeholder="请选择(可搜索)"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in unitData"
              :key="index"
              :label="item.unit_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="userForm" prop="task_type" label="挂接任务">
          <el-select
            v-model="ruleForm.task_type"
            @change="getStatus"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <div class="footBtn">
          <div class="fmBtn">
            <el-button class="searchBtn" type="primary" @click="searchBtn"
              >查&nbsp;&nbsp;&nbsp;&nbsp;询</el-button
            >
            <el-button
              class="reset"
              @click="resetForm('ruleForm')"
              type="primary"
              plain
              >重&nbsp;&nbsp;&nbsp;&nbsp;置</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
    <div class="center-body">
      <el-table
        height="85%"
        :stripe="true"
        :header-cell-style="{ background: '#DDE6EF' }"
        :data="releaData"
        style="width: 98%; margin-left: 1%; margin-top: 1%"
      >
        <el-table-column
          align="center"
          prop="catalog"
          label="数据编号"
          width="300"
          class="number"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="数据名称"
          width="150"
        ></el-table-column>
        <el-table-column align="center" label="创建时间" width="180">
          <template slot-scope="scope">
            {{ scope.row.created_at }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="type"
          label="数据类型"
          width="120"
        >
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.type }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="hookTask" label="挂接任务">
          <template slot-scope="scope">
            <span v-if="scope.row.project_name">
              <el-link
                type="primary"
                :underline="false"
                @click="selectTask(scope.row.id)"
                >更改</el-link
              >
            </span>
            <span v-else>
              <el-link
                type="primary"
                :underline="false"
                @click="selectTask(scope.row.id)"
                >挂接</el-link
              >
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="taskDetails" label="任务详情">
          <template slot-scope="scope">
            <span v-if="scope.row.project_name">
              <el-link
                type="primary"
                :underline="false"
                @click="showTaskDesc(scope.row)"
                >查看</el-link
              >
            </span>
            <span v-else>————</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="uploadMediaTable"
          label="上传媒介"
        ></el-table-column>
        <el-table-column align="center" prop="resultspreview" label="预览">
          <template slot-scope="scope">
            <el-link
              @click="locateBtn(scope.row)"
              target="_blank"
              type="primary"
              :underline="false"
              >在线预览</el-link
            >
          </template>
        </el-table-column>
        <el-table-column align="center" label="发布">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="release(scope.row.id)"
              size="mini"
              plain
              >发布</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @current-change="currentchange"
          @next-click="handleNext"
          @prev-click="handlePrev"
          :current-page="current_page"
          :page-size="this.per_page"
          layout="total,  prev, pager, next, jumper"
          :total="this.total"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      :modal="false"
      :width="'80%'"
      :lock-scroll="false"
      :visible.sync="taskSelectDialog"
    >
      <taskSelect
        :selectType="'task'"
        @selectTask="isSelectTask"
        @cancelSelect="taskSelectDialog = false"
      />
    </el-dialog>
    <el-dialog
      title="任务详情"
      :width="'40%'"
      :lock-scroll="false"
      :visible.sync="taskDescDialog"
    >
      <div class="task-desc">
        <div class="block">
          <div class="row">
            <div class="column">
              <div class="title">数据名称</div>
              <div class="content" :title="taskDesc.resultName">
                {{ taskDesc.resultName }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <div class="title">发布账号</div>
              <div class="content">{{ taskDesc.publishAccount }}</div>
            </div>
            <div class="column">
              <div class="title">巡护单位</div>
              <div class="content" :title="taskDesc.unitName">
                {{ taskDesc.unitName }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <div class="title">数据类型</div>
              <div class="content">{{ taskDesc.resultType }}</div>
            </div>
            <div class="column">
              <div class="title">发布时间</div>
              <div class="content">{{ taskDesc.publishTime }}</div>
            </div>
          </div>
        </div>
        <div class="block">
          <div class="row">
            <div class="column">
              <div class="title">任务名称</div>
              <div class="content" :title="taskDesc.taskName">
                {{ taskDesc.taskName }}
              </div>
            </div>
            <div class="column">
              <div class="title">任务类型</div>
              <div class="content">{{ taskDesc.taskType }}</div>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <div class="title">任务日期</div>
              <div class="content">{{ taskDesc.taskTime }}</div>
            </div>
          </div>
        </div>
        <div class="close-btn" @click="taskDescDialog = false">关闭</div>
        <div style="clear: both"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import EarthHelper from "@/components/earthOperator/earthHelper";
import taskSelect from "@/components/task/taskSelect";
export default {
  components: {
    taskSelect,
  },
  data() {
    return {
      num: "stay", //页面是已发布还是待发布
      condition: 0, //  跳转的哪个页面
      serialNumber: "", //  编号
      serialName: "", //  成果名称
      serialFormat: ["Tileset", "Imagery", "Terrain", "Poi", "Model", "VECTOR"], //成果格式
      serialFormatData: "", //成果格式选择数据
      uploadMedia: [
        {
          value: "1",
          label: "dataManage",
        },
        {
          value: "2",
          label: "调度中心直接上传",
        },
      ], // 上传媒介
      uploadMediaData: "", // 上传媒介选择数据
      userAccount: "", //用户账号
      alliedUnits: [], //巡护单位
      alliedUnitsData: "", //巡护单位选择数据
      taskOf: [], //挂接任务
      taskOfData: "", //挂接任务选择数据
      totalNum: 100, //  分页绑定的数量
      nav: "",
      releaData: [],
      current_page: 1, //当前页
      total: 0, //总条目数
      per_page: 10, //每页多少条
      page: 1,
      ruleForm: {
        catalog: "",
        user_mobile: "",
        name: "",
        type: "",
        task_type: "",
        unit_id: "",
        publish_way: "",
      },
      taskSelectDialog: false, //任务选择框
      taskDescDialog: false, //任务详情框
      taskDesc: {
        resultName: "",
        publishAccount: "",
        unitName: "",
        resultType: "",
        publishTime: "",
        taskName: "",
        taskType: "",
        taskTime: "",
      },
      unitData: [],
      options: [
        {
          value: "1",
          label: "航线任务",
        },
        {
          value: "2",
          label: "通用任务",
        },
      ],
    };
  },
  methods: {
    locateBtn(params) {
      const type = EarthHelper.getResultType(params.type);
      const urlParam = {
        url: params.url,
        type: type,
        name: params.name,
      };
      window.open(
        "/result_preview.html?param=" + JSON.stringify(urlParam),
        "_blank"
      );
    },
    ...mapMutations(["currentUrlStorage"]),
    resetForm(ruleForm) {
      this.$refs["ruleForm"].resetFields();
    },
    // 获取巡护ID
    getUnitId(param) {
      this.ruleForm.unit_id = param;
    },
    // 获取任务类型
    getStatus(param) {
      this.ruleForm.task_type = param;
    },
    // 获取成果类型
    getcgType(param) {
      this.ruleForm.type = param;
    },
    // 获取上传媒介
    getUploadType(param) {
      this.ruleForm.publish_way = param;
    },
    // 查询
    searchBtn() {
      this.page = 1;
      this.releaList();
    },
    handleNext() {
      this.page++;
      this.releaList(this.page, this.per_page);
    },
    handlePrev() {
      this.page--;
      this.releaList(this.page, this.per_page);
    },
    currentchange(page) {
      this.page = page;
      this.releaList(this.page, this.per_page);
    },
    release(param) {

      this.$confirm("确定发布该成果吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$apis
            .publishResult(
              {
                id: param,
                publish_type: 1,
              },
              this.token
            )
            .then((data) => {
              this.releaList();
              if (data.data.status == 0) {
                this.$message({
                  message: data.data.message,
                  type: "success",
                  offset: 80,
                });
              } else {
                this.$message({
                  message: data.data.message,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch(() => {});
    },
    fetchData(data) {
      this.nav = data.query.nav;
    },
    releaList() {
      this.$apis
        .listResult(
          {
            publish_type: 0,
            per_page: this.per_page,
            page: this.page,
            name: this.ruleForm.name,
            user_mobile: this.ruleForm.user_mobile,
            catalog: this.ruleForm.catalog,
            type: this.ruleForm.type,
            unit_id: this.ruleForm.unit_id,
            task_type: this.ruleForm.task_type,
            publish_way: this.ruleForm.publish_way,
          },
          this.token
        )
        .then((data) => {
          this.releaData = data.data.data.data;
          this.total = data.data.data.total;
          this.current_page = data.data.data.current_page;
          this.per_page = data.data.data.per_page;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    selectTask(id) {
      this.optionId = id;
      this.taskSelectDialog = true;
    },
    /**
     *选择任务完成事件
     */
    isSelectTask(e) {
      this.$apis
        .relationWithTask(
          {
            id: this.optionId,
            task_id: e.id,
          },
          this.token
        )
        .then((res) => {
          this.taskSelectDialog = false;
          if (res.data.status == 0) {
            this.$message({ type: "success", message: "操作成功" });
            this.releaList();
          } else {
            this.$message({ type: "error", message: res.data.error });
          }
        });
    },
    /**
     * 查看任务详情
     */
    showTaskDesc(data) {
      this.taskDesc.resultName = data.name;
      this.taskDesc.resultType = data.type;
      this.taskDesc.unitName = data.unit_name;
      this.taskDesc.publishTime = data.published_at;
      this.taskDesc.publishAccount = data.user_name;
      this.taskDesc.taskName = data.project_name;
      this.taskDesc.taskType = data.task_type == 1 ? "航线任务" : "通用任务";
      this.taskDesc.taskTime = data.project_created_at;
      this.taskDescDialog = true;
    },
    // 巡护列表
    unitsList() {
      this.$apis
        .unitList({ per_page: 100000000000 }, this.token, "?page=" + this.page)
        .then((data) => {
          if (data.data.status == 0) {
            this.unitData = this.unitData.concat(data.data.data.data);
            this.totalPages = data.data.data.last_page;
            this.loading = false;
            const param = data.data.data.data.id;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取成果类型选项
    getAchievement() {
      this.$apis
        .getInterestType({}, this.token)
        .then((data) => {
          if (data.data.status == 0) {
            let Poi = data.data.data;
            Poi.forEach((element) => {
              this.serialFormat.push(element.data_interest_type);
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  watch: {
    $route: "fetchData",
  },
  computed: {
    ...mapState(["user", "token"]),
  },
  created() {
    this.releaList();
    this.unitsList();
    // this.getAchievement();
  },
  mounted() {
    if (this.$route.query.nav == "stay") {
      this.currentUrlStorage(["首页", "目录查询", "数据发布", "待发布"]);
    }
  },
};
</script>

<style lang="scss" scoped>
.el-tag.el-tag--success {
  background-color: #e7faf0;
  border-color: #1cd0bd;
  color: #1cd0bd;
  width: 80px;
}
/deep/.el-pagination__editor.el-input .el-input__inner {
  width: 40px;
}
/deep/.el-input {
  width: 200px;
}
/deep/.el-form-item {
  float: left;
  margin-right: 20px;
  width: 30%;
}
.online {
  color: #409eff;
  font-size: 14px;
}
.formbox {
  float: left;
  margin-top: 2%;
  width: 100%;
}
.fmBtn {
  float: right;
  width: 35%;
}
.block {
  padding: 20px 0 0 70px;
}
.subject {
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: #f0f0f0;

  .top {
    width: 100%;
    display: flow-root;
    background: rgba(255, 255, 255, 1);
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    .nape {
      margin: 2.664% 2% 0 0;
      display: inline-block;
      width: 22%;
      height: 40px;
      span {
        padding-right: 8.3%;
      }
      .import {
        display: inline-block;
        width: 58.363%;
      }
    }
  }
  .center-body {
    width: 100%;
    height: 75%;
    margin-top: 15px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    position: relative;
    overflow-y: auto;
    /deep/ .el-table .cell {
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
    }
    /deep/ .el-table .cell:hover {
      text-overflow: inherit;
      overflow: visible;
      white-space: pre-line;
    }
    .pagination {
      position: absolute;
      bottom: 4.973%;
      right: 19.216%;
    }
  }
}
.task-desc {
  font-size: 16px;
  .block {
    padding: 30px 45px;
    padding-bottom: 0;
    border-bottom: 1px solid #cfd2da;
    .row {
      display: flex;
      margin-bottom: 30px;
      .column {
        display: flex;
        width: 50%;
        .title {
          margin-right: 35px;
          width: 75px;
          color: #3c4353;
        }
        .content {
          width: 240px;
          color: #838a9d;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .close-btn {
    width: 152px;
    height: 48px;
    margin-top: 23px;
    color: #1182fb;
    border: 1px solid #1182fb;
    line-height: 48px;
    text-align: center;
    font-size: 20px;
    float: right;
    cursor: pointer;
  }
  .close-btn:hover {
    background: #1182fb;
    color: #fff;
  }
}
@media only screen and (max-width: 1344px) {
  .subject {
    .center-body {
      height: 67%;
    }
  }
}
</style>
