<template>
  <div>
    <div class="topBgc">
      <navTop />
      <div class="bgcImg">
        <!-- 超级管理员顶部 -->
        <div v-if="role === 0" class="topImg"></div>
        <!-- 巡护顶部 -->
        <div v-if="role === 1" class="uniter">
          <div class="unitImgOne">
            <div class="topBar">
              <div class="imgBar">
                <img src="../../assets/img/5.png" alt />
              </div>
              <div class="comppy">
                <p class="unitnames">{{ this.user.user_unit.unit_name }}</p>
                <p class="unitnames">{{ this.user.user_unit.unit_code }}</p>
                <div class="userBtn">巡护管理员</div>
              </div>
            </div>
            <div class="taskBar">
              <div class="leftNav">
                <span class="alltask">
                  总任务
                  <i class="iconfont sign" title="指已评价总任务个数"
                    >&#xe616;</i
                  >
                </span>
                <p class="tasknum">
                  {{
                    this.rankingtop.report_user_ranking_for_unit_project_count
                  }}
                </p>
              </div>
              <div class="midNav"></div>
              <div class="rightNav">
                <span class="alltask">
                  分任务
                  <i class="iconfont sign" title="指已评价分任务个数"
                    >&#xe616;</i
                  >
                </span>
                <p class="tasknum">
                  {{ this.rankingtop.report_user_ranking_for_unit_task_count }}
                </p>
              </div>
            </div>
            <div class="divvsider"></div>
            <div class="footNav">
              <span class="rankingword">在全部巡护单位中排名</span>
              <span class="ranking"
                >第{{
                  this.rankingtop.report_user_ranking_for_unit_rank
                }}名</span
              >
            </div>
          </div>
          <div class="unitImgTwo">
            <div id="Myeacharts"></div>
            <div class="score">
              <p class="titleNav">评分</p>
              <div class="scoreline">
                <span class="title">响应速度</span>
                <el-rate
                  :value="Number(speed)"
                  disabled
                  text-color="#ff9900"
                  show-text
                  :texts="csoretext"
                ></el-rate>
              </div>
              <div class="scoreline">
                <span class="title">过程调度</span>
                <el-rate
                  :value="Number(scheduling)"
                  disabled
                  text-color="#ff9900"
                  show-text
                  :texts="csoretext"
                ></el-rate>
              </div>
              <div class="scoreline">
                <span class="title">服务态度</span>
                <el-rate
                  :value="Number(attitude)"
                  disabled
                  text-color="#ff9900"
                  show-text
                  :texts="csoretext"
                ></el-rate>
              </div>
              <div class="scoreline">
                <span class="title">成果质量</span>
                <el-rate
                  :value="Number(results)"
                  disabled
                  text-color="#ff9900"
                  show-text
                  :texts="csoretext"
                ></el-rate>
              </div>
              <div class="scoreline">
                <span class="title">总体评分</span>
                <el-rate
                  :value="Number(overall)"
                  disabled
                  text-color="#ff9900"
                  show-text
                  :texts="csoretext"
                ></el-rate>
              </div>
            </div>
          </div>
        </div>
        <!-- 终端顶部 -->
        <div v-if="role === 2" class="userImg">
          <div class="userImgOne">
            <div class="topBar">
              <div class="imgBar">
                <img src="../../assets/img/5.png" alt />
              </div>
              <div class="comppy">
                <p class="unitnames">{{ this.user.name }}</p>
                <p class="unitnames">{{ this.user.mobile }}</p>
                <div class="userzd">巡护终端用户</div>
              </div>
            </div>
            <div class="taskBar">
              <div class="leftNav">
                <span class="alltask">
                  总任务
                  <i class="iconfont sign" title="指已评价总任务个数"
                    >&#xe616;</i
                  >
                </span>
                <p class="tasknum">
                  {{
                    this.rankingtop.report_user_ranking_for_user_project_count
                  }}
                </p>
              </div>
              <div class="midNav"></div>
              <div class="rightNav">
                <span class="alltask">
                  分任务
                  <i class="iconfont sign" title="指已评价分任务个数"
                    >&#xe616;</i
                  >
                </span>
                <p class="tasknum">
                  {{ this.rankingtop.report_user_ranking_for_user_task_count }}
                </p>
              </div>
            </div>
            <div class="divvsider"></div>
            <div class="footNav">
              <span class="rankingword"
                >在{{ user.user_unit.unit_name }}中排名</span
              >
              <span class="ranking"
                >第{{
                  this.rankingtop.report_user_ranking_for_user_rank
                }}名</span
              >
            </div>
          </div>
          <div class="userImgTwo">
            <div id="Usereachart"></div>
            <div class="score">
              <p class="titleNav">评分</p>
              <div class="scoreline">
                <span class="title">响应速度</span>
                <el-rate
                  :value="Number(speed)"
                  disabled
                  text-color="#ff9900"
                  show-text
                  :texts="csoretext"
                ></el-rate>
              </div>
              <div class="scoreline">
                <span class="title">过程调度</span>
                <el-rate
                  :value="Number(scheduling)"
                  disabled
                  text-color="#ff9900"
                  show-text
                  :texts="csoretext"
                ></el-rate>
              </div>
              <div class="scoreline">
                <span class="title">服务态度</span>
                <el-rate
                  :value="Number(attitude)"
                  disabled
                  text-color="#ff9900"
                  show-text
                  :texts="csoretext"
                ></el-rate>
              </div>
              <div class="scoreline">
                <span class="title">成果质量</span>
                <el-rate
                  :value="Number(results)"
                  disabled
                  text-color="#ff9900"
                  show-text
                  :texts="csoretext"
                ></el-rate>
              </div>
              <div class="scoreline">
                <span class="title">总体评分</span>
                <el-rate
                  :value="Number(overall)"
                  disabled
                  text-color="#ff9900"
                  show-text
                  :texts="csoretext"
                ></el-rate>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="serachBox">
        <div class="formList">
          <el-form
            class="formbox"
            ref="ruleForm"
            :rules="rules"
            :model="ruleForm"
            label-width="80px"
          >
            <el-form-item
              v-if="user.is_admin == 0 && user.is_unit == 1"
              class="userForm"
              prop="name"
              label="账户名称"
            >
              <el-input placeholder="请输入" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item
              v-if="user.is_admin == 0 && user.is_unit == 1"
              class="userForm"
              prop="mobile"
              label="账号"
            >
              <el-input
                placeholder="请输入"
                v-model="ruleForm.mobile"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="user.is_admin == 1"
              class="userForm"
              prop="unit_name"
              label="巡护单位"
            >
              <el-input
                placeholder="请输入"
                v-model="ruleForm.unit_name"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="user.is_admin == 1"
              class="userForm"
              prop="unit_code"
              label="巡护代码"
            >
              <el-input
                placeholder="请输入"
                v-model="ruleForm.unit_code"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="user.is_admin == 0 && user.is_unit == 0"
              class="userForm"
              prop="task_name"
              label="任务名称"
            >
              <el-input
                placeholder="请输入"
                v-model="ruleForm.task_name"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="user.is_admin == 0 && user.is_unit == 0"
              class="userForm"
              prop="task_id"
              label="任务ID"
            >
              <el-input
                placeholder="请输入"
                v-model="ruleForm.task_id"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="userForm"
              prop="response_speed"
              label="响应速度"
            >
              <el-select
                v-model="ruleForm.response_speed"
                placeholder="请选择"
                @change="getresponse(ruleForm.response_speed)"
                clearable
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="userForm"
              prop="process_scheduling"
              label="过程调度"
            >
              <el-select
                v-model="ruleForm.process_scheduling"
                placeholder="请选择"
                @change="getprocess(ruleForm.process_scheduling)"
                clearable
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="userForm"
              prop="quality_of_results"
              label="成果质量"
            >
              <el-select
                v-model="ruleForm.quality_of_results"
                placeholder="请选择"
                @change="getquality(ruleForm.quality_of_results)"
                clearable
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="userForm"
              prop="overall_score"
              label="总体评分"
            >
              <el-select
                v-model="ruleForm.overall_score"
                placeholder="请选择"
                @change="getoverall(ruleForm.overall_score)"
                clearable
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="userForm"
              prop="service_attitude"
              label="服务态度"
            >
              <el-select
                v-model="ruleForm.service_attitude"
                placeholder="请选择"
                @change="getservice(ruleForm.service_attitude)"
                clearable
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="footBtn">
          <div class="fmBtn">
            <el-button @click="searchBtn" class="searchBtn" type="primary"
              >查&nbsp;&nbsp;&nbsp;&nbsp;询</el-button
            >
            <el-button
              class="reset"
              @click="resetForm('ruleForm')"
              type="primary"
              plain
              >重&nbsp;&nbsp;&nbsp;&nbsp;置</el-button
            >
          </div>
        </div>
      </div>
      <div class="footerBox">
        <div
          :class="{
            tableBox:
              user.is_admin == 1 || (user.is_admin == 0 && user.is_unit == 1),
          }"
        >
          <!-- 超管 -->
          <table v-if="role == 0" class="tableData" style="width: 100%">
            <tr class="tabTitle">
              <th
                class="tableTitle"
                style="width: 25%"
                align="center"
                valign="middle"
              >
                巡护单位
              </th>
              <th
                class="tableTitle"
                style="width: 20%"
                align="center"
                valign="middle"
              >
                巡护代码
              </th>
              <th
                class="tableTitle"
                style="width: 10%"
                align="center"
                valign="middle"
              >
                响应速度
              </th>
              <th
                class="tableTitle"
                style="width: 10%"
                align="center"
                valign="middle"
              >
                过程调度
              </th>
              <th
                class="tableTitle"
                style="width: 10%"
                align="center"
                valign="middle"
              >
                服务态度
              </th>
              <th
                class="tableTitle"
                style="width: 10%"
                align="center"
                valign="middle"
              >
                成果质量
              </th>
              <th
                class="tableTitle"
                style="width: 10%"
                align="center"
                valign="middle"
              >
                总体评分
              </th>
            </tr>
            <tr
              class="tabletr"
              v-for="(item, index) in rankingList"
              :key="index"
            >
              <td
                v-if="index < 3"
                class="tableFile"
                align="justify"
                valign="middle"
              >
                <img
                  class="rankingImg"
                  v-if="index == 0"
                  src="../../assets/img/num1.png"
                  alt
                />
                <img
                  class="rankingImg"
                  v-if="index == 1"
                  src="../../assets/img/num2.png"
                  alt
                />
                <img
                  class="rankingImg"
                  v-if="index == 2"
                  src="../../assets/img/num3.png"
                  alt
                />
                <img class="feimatit" src="../../assets/img/title.png" alt />
                <span class="unitname" :title="item.unit_name">{{
                  item.unit_name
                }}</span>
              </td>
              <td class="tableFile" v-if="index >= 3" align="justify">
                <span class="rankingNum">{{ index + 1 }}</span>
                <img class="feimaImg" src="../../assets/img/title.png" alt />
                <span class="unitname" :title="item.unit_name">{{
                  item.unit_name
                }}</span>
              </td>
              <td class="tableFile" align="center" valign="middle">
                {{ item.unit_code }}
              </td>

              <td class="tableFile" align="center" valign="middle">
                <!-- {{ csoretext[Math.round(item.response_speed) - 1] }} -->
                <el-popover placement="top" width="200" trigger="hover">
                  <el-rate
                    :value="Number(item.response_speed)"
                    disabled
                    :texts="csoretext"
                    show-text
                    disabled-void-color="#ccc"
                  ></el-rate>
                  <el-button style="border: 0" size="small" slot="reference">
                    {{
                      csoretext[Math.round(item.response_speed) - 1]
                    }}</el-button
                  >
                </el-popover>
              </td>

              <td class="tableFile" align="center" valign="middle">
                <!-- {{ csoretext[Math.round(item.process_scheduling) - 1] }} -->
                <el-popover placement="top" width="200" trigger="hover">
                  <el-rate
                    v-model="item.process_scheduling"
                    disabled
                    :texts="csoretext"
                    show-text
                    disabled-void-color="#ccc"
                  ></el-rate>
                  <el-button style="border: 0" size="small" slot="reference">
                    {{
                      csoretext[Math.round(item.process_scheduling) - 1]
                    }}</el-button
                  >
                </el-popover>
              </td>

              <td class="tableFile" align="center" valign="middle">
                <!-- {{ csoretext[Math.round(item.service_attitude) - 1] }} -->
                <el-popover placement="top" width="200" trigger="hover">
                  <el-rate
                    v-model="item.service_attitude"
                    disabled
                    :texts="csoretext"
                    show-text
                    disabled-void-color="#ccc"
                  ></el-rate>
                  <el-button style="border: 0" size="small" slot="reference">
                    {{
                      csoretext[Math.round(item.service_attitude) - 1]
                    }}</el-button
                  >
                </el-popover>
              </td>

              <td class="tableFile" align="center" valign="middle">
                <!-- {{ csoretext[Math.round(item.quality_of_results) - 1] }} -->
                <el-popover placement="top" width="200" trigger="hover">
                  <el-rate
                    :value="Number(item.quality_of_results)"
                    disabled
                    :texts="csoretext"
                    show-text
                    disabled-void-color="#ccc"
                  ></el-rate>
                  <el-button style="border: 0" size="small" slot="reference">
                    {{
                      csoretext[Math.round(item.quality_of_results) - 1]
                    }}</el-button
                  >
                </el-popover>
              </td>

              <td class="tableFile" align="center" valign="middle">
                <!-- {{ csoretext[Math.round(item.overall_score) - 1] }} -->
                <el-popover placement="top" width="200" trigger="hover">
                  <el-rate
                    :value="Number(item.overall_score)"
                    disabled
                    :texts="csoretext"
                    show-text
                    disabled-void-color="#ccc"
                  ></el-rate>
                  <el-button style="border: 0" size="small" slot="reference">
                    {{
                      csoretext[Math.round(item.overall_score) - 1]
                    }}</el-button
                  >
                </el-popover>
              </td>
            </tr>
          </table>
          <!-- 巡护管理员 -->
          <table v-if="role === 1" class="tableData" style="width: 100%">
            <tr class="tabTitle">
              <th
                class="tableTitle"
                style="width: 25%"
                align="center"
                valign="middle"
              >
                账户名称
              </th>
              <th
                class="tableTitle"
                style="width: 20%"
                align="center"
                valign="middle"
              >
                账号
              </th>
              <th
                class="tableTitle"
                style="width: 10%"
                align="center"
                valign="middle"
              >
                响应速度
              </th>
              <th
                class="tableTitle"
                style="width: 10%"
                align="center"
                valign="middle"
              >
                过程调度
              </th>
              <th
                class="tableTitle"
                style="width: 10%"
                align="center"
                valign="middle"
              >
                服务态度
              </th>
              <th
                class="tableTitle"
                style="width: 10%"
                align="center"
                valign="middle"
              >
                成果质量
              </th>
              <th
                class="tableTitle"
                style="width: 10%"
                align="center"
                valign="middle"
              >
                总体评分
              </th>
            </tr>
            <tr
              class="tabletr"
              v-for="(item, index) in rankingList"
              :key="index"
            >
              <td
                v-if="index < 3"
                class="tableFile"
                align="justify"
                valign="middle"
              >
                <img
                  class="rankingImg"
                  v-if="index == 0"
                  src="../../assets/img/num1.png"
                  alt
                />
                <img
                  class="rankingImg"
                  v-if="index == 1"
                  src="../../assets/img/num2.png"
                  alt
                />
                <img
                  class="rankingImg"
                  v-if="index == 2"
                  src="../../assets/img/num3.png"
                  alt
                />
                <img class="feimatit" src="../../assets/img/title.png" alt />
                <span class="unitname" :title="item.name">{{ item.name }}</span>
              </td>
              <td class="tableFile" v-if="index >= 3" align="justify">
                <span class="rankingNum">{{ index + 1 }}</span>
                <img class="feimaImg" src="../../assets/img/title.png" alt />
                <span class="unitname" :title="item.name">{{ item.name }}</span>
              </td>
              <td class="tableFile" align="center" valign="middle">
                {{ item.mobile }}
              </td>

              <td class="tableFile" align="center" valign="middle">
                <el-popover placement="top" width="200" trigger="hover">
                  <el-rate
                    :value="Number(item.response_speed)"
                    disabled
                    :texts="csoretext"
                    show-text
                    disabled-void-color="#ccc"
                  ></el-rate>
                  <el-button style="border: 0" size="small" slot="reference">
                    {{
                      csoretext[Math.round(item.response_speed) - 1]
                    }}</el-button
                  >
                </el-popover>
              </td>

              <td class="tableFile" align="center" valign="middle">
                <el-popover placement="top" width="200" trigger="hover">
                  <el-rate
                    :value="Number(item.process_scheduling)"
                    disabled
                    :texts="csoretext"
                    show-text
                    disabled-void-color="#ccc"
                  ></el-rate>
                  <el-button style="border: 0" size="small" slot="reference">
                    {{
                      csoretext[Math.round(item.process_scheduling) - 1]
                    }}</el-button
                  >
                </el-popover>
              </td>

              <td class="tableFile" align="center" valign="middle">
                <el-popover placement="top" width="200" trigger="hover">
                  <el-rate
                    :value="Number(item.service_attitude)"
                    disabled
                    :texts="csoretext"
                    show-text
                    disabled-void-color="#ccc"
                  ></el-rate>
                  <el-button style="border: 0" size="small" slot="reference">
                    {{
                      csoretext[Math.round(item.service_attitude) - 1]
                    }}</el-button
                  >
                </el-popover>
              </td>

              <td class="tableFile" align="center" valign="middle">
                <el-popover placement="top" width="200" trigger="hover">
                  <el-rate
                    :value="Number(item.quality_of_results)"
                    disabled
                    :texts="csoretext"
                    show-text
                    disabled-void-color="#ccc"
                  ></el-rate>
                  <el-button style="border: 0" size="small" slot="reference">
                    {{
                      csoretext[Math.round(item.quality_of_results) - 1]
                    }}</el-button
                  >
                </el-popover>
              </td>

              <td class="tableFile" align="center" valign="middle">
                <el-popover placement="top" width="200" trigger="hover">
                  <el-rate
                    :value="Number(item.overall_score)"
                    disabled
                    :texts="csoretext"
                    show-text
                    disabled-void-color="#ccc"
                  ></el-rate>
                  <el-button style="border: 0" size="small" slot="reference">
                    {{
                      csoretext[Math.round(item.overall_score) - 1]
                    }}</el-button
                  >
                </el-popover>
              </td>
            </tr>
          </table>
          <!-- 终端用户 -->
          <div class="tabelData" v-if="role === 2">
            <div class="titleNavs">
              <span class="titleword">任务名称</span>
              <span class="titleword">任务ID</span>
              <span class="titleword">响应速度</span>
              <span class="titleword">过程调度</span>
              <span class="titleword">服务态度</span>
              <span class="titleword">成果质量</span>
              <span class="titleword">总体评分</span>
              <span class="titleword">评价建议</span>
            </div>
            <div class="usertabel">
              <div
                class="tablecard"
                v-for="(item, index) in usertableList"
                :key="index"
              >
                <div class="tabletitBar">
                  <div
                    @click="getBlockList(item.project_id, true)"
                    class="openbtn"
                  >
                    {{ flagNum == true ? "-" : "+" }}
                  </div>
                  <div class="setuser">创建人：{{ item.mobile }}</div>
                  <div class="setime">创建时间：{{ item.created_at }}</div>
                </div>
                <div class="taskNameBar">
                  <span class="projectname">{{ item.project_name }}</span>
                </div>
              </div>
              <div
                v-if="flagNum == true"
                v-for="(item, index) in blockList"
                :key="'blockBar' + index"
                class="blockBar"
              >
                <div class="blockname">{{ item.block_name }}</div>
                <el-table :data="taskList" style="width: 100%" height="114">
                  <el-table-column
                    prop="task_name"
                    label="任务名称"
                    width="120"
                  ></el-table-column>

                  <el-table-column
                    prop="task_id"
                    label="任务ID"
                    align="center"
                    class="taskID"
                  ></el-table-column>

                  <el-table-column label="响应速度" align="center">
                    <template slot-scope="scope">
                      <el-popover placement="top" width="200" trigger="hover">
                        <el-rate
                          :value="Number(scope.row.response_speed)"
                          disabled
                          :texts="csoretext"
                          show-text
                          disabled-void-color="#ccc"
                        ></el-rate>
                        <el-button
                          style="border: 0"
                          size="small"
                          slot="reference"
                          >{{
                            csoretext[Math.round(scope.row.response_speed) - 1]
                          }}</el-button
                        >
                      </el-popover>
                    </template>
                  </el-table-column>

                  <el-table-column label="过程调度" align="center">
                    <template slot-scope="scope">
                      <el-popover placement="top" width="200" trigger="hover">
                        <el-rate
                          :value="Number(scope.row.process_scheduling)"
                          disabled
                          :texts="csoretext"
                          show-text
                          disabled-void-color="#ccc"
                        ></el-rate>
                        <el-button
                          style="border: 0"
                          size="small"
                          slot="reference"
                          >{{
                            csoretext[
                              Math.round(scope.row.process_scheduling) - 1
                            ]
                          }}</el-button
                        >
                      </el-popover>
                    </template>
                  </el-table-column>

                  <el-table-column label="服务态度" align="center">
                    <template slot-scope="scope">
                      <el-popover placement="top" width="200" trigger="hover">
                        <el-rate
                          :value="Number(scope.row.service_attitude)"
                          disabled
                          :texts="csoretext"
                          show-text
                          disabled-void-color="#ccc"
                        ></el-rate>
                        <el-button
                          style="border: 0"
                          size="small"
                          slot="reference"
                          >{{
                            csoretext[
                              Math.round(scope.row.service_attitude) - 1
                            ]
                          }}</el-button
                        >
                      </el-popover>
                    </template>
                  </el-table-column>

                  <el-table-column label="成果质量" align="center">
                    <template slot-scope="scope">
                      <el-popover placement="top" width="200" trigger="hover">
                        <el-rate
                          :value="Number(scope.row.quality_of_results)"
                          disabled
                          :texts="csoretext"
                          show-text
                          disabled-void-color="#ccc"
                        ></el-rate>
                        <el-button
                          style="border: 0"
                          size="small"
                          slot="reference"
                          >{{
                            csoretext[
                              Math.round(scope.row.quality_of_results) - 1
                            ]
                          }}</el-button
                        >
                      </el-popover>
                    </template>
                  </el-table-column>

                  <el-table-column label="总体评分" align="center">
                    <template slot-scope="scope">
                      <el-popover placement="top" width="200" trigger="hover">
                        <el-rate
                          :value="Number(scope.row.overall_score)"
                          disabled
                          :texts="csoretext"
                          show-text
                          disabled-void-color="#ccc"
                        ></el-rate>
                        <el-button
                          style="border: 0"
                          size="small"
                          slot="reference"
                          >{{
                            csoretext[Math.round(scope.row.overall_score) - 1]
                          }}</el-button
                        >
                      </el-popover>
                    </template>
                  </el-table-column>

                  <el-table-column label="评价建议" align="center">
                    <template slot-scope="scope">
                      <i class="iconfont evaluate" :title="scope.row.advise"
                        >&#xe658;</i
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="block">
          <el-pagination
            @current-change="currentchange"
            @next-click="handleNext"
            @prev-click="handlePrev"
            :current-page="current_page"
            :page-size="this.per_page"
            layout="total,  prev, pager, next, jumper"
            :total="this.total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        mobile: "",
        unit_name: "",
        unit_code: "",
        response_speed: "",
        process_scheduling: "",
        quality_of_results: "",
        overall_score: "",
        service_attitude: "",
        task_name: "",
        task_id: "",
      },
      rankingList: [],
      rankingtop: [],
      response: "", //响应速度
      current_page: 1, //当前页
      total: 0, //总条目数
      per_page: 8, //每页多少条
      page: 1,
      options: [
        {
          value: "1",
          label: "较差",
        },
        {
          value: "2",
          label: "一般",
        },
        {
          value: "3",
          label: "良好",
        },
        {
          value: "4",
          label: "优秀",
        },
      ],
      rules: {},
      response_speed: 0,
      process_scheduling: 0,
      quality_of_results: 0,
      overall_score: 0,
      service_attitude: 0,
      csoretext: ["较差", "一般", "良好", "优秀", "优秀"],
      scoreList: [],
      speed: 0,
      scheduling: 0,
      results: 0,
      overall: 0,
      attitude: 0,
      usertableList: [],
      action: "getList",
      blockList: [],
      taskList: [],
      flagNum: false,
    };
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    addBtn() {
      this.$refs.childuav.open();
    },
    // 搜索
    searchBtn() {
      this.page = 1;
      this.getRankingList();
    },
    // 排行
    getRankingList() {
      this.$apis
        .ranking(
          {
            page: this.page,
            per_page: this.per_page,
            name: this.ruleForm.name,
            mobile: this.ruleForm.mobile,
            unit_name: this.ruleForm.unit_name,
            unit_code: this.ruleForm.unit_code,
            task_name: this.ruleForm.task_name,
            task_id: this.ruleForm.task_id,
            response_speed: this.response_speed,
            process_scheduling: this.process_scheduling,
            quality_of_results: this.quality_of_results,
            overall_score: this.overall_score,
            service_attitude: this.service_attitude,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status == 0) {
            this.rankingList = data.data.data.user_rank_data.data;
            this.rankingtop = data.data.data.top_data;
            this.total = data.data.data.user_rank_data.total;
            this.current_page = data.data.data.user_rank_data.current_page;

            if (this.role === 1) {
              this.scoreList =
                data.data.data.top_data.report_user_ranking_for_unit_score ===
                null
                  ? {
                      response_speed: 0,
                      overall_score: 0,
                      quality_of_results: 0,
                      service_attitude: 0,
                      process_scheduling: 0,
                    }
                  : data.data.data.top_data.report_user_ranking_for_unit_score;
            } else if (this.role === 2) {
              this.scoreList =
                data.data.data.top_data.report_user_ranking_for_user_score ===
                null
                  ? {
                      response_speed: 0,
                      overall_score: 0,
                      quality_of_results: 0,
                      service_attitude: 0,
                      process_scheduling: 0,
                    }
                  : data.data.data.top_data.report_user_ranking_for_user_score;
              this.usertableList = data.data.data.user_rank_data.data;
            }
            this.speed = Math.round(this.scoreList.response_speed);
            this.scheduling = Math.round(this.scoreList.process_scheduling);
            this.results = Math.round(this.scoreList.quality_of_results);
            this.overall = Math.round(this.scoreList.overall_score);
            this.attitude = Math.round(this.scoreList.service_attitude);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getBlockList(param) {
      this.flagNum = !this.flagNum;
      this.$apis
        .ranking({ action: this.action, project_id: param }, this.token)
        .then((data) => {
          this.blockList = data.data.data;
          this.blockList.forEach((element) => {
            this.taskList = element.task;
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getresponse(param) {
      this.response_speed = param;
    },
    getprocess(param) {
      this.process_scheduling = param;
    },
    getquality(param) {
      this.quality_of_results = param;
    },
    getoverall(param) {
      this.overall_score = param;
    },
    getservice(param) {
      this.service_attitude = param;
    },
    handleNext() {
      this.page++;
      this.getRankingList(this.page, this.per_page);
      this.flagNum = false;
    },
    handlePrev() {
      this.page--;
      this.getRankingList(this.page, this.per_page);
      this.flagNum = false;
    },
    currentchange(page) {
      this.page = page;
      this.getRankingList(this.page, this.per_page);
      this.flagNum = false;
    },
    myeachart() {
      let myChart = this.$echarts.init(document.querySelector("#Myeacharts"));
      myChart.setOption({
        title: {
          text: "",
        },
        tooltip: {},
        legend: {
          data: [],
        },
        radar: {
          // shape: 'circle',
          name: {
            textStyle: {
              color: "#fff",
              backgroundColor: "",
              borderRadius: 3,
              padding: [3, 0],
            },
          },
          indicator: [
            { name: "响应速度", max: "5" },
            { name: "总体评分", max: "5" },
            { name: "成果质量", max: "5" },
            { name: "服务态度", max: "5" },
            { name: "过程调度", max: "5" },
          ],
          center: ["48%", "60%"], // 位置
        },
        series: [
          {
            name: "",
            type: "radar",
            areaStyle: { normal: {} },
            data: [
              {
                value: [],
                name: "",
              },
            ],
          },
        ],
      });
      this.$apis
        .ranking({}, this.token)
        .then((data) => {
          let report_user_ranking_for_unit_score =
            data.data.data.top_data.report_user_ranking_for_unit_score === null
              ? {
                  response_speed: 0,
                  overall_score: 0,
                  quality_of_results: 0,
                  service_attitude: 0,
                  process_scheduling: 0,
                }
              : data.data.data.top_data.report_user_ranking_for_unit_score;
          myChart.setOption({
            series: [
              {
                data: [
                  {
                    value: [
                      report_user_ranking_for_unit_score.response_speed,
                      report_user_ranking_for_unit_score.overall_score,
                      report_user_ranking_for_unit_score.quality_of_results,
                      report_user_ranking_for_unit_score.service_attitude,
                      report_user_ranking_for_unit_score.process_scheduling,
                    ],
                  },
                ],
              },
            ],
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    userEachar() {
      let userChart = this.$echarts.init(
        document.querySelector("#Usereachart")
      );
      userChart.setOption({
        title: {
          text: "",
        },
        tooltip: {},
        legend: {
          data: [],
        },
        radar: {
          // shape: 'circle',
          name: {
            textStyle: {
              color: "#fff",
              backgroundColor: "",
              borderRadius: 3,
              padding: [3, 0],
            },
          },
          indicator: [
            { name: "响应速度", max: "5" },
            { name: "总体评分", max: "5" },
            { name: "成果质量", max: "5" },
            { name: "服务态度", max: "5" },
            { name: "过程调度", max: "5" },
          ],
          center: ["50%", "60%"], // 位置
        },
        series: [
          {
            name: "",
            type: "radar",
            areaStyle: { normal: {} },
            data: [
              {
                value: [],
                name: "",
              },
            ],
          },
        ],
      });
      this.$apis
        .ranking({}, this.token)
        .then((data) => {
          userChart.setOption({
            series: [
              {
                data: [
                  {
                    value: [
                      data.data.data.top_data.report_user_ranking_for_user_score
                        .response_speed,
                      data.data.data.top_data.report_user_ranking_for_user_score
                        .overall_score,
                      data.data.data.top_data.report_user_ranking_for_user_score
                        .quality_of_results,
                      data.data.data.top_data.report_user_ranking_for_user_score
                        .service_attitude,
                      data.data.data.top_data.report_user_ranking_for_user_score
                        .process_scheduling,
                    ],
                  },
                ],
              },
            ],
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  computed: {
    ...mapState(["user", "token", "role"]),
  },
  mounted() {
    if (this.$route.query.nav == "paihang") {
      this.currentUrlStorage(["首页", "用户管理", "排行榜"]);
    }
    this.$nextTick(() => {
      if (this.user.is_admin == 0 && this.user.is_unit == 1) {
        this.myeachart();
      } else if (this.user.is_admin == 0 && this.user.is_unit == 0) {
        this.userEachar();
      }
    });
    //实现自适应部分
    this.resizefun = () => {
      this.$echarts.init(document.getElementById("Myeacharts")).resize();
      this.$echarts.init(document.getElementById("Usereachart")).resize();
    };
    window.addEventListener("resize", this.resizefun);
  },
  //移除事件监听，避免内存泄漏
  beforeDestroy() {
    window.removeEventListener("resize", this.resizefun);
    this.resizefun = null;
  },
  created() {
    if (this.user.is_admin == 0 && this.user.is_unit == 0) {
      this.per_page = 1;
    }
    this.getRankingList();
  },
};
</script>

<style lang="less" scoped>
.evaluate {
  cursor: pointer;
}
/deep/.el-table--fit {
  height: 101px !important;
}
/deep/ .el-table .cell {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  margin-left: 40px;
}
/deep/ .el-table .cell:hover {
  text-overflow: inherit;
  overflow: visible;
  white-space: pre-line;
}
/deep/.el-table thead {
  display: none;
}
.usertabel {
  width: 100%;
  height: 330px;
  border: 1px solid #dde2ee;
  margin-top: 4%;
  overflow-y: auto;
}
.titleNavs {
  display: flex;
  justify-content: space-around;
  .titleword {
    color: #3c4353;
    font-size: 16px;
    font-weight: 800;
    margin-top: 4%;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
      Microsoft YaHei, Arial, sans-serif;
    text-align: center;
  }
}
.tablecard {
  width: 100%;
  height: 145px;
  border: 1px solid #dde2ee;
  border-radius: 2px;
  .tabletitBar {
    width: 100%;
    height: 56px;
    background-color: #dde5ef;
    display: flex;
    align-items: center;
    .openbtn {
      width: 13.6px;
      height: 13.6px;
      border-radius: 2px;
      border: 1px solid rgba(131, 138, 157, 1);
      text-align: center;
      cursor: pointer;
      user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
    }
    .setuser {
      font-size: 12px;
      color: #3c4353;
      margin-left: 20px;
      float: left;
    }
    .setime {
      font-size: 12px;
      color: #3c4353;
      float: left;
      margin-left: 50px;
    }
  }
  .taskNameBar {
    height: 89px;
    width: 100%;
    background-color: #f5f7fb;
    display: flex;
    align-items: center;
    .projectname {
      font-size: 16px;
      color: #3c4353;
      font-weight: 600;
      margin-left: 50px;
    }
  }
}
.blockBar {
  width: 100%;
  height: 140px;
}
.blockname {
  width: 100%;
  height: 40px;
  border: 1px solid #dde2ee;
  font-size: 16px;
  color: #000000;
  padding-left: 50px;
  line-height: 40px;
}
/deep/.el-pagination__editor.el-input .el-input__inner {
  width: 30px !important;
}
.block {
  padding: 30px 0 0 50px;
}
.rankingImg {
  width: 25px;
  height: 25px;
  margin-left: 20px;
  float: left;
}
.feimatit {
  margin-left: 38px;
  float: left;
  margin-right: 40px;
}
.rankingNum {
  margin-left: 28px;
  float: left;
}
.feimaImg {
  margin-left: 46px;
  float: left;
  margin-right: 40px;
}
.unitname {
  width: 100px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: left;
  margin: 0;
  transform: translateY(50%);
}
th,
tr {
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
}
/deep/.el-form-item__content {
  width: 240px;
  float: left;
}
/deep/.el-form-item {
  float: left;
}
/deep/.el-input--medium .el-input__inner {
  width: 240px;
}
/deep/.el-menu.el-menu--horizontal {
  width: 100%;
}
.formList {
  width: 100%;
  height: 30%;
  padding: 35px 15px 0 15px;
}
.formbox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.footBtn {
  width: 100%;
  margin-top: 100px;
}
.fmBtn {
  float: right;
  margin-right: 100px;
}
.searchBtn {
  margin-right: 20px;
  width: 125px;
  height: 45px;
}
.reset {
  width: 125px;
  height: 45px;
}
.tableData {
  border-collapse: collapse;
  .tabTitle {
    height: 56px;
  }
}
.tableFile {
  height: 48px;
}
.tableTitle {
  height: 30px;
  background-color: #e5e8ed;
}

table tr:nth-child(odd) {
  background-color: #f6f6f6;
}

table tr:nth-child(even) {
  background: #fff;
}
.tabletr {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: rgba(0, 0, 0, 0.65);
}
.uniter {
  display: flex;
  justify-content: space-between;
}
.topBgc {
  width: 100%;
  height: 100%;
  padding: 0px 30px 10px 30px;
  background-color: #f0f0f0;
  .bgcImg {
    min-height: 400px;
    .topImg {
      width: 100%;
      height: 400px;
      background: url("../../assets/img/terminal.png") no-repeat center;
      background-size: cover;
    }
    .unitImgOne {
      background: url("../../assets/img/3.png") no-repeat center;
      background-size: cover;
      width: 40%;
      height: 400px;
    }
    .unitImgTwo {
      width: 58%;
      background: url("../../assets/img/2.png") no-repeat center;
      background-size: cover;
      height: 400px;
    }
    .userImgOne {
      background: url("../../assets/img/1.png") no-repeat center;
      background-size: cover;
      width: 40%;
      height: 400px;
      float: left;
    }
    .userImgTwo {
      width: 58%;
      background: url("../../assets/img/2.png") no-repeat center;
      background-size: cover;
      height: 400px;
      float: right;
    }
  }
  .serachBox {
    width: 100%;
    height: 266px;
    background-color: #fff;
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    margin-top: 2.07%;
  }
  .footerBox {
    width: 100%;
    height: 600px;
    background-color: #fff;
    box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
    margin-top: 2.07%;
    margin-bottom: 40px;
    padding: 30px 20px 55px 20px;
    .tableBox {
      height: 95%;
      border: 1px solid #dde2ee;
    }
  }
}
.topBar {
  height: 120px;
  width: 100%;
  margin-top: 5%;
  display: flex;
  align-items: center;
  margin-left: 20px;
  .imgBar {
    float: left;
    margin-left: 20px;
  }
  .comppy {
    float: left;
    margin-left: 20px;
    font-size: 20px;
    color: #ffffff;
  }
  .unitnames {
    margin: 0 0 15px 0;
    color: #ffffff;
  }
  .userBtn {
    color: #3c4353;
    width: 112px;
    height: 32px;
    border-radius: 3px;
    background-color: #ffc000;
    display: flex;
    justify-content: center;
    line-height: 32px;
    font-size: 14px;
  }
  .userzd {
    color: #3c4353;
    width: 112px;
    height: 32px;
    border-radius: 3px;
    background-color: #59fa9c;
    display: flex;
    justify-content: center;
    line-height: 32px;
    font-size: 14px;
  }
}
.sign {
  cursor: pointer;
}
.taskBar {
  height: 80px;
  width: 250px;
  float: left;
  margin-top: 50px;
  margin-left: 25px;
  display: flex;
  justify-content: space-around;
  .leftNav {
    .alltask {
      font-size: 16px;
      color: #ffffff;
    }
    .tasknum {
      font-size: 20px;
      color: #ffffff;
    }
  }
  .midNav {
    width: 1px;
    height: 80%;
    background-color: #fff;
  }
  .rightNav {
    .alltask {
      font-size: 16px;
      color: #ffffff;
    }
    .tasknum {
      font-size: 20px;
      color: #ffffff;
    }
  }
}
.divvsider {
  margin-left: 8%;
  height: 1px;
  width: 84%;
  background-color: #fff;
  float: left;
  margin-top: 20px;
}
.footNav {
  width: 84%;
  margin-left: 8%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  float: left;
  .rankingword {
    font-size: 16px;
    color: #ffffff;
    max-width: 300px;
  }
  .ranking {
    font-size: 20px;
    color: #ffffff;
    font-weight: bold;
  }
}
#Myeacharts {
  width: 50%;
  height: 80%;
  float: left;
}
#Usereachart {
  width: 50%;
  height: 80%;
  float: left;
}
.score {
  width: 40%;
  height: 80%;
  margin-top: 5%;
  float: right;
}
.scoreline {
  display: flex;
  align-items: center;
  margin-bottom: 5%;
  .title {
    color: #fff;
    font-size: 12px;
    margin-right: 25px;
  }
}
.titleNav {
  font-size: 32px;
  color: #fff;
}
@media only screen and (max-width: 1650px) {
  .el-form-item {
    float: left;
    width: 300px;
  }
  /deep/.el-form-item__content {
    width: 190px;
    float: left;
  }
  /deep/.el-input--medium .el-input__inner {
    width: 190px;
  }
  .rankingImg {
    width: 25px;
    height: 25px;
    margin-left: 20px;
    float: left;
  }
  .feimatit {
    margin-left: 10px;
    float: left;
    margin-right: 40px;
  }
  .rankingNum {
    margin-left: 28px;
    float: left;
  }
  .feimaImg {
    margin-left: 18px;
    float: left;
    margin-right: 40px;
  }
  .topBgc {
    width: 100%;
    height: 100%;
    padding: 0px 30px 10px 30px;
    background-color: #f0f0f0;
    .bgcImg {
      // box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      .topImg {
        width: 100%;
        height: 400px;
      }
    }
    .serachBox {
      width: 100%;
      height: 266px;
      background-color: #fff;
      box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      margin-top: 2.07%;
    }
    .footerBox {
      width: 100%;
      height: 520px;
      background-color: #fff;
      box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
      margin-top: 2.07%;
      margin-bottom: 40px;
      padding: 23px 12px 40px 12px;
      .tableBox {
        height: 90%;
        border: 1px solid #dde2ee;
      }
    }
  }
  #Myeacharts {
    width: 55%;
    height: 55%;
    margin-top: 15%;
  }
  #Usereachart {
    width: 55%;
    height: 55%;
    margin-top: 15%;
  }
  .score {
    margin-top: 10%;
    width: 42%;
  }
}
</style>
