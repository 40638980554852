<template>
  <!-- 平台外 -->
  <div>
    <el-dialog
      class="fmdialog"
      title="添加巡护绑定"
      @click="open"
      :visible.sync="outerVisible"
      :close-on-click-modal="false"
    >
      <el-divider></el-divider>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item prop="mobile" class="demo-ruleForm" label="手机号码">
          <el-input placeholder="请输入手机号" v-model="ruleForm.mobile"></el-input>
        </el-form-item>
        <el-form-item prop="name" class="demo-ruleForm" label="用户名称">
          <el-input placeholder="请输入用户名" v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-alert title="手机号登录时，密码与手机号同步！" type="warning" show-icon :closable="false"></el-alert>
      </el-form>
      <el-divider></el-divider>
      <div slot="footer" class="dialog-footer">
        <el-button class="fmButton" type="primary" @click="handAddReg">添 加</el-button>
        <el-button class="fmButton" @click="handCancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  inject: ["reload"],
  data() {
    return {
      outerVisible: false,
      innerVisible: false,
      ruleForm: {
        mobile: "",
        name: ""
      },
      rules: {
        mobile: [
          { required: true, message: "该手机号不存在", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确手机号", trigger: "blur" }
        ],
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { max: 10, message: "最大字符数10", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    open() {
      this.outerVisible = true;
    },
    handAddReg() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          this.addManager();
          this.outerVisible = false;
        } else {
        }
      });
    },
    addManager() {
      this.$apis
        .addManager(
          {
            type: 1,
            name: this.ruleForm.name,
            mobile: this.ruleForm.mobile,
            password: this.ruleForm.mobile,
            unit_id: this.unit.id
          },
          this.unit_token
        )
        .then(data => {
         
          this.reload();
        })
        .catch(err => {
          console.error(err);
        });
    },
    handCancel() {
      this.outerVisible = false;
    }
  },
  created() {},
  computed: {
    ...mapState(["unit", "unit_token"])
  }
};
</script>
<style lang="less" scoped>
/deep/.el-dialog {
  width: 470px !important;
  height: 380px;
}
/deep/.el-dialog__title {
  font-size: 20px;
  margin-left: 55px;
  line-height: 30px;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-dialog__header {
  height: 46px;
}
.demo-ruleForm {
  margin-top: 37px;
  width: 370px;
}
/deep/.el-form-item__content {
  margin-left: 110px !important;
}
/deep/.el-dialog__footer {
  margin-right: 58px;
}
/deep/.el-divider--horizontal{
  margin: 16px 0;
}
.fmButton {
  width: 152px;
  height: 40px;
}
.el-alert {
  width: 275px;
  padding: 8px 16px;
  margin: 0;
  box-sizing: border-box;
  border-radius: 0px;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  opacity: 1;
  display: flex;
  align-items: center;
  transition: opacity 0.2s;
  border: 1px solid orange;
  margin-left: 100px;

}
</style>