<template>
  <div>
    <div class="avatar-list" v-if="user && user.length == 3">
      <div class="avatar-part avatar-part-top">
        <el-image class="avatar-small" :src="user[0].photo" fit="fill">
          <div slot="error" class="image-slot">
            <img :style="'width:19px;height:19px'" src="@/assets/img/user-default-head-big.png" />
          </div>
        </el-image>
      </div>
      <div class="avatar-part" v-for="item in user.slice(1)">
        <el-image class="avatar-small" :src="item.photo" fit="fill">
          <div slot="error" class="image-slot">
            <img :style="'width:19px;height:19px'" src="@/assets/img/user-default-head-big.png" />
          </div>
        </el-image>
      </div>
    </div>
    <div class="avatar-list" v-else-if="user && user.length > 3">
      <div class="avatar-part" v-for="item in user.slice(0,4)">
        <el-image class="avatar-small" :src="item.photo" fit="fill">
          <div slot="error" class="image-slot">
            <img :style="'width:19px;height:19px'" src="@/assets/img/user-default-head-big.png" />
          </div>
        </el-image>
      </div>
    </div>
    <div class="avatar-list" v-else>
      <el-image :style="'width:'+width+'px;height:'+height+'px'" class="avatar-box" :src="avatar">
        <div slot="error" class="image-slot">
          <img
            :style="'width:'+width+'px;height:'+height+'px'"
            src="@/assets/img/user-default-head-big.png"
          />
        </div>
      </el-image>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  props: {
    avatar: {
      type: String,
    },
    user: {
      type: Array,
    },
    width: {
      default: 40,
    },
    height: {
      default: 40,
    },
  },
};
</script>

<style lang="less" scoped>
.avatar-box {
  border-radius: 50%;
}

.avatar-list {
  width: 40px;
  height: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}
.avatar-part {
  border-right: 1px solid #fff;
  height: 19px;
  .avatar-small {
    width: 19px;
    height: 19px;
  }
}

.avatar-part-top {
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #fff;
}
</style>