// 飞行统计
<template>
  <div class="uavBody">
    <navTop />
    <div class="topBar">
      <div class="statIng">
        <div class="countNum">
          <p class="titleNum">今日飞行架次</p>
          <span class="countOne">{{ statisticsObj.today_fly_count }}</span>
          <span class="danwei">次</span>
        </div>
        <div class="growthrate">
          <span class="growthNum"
            >{{ statisticsObj.todayFlyCountGrowthRate }}%</span
          >
          <p class="growthWord">相较昨日</p>
        </div>
        <div class="imgBoxOne"></div>
      </div>
      <div class="statIng">
        <div class="countNum">
          <p class="titleNum">今日飞行时长</p>
          <span class="countTwo">{{ statisticsObj.today_fly_time }}</span>
          <span class="danweiTwo">小时</span>
        </div>
        <div class="growthrate">
          <span class="growthNum"
            >{{ statisticsObj.todayFlyTimeGrowthRate }}%</span
          >
          <p class="growthWord">相较昨日</p>
        </div>
        <div class="imgBoxTwo"></div>
      </div>
      <div class="statIng">
        <div class="countNum">
          <p class="titleNum">历史飞行架次</p>
          <span class="countThr">{{ statisticsObj.flyCountNum }}</span>
          <span class="danweiThr">次</span>
        </div>
        <div class="imgBoxThr"></div>
      </div>
      <div class="statIng">
        <div class="countNum">
          <p class="titleNum">历史飞行时长</p>
          <span class="countFour">{{ statisticsObj.flyTime }}</span>
          <span class="danweiFour">小时</span>
        </div>
        <div class="imgBoxFour"></div>
      </div>
    </div>
    <div class="midleBar" style="position: relative">
      <el-tabs
        v-model="activeName"
        @tab-click="handleClick"
        style="height: 100px"
        class="tabs"
      >
        <el-tab-pane label="飞行面积" name="work_area_sum"></el-tab-pane>
        <el-tab-pane label="飞行里程" name="mileage_sum"></el-tab-pane>
        <el-tab-pane label="飞行架次" name="uas_serial_id_count"></el-tab-pane>
        <el-tab-pane label="飞行时长" name="work_time_sum"></el-tab-pane>
      </el-tabs>
      <div class="tabs-radio">
        <el-radio v-model="timeRadio" label="0">按年</el-radio>
        <el-radio v-model="timeRadio" label="1">按月</el-radio>
        <el-date-picker
          v-if="timeRadio === '1'"
          v-model="monthlyData"
          type="monthrange"
          style="height: 30px"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          @change="monthrangeDataChange"
        >
        </el-date-picker>
        <div
          :style="{ 'margin-left': timeRadio === '0' ? '10vw' : '30px' }"
          class="droneTags"
        >
          <div
            :class="{ droneTagsBgc: droneTagsIndex === 0 }"
            @click="droneTagsIndex = 0"
          >
            全部无人机
          </div>
          <div
            :class="{ droneTagsBgc: droneTagsIndex === 1 }"
            style="border-left: 1px solid #a6aab8"
            @click="droneTagsIndex = 1"
          >
            彩虹无人机
          </div>
          <div
            :class="{ droneTagsBgc: droneTagsIndex === 2 }"
            style="border-left: 1px solid #a6aab8"
            @click="droneTagsIndex = 2"
          >
            其他无人机
          </div>
        </div>
      </div>
      <div id="myEcahrts"></div>
    </div>
    <div class="botBar">
      <div class="RtopBar">
        <div class="Rtitle">
          <span class="titleWord">飞行累计统计</span>
        </div>
        <el-divider></el-divider>

        <div class="statistics-bottom">
          <div class="statistics-div statistics-bottom-div">
            <div class="drone-type statistics-column-div">
              <div>无人机类型</div>
              <div
                @click="statisticsBottom = '全部无人机'"
                :class="{ 'selected-btn': statisticsBottom === '全部无人机' }"
              >
                全部无人机
              </div>
              <div
                @click="statisticsBottom = '彩虹无人机'"
                :class="{ 'selected-btn': statisticsBottom === '彩虹无人机' }"
              >
                彩虹无人机
              </div>
              <div
                @click="statisticsBottom = '其他无人机'"
                :class="{ 'selected-btn': statisticsBottom === '其他无人机' }"
              >
                其他无人机
              </div>
            </div>
            <div class="flight-time statistics-column-div">
              <div>飞行日期</div>
              <div
                :class="{ 'flight-period-btn': flightPeriod === '今天' }"
                class="flight-time-btn"
                @click="flightPeriodBtn('今天')"
              >
                今天
              </div>
              <div
                :class="{ 'flight-period-btn': flightPeriod === '本周' }"
                class="flight-time-btn"
                @click="flightPeriodBtn('本周')"
              >
                本周
              </div>
              <div
                :class="{ 'flight-period-btn': flightPeriod === '本月' }"
                class="flight-time-btn"
                @click="flightPeriodBtn('本月')"
              >
                本月
              </div>
            </div>
            <div class="flight-time-select">
              <el-date-picker
                v-model="flightTime"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptionsFlight"
                @change="flightTimeChange"
              >
              </el-date-picker>
            </div>
            <div class="reset">
              <el-button type="primary" @click="bottomGetFlyDataReset" round
                >重置</el-button
              >
            </div>
          </div>
          <div class="statistics-div statistics-progress">
            <div class="progress-column">
              <div>飞行面积</div>
              <div class="progress-div">
                <el-progress
                  :show-text="false"
                  :percentage="percentageAreaPercentage"
                  :color="areaCustomColor"
                ></el-progress>
              </div>
              <div class="progress-text">
                {{ areaPercentage }}|{{ totalAreaPercentage }}平方公里
              </div>
            </div>
            <div class="progress-column">
              <div>飞行里程</div>
              <div class="progress-div">
                <el-progress
                  :show-text="false"
                  :percentage="percentageMileagePercentage"
                  :color="mileageCustomColor"
                ></el-progress>
              </div>
              <div class="progress-text">
                {{ mileagePercentage }}|{{ totalMileagePercentage }}公里
              </div>
            </div>
            <div class="progress-column">
              <div>飞行架次</div>
              <div class="progress-div">
                <el-progress
                  :show-text="false"
                  :percentage="percentageSortiesPercentage"
                  :color="sortiesCustomColor"
                ></el-progress>
              </div>
              <div class="progress-text">
                {{ sortiesPercentage }}|{{ totalSortiesPercentage }}架次
              </div>
            </div>
            <div class="progress-column">
              <div>飞行时长</div>
              <div class="progress-div">
                <el-progress
                  :show-text="false"
                  :percentage="percentageDurationPercentage"
                  :color="durationCustomColor"
                ></el-progress>
              </div>
              <div class="progress-text">
                {{ durationPercentage }}|{{ totalDurationPercentage }}分
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import timeFn from "@/utils/time.js";
let bottomGetFlyDataObj = {};
let centerReportObj = {
  common: {
    mileage_sum: [],
    uas_serial_id_count: [],
    work_area_sum: [],
    work_time_sum: [],
  },
  feima: {
    mileage_sum: [],
    uas_serial_id_count: [],
    work_area_sum: [],
    work_time_sum: [],
  },
  with: {
    mileage_sum: [],
    uas_serial_id_count: [],
    work_area_sum: [],
    work_time_sum: [],
  },
};
let time = new Date();

export default {
  data() {
    return {
      areaPercentage: 0, //  飞行面积
      areaCustomColor: "#1980FF", //  飞行面积color
      mileagePercentage: 0, //  飞行里程
      mileageCustomColor: "#C75CDF", //  飞行里程color
      sortiesPercentage: 0, //  飞行架次
      sortiesCustomColor: "#15BEA5", //  飞行架次color
      durationPercentage: 0, //  飞行时长
      durationCustomColor: "#FF5C5E", //  飞行时长color
      percentageAreaPercentage: 0, //  总飞行面积百分比
      percentageMileagePercentage: 0, //  总飞行里程百分比
      percentageSortiesPercentage: 0, //  总飞行架次百分比
      percentageDurationPercentage: 0, //  总飞行时长百分比
      totalAreaPercentage: 0, //  总飞行面积
      totalMileagePercentage: 0, //  总飞行里程
      totalSortiesPercentage: 0, //  总飞行架次
      totalDurationPercentage: 0, //  总飞行时长
      statisticsObj: {
        today_fly_count: 0, //  飞行次数
        todayFlyCountGrowthRate: 0, //   飞行次数相比昨日增长率
        today_fly_time: 0, //  飞行时长
        todayFlyTimeGrowthRate: 0, //   飞行时长相比昨日增长率
        flyCountNum: 0, //  历史飞行架次
        flyTime: 0, //   历史飞行时长
      },
      timeRadio: "1", //  所选中的时间
      activeName: "mileage_sum", //  所选择的标签页
      yearPickerOptions: {
        disabledDate(time) {
          let date = new Date();
          return (
            time.getFullYear() != 2020 &&
            time.getFullYear() != date.getFullYear()
          );
        },
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      pickerOptionsFlight: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      yearMonthlyData: "", //  年的选择
      monthlyData: "", //  日期的选择
      droneTagsIndex: 0, //  设置
      flightTime: "", //  底部选择的日期
      statisticsBottom: "彩虹无人机", //  无人机类型
      flightPeriod: "今天", //  当前的日期
      centerGetFlyDataObj: {}, //中间折线图数据
      allDate: [], //中间折线图时间日期
      allDroneData: {}, //全部无人机数据
      caihonData: {}, //彩虹数据，折线图
      otherData: {}, //其他无人机数据，折线图
      allFlyData: {}, //全部飞行数据
      bottomData: {},
      bottomAllDroneData: {},
      bottomCaihonDroneData: {},
      bottomOtherDroneData: {},
      defaultBottomData: {
        area: 0,
        meter: 0,
        sorties_count: 0,
        time: 0,
      },
    };
  },
  created() {
    let nowDate = timeFn.getYmdTime(new Date());
    let yesterday = timeFn.getBeforeDay(-1, "-");
    const loading = this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    let todayParams = {
      calendar_interval: "1M",
      created_at_from: nowDate,
      created_at_to: nowDate,
    };
    let yesterdayParams = {
      calendar_interval: "1M",
      created_at_from: yesterday,
      created_at_to: yesterday,
    };
    let yearsParams = {
      calendar_interval: "1M",
      created_at_from: time.getFullYear() + "-01",
      created_at_to: time.getFullYear() + "-12",
    };
    let caihonParams = JSON.parse(JSON.stringify(yearsParams));
    caihonParams["category"] = "彩虹无人机";
    let otherParams = JSON.parse(JSON.stringify(yearsParams));
    otherParams["category"] = "其他无人机";

    this.reportGetFlyData("date", caihonParams).then((res) => {
      this.temCenterGetFlyData(res).then((data) => {
        let objReport = data.objReport;
        let date = data.date;
        this.caihonData = objReport;
      });
    });
    this.reportGetFlyData("date", otherParams).then((res) => {
      this.temCenterGetFlyData(res).then((data) => {
        let objReport = data.objReport;
        let date = data.date;
        this.otherData = objReport;
      });
    });
    this.reportGetFlyData("date", yearsParams);
    Promise.all([
      this.reportGetFlyData("date", todayParams),
      this.reportGetFlyData("date", yesterdayParams),
      this.reportGetFlyData("all", {}),
      this.reportGetFlyData("date", yearsParams),
    ]).then((res) => {
      let allData = {
        sorties_count: 0,
        time: 0,
        area: 0,
        meter: 0,
      };
      for (let key in res[2]) {
        allData.sorties_count += res[2][key].sorties_count;
        allData.time += res[2][key].time;
        allData.area += res[2][key].area;
        allData.meter += res[2][key].meter;
      }
      this.allFlyData = allData;
      let month;
      for (let key in res[0]) {
        month = key;
      }
      this.topGetFlyData(res[0], res[1], allData, month);
      this.temCenterGetFlyData(res[3]).then((data) => {
        let objReport = data.objReport;
        let date = data.date;
        this.centerGetFlyDataObj = objReport;
        this.allDroneData = objReport;
        this.allDate = date;
        this.unitNum(objReport, date, "全部无人机");
      });
      loading.close();
    });

    this.getDroneFlyData(todayParams);
  },
  mounted() {
    if (this.$route.query.nav == "flysta") {
      this.currentUrlStorage(["首页", "统计分析", "飞行统计"]);
    }
    //实现自适应部分
    this.resizefun = () => {
      this.$echarts.init(document.getElementById("myEcahrts")).resize();
    };
    window.addEventListener("resize", this.resizefun);
  },
  watch: {
    droneTagsIndex(newVal, oldVal) {
      // this.centerGetFlyData(this.centerGetFlyDataObj);
      this.switchData(newVal);
    },
    statisticsBottom() {
      this.bottomGetFlyData(bottomGetFlyDataObj);
    },
    timeRadio(newVal, oldVal) {
      let startDate =
        this.timeRadio === "0" ? "2020-01" : time.getFullYear() + "-01";
      let endDate = time.getFullYear() + "-12";
      let params = {
        calendar_interval: "1M",
        created_at_from: startDate,
        created_at_to: endDate,
      };
      let isMonth = true;
      if (newVal === "0") {
        params.calendar_interval = "1y";
        isMonth = false;
      } else {
      }
      this.getTimeData(params, isMonth);
    },
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    /**
     * 年日期的选择
     */
    yearDataChange() {
      console.log(this.yearMonthlyData);
    },
    getDroneFlyData(todayParams) {
      let todayFlyParams = JSON.parse(JSON.stringify(todayParams));

      Promise.all([
        this.reportGetFlyData("date", { ...todayFlyParams }),
        this.reportGetFlyData("date", {
          ...todayFlyParams,
          category: "彩虹无人机",
        }),
        this.reportGetFlyData("date", {
          ...todayFlyParams,
          category: "其他无人机",
        }),
      ]).then((res) => {
        let time = todayFlyParams.created_at_from.split("-");
        let key = time[0] + "-" + time[1];
        if (res[0][key]) this.bottomAllDroneData = res[0][key];
        else
          this.bottomAllDroneData = JSON.parse(
            JSON.stringify(this.defaultBottomData)
          );
        if (res[1][key]) this.bottomCaihonDroneData = res[1][key];
        else
          this.bottomCaihonDroneData = JSON.parse(
            JSON.stringify(this.defaultBottomData)
          );
        if (res[2][key]) this.bottomOtherDroneData = res[2][key];
        else
          this.bottomOtherDroneData = JSON.parse(
            JSON.stringify(this.defaultBottomData)
          );
        this.bottomData = JSON.parse(
          JSON.stringify(this.bottomCaihonDroneData)
        );

        this.bottomGetFlyData();
      });
    },
    getTimeData(params, isMonth) {
      let caihonParams = JSON.parse(JSON.stringify(params));
      caihonParams["category"] = "彩虹无人机";
      let otherParams = JSON.parse(JSON.stringify(params));
      otherParams["category"] = "其他无人机";
      // console.log(params);
      let months = [
        Number(params.created_at_from.split("-")[1]),
        Number(params.created_at_to.split("-")[1]),
      ];
      Promise.all([
        this.reportGetFlyData("date", params),
        this.reportGetFlyData("date", caihonParams),
        this.reportGetFlyData("date", otherParams),
      ]).then((res) => {
        Promise.all([
          this.temCenterGetFlyData(res[0], isMonth, months),
          this.temCenterGetFlyData(res[1], isMonth, months),
          this.temCenterGetFlyData(res[2], isMonth, months),
        ]).then((data) => {
          this.allDroneData = data[0].objReport;
          this.caihonData = data[1].objReport;
          this.otherData = data[2].objReport;
          this.switchData(this.droneTagsIndex);
        });
      });
    },
    /**
     * 月日期的选择
     */
    monthrangeDataChange() {
      let startDate = this.monthlyData[0];
      let endDate = this.monthlyData[1];
      let endTime = endDate.split("-");

      let lastDate = timeFn.getLastDay(endTime[0], endTime[1]);
      endDate = endTime[0] + "-" + endTime[1] + "-" + lastDate;
      let params = {
        calendar_interval: "1M",
        created_at_from: startDate,
        created_at_to: endDate,
      };
      let isMonth = true;
      if (this.timeRadio === "0") {
        params.calendar_interval = "1y";
        isMonth = false;
      } else {
      }

      this.getTimeData(params, isMonth);
      // this.reportGetFlyData(
      //   {
      //     section: "center",
      //     date_type: "months",
      //     start_date: this.monthlyData[0],
      //     end_date: this.monthlyData[1],
      //   },
      //   "center"
      // );
    },
    /**
     * 重置
     */
    bottomGetFlyDataReset() {
      this.statisticsBottom = "彩虹无人机";
      this.flightPeriodBtn("今天");
    },
    /**
     * 选取时间后
     */
    flightTimeChange(data) {
      // this.reportGetFlyData(
      //   {
      //     section: "bottom",
      //     date_type: "month",
      //     start_date: this.flightTime[0],
      //     end_date: this.flightTime[1],
      //   },
      //   "bottom"
      // );
      let todayParams = {
        calendar_interval: "1M",
        created_at_from:  this.flightTime[0],
        created_at_to: this.flightTime[1],
      };
      this.getDroneFlyData(todayParams)
      this.flightPeriod = "多月";
    },
    /**
     * 飞行累计统计切换日期
     */
    flightPeriodBtn(date_type) {
      let timeNow = new Date();
      if (this.flightPeriod === date_type) {
        return;
      }
      this.flightTime = [];
      this.flightPeriod = date_type;
      let type = "";
      let obj = {
        section: "bottom",
        date_type: "today",
        start_date: "",
        end_date: "",
      };
      switch (date_type) {
        case "今天":
          obj.date_type = "today";
          obj.start_date = timeFn.getYmdTime(timeNow);
          obj.end_date = timeFn.getYmdTime(timeNow);
          break;
        case "本周":
          obj.date_type = "week";
          obj.start_date = timeFn.getFirstDayOfWeek();
          obj.end_date = timeFn.getLastDayOfWeek();
          break;
        case "本月":
          obj.date_type = "month";
          obj.start_date = timeFn.getFirstDayOfMonth();
          obj.end_date = timeFn.getLastDayOfMonth();
          break;
        case "多个月":
          obj.date_type = "months";
          break;
        default:
          break;
      }
      let todayParams = {
        calendar_interval: "1M",
        created_at_from:  obj.start_date,
        created_at_to: obj.end_date,
      };
      this.getDroneFlyData(todayParams)
      // this.reportGetFlyData(obj, "bottom");
    },
    /**
     * 获取飞行统计
     */
    reportGetFlyData(type, params) {
      // reportGetFlyData(obj, section) {

      return new Promise((resolve, reject) => {
        this.$apis
          .getMissionStatData({ type: type, ...params }, this.token)
          .then((res) => {
            resolve(res.data.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    switchData(newVal) {
      let name = "";
      switch (newVal) {
        case 0:
          name = "全部无人机";
          this.centerGetFlyDataObj = JSON.parse(
            JSON.stringify(this.allDroneData)
          );
          break;
        case 1:
          name = "彩虹无人机";
          this.centerGetFlyDataObj = JSON.parse(
            JSON.stringify(this.caihonData)
          );
          break;
        default:
          name = "其他无人机";
          this.centerGetFlyDataObj = JSON.parse(JSON.stringify(this.otherData));
          break;
      }
      this.unitNum(this.centerGetFlyDataObj, this.allDate, name);
    },
    /**
     * 顶部获取数据
     */
    topGetFlyData(todayData, yesterdayData, allData, month = "2021-08") {
      if (todayData[month]){
        this.statisticsObj = {
        today_fly_count: todayData[month].sorties_count,
        todayFlyCountGrowthRate: (
          ((todayData[month].sorties_count -
            yesterdayData[month].sorties_count) /
            yesterdayData[month].sorties_count) *
          100
        ).toFixed(2), //   飞行次数相比昨日增长率
        today_fly_time: (Number(todayData[month].time) / 3600).toFixed(2), //  飞行时长
        todayFlyTimeGrowthRate: (
          (((todayData[month].time - yesterdayData[month].time) /
            yesterdayData[month].time) || 0) *
          100
        ).toFixed(2), //  飞行时长相比昨日增长率
        flyCountNum: allData.sorties_count, //  历史飞行架次
        flyTime: ~~(allData.time / 3600), //   历史飞行时长
      };
      }

      // loading.close();
    },
    temCenterGetFlyData(data, isMonth = true, months = [1, 12]) {
      return new Promise((resolve, reject) => {
        let objReport = {
          mileage_sum: [],
          uas_serial_id_count: [],
          work_area_sum: [],
          work_time_sum: [],
        };
        let years = String(time.getFullYear());
        let date = [];
        if (isMonth) {
          for (let i = months[0]; i <= months[1]; i++) {
            i = i < 10 ? "0" + i : i;
            let key = years + "-" + i;
            date.push(key);
            if (data[key]) {
              objReport.mileage_sum.push(data[key].meter);
              objReport.uas_serial_id_count.push(data[key].sorties_count);
              objReport.work_area_sum.push(data[key].area);
              objReport.work_time_sum.push(data[key].time);
            } else {
              objReport.mileage_sum.push(0);
              objReport.uas_serial_id_count.push(0);
              objReport.work_area_sum.push(0);
              objReport.work_time_sum.push(0);
            }
          }
        } else {
          let year = 2018;
          for (let i = 1; i <= 3; i++) {
            let key = year + i;
            date.push(key);
            if (data[key]) {
              objReport.mileage_sum.push(data[key].meter);
              objReport.uas_serial_id_count.push(data[key].sorties_count);
              objReport.work_area_sum.push(data[key].area);
              objReport.work_time_sum.push(data[key].time);
            } else {
              objReport.mileage_sum.push(0);
              objReport.uas_serial_id_count.push(0);
              objReport.work_area_sum.push(0);
              objReport.work_time_sum.push(0);
            }
          }
        }
        this.allDate = date;
        resolve({ objReport, date });
        // this.centerGetFlyDataObj = objReport
        // this.allDroneData = objReport
        // this.allDate = date
      });

      // if (unitNum){
      //   this.unitNum(objReport, date, "全部无人机");

      // }
    },
    /**
     * 中部报表数据获取
     */
    centerGetFlyData(data) {
      let obj = data.data.data.report_portal_fly_data;
      let flightData = JSON.parse(obj.flight_data);
      let date = JSON.parse(obj.date);
      flightData.forEach((e, index) => {
        centerReportObj.feima.mileage_sum[index] = e.feima.mileage_sum;
        centerReportObj.feima.uas_serial_id_count[index] =
          e.feima.uas_serial_id_count;
        centerReportObj.feima.work_area_sum[index] = e.feima.work_area_sum;
        centerReportObj.feima.work_time_sum[index] = e.feima.work_time_sum;

        centerReportObj.common.mileage_sum[index] = e.common.mileage_sum;
        centerReportObj.common.uas_serial_id_count[index] =
          e.common.uas_serial_id_count;
        centerReportObj.common.work_area_sum[index] = e.common.work_area_sum;
        centerReportObj.common.work_time_sum[index] = e.common.work_time_sum;

        centerReportObj.with.mileage_sum[index] = Number(
          e.common.mileage_sum + e.feima.mileage_sum
        );
        centerReportObj.with.uas_serial_id_count[index] = Number(
          e.common.uas_serial_id_count + e.feima.uas_serial_id_count
        );
        centerReportObj.with.work_area_sum[index] = Number(
          e.common.work_area_sum + e.feima.work_area_sum
        );
        centerReportObj.with.work_time_sum[index] = Number(
          e.common.work_time_sum + e.feima.work_time_sum
        );
      });
      let objReport = {};
      let name = "";
      switch (this.droneTagsIndex) {
        case 0:
          objReport = centerReportObj.with;
          name = "全部无人机";
          break;
        case 1:
          objReport = centerReportObj.feima;
          name = "飞马无人机";
          break;
        case 2:
          objReport = centerReportObj.common;
          name = "其他无人机";
          break;
        default:
          break;
      }
      if (Number(this.timeRadio) === 0) {
        let mileage_sum = objReport.mileage_sum;
        let uas_serial_id_count = objReport.uas_serial_id_count;
        let work_area_sum = objReport.work_area_sum;
        let work_time_sum = objReport.work_time_sum;
        objReport.mileage_sum = [0];
        objReport.uas_serial_id_count = [0];
        objReport.work_area_sum = [0];
        objReport.work_time_sum = [0];
        for (let i = 0; i < work_time_sum.length; i++) {
          let index = Math.floor(i / 12);
          if (index + 1 === (i + 1) / 12) {
            objReport.mileage_sum[index] = 0;
            objReport.uas_serial_id_count[index] = 0;
            objReport.work_area_sum[index] = 0;
            objReport.work_time_sum[index] = 0;
          }
          objReport.mileage_sum[index] = Number(
            objReport.mileage_sum[index] + mileage_sum[i]
          );
          objReport.uas_serial_id_count[index] = Number(
            objReport.uas_serial_id_count[index] + uas_serial_id_count[i]
          );
          objReport.work_area_sum[index] = Number(
            objReport.work_area_sum[index] + work_area_sum[i]
          );
          objReport.work_time_sum[index] = Number(
            objReport.work_time_sum[index] + work_time_sum[i]
          );
        }
        objReport.mileage_sum.unshift(0);
        objReport.uas_serial_id_count.unshift(0);
        objReport.work_area_sum.unshift(0);
        objReport.work_time_sum.unshift(0);

        obj.date = "[2019,2020,2021]";
      }
      this.unitNum(objReport, JSON.parse(obj.date), name);
    },
    /**
     * 底部进度条数据获取
     */
    bottomGetFlyData() {
      let obj = this.bottomData;
      this.totalAreaPercentage = Number(
        this.bottomAllDroneData.area.toFixed(2)
      ); //  总飞行面积
      this.totalMileagePercentage = Number(
        this.bottomAllDroneData.meter.toFixed(2)
      ); //  总飞行里程
      this.totalSortiesPercentage = Number(
        this.bottomAllDroneData.sorties_count.toFixed(2)
      ); //  总飞行架次
      this.totalDurationPercentage = Number(
        this.bottomAllDroneData.time.toFixed(2)
      ); //  总飞行时长
      switch (this.statisticsBottom) {
        case "全部无人机":
          this.areaPercentage = this.totalAreaPercentage; //  飞行面积
          this.mileagePercentage = this.totalMileagePercentage; //  飞行里程
          this.sortiesPercentage = this.totalSortiesPercentage; //  飞行架次
          this.durationPercentage = this.totalDurationPercentage; //  飞行时长
          break;
        case "彩虹无人机":
          this.areaPercentage = Number(
            Number(this.bottomCaihonDroneData.area).toFixed(2)
          ); //  飞行面积
          this.mileagePercentage = Number(
            Number(this.bottomCaihonDroneData.meter).toFixed(2)
          ); //  飞行里程
          this.sortiesPercentage = Number(
            Number(this.bottomCaihonDroneData.sorties_count).toFixed(2)
          ); //  飞行架次
          this.durationPercentage = Number(
            Number(this.bottomCaihonDroneData.time).toFixed(2)
          ); //  飞行时长
          break;
        case "其他无人机":
          this.areaPercentage = Number(
            Number(this.bottomOtherDroneData.area).toFixed(2)
          ); //  飞行面积
          this.mileagePercentage = Number(
            Number(this.bottomOtherDroneData.meter).toFixed(2)
          ); //  飞行里程
          this.sortiesPercentage = Number(
            Number(this.bottomOtherDroneData.sorties_count).toFixed(2)
          ); //  飞行架次
          this.durationPercentage = Number(
            Number(this.bottomOtherDroneData.time).toFixed(2)
          ); //  飞行时长
          break;
        default:
          this.areaPercentage = this.totalAreaPercentage; //  飞行面积
          this.mileagePercentage = this.totalMileagePercentage; //  飞行里程
          this.sortiesPercentage = this.totalSortiesPercentage; //  飞行架次
          this.durationPercentage = this.totalDurationPercentage; //  飞行时长
          break;
      }
      this.percentageAreaPercentage =
        this.totalAreaPercentage === 0
          ? 0
          : Number(
              (this.areaPercentage / this.totalAreaPercentage).toFixed(2)
            ) * 100; //  总飞行面积百分比
      this.percentageMileagePercentage =
        this.totalMileagePercentage === 0
          ? 0
          : Number(
              (this.mileagePercentage / this.totalMileagePercentage).toFixed(2)
            ) * 100; //  总飞行里程百分比
      this.percentageSortiesPercentage =
        this.totalSortiesPercentage === 0
          ? 0
          : Number(
              (this.sortiesPercentage / this.totalSortiesPercentage).toFixed(2)
            ) * 100; //  总飞行架次百分比
      this.percentageDurationPercentage =
        this.totalDurationPercentage === 0
          ? 0
          : Number(
              (this.durationPercentage / this.totalDurationPercentage).toFixed(
                2
              )
            ) * 100; //  总飞行时长百分比
    },
    /**
     * 选中标签页
     */
    handleClick() {
      this.unitNum(this.centerGetFlyDataObj, this.allDate, "全部无人机");
      // this.centerGetFlyData(this.centerGetFlyDataObj);
    },
    unitNum(obj, date, name) {
      let formatter = "";
      switch (this.activeName) {
        case "work_area_sum":
          formatter = "km²";
          break;
        case "mileage_sum":
          formatter = "km";
          break;
        case "uas_serial_id_count":
          formatter = "架次";
          break;
        case "work_time_sum":
          formatter = "分钟";
          break;
        default:
          break;
      }
      let unitechart = this.$echarts.init(document.querySelector("#myEcahrts"));
      let option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          formatter: "{a0}:{c0}" + formatter,
        },
        legend: {
          data: [],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "30%",
          containLabel: true,
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false, //坐标轴两边留白
          data: date,
          axisLabel: {
            //坐标轴刻度标签的相关设置。
            interval: 0, //设置为 1，表示『隔一次标签显示一次标签』
            //	margin:15,
            textStyle: {
              color: "#1B253A",
              fontStyle: "normal",
              fontFamily: "微软雅黑",
              fontSize: 12,
            },
            formatter: function (params) {
              var newParamsName = "";
              var paramsNameNumber = params.length;
              var provideNumber = 8; //一行显示几次字
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
              if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  var start = p * provideNumber;
                  var end = start + provideNumber;
                  if (p == rowNumber - 1) {
                    tempStr = params.substring(start, paramsNameNumber);
                  } else {
                    tempStr = params.substring(start, end) + "\n";
                  }
                  newParamsName += tempStr;
                }
              } else {
                newParamsName = params;
              }
              return newParamsName;
            },
            //rotate:50,
          },
          axisTick: {
            //坐标轴刻度相关设置。
            show: false,
          },
          axisLine: {
            //坐标轴轴线相关设置
            lineStyle: {
              color: "#E5E9ED",
              // opacity:0.2
            },
          },
          splitLine: {
            //坐标轴在 grid 区域中的分隔线。
            show: true,
            lineStyle: {
              color: "#E5E9ED",
              // 	opacity:0.1
            },
          },
        },
        yAxis: [
          {
            type: "value",
            splitNumber: 5,
            axisLabel: {
              textStyle: {
                color: "#a8aab0",
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 12,
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#E5E9ED",
                // 	opacity:0.1
              },
            },
          },
        ],
        series: [
          {
            name: name,
            type: "line",
            itemStyle: {
              normal: {
                color: "#3A84FF",
                lineStyle: {
                  color: "#3A84FF",
                  width: 1,
                },
                areaStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "rgba(58,132,255,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(58,132,255,0.35)",
                    },
                  ]),
                },
              },
            },
            data: obj[this.activeName],
          },
        ],
      };
      unitechart.setOption(option);
    },
  },
  //移除事件监听，避免内存泄漏
  beforeDestroy() {
    window.removeEventListener("resize", this.resizefun);
    this.resizefun = null;
  },
  computed: {
    ...mapState(["navMessage", "token", "user"]),
  },
};
</script>

<style lang="less" scoped>
/deep/.el-divider--horizontal {
  margin: 24px 0 0 0;
}
.uavBody {
  background-color: #f0f0f0;
  padding: 0px 30px 170px 30px;
}
.topBar {
  width: 100%;
  height: 16.3%;
  display: flex;
  justify-content: space-between;
}
.statIng {
  float: left;
  .topBgcImg {
    width: 100%;
    height: 100%;
  }
}
.countNum {
  float: left;
  margin-left: 15px;
  .titleNum {
    color: #a6aab8;
    font-size: 16px;
  }
  .countOne {
    color: #507fe7;
    font-size: 40px;
  }
  .danwei {
    color: #507fe7;
  }
}
.growthrate {
  float: right;
  margin-top: 5%;
  margin-right: 8%;
  .growthNum {
    font-size: 16px;
    color: #1cd0bd;
  }
  .growthWord {
    font-size: 12px;
    color: #a6aab8;
  }
}
.countTwo {
  color: #d265e6;
  font-size: 40px;
}
.danweiTwo {
  color: #d265e6;
}
.countThr {
  color: #15bea5;
  font-size: 40px;
}
.danweiThr {
  color: #15bea5;
}
.countFour {
  color: #ff5c5e;
  font-size: 40px;
}
.danweiFour {
  color: #ff5c5e;
}
.imgBoxOne {
  width: 18vw;
  height: 13vh;
  background: url("../../assets/img/statistical／flight／bg1.png") no-repeat
    center;
  background-size: 100% 100%;
}
.imgBoxTwo {
  width: 18vw;
  height: 13vh;
  background: url("../../assets/img/statistical／flight／bg2.png") no-repeat
    center;
  background-size: 100% 100%;
}
.imgBoxThr {
  width: 18vw;
  height: 13vh;
  background: url("../../assets/img/statistical／flight／bg3.png") no-repeat
    center;
  background-size: 100% 100%;
}
.imgBoxFour {
  width: 18vw;
  height: 13vh;
  background: url("../../assets/img/statistical／flight／bg4.png") no-repeat
    center;
  background-size: 100% 100%;
}
.midleBar {
  width: 100%;
  height: 48vh;
  margin-top: 2vh;
  background-color: #fff;
  float: left;
  box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
  padding-top: 10px;
}
.midTitBar {
  width: 45%;
  margin-top: 2vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.midTit {
  font-size: 20px;
  color: #838a9d;
}
.unitLine {
  width: 30px;
  height: 2px;
  background-color: #ff5c5e;
}
.userLine {
  width: 30px;
  height: 2px;
  background-color: #0255d8;
}
.otherLine {
  width: 30px;
  height: 2px;
  background-color: #1dd0bd;
}
#myEcahrts {
  width: 100%;
  height: 80%;
}
.RtopBar {
  // height: 31.2vh;
  background-color: #fff;
  box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
  margin-top: 2vh;
  padding-top: 10px;
}
.Rtitle {
  margin-left: 3%;
}
.titleWord {
  font-size: 16px;
  color: #838a9d;
}
#sector {
  width: 100%;
  height: 100%;
}
#sectorTwo {
  width: 100%;
  height: 100%;
}
.botBar {
  height: 30%;
  width: 100%;
  float: left;
}
@media screen and (min-width: 1465px) and (max-width: 1640px) {
  .uavBody {
    background-color: #f0f0f0;
    padding: 0px 30px 230px 30px;
  }
  .midTit {
    font-size: 18px;
    color: #838a9d;
  }
  .titleWord {
    font-size: 18px;
    color: #838a9d;
  }
  .countNum {
    float: left;
    margin-left: 15px;
    .titleNum {
      color: #a6aab8;
      font-size: 16px;
    }
    .countOne {
      color: #507fe7;
      font-size: 36px;
    }
    .danwei {
      color: #507fe7;
    }
  }
  .countTwo {
    color: #d265e6;
    font-size: 36px;
  }
  .countThr {
    color: #15bea5;
    font-size: 36px;
  }
  .countFour {
    color: #ff5c5e;
    font-size: 36px;
  }
}
@media screen and (min-width: 1366px) and (max-width: 1465px) {
  .uavBody {
    background-color: #f0f0f0;
    padding: 0px 30px 210px 30px;
  }
  .midTit {
    font-size: 17px;
    color: #838a9d;
  }
  .titleWord {
    font-size: 17px;
    color: #838a9d;
  }
  .countNum {
    float: left;
    margin-left: 15px;
    .titleNum {
      color: #a6aab8;
      font-size: 16px;
    }
    .countOne {
      color: #507fe7;
      font-size: 34px;
    }
    .danwei {
      color: #507fe7;
    }
  }
  .countTwo {
    color: #d265e6;
    font-size: 34px;
  }
  .countThr {
    color: #15bea5;
    font-size: 34px;
  }
  .countFour {
    color: #ff5c5e;
    font-size: 34px;
  }
  .unitname {
    font-size: 14px;
  }
  .username {
    font-size: 14px;
  }
  .othername {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1465px) {
  .uavBody {
    background-color: #f0f0f0;
    padding: 0px 30px 17 5px 30px;
  }
  .midTit {
    font-size: 16px;
    color: #838a9d;
  }
  .titleWord {
    font-size: 16px;
    color: #838a9d;
  }
  .countNum {
    float: left;
    margin-left: 13px;
    .titleNum {
      color: #a6aab8;
      font-size: 14px;
    }
    .countOne {
      color: #507fe7;
      font-size: 30px;
    }
    .danwei {
      color: #507fe7;
    }
  }
  .countTwo {
    color: #d265e6;
    font-size: 30px;
  }
  .countThr {
    color: #15bea5;
    font-size: 30px;
  }
  .countFour {
    color: #ff5c5e;
    font-size: 30px;
  }
  .unitname {
    font-size: 12px;
  }
  .username {
    font-size: 12px;
  }
  .othername {
    font-size: 12px;
  }
}

.tabs-radio {
  position: absolute;
  left: 26.67vw;
  top: 10px;
  display: flex;
  align-items: center;
}

.droneTags {
  display: flex;
  align-items: center;
  width: 18.125vw;
  border: 1px solid #a6aab8;
  border-radius: 20px;
  height: 30px;
  line-height: 30px;
  // margin-top: -10px;
  margin-left: 48px;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 14px;
  div {
    width: 33.3%;
    text-align: center;
    cursor: pointer;
    color: #838a9d;
  }
}

.droneTagsBgc {
  background-color: #1182fb;
  color: #fff !important;
}

.statistics-bottom {
  display: flex;
  align-self: center;
  .statistics-div {
    width: 50%;
    height: 36vh;
  }
  .statistics-bottom-div {
    padding-top: 59px;
    position: relative;
    .statistics-column-div {
      height: 40px;
      line-height: 40px;
      display: flex;
      font-size: 14px;
      width: 82%;
    }
  }
}

.drone-type {
  div {
    width: 25%;
    text-align: center;
    cursor: pointer;
  }
}
.flight-time {
  margin-top: 41px;
  div {
    width: 25%;
    text-align: center;
  }
  .flight-time-btn {
    width: 88px;
    height: 36px;
    line-height: 36px;
    border-radius: 20px;
    border: 1px solid #a6aab8;
    color: #a6aab8;
    text-align: center;
    margin-left: 40px;
    cursor: pointer;
  }
}

.selected-btn {
  color: #1182fb !important;
}

.flight-period-btn {
  border: 1px solid #1182fb !important;
  color: #1182fb !important;
}

.flight-time-select {
  margin-top: 24px;
  position: absolute;
  right: 20%;
}

.reset {
  position: absolute;
  right: 20%;
  bottom: 3vh;
}

.statistics-progress {
  padding-top: 23px;
}

.progress-column {
  margin-top: 46px;
  display: flex;
  font-size: 14px;

  .progress-div {
    width: 21.2vw;
    margin-left: 2.34vw;
    margin-top: 5px;
  }

  .progress-text {
    margin-left: 46px;
  }
}
</style>
