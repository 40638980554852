<template>
  <div>
    <div class="appBar">
      <div class="amap-box">
        <el-amap
          :plugin="plugin"
          :events="events"
          :zoom="zoom"
          :center="center"
          class="amap-box"
          :vid="'amap-vue'"
        >
          <el-amap-polygon
            v-for="(polygon, index) in polygons"
            :key="index + '0'"
            :ref="`polygon_${index}`"
            bubble="true"
            :path="polygon.path"
            :stroke-color="polygon.strokeColor"
            :fill-color="polygon.fillColor"
            :fill-opacity="polygon.fillOpacity"
            :draggable="polygon.draggable"
            :events="polygon.events"
          />
        </el-amap>
      </div>
    </div>
    <div class="inforBar">
      <div class="titleBar">
        <span class="title">详情</span>
      </div>
      <el-divider></el-divider>
      <div class="bodyBar">
        <div class="leftBar">
          <p class="tit">预警区域名称:</p>
          <p class="tit">预警灾害:</p>
          <p class="tit">预警建议:</p>
          <p class="tit">账户:</p>
          <p class="tit">创建时间:</p>
        </div>
        <div class="rightBar">
          <p class="content">{{ dataObj.area_name }}</p>
          <p class="content">{{ dataObj.disaster_name }}</p>
          <p class="content">{{ dataObj.advise }}</p>
          <p class="content">{{ dataObj.mobile }}</p>
          <p class="content">{{ dataObj.created_at }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import publicJs from "@/utils/public.js";
export default {
  data() {
    const self = this;
    return {
      id: "",
      dataObj: {},
      zoom: 14,
      center: [0, 0],
      events: {
        init(e, mapStyle) {
          e.setMapStyle("amap://styles//macaron"); // 自定义的高德地图的样式，我选的是马卡龙
        },
        click(e) {
          const { lng, lat } = e.lnglat;
          self.lng = lng;
          self.lat = lat;
        },
      },
      /**
       * 高德插件
       */
      plugin: [
        {
          pName: "Geolocation",
          events: {
            init(o) {
              // o 是高德地图定位插件实例
              o.getCurrentPosition((status, result) => {
                if (result && result.position) {
                  self.lng = result.position.lng;
                  self.lat = result.position.lat;
                  self.center = [self.lng, self.lat];
                }
              });
            },
          },
        },
      ],
      polygons: [], //  多边形的绑定数据
    };
  },
  methods: {
    getWarningDetailData() {
      this.$apis.getWarningDetail({ id: this.id }, this.token).then((res) => {
        if (res.data.status == 0) {
          this.dataObj = res.data.data;
          let coordinateList = publicJs.coordinateStr2coordinateList(
            res.data.data.survey_area_coordinate_points
          );

          let path = [];
          coordinateList.forEach((point) => {
            path.push([point[0], point[1]]);
          });
          let polygonItem = {
            draggable: false,
            strokeColor: "#49B2FF",
            fillColor: "#47ABF5",
            fillOpacity: 0.5,
            path: path,
            visible: false,
          };

          this.polygons.push(polygonItem);
          let centerpoint = this.polygons[0].path[0];

          this.center = [centerpoint[0], centerpoint[1]];
        }
      });
    },
  },
  created() {},
  mounted() {
    this.id = this.$route.query.id;
    this.getWarningDetailData();
  },
  watch: {
    $route() {
      this.id = this.$route.query.id;
      this.getWarningDetailData();
    },
  },
  computed: {
    ...mapState(["user", "token"]),
  },
};
</script>

<style lang="less" scoped>
.appBar {
  width: 100%;
  height: calc(100vh - 50px);
  float: left;
  background-color: #f0f0f0;
  .amap-box {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
/deep/.el-divider--horizontal {
  margin: 15px 0;
}
.inforBar {
  position: absolute;
  left: 15px;
  top: 30%;
  width: 400px;
  height: 400px;
  background-color: #fff;
  border-radius: 4px;
  .titleBar {
    width: 100%;
    font-size: 14px;
    color: #b4b4b4;
    margin: 15px 0 0 20px;
  }
  .bodyBar {
    width: 100%;
    .leftBar {
      width: 30%;
      float: left;
      margin-left: 30px;
      font-size: 14px;
      .tit {
        margin-top: 35px;
      }
    }
    .rightBar {
      float: right;
      width: 50%;
      font-size: 14px;
      color: #b4b4b4;
      .content {
        margin-top: 35px;
      }
    }
  }
}
</style>