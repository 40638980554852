<template>
  <div class="comBarC">
    <div class="month">
      <el-date-picker
        v-model="value1"
        type="monthrange"
        range-separator="→"
        start-placeholder="开始月份"
        end-placeholder="结束月份"
        @change="monthChange"
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
    </div>
    <div>
      <div id="eachartBar" class="echart" />
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
export default {
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          unit: "平方公里",
          time: [],
          diagram: {},
        };
      },
    },
  },
  watch: {
    options: {
      handler: "initDatas",
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      value1: "",
      monthlyData: "",
    };
  },
  mounted() {
    // this.monthReportPushCount()
  },
  methods: {
    initDatas() {
      if (document.getElementById("eachartBar")) {
        this.monthReportPushCount();
      } else {
        setTimeout(() => {
          this.initDatas();
        }, 500);
      }
    },
    // 月推送统计
    monthReportPushCount() {
      this.value1 = this.options.time ? this.options.time : [];
      if (!this.options.diagram) return;
      var chartDom = document.getElementById("eachartBar");
      var myChart = echarts.init(chartDom);
      var option;
      // 后端接口数据有时候会多1个月，在这里筛选掉
      const months = this.getMonthRangeLength();
      // var dataAxis = this.stringToMonthArr(this.options.diagram.date).slice(0, months);
      let dataAxis = [];
      let data = [];
      if (this.options.unit === "平方公里") {
        dataAxis = this.stringToMonthArr(this.options.diagram.date).slice(
          0,
          months
        );
        data = this.options.diagram.data.slice(0, months);
        var yMax = 500;
        var dataShadow = [];

        for (var i = 0; i < data.length; i++) {
          dataShadow.push(yMax);
        }
      } else {
        let startDate = this.value1[0].split("-")[1];
        let endDate = this.value1[1].split("-")[1];
        for (let i = startDate; i <= endDate; i++) {
          dataAxis.push(i + "月");
        }
        var yMax = 500;
        var dataShadow = [];
        if (this.options.diagram.dateList) {
          this.options.diagram.dateList.forEach((item) => {
            data.push(this.options.diagram.data[item]);
            dataShadow.push(yMax);
          });
        }
      }

      option = {
        tooltip: {
          trigger: "item",
          backgroundColor: "transparent",
          formatter: function (params) {
            // console.log(params);
            let data = params.data;
            let str =
              '<div class="echartDatas"><div class="yAxisData">' +
              params.data +
              "</div></div>";
            return str;
          },
          position: function (pos, params, dom, rect, size) {
            const obj = [pos[0], pos[1] - 30];
            return obj;
          },
        },
        grid: {
          // containLabel: true
          left: 40
        },
        title: {
          text: "",
          subtext: this.options.unit,
        },
        xAxis: {
          data: dataAxis,
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#fff",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          // z: 10
        },
        yAxis: [
          {
            axisLabel: {
              interval: 0,
              show: true,
              rotate: 0,
              textStyle: {
                fontStyle: "normal",
                fontFamily: "微软雅黑",
                fontSize: 11,
              },
            },
            splitLine: {
              // 网格线
              lineStyle: {
                type: "dotted", // 设置网格线类型 dotted：虚线   solid:实线
                color: ["#3C4353"],
                width: 1,
              },
              show: true, // 隐藏或显示
            },
            axisLine: {
              lineStyle: {
                color: "#69789E",
              },
            },
          },
          {
            type: "value",
            show: true,
          },
          {
            gridIndex: 1,
            show: false,
          },
        ],
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 8,
            showBackground: true,
            itemStyle: {
              barBorderRadius: 10,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#05C8D7" },
                // {offset: 0.5, color: '#188df0'},
                { offset: 1, color: "#0086FF" },
              ]),
            },
            // emphasis: {
            //   itemStyle: {
            //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //       { offset: 0, color: "#2378f7" },
            //       { offset: 0.7, color: "#2378f7" },
            //       { offset: 1, color: "#83bff6" },
            //     ]),
            //   },
            // },
            data: data,
          },
        ],
      };

      option && myChart.setOption(option);
    },
    // 月份改变
    monthChange() {
      this.$emit("rangeChange", this.value1);
    },

    // 实际需要拿到的数据长度
    getMonthRangeLength() {
      const date1 = new Date(this.value1[1]);
      const date0 = new Date(this.value1[0]);
      const months =
        (date1.getFullYear() - date0.getFullYear()) * 12 +
        (date1.getMonth() - date0.getMonth());
      return Math.abs(months) + 1;
    },
    // 字符串数组转月份数组
    stringToMonthArr(date) {
      if (!date) return null;
      const arr = JSON.parse(date);
      let dateArr = arr.map((date) => {
        return new Date(date).getMonth() + 1 + "月";
      });
      return dateArr;
    },
  },
};
</script>
<style lang="scss" scoped>
.comBarC {
  .month {
    margin-bottom: 35px;
    /deep/.el-range-editor.el-input__inner {
      border-radius: 22px !important;
      background-color: #0c2039 !important;
      border: 1px solid #7893b9 !important;
    }
    /deep/.el-range-editor--medium .el-range-input {
      background-color: #0c2039 !important;
    }
    /deep/.el-range-editor--medium .el-range-separator {
      color: #ffffff !important;
    }
  }
}
.echart {
  width: 300px;
  height: 345px;
}
.yAxisData {
}
</style>
