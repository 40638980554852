<template>
  <div>
    <div class="appBar">
      <div class="bodyBar">
        <div class="signBar">
          <span class="title">舆情发布</span>
          <el-input
            v-model="keywordAll"
            class="alliInput"
            placeholder="请输入任务名称进行搜索"
            @keyup.enter.native="searchTask"
            @input="fucusAll(keywordAll)"
          >
            <i slot="suffix" class="iconfont searchAll" @click="searchTask"
              >&#xe61d;</i
            >
          </el-input>
        </div>
        <div class="center-body">
          <el-table
            height="100%"
            :stripe="true"
            :data="tableData"
            style="width: 100%; margin-top: 5%"
          >
            <el-table-column
              align="center"
              prop="project_name"
              label="任务名称"
              width="300"
            >
              <template slot-scope="scope" class="taskLine">
                <div class="taskBar">
                  <img class="taskImg" :src="scope.row.area_snapshoot" alt />
                  <span class="taskWord">{{ scope.row.project_name }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="created_at"
              label="发布时间"
              width="180"
            />
            <el-table-column
              align="center"
              prop="project_type"
              label="任务类型"
              width="180"
            >
              <template slot-scope="scope" class="taskLine">
                {{ scope.row.project_type == 1 ? "航线任务" : "通用任务" }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="type"
              label="是否发过起现场连线"
              width="150"
            >
              <template slot-scope="scope" class="taskLine">
                {{ scope.row.spot_connection == 1 ? "是" : "否" }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="disaster_level"
              label="灾情态势"
            />
            <el-table-column align="center" prop="taskDetails" label="舆情详情">
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="sentiment(scope.row.id)"
                  >详情</el-link
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="resultspreview"
              label="任务详情"
            >
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  :underline="false"
                  @click="upload(scope.row)"
                  >详情</el-link
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="block">
            <el-pagination
              :current-page="current_page"
              :page-size="per_page"
              layout="total,  prev, pager, next, jumper"
              :total="total"
              @current-change="currentchange"
              @next-click="handleNext"
              @prev-click="handlePrev"
            />
          </div>
        </div>
      </div>
    </div>
    <sentiment
      ref="sent"
      :sentiment-data="sentimentData"
      :project-name="projectName"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import sentiment from "@/components/messageBox/sentimentDetails";
export default {
  components: {
    sentiment,
  },
  data() {
    return {
      keywordAll: "",
      userList: [],
      tableData: [],
      sentimentData: {},
      projectName: [],
      current_page: 1, // 当前页
      total: 0, // 总条目数
      per_page: 6, // 每页多少条
      page: 1,
    };
  },
  created() {
    this.getDisasterList();
  },
  methods: {
    ...mapMutations(["navMessageStorage", "currentUrlStorage"]),
    fucusAll(keywordAll) {
      if (keywordAll == "") {
        this.getDisasterList();
      }
    },
    upload(param) {
      this.currentUrlStorage([]);
      this.currentUrlStorage([
        "首页",
        "应急任务",
        param.project_type === 1 ? "航线任务" : "通用任务",
      ]);
      this.navMessageStorage(0);
      this.$router.push({
        path: "/taskDetails",
        query: {
          nav: param.project_type,
          id: param.project_id,
          name: param.project_name,
          mobile: param.project_user_mobile,
          push_flag: param.push_flag,
        },
      });
    },
    getDisasterList() {
      this.$apis
        .getDisasterList(
          {
            search_project_name: this.keywordAll,
            per_page: 6,
            page: this.page,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status == 0) {
            this.tableData = data.data.data.data;
            this.total = data.data.data.total;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 搜索任务按钮
    searchTask() {
      this.page = 1;
      this.getDisasterList();
    },
    sentiment(row) {
      setTimeout(() => {
        this.$refs.sent.open();
      }, 800);
      this.$apis
        .getDisasterDetail({ id: row }, this.token)
        .then((data) => {
          if (data.data.status == 0) {
            this.sentimentData = data.data.data;
            this.projectName = data.data.data.relation_task_name;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 分页
    handleNext() {
      this.page++;
      this.getDisasterList(this.page, this.per_page);
    },
    handlePrev() {
      this.page--;
      this.getDisasterList(this.page, this.per_page);
    },
    currentchange(page) {
      this.page = page;
      this.getDisasterList(this.page, this.per_page);
    },
  },
  computed: {
    ...mapState(["user", "token"]),
  },
};
</script>

<style lang="less" scoped>
.taskBar {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  line-height: 74px;
}
.block {
  position: absolute;
  bottom: 20px;
  margin-left: 10px;
}
.taskImg {
  width: 74px;
  height: 74px;
  border-radius: 4px;
}
.taskWord {
  text-align: center;
  margin-left: 15px;
}
/deep/.el-table--scrollable-y .el-table__body-wrapper {
  height: 100% !important;
}
/deep/.el-table .cell {
  display: flex;
  justify-content: center;
  align-items: center;
}
.appBar {
  width: 100%;
  height: calc(100vh - 50px);
  float: left;
  background-color: #f0f0f0;
}
.bodyBar {
  // height: calc(100vh - 50px);
  height: 98%;
  width: 70%;
  float: left;
  margin-left: 15%;
  background-color: #ffffff;
  margin-top: 20px;
  padding: 30px 20px 0px 20px;
}
.signBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    color: #828a9e;
    font-size: 16px;
  }
}
.searchAll {
  cursor: pointer;
  margin-top: 8px;
  display: block;
}
.alliInput {
  width: 300px;
}
</style>
