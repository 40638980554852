<template>
  <div class="content-data-item" v-if="sortiesData">
    <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
      ><span class="deviceName">{{ deviceName }}</span>
      <span class="deviceTotal">{{ sortiesData.count }}</span>
      <span class="showMore" @click.prevent="showMoreOtherDrone">
        <img
          src="@/assets/img/centerHome/caretdown.png"
          alt=""
          :class="{ selectRotate: isMoreOtherDrone }"
      /></span>
    </el-checkbox>

    <div v-show="isHistoricalTask" class="selectDate">
      <selectDate :date-range-type="deviceName" @getDateData="getDateData" />
    </div>
    <div></div>
    <div class="caihongDrone" v-show="isMoreOtherDrone" ref="droneData">
      <el-checkbox-group
        v-model="checkedBrand"
        style="display: flex; flex-wrap: wrap"
      >
        <el-checkbox
          v-for="(val, key) in sortiesData.brand"
          :label="key"
          :key="key"
          @change="handleChecedOne(key)"
          style="margin: 14px 0 14px 14px"
          ><span style="color: #7893b9;display: flex;align-items: center;"
            ><span class="styleKey" :title="key">{{ key }}</span
            ><span class="styleVal">{{ val }}</span></span
          >
        </el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>
<script>
import selectDate from "./selectDate";
export default {
  props: {
    isHistoricalTask: {
      type: Boolean,
      default: true,
    },
    sortiesData: {
      type: Object,
    },
    deviceName: {
      type: String,
    },
    isDate: {
      type: Boolean,
      default: false,
    },
    datedDeviceName: {
      type: String,
      default: "",
    },
    initSelect: {
      type: Number,
      default: 1,
    }
  },
  components: {
    selectDate,
  },

  data() {
    return {
      checkAll: true,
      isMoreOtherDrone: false,

      checkedBrand: [],
      allCheckedBrand: [],
    };
  },
  watch: {
    initSelect(newVal, oldVal){
       this.checkedBrand = [];
        this.checkAll = true;
        for (let key in this.sortiesData.brand) {
          this.checkedBrand.push(key);
        }
        this.allCheckedBrand = JSON.parse(JSON.stringify(this.checkedBrand));
    },
    isDate(newVal, oldVal){
      if(newVal){
        this.checkedBrand = [];
        this.checkAll = true;
        for (let key in this.sortiesData.brand) {
          this.checkedBrand.push(key);
        }
        this.allCheckedBrand = JSON.parse(JSON.stringify(this.checkedBrand));
      }
    },
    sortiesData(newVal, oldVal) {
      // if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
      //   console.log("改变选中状态", newVal,oldVal);
      //   this.checkedBrand = [];
      //   this.checkAll = true;
      //   for (let key in this.sortiesData.brand) {
      //     this.checkedBrand.push(key);
      //   }
      //   this.allCheckedBrand = JSON.parse(JSON.stringify(this.checkedBrand));
      // }
    },
    datedDeviceName(newVal, oldVal) {
        if (this.datedDeviceName==="无人机"){
          this.checkedBrand = [];
          this.checkAll = true;
          for (let key in this.sortiesData.brand) {
            this.checkedBrand.push(key);
          }
          this.allCheckedBrand = JSON.parse(JSON.stringify(this.checkedBrand));
        }
        if (this.deviceName === this.datedDeviceName) {
          this.checkedBrand = [];
          this.checkAll = true;
          for (let key in this.sortiesData.brand) {
            this.checkedBrand.push(key);
          }
          this.allCheckedBrand = JSON.parse(JSON.stringify(this.checkedBrand));
        }
    },
  },
  mounted() {
    for (let key in this.sortiesData.brand) {
      this.checkedBrand.push(key);
    }
    this.allCheckedBrand = JSON.parse(JSON.stringify(this.checkedBrand));
  },
  methods: {
    handleCheckAllChange(e) {
      this.checkedBrand = e ? this.allCheckedBrand : [];
      let show = false;
      if (!e) {
        show = false;
      } else {
        show = true;
      }

      this.$emit("checkedDevice", { parent: this.deviceName, show: show });
    },
    showMoreOtherDrone() {
      this.isMoreOtherDrone = !this.isMoreOtherDrone;
    },
    // 根据新的日期获取新的数据
    getDateData(data) {
      // console.log(data);
      this.$emit("getDateData", data);
    },
    handleCheckedBrandChange(value) {},
    // 选中一个
    handleChecedOne(val) {
      let checkedCount = this.checkedBrand.length;
      this.checkAll = checkedCount === this.allCheckedBrand.length;
      // console.log(this.deviceName, val, this.checkedBrand);
      let show = true;
      if (this.checkedBrand.indexOf(val) === -1) {
        show = false;
      }
      this.$emit("checkedDevice", {
        parent: this.deviceName,
        show: show,
        sonLayer: val,
      });
      // this.$emit("checkedDevice", {
      //   parent: this.deviceName,
      //   show: show,
      //   sonLayer: val,
      // });
    },
  },
  filters: {
    filtTotal(val) {
      let total = 0;
      if (val) {
        val.forEach((item) => {
          total = total + item.count;
        });
      }
      return total;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-data-item {
  width: 100%;
  position: relative;
  margin-top: 24px;
  .checkBoxGroup {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    width: 269px;
  }

  .deviceName {
    font-weight: bold;
    color: #fff;
  }
  .deviceTotal {
    font-weight: bold;
    color: #ffd65c;

    margin-left: 10px;
    margin-right: 4px;
  }
  .selectDate {
    position: absolute;
    right: 0;
    top: 2px;
  }
}

.showMore {
  position: relative;
  img {
    position: absolute;
    top: 2px;
    left: 5px;
    transform: rotate(180deg);
    cursor: pointer;
  }
  .selectRotate {
    transform: rotate(0deg);
  }
}
.caihongDrone {
  margin-top: 13px;
  width: 296px;
  background: rgba(105, 120, 158, 0.3);
  border-radius: 4px;
  .styleKey {
    display: inline-block;
    width: 65px;
    margin-right: 10px;
    overflow: hidden; /*超出部分隐藏*/
    white-space: nowrap; /*不换行*/
    text-overflow: ellipsis; /*超出部分文字以...显示*/
  }
  .styleVal {
    display: inline-block;
    width: 35px;
  }
}
</style>
