const defaultPrecisionsTypes = [{
    checked: false,
    value: '1:500',
  },
  {
    checked: false,
    value: '1:1000',
  }, {
    checked: false,
    value: '1:2000',
  },
]
const lidar = [
  {checked:true,value:""}
]
const defaultGsdTypes = [
  {
      checked: false,
      value: '4cm',
    },
    {
      checked: true,
      value: '8cm',
    },
    {
      checked: false,
      value: '16cm',
    }
]
const sensors = {
  '正射': {
    gsdTypes: [{
        checked: false,
        value: '4cm',
      },
      {
        checked: true,
        value: '8cm',
      },
      {
        checked: false,
        value: '16cm',
      }
    ],
    longitudinal_overlap: '80',
    lateral_overlap: '50',
    resultTypes: [{
        label: "原始影像",
        value: '原始影像',
      },
      {
        label: "快拼",
        value: '快拼',
      },
      {
        label: "TDOM",
        value: 'TDOM',
      },
      {
        label: "DOM",
        value: 'DOM',
      },
      {
        label: "DSM",
        value: 'DSM',
      },
      {
        label: "DEM",
        value: 'DEM',
      },
      {
        label: "DLG",
        value: 'DLG',
      },

    ],
    resultPresicions: defaultPrecisionsTypes
  },
  '倾斜': {
    gsdTypes: [{
        checked: false,
        value: '2cm',
      },
      {
        checked: true,
        value: '3cm',
      },
      {
        checked: false,
        value: '5cm',
      },
    ],
    longitudinal_overlap: '80',
    lateral_overlap: '70',
    resultTypes: [{
        checked: false,
        value: '原始影响',
      },
      {
        checked: false,
        value: '实景三维模型',
      },
      {
        checked: false,
        value: 'TDOM',
      },
      {
        checked: false,
        value: 'DOM',
      },
      {
        checked: false,
        value: 'DSM',
      },
      {
        checked: false,
        value: 'DEM',
      },
      {
        checked: false,
        value: 'DLG',
      },
    ],
    resultPresicions: defaultPrecisionsTypes,
  },
  'LIDAR': {
    gsdTypes: defaultGsdTypes,
    longitudinal_overlap: '0',
    lateral_overlap: '0',
    resultTypes: [{
        checked: false,
        value: '原始数据',
      },
      {
        checked: false,
        value: '标准点云',
      },
      {
        checked: false,
        value: '分类点云',
      },
      {
        checked: false,
        value: 'DEM',
      },
      {
        checked: false,
        value: 'DLG',
      },
    ],
    resultPresicions: defaultPrecisionsTypes,
  },
  'LIDAR+正射': {
    gsdTypes: lidar,
    longitudinal_overlap: '',
    lateral_overlap: '',
    resultTypes: [{
        checked: false,
        value: '原始数据',
      },
      {
        checked: false,
        value: '标准点云',
      },
      {
        checked: false,
        value: '分类点云',
      },
      {
        checked: false,
        value: '快拼',
      },
      {
        checked: false,
        value: 'TDOM',
      },
      {
        checked: false,
        value: 'DOM',
      },
      {
        checked: false,
        value: 'DSM',
      },
      {
        checked: false,
        value: 'DEM',
      },
      {
        checked: false,
        value: 'DLG',
      },
    ],
    resultPresicions: defaultPrecisionsTypes,
  },
}

export default sensors
