<template>
  <div>
    <el-dialog
      title="编辑任务群组"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      class="taskBox"
      v-dialogDrag
    >
      <el-divider></el-divider>
      <div class="detailBar">
        <div class="formBox">
          <el-form
            class="formbox"
            ref="ruleForm"
            :rules="rules"
            :model="ruleForm"
            label-width="80px"
          >
            <el-form-item class="userForm" prop="name" label="群组名称">
              <el-input placeholder="请输入" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item class="userForm" prop="source " label="创建源">
              <el-input v-model="ruleForm.source" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item class="userForm" prop="admin" label="管理员">
              <el-input v-model="ruleForm.admin" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item class="userForm" prop="Allianceunit" label="巡护单位">
              <el-input
                v-model="ruleForm.Allianceunit"
                :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item class="userForm" prop="adduser" label="添加成员">
              <div class="userList">
                <span
                  class="unitUser"
                  v-for="(item, index) in unitUserList"
                  :key="index"
                  >{{ item.name }}</span
                >
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-divider></el-divider>
      <span slot="footer" class="dialog-footer">
        <el-button class="footBtn" @click="newGrounp" type="primary"
          >确 认</el-button
        >
        <el-button class="footBtn" @click="dialogVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
    <AddMember
      ref="addmem"
      :grounpObj="grounpObj"
      @handAddReg="getChooseList"
    />
  </div>
</template>

<script>
import { mapState,mapMutations } from "vuex";
import AddMember from "./addMember";
export default {
  inject: ["reload"],
  props: {
    groupObj: {
      type: Object,
      default: {}
    },
    grounpInfo: {
      type: Object,
      default: {}
    }
  },
  components: {
    AddMember
  },
  data () {
    return {
      grounpObj: {},
      dialogVisible: false,
      ruleForm: {
        name: "",
        source: "调度中心端",
        admin: "",
        Allianceunit: "",
        adduser: ""
      },
      rules: {
        name: [],
        source: [],
        admin: [],
        Allianceunit: [],
        adduser: []
      },
      page: 1,
      unitUserList: []
    };
  },
  methods: {
    // 接收选中的用户数组
    getChooseList (param) {
    },
    openAddUser () {
      this.getGroupUser();
      this.ruleForm.name=this.grounpInfo.name;
      this.ruleForm.admin=this.user.mobile;
      this.ruleForm.Allianceunit=this.groupObj.unit_name;
      this.dialogVisible=true;
      this.grounpObj=this.groupObj;
    },
    newGrounp () {
      this.$apis
        .groupupdate(
          {
            group_id: this.grounpInfo.id,
            name: this.ruleForm.name
          },
          this.token
        )
        .then(data => {
          if(data.data.status==0) {
            this.reload();
            this.$message({
              message: data.data.message,
              type: "success"
            });
          } else {
            this.$message({
              message: data.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.error(err);
        });
      this.dialogVisible=false;
    },
    // 获取群组下的成员
    getGroupUser () {
      this.$apis
        .groupuserList(
          {
            group_id: this.grounpInfo.id
          },
          this.token
        )
        .then(data => {
          this.unitUserList=data.data.data
         
        })
        .catch(err => {
          console.error(err);
        });
    },
    goAdduser () {
      this.$refs.addmem.open();
    }
  },
  created () {
  },
  computed: {
    ...mapState(["user","token"])
  }
};
</script>

<style lang="less" scoped>
.userList {
  overflow-y: auto;
}
.unitUser {
  margin-left: 10px;
}
.addImg {
  cursor: pointer;
}
.formBox {
  margin-top: 50px !important;
  margin-left: 30px;
  margin-bottom: 50px;
}
/deep/.el-dialog {
  width: 460px;
  height: 560px;
  margin-left: 73vh;
  margin-top: 15vh;
}
.footBtn {
  width: 140px;
  height: 48px;
  margin-left: 20px;
}
.el-input {
  width: 277px;
}
/deep/.el-select {
  width: 277px;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-dialog__footer {
  text-align: center;
  padding: 0px;
  padding-bottom: 10px;
}
/deep/.el-dialog__title {
  margin-left: 25px;
  line-height: 50px;
}
/deep/.el-dialog__header {
  height: 50px;
}
/deep/.el-input--medium .el-input__inner {
  margin-top: 0px !important;
  margin-left: 0px !important;
  width: 190px;
}
@media only screen and (max-width: 1670px) {
  .addImg {
    cursor: pointer;
  }
  /deep/.el-dialog {
    width: 400px;
    height: 500px;
    margin-left: 73vh;
    margin-top: 15vh;
  }
  /deep/.el-input--medium .el-input__inner {
    margin-top: 0px !important;
    margin-left: 0px !important;
    width: 190px;
  }
  .el-input {
    width: 222px;
  }
  /deep/.el-input--medium {
    width: 222px;
  }
  .formBox {
    margin-top: 30px !important;
    margin-left: 30px;
    margin-bottom: 30px;
  }
  /deep/.el-dialog__footer {
    padding: 0px;
  }
}
</style>