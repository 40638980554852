const wsURL = process.env.VUE_APP_WEBSOCKET_URL;
import store from "@/store";
let that = ""
class LinkWebSocket {
  constructor(){
    this.websocket = null
    this.lockReconnect = false
    this.timeout = 20000//20秒一次心跳
    this.timeoutObj = null //心跳心跳倒计时
    this.serverTimeoutObj=null //心跳倒计时
    this.timeoutnum = null //断开 重连倒计时
    this.sendData = {}
    that = this;
    this.wsInit()

  }
  wsInit() {
    if (typeof WebSocket === "undefined") {
      console.error("您的浏览器不支持WebSocket");
      return;
    }

    this.websocket = new WebSocket(wsURL);

    this.websocket.onerror = this.wsOnerror;
    this.websocket.onmessage = this.wsOnMessage;
    this.websocket.onclose = this.wsClose;
  }
  wsSendMes(data) {
    this.websocket.onopen = event => {
      if (this.websocket.readyState == 1){
        this.wsSend(data);
        this.sendData = data;
        this.start();
      }
    };
  }
  wsOnerror(e) {
    // console.log("WebSocket连接发生错误", e);
    this.reconnect();
  }
  wsCloseWhenCloseView(){
    this.websocket.close();
    clearTimeout(this.timeoutObj);
    clearTimeout(this.serverTimeoutObj);
    this.websocket.onclose = function(evt) {
      // console.log("websocket已关闭");

    };
  }
  // 关闭
  wsClose(e) {
    // console.log("关闭");
    this.reconnect;
  }
  // 数据接收
  wsOnMessage(e) {
    // console.log(webSocket.timeout);
    if (e !== undefined) {
      const jsonData = eval("(" + e.data + ")");
      if (typeof jsonData.data === "object") {
        // store.commit("setMonitorPlaneData", jsonData.data);
        that.wsSendDataToCom(e)
      }
    }
    // this.reset();
  }
  wsSendDataToCom(e) { }
  // 数据发送
  wsSend(agentData) {
    this.websocket.send(JSON.stringify(agentData));
  }
  reconnect() {
    // console.log("重新连接");
    //重新连接
    var that = this;
    if (that.lockReconnect) {
      return;
    }
    that.lockReconnect = true;
    //没连接上会一直重连，设置延迟避免请求过多
    that.timeoutnum && clearTimeout(that.timeoutnum);
    that.timeoutnum = setTimeout(function() {
      //新连接
      that.wsInit();

      that.wsSendMes(that.sendData);
      that.lockReconnect = false;
    }, 5000);
  }
  reset() {
    // console.log("重置心跳");
    //重置心跳
    var that = this;
    //清除时间
    clearTimeout(that.timeoutObj);
    clearTimeout(that.serverTimeoutObj);
    //重启心跳
    that.start();
  }
  start() {
    //开启心跳
    var self = this;
    self.timeoutObj && clearTimeout(self.timeoutObj);
    self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
    self.timeoutObj = setTimeout(function() {
      //这里发送一个心跳，后端收到后，返回一个心跳消息，
      if (self.websocket.readyState == 1) {
        //如果连接正常
        // console.log("正常");
        self.websocket.send("ping");
        self.reset();
      } else {
        console.log("后端未返回信息");
        //否则重连
        self.reconnect();
      }
      self.serverTimeoutObj = setTimeout(function() {
        //超时关闭
        console.log("超时");
        self.websocket.close();
        self.reconnect();
      }, self.timeout);
    }, self.timeout);
  }
}

export default LinkWebSocket;
