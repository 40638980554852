<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      class="upfile"
      v-dialogDrag
    >
      <div class="titleBar">
        <span class="title">数据回传</span>
        <el-divider></el-divider>
        <div v-if="this.flag == 1" class="bodyBar">
          <div class="leftBar">
            <span class="uptit">上传文件</span>
          </div>
          <div class="rightBar">
            <div class="uplay">
              <div class="clickplay">
                <img
                  class="upImg"
                  src="@/assets/img/datareturn／upload／file.png"
                  alt
                />
                <span class="describe"
                  >点击上传单个/多个文件，或将单个/多个文件拖拽到这里</span
                >
                <input
                  type="file"
                  multiple="multiple"
                  ref="file"
                  @change="aetherupload"
                  class="uploadInput"
                />
              </div>
            </div>
            <div class="sider"></div>
            <div class="tips">
              <div class="tit">
                <i class="el-icon-warning-outline"></i>
                <span class="titWord">文件上传提示</span>
                <p class="tipsWord">1、请勿一次上传多个大型文件</p>
                <p class="tipsWord">
                  2、如需上传大型文件，请待上传进度完成以后在进行下一个文件上传
                </p>
                <p class="tipsWord">3、请勿重复上传</p>
                <p class="tipsWord">
                  4、上传过程中请勿刷新页面，否则任务将重新进行
                </p>
              </div>
              <div class="bodyNav"></div>
            </div>
          </div>
        </div>
        <div v-if="this.flag == 2" class="bodyBar">
          <div class="leftBar">
            <span class="uptit">上传文件夹</span>
          </div>
          <div class="rightBar">
            <div class="uplay">
              <div class="clickplay">
                <img
                  class="upImg"
                  src="@/assets/img/datareturn／upload／folder.png"
                  alt
                />
                <span class="describe"
                  >点击上传单个/多个文件，或将单个/多个文件拖拽到这里</span
                >
                <input
                  type="file"
                  multiple="multiple"
                  ref="file"
                  webkitdirectory
                  @change="aetherupload"
                  class="uploadInput"
                />
              </div>
            </div>
            <div class="sider"></div>
            <div class="tips">
              <div class="tit">
                <i class="el-icon-warning-outline"></i>
                <span class="titWord">文件上传提示</span>
                <p class="tipsWord">1.请勿一次上传多个大型文件</p>
                <p class="tipsWord">
                  2.如需上传大型文件，请待上传进度完成以后在进行下一个文件上传
                </p>
                <p class="tipsWord">3、请勿重复上传</p>
                <p class="tipsWord">
                  4、上传过程中请勿刷新页面，否则任务将重新进行
                </p>
              </div>
              <div class="bodyNav"></div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisibleTwo"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="titleBar" v-if="filesList.length > 0">
        <span class="title">正在上传({{ underway }}/{{ sum }})</span>
        <el-divider></el-divider>
        <div class="bodyBar">
          <div class="dataTit">
            <span class="title">文件名</span>
            <span class="title">大小</span>
            <span class="title">状态</span>
          </div>
          <div class="mainBar">
            <div
              class="fileline"
              v-for="(item, index) in filesList"
              :key="index"
            >
              <div class="filename">
                <img
                  class="fileImg"
                  src="@/assets/img/datareturn／other.png"
                  alt
                />
                <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  :content="item.name"
                >
                  <span slot="reference" class="name">{{ item.name }}</span>
                </el-popover>
              </div>
              <div class="filesize">
                <span class="size">{{ item.size }}</span>
              </div>
              <div class="filestatus iconfont">
                <span>{{ filesList[index].status }}</span>
                <!-- <span
                  style="color: #1bd0be"
                  v-else-if="item.status === 'success'"
                >
                  &#xe670;</span
                >
                <span style="color: #f00" v-else-if="item.status === 'lose'"
                  >&#xe639;</span
                > -->
              </div>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="footbtn">
          <el-button
            class="footBtn"
            :disabled="underway !== 0"
            type="primary"
            @click="suerBtn"
            >确 定</el-button
          >
          <el-button
            class="footBtn"
            @click="
              dialogVisibleTwo = false;
              dialogVisible = true;
            "
            :disabled="underway !== 0"
            >继续回传</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SparkMD5 from "spark-md5";
import { mapState, mapMutations } from "vuex";
window.progress = {};

export default {
  inject: ["reload"],
  name: "uploading",
  data() {
    return {
      success: false,
      existence: false,
      status: false,
      dialogVisible: false,
      dialogVisibleTwo: false,
      size: "",
      timeat: "",
      time: "",
      showTableList: false,
      sum: 0, //  总数量
      schedule: 0, //  已完成数量
      underway: 0, //  进行中数量
      percentageNum: 0,
      underwayNum: 0,
      flies: [
        {
          isActive: true,
          number: 1,
        },
      ],
      current_page: 1, //当前页
      total: 0, //总条目数
      page_size: 0, //每页多少条
      page: 1,
      filesList: [],
      flag: 0,
      bytesTosize: "",
      fileDataList: [],
    };
  },
  watch: {
    dialogVisibleTwo() {
      if (this.dialogVisibleTwo === false) {
        this.sum = 0;
      }
    },
  },
  methods: {
    suBtn() {
      this.fileList();
      this.dialogVisible = false;
    },
    suerBtn() {
      this.fileList();
      this.dialogVisibleTwo = false;
      this.sum = 0;
    },
    // 文件列表
    fileList() {
      this.$apis
        .uploadList({}, this.token)
        .then((data) => {
          this.fileDataList = data.data.data.data;
          this.total = data.data.data.total;
          this.current_page = data.data.data.current_page;
          this.page_size = data.data.data.per_page;
          this.size = data.data.data.data.size;
          this.bytesTosize(data.data.data.data.size);
        })
        .catch((err) => {});
    },
    // 字节转换
    bytesToSize(size) {
      if (size === 0) return "0 B";
      var k = 1024;
      var sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      var i = Math.floor(Math.log(size) / Math.log(k));
      this.bytesTosize =
        (size / Math.pow(k, i)).toPrecision(3) + " " + sizes[i];
      return (size / Math.pow(k, i)).toPrecision(3) + " " + sizes[i];
    },
    open(data) {
      this.dialogVisible = true;
      this.flag = data;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    aetherupload(e) {
      this.dialogVisible = false;
      const inputs = e.target;
      const files = e.target.files;
      this.sum = this.sum + files.length;
      if (files.length > 0) {
        this.filesList = [];
        for (let i = 0; i < files.length; i++) {
          this.filesList[i] = {
            name: "",
            size: 0,
            status: "0%",
          };
          this.filesList[i].name = files[i].name;
          if (files[i].size > 1024 * 1024 * 1024) {
            this.$alert("该文件超过1GB，请使用大文件回传", "提示", {
              confirmButtonText: "确定",
              callback: (action) => {
                this.dialogVisible = false;
                this.$emit("toLargeFile");
              },
            });
            return;
          }
          this.filesList[i].size = this.bytesToSize(files[i].size);
          // this.filesList[i].status = "0%";

          // this.bytesToSize(files[i].size);
          this.formDatas(files[i], i);
        }
      }
      this.dialogVisibleTwo = true;
    },
    /**
     * 回传文件
     */
    formDatas(data, index) {
      let file = data;
      let spark = new SparkMD5();
      let fileMd5 = file.name + file.size;
      spark.appendBinary(fileMd5);
      let md5 = spark.end();
      console.log(md5);
      let formData = {
        upload_file: true,
        file: file,
        md5: md5,
        size: file.size,
      };
      this.underway = this.underway + 1;
      this.showTableList = true;

      let header = {
        token: this.token,
      };
      let url = process.env.VUE_APP_API_HOST + "/api" + "/data/upload";
      let objkey = "dataReturn" + md5; //  需要监听的数据变化的对象key
      this.utils.$funcFileRequest(formData, url, header, 99999999, objkey);
      Object.defineProperty(window.progress, objkey, {
        get: () => {
          return data;
        },
        set: (newValue) => {
          if (newValue !== this.filesList[index].status) {
            let arrObj = this.filesList[index];
            arrObj.status = newValue;
            this.filesList.splice(index, 1, arrObj);
          }
          if (newValue === "已完成") {
            this.$emit("refresh");
            this.success = true;
            this.underway--;
            this.schedule++;
          } else if (newValue === "失败") {
            this.existence = true;
            this.underway--;
            this.schedule++;
          }
        },
      });
    },
  },
  computed: {
    ...mapState(["user", "token"]),
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-progress-bar__inner {
  background-color: #1bd0be;
}

/deep/ .el-dialog {
  width: 900px !important;
  height: 650px !important;
}

/deep/ .el-dialog__body {
  padding: 0;
}

.filestatus {
  width: 18%;
  text-align: center;
}

.titleBar {
  width: 100%;
  height: 100%;

  .title {
    color: #000000;
    font-size: 18px;
    margin-left: 40px;
  }
}

.dataTit {
  width: 90%;
  margin-left: 5%;
  height: 40px;
  background-color: #dde6ef;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .title {
    font-size: 16px;
    color: #000000;
    font-family: MicrosoftYaHei;
  }
}

.mainBar {
  width: 80%;
  margin-left: 10%;

  .fileline {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filename {
      width: 20%;

      .fileImg {
        margin-top: 9px;
        float: left;
      }

      .name {
        line-height: 40px;
        font-size: 14px;
        color: #000000;
        float: right;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 70%;
      }
    }

    .filesize {
      width: 20%;
      text-align: center;

      .size {
        font-size: 14px;
        color: #000000;
        line-height: 40px;
      }
    }
  }
}

.bodyBar {
  width: 800px;
  height: 480px;
  margin-left: 5%;

  .leftBar {
    float: left;
    width: 80px;

    .uptit {
      font-size: 14px;
      color: #838a9e;
    }
  }

  .rightBar {
    float: right;
    width: 710px;
    height: 100%;

    .uplay {
      width: 100%;
      height: 186px;
      border-radius: 2px;
      border: 1px dashed rgba(0, 0, 0, 0.15);
      background-color: #f6f7f9;
      display: flex;
      justify-content: center;
      align-items: center;

      .clickplay {
        width: 400px;
        height: 100px;

        .upImg {
          float: left;
          margin-left: 140px;
        }

        .describe {
          color: #838a9d;
          font-size: 14px;
          float: left;
        }

        .uploadInput {
          font-size: 0;
          width: 710px;
          height: 186px;
          opacity: 0;
          position: absolute;
          left: 135px;
          top: 99px;
          cursor: pointer;
        }
      }
    }

    .sider {
      width: 710px;
      height: 1px;
      border: 1px dashed #a6aab8;
      margin-top: 37px;
    }

    .tips {
      width: 710px;
      margin-top: 40px;

      .tit {
        float: left;

        .titWord {
          font-size: 14px;
          color: #3c4353;
        }

        .tipsWord {
          font-size: 14px;
          color: #838a9d;
          margin-left: 10px;
        }
      }
    }
  }
}

.footbtn {
  float: right;
  margin-right: 20px;

  .footBtn {
    width: 96px;
    height: 36px;
    margin-left: 10px;
  }
}
</style>
