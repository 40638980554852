/*
 * @Author: your name
 * @Date: 2020-04-28 10:02:24
 * @LastEditTime: 2020-06-04 11:01:55
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \gx_web\src\permission.js
 */
import router from './router'
import store from './store'
import {
  Message
} from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import {
  getToken
} from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({
  showSpinner: false
}) // NProgress Configuration

const whiteList = [
  '/login',               '/auth-redirect',        '/otherlogin',
  '/forgetPsw',           '/changeregpsw',         '/register',
  '/home',                '/flying',               '/newgeneric',
  '/taskPush',            '/teamterminal',         '/deviceManagement',
  '/released',            '/loginsuccess',         '/taskstat',
  '/userstat',            '/flystat',              '/datareturn',
  '/information',         '/setting',              '/publish',
  '/taskchat/index',      '/taskchat/add',         '/taskchat/taskselect',
  '/testHome',            '/achievement',          '/analysis',
  '/file',                '/alliance',             '/terminal',
  '/ranking',             '/permissionManagement', '/configuration',
  '/taskchat',            '/airspaceQuery',        '/airspaceApplied',
  '/approved',            '/pendingApprova',       '/airspaceCataloging',
  '/authorizedAirspace',  '/weather',              '/weatherForecast',
  '/publicPortalWeather', '/getawayTask',          '/taskDetails',
  '/result',              '/meteorological',       '/airsearch',
  '/authorization',       '/sentiment',            '/warning',
  '/application',         '/issue',                '/personal',
  '/settings',            '/Applied',              '/issued',
  '/Airspace',            '/datamanage',           '/warningmanage',
  '/details',             '/taskpage',             '/actionpage',
  '/shpage',              '/datadetails',          '/portal',
  '/auditpage',           '/Allmessage',           '/postback',
  '/taskCoordination',    '/taskList',             '/blockTaskPush',
  '/publicWeatherForecast','/fireReporting', '/taskManager'
]
 // no redirect whitelist
router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({
        path: '/'
      })
      NProgress.done()
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      if (hasRoles) {
        next()
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          const {
            roles
          } = await store.dispatch('user/getInfo')

          // generate accessible routes map based on roles
          const accessRoutes = await store.dispatch('permission/generateRoutes', roles)

          // dynamically add accessible routes
          router.addRoutes(accessRoutes)

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({
            ...to,
            replace: true
          })
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          Message.error(error || 'Has Error')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
