<template>
  <div>
    <el-dialog
      :modal="false"
      :visible.sync="dialogVisible"
      width="260px"
      :show-close="false"
    >
      <div class="userInfor">
        <div class="userAva">
          <avatar v-if="token" class="avatar" :avatar="user.photo_url" :width="51" :height="51" />
        </div>
        <div class="userIdentity">
          <span :title="user.name" class="usernames">{{ user.name }}</span>
          <i v-if="user.is_admin == 1" class="iconfont userIcons">&#xe632;</i>
          <i
            v-if="user.is_admin == 0 && user.is_unit == 1"
            class="iconfont userIconsone"
            >&#xe605;</i
          >
        </div>
        <!-- 超管 -->
        <div v-if="user.is_admin == 1" class="unitBtn">超级管理员</div>
        <!-- 巡护管理 -->
        <div v-if="user.is_admin == 0 && user.is_unit == 1" class="userBtn">
          巡护管理员
        </div>
        <!-- 终端用户 -->
        <div
          v-if="
            user.is_admin == 0 &&
              user.is_unit == 0 &&
              user.register_channel !== '门户网站用户'
          "
          class="zhongduan"
        >
          巡护终端用户
        </div>
        <div
          v-if="
            user.is_admin == 0 &&
              user.is_unit == 0 &&
              user.register_channel === '门户网站用户'
          "
          class="enduser"
        >
          门户网站用户
        </div>
      </div>
      <div class="dividing"></div>
      <div class="bodyBar">
        <div class="settings">
          <span class="title">个人设置</span>
          <p @click="personal(7)" class="menu">个人信息</p>
          <p @click="settings(7)" class="menu">账户设置</p>
        </div>
        <div class="dividing"></div>
        <div class="application">
          <span class="title">数据申请</span>
          <p @click="Applied(7)" class="menu">已申请数据</p>
        </div>
        <div class="dividing"></div>
        <div class="application">
          <span class="title">预警发布</span>
          <p @click="issued(7)" class="menu">已发布预警</p>
        </div>
        <div class="dividing"></div>
        <div class="application">
          <span class="title">空域查询</span>
          <p @click="Airspaceapplied(7)" class="menu">已申请空域</p>
        </div>
      </div>
      <div class="divsolid"></div>
      <div class="loginOut">
        <div class="signout" @click="backstage(8)" v-if="user.is_admin == 1">
          <i class="iconfont backstage">&#xe657;</i>
          <span class="backstageword">后台管理</span>
        </div>
        <span @click="loginOut" class="out">退出登录</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState,mapMutations } from "vuex";
import avatar from "@/components/avatar/avatar"
export default {
  components: {
    avatar
  },
  data () {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    ...mapMutations([
      "currentUrlStorage",
      "navMessageStorage",
      "userStorage",
      "tokenStorage",
      "loginStorage",
      "visibleStorage",
    ]),
    // 个人信息
    personal (index) {
      this.navMessageStorage(index);
      this.$router.push("/personal");
      this.currentUrlStorage(["首页","个人中心","个人信息"]);
      this.dialogVisible=false;
    },
    // 账户设置
    settings (index) {
      this.navMessageStorage(index);
      this.$router.push("/settings");
      this.currentUrlStorage(["首页","个人中心","账户设置"]);
      this.dialogVisible=false;
    },
    // 已申请数据
    Applied (index) {
      this.navMessageStorage(index);
      this.$router.push("/Applied");
      this.currentUrlStorage(["首页","个人中心","已申请数据"]);
      this.dialogVisible=false;
    },
    // 已发布预警
    issued (index) {
      this.navMessageStorage(index);
      this.$router.push("/issued");
      this.currentUrlStorage(["首页","个人中心","已发布预警"]);
      this.dialogVisible=false;
    },
    // 已申请空域
    Airspaceapplied (index) {
      this.navMessageStorage(index);
      this.$router.push("/Airspace");
      this.currentUrlStorage(["首页","个人中心","已申请空域"]);
      this.dialogVisible=false;
    },
    // 后台管理
    backstage (index) {
      this.navMessageStorage(index);
      this.$router.push("/datamanage");
      this.currentUrlStorage(["首页","后台管理","数据申请管理"]);
      this.dialogVisible=false;
    },
    open () {
      this.dialogVisible=true;
    },
    loginOut () {
      this.$confirm("您确定退出登录吗?","提示",{
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.commit("logOut");
          this.loginStorage(true);
          this.$router.push("/login");
          this.dialogVisible=false;
          this.visibleStorage(true);
          this.$message({
            type: "success",
            message: "退出成功!",
          });
        })
        .catch(() => { });
    },
    handleClose (done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
  },
  computed: {
    ...mapState(["navMessage","role","token","user","currentUrl"]),
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__header {
  padding: 0px !important;
}
/deep/.el-dialog__body {
  height: 570px !important;
  background-color: #04081f !important;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.1),
    0px 6px 16px 0px rgba(0, 0, 0, 0.13), 0px 3px 6px -4px rgba(0, 0, 0, 0.13) !important;
  border-radius: 4px;
  border: 1px solid rgba(11, 104, 254, 1) !important;
  padding: 15px 0px !important;
}
/deep/.el-dialog {
  margin-top: 70px !important;
  position: absolute !important;
  left: 70vw !important;
}
/deep/.el-button--medium {
  padding: 0px 0px !important;
}
.userInfor {
  width: 100%;
  height: 85px;
  display: flex;
  align-items: center;
}
.userAva {
  width: 60px;
  height: 60px;
  margin-left: 35px;
  float: left;
}
.AvaIdenti {
  width: 51px;
  height: 51px;
  border-radius: 30px;
}
.userIdentity {
  margin-bottom: 35px;
  display: flex;
}
.usernames {
  margin-left: 10px;
  color: #fff;
  width: 110px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: MicrosoftYaHei;
}
.userIcons {
  color: #1862ff;
  margin-left: 5px;
}
.unitBtn {
  color: #fff;
  position: absolute;
  left: 105px;
  top: 10%;
  width: 80px;
  height: 20px;
  border-radius: 3px;
  background-color: #1862ff;
  display: flex;
  justify-content: center;
  line-height: 20px;
  font-size: 12px;
}
.userBtn {
  color: #3c4353;
  position: absolute;
  left: 105px;
  top: 55px;
  width: 80px;
  height: 20px;
  border-radius: 3px;
  background-color: #f4ae23;
  display: flex;
  justify-content: center;
  line-height: 20px;
  font-size: 12px;
}
.zhongduan {
  color: #3c4353;
  position: absolute;
  left: 105px;
  top: 55px;
  width: 90px;
  height: 20px;
  border-radius: 3px;
  background-color: #59fa9c;
  display: flex;
  justify-content: center;
  line-height: 20px;
  font-size: 12px;
}
.enduser {
  color: #fff;
  position: absolute;
  left: 105px;
  top: 55px;
  width: 90px;
  height: 20px;
  border-radius: 3px;
  background-color: #3c4353;
  display: flex;
  justify-content: center;
  line-height: 20px;
  font-size: 12px;
}
.dividing {
  height: 1px;
  width: 100%;
  border: 1px dashed #828a9e;
  float: left;
}
.divsolid {
  height: 1px;
  width: 100%;
  border: 1px solid #828a9e;
  float: left;
}
.bodyBar {
  float: left;
  width: 100%;
  height: 355px;
  padding: 20px 0px 20px 20px;
}
.title {
  font-size: 14px;
  color: #6a778d;
}
.menu {
  font-size: 13px;
  color: #ffffff;
  margin-left: 25px;
  cursor: pointer;
}
.menu:hover {
  color: #0094ff;
}
.application {
  margin-top: 35px;
}
.loginOut {
  width: 100%;
  height: 100px;
  float: left;
  padding: 40px 20px 20px 20px;
}
.signout {
  float: left;
  line-height: 20px;
}
.out {
  float: right;
  line-height: 20px;
  color: #fb4439;
  font-size: 13px;
  cursor: pointer;
}
.backstage {
  color: #1853ff;
  cursor: pointer;
}
.backstageword {
  color: #1853ff;
  cursor: pointer;
  font-size: 13px;
}
</style>