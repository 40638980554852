<template>
  <div class="releaseTask" v-loading="isLoading">
    <div class="releaseBody" v-if="Object.keys(orderData).length > 0">
      <el-radio-group v-model="labelSelect" size="small" @change="changeTest">
        <el-radio-button label="releasePeople">发布人</el-radio-button>
        <el-radio-button label="taskDescription">任务描述</el-radio-button>
        <el-radio-button label="taskTest">任务面积</el-radio-button>
        <el-radio-button label="supplement">补充说明</el-radio-button>
      </el-radio-group>
      <div style="margin: 15px"></div>
      <div class="formBody">
        <table class="fixedVal" id="releasePeople">
          <tr>
            <td>账户</td>
            <td :title="orderData.created_user_mobile">
              {{ orderData.created_user_mobile }}
            </td>
          </tr>
          <tr>
            <td>账户类型</td>
            <td :title="orderData.created_user_role_name">
              {{ orderData.created_user_role_name }}
            </td>
          </tr>
          <tr>
            <td>单位</td>
            <td :title="orderData.created_user_unit_name">
              {{ orderData.created_user_unit_name }}
            </td>
          </tr>
          <tr>
            <td>账户名称</td>
            <td :title="orderData.created_user_name">
              {{ orderData.created_user_name }}
            </td>
          </tr>
          <tr>
            <td>发布方式</td>
            <td>
              {{ orderData.created_type }}
            </td>
          </tr>
        </table>
        <el-divider></el-divider>
        <el-form
          :model="detailDescription"
          :rules="rules"
          ref="ruleForm"
          label-width="98px"
          class="demo-ruleForm"
          label-position="left"
          size="small"
          :id="taskDescription"
        >
          <span v-if="isShowAllData">
            <el-form-item label="任务类型">
              {{ orderData.description.task_type }}
            </el-form-item>
            <el-form-item label="任务名称">
              {{ orderData.description.task_name }}
            </el-form-item>
            <el-form-item label="授权空域">
              <span>{{ orderData.description.auth_area }}</span>
              <!-- <span v-if="orderData.description.auth_area === '1'"> 是 </span>
              <span v-else-if="orderData.description.auth_area === '2'">
                否
              </span>
              <span v-else> 申请中 </span> -->
            </el-form-item>
            <el-form-item label="传感器">
              {{ orderData.description.sensor_type }}
            </el-form-item>
            <el-form-item
              label="影像GSD"
              v-show="orderData.description.sensor_type.indexOf('LIDAR') === -1"
            >
              {{ orderData.description.data_requirements }}
            </el-form-item>

            <el-form-item
              label="航向重叠度"
              v-show="orderData.description.sensor_type !== 'LIDAR'"
            >
              {{ orderData.description.longitudinal_overlap }}
            </el-form-item>
            <el-form-item
              label="点密度"
              v-show="orderData.description.sensor_type.indexOf('LIDAR') !== -1"
            >
              {{ orderData.description.pts }}
            </el-form-item>
            <el-form-item
              label="影像GSD"
              v-show="orderData.description.sensor_type === 'LIDAR+正射'"
            >
              {{ orderData.description.data_requirements }}
            </el-form-item>
            <el-form-item label="旁向重叠度">
              {{ orderData.description.lateral_overlap }}
            </el-form-item>
            <el-form-item
              label="航向重叠度"
              v-show="orderData.description.sensor_type === 'LIDAR+正射'"
            >
              {{ orderData.description.longitudinal_overlap }}
            </el-form-item>
            <el-form-item label="成果类型">
              <!-- {{orderData.description.result_type}} -->
              <div class="result_type">
                <div
                  class="result_type_item"
                  v-for="item in orderData.description.result_type"
                  :key="item"
                >
                  {{ item }}
                </div>
              </div>
            </el-form-item>
            <el-form-item label="成果精度">
              {{ orderData.description.result_precision }}
            </el-form-item>
            <el-form-item label="截止工期">
              {{ orderData.description.end_date }}
            </el-form-item>
          </span>
          <span v-else>
            <el-form-item label="任务类型">
              {{ orderData.description.task_type }}
            </el-form-item>
            <el-form-item label="任务描述">
              <div class="taskDescribe">
                {{ orderData.description.description }}
              </div>

            </el-form-item>
            <div v-show="pathQuery === 'published'" style="margin-left: 99px">
              <el-button type="text" @click="completeInformation"
                >去完善</el-button
              >
            </div>
            <el-divider style="margin-top: 14px"></el-divider>
          </span>
        </el-form>
        <div id="taskTest">
          <table class="fixedVal">
            <tr>
              <td>任务面积</td>
              <td>
                共<span class="measurementArea">{{
                  orderData.survey_area_count
                }}</span
                >个区块，累计面积<span
                  class="measurementArea"
                  :title="Number(orderData.survey_area_area).toFixed(1) + `Km²`"
                  >{{ Number(orderData.survey_area_area).toFixed(2) }}</span
                >Km²
              </td>
            </tr>
          </table>
          <span v-for="(item, index) in orderData.survey_area" :key="index">
            <div class="testArea">
              <div>任务{{ index + 1 }}</div>
              <div>
                <img
                  src="@/assets/img/homeDialog/taskTestArea.png"
                  alt=""
                  width="12px"
                />
                <span class="area"
                  >{{ Number(item.survey_area_area).toFixed(2) }}Km²</span
                >
              </div>
            </div>
            <div class="location">
              <img
                src="@/assets/img/centerHome/onlinetask.png"
                alt=""
                width="14px"
              />
              <span>{{ item.survey_street_name }}</span>
            </div>
          </span>
        </div>
        <div>
          <table class="fixedVal">
            <tr>
              <td>补充说明</td>
              <td :title="orderData.additional_remarks">{{ orderData.additional_remarks }}</td>
            </tr>
          </table>
          <table class="fixedVal" id="supplement">
            <tr>
              <td>附件</td>
              <td>
                <div class="files">
                  <div
                    class="file"
                    v-for="(item, index) in fileList"
                    :key="index"
                    @click="loadFile(item.url, item.source_name)"
                  >
                    {{ item.source_name }}
                  </div>
                </div>
              </td>
            </tr>
            <!-- <tr>
                <td></td>
                <td>
                  <el-tooltip
                    effect="light"
                    content="上传详细说明文件"
                    placement="right-start"
                  >
                    <el-upload
                      action="aaaa"
                      accept="All"
                      :file-list="fileList"
                      :on-change="FileUpload"
                      :auto-upload="false"
                      :on-remove="handleRemove"
                    >
                      <span class="uploadBtn" slot="trigger">
                        <img src="@/assets/img/upload.png" alt="" width="20" />
                        上传
                      </span>
                    </el-upload>
                  </el-tooltip>
                </td>
              </tr> -->
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import releaseForm from "./releaseForm.vue";
export default {
  props: {
    id: {
      type: Number,
    },
    releaseShow: {
      type: Boolean,
    },
    showAllData: {
      type: Boolean,
      default: true,
    },
    isBlockInformation: {
      type: Boolean,
      default: false,
    },
    pathQuery: {
      type: String,
      default: "",
    }
  },
  components: {
    releaseForm,
  },
  data() {
    return {
      dialogVisible: false,
      labelSelect: "releasePeople",
      publishType: "一键发布",
      userInfo: {}, //发布人信息，即当前登录用户信息
      unit_name: "", //单位
      selectShape: {}, //测区数据
      ruleForm: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      detailDescription: {
        task_type: "无人机",
        task_name: "",
        auth_area: "1",
        sensor_type: "正射",
        data_requirements: "8cm",
        longitudinal_overlap: "80%",
        lateral_overlap: "50%",
        result_type: [],
        result_precision: "1:500",
        end_date: "",
        pts: "",
      },
      rules: {
        delivery: [{ required: true, message: "" }],
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        isRequired: [{ required: true, message: " ", trigger: "blur" }],
        task_name: [{ required: true, message: " ", trigger: "blur" }],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        resource: [
          { required: true, message: "请选择活动资源", trigger: "change" },
        ],
        desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
      },
      fileList: [], //文件列表
      fileData: "", //文件
      orderData: {},
      completeInformationShow: false,
      isLoading: true,
      taskDescription: "detailed", //任务描述动态ID
      isShowAllData: true,
    };
  },
  beforeMount() {
    this.init();
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
  },
  methods: {
    init() {
      this.dialogVisible = this.releaseShow;
      this.getDetailData();
    },
    getDetailData() {
      let pramas = { id: this.id };
      if (this.isBlockInformation) {
        pramas = { project_id: this.id };
      }
      this.$apis.demandShowOrder(pramas, this.token).then((res) => {
        this.orderData = res.data.data.order;
        this.fileList = res.data.data.file;
        this.orderData.description = JSON.parse(this.orderData.description);
        this.orderData.survey_area = JSON.parse(this.orderData.survey_area);
        this.userInfo["mobile"] = this.orderData.created_user_mobile;
        this.userInfo["role_name"] = this.orderData.created_user_role_name;
        this.userInfo["unit_name"] = this.orderData.created_user_unit_name;
        this.userInfo["name"] = this.orderData.created_user_name;
        this.userInfo["id"] = this.id;
        this.isLoading = false;
        if (this.orderData.created_type === "详细发布" || this.showAllData) {
          this.taskDescription = "detailed";
        } else {
          this.taskDescription = "aKey";
        }
        if (this.orderData.created_type === "详细发布") {
          this.isShowAllData = true;
        } else {
          if (this.orderData.order_complete_user_mobile === "") {
            this.isShowAllData = false;
          } else {
            this.isShowAllData = true;
          }
        }
      });
    },
    jump(id) {
      console.log(id);
      document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
    handleClose() {
      this.dialogVisible = false;
      this.$emit("closeForm");
    },
    // 监听滑动
    onScroll() {
      let formBody = document.getElementsByClassName("formBody")[0];
      let scrolled = formBody.scrollTop;
      let windowHeight = formBody.clientHeight;
      let scrollHeight = formBody.scrollHeight;
      let releasePeople = document.getElementById("releasePeople").scrollHeight;
      let detailed = document.getElementById(this.taskDescription).clientHeight;
      if (scrolled === 0) {
        this.labelSelect = "releasePeople";
      }
      if (releasePeople <= scrolled && scrolled < detailed + releasePeople) {
        this.labelSelect = "taskDescription";
      }
      if (detailed + releasePeople <= scrolled) {
        this.labelSelect = "taskTest";
      }
      if (scrolled + windowHeight === scrollHeight) {
        this.labelSelect = "supplement";
      }
    },
    FileUpload(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; // 这一步，是 展示最后一次选择的文件
      }

      const formData = new FormData();
      formData.append("file", file.raw);
      this.fileData = formData;
      // console.log(this.fileData.get("file"),"FileUpload", file.raw);
    },
    changeTest(name) {
      // console.log(name,this.publishType);
      if (name === "taskDescription") {
        if (this.orderData.created_type === "详细发布" || this.showAllData) {
          name = "detailed";
        } else {
          name = "aKey";
        }
      }
      // console.log(name);
      this.jump(name);
    },
    handleRemove(file, fileList) {
      this.fileData = this.formData.delete("file");
    },
    completeInformation() {
      // this.completeInformationShow = true;
      this.$emit("showCompleteOrder", {
        order: this.orderData,
        userInfo: this.userInfo,
        fileList: this.fileList,
      });
    },
    // 下载文件
    loadFile(url, name) {
      console.log(url + "/source_name/" + name);
      window.open(url + "/source_name/" + name);
    },
    completeAkeyOrder() {
      console.log("完善好了");
    },
  },
  computed: {
    totalArea() {
      let area = 0;
      let selectShape = this.listDrawShapeData[0];
      for (let key in selectShape) {
        area = area + selectShape[key].area;
      }
      return area.toFixed(2);
    },
    ...mapState(["token"]),
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
};
</script>

<style lang="scss" scoped>
.releaseTask {
  /deep/.el-dialog__body {
    padding: 0;
  }
  /deep/.el-dialog__header {
    text-align: left;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
  /deep/.el-form-item__label {
    font-size: 13px;
    color: #666666;
    font-weight: 400;
    // height: 31px;
  }
  /deep/.el-form-item {
    margin-bottom: 5px;
    // height: 31px;
  }
  /deep/.el-divider--horizontal {
    margin-top: 4px;
    margin-bottom: 6px !important;
  }
  /deep/.el-input {
    width: 106%;
    height: 32px;
    line-height: 32px;
  }
  /deep/.el-radio {
    margin-right: 10px;
    color: #c0c4cc;
  }
  /deep/.el-radio__label {
    font-size: 13px;
    padding-left: 5px;
  }
  /deep/.el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  .result_type {
    margin-left: 31%;
    padding-top: 8px;
    display: flex;
    flex-wrap: wrap;
    .result_type_item {
      background: #f6f6f6;
      border-radius: 2px;
      width: 66px;
      text-align: center;
      // padding: 3px;
      margin-right: 5px;
      margin-bottom: 5px;
      height: 24px;
      line-height: 24px;
      padding: 0 2px;
      font-size: 13px;
    }
  }
  .releaseBody {
    width: 365px;
    height: 533px;
    color: #333;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 24px 0 0 27px;
    display: flex;
    flex-direction: column;
    ::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      // border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #c2c6cc;
    }

    ::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      -webkit-box-shadow: inset 0 0 5px #fff;
      border-radius: 10px;
      background: transparent;
    }
    * {
      scrollbar-color: #000 #6d6d83; /* 滑块颜色  滚动条背景颜色 */
      scrollbar-width: thin; /* 滚动条宽度有三种：thin、auto、none */
    }
    .formBody {
      overflow: auto;
      padding-right: 18px;
      .uploadBtn {
        width: 83px;
        height: 32px;
        border-radius: 2px;
        border: 1px solid #838a9d;
        font-size: 14px;
        color: #838a9d;
        display: flex;
        align-items: center;
        margin-left: 1.45vw;
        // position: absolute;
        // right: 29%;
        // bottom: -29px;
        img {
          margin: 0 8px;
        }
      }
      .taskDescribe {
        width: 222px;
        margin-left: 98px;
      }
      .fixedVal {
        margin-left: -4px;

        tr {
          line-height: 24px;

          > td:nth-child(1) {
            width: 98px;
            display: inline-block;
            height: 31px;
            // padding-left: 7px;
          }
          > td:nth-child(2) {
            color: #333333;
            display: inline-block;
            width: 205px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        td {
          text-align: left;
          font-size: 13px;
          font-family: MicrosoftYaHei;
          color: #666666;
        }
        .measurementArea {
          font-weight: 600;
          font-size: 21px;
        }
        .files {
          display: flex;
          flex-wrap: wrap;
        }
        .file {
          width: 85px;
          height: 32px;
          color: #1890ff;
          border-radius: 2px;
          border: 1px solid #1890ff;
          line-height: 32px;
          text-align: center;
          overflow: hidden; /*超出部分隐藏*/
          text-overflow: ellipsis; /* 超出部分显示省略号 */
          white-space: nowrap; /*规定段落中的文本不进行换行 */
          margin-right: 3px;
          margin-top: 5px;
          cursor: pointer;
          user-select: none;
        }
      }
      .testArea {
        display: flex;
        font-size: 13px;
        line-height: 30px;
        color: #666666;
        > div:nth-child(1) {
          width: 84px;
          margin-left: 10px;
        }
        .area {
          color: #edad00;
          font-weight: 500;
        }
      }
      .location {
        color: #1989fa;
        margin-left: 94px;
        line-height: 30px;
      }
      .otherVal {
        margin-left: 97px;
        width: 62.2%;
        margin-bottom: 3px;
      }
      /deep/.el-input-group__append {
        height: 32px;
        line-height: 28px;
        background-color: transparent;
        padding: 0;
      }
      .otherInput {
        /deep/.el-input__inner {
          // border-right: none;
        }
      }
      .arrowUpAndDown {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-right: -4px;
        cursor: pointer;
        height: 100%;
        border-left: 1px solid #d9d9d9;
        .el-icon-arrow-down {
          border-top: 1px solid #d9d9d9;
          width: 21px;
        }
      }
    }
  }
}
</style>
