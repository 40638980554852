var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allianceNav" },
    [
      _c("navTop"),
      _vm._v(" "),
      _c("div", { staticClass: "allianceBody" }, [
        _c(
          "div",
          { staticClass: "navBody" },
          [
            _c("div", { staticClass: "topNav" }, [
              _c(
                "div",
                { staticClass: "leftBtn" },
                [
                  _vm.showOne
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.newUser },
                        },
                        [_vm._v("新建用户")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.showOne
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.addgroupUser },
                        },
                        [_vm._v("添加任务群组成员")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "rightInp" },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "alliInput",
                      attrs: { placeholder: "请输入账号/账户名称进行搜索" },
                      on: {
                        input: function ($event) {
                          return _vm.fucusAll(_vm.keywordAll)
                        },
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.search($event)
                        },
                      },
                      model: {
                        value: _vm.keywordAll,
                        callback: function ($$v) {
                          _vm.keywordAll = $$v
                        },
                        expression: "keywordAll",
                      },
                    },
                    [
                      _c(
                        "i",
                        {
                          staticClass: "iconfont searchAll",
                          attrs: { slot: "suffix" },
                          on: { click: _vm.search },
                          slot: "suffix",
                        },
                        [_vm._v("")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("el-divider", { staticClass: "sider" }),
            _vm._v(" "),
            _c("div", { staticClass: "midlleNav" }, [
              _vm.showOne
                ? _c("div", { staticClass: "tables" }, [
                    _c("span", { staticClass: "feimaTit" }, [
                      _vm._v("巡护单位："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "titname" }, [
                      _vm._v(_vm._s(_vm.unitname)),
                    ]),
                    _vm._v("          \n          "),
                    _c("span", { staticClass: "feimaTit" }, [
                      _vm._v("巡护代码："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "titname" }, [
                      _vm._v(_vm._s(_vm.unitcode)),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.showOne
                ? _c("div", { staticClass: "tables" }, [
                    _c("span", { staticClass: "feimaTit" }, [
                      _vm._v("任务群组："),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "titname" }, [
                      _vm._v(_vm._s(_vm.groupname)),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "tabList" }, [
                _c(
                  "div",
                  { staticClass: "unitNav" },
                  [
                    _vm.showOne
                      ? _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              height: "100%",
                              data: _vm.userterminalList,
                              stripe: true,
                              "header-cell-style": { background: "#DDE6EF" },
                            },
                          },
                          [
                            _c("el-table-column", {
                              staticClass: "number",
                              attrs: {
                                label: "账号",
                                width: "180",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.is_admin == 1
                                          ? _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "iconfont icon-VIP",
                                              },
                                              [_vm._v("")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        scope.row.is_admin == 0 &&
                                        scope.row.is_unit == 1
                                          ? _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "iconfont icon-pre",
                                              },
                                              [_vm._v("")]
                                            )
                                          : scope.row.is_admin !== 1 &&
                                            scope.row.is_unit !== 1
                                          ? _c("i", {
                                              staticClass:
                                                "iconfont icon-member",
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("img", {
                                          staticClass: "userhead",
                                          attrs: {
                                            src: require("../../assets/img/user-default-head-small.png"),
                                            alt: "",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(scope.row.mobile) +
                                            "\n                "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3395370769
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "账户名称",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "账户权限", align: "center" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "right",
                                              width: "140",
                                              trigger: "click",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "jurisdiction-list",
                                              },
                                              _vm._l(
                                                _vm.gridData,
                                                function (item, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: index,
                                                      class: {
                                                        "jurisdiction-list-div":
                                                          _vm.jurisdictionIndex ===
                                                          item.id,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.jurisdictionClick(
                                                            scope.row,
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(item.name) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { slot: "reference" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.rolechildList(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                                slot: "reference",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(scope.row.role_name)
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2965680409
                              ),
                            }),
                            _vm._v(" "),
                            _vm.showOne
                              ? _c("el-table-column", {
                                  attrs: {
                                    label: "是否为超管",
                                    width: "100",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return _vm.showOne
                                            ? [
                                                scope.row.is_admin == 1
                                                  ? _c(
                                                      "el-link",
                                                      {
                                                        attrs: {
                                                          title: "点击更改",
                                                          underline: false,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.setUser(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("是")]
                                                    )
                                                  : _c(
                                                      "el-link",
                                                      {
                                                        attrs: {
                                                          title: "点击更改",
                                                          underline: false,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.setUser(
                                                              scope.row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("否")]
                                                    ),
                                              ]
                                            : undefined
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "register_channel",
                                label: "账户类型",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "created_at_format",
                                label: "注册时间",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _vm.showOne
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "holder_dept",
                                    width: "70",
                                    label: "操作",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "feimabtn",
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.delUnitUser(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3118149841
                                  ),
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.showOne
                      ? _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              stripe: true,
                              height: "100%",
                              "header-cell-style": { background: "#DDE6EF" },
                              data: _vm.groupUserList,
                            },
                          },
                          [
                            _c("el-table-column", {
                              staticClass: "number",
                              attrs: {
                                label: "账号",
                                width: "180",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.user_id == _vm.dataid
                                          ? _c(
                                              "i",
                                              {
                                                staticClass:
                                                  "iconfont icon-groupUser",
                                              },
                                              [_vm._v("")]
                                            )
                                          : scope.row.user_id !== _vm.dataid
                                          ? _c("i", {
                                              staticClass:
                                                "iconfont icon-member",
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("img", {
                                          staticClass: "userhead",
                                          attrs: {
                                            src: require("../../assets/img/user-default-head-small.png"),
                                            alt: "",
                                          },
                                        }),
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(scope.row.mobile) +
                                            "\n                "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2278788584
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "name",
                                label: "账户名称",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "成员类型", align: "center" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.user_id == _vm.dataid
                                              ? "管理员"
                                              : "成员"
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2426038833
                              ),
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: {
                                prop: "created_at",
                                label: "注册时间",
                                align: "center",
                              },
                            }),
                            _vm._v(" "),
                            !_vm.showOne
                              ? _c("el-table-column", {
                                  attrs: {
                                    prop: "holder_dept",
                                    label: "操作",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return !_vm.showOne
                                            ? [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "editBtn",
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.details(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("详情")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "feimabtn",
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delUnit(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                ),
                                              ]
                                            : undefined
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showOne
                      ? _c(
                          "div",
                          { staticClass: "block" },
                          [
                            _c("el-pagination", {
                              attrs: {
                                "current-page": _vm.current_page,
                                "page-size": this.per_page,
                                layout: "total,  prev, pager, next, jumper",
                                total: this.total,
                              },
                              on: {
                                "current-change": _vm.currentchange,
                                "next-click": _vm.handleNext,
                                "prev-click": _vm.handlePrev,
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "footNav" }, [
                              _vm.showOne
                                ? _c(
                                    "p",
                                    { staticClass: "numalliance" },
                                    [
                                      _vm._v(
                                        "\n                  巡护终端用户：共" +
                                          _vm._s(_vm.total) +
                                          "个\n                  "
                                      ),
                                      _vm.role !== 2
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "exportFileStyle",
                                              attrs: {
                                                type: "primary",
                                                plain: "",
                                                size: "mini",
                                              },
                                              on: { click: _vm.exportFile },
                                            },
                                            [_vm._v("导出")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.showOne
                                ? _c("p", { staticClass: "numalliance" }, [
                                    _vm._v(
                                      "\n                  任务群组成员：共" +
                                        _vm._s(this.groupUserList.length) +
                                        "个\n                "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "operationBtn" },
          [
            _c(
              "div",
              { staticClass: "searchInp" },
              [
                _c(
                  "el-input",
                  {
                    staticClass: "seainput",
                    attrs: { type: "text", placeholder: "请输入巡护单位搜索" },
                    on: {
                      input: function ($event) {
                        return _vm.fucus(_vm.keyword)
                      },
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.unitsList($event)
                      },
                    },
                    model: {
                      value: _vm.keyword,
                      callback: function ($$v) {
                        _vm.keyword = $$v
                      },
                      expression: "keyword",
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "iconfont search",
                        attrs: { slot: "suffix" },
                        on: { click: _vm.unitsList },
                        slot: "suffix",
                      },
                      [_vm._v("")]
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-divider", { staticClass: "sider" }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "infinite-scroll",
                    rawName: "v-infinite-scroll",
                    value: _vm.load,
                    expression: "load",
                  },
                ],
                staticClass: "unitlist",
                attrs: { "infinite-scroll-disabled": "disabled" },
              },
              [
                _c(
                  "el-collapse",
                  { attrs: { accordion: "" } },
                  _vm._l(_vm.unitData, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "hhhh",
                        on: {
                          click: function ($event) {
                            return _vm.getItem(item)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "baoBar",
                            on: {
                              click: function ($event) {
                                return _vm.titBtn(item)
                              },
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              [
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "collapse-title",
                                      attrs: { slot: "title" },
                                      slot: "title",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "unitName",
                                          attrs: { title: item.unit_name },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(item.unit_name) +
                                              "\n                    "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "i",
                                        {
                                          staticClass: "iconfont more",
                                          on: {
                                            click: function ($event) {
                                              return _vm.iconBtn(item)
                                            },
                                          },
                                        },
                                        [_vm._v("")]
                                      ),
                                    ]
                                  ),
                                ],
                                _vm._v(" "),
                                _vm._l(_vm.groupData, function (item, cindex) {
                                  return _c(
                                    "div",
                                    { key: cindex, staticClass: "Photography" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "photos",
                                          style: {
                                            background:
                                              _vm.groupId == item.id
                                                ? "#dde2ee"
                                                : "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.addgroupUserBtn(item),
                                                (_vm.showOne = false)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "photoname" },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "i",
                                            {
                                              staticClass: "iconfont more",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.SonBtn(item)
                                                },
                                              },
                                            },
                                            [_vm._v("")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.loading
                  ? _c(
                      "p",
                      {
                        staticClass: "loading",
                        staticStyle: { "margin-top": "10px" },
                      },
                      [_c("i", { staticClass: "el-icon-loading" })]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "footBar" }, [
              _c("p", [
                _vm._v(
                  "巡护单位：共" + _vm._s(_vm.userRelated.unit_count) + "个"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "用户总数：共" + _vm._s(_vm.userRelated.user_count) + "个"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "任务群组：共" + _vm._s(_vm.userRelated.group_count) + "个"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "新建任务群组",
                  visible: _vm.Fdialog,
                  width: "20%",
                  "close-on-click-modal": false,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.Fdialog = $event
                  },
                },
              },
              [
                _c("span", [_vm._v("新建一个任务群组")]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.Fdialog = false
                          },
                        },
                      },
                      [_vm._v("取 消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.newTask },
                      },
                      [_vm._v("新 建")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "更多操作",
                  visible: _vm.Sdialog,
                  width: "25%",
                  "close-on-click-modal": false,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.Sdialog = $event
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c("el-button", { on: { click: _vm.delGrounp } }, [
                      _vm._v("删除任务群组"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.editGrounp },
                      },
                      [_vm._v("编辑任务群组")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("AddUser", {
        ref: "adduser",
        attrs: { unitID: _vm.unitID },
        on: { refresh: _vm.refresh },
      }),
      _vm._v(" "),
      _c("NewTask", { ref: "newtask", attrs: { grounpId: _vm.grounpId } }),
      _vm._v(" "),
      _c("delGroupUser", {
        ref: "deluser",
        attrs: { groupUser: _vm.groupUser },
        on: { refresh: _vm.refGroupUserList },
      }),
      _vm._v(" "),
      _c("EditGroup", {
        ref: "editgroup",
        attrs: { grounpInfo: _vm.grounpInfo, groupObj: _vm.groupObj },
      }),
      _vm._v(" "),
      _c("AddMember", {
        ref: "paltform",
        attrs: { grounpObj: _vm.grounpObj },
        on: { handAddReg: _vm.getChooseList, refresh: _vm.refGroupUserList },
      }),
      _vm._v(" "),
      _c("Detail", {
        ref: "detail",
        attrs: { detailObj: _vm.detailObj, detailList: _vm.detailList },
      }),
      _vm._v(" "),
      _c("Choose", {
        ref: "reference",
        attrs: { userform: _vm.userform },
        on: { refresh: _vm.refresh },
      }),
      _vm._v(" "),
      _c("delUnitUser", {
        ref: "delunituser",
        attrs: { delStr: "alliance", unitUser: _vm.unitUser },
        on: { refresh: _vm.refresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }