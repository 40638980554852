<template>
  <div class="dialogSingleMonitor">
    <el-dialog
      v-if="singleDialogVisible"
      v-dialogDrag
      :modal="false"
      :visible.sync="singleDialogVisible"
      :show-close="true"
      :append-to-body="true"
      custom-class="singleM"
      width="88%"
      @close="closeMonitor"
    >
      <div slot="title" class="dialog_title">
        <img
          class="icon"
          :src="terminal.device_data.device_brands_icon"
          alt=""
          width="56"
        >
        <div class="area">
          <img src="@/assets/img/homeDialog/area.png" alt="">
          <span class="area_data">{{ flyArea }}</span>
          <span style="font-size: 13px">已飞面积（k㎡）</span>
        </div>
        <div class="location">
          <img src="@/assets/img/homeDialog/location.png" alt="">
          <span
            v-show="terminal.device_data.last_point.province !== undefined"
            style="font-size: 13px"
          >{{
            terminal.device_data.last_point.province +
              "-" +
              terminal.device_data.last_point.city +
              "-" +
              terminal.device_data.last_point.area
          }}</span>
        </div>
        <div
          v-show="terminal.monitor_type !== 'common'"
          class="feimaPlaneDatas"
        >
          <div class="feimaPlaneData">
            <img
              src="@/assets/img/homeDialog/mobile_asset/gps_normal.png"
              alt=""
            >
            <img :src="feimaDroneInfo.gps_signal_icon" alt="">
            <div class="feimaPlaneSingal">
              {{ feimaDroneInfo.gps_signal_sup }}
            </div>
          </div>
          <div class="feimaPlaneData">
            <img
              src="@/assets/img/homeDialog/mobile_asset/hd_normal.png"
              alt=""
            >
            <img :src="feimaDroneInfo.hd_signal_icon" alt="">
            <div class="feimaPlaneHd">{{ feimaDroneInfo.hd_signal_sup }}</div>
          </div>
          <div class="feimaPlaneData">
            <img
              src="@/assets/img/homeDialog/mobile_asset/drone_battery_disconnected.png"
              alt=""
            >
            <span v-if="feimaDroneInfo.drone_battery_percent_0 && feimaDroneInfo.drone_battery_percent_0 !== 'undefined%'">{{
              feimaDroneInfo.drone_battery_percent_0
            }}</span>
            <!-- <span v-show="feimaDroneInfo.drone_battery_percent_2 !== undefined" >{{feimaDroneInfo.drone_battery_percent_2}}</span>
            <span v-show="feimaDroneInfo.drone_battery_percent_3 !== undefined">{{feimaDroneInfo.drone_battery_percent_3}}</span> -->
          </div>
        </div>
        <img
          v-show="terminal.monitor_type === 'common'"
          src="@/assets/img/homeDialog/plane_information.png"
          alt=""
          class="plane_information"
          @click="lookPlaneData"
        >
        <div />
      </div>
      <!-- <span v-if="terminal.planeType === 'common'"> -->
      <singleMonitor
        ref="singleMonitor"
        :plane-type="terminal.monitor_type"
        :software_code="terminal.device_data.software_code"
        :order_id="terminal.droneInfo.mission_id"
        :is-image-map="isImageMap"
        :list-device-data="listDeviceData"
        :sn-id="terminal.droneInfo.software_code"
        :brand-name="terminal.droneInfo.brand_name"
        :source="source"
        @setFlyArea="setFlyArea"
        @setFeimaDroneInfo="setFeimaDroneInfo"
      />
      <div v-if="isShowData" class="plane_data">
        <div style="margin-bottom: 10px">
          <ul>
            <li class="snIdTitle">
              <img src="@/assets/img/homeDialog/snId.png" alt="">
              <div class="text">SN码</div>
            </li>
            <li class="snIdData">{{ terminal.droneInfo.software_code }}</li>
          </ul>
        </div>
        <deviceData :terminal="listDeviceData" :set-style="setStyle" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import singleMonitor from "@/components/monitorPlane/singleMonitor.vue"
import deviceData from "@/components/monitorPlane/deviceData.vue"

export default {
  name: "DialogSingleMonitor",
  components: {
    singleMonitor,
    deviceData
  },
  props: {
    terminal: {
      type: Object
    },
    listDeviceData: {
      type: Object
    },

    source: {
      type: String,
      default: "one"
    },
    nowShowLayer: {
      type: String,
      default: "online"
    }
  },
  data() {
    return {
      feimaDroneInfo: {
        gps_signal_sup: 0,
        hd_signal_sup: 0
      },
      flyArea: 0,
      isShowData: false,
      singleDialogVisible: true,
      setStyle: true,
      isImageMap: true
    }
  },
  mounted() {
    this.toDetail()
  },
  methods: {
    // 监控页面查看信息
    lookPlaneData() {
      if (this.terminal.planeType === "common") {
        this.$refs.singleMonitor.openMoreData()
      } else {
        this.isShowData = !this.isShowData
      }
    },
    // 设置飞行面积
    setFlyArea(data) {
      this.flyArea = data.toFixed(1)
    },
    // 设置飞马无人机硬件信息
    setFeimaDroneInfo(data) {
      this.feimaDroneInfo = data
    },
    closeMonitor() {
      this.$emit("closeDialogSingleMonitor")
      if (this.terminal.planeType === "common" && !this.terminal.isHistory) { this.$refs.singleMonitor.closeWebsocket() }

      if (this.terminal.planeType !== "common") {
        this.$refs.singleMonitor.closeFeimaWebsocket()
      }
    },
    // 单屏监控
    toDetail() {
      if (this.terminal.monitor_type === "common") {
        let isBack = true
        if (this.nowShowLayer === "online") {
          isBack = false
        }
        setTimeout(() => {
          this.$refs.singleMonitor.linkWebsocket(
            isBack,
            this.terminal.nowloaction
          )
        }, 500)
      } else {
        setTimeout(() => {
          this.$refs.singleMonitor.openFeimaDetail(
            this.terminal.droneInfo.mission_id
          )
        }, 500)
      }
      // setTimeout(() => {
      //   this.$refs.singleMonitor.openFeimaPlay(
      //     this.terminal.droneInfo.mission_id
      //   );
      // }, 500);
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  background: #061220 !important;
  height: 71.3%;
  margin-left: 15vh !important;
}
/deep/ .el-dialog__header {
  padding: 0;
  height: 48px;
}
/deep/ .el-dialog__body {
  padding: 0;
  height: 100%;
  position: relative;
}
/deep/ .el-dialog__headerbtn {
  margin-top: -4px;
}
.dialogSingleMonitor {
  border: 1px solid red;
}
.plane_data {
    width: 320px;
    height: 164px;
    background: #001125;
    border-radius: 2px;
    border: 2px solid rgba(10, 110, 247, 0.4);
    position: absolute;
    top: 10px;
    right: 75px;
    padding: 30px 30px 0 33px;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
    }
    .snIdTitle {
      display: flex;
      align-items: center;
    }
    .snIdData {
      // line-height: 24px;
      color: #007bf7;
      margin-left: 4px;
    }
    .setLineHeight {
      line-height: 27px;
    }
    .status {
      display: flex;
      justify-content: space-between;
    }
    .title {
      color: #69789e;
      margin-right: 12px;
    }
    .data {
      color: #007bf7;
    }
    .phone {
      color: #ffba00;
      text-decoration: underline;
      margin-left: 11px;
    }
  }
.dialog_title {
  width: 100%;
  height: 100%;
  display: flex;
  color: #fff;
  align-items: center;
  position: relative;
  .area {
    margin-left: 24px;
    margin-right: 25px;
    img {
      position: relative;
      top: 5px;
    }
    .area_data {
      font-family: "DIN";
      font-weight: bold;
      color: #ffba00;
      margin: 0 8px;
      // width: 30px;
      display: inline-block;
    }
  }
  .location {
    img {
      position: relative;
      top: 3px;
      margin-right: 5px;
    }
  }
  .plane_information {
    position: absolute;
    right: 75px;
    cursor: pointer;
  }
  .feimaPlaneDatas {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 42px;
    top: 17px;
    color: #848c96;
    .feimaPlaneData {
      position: relative;
      margin-right: 30px;
      img {
        height: 18px;
        margin-right: 3px;
      }
      .feimaPlaneSingal {
        position: absolute;
        top: -11px;
        left: 18px;
        transform: scale(0.6);
      }
      .feimaPlaneHd {
        position: absolute;
        top: -11px;
        left: 27px;
        transform: scale(0.6);
      }
    }
  }
}
.icon {
  margin-left: 20px;
}
.dia_body {
  background: #001125;
  font-size: 14px;
  border-radius: 2px;
  // width: 316px;

  .detail {
    width: 1736px;
    height: 856px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .setSnIDPadding {
    padding-left: 30px;
    padding-right: 19px;
  }
  .setLocationPadding {
    padding-left: 30px;
    padding-right: 31px;
  }
  .setLngLatPadding {
    padding-left: 30px;
    padding-right: 28px;
    .toRegisterStyle {
      color: #ffba00;
      text-decoration: underline;
      cursor: pointer;
      user-select: none;
    }
  }
  .sn_id {
    // padding: 10px;
    // width: 57px;
    background: #000d1c;
    display: flex;
    align-items: center;
    font-size: 13px;
    // justify-content: space-around;
    height: 82px;
    .snIdTitle {
      display: flex;
      align-items: center;
    }
    .snIdData {
      // line-height: 24px;
      color: #007bf7;
      margin-left: 4px;
    }
    .play {
      // margin-left: 24px;
      margin-top: 9px;
    }
  }
  .location {
    display: flex;
    justify-content: space-between;
    height: 36px;
    line-height: 36px;
    color: #ffffff;
    .setLocation {
      color: #ffba00;
      text-decoration: underline;
      margin-left: 14px;
      cursor: pointer;
    }
    .cityAndarea {
      overflow: hidden; /*超出部分隐藏*/
      text-overflow: ellipsis; /* 超出部分显示省略号 */
      white-space: nowrap; /*规定段落中的文本不进行换行 */
      display: inline-block;
      width: 120px;
    }
  }
  .lnglat {
    height: 42px;
    background: #000d1c;
    font-size: 14px;
    color: #69789e;
    line-height: 42px;
    // padding-right: 20px;
    display: flex;
    justify-content: space-between;
    .setImg {
      display: flex;
      align-items: center;
    }
    .lnglatData {
      margin-left: 5px;
    }
  }
  .information {
    background: #000d1c;
    height: 117px;
    padding-top: 13px;
    .setLineHeight {
      line-height: 27px;
    }
    .status {
      display: flex;
      justify-content: space-between;
    }
    .title {
      color: #69789e;
      margin-right: 12px;
    }
    .data {
      color: #007bf7;
    }
    .phone {
      color: #ffba00;
      text-decoration: underline;
      margin-left: 11px;
    }
  }
  // .params {
  // display: flex;
  // font-size: 14px;
  tr {
    line-height: 30px;
  }

  // }
}
</style>
