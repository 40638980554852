<template>
  <el-dialog
    title="舆情发布"
    :visible.sync="dialogVisible"
    width="720px"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <el-divider />
    <div class="bodyBar">
      <el-form ref="ruleForm" class="formbox" :model="ruleForm">
        <el-form-item
          class="userForm"
          prop="project_id"
          label="灾害应急任务名称"
        >
          <el-select
            v-model="ruleForm.project_id"
            filterable
            class="fmSelect"
            :disabled="true"
            placeholder="请选择"
            @change="getTaskId(ruleForm.project_id)"
          >
            <el-option
              v-for="item in taskList"
              :key="item.id"
              :label="item.project_name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          class="userForm"
          prop="software_code"
          label="灾害应急任务包络"
        >
          <el-select
            v-model="value1"
            multiple
            filterable
            class="fmSelect"
            placeholder="请选择"
            @change="envelope"
          >
            <el-option-group
              v-for="group in subtaskList"
              :key="group.id"
              :label="group.block_name"
            >
              <el-option
                v-for="item in group.task"
                :key="item.id"
                :label="item.task_name"
                :value="item.id"
              />
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item
          class="userForm"
          prop="software_code"
          label="任务下达类型"
        >
          <el-radio-group
            v-model="radio"
            :disabled="true"
            class="radioBar taskchoose"
          >
            <el-radio :label="1">航线任务下达</el-radio>
            <el-radio :label="2">通用任务下达</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="summary">
          <span class="summarytit">灾害应急任务汇总</span>
          <div class="summaryBar">
            <span class="count">包含总任务数：{{ project_task_count }}个</span>
            <span class="count"
              >已接单任务数：{{ project_task_confirm_count }}个</span
            >
            <span class="count"
              >待接单任务数：{{ project_task_unconfirmed_count }}个</span
            >
            <span class="count"
              >进行中任务数：{{ project_task_progress }}个</span
            >
            <span class="count"
              >已完成任务数：{{ project_task_finished }}个</span
            >
          </div>
        </div>
        <div class="scene">
          <span class="scenetit">灾害应急任务现场</span>
          <div class="sceneBar" />
        </div>
        <el-form-item
          class="userForm"
          prop="software_code"
          label="是否发起现场连线"
        >
          <el-radio-group
            v-model="radio1"
            class="radioBar yesorno"
            @change="getLabelId"
          >
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="userForm" prop="disaster_level" label="灾情态势">
          <el-radio-group
            v-model="radio2"
            class="radioBar degree"
            @change="getsituation"
          >
            <el-radio label="及其严重">及其严重</el-radio>
            <el-radio label="严重">严重</el-radio>
            <el-radio label="中等">中等</el-radio>
            <el-radio label="一般">一般</el-radio>
            <el-radio label="轻微">轻微</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          class="userForm"
          prop="disaster_description"
          label="灾情评述"
        >
          <el-input
            v-model="ruleForm.disaster_description"
            type="textarea"
            maxlength="50"
            :rows="2"
            :show-word-limit="true"
            placeholder="请输入内容"
            resize="none"
          />
        </el-form-item>
        <el-form-item label>
          <div class="snapshot">
            <span class="snapshotit">灾情测区快照</span>
            <div class="snapshotbar">
              <input
                type="file"
                class="imgupload"
                style="z-index: 999"
                accept="image/png, image/jpeg, image/gif, image/jpg"
                @change="uploadImg"
              />
              <div class="snapshotBar">
                <img
                  class="uploadImg"
                  src="../assets/img/Upload／pic .png"
                  alt
                />
                <p class="describe">点击或将文件拖拽到这里上传</p>
              </div>

              <div
                v-if="this.fileUrl !== ''"
                style="margin: 0; position: absolute; top: 13%; left: 21.5%"
                class="imgNav snapshotBar"
              >
                <img class="imgtit" :src="this.fileUrl" alt />
              </div>
              <div
                v-if="this.fileUrl === ''"
                style="margin: 0; position: absolute; top: 13%; left: 21.5%"
                class="imgNav snapshotBar"
              >
                <img
                  class="imgtit"
                  :src="disasterDetailObj.area_snapshoot"
                  alt
                />
              </div>
            </div>
          </div>
          <div class="hovers">
            <i class="iconfont sign sings">&#xe6da;</i>
            <el-popover
              v-model="visible"
              placement="right"
              width="200"
              trigger="manual"
              class="hoverBar"
            >
              <i class="iconfont yellow">&#xe6da;</i>
              <p class="sign">请使用截图工具截图测区范围并上传至此。</p>
            </el-popover>
          </div>
        </el-form-item>
        <el-form-item label>
          <div class="henduoren">
            <span class="henduorenWord">灾情现场快照</span>
            <div class="imgBar">
              <div class="snapshotbar">
                <input
                  type="file"
                  class="imgupload"
                  accept="image/png, image/jpeg, image/gif, image/jpg"
                  @change="aetherupload"
                />
                <div class="snapshotBar">
                  <img
                    class="uploadImg"
                    src="../assets/img/Upload／pic .png"
                    alt
                  />
                  <p class="describe">点击或将文件拖拽到这里上传</p>
                </div>
              </div>
            </div>
          </div>
          <div class="hoverOne">
            <i class="iconfont sign sings">&#xe6da;</i>
            <el-popover
              v-model="visible"
              placement="right"
              width="200"
              trigger="manual"
              class="hoverBar"
            >
              <i class="iconfont yellow">&#xe6da;</i>
              <p class="sign">请上传林业巡护回传的照片，最多上传九张。</p>
            </el-popover>
          </div>
          <div v-if="fileList.length !== 0" class="imgNav">
            <img
              v-for="(item, index) in fileList"
              :key="index"
              class="imgtit"
              :src="fileList[index]"
              alt
            />
          </div>
          <div v-if="fileList.length === 0" class="imgNav">
            <img
              v-for="(item, index) in editFileList"
              v-if="editFileList[index] !== ''"
              :key="index"
              class="imgtit"
              :src="editFileList[index]"
              alt
            />
          </div>
        </el-form-item>
      </el-form>
    </div>
    <el-divider class="divd" />
    <label
        :title="!obj.is_portal ? '该任务暂未发布至门户网站，请先发布任务至门户':''" class="select">
      <input
        id="Google"
        v-model="checkednameUni"
        type="checkbox"
        class="historyInp"
        value
        @change="historyFlyUni"
        :disabled="!obj.is_portal"
      />
      <span :style="{opacity: !obj.is_portal? '0.5':'1'}" class="flystatus">舆情发布同步发布至【公众门户网站】</span>
    </label>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="postback">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
let is_portal = "";
let file = [];
let files = {};
const formData = new FormData();
import { mapState, mapMutations } from "vuex";
export default {
  props: {
    obj: {
      type: Object,
      data: {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: { project_id: "", disaster_description: "" },
      value: "",
      value1: "",
      radio: "",
      radio1: "",
      radio2: "",
      textarea: "",
      visible: true,
      dialogImageUrl: "",
      fileList: [],
      taskList: [],
      project_task_count: "",
      project_task_confirm_count: "",
      project_task_unconfirmed_count: "",
      project_task_progress: "",
      project_task_finished: "",
      subtaskList: [],
      fileUrl: "",
      taskID: "",
      situation: "",
      labelId: "",
      taskType: "",
      ifUpload: false, //  是否可以点击上传按钮
      checkednameUni: "",
      disasterDetailObj: {}, //  舆情上传的数据
      editFileList: [],
    };
  },
  mounted() {
    if (this.obj.id) {
      // this.ruleForm.project_id = this.obj.project_name;
      // this.getTaskId(this.obj.id);
      //   this.$forceUpdate()
    }
  },
  methods: {
    /**
     * 舆情详情
     */
    getDisasterDetail(id) {
      this.$apis
        .getDisasterDetail(
          {
            id: Number(id),
          },
          this.token
        )
        .then((data) => {
          this.disasterDetailObj = data.data.data;
          this.ruleForm.project_id = this.disasterDetailObj.project_name;
          this.radio = this.obj.radio;
          this.radio1 = this.disasterDetailObj.spot_connection;
          this.radio2 = this.disasterDetailObj.disaster_level;
          this.ruleForm.disaster_description = this.disasterDetailObj.disaster_description;
          this.checkednameUni = this.obj.disaster.is_portal;
          this.taskType = this.disasterDetailObj.project_type;
          this.labelId = this.disasterDetailObj.spot_connection;
          this.value1 = JSON.parse(this.disasterDetailObj.relation_task_id);
          this.envelopeList = this.disasterDetailObj.relation_task_id;
          is_portal = this.checkednameUni;
          for (let i = 1; i <= 9; i++) {
            this.editFileList[i - 1] = this.disasterDetailObj["snapshoot_" + i];
          }
          this.getTaskId(this.disasterDetailObj.project_id);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    open() {
      this.dialogVisible = true;
      this.getDisasterDetail(this.obj.disaster.id);
    },
    getSentimentData() {
      this.$apis.sentiment({}, this.token);
    },
    postback() {
      this.saveDisaster();
    },
    uploadImg(e) {
      files = e.target.files[0];
      this.fileUrl = window.URL.createObjectURL(e.srcElement.files[0]);
      this.formDatas(files);
    },
    aetherupload(e) {
      if (this.fileList.length <= 9) {
        file = e.target.files;
        for (let i = 0; i < file.length; i++) {
          this.fileList.push(window.URL.createObjectURL(e.srcElement.files[i]));
          this.formDatas(file[i], i + 1);
        }
      }
    },
    formDatas(data, index) {
      const file = data;
      const fileReader = new FileReader();
      this.ifUpload = false;
      fileReader.readAsBinaryString(file);
      fileReader.onload = (e) => {
        if (index === undefined) {
          formData.set("area_snapshoot", file, file.name);
          this.fileList.length > 0
            ? (this.ifUpload = true)
            : (this.ifUpload = false);
        } else {
          formData.set("snapshoot_" + index, file, file.name);
          this.fileUrl != "" ? (this.ifUpload = true) : (this.ifUpload = false);
        }
      };
    },
    saveDisaster() {
      formData.set(
        "disaster_description",
        this.ruleForm.disaster_description + ""
      );
      formData.set("action", "update");
      formData.set("disaster_level", this.situation);
      formData.set("spot_connection", this.labelId);
      formData.set("project_type", this.taskType);
      formData.set("relation_task_id", this.envelopeList);
      formData.set("project_id", this.taskID);
      formData.set("id", this.obj.disaster.id);
      formData.set("is_portal", is_portal);
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$apis
        .saveDisaster(formData, this.token)
        .then((data) => {
          if (data.data.status == 0) {
            this.$message({
              message: data.data.message,
              type: "success",
            });
            this.dialogVisible = false;
          }
          this.$emit("updateFlying");
          loading.close();
        })
        .catch((err) => {
          console.error(err);
          loading.close();
        });
    },
    // 获取下拉任务列表
    getTaskList() {
      this.$apis
        .getProjectList({}, this.token)
        .then((data) => {
          if (data.data.status == 0) {
            this.taskList = data.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getTaskId(param) {
      this.taskID = param;
      this.getProjectDetail(param);
    },
    getProjectDetail(param) {
      this.$apis
        .getProjectDetail({ project_id: param }, this.token)
        .then((data) => {
          if (data.data.status == 0) {
            this.project_task_count = data.data.data.project_task_count;
            this.project_task_confirm_count =
              data.data.data.project_task_confirm_count;
            this.project_task_unconfirmed_count =
              data.data.data.project_task_unconfirmed_count;
            this.project_task_progress = data.data.data.project_task_progress;
            this.project_task_finished = data.data.data.project_task_finished;
            // this.taskType = data.data.data.project_type
            if (data.data.data.project_data.type == 1) {
              this.radio = 1;
              this.$apis
                .listFeiMaTask({ project_id: param }, this.token)
                .then((data) => {
                  this.subtaskList = data.data.data;
                })
                .catch((err) => {
                  console.error(err);
                });
            } else {
              this.radio = 2;
              this.$apis
                .listCommonTask({ project_id: param }, this.token)
                .then((data) => {
                  this.subtaskList = data.data.data;
                })
                .catch((err) => {
                  console.error(err);
                });
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getLabelId(param) {
      this.labelId = param;
    },
    getsituation(param) {
      this.situation = param;
    },
    envelope(param) {
      this.envelopeList = JSON.stringify(param);
    },
    historyFlyUni() {
      if (!this.obj.is_portal) {
        is_portal = 0;
        this.checkednameUni = false;
        return;
      }
      if (event.target.checked == true) {
        is_portal = 1;
      } else {
        is_portal = 0;
      }
    },
  },
  computed: {
    ...mapState(["token", "user"]),
  },
};
</script>

<style lang="less" scoped>
.sign {
  float: left;
}
.taskchoose {
  margin-left: 28px;
}
.degree {
  margin-left: 55px;
}
/deep/.el-form-item {
  width: 100%;
}
/deep/.el-dialog {
  height: 650px !important;
  overflow-y: auto;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-divider--horizontal {
  margin: 12px 0;
}
.bodyBar {
  width: 80%;
  height: 100%;
  margin-left: 8%;
}
.taskname {
  font-size: 13px;
  color: #3c4353;
  line-height: 35px;
  float: left;
}
.fmSelect {
  width: 240px;
}
.disaster {
  font-size: 13px;
  color: #3c4353;
  line-height: 35px;
  float: left;
}

.summary {
  width: 100%;
  margin-top: 20px;
  float: left;
  .summarytit {
    font-size: 13px;
    color: #3c4353;
    line-height: 35px;
    float: left;
  }
  .summaryBar {
    width: 410px;
    height: 100px;
    border-radius: 2px;
    background-color: #f6f7f9;
    float: right;
    display: flex;
    flex-wrap: wrap-reverse;
    .count {
      color: #838a9d;
      font-size: 13px;
      line-height: 45px;
      margin-left: 10px;
    }
  }
}
.scene {
  width: 100%;
  margin-top: 20px;
  float: left;
  .scenetit {
    font-size: 13px;
    color: #3c4353;
    line-height: 35px;
    float: left;
  }
  .sceneBar {
    height: 15px;
    width: 410px;
    float: right;
    border-bottom: 1px dashed #a6aab8;
  }
}
/deep/.el-textarea {
  width: 74%;
  float: right;
}
.snapshot {
  width: 63%;
  margin-top: 20px;
  float: left;
  .snapshotit {
    font-size: 13px;
    color: #3c4353;
    line-height: 35px;
    float: left;
  }
  .snapshotbar {
    margin-left: 12%;
    float: left;
    line-height: 45px;
    .imgupload {
      font-size: 0;
      width: 224px;
      height: 130px;
      opacity: 0;
      cursor: pointer;
      position: absolute;
      top: 5%;
      left: 21.5%;
    }
    .snapshotBar {
      width: 224px;
      height: 130px;
      background-color: #f6f7f9;
      border: 1px dashed rgba(0, 0, 0, 0.15);
      .uploadImg {
        margin-left: 89px;
        margin-top: 20px;
      }
      .describe {
        color: #838a9d;
        font-size: 13px;
        text-align: center;
        line-height: 1px;
      }
    }
  }
}
.hovers {
  float: left;
  margin-top: 50px;
  .hoverBar {
    display: none;
    height: 50px;
    .yellow {
      color: #faad14;
      float: left;
      text-align: center;
      margin-top: 10px;
      margin-left: 8px;
    }
    .sign {
      color: #a6aab8;
      font-size: 12px;
      line-height: 20px;
      margin-left: 5px;
      margin-right: 5px;
    }
    /deep/ .el-popper {
      padding: 0;
      float: left;
      margin-left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

// .snapshot:hover + .hovers {
//   display: block !important;
// }

.sings:hover + .hoverBar {
  display: block !important;
}

.henduoren {
  float: left;
  width: 63%;
  margin-top: 20px;
  .henduorenWord {
    font-size: 13px;
    color: #3c4353;
    line-height: 35px;
    float: left;
  }
  .imgBar {
    .snapshotbar {
      margin-left: 12%;
      float: left;
      line-height: 45px;
      .imgupload {
        font-size: 0;
        width: 224px;
        height: 130px;
        opacity: 0;
        cursor: pointer;
        position: absolute;
        top: 5%;
        left: 21.5%;
      }
      .snapshotBar {
        width: 224px;
        height: 130px;
        background-color: #f6f7f9;
        border: 1px dashed rgba(0, 0, 0, 0.15);
        .uploadImg {
          margin-left: 89px;
          margin-top: 20px;
        }
        .describe {
          color: #838a9d;
          font-size: 13px;
          text-align: center;
          line-height: 1px;
        }
      }
    }
  }
}

// .henduoren:hover + .hoverOne {
//   display: block !important;
// }

.imgNav {
  width: 90%;
  margin-left: 21.5%;
  margin-top: 4%;
  float: left;
  .imgtit {
    width: 224px;
    height: 130px;
    margin-right: 15px;
    float: left;
    margin-bottom: 15px;
  }
}
.hoverOne {
  float: left;
  margin-top: 50px;
  .hoverBar {
    display: none;
    .yellow {
      color: #faad14;
      float: left;
      text-align: center;
      margin-top: 10px;
      margin-left: 8px;
    }
    .sign {
      color: #a6aab8;
      font-size: 12px;
      line-height: 20px;
      margin-left: 5px;
      margin-right: 5px;
    }
    /deep/ .el-popper {
      float: left;
      margin-left: 20px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.sings:hover + .hoverBar {
  display: block !important;
}

.sings {
  position: relative;
  top: 20px;
  color: #faad14;
}

.divd {
  float: left;
}
.dialog-footer {
  float: right;
  margin-bottom: 10px;
}
.select {
  float: left;
  margin-left: 20px;
  margin-top: 8px;
}
</style>
