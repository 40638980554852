var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.requestDataManager !== ""
    ? _c("div", { staticClass: "comBjDio" }, [
        _c("div", { ref: "bj_header", staticClass: "header" }),
        _vm._v(" "),
        _c("div", { ref: "bj_body", staticClass: "body" }),
        _vm._v(" "),
        _c("div", { ref: "bj_footer", staticClass: "footer" }),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isTasks,
                  expression: "isTasks",
                },
              ],
              staticClass: "taskBtn",
            },
            [
              _c(
                "div",
                {
                  staticClass: "onlineTaskBtn btn",
                  class: { selectTaskBtn: _vm.isTaskBtn === 1 },
                  on: {
                    click: function ($event) {
                      return _vm.clickTaskBtn(1)
                    },
                  },
                },
                [_vm._v("\n        在线任务\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "onlineTaskBtn btn",
                  class: { selectTaskBtn: _vm.isTaskBtn === 2 },
                  on: {
                    click: function ($event) {
                      return _vm.clickTaskBtn(2)
                    },
                  },
                },
                [_vm._v("\n        历史任务\n      ")]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "content-header" }, [
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isTasks,
                  expression: "!isTasks",
                },
              ],
              attrs: {
                src: require("@/assets/img/centerHome/incoming.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isTasks && _vm.isTaskBtn === 1,
                  expression: "isTasks && isTaskBtn === 1",
                },
              ],
              attrs: {
                src: require("@/assets/img/centerHome/onlinetask.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isTasks && _vm.isTaskBtn === 2,
                  expression: "isTasks && isTaskBtn === 2",
                },
              ],
              attrs: {
                src: require("@/assets/img/centerHome/offtask.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "device-total" }, [
              _vm._v(_vm._s(_vm.corsTotal + _vm.total)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isTasks && _vm.isTaskBtn === 1,
                    expression: "isTasks && isTaskBtn === 1",
                  },
                ],
                staticClass: "examine",
                on: { click: _vm.toMore },
              },
              [_vm._v("\n        多屏查看\n      ")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isTasks,
                  expression: "!isTasks",
                },
              ],
              staticClass: "content-btns",
            },
            [
              _c(
                "div",
                {
                  staticClass: "content-btn",
                  class: { selectContentBtn: _vm.isSelectLocation === 1 },
                  on: {
                    click: function ($event) {
                      return _vm.selectLocation(1)
                    },
                  },
                },
                [_vm._v("\n        当前位置\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "content-btn",
                  class: { selectContentBtn: _vm.isSelectLocation === 2 },
                  on: {
                    click: function ($event) {
                      return _vm.selectLocation(2)
                    },
                  },
                },
                [_vm._v("\n        上次位置\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "content-btn",
                  class: { selectContentBtn: _vm.isSelectLocation === 3 },
                  on: {
                    click: function ($event) {
                      return _vm.selectLocation(3)
                    },
                  },
                },
                [_vm._v("\n        登记位置\n      ")]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "content-data" }, [
            _vm.sortiesData["其他"]
              ? _c(
                  "div",
                  { staticClass: "content-data-item" },
                  [
                    _c("show-more-check-box", {
                      attrs: {
                        sortiesData: _vm.sortiesData["其他"]["无人机"],
                        isHistoricalTask: _vm.isHistoricalTask,
                        deviceName: "其他无人机",
                        isDate: _vm.isDate,
                        datedDeviceName: _vm.datedDeviceName,
                        initSelect: _vm.initSelect,
                      },
                      on: {
                        checkedDevice: _vm.checkedDevice,
                        getDateData: _vm.getDateData,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.sortiesData["其他"]
              ? _c(
                  "div",
                  { staticClass: "content-data-item" },
                  [
                    _c("show-more-check-box", {
                      attrs: {
                        sortiesData: _vm.sortiesData["其他"]["终端设备"],
                        isHistoricalTask: _vm.isHistoricalTask,
                        deviceName: "终端设备",
                        isDate: _vm.isDate,
                        datedDeviceName: _vm.datedDeviceName,
                        initSelect: _vm.initSelect,
                      },
                      on: {
                        checkedDevice: _vm.checkedDevice,
                        getDateData: _vm.getDateData,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticStyle: { margin: "11px 0" } }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isTaskBtn === 1 && _vm.isTasks,
                    expression: "isTaskBtn === 1 && isTasks",
                  },
                ],
                staticClass: "trajectoryBar",
              },
              [
                _c("span", { staticClass: "trajectory" }, [_vm._v("聚合开关")]),
                _vm._v(" "),
                _c("el-switch", {
                  staticClass: "switchTurn",
                  staticStyle: { "margin-right": "20px" },
                  on: { change: _vm.showMaxZoom },
                  model: {
                    value: _vm.isMaxZoom,
                    callback: function ($$v) {
                      _vm.isMaxZoom = $$v
                    },
                    expression: "isMaxZoom",
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "trajectory" }, [_vm._v("移动轨迹")]),
                _vm._v(" "),
                _c("el-switch", {
                  staticClass: "switchTurn",
                  on: { change: _vm.showAllTrack },
                  model: {
                    value: _vm.isShowAllTrack,
                    callback: function ($$v) {
                      _vm.isShowAllTrack = $$v
                    },
                    expression: "isShowAllTrack",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }