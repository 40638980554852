import { render, staticRenderFns } from "./showView.vue?vue&type=template&id=58dcb22f&scoped=true&"
import script from "./showView.vue?vue&type=script&lang=js&"
export * from "./showView.vue?vue&type=script&lang=js&"
import style0 from "./showView.vue?vue&type=style&index=0&id=58dcb22f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58dcb22f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/www/wwwroot/xhddgl.afuav.com/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('58dcb22f')) {
      api.createRecord('58dcb22f', component.options)
    } else {
      api.reload('58dcb22f', component.options)
    }
    module.hot.accept("./showView.vue?vue&type=template&id=58dcb22f&scoped=true&", function () {
      api.rerender('58dcb22f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/monitorPlane/showView.vue"
export default component.exports