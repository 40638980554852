<template>
  <div class="task">
    <div id="map" class="map"></div>
    <div v-if="!isComponents" class="info">
      <div class="input-item">
        <el-input
          id="tipinput"
          type="text"
          v-model="searchLoactionName"
          placeholder="搜地点"
        >
          <div slot="suffix" class="arrowUpAndDown">
            <img
              src="@/assets/img/planTask/search.png"
              alt=""
              style="height: 40px"
              @click="complete"
            />
          </div>
        </el-input>
      </div>
    </div>
    <div class="control">
      <div style="top: 27%">
        <img
          src="@/assets/img/homeDialog/switchMap.png"
          alt=""
          @click="clickControl(3)"
        />
        <div class="switchMap" v-show="isControl === 3">
          <div class="clickMap" @click="switchMap(1)">
            <img
              v-if="mapIndex === 1"
              src="@/assets/img/homeDialog/selectGaode.png"
              alt=""
            />
            <img v-else src="@/assets/img/homeDialog/gaode.png" alt="" />
            <div class="mapType" :class="mapIndex === 1 ? 'isMap' : ''">
              默认地图
            </div>
          </div>
          <div class="clickMap" @click="switchMap(2)">
            <img
              v-if="mapIndex === 2"
              src="@/assets/img/homeDialog/selectTianditu.png"
              alt=""
            />
            <img v-else src="@/assets/img/homeDialog/tianditu.png" alt="" />

            <div class="mapType" :class="mapIndex === 2 ? 'isMap' : ''">
              天地图
            </div>
          </div>
          <div class="clickMap" @click="switchMap(3)">
            <img
              v-if="mapIndex === 3"
              src="@/assets/img/homeDialog/selectYingxiang.png"
              alt=""
            />
            <img v-else src="@/assets/img/homeDialog/yingxiang.png" alt="" />

            <div class="mapType" :class="mapIndex === 3 ? 'isMap' : ''">
              影像瓦片
            </div>
          </div>
        </div>
      </div>
      <div style="top: 28%" v-show="isComponents">
        <img
          v-show="showTable"
          src="@/assets/img/planTask/showTable_nor.png"
          alt=""
          @click="clickShowTable()"
        />
        <img
          v-show="!showTable"
          src="@/assets/img/planTask/hideTable_nor.png"
          alt=""
          @click="clickShowTable()"
        />
      </div>
    </div>
    <div v-if="!isComponents" class="drawTool" @mouseleave="leaveImg()">
      <div
        class="draw canBtn toolBtn"
        :class="showDrawType ? 'selectToolBtn' : ''"
        @click="selectDraw()"
        @mouseenter="hovImg('shapeImg')"
      >
        <img
          v-show="shapeImg === 0"
          src="@/assets/img/planTask/drawShape_nor.png"
          alt=""
          width="24"
        />
        <img
          v-show="shapeImg === 1"
          src="@/assets/img/planTask/drawShape_hov.png"
          alt=""
          width="24"
        />
        <img
          v-show="shapeImg === 2"
          src="@/assets/img/planTask/drawShape_pre.png"
          alt=""
          width="24"
        />

        <!-- <img src="@/assets/img/planTask/drawShape_nor.png" alt="" width="24" /> -->

        绘制
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="importKml canBtn toolBtn" @click="$refs.kmlInputEl.click()">
        <img
          v-show="kmlImg === 0"
          src="@/assets/img/planTask/importKml_nor.png"
          alt=""
          width="24"
        />
        <input
          type="file"
          ref="kmlInputEl"
          style="display: none"
          accept=".kml, .KML"
          @change="uploadKmlFile"
        />KML
      </div>
      <el-divider direction="vertical"></el-divider>
      <div
        class="delDraw importKml canBtn toolBtn"
        @click="selectDel()"
        @mouseenter="hovImg('delImg')"
      >
        <img
          v-show="delImg === 0"
          src="@/assets/img/planTask/delShape_nor.png"
          alt=""
          width="24"
        />
        <img
          v-show="delImg === 1"
          src="@/assets/img/planTask/delShape_hov.png"
          alt=""
          width="24"
        />

        删除
      </div>
      <!-- <el-divider direction="vertical"></el-divider> -->

      <div
        class="release canBtn release"
        @click="selectRelease()"
        :class="isCanRelease ? 'canRelease' : 'noRelease'"
      >
        <img
          v-if="isCanRelease"
          src="@/assets/img/planTask/release.png"
          alt=""
          width="24"
        />
        <img
          v-else
          src="@/assets/img/planTask/release_nor.png"
          alt=""
          width="24"
        />

        发布
      </div>
    </div>
    <div class="measurementTool">
      <div
        class="canBtn toolBtn"
        :class="{ selectBtn: selectText === 'distance' }"
        @click="selectMeasurementTool('distance')"
      >
        <img src="@/assets/img/planTask/getDistance.png" alt="" width="24" />

        测距
      </div>
      <div
        class="canBtn toolBtn"
        :class="{ selectBtn: selectText === 'area' }"
        @click="selectMeasurementTool('area')"
      >
        <img src="@/assets/img/planTask/getArea.png" alt="" width="24" />

        测面
      </div>
      <div
        class="canBtn toolBtn"
        :class="{ selectBtn: selectText === 'marker' }"
        @click="selectMeasurementTool('marker')"
      >
        <img src="@/assets/img/planTask/getPoint.png" alt="" width="24" />
        测点
      </div>
    </div>
    <div class="centerShow">
      <div
        class="canBtn toolBtn"
        :class="{ selectBtn: selectText === 'center' }"
        @click="shapeInCenter('center')"
      >
        <img src="@/assets/img/planTask/setCenter.png" alt="" width="24" />

        居中显示
      </div>
    </div>
    <div v-show="showDrawType" class="drawType">
      <div
        class="canBtn publicDrawType"
        :class="{ selectBtn: selectText === 'circle' }"
        @click="drawShape('circle')"
      >
        <img
          v-show="selectText !== 'circle'"
          class="setImgMargin"
          src="@/assets/img/planTask/cricle_nor.png"
          alt=""
          width="24"
        />
        <img
          v-show="selectText === 'circle'"
          class="setImgMargin"
          src="@/assets/img/planTask/cricle_pre.png"
          alt=""
          width="24"
        />

        圆形
      </div>
      <div
        class="canBtn publicDrawType"
        :class="{ selectBtn: selectText === 'rectangle' }"
        @click="drawShape('rectangle')"
      >
        <img
          v-show="selectText !== 'rectangle'"
          class="setImgMargin"
          src="@/assets/img/planTask/rectang_nor.png"
          alt=""
          width="24"
        />
        <img
          v-show="selectText === 'rectangle'"
          class="setImgMargin"
          src="@/assets/img/planTask/rectang_pre.png"
          alt=""
          width="24"
        />

        矩形
      </div>
      <div
        class="canBtn publicDrawType"
        :class="{ selectBtn: selectText === 'polygon' }"
        @click="drawShape('polygon')"
      >
        <img
          v-show="selectText !== 'polygon'"
          style="margin-left: -18px"
          src="@/assets/img/planTask/drawShape_nor.png"
          alt=""
          width="24"
        />
        <img
          v-show="selectText === 'polygon'"
          style="margin-left: -18px"
          src="@/assets/img/planTask/drawShape_pre.png"
          alt=""
          width="24"
        />

        多边形
      </div>
    </div>
    <div></div>
    <div v-if="releaseShow">
      <fireReportingForm
        :listDrawShapeData="listDrawShapeData"
        :releaseShow="releaseShow"
        @closeForm="closeForm"
        :userInfo="userInfo"
        :kmlData="kmlData"
        :isSelfDraw="isSelfDraw"
        :isWgs="isWgs"
      />
    </div>
  </div>
</template>
<script>
import coordtransform from "@/utils/transform.js";
import transform from "@/utils/transform.js";
import { mapState, mapMutations } from "vuex";
import fireReportingForm from "./fireReportingForm.vue";
const turf = require("@turf/turf");
import MapTool from "./mapTool";
export default {
  components: {
    fireReportingForm,
  },
  props: {
    isComponents: {
      //是否当做组件使用
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      map: "", //地球实例

      mapIndex: 1, //选择不同地图
      tiandituLayer: {}, //天地图
      tianditutext: {}, //天地图文字
      googleLayer: {}, //谷歌影像地图
      tiandituYinxiangLayer: {}, //天地图影像
      isControl: -1, //选中右侧控件的第几个
      showDrawType: false,
      mouseTool: "", //鼠标工具类
      selectText: "", //点击中按钮的文字，用于按钮选中标识
      shapeTotal: 0,
      mapToolManage: "",
      polylineData: [], //绘制多边形时，未结束时的折线，区分是否已经结束的线条颜色
      ruler2: "", //测距
      disMouseMoveStart: "", //测距时的标记点
      distanceText: "", //测距时的标记点
      areaPath: [], //测面时的坐标
      areaText: "", //面积文本标签
      measurePoint: "", //测点对象
      releaseShow: false,
      listDrawShapeData: [], //绘制好的数据
      userInfo: {},
      shapeImg: 0,
      kmlImg: 0,
      delImg: 0,
      isCanRelease: false, //是否可以发布
      selectShape: [],
      searchLocation: "",
      placeSearch: "",
      searchLoactionName: "", //搜索的地点
      isSelfDraw: true,
      kmlData: {}, //导入的Kml数据
      showTable: true, //是否收起表格
      allDrawShape: {}, //所有的绘制的图形
      isWgs: false,
      wgsIndex: 0, //判断是否两次点击的是wgs底图
      gxGcjPolygon: [],
      gxWgsPolygon: [],
      gxPolygon: [],
      gxBorder: "", //广西边界
      distancePolyline: [], //测距图形
      polygonMarkerNumber: 0, //画多边形时，点击时绘制的点的序号
      poiData: [], //搜索时的marker 点标记，用与切换地图更新坐标
    };
  },

  computed: {
    ...mapState(["monitorPlaneData", "token"]),
  },
  watch: {
    selectShape(newVal, oldVal) {
      // console.log(newVal, oldVal);
    },
  },
  filters: {},
  mounted() {
    this.init();
  },
  methods: {
    ...mapMutations([
      "setMonitorPlaneData",
      "currentUrlStorage",
      "setOrderQty",
    ]),
    init() {
      setTimeout(() => {
        this.initMap();
        window.getSelectShape = this.getSelectShape;
      }, 1000);
      this.currentUrlStorage(["首页", "任务管理", "火情上报"]);
      this.getUserInfo();
      this.getOrderQty();
    },
    getUserInfo() {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.userInfo.unit_name = this.userInfo.user_unit.unit_name;
    },
    // 获取列表未查看的数量。
    getOrderQty() {
      this.$apis
        .demandListOrder({ task_type: "无人机" }, this.token)
        .then((res) => {
          this.setOrderQty(res.data.data.order_qty);
        });
    },
    initMap() {
      let map = new AMap.Map("map", {
        resizeEnable: true,
        mapStyle: "amap://styles/normal",
        zoom: 4,
      });

      this.map = map;

      let that = this;
      AMap.plugin("AMap.PlaceSearch", function () {
        that.placeSearch = new AMap.PlaceSearch({
          map: that.map,
        }); //构造地点查询类
      });
      AMap.plugin("AMap.Autocomplete", function () {
        // 实例化Autocomplete
        var autoOptions = {
          // input 为绑定输入提示功能的input的DOM ID
          input: "tipinput",
        };

        var autoComplete = new AMap.Autocomplete(autoOptions);
        autoComplete.on("select", that.search); //注册监听，当选中某条记录时会触发
        // autoComplete.on("complete", that.complete); //注册监听，当选中某条记录时会触发

        // 无需再手动执行search方法，autoComplete会根据传入input对应的DOM动态触发search
      });

      this.tiandituLayer = new AMap.TileLayer({
        getTileUrl:
          "//t{0,1,2,3,4,5,6,7}.tianditu.gov.cn/DataServer?T=vec_w&tk="+process.env.VUE_APP_TIANDITU_KEY+"&x=[x]&y=[y]&l=[z]",
        zIndex: 100,
      });
      this.tianditutext = new AMap.TileLayer({
        getTileUrl:
          "//t{0,1,2,3,4,5,6,7}.tianditu.gov.cn/DataServer?T=cva_w&tk="+process.env.VUE_APP_TIANDITU_KEY+"&x=[x]&y=[y]&l=[z]",
        zIndex: 101,
      });
      this.googleLayer = new AMap.TileLayer({
        getTileUrl:
          "//wprd0{1,2,3,4}.is.autonavi.com/appmaptile?&x=[x]&y=[y]&z=[z]&lang=zh_cn&size=1&scl=1&style=6",
        zIndex: 2,
      });

      // 天地图影像图层
      this.tiandituYinxiangLayer = new AMap.TileLayer({
        getTileUrl:
          // http://t0.tianditu.gov.cn/img_w/wmts
          "//t{0,1,2,3,4,5,6,7}.tianditu.gov.cn/DataServer?T=img_w&tk=" +
          process.env.VUE_APP_TIANDITU_KEY +
          "&x=[x]&y=[y]&l=[z]",
        zIndex: 100,
      });
      if (this.isComponents) {
        this.$emit("getMap", this.map);
      } else {
        this.setBorder(); //只有是发布页面需要有边界
      }
      this.mouseTool = new AMap.MouseTool(this.map);
      this.mapToolManage = new MapTool(this.map, this.mouseTool);
      this.getDistance();
      this.map.on("dblclick", this.dblclickFunc);
    },
    search(e) {
      console.log(e);
      this.placeSearch.setCity(e.poi.adcode);
      this.placeSearch.search(e.poi.name, function (status, result) {
        // console.log(status,result);
      }); //关键字查询查询
      setTimeout(() => {
        let data = this.map.getAllOverlays("marker");
        this.poiData = [];
        data.forEach((item) => {
          if (item.CLASS_NAME === "AMap.Marker") {
            // console.log(item.getExtData());
            let extData = item.getExtData();
            if (!extData.index) {
              this.poiData.push(item);
            }
          }
        });
      }, 1000);
    },
    complete(e) {
      console.log(e);
      this.placeSearch.search(this.searchLoactionName); //关键字查询查询
      // this.searchLoactionName = e.
    },
    // 选择地图
    switchMap(index) {
      if (index !== this.mapIndex) {
        this.mapIndex = index;
        if (index === 1) {
          this.tiandituLayer.setMap(null);
          this.tiandituYinxiangLayer.setMap(null);
          this.tianditutext.setMap(null);
          this.wgsIndex = 0;
          this.isWgs = false;
        } else if (index === 2) {
          this.tiandituYinxiangLayer.setMap(null);

          this.tianditutext.setMap(this.map);
          this.tiandituLayer.setMap(this.map);
          this.wgsIndex++;
          this.isWgs = true;
        } else {
          this.wgsIndex++;
          this.tianditutext.setMap(this.map);
          this.tiandituYinxiangLayer.setMap(this.map);
          this.isWgs = true;
        }
        // this.map.setCenter(this.trajectory[0]);
      }
      if (this.wgsIndex < 2) {
        if (this.isComponents) {
          //当组件使用
          this.$emit("changeMap", this.isWgs);
        } else {
          this.mapToolManage.switchMapLnglat(this.isWgs);
          if (this.isWgs){
            this.poiData.forEach((item,index)=>{
              let lnglat = item.getPosition()
              item.setPosition(coordtransform.gcj02towgs84(lnglat.R,lnglat.Q))
              this.poiData[index] = item
            })
          }else {
            this.poiData.forEach((item,index)=>{
              let lnglat = item.getPosition()
              item.setPosition(coordtransform.wgs84togcj02(lnglat.R,lnglat.Q))
              this.poiData[index] = item
            })
          }
        }
      }
    },

    // 选择右侧的控件 切换地图
    clickControl(index) {
      if (index === this.isControl) {
        this.isControl = -1;
      } else {
        this.isControl = index;
      }
      this.isShowData = false;
    },
    // 点击绘制
    selectDraw() {
      this.ruler2.turnOff();
      if (this.showDrawType) {
        this.shapeImg = 0;
        this.mapToolManage.setCanSelectShape(true);
      } else {
        this.shapeImg = 2;
        this.mapToolManage.setCanSelectShape(false);
      }
      this.showDrawType = !this.showDrawType;
      this.selectText = "";
      this.mouseTool.close();
    },
    // 点击删除
    selectDel() {
      this.mapToolManage.delSelectShape();
      this.getSelectShape();
      this.showDrawType = false;
      this.mapToolManage.setCanSelectShape(true);
      this.mouseTool.close();
      this.map.off("click", this.drawPolygon);
      this.mapToolManage.clearPolygon();
    },
    // 点击发布
    selectRelease() {
      this.mapToolManage.clearPolygon();
      let data = this.mapToolManage.returnDrawShape();
      this.listDrawShapeData = data;
      let allShape = data[1];
      let selectShape = data[2];
      this.selectShape = data[2];
      if (this.isCanRelease) {
        let str = "";
        if (this.isSelfDraw) {
          let shapeAll = Object.keys(allShape).length;
          let giveUp = shapeAll - selectShape.length;

          if (giveUp === 0) {
            str = "您共规划了" + shapeAll + "个区块，已全部选中";
          } else {
            str =
              "您共规划了" +
              shapeAll +
              "个区块，当前只选中" +
              selectShape.length +
              "个，确定放弃其中" +
              (shapeAll - selectShape.length) +
              "个吗？";
          }
        } else {
          let total = Object.keys(this.kmlData).length;
          str = "您共导入了" + total + "个区块";
        }
        this.$confirm(str, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.releaseShow = true;
          })
          .catch(() => {});
      }
    },
    clearMap() {
      this.map.clearMap();
      this.map.off("click", this.clearMap);
    },
    // 绘制图形
    drawShape(shape) {
      // this.mapToolManage.clearPolygon()
      this.selectText = shape;
      this.delCompute();
      if (!this.isSelfDraw) {
        this.map.on("click", this.clearMap);
        this.isSelfDraw = true;
      }
      this.mapToolManage.setShape(shape);
      switch (this.selectText) {
        case "circle":
          this.mouseTool.circle({
            strokeColor: "#3F67FF",
            strokeOpacity: 1,
            strokeWeight: 3,
            fillColor: "#3F67FF",
            fillOpacity: 0.4,
            strokeStyle: "solid",
          });
          break;
        case "rectangle":
          this.mouseTool.rectangle({
            strokeColor: "#3F67FF",
            strokeOpacity: 1,
            strokeWeight: 3,
            fillColor: "#3F67FF",
            fillOpacity: 0.4,
            // strokeStyle还支持 solid
            strokeStyle: "solid",
            // strokeDasharray: [30,10],
          });
          break;
        case "polygon":
          // 使用鼠标事件，绘制多边形，此时线条颜色为透明
          this.mouseTool.polygon({
            strokeColor: "transparent",
            strokeOpacity: 0.3,
            strokeWeight: 3,
            fillColor: "#3F67FF",
            fillOpacity: 0.3,
            strokeStyle: "solid",
          });
          this.map.on("click", this.drawPolygon);
          // 绘制线条
          break;
        default:
          break;
      }
    },
    drawPolygon(e) {
      if (this.selectText === "polygon") {
        this.polygonMarkerNumber++;
        this.mapToolManage.lnglatInShape(e.lnglat, this.polygonMarkerNumber);
      }
    },
    mouseMove(e) {
      // console.log(e);
      let p1 = this.disMouseMoveStart;
      let p2 = e.lnglat;
      const distance =
        (Math.round(p1.distance(p2)) / 1000).toFixed(1) + "km,双击结束";
      if (this.distanceText === "") {
        this.distanceText = this.mapToolManage.setMapShapeaArea(
          p2,
          distance,
          "#FFFFFF",
          "#DCDCDC",
          "120px",
          "0px"
        );
      } else {
        this.distanceText.setPosition(p2);
        this.distanceText.setText(distance);
      }
    },
    clickMouseMove(e) {
      this.disMouseMoveStart = e.lnglat;

      if (this.selectText === "distance") {
        if (this.distanceText === "") this.map.on("mousemove", this.mouseMove);
      }
      if (this.selectText === "area") {
        this.areaPath.push(e.lnglat);

        if (this.areaPath.length <= 2) {
          if (this.areaText === "") {
            this.areaText = this.mapToolManage.setMapShapeaArea(
              e.lnglat,
              "至少绘制三个点",
              "#FFFFFF",
              "#DCDCDC",
              "8.2rem",
              "10px"
            );
          } else {
            this.areaText.setPosition(e.lnglat);
            this.areaText.setText("至少绘制三个点");
          }
        } else {
          var area =
            (
              Math.round(AMap.GeometryUtil.ringArea(this.areaPath)) / 1000000
            ).toFixed(1) + "km²,双击结束";
          this.areaText.setPosition(e.lnglat);
          this.areaText.setText(area);
        }
      }
      if (this.selectText === "marker") {
        if (this.measurePoint === "") {
          this.measurePoint = this.mapToolManage.setMarker(
            [e.lnglat],
            "measurePointIcon",
            -7.5,
            -30
          );
        } else {
          this.measurePoint.setPosition(e.lnglat);
        }
        this.measurePoint.setLabel({
          offset: new AMap.Pixel(10, 10), //设置文本标注偏移量
          content:
            '<div class="measurePoint"><div class="lnglat"><img src="/static/img/planTask/lngIcon.png" alt="" width="12"><span class="titleLnglat">经度</span><span>' +
            e.lnglat.lng.toFixed(4) +
            '</span></div><div class="lnglat"><img src="/static/img/planTask/latIcon.png" alt="" width="12"><span class="titleLnglat">纬度</span><span>' +
            e.lnglat.lat.toFixed(4) +
            "</span></div></div>", //设置文本标注内容
          direction: "right", //设置文本标注方位
        });
      }
    },
    dblclickFunc(e) {
      if (this.selectText === "polygon") {
        this.mapToolManage.clearPolygon();
        this.polygonMarkerNumber = 0;
      }
      if (this.selectText === "area") {
        let text = this.areaText.getText();
        this.areaText.setText(text.split(",")[0]);
        // this.mapToolManage.clearTextAndMarker(this.selectText);
        if (this.areaPath.length <= 2) {
          this.areaText.setMap(null);
          this.areaText = "";
        }
      }
      if (!this.showDrawType) {
        this.measurePoint = "";
        this.areaPath = [];
        // this.areaText.setMap
        // this.mapToolManage.clearTextAndMarker(this.selectText);
        this.areaText = "";
      }
    },
    delCompute() {
      let that = this;
      if (that.distancePolyline.length > 0) {
        that.mapToolManage.clearTextAndMarker(that.selectText);
        that.distancePolyline.forEach((item) => {
          item.setMap(null);
        });
        that.distancePolyline = [];
      }
      that.mapToolManage.clearTextAndMarker(that.selectText);

      this.mapToolManage.clearCompute();
    },
    selectMeasurementTool(name) {
      this.mapToolManage.clearPolygon();
      let that = this;
      this.showDrawType = false;
      this.mapToolManage.setShape(name);

      this.mouseTool.close();
      this.ruler2.turnOff();
      this.selectText = name;

      that.map.on("click", this.clickMouseMove);
      // that.map.on("dblclick", this.dblclickFunc);

      this.delCompute();
      switch (this.selectText) {
        case "distance":
          this.ruler2.turnOn();
          let lnglat = "";
          this.ruler2.on("end", function (e) {
            // e.polyline.setMap(null);
            that.distancePolyline.push(e.polyline);
            that.map.off("mousemove", that.mouseMove);
            if (that.distanceText !== "") that.distanceText.setMap(null);
            that.distanceText = "";
            // that.mapToolManage.clearTextAndMarker(that.selectText);
            lnglat = "";
          });

          this.ruler2.on("addnode", function (e) {
            that.mapToolManage.setMarker([e.position], "distanceIcon");
            let distance = "0km";
            if (lnglat !== "") {
              distance =
                (Math.round(lnglat.distance(e.position)) / 1000).toFixed(1) +
                "km";
            }
            that.mapToolManage.setMapShapeaArea(
              e.position,
              distance,
              "#FFFFFF",
              "#DCDCDC",
              "71px",
              "20px"
            );
            lnglat = e.position;
          });

          break;
        case "area":
          this.mouseTool.polygon({
            strokeColor: "#7C37B9",
            strokeOpacity: 1,
            strokeWeight: 3,
            fillColor: "#3F67FF",
            fillOpacity: 0.3,
            strokeStyle: "solid",
          });
          break;
        default:
          break;
      }
    },

    getDistance() {
      let icon = {
        icon: new AMap.Icon({
          size: new AMap.Size(0, 0),
          image: require("@/assets/img/planTask/diatanceDot.png"),
          imageSize: new AMap.Size(14, 14),
        }),
        offset: new AMap.Pixel(-7, -7),
      };
      let startMarkerOptions = icon;
      var endMarkerOptions = icon;
      var midMarkerOptions = icon;
      let tmpLineOptions = {
        strokeStyle: "dashed",
        strokeColor: "#FF3000",
        strokeOpacity: 1,
        strokeWeight: 2,
      };
      var lineOptions = {
        strokeStyle: "solid",
        strokeColor: "#FF3000",
        strokeOpacity: 1,
        strokeWeight: 2,
      };
      var rulerOptions = {
        startMarkerOptions: startMarkerOptions,
        midMarkerOptions: midMarkerOptions,
        endMarkerOptions: endMarkerOptions,
        lineOptions: lineOptions,
        tmpLineOptions: tmpLineOptions,
        startLabelText: "0公里",
      };
      this.ruler2 = new AMap.RangingTool(this.map, rulerOptions);
    },
    closeForm() {
      this.releaseShow = false;
    },
    // 居中显示
    shapeInCenter() {
      this.gxBorder.hide();
      this.map.setFitView();
      this.gxBorder.show();
    },
    // 悬浮改变图片
    hovImg(name) {
      if (!this.showDrawType) this[name] = 1;
    },
    // 悬浮取消
    leaveImg() {
      if (!this.showDrawType) {
        this.shapeImg = 0;
      }
      this.delImg = 0;
    },
    /**
     * kml导入的input触发
     */
    async uploadKmlFile(e) {
      let that = this;
      this.map.clearMap();
      let file = e.target.files[0];
      // 使用 FileReader 来读取文件
      let reader = new FileReader();

      // 读取纯文本文件,且编码格式为 utf-8
      reader.readAsText(file, "UTF-8");

      // 读取文件,会触发 onload 异步事件,可使用回调函数 来获取最终的值.
      reader.onload = function (e) {
        let fileContent = e.target.result;
        let data = fileContent.split("<coordinates>");
        let lnglatData = [];
        data.forEach((item) => {
          if (item.indexOf("</coordinates>") !== -1) {
            let path = [];
            let strLnglat = item.split("</coordinates>")[0];
            strLnglat.split(" ").forEach((lnglats) => {
              let lnglat = lnglats.split(",");
              if (lnglat[0] !== "" && lnglat[0])
                path.push(coordtransform.wgs84togcj02(lnglat[0], lnglat[1]));
            });
            if (JSON.stringify(path[0]) !== JSON.stringify(path[path.length -1])){
              // path.push(path[0])
              that.$message({
                message: "导入的kml区块中含有非多边形，请检查后再导入。",
                type: "warning"
              })
              return
            }
            lnglatData.push(path);
          }
        });
        that.kmlData = {};
        lnglatData.forEach((path, index) => {

          that.kmlData[index] = {};
          let lnglatData = turf.polygon([path]);

          let area = turf.area(lnglatData);

          that.kmlData[index]["area"] = area / 1000000;
          that.kmlData[index]["path"] = path;
          that.mapToolManage.findLocation(path[0]).then((res) => {
            that.kmlData[index]["location"] = res;
          });

          that.mapToolManage.drawPolygon(path);
        });
        // that.kmlData["count"] = lnglatData.length
        that.isCanRelease = true;
        that.isSelfDraw = false;
        that.map.setFitView();
      };
    },
    // 监听选中图形，来显示是否可以发布
    getSelectShape() {
      let data = this.mapToolManage.returnDrawShape();
      this.allDrawShape = data[1];
      // console.log("监听");
      if (data[2].length > 0) {
        this.isCanRelease = true;
      } else {
        this.isCanRelease = false;
      }
    },
    // 点击收起表格
    clickShowTable() {
      this.showTable = !this.showTable;
      this.$emit("showTable", this.showTable);
    },
    // 设备边界
    setBorder() {
      let e = this.map;
      let self = this;
      //高亮广西省边界
      e.plugin("AMap.DistrictSearch", () => {
        var districtSearch = new AMap.DistrictSearch({
          // 关键字对应的行政区级别，共有5种级别
          level: "province",
          //  是否显示下级行政区级数，1表示返回下一级行政区
          subdistrict: 0,
          // 返回行政区边界坐标点
          extensions: "all",
        });

        // 搜索所有省/直辖市信息
        districtSearch.search("广西", (status, result) => {
          // 查询成功时，result即为对应的行政区信息
          self.gxGcjPolygon = result.districtList[0].boundaries;
          self.handlePolygon(self.gxGcjPolygon);

          if (self.gxWgsPolygon.length === 0) {
            self.gxGcjPolygon.forEach((items, index) => {
              self.gxWgsPolygon[index] = [];
              items.forEach((data, i) => {
                self.gxWgsPolygon[index].push(
                  coordtransform.gcj02towgs84(data.lat, data.lng)
                );
              });
            });
          }
        });
      });
    },
    handlePolygon(result) {
      let bounds = result;
      let self = this;
      self.gxPolygon = [];
      if (bounds.length) {
        for (let i = 0, l = bounds.length; i < l; i++) {
          //生成行政区划polygon
          self.gxPolygon.push(
            (self.gxBorder = new AMap.Polyline({
              map: self.map, // 指定地图对象
              strokeWeight: 2, // 轮廓线宽度
              path: bounds[i], //轮廓线的节点坐标数组
              fillOpacity: 0.15, //透明度
              fillColor: "transparent", //填充颜色
              strokeColor: "#256edc", //线条颜色
            }))
          );
        }
        // 地图自适应
        self.map.setFitView();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.amap-marker-label {
  border: 0;
  background-color: transparent;
}
/deep/.measurePoint {
  width: 117px;
  height: 45px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #dcdcdc;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .lnglat {
    // display: flex;
    // justify-content: space-around;
    // align-items: center;
    display: flex;
    align-items: center;
    .titleLnglat {
      // display: inline-block;
      // width: 46px;
      margin: 0 5px;
    }
    img {
      margin-left: 10px;
      height: 12px;
    }
  }
  span {
    display: inline-block;
    font-size: 12px;
    // transform: scale(0.5);
    color: #999999;
  }
}
.task {
  height: 100%;
  position: relative;
  overflow: hidden;
  min-width: 1000px;
  /deep/.amap-logo {
    right: 0 !important;
    left: auto !important;
    display: none !important !important;
  }
  /deep/.amap-ranging-label span img {
    display: none;
  }
  /deep/.amap-ranging-label {
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #dcdcdc;
    color: #999999;
    display: none;
  }

  .map {
    height: 100%;
  }
  .info {
    position: absolute;
    top: 21px;
    right: 395px;
    /deep/.el-input__suffix {
      right: 0;
    }
    /deep/.el-input--medium .el-input__inner {
      height: 40px;
      background: rgba(3, 13, 28, 0.8);
      color: #fff;
      border: 1px solid rgba(3, 13, 28, 0.8);
    }
  }
  .control {
    position: absolute;
    top: 10px;
    right: 20px;
    height: 100%;
    div {
      position: relative;
    }
    .switchMap {
      width: 228px;
      height: 90px;
      color: #69789e;
      background: rgba(3, 13, 28, 0.8);
      border-radius: 11px;
      position: absolute;
      left: -235px;
      top: 0px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .clickMap {
        cursor: pointer;
      }
      .mapType {
        text-align: center;
        margin-top: 5px;
      }
      .isMap {
        color: #3f67ff;
      }
    }
    .playback {
      position: absolute;
      left: -545px;
      top: 0px;
    }
    .showView {
      position: absolute;
      right: -4px;
      top: -5px;
    }
    .show {
      img {
        z-index: 999;
        position: relative;
      }
    }
    .qrCode {
      width: 244px;
      height: 268px;
      background: rgba(3, 13, 28, 0.8);
      border-radius: 11px;
      position: absolute;
      right: 55px;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      .vueQr {
        width: 200px;
        height: 200px;
        background: #ffffff;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
      }
    }
  }
  .noRelease {
    color: #fff !important;
    opacity: 0.4;
    cursor: not-allowed !important;
  }
  .canRelease {
    color: #fff;
    background-color: #409eff;
  }
  .canBtn {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 3px;
    }
  }
  .release {
    text-align: center;
    font-size: 13px;
    color: #d8d8d8;
  }
  .selectRelease {
    background-color: #409eff;
  }
  .selectBtn {
    color: #409eff !important;
  }
  %drawToolPubilcStyle {
    text-align: center;
    font-size: 13px;
    color: #d8d8d8;
    &:hover {
      color: #5d626b;
    }
  }
  .drawTool {
    width: 355px;
    height: 40px;
    background-color: rgba(3, 13, 28, 0.8);
    position: absolute;
    top: 19px;
    left: 20px;
    display: flex;
    align-items: center;
    line-height: 40px;

    .draw {
      width: 100px;
      height: 100%;
      // border-right: 1px solid #767676;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .importKml {
      width: 80px;
      height: 100%;
      // border-right: 1px solid #767676;
    }
    .release {
      width: 91px;
      height: 100%;
      // border-right: 1px solid #767676;
    }
  }
  .selectToolBtn {
    color: #1182fb;
    &:hover {
      color: #1182fb;
    }
  }
  .toolBtn {
    @extend %drawToolPubilcStyle;
  }
  .measurementTool {
    position: absolute;
    top: 21px;
    right: 152px;
    width: 231px;
    display: flex;
    align-items: center;
    height: 40px;
    background-color: rgba(3, 13, 28, 0.8);
    justify-content: space-around;
    div {
      width: 77px;
      height: 100%;
      line-height: 40px;
    }
  }
  .centerShow {
    position: absolute;
    top: 21px;
    right: 20px;
    width: 120px;
    height: 40px;
    line-height: 40px;
    background-color: rgba(3, 13, 28, 0.8);
  }
  .drawType {
    position: absolute;
    top: 63px;
    left: 20px;
    width: 116px;
    height: 112px;
    background: rgba(3, 13, 28, 0.8);
    border-radius: 2px;
    .setImgMargin {
      margin-left: -30px;
    }
    .publicDrawType {
      width: 100%;
      height: 37px;
      line-height: 37px;
      @extend %drawToolPubilcStyle;
    }
  }
}
</style>
