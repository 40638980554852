var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        title: "舆情发布",
        visible: _vm.dialogVisible,
        width: "720px",
        "close-on-click-modal": false,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bodyBar" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "formbox",
              attrs: { model: _vm.ruleForm },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "project_id", label: "灾害应急任务名称" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "fmSelect",
                      attrs: {
                        filterable: "",
                        disabled: !_vm.obj.id,
                        placeholder: "请选择",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.getTaskId(_vm.ruleForm.project_id)
                        },
                      },
                      model: {
                        value: _vm.ruleForm.project_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "project_id", $$v)
                        },
                        expression: "ruleForm.project_id",
                      },
                    },
                    _vm._l(_vm.taskList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.project_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "software_code", label: "灾害应急任务包络" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "fmSelect",
                      attrs: {
                        multiple: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.envelope },
                      model: {
                        value: _vm.value1,
                        callback: function ($$v) {
                          _vm.value1 = $$v
                        },
                        expression: "value1",
                      },
                    },
                    _vm._l(_vm.subtaskList, function (group) {
                      return _c(
                        "el-option-group",
                        { key: group.id, attrs: { label: group.block_name } },
                        _vm._l(group.task, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.task_name, value: item.id },
                          })
                        }),
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "software_code", label: "任务下达类型" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "radioBar taskchoose",
                      model: {
                        value: _vm.radio,
                        callback: function ($$v) {
                          _vm.radio = $$v
                        },
                        expression: "radio",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("航线任务下达"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("通用任务下达"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "summary" }, [
                _c("span", { staticClass: "summarytit" }, [
                  _vm._v("灾害应急任务汇总"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "summaryBar" }, [
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      "包含总任务数：" + _vm._s(_vm.project_task_count) + "个"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      "已接单任务数：" +
                        _vm._s(_vm.project_task_confirm_count) +
                        "个"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      "待接单任务数：" +
                        _vm._s(_vm.project_task_unconfirmed_count) +
                        "个"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      "进行中任务数：" +
                        _vm._s(_vm.project_task_progress) +
                        "个"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "count" }, [
                    _vm._v(
                      "已完成任务数：" +
                        _vm._s(_vm.project_task_finished) +
                        "个"
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "scene" }, [
                _c("span", { staticClass: "scenetit" }, [
                  _vm._v("灾害应急任务现场"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sceneBar" }),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "software_code", label: "是否发起过现场连线" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "radioBar yesorno",
                      on: { change: _vm.getLabelId },
                      model: {
                        value: _vm.radio1,
                        callback: function ($$v) {
                          _vm.radio1 = $$v
                        },
                        expression: "radio1",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "disaster_level", label: "灾情态势" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "radioBar degree",
                      on: { change: _vm.getsituation },
                      model: {
                        value: _vm.radio2,
                        callback: function ($$v) {
                          _vm.radio2 = $$v
                        },
                        expression: "radio2",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "及其严重" } }, [
                        _vm._v("及其严重"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "严重" } }, [
                        _vm._v("严重"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "中等" } }, [
                        _vm._v("中等"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "一般" } }, [
                        _vm._v("一般"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "轻微" } }, [
                        _vm._v("轻微"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "userForm",
                  attrs: { prop: "disaster_description", label: "灾情评述" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "50",
                      rows: 2,
                      "show-word-limit": true,
                      placeholder: "请输入内容",
                      resize: "none",
                    },
                    model: {
                      value: _vm.ruleForm.disaster_description,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "disaster_description", $$v)
                      },
                      expression: "ruleForm.disaster_description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "" } }, [
                _c("div", { staticClass: "snapshot" }, [
                  _c("span", { staticClass: "snapshotit" }, [
                    _vm._v("灾情测区快照"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "snapshotbar" }, [
                    _c("input", {
                      staticClass: "imgupload",
                      attrs: {
                        type: "file",
                        accept: "image/png, image/jpeg, image/gif, image/jpg",
                      },
                      on: { change: _vm.uploadImg },
                    }),
                    _vm._v(" "),
                    this.fileUrl === ""
                      ? _c("div", { staticClass: "snapshotBar" }, [
                          _c("img", {
                            staticClass: "uploadImg",
                            attrs: {
                              src: require("@/assets/img/Upload／pic .png"),
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "describe" }, [
                            _vm._v("点击或将文件拖拽到这里上传"),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    this.fileUrl !== ""
                      ? _c(
                          "div",
                          {
                            staticClass: "imgNav snapshotBar",
                            staticStyle: { "margin-left": "0" },
                          },
                          [
                            _c("img", {
                              staticClass: "imgtit",
                              attrs: { src: this.fileUrl, alt: "" },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "hovers" },
                  [
                    _c("i", { staticClass: "iconfont sign sings" }, [
                      _vm._v(""),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-popover",
                      {
                        staticClass: "hoverBar",
                        attrs: {
                          placement: "right",
                          width: "200",
                          trigger: "manual",
                        },
                        model: {
                          value: _vm.visible,
                          callback: function ($$v) {
                            _vm.visible = $$v
                          },
                          expression: "visible",
                        },
                      },
                      [
                        _c("i", { staticClass: "iconfont yellow" }, [
                          _vm._v(""),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "sign" }, [
                          _vm._v("请使用截图工具截图测区范围并上传至此。"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "" } }, [
                _c("div", { staticClass: "henduoren" }, [
                  _c("span", { staticClass: "henduorenWord" }, [
                    _vm._v("灾情现场快照"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "imgBar" }, [
                    _c("div", { staticClass: "snapshotbar" }, [
                      _c("input", {
                        staticClass: "imgupload",
                        attrs: {
                          type: "file",
                          multiple: "multiple",
                          accept: "image/png, image/jpeg, image/gif, image/jpg",
                        },
                        on: { change: _vm.aetherupload },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "snapshotBar" }, [
                        _c("img", {
                          staticClass: "uploadImg",
                          attrs: {
                            src: require("@/assets/img/Upload／pic .png"),
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "describe" }, [
                          _vm._v("点击或将文件拖拽到这里上传"),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "hoverOne" },
                  [
                    _c("i", { staticClass: "iconfont sign sings" }, [
                      _vm._v(""),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-popover",
                      {
                        staticClass: "hoverBar",
                        attrs: {
                          placement: "right",
                          width: "200",
                          trigger: "manual",
                        },
                        model: {
                          value: _vm.visible,
                          callback: function ($$v) {
                            _vm.visible = $$v
                          },
                          expression: "visible",
                        },
                      },
                      [
                        _c("i", { staticClass: "iconfont yellow" }, [
                          _vm._v(""),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "sign" }, [
                          _vm._v("请上传林业巡护回传的照片，最多上传九张。"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "imgNav" },
                  _vm._l(_vm.fileList, function (item, index) {
                    return _c("img", {
                      key: index,
                      staticClass: "imgtit",
                      attrs: { src: _vm.fileList[index], alt: "" },
                    })
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-divider", { staticClass: "divd" }),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "select",
          attrs: {
            title: !_vm.obj.is_portal
              ? "该任务暂未发布至门户网站，请先发布任务至门户"
              : "",
          },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.checkednameUni,
                expression: "checkednameUni",
              },
            ],
            staticClass: "historyInp",
            attrs: {
              id: "Google",
              type: "checkbox",
              value: "",
              disabled: !_vm.obj.is_portal,
            },
            domProps: {
              checked: Array.isArray(_vm.checkednameUni)
                ? _vm._i(_vm.checkednameUni, "") > -1
                : _vm.checkednameUni,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.checkednameUni,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = "",
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.checkednameUni = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.checkednameUni = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.checkednameUni = $$c
                  }
                },
                _vm.historyFlyUni,
              ],
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "flystatus",
              style: { opacity: !_vm.obj.is_portal ? "0.5" : "1" },
            },
            [_vm._v("舆情发布同步发布至【公众门户网站】")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { disabled: !_vm.ifUpload, type: "primary" },
              on: { click: _vm.postback },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }