<template>
  <div class="uavBody">
    <navTop />
    <div class="bodyNav">
      <div class="rulForm">
        <div class="formList">
          <el-form
            class="formbox"
            ref="searchForm"
            :model="searchForm"
            label-width="80px"
          >
            <el-form-item v-show="type!=='terminal'" class="userForm" prop="fade_code" label="设备ID">
              <el-input
                placeholder="请输入"
                v-model="searchForm.fade_code"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="userForm"
              prop="uuid"
              label="UUID"
              v-if="type == 'terminal'"
            >
              <el-input
                placeholder="请输入"
                v-model="searchForm.uuid"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="userForm"
              prop="brand"
              label="设备品牌"
              v-if="type != 'terminal' && type != 'monitorModule' && type!=='countryApp'"
            >
              <el-select
                @change="brandChange"
                v-model="searchForm.brand_id"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="(item, index) in brandList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="userForm"
              prop="model_id"
              label="设备型号"
              v-if="type != 'terminal' && type != 'monitorModule'"
            >
              <el-select
                v-model="searchForm.model_id"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="(item, index) in modelList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="userForm" prop="unit_id" label="巡护单位">
              <el-select
                v-model="searchForm.unit_id"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="(item, index) in unitList"
                  :key="index"
                  :label="item.unit_name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="userForm" prop="status" label="设备状态">
              <el-select
                v-model="searchForm.status"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="(item, index) in statusList"
                  :key="index"
                  :label="item.key"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="userForm" prop="holder_name" label="持有人">
              <el-input
                placeholder="请输入"
                v-model="searchForm.holder_name"
              ></el-input>
            </el-form-item>
            <el-form-item class="userForm" prop="holder_phone" label="手机号">
              <el-input
                placeholder="请输入"
                v-model="searchForm.holder_phone"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="userForm"
              prop="name"
              label="创建人账户"
              v-if="type == 'uav'"
            >
              <el-input
                placeholder="请输入"
                v-model="searchForm.name"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="userForm"
              label="关联设备类型"
              v-if="type == 'monitorModule'"
            >
              <el-select
                v-model="searchForm.associated_device_type_id"
                placeholder="请选择"
                clearable
              >
                <el-option :value="0" label="请选择"></el-option>
                <el-option
                  v-for="(item, index) in associatedDeviceTypeList"
                  :key="index"
                  :label="item.value"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              class="userForm"
              prop="name"
              label="关联设备ID"
              v-if="type == 'monitorModule'"
            >
              <el-input
                placeholder="请输入"
                v-model="searchForm.associated_device_software_code"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="footBtn">
          <div class="fmBtn">
            <el-button
              class="btn"
              @click="addBtn"
              type="primary"
              plain
              v-if="type == 'uav' || type == 'monitorModule'"
              >添&nbsp;&nbsp;&nbsp;&nbsp;加</el-button
            >
            <el-button class="btn" @click="clickSearchBtn" type="primary" plain
              >查&nbsp;&nbsp;&nbsp;&nbsp;询</el-button
            >
            <el-button class="btn" @click="resetForm" type="primary" plain
              >重&nbsp;&nbsp;&nbsp;&nbsp;置</el-button
            >
          </div>
        </div>
      </div>
      <deviceList
        class="operation"
        @searchData="searchData"
        @pageChange="pageChange"
        @edit="edit"
        @del="del"
        @exportFile="exportFile"
        :editAble="true"
        :delAble="true"
        :exportAble="role !== 2"
        :data="data"
        :tableConfig="tableConfig"
        :page.sync="searchForm.page"
        :perPage.sync="searchForm.per_page"
        :total="dataTotal"
        :deviveType="type"
      />
    </div>

    <editDialog
      :categoryId="categoryId"
      :type="type"
      @reload="
        resetForm();
        searchData();
      "
      ref="editDialog"
    />
  </div>
</template>
<script>
import deviceList from "@/components/device/list";
import editDialog from "@/components/device/editDialog";
import { mapState, mapMutations } from "vuex";
import { exportFile } from "@/utils/exportFile";
export default {
  components: {
    deviceList,
    editDialog,
  },
  data() {
    return {
      tableConfig: [
        {
          field: "name",
          label: "设备名称",
          category: ["uav", "mapping"],
        },
        {
          field: "fade_code",
          label: "设备ID",
          width: "155",
          category: ["uav", "mapping", "monitorModule","countryApp"],
        },
        {
          field: "uuid",
          label: "UUID",
          category: ["terminal"],
        },
        {
          field: "brand_name",
          label: "设备品牌",
          category: ["uav", "mapping"],
        },
        {
          field: "model_name",
          label: "设备型号",
          category: ["uav", "mapping","countryApp"],
        },
        {
          field: "unit_name",
          label: "巡护单位",
          width: "120",
          category: ["uav", "terminal", "mapping", "monitorModule","countryApp"],
        },
        {
          field: "status_text",
          label: "设备状态",
          category: ["uav", "terminal", "mapping", "monitorModule","countryApp"],
        },
        {
          field: "holder_dept",
          label: "归属部门",
          category: ["uav", "terminal", "mapping", "monitorModule","countryApp"],
        },
        {
          field: "holder_name",
          label: "持有人",
          category: ["uav", "terminal", "mapping", "monitorModule","countryApp"],
        },
        {
          field: "holder_user_phone",
          label: "持有人手机号",
          category: ["uav", "terminal", "mapping", "monitorModule","countryApp"],
        },
        {
          field: "created_user_phone",
          label: "创建人账户",
          category: ["uav", "monitorModule"],
        },
        {
          field: "associated_device_type",
          label: "关联设备",
          category: ["monitorModule"],
        },
        {
          field: "associated_device_software_code",
          label: "关联设备编号",
          category: ["monitorModule"],
        },
        // {
        //   field: "address",
        //   label: "登记位置",
        //   category: ["uav", "terminal", "mapping", "monitorModule"],
        // },
      ],
      type: "",
      categoryId: 0,
      brandName: "",
      data: [],
      dataTotal: 0,
      searchForm: {
        name: "",
        software_code: "", //设备ID
        uuid: "",
        brand_id: "", //品牌
        brand_name: "",
        model_id: "", //型号
        unit_id: "", //单位
        status: "", //状态
        holder_name: "", //持有人
        holder_phone: "", //手机号
        create_user_phone: "",
        associated_device_type_id: 0,
        associated_device_software_code: "",
        page: 1,
        per_page: 8,
      },
      temSearchForm: "",
      unitList: [],
      modelList: [],
      statusList: [],
      brandList: [],
      associatedDeviceTypeList: [],
      canSearch: true, //从首页的去登记跳转过来时，第一次允许使用设备号查询列表
    };
  },
  computed: {
    ...mapState(["navMessage", "token", "user", "role"]),
  },
  watch: {
    $route: "init",
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapMutations(["currentUrlStorage"]),
    async init() {
      this.type = this.$route.query.type;
      Object.assign(this.$data.searchForm, this.$options.data().searchForm);
      this.temSearchForm = JSON.parse(JSON.stringify(this.searchForm))
      switch (this.$route.query.type) {
        case "uav":
          this.categoryId = 2;
          this.brandName = "";
          this.currentUrlStorage(["首页", "应急装备", "无人机"]);
          break;
        case "mapping":
          this.categoryId = 3;
          this.brandName = "";
          this.currentUrlStorage(["首页", "应急装备", "测绘装备"]);
          break;
        case "terminal":
          this.categoryId = 1;
          this.brandName = "林业巡护";
          this.currentUrlStorage(["首页", "应急装备", "林业巡护"]);
          break;
        case "monitorModule":
          this.categoryId = 1;
          this.brandName = "监控模块";
          this.currentUrlStorage(["首页", "应急装备", "监控模块"]);
          break;
        case "countryApp":
          this.categoryId = 1;
          this.brandName = "乡村绘";
          this.currentUrlStorage(["首页", "应急装备", "乡村绘"]);
          break;
      }
      // console.log(this.$options.data().tableConfig);
      Object.assign(this.$data.tableConfig, this.$options.data().tableConfig);
      for (let i = 0; i < this.tableConfig.length; i++) {
        if (this.tableConfig[i].category.indexOf(this.type) < 0) {
          this.tableConfig.splice(i, 1);
          i--;
        }
      }
      await this.searchStatus();
      this.searchUnit();
      this.searchBrand().then(data=>{
        this.searchModel();
      });

      this.searchData();
      this.searchAssociatedDeviceType();
    },
    resetForm() {
      Object.assign(this.$data.searchForm, this.$options.data().searchForm);
    },
    clickSearchBtn(){
      this.searchForm.page = 1;
      this.searchData()
      this.temSearchForm = JSON.parse(JSON.stringify(this.searchForm))
    },
    searchData(params = "") {

      let param = {};
      if (params !== ""){
        param = params
      }else {
        param = JSON.parse(JSON.stringify(this.searchForm))
      }
      param.category_id = this.categoryId;
      param.brand_name = this.brandName;
      if (this.$route.query.fade_code && this.canSearch){
        param.fade_code = this.$route.query.fade_code
        this.canSearch = false
      }
      this.$apis
        .ListDevice(param, this.token)
        .then((res) => {
          if (res.data.status == 0) {
            let result = res.data.data;
            this.data = result.data;
            this.dataTotal = result.total;
            this.data.forEach((item) => {
              item.status_text = this.statusList.find((v) => {
                return v.value == item.status;
              }).key;
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    pageChange(param) {
      let params = {}
      if (JSON.stringify(this.temSearchForm) === JSON.stringify(this.searchForm)){
        params = JSON.parse(JSON.stringify(this.searchForm))
      }else {
        params = JSON.parse(JSON.stringify(this.temSearchForm))
      }
      params.page = param.page;
      this.searchData(params);
    },
    addBtn() {
      setTimeout(() => {
        this.$refs.editDialog.open(null);
      }, 0);
    },
    edit(param) {
      setTimeout(() => {
        this.$refs.editDialog.open(param);
      }, 0);
    },
    del(param) {
      this.$confirm("确定删除此设备吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$apis.delteDev({ id: param.id }, this.token).then((res) => {
          if (res.data.status == 0) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.searchData();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      });
    },
    exportFile() {
      this.$apis
        .exportDevice(
          {
            type: this.$route.query.type,
            category_id: this.categoryId,
            model_id: 0,
          },
          this.token
        )
        .then((data) => {
          exportFile(data);
        })
        .catch((err) => {
          console.error(err);
        });
    },

    searchAssociatedDeviceType() {
      this.$apis
        .listEnum({ type: "associated_device_type" }, this.token)
        .then((data) => {
          this.associatedDeviceTypeList = data.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    searchUnit() {
      this.$apis
        .unitLists({ per_page: 10000 }, this.token)
        .then((data) => {
          this.unitList = data.data.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 型号查询
    searchModel() {
      this.$apis
        .ListModel(
          {
            category_id: this.categoryId,
            brand_id: this.searchForm.brand_id
          },
          this.token
        )
        .then((data) => {
          this.modelList = data.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 品牌查询
    searchBrand() {
      return new Promise((resolve,reject)=>{
        this.$apis
          .ListBrand(
            {
              category_id: this.categoryId,
            },
            this.token
          )
          .then((data) => {
            this.brandList = data.data.data;
            if (this.brandName !== ''){
              let index = this.brandList.findIndex(item=>{
                return item.name === this.brandName
              })
              this.searchForm.brand_id = this.brandList[index].id;
            }
            resolve(data.data.data)
          })
          .catch((err) => {
            console.error(err);
          });
      })

    },
    // 状态查询
    searchStatus() {
      return new Promise((resolve, reject) => {
        this.$apis
          .ListStatus(
            {
              category_id: this.categoryId,
            },
            this.token
          )
          .then((data) => {
            this.statusList = data.data.data;
            resolve();
          })
          .catch((err) => {
            console.error(err);
            reject();
          });
      });
    },
    brandChange() {
      this.searchForm.model_id = "";
      this.searchModel();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form-item__content {
  width: 190px;
  float: left;
}
/deep/.el-form-item {
  float: left;
  margin-bottom: 30px;
}
/deep/.el-input--medium .el-input__inner {
  width: 190px;
}
/deep/.el-menu.el-menu--horizontal {
  width: 100%;
}
/deep/.el-form-item--medium .el-form-item__label {
  width: 110px !important;
}

.uavBody {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  padding: 0px 30px 15px 30px;
}
.rulForm {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
  .fmNav {
    width: 90%;
    display: flex;
    justify-content: left;
    margin-bottom: 50px;
    margin-left: 150px;
  }
}
.formList {
  width: 100%;
  height: 30%;
}
.formbox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0 0 0;
}
.userForm {
  margin-left: 1px;
  font-size: 16px;
  color: #838a9d;
}
.footBtn {
  width: 100%;
}
.fmBtn {
  margin-left: 2%;
  margin-bottom: 2%;
  display: inline-table;
  .btn {
    margin-right: 20px;
  }
}
.operation {
  width: 100%;
  height: 65%;
  background-color: #ffffff;
  box-shadow: 4px 4px 4px 2px rgba(221, 229, 237, 0.5);
  margin-top: 2vh;
}
@media only screen and (min-width: 1920px) {
  .footBtn {
    margin-top: 30px;
  }
  .uavBody {
    overflow-y: auto;
  }
}
@media only screen and (min-width: 1290px) and (max-width: 1920px) {
  .uavBody {
    device-height: 800px;
    min-width: 1030px;
    overflow-y: auto;
  }
  /deep/.el-menu.el-menu--horizontal {
    width: 90%;
  }
  .footBtn {
    margin-top: 30px;
  }
  .tableData {
    border-collapse: collapse;
    font-size: 14px;
    font-weight: normal;
    .tabTitle {
      height: 56px;
      color: #000000;
      font-size: 16px;
    }
  }
  .tableFile {
    height: 30px;
    color: #000000;
    font-size: 15px;
  }
}
@media only screen and (max-width: 1290px) {
  /deep/.el-menu.el-menu--horizontal {
    width: 90%;
  }
  .uavBody {
    overflow-y: auto;
  }
  .rulForm {
    .fmNav {
      margin-left: 50px;
    }
  }
  .searchBtn {
    margin-right: 15px;
  }
  .reset {
    width: 90px;
    height: 35px;
  }
  .tableData {
    border-collapse: collapse;
    font-size: 14px;
    font-weight: normal;
    .tabTitle {
      height: 56px;
      color: #000000;
      font-size: 16px;
    }
  }
  .tableFile {
    height: 30px;
    color: #000000;
    font-size: 14px;
  }
}
</style>
