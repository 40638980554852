<template>
  <div>
    <el-dialog
      :modal="false"
      :visible.sync="dialogVisible"
      width="35%"
      top="85px"
      :show-close="false"
      v-dialogDrag
    >
      <div class="titleBar">
        <div
          class="leftBar"
          :class="{ active: this.flag == 1 }"
          @click="leftBtn(1)"
        >
          <span class="leftword"
            >待办任务({{ msgCenter.unread_num.task }})</span
          >
        </div>
        <div
          class="midBar"
          :class="{ active: this.flag == 2 }"
          @click="midBtn(2)"
        >
          <span class="midword"
            >标准动作指令({{ msgCenter.unread_num.task_command }})</span
          >
        </div>
        <div
          class="rightBar"
          :class="{ active: this.flag == 3 }"
          @click="rightBtn(3)"
        >
          <span class="rightword"
            >SHP文件({{ msgCenter.unread_num.task_file }})</span
          >
        </div>
      </div>
      <div class="bodyBar">
        <div
          v-for="(item, index) in messageData"
          :key="index"
          class="lineBar"
          @click="readBtn(item)"
        >
          <div v-if="flag == 1" class="task">
            <div v-show="item.is_read == 0" class="sircel" />
            <div class="body">
              <div class="imgNav">
                <img
                  v-if="item.project.type == 1 && !item.task"
                  src="@/assets/img/homeImg/message／task／feima.png"
                  alt
                />
                <img
                  v-if="item.project.type == 1 && item.task && item.task.push_type == 1"
                  src="@/assets/img/homeImg/message／task／feima／1.png"
                  alt
                />
                <img
                  v-if="item.project.type == 1 && item.task && item.task.push_type == 2"
                  src="@/assets/img/homeImg/message／task／feima／2.png"
                  alt
                />
                <img
                  v-if="item.project.type == 2 && !item.task"
                  src="@/assets/img/homeImg/message／task／general.png"
                  alt
                />
                <img
                  v-if="item.project.type == 2 && item.task && item.task.push_type == 1"
                  src="@/assets/img/homeImg/message／task／general1.png"
                  alt
                />
                <img
                  v-if="item.project.type == 2 && item.task && item.task.push_type == 2"
                  src="@/assets/img/homeImg/message／task／general2.png"
                  alt
                />

                <img
                  v-if="item.project.type == 3 && !item.task"
                  src="@/assets/img/homeImg/blocksassigned.png"
                  alt
                />
                <img
                  v-if="item.project.type == 3 && item.task && item.task.push_type == 1"
                  src="@/assets/img/homeImg/block_assigned.png"
                  alt
                />
                <img
                  v-if="item.project.type == 3 && item.task && item.task.push_type == 2"
                  src="@/assets/img/homeImg/block_mass.png"
                  alt
                />

              </div>
              <div class="taskNav">
                <div class="leftNav">
                  <p class="taskname">{{ item.project.project_name }}</p>
                  <p class="setuser">创建人：{{ item.from_user_data.name }}</p>
                </div>
                <div class="rightNav">
                  <span class="settime">创建时间：{{ item.created_at }}</span>
                </div>
              </div>
              <div class="timeNav">
                <span class="timer">{{ item.created_at }}</span>
              </div>
            </div>
          </div>
          <div v-if="flag == 2" class="task">
            <div v-if="item.is_read == 0" class="sircel" />
            <div class="instruction">
              <div class="instructionImgNav">
                <img
                  class="img"
                  v-if="item.from_user_data.photo_url"
                  :src="item.from_user_data.photo_url"
                  alt
                />
                <img
                  class="img"
                  v-else
                  src="@/assets/img/user-default-head-small.png"
                />
              </div>
              <div class="instructionTaskNav">
                <div class="instructionLeftNav">
                  <p class="instructionTaskname">{{ item.from_user_data.name }}</p>
                  <p class="instructionSetuser" v-if="item.task">{{ item.task.task_command }}</p>
                </div>
              </div>
              <div class="instructiontimeNav">
                <span class="instructionTimer">{{ item.created_at }}</span>
                <p
                v-if="item.task"
                  :title="
                    item.project.project_name +
                    '-' +
                    item.task.block_name +
                    '-' +
                    item.task.task_name
                  "
                  class="instructionPlay"
                >
                  {{
                    item.project.project_name +
                    "-" +
                    item.task.block_name +
                    "-" +
                    item.task.task_name
                  }}
                </p>
              </div>
            </div>
          </div>
          <div v-if="flag == 3" class="task">
            <div v-if="item.is_read == 0" class="sircel" />
            <div class="body">
              <div class="imgNav">
                <img
                  class="img"
                  v-if="item.from_user_data.photo_url"
                  :src="item.from_user_data.photo_url"
                  alt
                />
                <img
                  class="img"
                  v-else
                  src="@/assets/img/user-default-head-small.png"
                />
              </div>
              <div class="taskNav">
                <div class="leftNav">
                  <p class="taskname">{{ item.from_user_data.name }}</p>
                  <p class="setuser">{{ item.task.task_file }}</p>
                </div>
              </div>
              <div class="timeNav">
                <span class="timer">{{ item.created_at }}</span>
                <p class="play">
                  {{
                    item.project.project_name +
                    "-" +
                    item.task.block_name +
                    "-" +
                    item.task.task_name
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footBar">
        <el-button type="text" @click="readAll">全部清空</el-button>
        <el-divider direction="vertical" />
        <el-button type="text" @click="watchMore(7)">查看更多</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false,
      flag: 1,
      messageData: [],
    };
  },
  methods: {
    ...mapMutations(["navMessageStorage", "currentUrlStorage"]),
    open() {
      this.dialogVisible = true;
      this.NoticeList();
    },
    leftBtn(param) {
      this.flag = param;
      this.NoticeList();
    },
    midBtn(param) {
      this.flag = param;
      this.NoticeList();
    },
    rightBtn(param) {
      this.flag = param;
      this.NoticeList();
    },
    // 消息列表
    NoticeList() {
      this.$apis
        .getNoticeList(
          {
            type: this.flag,
            per_page: 6,
            is_read: 0,
          },
          this.token
        )
        .then((data) => {
          if (data.data.status == 0) {
            this.messageData = data.data.data.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 读单条消息
    readBtn(param) {
      this.$apis
        .setNoticeRead(
          {
            id: param._id.$oid,
          },
          this.token
        )
        .then((data) => {
          param.is_read = 0;
          switch (this.flag) {
            case 1:

              this.navMessageStorage(0);
              if (param.project.type !== 3) {
                this.currentUrlStorage([
                "首页",
                "任务下达",
                param.project.type == 1 ? "航线任务" : "通用任务",
              ]);
                this.$router.push({
                  path: "/blockTaskPush",
                  query: {
                    id: param.project.id,
                    nav: param.project.type,
                    name: param.project.project_name,
                    mobile: param.project.user.mobile,
                    push_flag: param.project.push_flag,
                  },
                });
              } else {
                this.currentUrlStorage([
                "首页",
                "任务下达",
                '区块任务'
              ]);
                this.$router.push({
                  path: "/blockTaskPush",
                  query: {
                    id: param.project.id,
                    nav: param.project.type,
                    name: param.project.project_name,
                    mobile: param.project.user.mobile,
                    push_flag: param.project.push_flag,
                  },
                });
              }

              //减少未读数
              if (this.msgCenter.unread_num.task > 0) {
                this.msgCenter.unread_num.task--;
              }

              break;
            case 2:
              this.currentUrlStorage([
                "首页",
                "任务下达",
                param.project.type == 1 ? "航线任务" : "通用任务",
              ]);
              this.navMessageStorage(0);
              this.$router.push({
                path: "/blockTaskPush",
                query: {
                  id: param.project.id,
                  nav: param.project.type,
                  name: param.project.project_name,
                  mobile: param.project.user.mobile,
                  push_flag: param.project.push_flag,
                },
              });
              //减少未读数
              if (this.msgCenter.unread_num.task_command > 0) {
                this.msgCenter.unread_num.task_command--;
              }
              break;
            case 3:
              //减少未读数
              if (this.msgCenter.unread_num.task_file > 0) {
                this.msgCenter.unread_num.task_file--;
              }
              window.open(param.task.task_file_url);
              break;
            default:
              break;
          }
          this.dialogVisible = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 全部清空
    readAll() {
      this.$apis
        .readAllNotice({}, this.token)
        .then((data) => {
          if (data.data.status == 0) {
            this.msgCenter.unread_num.task = 0;
            this.msgCenter.unread_num.task_command = 0;
            this.msgCenter.unread_num.task_file = 0;
            this.msgCenter.unread_num.data_apply = 0;
            this.NoticeList();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 查看更多
    watchMore(index) {
      this.dialogVisible = false;
      this.navMessageStorage(index);
      switch (this.flag) {
        case 1:
          this.$router.push({ path: "/taskpage", query: { nav: "taskp" } });
          this.currentUrlStorage(["首页", "消息通知", "待办任务"]);
          break;
        case 2:
          this.$router.push({ path: "/actionpage", query: { nav: "action" } });
          this.currentUrlStorage(["首页", "消息通知", "标准动作指令"]);
          break;
        case 3:
          this.$router.push({ path: "/shpage", query: { nav: "shp" } });
          this.currentUrlStorage(["首页", "消息通知", "SHP文件"]);
          break;

        default:
          break;
      }
    },
  },
  computed: {
    ...mapState(["token", "user", "msgCenter"]),
  },
  watch: {
    msgCenter: {
      handler(newVal, oldVal) {
        if (newVal.new_msg.wait_handle == 1) {
          this.NoticeList();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.active {
  border-bottom: 5px solid #1182fb;
  color: #1182fb;
}

/deep/.el-dialog__header {
  padding: 0px;
}
/deep/.el-dialog__body {
  border-radius: 4px;
  padding: 10px 0px;
}
/deep/.el-divider--vertical {
  height: 47px;
}
/deep/.el-dialog {
  position: absolute;
  left: 64vw;
  height: 685px !important;
  width: 585px !important;
  border-radius: 4px;
}
/deep/.el-button--medium {
  padding: 0px 0px;
}
.titleBar {
  height: 72px;
  width: 100%;
  border-bottom: 1px solid #dde2ef;
}
.leftBar {
  width: 33.3%;
  height: 100%;
  float: left;
  text-align: center;
  line-height: 72px;
  cursor: pointer;
}
.footBar {
  width: 100%;
  height: 48px;
  border-top: 1px solid #dde2ef;
  display: flex;
  justify-content: space-evenly;
}
.leftBar:hover {
  border-bottom: 5px solid #1182fb;
  .leftword {
    color: #1182fb;
  }
}
.midBar {
  width: 33.3%;
  height: 100%;
  float: left;
  text-align: center;
  line-height: 72px;
  cursor: pointer;
}
.midBar:hover {
  border-bottom: 5px solid #1182fb;
  .midword {
    color: #1182fb;
  }
}
.rightBar {
  width: 33.3%;
  height: 100%;
  float: left;
  text-align: center;
  line-height: 72px;
  cursor: pointer;
}
.rightBar:hover {
  border-bottom: 5px solid #1182fb;
  .rightword {
    color: #1182fb;
  }
}
.bodyBar {
  height: 550px;
  width: 100%;
}
.lineBar {
  height: 92px;
  width: 100%;
  border-bottom: 1px solid #dde2ef;
  cursor: pointer;
}
.lineBar:hover {
  background-color: #dde2ef;
}
.task {
  width: 100%;
  height: 100%;
}
.imgNav {
  margin-left: 20px;
  display: flex;
  align-items: center;
  height: 100%;
  float: left;
  width: 15%;
  .img {
    width: 48px;
    .el-pagination__editor.el-input {
      width: 50px;
    }
    height: 48px;
    border-radius: 24px;
  }
}
.taskNav {
  height: 100%;
  display: flex;
  align-items: center;
  float: left;
  width: 51%;
}
.timeNav {
  height: 100%;
  margin-top: 15px;
  float: left;
  width: 23%;
}
.timer {
  font-size: 12px;
  color: #838a9d;
}
.play {
  height: 28px;
  border: 1px solid #a6aab8;
  background-color: #f3f5f7;
  line-height: 28px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 165px;
  text-align: center;
}
.rightNav {
  margin-top: 28px;
  margin-left: 15px;
}
.leftNav {
}
.taskname {
  font-size: 14px;
  color: #3c4353;
}
.setuser {
  font-size: 12px;
  color: #838a9d;
}
.settime {
  font-size: 12px;
  color: #838a9d;
}
.sircel {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #fd5c5e;
  margin-left: 10px;
  float: left;
  margin-top: 34px;
}
.body {
  height: 90%;
  float: right;
  width: 95%;
}
.instruction {
  height: 100%;
  display: flex;
  align-items: center;
  // justify-content: space-around;
  .instructionImgNav{
      margin: 0 20px;
      .img {
      width: 48px;
      .el-pagination__editor.el-input {
        width: 50px;
      }
      height: 48px;
      border-radius: 24px;
    }

  }
  .instructionTaskNav{
    margin-right: 20px;
    max-width: 150px;
  }
  .instructiontimeNav{
    margin-top: 1rem;
  }
}
</style>
