var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticClass: "particulars-body" }, [
        _c("div", { staticClass: "div uav" }, [
          _c(
            "div",
            { staticClass: "leftBar" },
            _vm._l(_vm.displayedInfo, function (item) {
              return _c("span", { key: item.no, staticClass: "title" }, [
                _vm._v(_vm._s(item.name)),
              ])
            }),
            0
          ),
          _vm._v(" "),
          Object.keys(_vm.displayedInfo).length > 0
            ? _c(
                "div",
                { staticClass: "rightNav" },
                _vm._l(_vm.displayedInfo, function (item) {
                  return _c(
                    "span",
                    {
                      key: item.no,
                      staticClass: "rightBar",
                      attrs: { title: _vm.allInfo[item.id] },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("filterAppName")(
                            _vm._f("statusName")(
                              _vm._f("filterEmpty")(_vm.allInfo[item.id])
                            )
                          )
                        )
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.popType === "terminal"
          ? _c(
              "div",
              { staticClass: "trajectoryBar" },
              [
                _c("el-switch", {
                  staticClass: "switchTurn",
                  on: { change: _vm.showThisTrack },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "trajectory" }, [_vm._v("移动轨迹")]),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.popType === "drone"
          ? _c(
              "div",
              { staticClass: "trajectoryBar" },
              [
                this.droneInfo.url || this.droneInfo.iframe_url
                  ? _c(
                      "el-button",
                      { staticClass: "toDetail", on: { click: _vm.toDetail } },
                      [_vm._v("查看详情")]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }